import { ComponentStyleConfig } from '@chakra-ui/theme'

const UserInfoStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: [
    'wrapper',
    'info',
    'about',
    'labelName',
    'labelEmail',
    'trigger',
    'signOut',
  ],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      flexDirection: 'column',
    },
    info: {
      alignItems: 'center',
    },
    about: {
      flexDirection: 'column',
    },
    labelName: {
      fontFamily: 'body',
      fontWeight: 'regular',
    },
    labelEmail: {
      fontFamily: 'body',
      fontWeight: 'regular',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    trigger: {
      cursor: 'pointer',
      '.icon': {
        use: {
          fill: 'fontandicongray',
        },
      },
    },
    signOut: {
      cursor: 'pointer',
      color: 'fontnavy',
      alignItems: 'center',
      '.icon': {
        use: {
          fill: 'fontnavy',
        },
      },
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      labelName: {
        fontSize: 'body',
        lineHeight: 'body',
      },
      labelEmail: {
        fontSize: 'caption',
        lineHeight: 'caption',
      },
      wrapper: {
        '.chakra-avatar': {
          width: '36px',
          height: '36px',
        },
      },
      about: {
        margin: '0 12px',
        width: '170px',
      },
      signOut: {
        marginTop: '30px',
        '.icon': {
          marginRight: '22px',
          marginLeft: '6px',
        },
      },
    }),
  },
  // Styles for the visual style variations
  variants: {
    normal: {
      labelName: {
        color: 'fontnavy',
      },
      labelEmail: {
        color: 'fontandicongray',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}

export default UserInfoStyle
