import { useEffect } from 'react'
import useLooseConectionDialog from './useLooseConnectionDialog'

//=======================================

const useNetwork = () => {
  const [handleDialogOpen, handleDialogClose, looseConnectionDialog] =
    useLooseConectionDialog()

  useEffect(() => {
    window.addEventListener('online', handleDialogClose)
    window.addEventListener('offline', handleDialogOpen)

    return () => {
      window.removeEventListener('online', handleDialogClose)
      window.removeEventListener('offline', handleDialogOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return looseConnectionDialog
}

export default useNetwork
