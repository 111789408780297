import { Services, SFApplicationStatus } from 'constants/dicti'
import { PRECONF_FILTER_PL_TC150ONLY } from 'hooks/api/firebase/usePropertyList'
import { useMyApplications } from 'hooks/api/my/MyApplicationsContext'
import { useAppConfig } from 'hooks/useApplicationContext'
import { useMemo } from 'react'

export const useApplications = (serviceType?: Services) => {
  const appconf = useAppConfig(),
    applicationsState = useMyApplications()

  const applications = useMemo(
    () =>
      applicationsState.data
        ?.filter(a => !serviceType || a.serviceCS === serviceType)
        .filter(
          a =>
            a.applicationSFStatusCS !== SFApplicationStatus.Void &&
            (serviceType &&
            appconf.propertyListDefaultFilter?.[serviceType] ===
              PRECONF_FILTER_PL_TC150ONLY
              ? a.hasTC150Tag
              : true)
        ),
    [appconf.propertyListDefaultFilter, applicationsState.data, serviceType]
  )
  return {
    data: applications,
    isPending: applicationsState.isPending,
    error: applicationsState.error,
  }
}
