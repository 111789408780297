import { ReactNode } from 'react'
import { Box, Tooltip, TooltipProps, useStyles } from '@chakra-ui/react'
import { AnyFunction } from 'helpers/utils'

//===================================================

export enum NavBarItemVariants {
  ACTIVE = 'navBarActive',
  VISITED = 'navBarVisited',
  NETURAL = 'navBar',
}

type Item = {
  id?: any
  label?: string | ReactNode
  navHandler?: AnyFunction
  toolipParams?: TooltipProps
  variant?: string
  width?: string
}
export type NavBarItems = Item[]

type NavBarProps = {
  items: NavBarItems
}

const NavBar = ({ items, ...props }: NavBarProps) => {
  const styles = useStyles()

  return (
    <>
      {items.map((item: Item) => (
        <Tooltip
          isLazy
          hasArrow
          key={item.id}
          placement={`bottom-start`}
          bg={'white100'}
          color={'fontnavy'}
          textStyle={'caption.regular'}
          p={'8px'}
          borderRadius={'6px'}
          label={item.label}
          {...item.toolipParams}
        >
          <Box
            sx={styles[item.variant as string]}
            w={item.width}
            onClick={item.navHandler}
          />
        </Tooltip>
      ))}
    </>
  )
}

export default NavBar
