import React from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { chakra } from '@chakra-ui/system'
import { Text } from '@chakra-ui/layout'
import Button from 'ui-framework/components/primitives/Button'
import { OnClickHandler } from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'

//===================================================
const Wrapper = chakra('div', {
  baseStyle: {
    width: '100%',
    height: '',
    borderRadius: '8px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'secondary.divider',
    padding: '16px',
  },
})

type UpdateNotificationProps = {
  onUpdate?: OnClickHandler
} & BaseComponentProps

/**
 * UpdateNotification component
 */
const UpdateNotification = (props: UpdateNotificationProps) => {
  return (
    <Wrapper className={props.className}>
      <Text textStyle="body.semibold" mb="10px">
        New Version of the Portal
      </Text>
      <Text textStyle="caption.regular" mb="16px">
        Our portal has been updated. We recommend to update version by clicking
        button below.
      </Text>
      <Button
        variant="secondary"
        size="sm"
        w="100%"
        onClick={getCallback(props.onUpdate)}
      >
        Update now
      </Button>
    </Wrapper>
  )
}

export default UpdateNotification
