import React, { memo } from 'react'
import { ChakraComponentProps, OnClickHandler } from 'ui-framework/common/types'
import { IconType } from './iconsList'
import { Box } from '@chakra-ui/layout'
import { useStyleConfig } from '@chakra-ui/system'
import { keyboardEventListener } from 'helpers/utils'

//===================================================
export type IconProps = {
  /**
   * Icon size constant or exact pixel size
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  /**
   * Icon color.
   */
  color?: string
  children: IconType | string
  onClick?: OnClickHandler
  onClickCapture?: OnClickHandler
  tabIndex?: number
  margin?: string
  onKeyUp?: React.KeyboardEventHandler
} & Omit<ChakraComponentProps, 'children' | 'size'>
/**
 * Icon shows an icon from the svg sprite with sizes
 */
const Icon = (props: IconProps) => {
  const style = useStyleConfig('MGNYIcon', {
      ...props,
      type: props.children,
    } as any),
    onKeyUp =
      props.onKeyUp ||
      function (e) {
        keyboardEventListener(e, props.onClick)
      }

  return (
    <Box
      sx={style}
      {...props}
      className={`${props.className} icon ${props.children}`}
      onKeyUp={onKeyUp}
    >
      <svg>
        {/* <use xlinkHref={`#${props.children}`}></use> */}
        <use href={`/sprite.svg#${props.children}`}></use>
      </svg>
    </Box>
  )
}

export default memo(Icon)
