import React, { useCallback, useMemo, useState, ReactNode } from 'react'
import { VStack, chakra, HStack } from '@chakra-ui/react'
import { set, get } from 'object-path-immutable'

import { integerPositiveThousands } from 'helpers/formats'
import { getCallback } from 'helpers/utils'
import { getNameAndErrorProps } from 'helpers/utils'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { Parking } from 'hooks/api/ta/TaFormData'

import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import NullableMaskedNumber from 'ui-framework/components/primitives/Input/NullableMaskedNumber'

import schema from './schema'

const Li = chakra('li', {
  baseStyle: {
    lineHeight: '25px',
    color: 'fontnavy',
  },
})

const inputForDigitsFormat = new NullableMaskedNumber({
  ...integerPositiveThousands,
  max: 9999,
})

type Props = {
  parking: Parking
  onlyTotal?: boolean
} & BaseComponentProps &
  StepPropsInterface

const StepPropertyParking = ({
  parking,
  onlyTotal = false,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const [initialValue] = useState(parking),
    parkingChangeHandler = useCallback(
      (value, name) => getCallback(onChange)(set({ parking }, name, value)),
      [onChange, parking]
    ),
    attentionMessage = useMemo(() => {
      if (!parking) return null
      const keyLabel = {
        'indoorParkingUses.totalNumberOfParkingSpots':
          'Total Indoor Parking Spaces',
        'indoorParkingUses.numberOfPaidParkingSpots':
          'Paid Indoor Parking Spaces',
        'outdoorParkingUses.totalNumberOfParkingSpots':
          'Total Outdoor Parking Spaces',
        'outdoorParkingUses.numberOfPaidParkingSpots':
          'Paid Outdoor Parking Spaces',
      },
        isZero = v => v === 0,
        values = Object.keys(keyLabel).reduce((acc, key) => {
          const zero = isZero(get({ parking }, `parking.${key}`)),
            initial = get({ initialValue }, `initialValue.${key}`)

          if (zero && initial) {
            acc.push(
              (
                <Li textStyle="body.semibold">
                  - {initial} {keyLabel[key]}.
                </Li>
              ) as ReactNode
            )
          }
          return acc
        }, [] as Array<ReactNode | undefined>)

      return (
        values.length && (
          <AttentionBox title={null} w={`516px`} variant="warning">
            <ul style={{ listStyle: 'none' }}>
              <VStack spacing={'4px'} align="left">
                <Li textStyle="body.regular">
                  The data from previous submissions indicates:
                </Li>
                {values}
              </VStack>
            </ul>
          </AttentionBox>
        )
      )
    }, [parking, initialValue]),
    validationData = useMemo(
      () => ({ parking, onlyTotal }),
      [onlyTotal, parking]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = useCallback(
      name => getNameAndErrorProps(name, hasError),
      [hasError]
    )

  return (
    <StepBasic className={props.className}>
      <VStack width="560px" spacing="36px" align="left">
        <ReviewLastYearDataWarningBlock />

        <HStack spacing={`36px`}>
          <HelpTarget name="parking.indoorParkingUses.totalNumberOfParkingSpots">
            <Input
              width="247px"
              label="# of Total Indoor Parking Spaces"
              value={parking.indoorParkingUses.totalNumberOfParkingSpots}
              type="number"
              format={inputForDigitsFormat}
              disabled={disabled}
              errorTooltip
              onChange={parkingChangeHandler}
              {...nameAndErrorProps(
                'parking.indoorParkingUses.totalNumberOfParkingSpots'
              )}
            />
          </HelpTarget>
          {!onlyTotal && (
            <HelpTarget name="parking.indoorParkingUses.numberOfPaidParkingSpots">
              <Input
                width="247px"
                label="# of Paid Indoor Parking Spaces"
                value={parking.indoorParkingUses.numberOfPaidParkingSpots}
                type="number"
                format={inputForDigitsFormat}
                disabled={disabled}
                errorTooltip
                onChange={parkingChangeHandler}
                {...nameAndErrorProps(
                  'parking.indoorParkingUses.numberOfPaidParkingSpots'
                )}
              />
            </HelpTarget>
          )}
        </HStack>

        <HStack spacing={`36px`}>
          <HelpTarget name="parking.outdoorParkingUses.totalNumberOfParkingSpots">
            <Input
              width="247px"
              label="# of Total Outdoor Parking Spaces"
              value={parking.outdoorParkingUses.totalNumberOfParkingSpots}
              type="number"
              format={inputForDigitsFormat}
              disabled={disabled}
              errorTooltip
              onChange={parkingChangeHandler}
              {...nameAndErrorProps(
                'parking.outdoorParkingUses.totalNumberOfParkingSpots'
              )}
            />
          </HelpTarget>
          {!onlyTotal && (
            <HelpTarget name="parking.outdoorParkingUses.numberOfPaidParkingSpots">
              <Input
                width="247px"
                label="# of Paid Outdoor Parking Spaces"
                value={parking.outdoorParkingUses.numberOfPaidParkingSpots}
                type="number"
                format={inputForDigitsFormat}
                disabled={disabled}
                errorTooltip
                onChange={parkingChangeHandler}
                {...nameAndErrorProps(
                  'parking.outdoorParkingUses.numberOfPaidParkingSpots'
                )}
              />
            </HelpTarget>
          )}
        </HStack>
        {attentionMessage && attentionMessage}
      </VStack>
    </StepBasic>
  )
}

export default StepPropertyParking
