import useUserState from "hooks/useUserState"
import { useCallback, useEffect } from "react"
import { useAssistantOnboardingState } from "ui-framework/components/patterns/HelpBlock/useAssistantOnboarding"
import { firebase } from 'helpers/firebaseInit'
import { throttle } from "lodash-es"

const useTrackAccessEvent = () => {
  const assistantOnboardingState = useAssistantOnboardingState(),
    [, laSet] = useUserState('lastAccess', null),
    run = useCallback(() => {
      laSet(firebase.firestore.FieldValue.serverTimestamp())
      assistantOnboardingState.updateOnLogin()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assistantOnboardingState.updateOnLogin, laSet])

  useEffect(() => {
    run()
    const fn = throttle(run, 15 * 1000, { leading: false })
    document.body.addEventListener('click', fn, { capture: true })
    return document.body.removeEventListener('click', fn)
  }, [run])
}

export default useTrackAccessEvent