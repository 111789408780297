import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { PortionRentFromAnyTenants } from 'constants/dicti'

const schema = schemaWrapper({
  wereAnySubstantialVacancies: schemaRules.BOOLEAN,
  isCovid: Joi.when('wereAnySubstantialVacancies', {
    is: true,
    then: schemaRules.BOOLEAN,
    otherwise: null,
  }),
  portionVacantTroubleTenantCS: Joi.when('isPortionVacantQuestionShown', {
    is: true,
    then: schemaRules.VALID(
      PortionRentFromAnyTenants.Residential,
      PortionRentFromAnyTenants.NonResidential,
      PortionRentFromAnyTenants.Both
    ),
    otherwise: null,
  }),
  covidFiles: schemaRules.ANY,
  whatTriggeredLowCollection: Joi.when('wereAnySubstantialVacancies', {
    is: true,
    then: schemaRules.SOME_SYMBOL_STRING(),
    otherwise: null,
  }),
  whatPartsWereAffected: Joi.when('wereAnySubstantialVacancies', {
    is: true,
    then: schemaRules.SOME_SYMBOL_STRING(),
    otherwise: null,
  }),
  howMuchIncomeWasLost: Joi.when('wereAnySubstantialVacancies', {
    is: true,
    then: schemaRules.SOME_SYMBOL_STRING(),
    otherwise: null,
  }),
})

export default schema
