import styled, { CSSObject } from '@emotion/styled/macro'

type MessageProps = {
  error?: string
  width: string | number
}

const Message = styled('span')<MessageProps>(
  ({ error, width, ...props }): CSSObject => ({
    ...props.theme.typography.body2,
    width: width,
    // height: props.theme.typography.caption2.lineHeight,
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    height: 'auto',
    whiteSpace: 'pre-line',
    overflow: 'initial',
    color: props.theme.colors[error ? 'error' : 'black70'],
  })
)

export default Message
