import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import { chakra, useDisclosure } from '@chakra-ui/react'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import SummaryTable from '../Wizard/SummaryTable'
import BaseComponentProps from 'common/BaseComponentProps'
import { AnyFunction } from 'helpers/utils'

//=================================================

type InformationDialogWithTriggerProps = {
  triggerText?: string
  title?: string
  sectionKey?: string
  sectionFn?: AnyFunction
} & BaseComponentProps

const findSection = (sections, id) =>
  sections.reduce((res, section) => {
    id.includes(section.id) && res.push(section)

    return res
  }, [])

const DEFAULT_WIDTH = 'w860',
  MAX_WIDTH = 'w1100'

const InformationDialogWithTrigger = ({
  triggerText = 'Show Info',
  title = '',
  sectionKey,
  sectionFn,
  children,
  ...props
}: InformationDialogWithTriggerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { context } = useServiceWizard()

  const [info, modalWidth] = (() => {
    if (children) return [children, DEFAULT_WIDTH]
    if (!sectionFn) return [null, DEFAULT_WIDTH]
    const section = findSection(sectionFn(context), sectionKey)
    return [
      section.length > 0 && (
        <SummaryTable
          context={context}
          sectionsConfig={section}
          withoutTitleRow
          fullSize
        />
      ),
      section.some(s => s.maxContent) ? MAX_WIDTH : DEFAULT_WIDTH,
    ] as any
  })()

  return (
    info && (
      <>
        <Button onClick={onOpen} variant={`secondary`} size={`sm`}>
          {triggerText}
        </Button>
        <ModalDialog
          closeOnOverlayClick
          onClose={onClose}
          key={`informationDialog`}
          isOpen={isOpen}
          title={title}
          height
          size={modalWidth}
          footerActions={
            <Button onClick={onClose} m={`0 auto`} w={`400px`}>
              Close
            </Button>
          }
        >
          <chakra.div
            sx={{
              table: {
                borderTop: 0,
              },
            }}
          >
            {info}
          </chakra.div>
        </ModalDialog>
      </>
    )
  )
}

export default InformationDialogWithTrigger
