import React, { useState, useEffect } from 'react'
import FooterUI, {
  FooterTop,
  FooterTitle,
  VersionController,
} from './styles/Footer'
import Divider from 'ui-framework/components/primitives/Divider'

const Footer = props => {
  const [showVersion, setShowVersion] = useState(false)
  const [counter, setCounter] = useState({ count: 0, time: Date.now() })

  const handleVersionClick = () => {
    if (!showVersion)
      setCounter(prevValue => {
        const count =
          Date.now() - prevValue.time > 1000 ? 0 : prevValue.count + 1
        return {
          count,
          time: Date.now(),
        }
      })
  }

  useEffect(() => {
    if (counter.count > 10) setShowVersion(true)
  }, [counter])

  return (
    <FooterUI className={props.className}>
      <FooterTop>
        <FooterTitle>MGNY Consulting</FooterTitle>
        <div>
          <VersionController onClick={handleVersionClick}>
            &copy;
          </VersionController>
          <span>{new Date().getFullYear()}</span>
        </div>
        {showVersion && (
          <div>{`${process.env.REACT_APP_BRANCH} / ${process.env.REACT_APP_COMMIT} / ${process.env.REACT_APP_BUILD_DATETIME}`}</div>
        )}
      </FooterTop>
      <Divider />
    </FooterUI>
  )
}

export default Footer
