import { useStyleConfig } from '@chakra-ui/system'
import { Box } from '@chakra-ui/react'

const Card = props => {
  const style = useStyleConfig('MGNYCard', props)
  return (
    <Box className={props.className} sx={style}>
      {props.children}
    </Box>
  )
}
export default Card
