import React, { useMemo, useCallback, useEffect } from 'react'

import Button from 'ui-framework/components/primitives/Button'
import Icon from 'ui-framework/components/primitives/Icon'
import Input from 'ui-framework/components/primitives/Input'
import Table from 'ui-framework/components/patterns/Table'

import BaseComponentProps from 'common/BaseComponentProps'

import { capitalizeFirstLetter } from 'helpers/utils'
import { TableHeaderItems } from 'ui-framework/components/patterns/Table/TableHeaders'
import { chakra, Text } from '@chakra-ui/react'
import PageTabbedLayout from 'ui-framework/components/layout/PageTabbedLayout'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import Chips from 'ui-framework/components/primitives/Chips'
import { TabItem } from 'ui-framework/components/primitives/Tabs'
import { ResultDoc } from 'hooks/api/documents/useGetDocs'
import { getDictiName, DocumentStatus } from 'constants/dicti'
import {
  DocumentWithFiles,
  useDownloadFiles,
} from 'hooks/api/documents/useDownloadFiles'
import useDocumentCenterDownloadAllDialog from './useDocumentCenterDownloadAllDialog'
import { useAppConfig } from 'hooks/useApplicationContext'
import { useMyDocuments } from 'hooks/api/my/MyDocumentsContext'
import useQueryParamState from 'hooks/useQueryParamState'

//===================================================
type DocumentCenterProps = {} & BaseComponentProps

const TAB_ALL: string = 'all',
  tabsConstants = [TAB_ALL, ...Object.values(DocumentStatus)],
  filterByTab = activeTab => doc =>
    activeTab && activeTab !== TAB_ALL
      ? getDictiName(doc.statusCS) === activeTab
      : true
/**
 * DocumentCenter component
 */
const DocumentCenter = (props: DocumentCenterProps) => {
  const appconf = useAppConfig(),
    { downloadFilesHandler, downloadDialog, blockedPopupIssueDialog } =
      useDownloadFiles(),
    [activeTab, handleTabChange] = useQueryParamState('tab', TAB_ALL),
    [search, handleSearchChange] = useQueryParamState('search', undefined),
    {
      data: { dc: items },
      isPending,
    } = useMyDocuments(),
    filteredItems = useMemo(() => {
      const getLower = val => String(val || '').toLowerCase(),
        searchLower = getLower(search),
        filterBySearch = item =>
          searchLower ? getLower(item.title).indexOf(searchLower) > -1 : true
      return items!
        .reduce((acc, doc) => {
          acc.push({
            ...doc,
            documents: doc.documents.filter(filterByTab(activeTab)),
          })

          return acc
        }, [] as ResultDoc[])
        .filter(filterBySearch)
        .filter(doc => doc.documents.length)
    }, [items, search, activeTab]),
    downloadItems = useMemo(
      () =>
        items!.reduce((acc, docs) => {
          docs.documents.filter(filterByTab(activeTab)).forEach(doc => {
            if (doc.files.length) {
              acc.push({
                docTitle: docs.title,
                ...doc,
              })
            }
          })

          return acc
        }, [] as DocumentWithFiles[]),
      [activeTab, items]
    ),
    hasGroupDownload = useMemo(() => downloadItems.length > 1, [downloadItems]),
    downloadAllDialog = useDocumentCenterDownloadAllDialog(
      hasGroupDownload,
      appconf.fiscalYear,
      () => downloadFilesHandler(downloadItems)
    ),
    headers: TableHeaderItems = [
      { text: '', width: '40px' },
      {
        text: 'Address & Forms',
        path: 'owner.name',
        width: '45%',
      },
      { text: 'Status', path: 'status.name', width: '40%' },
      {
        text: '',
        width: '170px',
        children: hasGroupDownload && (
          <Button
            size="sm"
            width="100%"
            onClick={() => downloadFilesHandler(downloadItems)}
          >
            Group Download
          </Button>
        ),
      },
      { text: '', width: '80px' },
    ],
    getStatusChip = useCallback(docStatus => {
      const createChip = (variant?: string) => (
        <Chips variant={variant} prefix>
          {getDictiName(docStatus)}
        </Chips>
      )
      switch (docStatus) {
        case DocumentStatus.Downloaded:
          return createChip()
        case DocumentStatus.Received:
          return createChip('green')
        case DocumentStatus.DownloadNow:
          return createChip('orange')
        case DocumentStatus.Uploaded:
          return createChip('gray')
      }
    }, []),
    counts = useMemo<{ [key: string]: number | undefined } | undefined>(() => {
      const totalDocs = items?.reduce((acc, i) => {
        acc += i?.documents?.length

        return acc
      }, 0)
      return items?.reduce(
        (acc, item) => (
          item?.documents?.forEach(el => {
            const tabId = getDictiName(el.statusCS)

            return acc[tabId] ? (acc[tabId] += 1) : (acc[tabId] = 1)
          }),
          acc
        ),
        { all: totalDocs }
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredItems]),
    tabs = useMemo<TabItem[]>(
      () =>
        tabsConstants.map(tabConst => {
          const id = getDictiName(tabConst, tabConst)
          const title = capitalizeFirstLetter(id)
          const count = counts?.[id]

          return {
            id,
            title,
            label: count,
            hidden: !count,
          } as TabItem
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filteredItems, counts]
    )

  // if filteredItems is empty change tab to all
  useEffect(() => {
    if (!filteredItems.length) {
      handleTabChange(TAB_ALL)
    }
  }, [filteredItems, handleTabChange])

  return (
    <PageTabbedLayout
      title="Document Center"
      mainActions={
        <Input
          value={search as string}
          showClearAfter={2}
          width={`448px`}
          prefix={<Icon>search</Icon>}
          placeholder="Search"
          onChange={handleSearchChange}
        />
      }
      tabs={tabs}
      onTabChange={handleTabChange}
      activeTab={activeTab as string}
    >
      <Table
        isSticky
        items={filteredItems}
        headers={headers}
        pending={isPending}
        hasSearch={search ? Boolean(String(search).length) : false}
        emptyMessage={{
          title: 'There are no documents yet',
          message: 'Check this page later.',
        }}
      >
        {item => {
          return (
            <>
              <chakra.tr
                sx={{
                  bgColor: 'secondary.sideMenu',
                  height: '56px',
                  pointerEvents: 'none',
                }}
                key={item.parentId}
              >
                <td></td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Text textStyle="body.semibold" lineHeight="20px">
                    {item.title}
                  </Text>
                </td>
                <td colSpan={3}></td>
              </chakra.tr>
              {item.documents.map((doc, i, arr) => {
                const { typeCS, statusCS } = doc,
                  isLastRow = i === arr.length - 1,
                  conditionCell = isLastRow ? <td></td> : <CellWithoutBorder />

                return (
                  <tr key={typeCS}>
                    {conditionCell}
                    <td>{getDictiName(typeCS)}</td>
                    <td>{getStatusChip(statusCS)}</td>
                    <td>
                      <Button
                        size="sm"
                        w={`100%`}
                        variant={'secondary'}
                        disabled={!doc.files?.length}
                        onClick={() =>
                          downloadFilesHandler({
                            docTitle: item.title,
                            ...doc,
                          })
                        }
                      >
                        Download
                      </Button>
                    </td>
                    {conditionCell}
                  </tr>
                )
              })}
            </>
          )
        }}
      </Table>
      {downloadDialog}
      {blockedPopupIssueDialog}
      {downloadAllDialog}
    </PageTabbedLayout>
  )
}

export default DocumentCenter
