import React from 'react'
import {
  OnChangeHandler,
  ChakraComponentProps,
  OnClickHandler,
} from 'ui-framework/common/types'
import { getCallback, isCallback, IsAdmin } from 'helpers/utils'
import {
  useMultiStyleConfig,
  Box,
  HStack,
  Divider,
  Center,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'

import Button from 'ui-framework/components/primitives/Button'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import Checkbox from 'ui-framework/components/primitives/Checkbox'

type BackAndNextProps = {
  backButtonText: string
  nextButtonText: string
  showBack: boolean
  onSave?: OnClickHandler
  onClick: OnClickHandler
  onBackClick?: OnClickHandler
  nextPending?: boolean
  disabled?: boolean
  isLastStep?: boolean
  autosave?: boolean
  onChangeAutosave?: OnChangeHandler<boolean>
  hasChanges?: boolean
  onImport?: OnClickHandler
  onResetChanges?: OnClickHandler
  promptCfg?: TooltipProps
} & ChakraComponentProps

const BackAndNext = (props: BackAndNextProps) => {
  const {
      backButtonText,
      nextButtonText,
      showBack,
      onClick,
      onBackClick,
      onSave,
      nextPending,
      disabled,
      autosave = true,
      onChangeAutosave,
      hasChanges = false,
      onImport,
      onResetChanges,
      promptCfg = {},
    } = props,
    style = useMultiStyleConfig('MGNYBackAndNext', props),
    admin = IsAdmin()

  return (
    <Box sx={style} className={props.className}>
      <HStack spacing="20px">
        {admin && isCallback(onImport) && (
          <Button variant="primary" onClick={onImport}>
            Import
          </Button>
        )}
        {admin && isCallback(onChangeAutosave) && (
          <Checkbox checked={autosave} onChange={onChangeAutosave}>
            Enable autosave of changes
          </Checkbox>
        )}
        {hasChanges && (
          <Button variant="secondary" onClick={getCallback(onResetChanges)}>
            Reset changes in this step
          </Button>
        )}
      </HStack>
      <HStack spacing="20px">
        {admin && !autosave && hasChanges && (
          <>
            <Button variant="primary" onClick={getCallback(onSave)}>
              Save changes
            </Button>
            <Center height="40px">
              <Divider orientation="vertical" />
            </Center>
          </>
        )}
        {showBack && (
          <Button
            prefix="left"
            variant="secondary"
            w={'85px'}
            onClick={getCallback(onBackClick)}
          >
            {backButtonText}
          </Button>
        )}
        {props.children}
        <Tooltip
          hasArrow
          hidden
          bg={'white100'}
          color={'fontnavy'}
          textStyle="caption.regular"
          p="8px"
          borderRadius="8px"
          margin="0"
          {...promptCfg}
        >
          <div key="next">
            <HelpTarget name="$next" area="tl" direction="br">
              <Button
                variant={
                  autosave && !(admin && isCallback(onImport))
                    ? 'primary'
                    : 'secondary'
                }
                pending={nextPending}
                disabled={disabled || nextPending}
                name="nextButton"
                onClick={onClick}
              >
                {nextButtonText}
              </Button>
            </HelpTarget>
          </div>
        </Tooltip>
      </HStack>
    </Box>
  )
}

export default BackAndNext
