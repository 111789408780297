import React, { useState } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import MonthView from './MonthView'

import dayjs, { Dayjs } from 'dayjs'
import { OnChangeHandler } from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'
import ShifterUI from 'ui-framework/components/primitives/Shifter/ShifterUI'
import { InputState } from '../Input/styles/InputStyles'
import {
  useMultiStyleConfig,
  Box,
  Divider,
  createStylesContext,
} from '@chakra-ui/react'

//===================================================

const [CalendarStylesProvider, useCalendarStyles] = createStylesContext(
  'CalendarStyleContext'
)

export type CalendarMessage = {
  type?: InputState
  title?: string
  description: string
}

type Props = {
  value?: Date | null
  endValue?: Date
  interval?: boolean
  defaultDate?: Date
  max?: Date
  min?: Date
  isPeriodLeft?: boolean
  isPeriodRight?: boolean
  period?: any
  onViewChange?: OnChangeHandler<Date>
  onChange?: OnChangeHandler<Date>
} & BaseComponentProps

/**
 * Calendar component
 */
const Calendar = (props: Props) => {
  const {
    value,
    endValue,
    interval,
    defaultDate,
    max,
    min,
    onViewChange,
    onChange,
    period,
  } = props
  const styles = useMultiStyleConfig('MGNYCalendar', props)
  const [dateSelected, dateSelectedSet] = useState<Dayjs>(dayjs(defaultDate))
  // useEffect(() => {
  //   dateSelectedSet(dayjs(defaultDate))
  // }, [defaultDate])

  const handleChange = (delta, type) => {
    const ds = dateSelected.add(delta, type)
    dateSelectedSet(ds)
    getCallback(onViewChange)(ds)
  }

  const checkDates = (delta, type) => {
    const d = dateSelected.add(delta, type).startOf('month')
    const maxMonthStart = max && dayjs(max).startOf('month')
    const minMonthStart = min && dayjs(min).startOf('month')

    return (
      (maxMonthStart && d.isAfter(maxMonthStart)) ||
      (minMonthStart && d.isBefore(minMonthStart))
    )
  }

  return (
    <Box
      className={props.className}
      sx={styles.calendarWrapper}
      ref={props.ref}
    >
      <Box as={`div`} style={{ position: 'relative' }}>
        <ShifterUI
          disabledPrev={checkDates(-1, 'month')}
          disabledNext={checkDates(1, 'month')}
          onPrev={() => handleChange(-1, 'month')}
          onNext={() => handleChange(1, 'month')}
        >
          {dateSelected.format('MMMM YYYY')}
        </ShifterUI>
        <Divider margin={`16px 0`} />
        <CalendarStylesProvider value={styles}>
          <MonthView
            value={value}
            endValue={endValue}
            interval={interval}
            min={min}
            max={max}
            year={dateSelected.year()}
            month={dateSelected.month()}
            onChange={getCallback(onChange)}
            period={period}
          />
        </CalendarStylesProvider>
      </Box>
    </Box>
  )
}

export default Calendar
export { useCalendarStyles }
