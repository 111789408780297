import { ComponentStyleConfig } from '@chakra-ui/theme'
import Link from 'ui-framework/components/primitives/Link'

const TableStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['wrapper', 'boxTable'],
  baseStyle: props => ({
    wrapper: {},
    boxTable: {
      width: '100%',
      tableLayout: 'fixed',
      backgroundColor: 'white100',
      textStyle: 'body.regular',
      borderSpacing: 0,
      borderCollapse: 'separate',
      thead: {
        position: props.isSticky ? 'sticky' : '',
        top: props.isSticky ? '0' : '',
        zIndex: props.isSticky ? '1' : '',
        backgroundColor: props.isSticky ? 'white100' : '',
        tr: {
          wordWrap: 'normal',
          color: 'fontandicongray',
          td: {
            borderBottom: '1px solid',
            borderColor: 'secondary.divider',
          },
        },
        'td, th': {
          wordWrap: 'normal',
          verticalAlign: 'bottom',
          paddingBottom: '12px',
          textStyle: 'body.regular',
        },
      },
      tbody: {
        minHeight: '80px',
        tr: {
          _hover: {
            'td:not(.nohovered)': {
              bgColor: '#F6F8FF',
            },
          },
          '&.noborder > td': {
            border: 'none !important',
          },
          '&:last-of-type': {
            td: {
              border: 'none',
            },
          },
          td: {
            color: 'fontnavy',
            borderBottom: '1px solid',
            borderColor: 'secondary.divider',
          },
          [Link as any]: {
            color: 'primary.base',
          },
        },
      },
      tfoot: {
        td: {
          borderBottom: '1px solid',
          borderColor: 'secondary.divider',
        },
        'tr:first-of-type': {
          td: {
            borderTop: '1px solid',
            borderColor: 'secondary.divider',
          },
        },
        'tr:last-of-type': {
          td: {
            borderBottom: 'none',
          },
        },
      },
    },
  }),
  // The size styles for each part
  sizes: {
    md: props => ({
      wrapper: {},
      boxTable: {
        thead: {
          'td,th': {
            height: props.rowsHeadingHeight || 'auto',
            paddingBottom: props.rowsHeadingHeight ? '0' : '12px',
          },
        },
        'tbody, tfoot': {
          td: {
            paddingTop: props.rowsHeight ? '0' : '12px',
            paddingBottom: props.rowsHeight ? '0' : '12px',
          },
          tr: {
            height: props.rowsHeight || 'auto',
          },
        },
        tr: {
          //TODO: remove empty cells on tables sides in order to be able to unify and sumplify table component
          //TODO: remove this overheaded -n+2 code after removing side empty cells in tables
          'td,th': {
            '&:last-of-type, &:first-of-type': {
              paddingLeft: '0',
              paddingRight: '0',
            },
            '&:nth-last-of-type(-n+2)': {
              paddingRight: '0',
            },
            '&:nth-of-type(-n+2)': {
              paddingLeft: '0',
            },
            '&:not(:nth-of-type(-n+2)):not(:last-of-type)': {
              paddingLeft: props.cellSidePaddings || '0',
            },
            '&:not(:nth-last-of-type(-n+2)):not(:first-of-type)': {
              paddingRight: props.cellSidePaddings || '0',
            },
          },
          [Link as any]: {
            fontSize: 'body',
          },
        },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default TableStyle
