import { useState, useMemo, useEffect } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'
import { AnyFunction, getCallback } from 'helpers/utils'
import { ReactChild, useCallback } from 'react'
import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import DownloadAllBg from './bg.png'
import createPersistedState from 'use-persisted-state'

const useDownloadAllDialog = createPersistedState(
  `document_center_download_all_dialog`
)

const useDocumentCenterDownloadAllDialog = (
  conditionToShow: boolean,
  valueToShow: any,
  onAction: AnyFunction
): ReactChild | false => {
  const downloadAllDialog = useDisclosure(),
    [downloadAllValue, downloadAllValueSet] = useDownloadAllDialog(),
    [showDownloadAllDialog, showDownloadAllDialogSet] = useMemo(() => {
      return [
        downloadAllValue !== valueToShow,
        () => downloadAllValueSet(valueToShow),
      ]
    }, [valueToShow, downloadAllValue, downloadAllValueSet]),
    [downloadAllShown] = useState<boolean>(false),
    needToShow = useMemo(
      () => conditionToShow && showDownloadAllDialog && !downloadAllShown,
      [conditionToShow, downloadAllShown, showDownloadAllDialog]
    ),
    handleOnAction = useCallback(() => {
      getCallback(onAction)()
      getCallback(showDownloadAllDialogSet)()
      downloadAllDialog.onClose()
    }, [onAction, downloadAllDialog, showDownloadAllDialogSet])

  useEffect(() => {
    if (needToShow) {
      // downloadAllShownSet(!downloadAllShown)
      getCallback(downloadAllDialog.onOpen)()
    }
  }, [needToShow, downloadAllDialog.onOpen, downloadAllShown])

  return (
    needToShow && (
      <ModalDialog
        key="downloadAllDialog"
        isOpen={downloadAllDialog.isOpen}
        onAction={handleOnAction}
        actionText={`Download All Files`}
        title={<Image src={DownloadAllBg} height={'377px'} mt={'-5px'} />}
        closeOnOverlayClick
        onClose={downloadAllDialog.onClose}
        size="w540"
        height={'600px'}
        footerActions={
          <Button
            tabIndex={1}
            variant={`textPrimary`}
            onClick={downloadAllDialog.onClose}
            m={`0 0 0 auto`}
          >
            Do it later
          </Button>
        }
      >
        <Text
          textStyle={'body.semibold'}
          lineHeight="33px"
          margin="0 0 16px"
          fontSize={'22px'}
        >
          One Click Download
        </Text>
        <Text lineHeight="25px">
          Make your life easier with our new <b>Group Download</b> feature.
          Download documents in one file for all lots or for each one
          separately. This will help save you time.
        </Text>
      </ModalDialog>
    )
  )
}

export default useDocumentCenterDownloadAllDialog
