type ApplicationData = { [key: string]: any }

function getFloorsArray(maxFloor: number): string[] {
  return [
    ...Array.from({ length: maxFloor }, (_, i) => String(i + 1)),
    'Basement',
    'Cellar',
    'Main/Ground',
    'Mezzanine',
    'Penthouse',
    'Roof',
    'Ground',
    'Subcellar',
  ]
}

function constNameComapreFn(value, selected) {
  return value && selected && value.constName === selected.constName
}

const getShortAddress = ({ bblsData = null }: ApplicationData) =>
  bblsData && `${bblsData?.houseNum || ''} ${bblsData?.street || ''}`

const getBBLString = ({ bblsData = null }: ApplicationData) =>
  bblsData &&
  `${bblsData?.boroughName || ''}/${bblsData?.block || ''}/${
    bblsData?.lot || ''
  }`.trim()

const getBBLWithAddressString = ({ bblsData = null }: ApplicationData) =>
  bblsData &&
  `${getBBLString({ bblsData })} - ${getShortAddress({ bblsData })}`.trim()

export {
  getFloorsArray,
  constNameComapreFn,
  getBBLString,
  getBBLWithAddressString,
  getShortAddress,
}
