import { useEffect } from 'react'
import { AnyFunction } from 'helpers/utils'
import { refObject } from 'ui-framework/common/types'

const useOnClickOutside = (
  refs: refObject[] | refObject,
  handler: AnyFunction
) => {
  useEffect(() => {
    const listener = event => {
      const refsInternal = Array.isArray(refs) ? refs : [refs]
      const exist = refsInternal.some(
        el => !el.current || el.current.contains(event.target)
      )
      !exist && handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [refs, handler])
}

export default useOnClickOutside
