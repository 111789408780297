import React, {
  useRef,
  useMemo,
  useEffect,
  ReactNode,
  useCallback,
} from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import PageTitle from 'ui-framework/components/patterns/PageTitle'
import { Divider, Flex, Box } from '@chakra-ui/react'
import HelpBlock from 'ui-framework/components/patterns/HelpBlock'
import MultisectionalProgress, {
  NavBarSectionItems,
} from 'ui-framework/components/patterns/MultisectionalProgress'
import useArrowHook from 'ui-framework/components/patterns/Wizard/useArrowHook'
import { Step } from 'hooks/wizard/useStepsConfig'
import Button from 'ui-framework/components/primitives/Button'
import { AnyFunction, isCallback } from 'helpers/utils'
import Loader from 'ui-framework/components/primitives/Loader'
import { useMainMenu } from 'hooks/ui/MainMenuProvider'
import { useNavigate } from 'react-router-dom'

//==================================================
type Props = {
  title: string | ReactNode
  subtitle: string
  backLinkOrCallback: string | AnyFunction
  pending: boolean
  stepsSections: any
  currentStepIndex: number
  currentStep?: Step
  propertyPringlsLine?: ReactNode | boolean
  isCurrentStepValid?: boolean
  assistantHeader?: string
  navigationBarCfg?: NavBarSectionItems
} & BaseComponentProps

/**
 * WizardLayout component
 */
const WizardLayout = ({
  title,
  subtitle,
  backLinkOrCallback,
  pending,
  stepsSections,
  currentStepIndex,
  currentStep,
  propertyPringlsLine,
  isCurrentStepValid,
  navigationBarCfg,
  ...props
}: Props) => {
  const menu = useMainMenu(),
    navigate = useNavigate(),
    focusedRef = useRef<HTMLElement | null>(null),
    [focusedName] = useArrowHook(focusedRef, currentStep?.help),
    helpProps = useMemo(() => {
      const helpType = () => {
        if (currentStep?.help) {
          if (focusedName) return 'field'
          if (isCurrentStepValid && currentStep?.help.$next) return 'next'
          return 'step'
        }
      }

      if (!currentStep?.help) return
      switch (helpType()) {
        case 'field':
          return currentStep?.help[focusedName as string]
        case 'step':
          return currentStep?.help.$step
        case 'next':
          return currentStep?.help.$next
        default:
          return {
            title: '',
            description: '',
          }
      }
    }, [focusedName, currentStep, isCurrentStepValid])

  const handleBack = useCallback(() => {
    if (isCallback(backLinkOrCallback)) {
      ;(backLinkOrCallback as AnyFunction)()
    } else {
      menu.onOpen()
      navigate(backLinkOrCallback as string)
    }
  }, [backLinkOrCallback, menu, navigate])

  useEffect(() => {
    menu.onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex direction="column" grow={1}>
      <PageTitle
        title={pending ? 'Loading...' : title}
        subtitle={subtitle}
        leftAction={
          <Button
            prefix={`left`}
            variant={`textSecondary`}
            onClick={handleBack}
          >
            Back to Property List
          </Button>
        }
        sticky
      />
      {propertyPringlsLine && (
        <>
          {propertyPringlsLine}
          <Divider />
        </>
      )}
      <Flex grow={1} justifyContent="space-between" width="100%">
        <Box width="100%">
          {!pending && (
            <Box
              paddingLeft="55px"
              mr="24px"
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Flex padding="20px 0">
                <MultisectionalProgress
                  sections={stepsSections}
                  current={currentStepIndex}
                  navigationBarCfg={navigationBarCfg}
                />
              </Flex>
              <Divider />
            </Box>
          )}
          {pending && <Loader height="300px" />}
          {!pending && props.children}
        </Box>
        <HelpBlock
          key={currentStep?.id}
          {...helpProps}
          hideHelp={currentStep?.hideHelp}
          assistantHeader={props.assistantHeader}
        />
      </Flex>
    </Flex>
  )
}

export default WizardLayout
