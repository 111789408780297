import Joi from 'joi'
import { IncomeItem } from 'hooks/api/ta/TaFormData'
import { IncomeCategories, IncomeSectionTypes } from 'constants/dicti'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

function checkRRUploadCase(value, helpers) {
  const original = helpers.prefs.context?.originalValue
  if (
    original.numberOfResidentialUnit > 0 &&
    original.isResidentialUploaded &&
    (value === IncomeCategories.ResRegulated ||
      value === IncomeCategories.ResUnregulated)
  )
    return helpers.error('any.custom')
}

function checkResUnresValues(value, helpers) {
  const r = value.some(
    (i: IncomeItem) =>
      (i.categoryCS === IncomeCategories.ResRegulated ||
        i.categoryCS === IncomeCategories.ResUnregulated) &&
      i.lastYearAmount &&
      i.lastYearAmount > 0
  )

  return r && helpers.error('any.custom')
}

const sectionItem = {
  categoryCS: schemaRules.NOT_EMPTY_STRING,
  lastYearAmount: schemaRules.NOT_EMPTY_NUMBER,
}

const mainSectionItem = {
  $show: schemaRules.BOOLEAN.optional(),
  categoryCS: schemaRules.NOT_EMPTY_STRING,
  lastYearAmount: Joi.when('..$show', {
    // should we pass income category for validation at all
    is: true, // Joi.custom(checkValueableCategory),
    then: Joi.when('categoryCS', {
      // is it RR upload case there we need to show Res and Unres categories
      is: Joi.custom(checkRRUploadCase),
      then: schemaRules.NOT_EMPTY_NUMBER,
      otherwise: Joi.when('....items', {
        // check if we shoud set optional for Res/Unres fields
        is: Joi.custom(checkResUnresValues),
        then: schemaRules.NOT_EMPTY_NUMBER,
        otherwise: schemaRules.NUMBER.optional(),
      }),
    }),
    otherwise: schemaRules.ANY,
  }),
}

const otherSectionItem = {
  categoryName: schemaRules.SOME_SYMBOL_STRING(3),
  lastYearAmount: schemaRules.NOT_EMPTY_NUMBER,
}

const incomeSectionSchema = {
  wasIncomeFromRentalTenants: schemaRules.ANY,
  typeCS: schemaRules.NOT_EMPTY_STRING,
  items: Joi.when('typeCS', {
    switch: [
      {
        is: IncomeSectionTypes.IncomeMain,
        then: schemaRules.ITEMS(mainSectionItem),
      },
      {
        is: IncomeSectionTypes.IncomeAdditional,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: IncomeSectionTypes.IncomeOther,
        then: schemaRules.ITEMS(otherSectionItem),
      },
      {
        is: IncomeSectionTypes.HotelMain,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: IncomeSectionTypes.HotelRental,
        then: Joi.when('/wasIncomeFromRentalTenants', {
          is: true,
          then: schemaRules.ITEMS(sectionItem),
          otherwise: schemaRules.ANY,
        }),
      },
      {
        is: IncomeSectionTypes.HotelOther,
        then: schemaRules.ITEMS(otherSectionItem),
      },
      {
        is: IncomeSectionTypes.CondoMain,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: IncomeSectionTypes.CondoRental,
        then: Joi.when('/wasIncomeFromRentalTenants', {
          is: true,
          then: schemaRules.ITEMS(sectionItem),
          otherwise: schemaRules.ANY,
        }),
      },
      {
        is: IncomeSectionTypes.CondoOther,
        then: schemaRules.ITEMS(otherSectionItem),
      },
    ],
    otherwise: schemaRules.ANY,
  }),
}

const incomeFillSchema = schemaWrapper({
  sections: schemaRules.ITEMS(incomeSectionSchema),
})

export default incomeFillSchema
