import React from 'react';
import './styles.css';

import { ReactComponent as Chrome } from './icons/chrome-logo.svg'
import { ReactComponent as Safari } from './icons/safari-logo.svg'
import { ReactComponent as Edge } from './icons/edge-logo.svg'
import { ReactComponent as Firefox } from './icons/firefox-logo.svg'
import { ReactComponent as Logo } from './icons/logo.svg'


const Supporting = () => {

  return (
    <div className="not-support">
      <Logo className='logo' />
      <div className="content">
        <div className="content-title">
          We are sorry, it appears you are using a browser that is not
          supported.
        </div>
        <div className="content-label">Please use one of the following:</div>
        <div className="content-toolbar">
          <a
            href="https://www.google.com/chrome/"
            className="content__toolbar-item"
            target="_blank">

            <Chrome />
            <span>Link to download</span>
          </a>
          <a
            href="https://support.apple.com/downloads/safari"
            className="content__toolbar-item"
            target="_blank">
            <Safari />
            <span>Link to download</span>
          </a>
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            className="content__toolbar-item"
            target="_blank">
            <Firefox />
            <span>Link to download</span>
          </a>
          <a
            href="https://www.microsoft.com/en-us/edge"
            className="content__toolbar-item"
            target="_blank">
            <Edge />
            <span>Link to download</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Supporting;
