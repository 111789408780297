import React, { useCallback, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { getNameAndErrorProps, onlyPositiveValue } from 'helpers/utils'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { VStack } from '@chakra-ui/react'
import StepGeneralCommercialDescriptionUseTQ from '../StepGeneralCommercialDescriptionUseTQ'
import {
  NonResidentialUse,
  Occupant,
  RentRollCommercialUnit,
  StorefrontUnit,
} from 'hooks/api/rpie/RpieFormData'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'

//===================================================
type Props = {
  numberOfCommercialUnit: number
  numberOfStorefrontUnit: number
  wasAnyNonResPortionOwnerOccupied: boolean
  wasAnyNonResPortionVacant: boolean
  uses: NonResidentialUse[]
  rentrollUnits: RentRollCommercialUnit[]
  storfrontUnits: StorefrontUnit[]
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepGeneralCommercialDescription component
 */
const StepGeneralCommercialDescription = ({
  numberOfCommercialUnit,
  numberOfStorefrontUnit,
  wasAnyNonResPortionOwnerOccupied,
  wasAnyNonResPortionVacant,
  uses,
  rentrollUnits,
  storfrontUnits,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({
        numberOfCommercialUnit,
        numberOfStorefrontUnit,
        wasAnyNonResPortionOwnerOccupied,
        wasAnyNonResPortionVacant,
        uses,
      }),
      [
        numberOfCommercialUnit,
        numberOfStorefrontUnit,
        wasAnyNonResPortionOwnerOccupied,
        wasAnyNonResPortionVacant,
        uses,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError),
    handleOnChange = useCallback(
      (value, name) => {
        const clearSF = (u, fb, fz) => ({
          ...u,
          [fb]: false,
          [fz]: 0,
        })

        if (name === 'wasAnyNonResPortionOwnerOccupied') {
          onChange({
            wasAnyNonResPortionOwnerOccupied: value,
            uses: uses.map(u =>
              !value ? clearSF(u, 'hasApplicantSF', 'sfApplicant') : u
            ),
          })
        } else if (name === 'wasAnyNonResPortionVacant') {
          onChange({
            wasAnyNonResPortionVacant: value,
            uses: uses.map(u =>
              !value ? clearSF(u, 'hasVacantSF', 'sfVacant') : u
            ),
          })
        } else onChange(value, name)
      },
      [onChange, uses]
    ),
    fillRR = () => {
      const num = onlyPositiveValue(
        numberOfCommercialUnit - numberOfStorefrontUnit
      )

      if (rentrollUnits.length !== num) {
        const emptyRRUnits = Array(Math.floor(num))
          .fill(undefined)
          .map((u, index) => ({
            unitApartmentNumber: (index + 1).toString(),
            currentlyOwnerOccupied: false,
            currentlyTenantOccupied: false,
            wasVacant: null,
            vacantMonths: null,
            floorNumber: null,
            rentableNetAreaSF: null,
            grossRentAmount: null,
            baseRentAmount: null,
            primaryBusinessActivityCS: null,
            otherPrimaryBusinessActivityDescription: null,
          }))
        onChange(emptyRRUnits, 'rentrollUnits')
      }

      if (storfrontUnits.length !== numberOfStorefrontUnit) {
        const emptySFUnits = Array(Math.floor(numberOfStorefrontUnit))
          .fill(undefined)
          .map((u, index) => ({
            isUnitApartmentNumberDifferent: false,
            isAddressDifferent: false,
            unitApartmentNumber: null,
            houseNo: null,
            streetName: null,
            descriptionTypeCS: null,
            floorSizeSF: null,
            occupants: [] as Occupant[],
          }))
        onChange(emptySFUnits, 'storfrontUnits')
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fillRR, [])

  return (
    <StepBasic className={props.className}>
      <VStack spacing="36px" align="start" w="338px">
        <ReviewLastYearDataWarningBlock />

        <HelpTarget name="numberOfCommercialUnit">
          <Input
            label="# of Commercial Units"
            maxLength={3}
            type="number"
            value={numberOfCommercialUnit}
            placeholder="Enter #"
            disabled={disabled}
            onChange={onChange}
            onBlur={fillRR}
            {...nameAndErrorProps('numberOfCommercialUnit')}
          />
        </HelpTarget>
        {numberOfCommercialUnit > 0 && (
          <HelpTarget name="numberOfStorefrontUnit">
            <Input
              label="# of Storefront Units"
              type="number"
              value={numberOfStorefrontUnit}
              placeholder="Enter #"
              disabled={disabled}
              onChange={onChange}
              onBlur={fillRR}
              maxLength={3}
              {...nameAndErrorProps('numberOfStorefrontUnit')}
            />
          </HelpTarget>
        )}
        {numberOfCommercialUnit > 0 && uses.length > 0 && (
          <>
            <HelpTarget name="wasAnyNonResPortionOwnerOccupied">
              <SelectsGroup
                label="Was any non-residential portion of the property owner-occupied or occupied 
                  by a related party?"
                error={hasError('wasAnyNonResPortionOwnerOccupied')}
              >
                <Radiobutton
                  value={wasAnyNonResPortionOwnerOccupied}
                  name="wasAnyNonResPortionOwnerOccupied"
                  selected={false}
                  disabled={disabled}
                  onChange={handleOnChange}
                >
                  No
                </Radiobutton>
                <Radiobutton
                  value={wasAnyNonResPortionOwnerOccupied}
                  name="wasAnyNonResPortionOwnerOccupied"
                  selected={true}
                  disabled={disabled}
                  onChange={handleOnChange}
                >
                  Yes
                </Radiobutton>
              </SelectsGroup>
            </HelpTarget>
            {wasAnyNonResPortionOwnerOccupied && (
              <StepGeneralCommercialDescriptionUseTQ
                usesType="owner"
                groupedKey={'floorCS'}
                uses={uses}
                onChange={handleOnChange}
                hasError={hasError}
              />
            )}
            <HelpTarget name="wasAnyNonResPortionVacant">
              <SelectsGroup
                label="Was any non-residential portion  vacant?"
                error={hasError('wasAnyNonResPortionVacant')}
              >
                <Radiobutton
                  value={wasAnyNonResPortionVacant}
                  name="wasAnyNonResPortionVacant"
                  selected={false}
                  disabled={disabled}
                  onChange={handleOnChange}
                >
                  No
                </Radiobutton>
                <Radiobutton
                  value={wasAnyNonResPortionVacant}
                  name="wasAnyNonResPortionVacant"
                  selected={true}
                  disabled={disabled}
                  onChange={handleOnChange}
                >
                  Yes
                </Radiobutton>
              </SelectsGroup>
            </HelpTarget>
            {wasAnyNonResPortionVacant && (
              <StepGeneralCommercialDescriptionUseTQ
                usesType="vacant"
                groupedKey={'floorCS'}
                uses={uses}
                hasError={hasError}
                onChange={handleOnChange}
              />
            )}
          </>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepGeneralCommercialDescription
