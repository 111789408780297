import React, { useEffect } from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'

import Loader from 'ui-framework/components/primitives/Loader'
import { ReactComponent as SuccessImage } from '../../assets/succesfulimage.svg'
import Box from 'ui-framework/components/primitives/Box'
import { useTheme } from '@emotion/react'
import { SwitchTransition } from 'react-transition-group'
import Transition, { TransitionStatus } from 'react-transition-group/Transition'
import { URL_HOME } from 'constants/URLS'
import CenterForm from './CenterForm'

//==================================================

type SuccessProps = {}
const SuccessImg = styled(SuccessImage)<SuccessProps>(props => ({
  width: '160px',
  height: '130px',
  marginLeft: '30px',
}))

type TransitionWrapperProps = { status: TransitionStatus }
const TransitionWrapper = styled('div')<TransitionWrapperProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  opacity: props.status === 'entering' ? 0 : 1,
  transition: '.2s',
}))

// ====

const FadeTransition = props => (
  <Transition
    {...props}
    appear
    addEndListener={(node, done) => {
      node.addEventListener('transitionend', done, false)
    }}
  />
)

type RegistrationProgressProps = {
  isPending: boolean
} & BaseComponentProps

/**
 * RegistrationProgress component
 */
const RegistrationProgress = ({
  isPending,
  ...props
}: RegistrationProgressProps) => {
  const theme = useTheme()

  useEffect(() => {
    if (!isPending) window.location.href = URL_HOME
  }, [isPending])

  return (
    <CenterForm className={props.className} marginTop={'200px'}>
      <SwitchTransition mode="out-in">
        <FadeTransition
          key={Number(isPending)}
          in={isPending}
          timeout={200}
          unmountOnExit
        >
          {(status: TransitionStatus) => (
            <TransitionWrapper status={status}>
              {isPending ? (
                <>
                  <Loader size="extra" />
                  <Box margin="33px 0 0 0" typography={theme.typography.body1}>
                    Hold tight! Creating your account...
                  </Box>
                </>
              ) : (
                <>
                  <SuccessImg />
                  <Box margin="33px 0 0 0" typography={theme.typography.body1}>
                    Congratulations!
                  </Box>
                  <Box
                    margin="12px 0 0 0"
                    typography={theme.typography.caption2}
                    color={theme.colors.black40}
                  >
                    <div>
                      You have created your account for the MGNY Portal!
                    </div>
                    <div>
                      You will be redirected to your Portal in a few seconds.
                    </div>
                  </Box>
                </>
              )}
            </TransitionWrapper>
          )}
        </FadeTransition>
      </SwitchTransition>
    </CenterForm>
  )
}

export default RegistrationProgress
