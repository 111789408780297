import colors from './colors'
import ButtonStyle from '../components/primitives/Button/style'
import ProgressStyles from 'ui-framework/components/primitives/Progress/styles/ProgressStyles'
import TabsStyle from '../components/primitives/Tabs/style'
import TabStyle from '../components/primitives/Tabs/Tab/style'
import AbstractInputStyles from 'ui-framework/components/primitives/AbstractInput/styles/AbstractInputStyles'
import ChipsStyle from 'ui-framework/components/primitives/Chips/style'
import BackAndNextStyles from '../components/layout/BackAndNext/style'
import MainLayoutStyle from 'ui-framework/components/layout/MainLayout/style'
import MainItemStyle from 'ui-framework/components/layout/MainLayout/styles/MenuItem'
import UserInfoStyle from 'ui-framework/components/primitives/UserInfo/style'
import IconStyle from 'ui-framework/components/primitives/Icon/styles/IconStyle'
import PageTitleStyle from 'ui-framework/components/patterns/PageTitle/style'
import HelpBlockStyle from 'ui-framework/components/patterns/HelpBlock/style'
import TableStyle from 'ui-framework/components/patterns/Table/style'

import IntervalItemStyle from 'ui-framework/components/patterns/IntervalBar/style'
import CheckboxStyle from 'ui-framework/components/primitives/Checkbox/styles/CheckboxStyle'
import SelectsGroupStyle from 'ui-framework/components/primitives/SelectsGroup/styles/SelectsGroupStyle'
import DropdownListStyle from 'ui-framework/components/primitives/DropdownList/styles/DropdownListStyle'
import EmptyMessageStyle from 'ui-framework/components/primitives/EmptyMessage/style'
import CalendarStyle from 'ui-framework/components/primitives/Calendar/style'
import ShifterStyle from 'ui-framework/components/primitives/Shifter/style'
import ErrorInfoStyle from 'ui-framework/components/primitives/ErrorWithInfo/style'
import OnboardingDialogStyle from 'ui-framework/components/primitives/OnboardingDialog/style'
import TAOnboardingBodyStyle from 'services/ta/onboarding/style'
import RPIEOnboardingBodyStyle from 'services/rpie/onboarding/style'
import ModalDialogStyle from 'ui-framework/components/primitives/ModalDialog/style'
import { MGNYInput } from 'ui-framework/components/primitives/Input/styles/InputStyles'
import PromptStyle from 'ui-framework/components/primitives/Prompt/style'
import AvatarStyle from 'ui-framework/components/primitives/AvatarUI/style'
import LinkStyle from 'ui-framework/components/primitives/Link/style'
import AttentionBoxStyle from 'ui-framework/components/primitives/AttentionBox/style'
import InformationDialogStyle from 'ui-framework/components/primitives/InformationDialog/style'
import CardStyle from 'ui-framework/components/primitives/Card/style'
import NotificationBarStyle from 'ui-framework/components/patterns/NotificationBar/style'

const componentsStyles = {
  Popover: {
    parts: [],
    baseStyle: {
      popper: {
        width: 'unset',
        zIndex: '1400', //Case: in modal window, popover like in dropdown isn't visible
        // maxWidth: 'none',
      },
      content: {
        '& > *': {
          border: `1px solid ${colors.gray1}`,
          boxShadow: `1px 1px 4px rgba(0, 0, 0, 0.1)`,
          borderRadius: '2px',
        },
        border: 'none',
        background: 'none',
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
  },
  Drawer: {
    variants: {
      small: {
        dialogContainer: {
          width: 264,
        },
        dialog: {
          maxW: 264,
        },
      },
    },
  },
  Divider: {
    baseStyle: {
      borderColor: 'secondary.divider',
    },
  },
  MGNYButton: ButtonStyle,
  MGNYBackAndNext: BackAndNextStyles,
  MGNYProgress: ProgressStyles,
  MGNYTabs: TabsStyle,
  MGNYTab: TabStyle,
  MGNYAbstractInput: AbstractInputStyles,
  MGNYChips: ChipsStyle,
  MGNYIcon: IconStyle,
  MGNYPageTitle: PageTitleStyle,
  MGNYHelpBlock: HelpBlockStyle,
  MGNYMainLayout: MainLayoutStyle,
  MGNYUserInfo: UserInfoStyle,
  MGNYMainItem: MainItemStyle,
  MGNYTable: TableStyle,
  MGNYIntervalItem: IntervalItemStyle,
  MGNYSelectsGroup: SelectsGroupStyle,
  MGNYCheckbox: CheckboxStyle,
  MGNYDropdownList: DropdownListStyle,
  MGNYEmptyMessage: EmptyMessageStyle,
  MGNYCalendar: CalendarStyle,
  MGNYShifter: ShifterStyle,
  MGNYErrorInfo: ErrorInfoStyle,
  MGNYOnboardingDialog: OnboardingDialogStyle,
  MGNYTAOnboardingBody: TAOnboardingBodyStyle,
  MGNYRPIEOnboardingBody: RPIEOnboardingBodyStyle,
  MGNYModalDialog: ModalDialogStyle,
  MGNYInput: MGNYInput,
  MGNYPrompt: PromptStyle,
  MGNYAvatar: AvatarStyle,
  MGNYLink: LinkStyle,
  MGNYAttentionBox: AttentionBoxStyle,
  MGNYInformationDialog: InformationDialogStyle,
  MGNYNotificationBar: NotificationBarStyle,
  MGNYCard: CardStyle,
}

export default componentsStyles
