import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { OnClickHandler } from 'ui-framework/common/types'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import Button from 'ui-framework/components/primitives/Button'
import { Box, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/react'
import Img from './img.jpg'

//===================================================
type FilingDeadlineWarningDialogProps = {
  isOpen: boolean
  message: string
  onClose: OnClickHandler
} & BaseComponentProps

/**
 * FilingDeadlineWarningDialog component
 */
const FilingDeadlineWarningDialog = (
  props: FilingDeadlineWarningDialogProps
) => {
  return (
    <ModalDialog
      size="w560"
      isOpen={props.isOpen}
      footerActions={
        <Button w={'100%'} onClick={props.onClose}>
          Got It
        </Button>
      }
      className={props.className}
    >
      <Image position="absolute" top="0" left="0" src={Img} />
      <Box height="220px" />
      <Text align="center" textStyle="h6" mt="16px">
        Filing Deadline is Approaching
      </Text>
      <Text align="center" mt="12px">
        Provide info for all properties by <b>{props.message}</b>!
      </Text>
    </ModalDialog>
  )
}

export default FilingDeadlineWarningDialog
