import React, { useMemo } from 'react'

import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Box from 'ui-framework/components/primitives/Box'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'

import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import styled from '@emotion/styled/macro'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { useDocumentFiles } from 'hooks/api/documents/useDocumentFiles'
import { getCallback } from 'helpers/utils'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import useStepValidation from '../useStepValidation'
import { DocumentType } from 'constants/dicti'
//====================================================
type Props = {
  fillingType: boolean
  docName?: string
  completeFillingText?: string
  infoText?: string
  questionText?: string
  docType: DocumentType
} & BaseComponentProps &
  StepPropsInterface

type TextWrapperProps = {}
const TextWrapper = styled('p')<TextWrapperProps>(props => ({
  fontFamily: (props.theme as any).fonts.heading,
  fontSize: (props.theme as any).fontSizes.body,
  color: (props.theme as any).colors.fontnavy,
  marginBottom: '36px',
  maxWidth: '508px',
}))

const schema = schemaWrapper({
  fillingType: schemaRules.BOOLEAN,
})

/**
 * StepFillingTypeSelection component
 */
const StepFillingTypeSelection = ({
  questionText,
  docName,
  completeFillingText = 'Complete Manually',
  infoText,
  fillingType,
  applyValidation,
  onValidation,
  disabled,
  docType,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { removeOrAddDoc } = useDocumentFiles(context?.ref, docType),
    handleChange = (v, name) => {
      getCallback(onChange)(v, name)
      removeOrAddDoc(v)
    },
    validationData = useMemo(
      () => ({
        fillingType,
      }),
      [fillingType]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )
  return (
    <StepBasic className={props.className}>
      <Box>
        {infoText && <TextWrapper>{infoText}</TextWrapper>}
        <SelectsGroup
          variant="horizontal"
          label={questionText}
          error={hasError('fillingType')}
        >
          <HelpTarget name="manual" area="tr" flexGrow={0}>
            <Radiobutton
              name="fillingType"
              value={fillingType}
              selected={false}
              onChange={handleChange}
              disabled={disabled}
            >
              {completeFillingText}
            </Radiobutton>
          </HelpTarget>
          <HelpTarget name="upload" area="tr" flexGrow={0}>
            <Radiobutton
              name="fillingType"
              value={fillingType}
              selected={true}
              onChange={handleChange}
              disabled={disabled}
            >
              {`Upload ${docName || ''}`}
            </Radiobutton>
          </HelpTarget>
        </SelectsGroup>
      </Box>
    </StepBasic>
  )
}

export default StepFillingTypeSelection
