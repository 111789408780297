import React from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { Flex, HStack } from '@chakra-ui/layout'
import { chakra } from '@chakra-ui/system'

//===================================================

export type AffixProps = {
  spacing?: string | number
  prefix?: React.ReactNode
  postfix?: React.ReactNode
  wide?: boolean
} & BaseComponentProps

/**
 * Affix component
 */
const Affix = ({
  wide = false,
  spacing = '10px',
  prefix,
  postfix,
  ...props
}: AffixProps) => {
  return (
    <HStack
      className={props.className}
      width={wide ? '100%' : 'auto'}
      align="center"
    >
      {prefix && <Flex data-type="prefix">{prefix}</Flex>}
      <Flex grow={1} width={wide ? '100%' : 'unset'} data-type="content">
        {props.children}
      </Flex>
      {postfix && <Flex data-type="postfix">{postfix}</Flex>}
    </HStack>
  )
}

export default chakra(Affix)

// spacing 12px
