import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods } from 'helpers/utils'

//===================================================
type HelpDetailsResidRentWereVacantHelpProps = {} & BaseComponentProps

/**
 * HelpDetailsResidRentWereVacantHelp component
 */
const HelpDetailsResidRentWereVacantHelp = (
  props: HelpDetailsResidRentWereVacantHelpProps
) => {
  const { context } = useServiceWizard(),
    { reportingYear, fiscalYear } = getFiscalPeriods(context)
  return (
    <>
      <p>
        If vacancy was ongoing during prior year ({reportingYear}) - but the
        property is no longer vacant as of January 5, {fiscalYear} - do not
        report it in this section!
      </p>
      <p>
        Ongoing vacancy in {reportingYear} should be reported in the prior
        “General Info” section and on this page under the "Residential Rent
        Information."
      </p>
    </>
  )
}

export default HelpDetailsResidRentWereVacantHelp
