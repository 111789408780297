import { useState, useMemo, useCallback, useEffect, ReactChild } from 'react'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'

import { useDisclosure } from '@chakra-ui/react'
import { AnyFunction, getCallback } from 'helpers/utils'

import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import ErrorWithInfo from 'ui-framework/components/primitives/ErrorWithInfo'
import { get } from 'object-path-immutable'

type EditRowModal = {
  schema: any
  validationData: object
  getEditContent: AnyFunction
} & StepPropsInterface

const useEditRowModal = ({
  schema,
  onValidation,
  applyValidation,
  validationData = {},
  onChange,
  getEditContent,
}: EditRowModal): [
  (unit: any, validationError: any) => ReactChild,
  AnyFunction,
  ReactChild
] => {
  const editRowModal = useDisclosure()
  const [unit, unitSet] = useState<object | undefined>(undefined),
    [trySave, trySaveSet] = useState<boolean>(false),
    [checkValidation, checkValidationSet] = useState<boolean>(applyValidation),
    editingValidationData = useMemo(
      () => ({ ...validationData, unit }),
      [unit, validationData]
    ),
    hasError = useStepValidation(
      editingValidationData,
      schema,
      checkValidation || applyValidation,
      onValidation
    ),
    validation = hasError(`unit`),
    getError = useCallback(name => get(validation, name), [validation])

  const clearDataAndClose = useCallback(() => {
      unitSet(undefined)
      checkValidationSet(false)
      editRowModal.onClose()
    }, [editRowModal]),
    handleAction = useCallback(() => {
      !checkValidation && checkValidationSet(true)
      trySaveSet(true)
    }, [checkValidation]),
    handleDataSave = useCallback(() => {
      getCallback(onChange)(unit)
      clearDataAndClose()
    }, [unit, clearDataAndClose, onChange])

  const handleChange = useCallback(
      (value, name) =>
        unitSet(
          !name
            ? value
            : {
                ...unit,
                [name]: value,
              }
        ),
      [unit]
    ),
    handleEdit = useCallback(
      unit => () => {
        unitSet(unit)
        checkValidationSet(false)
        editRowModal.onOpen()
      },
      [editRowModal]
    )

  const getEditButton = useCallback(
      (unit, validationError) => {
        const error = !editRowModal.isOpen && applyValidation && validationError

        return (
          <ErrorWithInfo
            hasError={error}
            rightPosition={'0'}
            message={error}
            tooltipParams={{
              maxW: '250px',
            }}
          >
            <Button variant={'textPrimary'} onClick={handleEdit(unit)}>
              Edit
            </Button>
          </ErrorWithInfo>
        )
      },
      [editRowModal, handleEdit, applyValidation]
    ),
    { title, body } = useMemo(
      () => getEditContent(unit, getError, handleChange),
      [unit, getError, getEditContent, handleChange]
    )

  useEffect(() => {
    if (trySave) {
      !validation && getCallback(handleDataSave)()
      trySaveSet(false)
    }
  }, [trySave, validation, handleDataSave])

  return [
    getEditButton,
    hasError,
    <ModalDialog
      isLazy
      title={title}
      key={'editRowModal'}
      isOpen={editRowModal.isOpen}
      size={'w484'}
      footerActions={
        <Button variant={'textPrimary'} onClick={clearDataAndClose}>
          Cancel
        </Button>
      }
      onAction={handleAction}
      actionText={`Save Changes`}
    >
      {body}
    </ModalDialog>,
  ]
}

export default useEditRowModal
