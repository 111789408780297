import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'
import { checkAtLeastOne } from 'services/common/validationHelpers'

const customCheckAtSomeOne =
    (totalTitle, fieldName, path, subtractApplicant = false) =>
    (value, helpers) => {
      const { originalValue } = helpers.prefs.context,
        checkValue = use =>
          use.sfTotal - (subtractApplicant ? use.sfApplicant : 0) < value &&
          use[fieldName] === value,
        notValid = originalValue.uses.filter(checkValue).length,
        someNotZero = originalValue.uses.some(use => use[fieldName] !== 0),
        errorMsg = `Must be ${
          value === 0 ? 'greater 0' : `less than or equal ${totalTitle}`
        }`

      if (originalValue[path] && (notValid || !someNotZero))
        return helpers.message(errorMsg)
    },
  checkAtLeastOneUseTypeMsg = {
    'any.custom': 'At least one Use Type must be selected',
  }

const items = {
  hasApplicantSF: Joi.when('....wasAnyNonResPortionOwnerOccupied', {
    is: true,
    then: schemaRules.BOOLEAN.custom(
      checkAtLeastOne('uses', 'hasApplicantSF')
    ).messages(checkAtLeastOneUseTypeMsg),
    otherwise: schemaRules.ANY,
  }),
  sfApplicant: schemaRules.NUMBER.custom(
    customCheckAtSomeOne(
      'Total SF',
      'sfApplicant',
      'wasAnyNonResPortionOwnerOccupied'
    )
  ),
  hasVacantSF: Joi.when('....wasAnyNonResPortionVacant', {
    is: true,
    then: schemaRules.BOOLEAN.custom(
      checkAtLeastOne('uses', 'hasVacantSF')
    ).messages(checkAtLeastOneUseTypeMsg),
    otherwise: schemaRules.ANY,
  }),
  sfVacant: schemaRules.NUMBER.custom(
    customCheckAtSomeOne(
      'Non-owner occupied SF',
      'sfVacant',
      'wasAnyNonResPortionVacant',
      true
    )
  ),
}

const needBeBoolean = Joi.when('uses', {
  is: schemaRules.ITEMS(schemaRules.ANY).min(1),
  then: Joi.when('numberOfCommercialUnit', {
    is: schemaRules.MIN_NUMBER(1),
    then: schemaRules.BOOLEAN,
    otherwise: schemaRules.ANY,
  }),
  otherwise: schemaRules.ANY,
})

const schema = schemaWrapper({
  numberOfCommercialUnit: schemaRules.MIN_NUMBER(),
  numberOfStorefrontUnit: Joi.when('numberOfCommercialUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules
      .MIN_MAX_NUMBER(0, Joi.ref('numberOfCommercialUnit'))
      .message('Must not exceed the # of commercial units above'),
    otherwise: schemaRules.ANY,
  }),
  wasAnyNonResPortionOwnerOccupied: needBeBoolean,
  wasAnyNonResPortionVacant: needBeBoolean,
  uses: Joi.when('numberOfCommercialUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.ITEMS(items),
    otherwise: schemaRules.ANY,
  }),
})

export default schema
