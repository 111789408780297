/* eslint-disable react-hooks/rules-of-hooks */
import { generatePortalPath } from 'helpers/utils'
import useConditionalRedirect from 'hooks/useConditionalRedirect'
import { identity } from 'lodash-es'

const useRedirectByApplicationStatuses =
  (fn = identity<boolean>) =>
  (application, statuses, url) => {
    useConditionalRedirect(
      generatePortalPath(url, {
        bblid: application.data?.id || 'undefined',
      }),
      application.data?.statusCS &&
        fn(statuses.includes(application.data.statusCS as any))
    )
  }

const useRedirectIfThisApplicationStatuses = useRedirectByApplicationStatuses()
const useRedirectIfNotThisApplicationStatuses =
  useRedirectByApplicationStatuses(v => !v)

export default useRedirectByApplicationStatuses
export {
  useRedirectIfThisApplicationStatuses,
  useRedirectIfNotThisApplicationStatuses,
}
