import React, { ReactNode } from 'react'

import { useMultiStyleConfig, chakra } from '@chakra-ui/system'
import { Box } from '@chakra-ui/layout'
import Affix, {
  AffixProps,
} from 'ui-framework/components/patterns/Table/typecells/Affix'
import { ChakraComponentProps, OnClickHandler } from 'ui-framework/common/types'
import { Flex, Tooltip } from '@chakra-ui/react'
import Icon from '../Icon'

//===================================================
export type AbstractInputProps = {
  errorTooltip?: boolean | object
  error?: string
  value?: any
  disabled?: boolean
  readonly?: boolean
  placeholder?: string | false | null
  label?: string | ReactNode
  fixedHeight?: boolean
  width?: string
  maxWidth?: string
  resize?: string
  onClick?: OnClickHandler
} & ChakraComponentProps &
  AffixProps

/**
 * AbstractInput component
 */
const AbstractInput = (props: AbstractInputProps) => {
  const { errorTooltip, placeholder, label, error, postfix, prefix, onClick } =
      props,
    styles = useMultiStyleConfig('MGNYAbstractInput', props)

  return (
    <Box sx={styles.gwrapper} className={props.className}>
      {label && <Box sx={styles.label}>{label}</Box>}
      <Box sx={styles.wrapper} onClick={onClick}>
        <Affix wide prefix={prefix} postfix={postfix}>
          {props.children}
          {placeholder && (
            <Box data-role="placeholder" sx={styles.placeholder}>
              {placeholder}
            </Box>
          )}
        </Affix>
        {errorTooltip && error && (
          <Tooltip
            sx={styles.tooltip}
            label={error}
            arrowSize={8}
            maxW={'268px'}
            bg={`red.base`}
            hasArrow
          >
            <Flex sx={styles.wrapperInner}>
              <Icon className="warning" color="red.base">
                info-circle
              </Icon>
            </Flex>
          </Tooltip>
        )}
      </Box>
      {!errorTooltip && error && <Box sx={styles.message}>{error}</Box>}
    </Box>
  )
}

export default chakra(AbstractInput, {
  shouldForwardProp: prop => true,
})
