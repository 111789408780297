import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { StorefrontUnitOccupancyType } from 'constants/dicti'
import Joi from 'joi'

const schema = schemaWrapper({
  occupancyTypeCS: schemaRules.ANY,
  grossRentAmount: Joi.when('occupantTypeCS', {
    switch: [
      {
        is: StorefrontUnitOccupancyType.Owner,
        then: 0,
      },
      {
        is: StorefrontUnitOccupancyType.Tenant,
        then: schemaRules.GREATER_NUMBER(),
      },
    ],
    otherwise: schemaRules.MIN_NUMBER(),
  }),
  baseRentAmount: Joi.when('occupantTypeCS', {
    switch: [
      {
        is: StorefrontUnitOccupancyType.Owner,
        then: 0,
      },
      {
        is: StorefrontUnitOccupancyType.Tenant,
        then: Joi.when('grossRentAmountRequired', {
          is: true,
          then: schemaRules.GREATER_NUMBER(),
          otherwise: null,
        }),
      },
    ],
    otherwise: schemaRules.MIN_NUMBER(),
  }),
})

export default schema
