import React, { useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { RpieFormData } from 'hooks/api/rpie/RpieFormData'
import { chakra, ChakraProps, Stack, VStack } from '@chakra-ui/react'
import { ExpenseSection } from 'hooks/api/ta/TaFormData'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { ExpenseSectionTypes } from 'constants/dicti'
import SectionsTable, {
  emptyForManually, StyledCell,
} from 'ui-framework/components/patterns/Wizard/SectionsTable'
import { categoriesEditTypeCS } from 'ui-framework/components/patterns/Wizard/SectionsTable/useSections'
import InformationTooltipWithLabel from 'ui-framework/components/primitives/InformationTooltipWithLabel'
import { expenseMainPrompts } from './expensePrompts'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { formatToCurrencyOrDash } from 'helpers/formatter'
import useApplicationContext from 'hooks/useApplicationContext'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { dashIfEmpty, getExceedDOFEstimatedTextForExpenses, getLowerDOFEstimatedTextForExpenses } from 'helpers/utils'
import InfoBox from 'ui-framework/components/primitives/InfoBox'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'

//===================================================

type Props = {
  sections: ExpenseSection[]
  readOnly?: boolean
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

const CellWithoutBorder = chakra('td', {
  baseStyle: {
    border: 'none !important',
  },
})

/**
 * StepExpenseTable component
 */
const StepExpenseTable = (props: Props) => {
  const { context } = useServiceWizard()

  const { sections, applyValidation, onValidation, readOnly = false } = props,
    validationData = useMemo(() => ({ sections }), [sections]),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    items = useMemo(
      () => [
        {
          type: [
            ExpenseSectionTypes.ExpenseMain,
            ExpenseSectionTypes.CondoMain,
          ],
          title: 'Main',
          prompts: expenseMainPrompts,
          readOnly,
        },
        {
          type: ExpenseSectionTypes.HotelDepartmental,
          title: 'Departmental',
          readOnly,
        },
        {
          type: ExpenseSectionTypes.HotelFinancial,
          title: 'Fixed',
          readOnly,
        },
        {
          type: ExpenseSectionTypes.HotelUndistributed,
          title: 'Operating',
          readOnly,
        },
        {
          type: ExpenseSectionTypes.ExpenseNonDeductable,
          readOnly,
          title: (
            <InformationTooltipWithLabel
              infoMessage={
                'The DOF considers these expenses ineligible. As a result, they are not included in the total.'
              }
            >
              <span style={{ marginRight: '8px' }}>Nondeductible</span>
            </InformationTooltipWithLabel>
          ),
        },
        {
          type: [
            ExpenseSectionTypes.HotelOther,
            ExpenseSectionTypes.CondoOther,
            ExpenseSectionTypes.ExpenseOther,
          ],
          title: 'Other',
          readOnly,
          params: {
            categoriesEditType: categoriesEditTypeCS.manually,
            newLineOptions: {
              empty: emptyForManually,
            },
          },
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

  const dofWarning = useMemo(() => {
    console.log('sections', sections)
    const dofEstimated = context?.external?.dofExpense
    const totalLastWoNonDeducted =
      sections.reduce((acc, section) => {
        if (section.typeCS === ExpenseSectionTypes.ExpenseNonDeductable) return acc
        return acc + section.items.reduce((acc, item) => {
          return acc + (item.lastYearAmount || 0)
        }, 0)
      }, 0)

    const dofDiffPercent = dofEstimated ? Math.round((dofEstimated - totalLastWoNonDeducted) * 100 / dofEstimated) : 0
    const showDOFWarning = dofEstimated > 0 && totalLastWoNonDeducted > 0 && (dofDiffPercent > 10 || dofDiffPercent < -10)
    const message = (dofDiffPercent > 0 ? getLowerDOFEstimatedTextForExpenses : getExceedDOFEstimatedTextForExpenses)(Math.abs(dofDiffPercent))

    if (!showDOFWarning) return null
    return <AttentionBox title={null} w={`516px`} variant="warning">
      <p>The Total Expenses without Nondeductible expense categories are <b>{formatToCurrencyOrDash(totalLastWoNonDeducted)}</b>. The Nondeductible expense categories are not used in the calculation as they are considered to be ineligible expenses by the DOF.</p>
      <p>DOF Estimated Expenses are <b>{formatToCurrencyOrDash(dofEstimated)}</b>.</p>
      <p>{message}</p>
    </AttentionBox>
  }, [sections, context?.external?.dofExpense])

  return (<>
    <SectionsTable
      {...props}
      sections={sections}
      items={items}
      hasError={hasError}
      modalContent={`Caution! You are removing an expense source previously added. Confirm that it needs to be removed.`}
      totalFooterLabel={`Total Expenses`}
      tableParams={{
        isSticky: !readOnly,
      }}
    />
    <chakra.div sx={{ margin: "20px 0 0 50px" }}>
      {dofWarning}
    </chakra.div>
  </>
  )
}

export default StepExpenseTable
export { CellWithoutBorder }
