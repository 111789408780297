import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { Box, chakra, useStyles } from '@chakra-ui/react'
import NavBar, { NavBarItems } from './NavBar'

//===================================================
type ProgressBarProps = {
  navCfg?: NavBarItems
} & BaseComponentProps

/**
 * ProgressBar component
 */
const ProgressBar = ({ navCfg, ...props }: ProgressBarProps) => {
  const styles = useStyles()
  return navCfg ? (
    <NavBar items={navCfg} />
  ) : (
    <>
      <Box sx={styles.bar} {...props} />
      <Box sx={styles.current} {...props} />
    </>
  )
}

export default chakra(ProgressBar)
