import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { Flex, Text } from '@chakra-ui/react'
import Icon from 'ui-framework/components/primitives/Icon'
import useAuth from 'hooks/useAuth'
import { chakra } from '@chakra-ui/react'

//===================================================
type EffectiveProfileInfoProps = {} & BaseComponentProps

/**
 * EffectiveProfileInfo component
 */
const EffectiveProfileInfo = (props: EffectiveProfileInfoProps) => {
  const { profile, effectiveProfile, effectiveProfileReset } = useAuth()

  if (effectiveProfile === profile) return null
  return (
    <Flex
      align={'center'}
      justifyContent={'space-between'}
      sx={{
        width: '100%',
        backgroundColor: 'red.base',
        color: 'white',
        padding: '0 5px',
        borderRadius: '5px',
      }}
    >
      <Text
        sx={{
          maxWidth: '90%',
        }}
        noOfLines={1}
      >
        {effectiveProfile?.email}
      </Text>
      <Icon size="sm" color="white" onClick={() => effectiveProfileReset()}>
        close
      </Icon>
    </Flex>
  )
}

export default EffectiveProfileInfo
