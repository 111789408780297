import { ComponentStyleConfig } from '@chakra-ui/theme'

const TabsStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['wrapper'],
  baseStyle: props => ({
    wrapper: {
      display: 'flex',
      position: 'relative',
      color: 'fontandicongray',
      textStyle: 'body.regular',
      paddingLeft: '40px',
      overflow: 'hidden',
    },
    moreButton: {
      height: `calc(100% / 2)`,
      fontSize: '28px',
      lineHeight: '8px',
      marginRight: '24px',
      borderColor: 'white100',
      color: 'fontandicongray',
      _hover: {
        color: 'primary.base',
      },
    },
  }),
  sizes: {
    sm: props => ({
      wrapper: {
        height: props.variant === 'rounded' ? '32px' : '36px',
      },
    }),
    md: props => ({
      wrapper: {
        height: props.variant === 'rounded' ? '40px' : '42px',
      },
    }),
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
}

export default TabsStyle
