import { useDisclosure } from '@chakra-ui/react'
import useRegisterEvent from 'hooks/analytics/useRegisterEvent'
import createPersistedState from 'use-persisted-state'

export const useAssistantShown = createPersistedState('assistant_shown')

const useAssistant = () => {
  const registerOpenEvent = useRegisterEvent('assistant_open'),
    registerCloseEvent = useRegisterEvent('assistant_close'),
    [assistantShown, assistantShownSet] = useAssistantShown(true),
    HelpBlock = useDisclosure({
      isOpen: assistantShown,
      onOpen: () => {
        assistantShownSet(true)
        registerOpenEvent()
      },
      onClose: () => {
        assistantShownSet(false)
        registerCloseEvent()
      },
      defaultIsOpen: true,
    })

  return HelpBlock
}

export default useAssistant
