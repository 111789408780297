import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpNumberStepCommercialDescriptionProps = {} & BaseComponentProps

/**
 * HelpNumberStepCommercialDescription component
 */
const HelpNumberStepCommercialDescription = (
  props: HelpNumberStepCommercialDescriptionProps
) => {
  return (
    <>
      <p>
        Out of the total commercial units, how many are storefronts? NYC Finance
        Department defines storefronts as follows:
      </p>
      <p>
        <b>Storefronts</b> typically have windows that face the street and are
        accessible to the public using a street or interior entrance.
      </p>
      <p>
        <b>Storefronts</b> generally display products and signs to make them
        easily identifiable to the public.
      </p>
      <p>
        <b>Storefronts</b> spaces are spaces that are used for retail sales or,
        without substantial physical alteration to the building or building
        entrances, could be used for retail sales.
      </p>
    </>
  )
}

export default HelpNumberStepCommercialDescription
