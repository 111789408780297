import { UseDisclosureReturn } from '@chakra-ui/react'
import { AnyFunction } from 'helpers/utils'
import { refObject } from 'ui-framework/common/types'
import {
  KEYCODES,
} from 'constants/CONSTANTS'

const useElementNav = (
  selector: UseDisclosureReturn,
  elementRef: refObject
): AnyFunction[] => {
  const checkCodes = (
      evt: React.KeyboardEvent<HTMLElement>,
      codes: Array<number>
    ): boolean => codes.includes(evt.keyCode),

    handleOnElementEvent = (event: React.KeyboardEvent<HTMLElement>) => {
      checkCodes(event, [KEYCODES.TAB]) && selector.onClose()
      checkCodes(event, [KEYCODES.UP, KEYCODES.DOWN]) &&
        elementRef?.current?.focus()
      if (checkCodes(event, [KEYCODES.ESC])) {
        event?.currentTarget?.blur()
        selector.onClose()
      }
    },

    handleOnElementClose = (event: React.KeyboardEvent<HTMLElement>) =>
      checkCodes(event, [KEYCODES.ESC]) && selector.onClose()

  return [handleOnElementEvent, handleOnElementClose]
}

export default useElementNav
