import { useMemo } from 'react'
import {
  getFiscalPeriods,
  getTotalDiffWithText,
  getFilteredDataByFieldAndCondition,
} from 'helpers/utils'
import {
  formatToCurrencyOrLongdash,
  formatToNumberITS,
} from 'helpers/formatter'
import { get } from 'object-path-immutable'
import {
  getDictiName,
  IncomeSectionTypes,
  PropertyType,
  PropertyUsage,
  UseByApplicant,
  DocumentType,
  FloorUsesType,
  TAStatuses,
  TAWizardSteps,
} from 'constants/dicti'
import {
  hasFloorNonResUses,
  hidePropertyDetailsSection,
  isConsolidatedNotRoot,
} from '../../wizard/taWizardConfig'
import { addUseByApplicantQuestion } from 'services/common/useFormDataHook'
import {
  IncomeSection,
  ExpenseSection,
  IncomeItem,
  ExpenseItem,
} from 'hooks/api/ta/TaFormData'
import useHandleStepChangeWithTooltip from 'ui-framework/components/patterns/Wizard/SummaryTable/useHandleStepChangeWithTooltip'
import { Section } from 'ui-framework/components/patterns/Wizard/SummaryTable'
import { useWasAnyRentalIncomeQuestionTitle } from 'pages/Services/TA/TAFormPage/steps/PropertyDescription/StepPropertyStatus'

type Sections = Array<IncomeSection | ExpenseSection>

const getFirstStepForNonRes = (data): TAWizardSteps | null =>
  Object.entries({
    basement: TAWizardSteps.DetailsNonResidentialBasement,
    firstFloor: TAWizardSteps.DetailsNonResidentialFirst,
    secondFloor: TAWizardSteps.DetailsNonResidentialSecond,
    thirdAndAboveFloors: TAWizardSteps.DetailsNonResidentialThird,
  }).reduce((res: TAWizardSteps | null, [key, stepId]) => {
    if (!res && hasFloorNonResUses(data, key)) res = stepId
    return res
  }, null)

const yesNo = v => (v ? 'Yes' : 'No'),
  getTotal = arr => arr.reduce((acc, i) => acc + (i || 0), 0),
  prepareFloorsItem = (items, onlyTotal = false) => {
    return items.reduce(
      (acc, item, i, arr) => {
        const isLast = arr.length - 1 === i,
          { data } = acc,
          createValue = () => ({
            value: [
              getDictiName(item.usesTypeCS),
              formatToNumberITS(item.vacantSF),
              formatToNumberITS(item.applicantSF),
              formatToNumberITS(item.rentedSF),
            ],
          }),
          insertItem = i => data.push(i)

        if (item.useCategoryCS !== FloorUsesType.Resident) {
          acc.vacantTotal += item.vacantSF || 0
          acc.applicantTotal += item.applicantSF || 0
          acc.rentedTotal += item.rentedSF || 0

          !onlyTotal && insertItem(createValue())
        }

        isLast &&
          insertItem({
            value: [
              'Total',
              formatToNumberITS(acc.vacantTotal),
              formatToNumberITS(acc.applicantTotal),
              formatToNumberITS(acc.rentedTotal),
            ],
            isTotal: true,
          })
        return acc
      },
      {
        vacantTotal: 0,
        applicantTotal: 0,
        rentedTotal: 0,
        data: [],
      }
    ).data
  },
  getSections = (sections: Sections = [], withRental) => {
    return withRental
      ? sections
      : sections.filter(
        section =>
          ![
            IncomeSectionTypes.CondoRental,
            IncomeSectionTypes.HotelRental,
          ].includes(section.typeCS as IncomeSectionTypes)
      )
  },
  preparedSectionItem = (sections: Sections = [], totalText: string = '') => {
    const items = getFilteredDataByFieldAndCondition(
      sections.reduce((acc, section) => {
        acc.push(...section.items)

        return acc
      }, [] as Array<IncomeItem | ExpenseItem>)
    ),
      { totalCurrent, totalDiff, totalLast } = getTotalDiffWithText(
        items,
        'prevYearAmount',
        'lastYearAmount',
        { textAlign: 'right' }
      )
    return [
      totalText,
      formatToCurrencyOrLongdash(totalLast),
      formatToCurrencyOrLongdash(totalCurrent),
      totalDiff,
    ]
  }

export const TASummarySectionsConfig = (
  context,
  remoteData?: any,
  changeStep?: any
): Section[] => {
  const { fiscalYear, reportingYear, prevReportingYear } =
    getFiscalPeriods(context),
    data = useMemo(
      () => remoteData || context?.defaultServiceData,
      [context, remoteData]
    ),
    getValue = (path, defaultValue?: any) =>
      data
        ? get(data, path, defaultValue)
        : get(context, `defaultServiceData.${path}`, defaultValue),
    incomeExpenseUploaded = getValue('income.showUploadArea'),
    isResidentialUploaded = getValue('uses.showUploadArea'),
    isPropertyToNetLease = getValue('general.isPropertyToNetLease'),
    propertyUsageCS = getValue('general.propertyUsageCS'),
    wasAnyConstruction = getValue('general.wasAnyConstruction'),
    { isRootConsolidateBBL, isConsolidated } = context,
    isCondoRelation7 =
      PropertyType.CondoRelation7 === context.bblsData.propertyTypeCS,
    hideNonResOccupancyStatus =
      ![PropertyType.Single, PropertyType.CondoRelation1].includes(
        context.bblsData.propertyTypeCS
      ) ||
      hidePropertyDetailsSection(data, context) ||
      (!hasFloorNonResUses(data, 'basement') &&
        !hasFloorNonResUses(data, 'firstFloor') &&
        !hasFloorNonResUses(data, 'secondFloor') &&
        !hasFloorNonResUses(data, 'thirdAndAboveFloors')),
    hideCovidAndResidential = [
      PropertyType.Coop,
      PropertyType.CondoRelation7,
    ].includes(context.bblsData.propertyTypeCS),
    isHotel = context?.bblsData?.propertyTypeCS === PropertyType.Hotel

  const inProgress = useMemo(
    () =>
      [TAStatuses.InProgress, TAStatuses.TC101Ready].includes(
        context?.statusCS
      ),
    [context?.statusCS]
  )

  const makeItemWithTooltip = useHandleStepChangeWithTooltip(changeStep)
  const wasAnyRentalIncomeQuestionTitle = useWasAnyRentalIncomeQuestionTitle(
    context.bblsData.buildingClasses,
    reportingYear
  )
  return [
    {
      id: TAWizardSteps.DescriptionMainInfo,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DescriptionMainInfo,
        'General Information'
      ),
      childrens: [
        {
          name: 'Applicant',
          value: getValue('general.applicant.name'),
        },
        {
          name: 'Relation',
          value: getDictiName(getValue('general.applicant.relationCS')),
        },
        {
          name: 'Signatory',
          value: getValue('general.signatory.name'),
        },
        {
          name: 'Title',
          value: getDictiName(getValue('general.signatory.signatoryTitleCS')),
        },
        {
          name: '# of Buildings on this Lot',
          value: getValue('general.propertyDescription.numberOfBuildings'),
        },
        {
          name: '# of Floor Above Grade',
          value: getValue(
            'general.propertyDescription.numberOfFloorAboveGrade'
          ),
        },
        {
          name: 'Year Built',
          value: getValue('general.propertyDescription.yearConstruction'),
        },
        {
          name: 'Year Purchased',
          value: getValue('general.propertyDescription.yearPurchase'),
        },
      ],
    },
    {
      id: TAWizardSteps.DescriptionAreas,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DescriptionAreas,
        'Uses and Floor Areas'
      ),
      childrens: [
        {
          name: 'Occupancy Type',
          value: getDictiName(getValue('general.propertyUsageCS')),
        },
        {
          name: 'Residential Units',
          value: getValue(
            'uses.residentialInfo.residentialUnits.numberOfResidentialUnit'
          ),
        },
        {
          name: 'Non-Residential Units',
          value: getValue('uses.nonResidentialInfo.numberOfNonResidentialUnit'),
        },
        {
          name: 'Retail Units',
          value: getValue('uses.nonResidentialInfo.numberOfRetailUnit'),
        },
      ],
    },
    {
      id: TAWizardSteps.DescriptionCovid,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DescriptionCovid,
        // 'Details about Vacancy and COVID-19'
        'Details about Vacancy'
      ),
      hidden:
        !(isRootConsolidateBBL || !isConsolidated) || hideCovidAndResidential,
      childrens: [
        {
          name: isHotel
            ? `In ${reportingYear}, were hotel reservations significantly low on average?`
            : `Throughout ${reportingYear}, were there any substantial vacancies longer than 3 months or difficulties collecting rent from any tenants?`,
          value: yesNo(getValue('general.wereAnySubstantialVacancies')),
        },
        // {
        //   name: `Were ${
        //     isHotel
        //       ? 'low hotel reservations'
        //       : 'the vacancies / lower collections'
        //   } related to COVID-19?`,
        //   value: yesNo(getValue('general.covid.isCovid')),
        // },
        // {
        //   name: 'Documents which can substantiate COVID-19 related losses',
        //   filesId: DocumentType.Covid,
        //   hidden: !getValue('general.covid.isCovid'),
        // },
      ],
    },
    {
      id: TAWizardSteps.DescriptionStatus,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DescriptionStatus,
        'Property Status Information'
      ),
      childrens: [
        {
          name: `In ${reportingYear}, was any part of this property used by Applicant or a related party?`,
          value:
            getValue('general.useByApplicantCS') === UseByApplicant.Part
              ? 'Yes, part of the property'
              : getValue('general.useByApplicantCS') === UseByApplicant.Entire
                ? 'Yes, entire property'
                : getDictiName(getValue('general.useByApplicantCS')),
          hidden: !addUseByApplicantQuestion(
            context.bblsData.propertyTypeCS,
            getValue('general.propertyUsageCS')
          ),
        },
        {
          name: `Which portion of the property was used by ${getValue(
            'general.applicant.name'
          )} or a related party in ${reportingYear}?`,
          value: getDictiName(getValue('general.portionUsedByApplicantCS')),
          hidden: !getValue('general.portionUsedByApplicantCS'),
        },
        {
          name: `After January 5, ${prevReportingYear}, was the property subject to any construction, demolition or major alteration (or have plans for demolition or a new building been filed with the Department of Buildings)?`,
          value: yesNo(getValue('general.wasAnyConstruction')),
        },
        {
          name: wasAnyRentalIncomeQuestionTitle,
          value: yesNo(getValue('general.wasAnyRentalIncome')),
          hidden: isCondoRelation7,
        },
      ],
    },
    {
      id: TAWizardSteps.DescriptionConstruction,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DescriptionConstruction,
        'Construction Information'
      ),
      hidden: !wasAnyConstruction,
      childrens: [
        {
          name: '% of Completion',
          value: getValue('construction.percentWorkCompleted'),
        },
        {
          name: 'Total Costs, $',
          value: formatToCurrencyOrLongdash(
            getTotal([
              getValue('construction.costs.contractCost'),
              getValue('construction.costs.financingCost'),
              getValue('construction.costs.professionalFee'),
              getValue('construction.costs.otherCost'),
            ])
          ),
          isTotal: true,
        },
      ],
    },
    {
      id: TAWizardSteps.DetailsResidentialMain,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DetailsResidentialMain,
        'Main Residential Information'
      ),
      hidden:
        hidePropertyDetailsSection(data, context) ||
        propertyUsageCS === PropertyUsage.NonResidential ||
        hideCovidAndResidential ||
        isResidentialUploaded,
      childrens: [
        {
          name: '# of Regulated Apartments',
          value: getValue(
            'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfUnits'
          ),
        },
        {
          name: '# of Unregulated Apartments',
          value: getValue(
            'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfUnits'
          ),
        },
        {
          name: '# of Owner - Occupied Apartments',
          value: getValue(
            'uses.residentialInfo.ownerOccupied.numberTotalUnits'
          ),
        },
        {
          name: 'Location',
          value: getValue('uses.residentialInfo.ownerOccupied.ownerFloors'),
        },
        {
          name: `Have any apartments been vacant as of January 5, ${fiscalYear}?`,
          value: yesNo(
            getValue('uses.residentialInfo.residentialUnits.wereAnyUnitsVacant')
          ),
        },
        {
          name: `Have any apartments vacant for more than 3 months in ${reportingYear}?`,
          value: yesNo(
            getValue(
              'uses.residentialInfo.residentialUnits.wereAnyUnitsVacant3Month'
            )
          ),
        },
      ],
    },
    {
      id: TAWizardSteps.DetailsResidentialRent,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.DetailsResidentialRent,
        'Residential Rent Information'
      ),
      hidden:
        hidePropertyDetailsSection(data, context) ||
        hideCovidAndResidential ||
        propertyUsageCS === PropertyUsage.NonResidential,
      childrens: [
        {
          name: ['Occupancy Type', 'Units', 'Total Rent'],
          hidden: isResidentialUploaded,
        },
        {
          hidden: isResidentialUploaded,
          value: [
            'Vacant',
            getTotal([
              getValue(
                'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfVacantUnits'
              ),
              getValue(
                'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfVacantUnits'
              ),
            ]),
            formatToCurrencyOrLongdash(
              getTotal([
                getValue(
                  'uses.residentialInfo.residentialUnits.regulatedUnits.monthlyRentLossDueVacant'
                ),
                getValue(
                  'uses.residentialInfo.residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant'
                ),
              ])
            ),
          ],
        },
        {
          hidden: isResidentialUploaded,
          value: [
            'Rented',
            getTotal([
              getValue(
                'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfRentedUnits'
              ),
              getValue(
                'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfRentedUnits'
              ),
            ]),
            formatToCurrencyOrLongdash(
              getTotal([
                getValue(
                  'uses.residentialInfo.residentialUnits.regulatedUnits.monthlyRent'
                ),
                getValue(
                  'uses.residentialInfo.residentialUnits.unRegulatedUnits.monthlyRent'
                ),
              ])
            ),
          ],
        },
        {
          hidden: isResidentialUploaded,
          value: [
            'Owner',
            getValue('uses.residentialInfo.ownerOccupied.numberTotalUnits'),
            null,
          ],
        },
        {
          hidden: isResidentialUploaded,
          value: [
            'Total',
            getTotal([
              getValue(
                'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfVacantUnits'
              ),
              getValue(
                'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfVacantUnits'
              ),
              getValue(
                'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfRentedUnits'
              ),
              getValue(
                'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfRentedUnits'
              ),
              getValue('uses.residentialInfo.ownerOccupied.numberTotalUnits'),
            ]),
            formatToCurrencyOrLongdash(
              getTotal([
                getValue(
                  'uses.residentialInfo.residentialUnits.regulatedUnits.monthlyRent'
                ),
                getValue(
                  'uses.residentialInfo.residentialUnits.unRegulatedUnits.monthlyRent'
                ),
                getValue(
                  'uses.residentialInfo.residentialUnits.regulatedUnits.monthlyRentLossDueVacant'
                ),
                getValue(
                  'uses.residentialInfo.residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant'
                ),
              ])
            ),
          ],
          isTotal: true,
        },
        {
          hidden: !isResidentialUploaded,
          filesId: DocumentType.ResRentRoll,
        },
      ],
    },
    {
      id: getFirstStepForNonRes(data),
      title: makeItemWithTooltip(
        inProgress && getFirstStepForNonRes(data),
        'Non-Residential Uses and Occupancy Status'
      ),
      hidden: hideNonResOccupancyStatus,
      childrens: [
        {
          name: ['Categories', 'Vacant SF', 'Owner SF', 'Rented SF'],
        },
        ...prepareFloorsItem(
          [
            ...getValue('uses.usesTables.basement', []),
            ...getValue('uses.usesTables.firstFloor', []),
            ...getValue('uses.usesTables.outdoorSpace.outdoorUses', []),
            ...getValue('uses.usesTables.secondFloor', []),
            ...getValue('uses.usesTables.thirdAndAboveFloors', []),
          ],
          true
        ),
      ],
    },
    {
      id: TAWizardSteps.Income,
      title: makeItemWithTooltip(inProgress && TAWizardSteps.Income, 'Income'),
      hidden:
        hidePropertyDetailsSection(data, context) ||
        isConsolidatedNotRoot(context) ||
        incomeExpenseUploaded ||
        isPropertyToNetLease ||
        getValue('general.wasAnyRentalIncome') === false,
      childrens: [
        {
          name: [
            'Categories',
            `${prevReportingYear} Annual`,
            `${reportingYear} Annual`,
            'Change (YoY)',
          ],
        },
        {
          value: preparedSectionItem(
            getSections(
              getValue('income.sections'),
              getValue('hotel.wasIncomeFromRentalTenants')
            ),
            'Total Gross Income'
          ),
          isTotal: true,
        },
      ],
    },
    {
      id: TAWizardSteps.Expense,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.Expense,
        'Expenses'
      ),
      hidden:
        hidePropertyDetailsSection(data, context) ||
        isConsolidatedNotRoot(context) ||
        incomeExpenseUploaded ||
        isPropertyToNetLease ||
        getValue('general.wasAnyRentalIncome') === false,
      childrens: [
        {
          name: [
            'Categories',
            `${prevReportingYear} Annual`,
            `${reportingYear} Annual`,
            'Change (YoY)',
          ],
        },
        {
          value: preparedSectionItem(
            getValue('expense.sections'),
            'Total Expenses'
          ),
          isTotal: true,
        },
      ],
    },
    {
      id: TAWizardSteps.IncomeUpload,
      title: makeItemWithTooltip(
        inProgress && TAWizardSteps.IncomeUpload,
        `Income and Expense Information`
      ),
      hidden:
        hidePropertyDetailsSection(data, context) ||
        !incomeExpenseUploaded ||
        isPropertyToNetLease ||
        getValue('general.wasAnyRentalIncome') === false,
      childrens: [
        {
          filesId: DocumentType.Income,
        },
      ],
    },
  ]
}
