import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpNextStepSFGeneral1Props = { year: number } & BaseComponentProps

/**
 * HelpNextStepSFGeneral1 component
 */
const HelpNextStepSFGeneral1 = (props: HelpNextStepSFGeneral1Props) => {
  return (
    <>
      <p>
        The storefront registration is a new RPIE requirement as of 2020.
        Although it is unclear how the City will use the collected storefront
        information, the data is likely to inform future City-wide policies
        related to storefronts. One thing is certain: the City is set on
        enforcing this requirement by assessing hefty penalties for
        non-compliance.
      </p>
      <p>
        In this section, you will be guided to provide information for each
        storefront unit in your building. You have already indicated the number
        of existing storefronts in the General section. If you need to correct
        the number of storefront units, go back and edit the field.
      </p>
    </>
  )
}

export default HelpNextStepSFGeneral1
