import { useCallback, useState, useEffect, useMemo } from 'react'
import { getBBLWithAddressString } from 'helpers/business'
import {
  collection,
  DocumentData,
  DocumentReference,
  getDocs,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore'
import { DocumentType, FileSources } from 'constants/dicti'

export type DocFile = {
  id?: string
  ref: DocumentReference<DocumentData>
  docRef: DocumentReference<DocumentData>
  filePath: string
  fileName: string
  wasDeleted?: boolean
  statusCS: string
  sfDocumentId: null | string
  fileSource: FileSources
  isAllowDeleting: boolean
}

export type Doc = {
  id: string
  ref: DocumentReference<DocumentData>
  appRef: DocumentReference<DocumentData>
  typeCS: DocumentType
  statusCS: string
  isConsolidated: boolean
  files: DocFile[]
}

export type ResultDoc = {
  parentId: string
  title: string
  documents: Doc[]
}

type UseGetDocsResult = {
  documents: ResultDoc[]
  isPending: boolean
}

const useGetDocs = (applications: any[] = []): UseGetDocsResult => {
  const [documents, documentsSet] = useState<Map<string, ResultDoc[]>>(
      new Map()
    ),
    [isPending, isPendingSet] = useState<boolean>(true)

  const processApplicationDocs = useCallback(async (application, docsCol) => {
    const result: ResultDoc[] = []

    if (!docsCol.empty) {
      const resultDoc: ResultDoc = {
        parentId: application.id,
        title: getBBLWithAddressString({
          bblsData: application.bblsData,
        }),
        documents: [],
      }

      for (let documentRef of docsCol.docs) {
        const docBody = await documentRef.data(),
          files: DocFile[] = []

        const q = query(
          collection(documentRef.ref, 'files'),
          where('wasDeleted', '==', false)
        )
        const filesCol = await getDocs(q)

        if (!filesCol.empty) {
          for (const fileRef of filesCol.docs) {
            const fileBody = await fileRef.data()
            files.push(fileBody as DocFile)
          }
        }

        resultDoc.documents.push({
          ...docBody,
          id: documentRef.id,
          ref: documentRef.ref,
          appRef: application.ref,
          isConsolidated: application.isConsolidated,
          files,
        })
      }
      result.push(resultDoc)
    }

    documentsSet(prev => {
      return new Map(prev).set(application.id, result)
    })
  }, [])

  useEffect(() => {
    const unsubscribies: Unsubscribe[] = []
    const fetchDocs = async () => {
      let cnt = applications.length
      documents.size === 0 && isPendingSet(true)
      for (let application of applications) {
        const q = query(
          collection(application.ref, 'documents'),
          where('typeCS', '!=', null)
        )

        // eslint-disable-next-line no-loop-func
        const unsubscribe = onSnapshot(q, async docsCol => {
          await processApplicationDocs(application, docsCol)
          cnt = cnt - 1
          if (cnt === 0) {
            //
            isPendingSet(false)
          }
        })
        unsubscribies.push(unsubscribe)
      }
    }

    applications.length > 0 && fetchDocs()
    !applications.length && documents.size && documentsSet(new Map())

    return () => unsubscribies.forEach(u => u())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications])

  const documentsOut = useMemo(
    () => [...documents.values()].flat(2),
    [documents]
  )

  return {
    documents: documentsOut,
    isPending,
  }
}

export default useGetDocs
