import React, { useMemo, useCallback, MutableRefObject } from 'react'
import InputBase, { InputBaseProps } from './InputBase'
import { HStack, Divider, useStyleConfig } from '@chakra-ui/react'
import Icon from '../Icon'
import { getCallback, isNullOrWhiteSpace } from 'helpers/utils'
import AbstractInput, { AbstractInputProps } from '../AbstractInput'
import { OnChangeHandler, OnClickHandler } from 'ui-framework/common/types'
//
//===================================================
type InputProps = {
  errorTooltip?: boolean | { outside?: boolean }
  showClearAfter?: number
  inputRef?: MutableRefObject<HTMLInputElement | undefined>
  onKeyDown?: OnClickHandler | undefined
  onClear?: OnChangeHandler<string | string[] | any | any[]> | undefined
} & AbstractInputProps &
  InputBaseProps

/**
 * Input component
 */
const Input = (props: InputProps) => {
  const {
    errorTooltip = false,
    showClearAfter = 0,
    onBlur,
    onFocus,
    inputRef,
    onKeyDown,
    placeholder = 'Enter',
    value,
    onChange,
    onClear,
  } = props,
    placeholderText = isNullOrWhiteSpace(value) ? placeholder : undefined,
    triggerRule = useMemo(
      () =>
        !isNullOrWhiteSpace(value) &&
        String(value).length > showClearAfter &&
        !props.disabled,
      [props.disabled, showClearAfter, value]
    ),
    handleOnClear = useCallback(
      e => {
        e.preventDefault()
        e.stopPropagation()
        //TODO: think about calling internalValueSet at every call
        getCallback(onChange)(null, props.name)
        getCallback(onClear)(null)
        inputRef?.current?.focus()
      },
      [onClear, onChange, props.name, inputRef]
    ),
    postixWithClear = useMemo(() => {
      const clearButton = triggerRule && (
        <Icon size="xs" onClickCapture={handleOnClear}>
          close
        </Icon>
      )

      return (
        <HStack>
          {clearButton}
          {clearButton !== false && props.postfix && (
            <Divider orientation="vertical" borderColor="fontandicongray" />
          )}
          {props.postfix}
        </HStack>
      )
    }, [triggerRule, handleOnClear, props.postfix])

  const style = useStyleConfig('MGNYInput', {
    clearBtn: triggerRule,
  })

  return (
    <AbstractInput
      className={props.className}
      sx={style}
      size={props.size}
      value={value}
      label={props.label}
      errorTooltip={errorTooltip}
      error={props.error}
      disabled={props.disabled}
      prefix={props.prefix}
      postfix={postixWithClear}
      fixedHeight={props.fixedHeight}
      width={props.width}
      maxWidth={props.maxWidth}
    >
      <InputBase
        placeholder={placeholderText}
        inputRef={inputRef}
        value={value}
        format={props.format}
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        readonly={props.readonly}
        autocomplete={props.autocomplete}
        onChange={getCallback(onChange)}
        maxLength={props.maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        tooltipForTruncated={props.tooltipForTruncated}
      />
    </AbstractInput>
  )
}

export default Input
