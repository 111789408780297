import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'

const MAX_OCCUPANCY_RATE_PERCENT = 200

const roomsRule = field =>
  schemaRules.NOT_EMPTY_NUMBER.equal(Joi.ref(`/${field}`)).messages({
    'any.only':
      'Number of Transient Rooms and Permanent Rooms must equal # of All Rooms',
  })

const additionalRule = (valid = schemaRules.BOOLEAN) =>
  Joi.when('additionalHidden', {
    not: true,
    then: valid,
    otherwise: schemaRules.ANY,
  })

const schema = schemaWrapper({
  hotelName: schemaRules.NOT_EMPTY_STRING,
  additionalHidden: schemaRules.ANY,
  isManagedByUnrelatedEntity: additionalRule(),
  haveProprietaryRights: additionalRule(),
  haveProprietaryRightsDescription: Joi.when('/haveProprietaryRights', {
    is: true,
    then: schemaRules.SOME_SYMBOL_STRING(3),
    otherwise: null,
  }),
  totalRoomsNumber: schemaRules.NOT_EMPTY_NUMBER,
  keysNumber: schemaRules.NOT_EMPTY_NUMBER,
  transientRoomsNumber: roomsRule('$maxTransient'),
  permanentRoomsNumber: roomsRule('$maxPermanent'),
  occupancyRate: schemaRules.MIN_MAX_NUMBER(0, MAX_OCCUPANCY_RATE_PERCENT),
})

export default schema
