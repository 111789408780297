import React, { useCallback, useMemo, useState } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { RoomRate, TaFormData } from 'hooks/api/ta/TaFormData'
import Table from 'ui-framework/components/patterns/Table'
import {
  getCallback,
  getSum,
  mapDictiNames,
  mapObjectToIdNameItems,
  getNameAndErrorProps,
} from 'helpers/utils'
import { formatToNumberITS } from 'helpers/formatter'
import { del, push, set } from 'object-path-immutable'
import Button from 'ui-framework/components/primitives/Button'
import {
  RemovedParams,
  StyledCell,
} from 'ui-framework/components/patterns/Wizard/SectionsTable'
import { integerPositiveThousands } from 'helpers/formats'
import Input from 'ui-framework/components/primitives/Input'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import { HotelRoomTypes } from 'constants/dicti'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import Icon from 'ui-framework/components/primitives/Icon'
import { ChakraProps, useDisclosure } from '@chakra-ui/react'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { emptyRoomRateItem } from 'services/ta/useTaFormDataHook'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'

//===================================================
const MAX_ROOM_RATES_ITEMS = 6

type Props = {
  totalRoomsNumber: number
  roomRates: RoomRate[]
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

const basicText = {
    textAlign: 'left',
    color: 'fontandicongray',
  },
  basicTextHeader = {
    ...basicText,
    m: '36px 0 11px',
  } as ChakraProps,
  basicTextFooter = {
    ...basicText,
    m: '5px 0px',
    textStyle: 'body.semibold',
    color: 'fontnavy',
  } as ChakraProps

const roomTypes = mapDictiNames([
  HotelRoomTypes.King,
  HotelRoomTypes.Double,
  HotelRoomTypes.Single,
  HotelRoomTypes.Queen,
  HotelRoomTypes.Suite,
  HotelRoomTypes.Penthouse,
])

/**
 * StepRoomRates component
 */
const StepRoomRates = ({
  totalRoomsNumber,
  roomRates,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const roomTypeItems = useMemo(() => mapObjectToIdNameItems(roomTypes), []),
    roomRatesItems = useMemo(
      () => (roomRates.length ? roomRates : [emptyRoomRateItem]),
      [roomRates]
    ),
    validationData = useMemo(
      () => ({ roomRates: roomRatesItems, totalRoomsNumber }),
      [roomRatesItems, totalRoomsNumber]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  const headers = [
    {
      width: '54px',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}>Room Type</TextFromParams>
      ),
      width: '25%',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}># of Rooms</TextFromParams>
      ),
      width: '25%',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}>Single Rate</TextFromParams>
      ),
      width: '25%',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}>Double Rate</TextFromParams>
      ),
      width: '25%',
    },
    {
      width: '14px',
    },
    {
      width: '24px',
    },
  ]

  const handleOtherAddClick = useCallback(
    () =>
      getCallback(onChange)(
        push({ roomRates }, 'roomRates', emptyRoomRateItem)
      ),
    [roomRates, onChange]
  )

  const numberOfeachSum = useMemo(
    () => roomRates.reduce(getSum('numberOfeach'), 0),
    [roomRates]
  )

  const onHandleChange = useCallback(
    index => (v, name) =>
      getCallback(onChange)(set({ roomRates }, ['roomRates', index, name], v)),
    [roomRates, onChange]
  )

  const cautionDialog = useDisclosure(),
    [removeParams, removeParamsSet] = useState<RemovedParams>({
      callback: () => {},
    }),
    modalOpen = removeParams => {
      removeParamsSet(removeParams)
      cautionDialog.onOpen()
    },
    handleRemoveClick = ({ callback }: RemovedParams) => {
      callback()
      cautionDialog.onClose()
    }

  const onHandleDelete = useCallback(
    index => () =>
      getCallback(onChange)(del({ roomRates }, ['roomRates', index])),
    [roomRates, onChange]
  )

  return (
    <StepBasic className={props.className} pl="0">
      <AttentionBox
        title={false}
        ml="54px"
        w="316px"
        h="82px"
        bgColor={'#F9FAFF'}
      >
        <TextFromParams
          params={{
            textAlign: 'left',
            lineHeight: '25px',
          }}
        >
          You have <b>{totalRoomsNumber}</b> Rooms in this Hotel!
          <br />
          Go Back if you need to edit this number.
        </TextFromParams>
      </AttentionBox>

      <Table
        headers={headers}
        items={roomRatesItems}
        rowsHeadingHeight="52px"
        footer={
          <>
            {roomRates.length < MAX_ROOM_RATES_ITEMS && (
              <tr>
                <td style={{ borderTop: 'none' }} />
                <td colSpan={headers.length - 2}>
                  <Button
                    h="18px"
                    disabled={disabled}
                    onClick={handleOtherAddClick}
                    variant="textPrimary"
                  >
                    Add new category
                  </Button>
                </td>
                <td style={{ borderTop: 'none' }} />
              </tr>
            )}
            <tr>
              <StyledCell />
              <StyledCell>
                <TextFromParams params={basicTextFooter}>
                  Total Rooms
                </TextFromParams>
              </StyledCell>
              <StyledCell colSpan={5}>
                <TextFromParams params={basicTextFooter}>
                  {formatToNumberITS(numberOfeachSum)}
                </TextFromParams>
              </StyledCell>
            </tr>
          </>
        }
      >
        {(item, index) => {
          const nameAndErrorProps = name =>
            getNameAndErrorProps(name, hasError, `roomRates.${index}`)
          const onChangeHandler = onHandleChange(index)

          return (
            <tr key={`${index}${item.roomTypeCS}`}>
              <CellWithoutBorder />
              <td>
                <DropdownList
                  useFilter={0}
                  listMaxHeight="275px"
                  width="180px"
                  size="sm"
                  value={item.roomTypeCS}
                  disabled={disabled}
                  listWidth="180px"
                  itemId="id"
                  itemValue="name"
                  items={roomTypeItems}
                  errorTooltip
                  onChange={onChangeHandler}
                  {...nameAndErrorProps('roomTypeCS')}
                />
              </td>
              <td>
                <Input
                  width="160px"
                  size="sm"
                  type="number"
                  placeholder="Enter number"
                  value={item.numberOfeach}
                  format={integerPositiveThousands}
                  disabled={disabled}
                  errorTooltip
                  onChange={onChangeHandler}
                  {...nameAndErrorProps('numberOfeach')}
                />
              </td>
              <td>
                <Input
                  width="160px"
                  size="sm"
                  type="number"
                  prefix="$"
                  value={item.singleRate}
                  format={integerPositiveThousands}
                  disabled={disabled}
                  errorTooltip
                  onChange={onChangeHandler}
                  {...nameAndErrorProps('singleRate')}
                />
              </td>
              <td>
                <Input
                  width="160px"
                  size="sm"
                  type="number"
                  prefix="$"
                  value={item.doubleRate}
                  format={integerPositiveThousands}
                  disabled={disabled}
                  errorTooltip
                  onChange={onChangeHandler}
                  {...nameAndErrorProps('doubleRate')}
                />
              </td>
              <td>
                <Icon
                  size="sm"
                  color="fontandicongray"
                  tabIndex={0}
                  onClick={() =>
                    modalOpen({
                      callback: onHandleDelete(index),
                    })
                  }
                >
                  close
                </Icon>
              </td>
              <CellWithoutBorder />
            </tr>
          )
        }}
      </Table>
      <ModalDialog
        title={`Caution!`}
        actionText="Yes, Remove"
        actionVariant="warning"
        onAction={() => handleRemoveClick(removeParams)}
        isOpen={cautionDialog.isOpen}
        onClose={cautionDialog.onClose}
        size="w560"
      >
        Caution! You are removing a room type. Confirm that it needs to be
        removed.
      </ModalDialog>
    </StepBasic>
  )
}

export default StepRoomRates
