import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import {
  PropertyUsage,
  UseByApplicant,
  PortionUsedByApplicant,
} from 'constants/dicti'

const schema = schemaWrapper({
  useByApplicantCS: Joi.when('showUseByApplicantQuestion', {
    is: true,
    then: schemaRules.VALID_STRING(
      UseByApplicant.Entire,
      UseByApplicant.Part,
      UseByApplicant.No
    ),
    otherwise: schemaRules.ANY,
  }),

  propertyUsageCS: schemaRules.VALID_STRING(
    PropertyUsage.Mixed,
    PropertyUsage.NonResidential,
    PropertyUsage.Residential,
    PropertyUsage.Vacant
  ),
  portionUsedByApplicantCS: Joi.when('showPortionUsedByApplicantQuestion', {
    is: true,
    then: schemaRules.VALID(
      PortionUsedByApplicant.Residential,
      PortionUsedByApplicant.NonResidential,
      PortionUsedByApplicant.Both
    ),
    otherwise: null,
  }),
  wasAnyConstruction: schemaRules.BOOLEAN,
  wasAnyRentalIncome: Joi.when('showWasAnyRentalIncome', {
    is: true,
    then: schemaRules.BOOLEAN,
    otherwise: null,
  }),
})

export default schema
