import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import Input from 'ui-framework/components/primitives/Input'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'

import { NonresidentialInfo, ResidentialInfo } from 'hooks/api/ta/TaFormData'
import { Box, HStack, Flex, Text, VStack } from '@chakra-ui/react'
import Empty from 'ui-framework/components/primitives/Empty'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { getDictiName, SignatoryTitle } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { createItemsByDicti, getNameAndErrorProps } from 'helpers/utils'

//===================================================
const signatoryTitleItems = createItemsByDicti(SignatoryTitle).filter(
  t => t.id !== SignatoryTitle.ManagingAgent
)

type Props = {
  applicantName: string
  applicantRelation: string
  signatoryName: string
  signatoryTitleCS: SignatoryTitle
  numberOfBuildings: number
  numberOfFloorAboveGrade: number
  yearConstruction: number
  yearPurchase: number
  residentialInfo: ResidentialInfo
  nonResidentialInfo: NonresidentialInfo
  isPropertyToNetLease: boolean
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepGeneralInformation component
 */
const StepGeneralInformation = ({
  applicantName,
  applicantRelation,
  signatoryName,
  signatoryTitleCS,
  numberOfBuildings,
  numberOfFloorAboveGrade,
  yearConstruction,
  yearPurchase,
  residentialInfo,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  isPropertyToNetLease,
  ...props
}: Props) => {
  const getDisplayValue = (value: any, params: { [key: string]: any } = {}) =>
      !value ? (
        <Box width="182px" h="72px">
          <Text textStyle="body.regular" color="fontnavy" marginBottom="18px">
            {params.label}
          </Text>
          <Empty type="hyphen" />
        </Box>
      ) : (
        <Input disabled width="182px" {...params} />
      ),
    validationData = useMemo(
      () => ({ applicantName, signatoryName, signatoryTitleCS }),
      [applicantName, signatoryName, signatoryTitleCS]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack spacing="0" align="left ">
        <Flex flexWrap={'wrap'} alignItems={`flex-start`} mt={'-36px'}>
          <Box mr={`36px`} mt={`36px`}>
            <HelpTarget name="applicantName">
              <Input
                width="400px"
                label="Applicant"
                value={applicantName}
                disabled={disabled}
                onChange={onChange}
                {...nameAndErrorProps('applicantName')}
              />
            </HelpTarget>
          </Box>
          <Box mt={'36px'}>
            <HelpTarget name="applicantRelation">
              <Input
                width="400px"
                label="Relation"
                name="applicantRelation"
                value={getDictiName(applicantRelation)}
                disabled
              />
            </HelpTarget>
          </Box>
        </Flex>
        <Flex flexWrap={'wrap'} alignItems={`flex-start`}>
          <Box mr={`36px`} mt={`36px`}>
            <HelpTarget name="signatoryName">
              <Input
                width="400px"
                label="Signatory"
                value={signatoryName}
                disabled={disabled}
                onChange={onChange}
                {...nameAndErrorProps('signatoryName')}
              />
            </HelpTarget>
          </Box>
          <Box mt={'36px'}>
            <HelpTarget name="signatoryTitle">
              <DropdownList
                width="400px"
                label="Title"
                items={signatoryTitleItems}
                value={signatoryTitleCS}
                itemId="id"
                itemValue="name"
                disabled={disabled}
                listWidth="400px"
                onChange={onChange}
                {...nameAndErrorProps('signatoryTitleCS')}
              />
            </HelpTarget>
          </Box>
        </Flex>
        <Flex flexWrap={'wrap'}>
          <Box mr={'36px'} mt={`36px`}>
            <HStack spacing={'36px'}>
              {getDisplayValue(numberOfBuildings, {
                label: '# of Buildings on this Lot',
                name: 'numberOfBuildings',
                value: numberOfBuildings,
              })}
              {getDisplayValue(numberOfFloorAboveGrade, {
                label: '# of Floors Above Grade',
                name: 'numberOfFloorAboveGrade',
                value: numberOfFloorAboveGrade,
              })}
            </HStack>
          </Box>
          <Box mt={`36px`}>
            <HStack spacing={'36px'}>
              {getDisplayValue(yearConstruction, {
                label: 'Year Built',
                name: 'yearConstruction',
                value: yearConstruction,
              })}
              {getDisplayValue(yearPurchase, {
                label: 'Year Purchased',
                name: 'yearPurchase',
                value: yearPurchase,
              })}
            </HStack>
          </Box>
        </Flex>
      </VStack>
    </StepBasic>
  )
}

export default StepGeneralInformation
