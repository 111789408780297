import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import RegistrationForm from './RegistrationForm'
import SimpleLayoutCentered from 'ui-framework/components/layout/SimpleLayout/SimpleLayoutCentered'
import useRegisterUser from 'hooks/useRegisterUser'
import RegistrationProgress from './RegistrationProgress'
import Logo from 'ui-framework/components/layout/MainLayout/Logo'
import { URL_AUTH_LOGIN, URL_HOME } from 'constants/URLS'
import useInvite from 'hooks/useInvite'
import useAuth from 'hooks/useAuth'
import { Navigate, useNavigate } from 'react-router-dom'

//===================================================

type Props = {} & BaseComponentProps

/**
 * RegistrationPage component
 */
const RegistrationPage = (props: Props) => {
  const { isLogged } = useAuth(),
    navigate = useNavigate(),
    handleRedirect = () => {
      const desired_url = localStorage.getItem('desired_url') || URL_HOME
      localStorage.removeItem('desired_url')
      navigate(desired_url)
    }
  isLogged && handleRedirect()

  const [register, handleRegister] = useRegisterUser(),
    { invite, profile, isPending: isPendingInvite } = useInvite(),
    getCentered = c => (
      <SimpleLayoutCentered className={props.className}>
        {c}
      </SimpleLayoutCentered>
    )

  if (isPendingInvite) return null

  if (!invite || (!isPendingInvite && (!profile || profile?.hasCreds))) {
    const search = invite ? `?invite=${invite}` : undefined
    return <Navigate to={{ pathname: URL_AUTH_LOGIN, search }} replace />
  }

  if (register.data === true || register.isPending)
    return getCentered(<RegistrationProgress isPending={register.isPending} />)

  return getCentered(
    <>
      <Logo width={'88px'} height={'80px'} margin={'36px auto'} />
      <RegistrationForm
        firstName={profile?.firstName}
        lastName={profile?.lastName}
        email={profile?.email}
        onRegister={handleRegister}
      />
    </>
  )
}

export default RegistrationPage
