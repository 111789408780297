import React, { useCallback, useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { FurnitureItem, TaFormData } from 'hooks/api/ta/TaFormData'
import { ChakraProps, VStack } from '@chakra-ui/react'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import Input from 'ui-framework/components/primitives/Input'
import { integerPositiveThousands } from 'helpers/formats'
import { set, wrap } from 'object-path-immutable'
import { getDictiName, HotelFurnitureCosts } from 'constants/dicti'
import {
  getCallback,
  getTotalDiffWithText,
  getFiscalPeriods,
  getNameAndErrorProps,
} from 'helpers/utils'
import { formatToCurrency } from 'helpers/formatter'
import Table from 'ui-framework/components/patterns/Table'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import { StyledCell } from 'ui-framework/components/patterns/Wizard/SectionsTable'
import Empty from 'ui-framework/components/primitives/Empty'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'

//===================================================

type Props = {
  isThereFurniture: boolean
  furniture: FurnitureItem[]
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

const basicText = {
  textAlign: 'left',
  color: 'fontandicongray',
},
  basicTextHeader = {
    ...basicText,
    m: '0 0 12px',
  } as ChakraProps,
  basicTextFooter = {
    textAlign: 'right',
    m: '5px 0px',
    color: 'fontnavy',
  } as ChakraProps,
  textFooter = {
    ...basicTextFooter,
    textStyle: 'body.semibold',
    textAlign: 'left',
  } as ChakraProps

/**
 * StepFurnitureFixturesAndEquipment component
 */
const StepFurnitureFixturesAndEquipment = ({
  isThereFurniture,
  furniture,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { reportingYear, prevReportingYear } = getFiscalPeriods(context)

  const furnitureCostsIndex = useMemo(
    () =>
      furniture.findIndex(
        i => i.categoryCS === HotelFurnitureCosts.PurchasingItems
      ),
    [furniture]
  )

  const filteredFurniture = useMemo(
    () => furniture.filter((item, i) => i !== furnitureCostsIndex),
    [furniture, furnitureCostsIndex]
  ),
    validationData = useMemo(
      () => ({
        furniture,
        isThereFurniture,
      }),
      [furniture, isThereFurniture]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  const headers = [
    {
      width: '54px',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}>Categories</TextFromParams>
      ),
      width: '40%',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}>
          {prevReportingYear} Annual
        </TextFromParams>
      ),
      width: '25%',
    },
    {
      children: (
        <TextFromParams params={basicTextHeader}>
          {reportingYear} Annual
        </TextFromParams>
      ),
      width: '25%',
    },
    {
      children: (
        <TextFromParams
          params={{
            ...basicTextHeader,
            textAlign: 'right',
          }}
        >
          Change (YoY)
        </TextFromParams>
      ),
      width: '10%',
    },
    {
      width: '24px',
    },
  ]

  const onHandleChange = useCallback(
    (index, field) => v =>
      getCallback(onChange)(
        set({ furniture }, `furniture.${index}.${field}`, v)
      ),
    [furniture, onChange]
  )

  const onChangeWithClear = useCallback(
    v => {
      const clearedFurniture = furniture.map(item => ({
        ...item,
        lastYearAmount: null,
      })),
        res = wrap({})
          .set(`furniture`, clearedFurniture)
          .set('isThereFurniture', v)
      getCallback(onChange)(res.value())
    },
    [furniture, onChange]
  )

  const { diffs, totalDiff, totalLast, totalCurrent } = useMemo(
    () =>
      getTotalDiffWithText(
        filteredFurniture,
        'prevYearAmount',
        'lastYearAmount',
        basicTextFooter
      ),
    [filteredFurniture]
  )

  return (
    <StepBasic paddingLeft="0" className={props.className}>
      <VStack spacing="36px" align="flex-start">
        <VStack paddingLeft="54px">
          <HelpTarget name="isThereFurniture">
            <SelectsGroup
              label={`Is there a reserve for Furniture, Fixtures and Equipment?`}
              error={hasError('isThereFurniture')}
            >
              <Radiobutton
                value={isThereFurniture}
                name="isThereFurniture"
                selected={false}
                disabled={disabled}
                onChange={onChangeWithClear}
              >
                No
              </Radiobutton>
              <Radiobutton
                value={isThereFurniture}
                name="isThereFurniture"
                selected={true}
                disabled={disabled}
                onChange={onChange}
              >
                Yes
              </Radiobutton>
            </SelectsGroup>
          </HelpTarget>
        </VStack>

        {isThereFurniture && (
          <>
            <VStack paddingLeft="54px">
              <Input
                width="182px"
                label={
                  <p>
                    Cost of items purchased in reporting year{' '}
                    <b>
                      January 1, {reportingYear} - December 31, {reportingYear}
                    </b>
                  </p>
                }
                value={furniture[furnitureCostsIndex].lastYearAmount}
                type="number"
                prefix="$"
                format={integerPositiveThousands}
                disabled={disabled}
                onChange={onHandleChange(furnitureCostsIndex, 'lastYearAmount')}
                errorTooltip
                {...getNameAndErrorProps('itemsCost', () =>
                  hasError(['furniture', furnitureCostsIndex, 'lastYearAmount'])
                )}
              />
            </VStack>

            <Table
              headers={headers}
              items={furniture}
              rowsHeadingHeight="32px"
              footer={
                <>
                  <tr>
                    <StyledCell />
                    <StyledCell>
                      <TextFromParams params={textFooter}>Total</TextFromParams>
                    </StyledCell>
                    <StyledCell>
                      <TextFromParams params={textFooter}>
                        {formatToCurrency(totalLast)}
                      </TextFromParams>
                    </StyledCell>
                    <StyledCell>
                      <TextFromParams params={textFooter}>
                        {formatToCurrency(totalCurrent)}
                      </TextFromParams>
                    </StyledCell>
                    <StyledCell>{totalDiff}</StyledCell>
                    <StyledCell />
                  </tr>
                </>
              }
            >
              {(item, index) =>
                index !== furnitureCostsIndex && (
                  <tr key={`${index}${item.categoryCS}`}>
                    <CellWithoutBorder />
                    <td>{getDictiName(item.categoryCS)}</td>
                    <td>
                      {item.prevYearAmount ? (
                        `${formatToCurrency(item.prevYearAmount)}`
                      ) : (
                        <Empty type="hyphen" />
                      )}
                    </td>
                    <td>
                      <Input
                        width="160px"
                        size="sm"
                        type="number"
                        prefix="$"
                        format={integerPositiveThousands}
                        value={item.lastYearAmount}
                        disabled={disabled}
                        errorTooltip
                        onChange={onHandleChange(index, 'lastYearAmount')}
                        {...getNameAndErrorProps(
                          'lastYearAmount',
                          hasError,
                          `furniture.${index}`
                        )}
                      />
                    </td>
                    <td>{diffs[index - 1]}</td>
                    <CellWithoutBorder />
                  </tr>
                )
              }
            </Table>
          </>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepFurnitureFixturesAndEquipment
