import styled from '@emotion/styled/macro'

type GridProps = {
  // className: string
  columns?: string | number
  gap?: string
  columnGap?: string
  rowGap?: string
  height?: string
  minRowHeight?: string
  flow?: string
  rows?: string | number
  areas?: string[]
  justifyContent?: string
  alignContent?: string
}

const formatAreas = areas => areas.map(area => `"${area}"`).join(' ')

const Grid = styled.div<GridProps>(props => ({
  display: 'grid',
  height: props.height ? props.height : 'auto',
  gridAutoFlow: props.flow || 'row',
  gridAutoRows: props.minRowHeight
    ? `minmax(${props.minRowHeight}, auto)`
    : `minmax(20px, auto)`,
  gridTemplateRows:
    typeof props.rows === 'number' ? `repeat(${props.rows}, 1fr)` : props.rows,
  gridTemplateColumns:
    typeof props.columns === 'number'
      ? `repeat(${props.columns}, 1fr)`
      : props.columns,
  gridGap: props.gap || '8px',
  columnGap: props.columnGap,
  rowGap: props.rowGap,
  gridTemplateAreas: props.areas ? formatAreas(props.areas) : undefined,
  justifyContent: props.justifyContent ? props.justifyContent : undefined,
  alignContent: props.alignContent ? props.alignContent : undefined,
}))

export default Grid
