import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import ServicePropertyList from 'ui-framework/components/patterns/PropertyList/ServicePropertyList'
import TAOnboardingBody from 'services/ta/onboarding/index'
import {
  URL_HOME,
  URL_SERVICETAFORM,
  URL_SERVICETAFORMSUMMARY,
} from 'constants/URLS'
import useTaStatus from 'services/ta/useTaStatus'
import { Services } from 'constants/dicti'

//===================================================
type TAListPageProps = {} & BaseComponentProps

/**
 * TAListPage component
 */
const TAListPage = (props: TAListPageProps) => {
  return (
    <>
      <ServicePropertyList
        className={props.className}
        serviceType={Services.TaxAppeal}
        backLink={URL_HOME}
        backLinkText={`Back to Home`}
        formLink={URL_SERVICETAFORM}
        formSummaryLink={URL_SERVICETAFORMSUMMARY}
        useServiceStatus={useTaStatus}
        onboarding={{
          template: <TAOnboardingBody />,
          styles: { minWidth: '980px' },
        }}
      ></ServicePropertyList>
    </>
  )
}

export default TAListPage
