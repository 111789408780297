import { useEffect, useState } from 'react'
import Joi from 'joi'
import { wrap } from 'object-path-immutable'

const useValidation = (
  value,
  schema: Joi.Schema,
  options: any = { context: {} }
): [any, any] => {
  const [errorsRaw, errorsRawSet] = useState<any>()
  const [errors, errorsSet] = useState<any>({})

  useEffect(() => {
    if (!value) return
    options.context = options.context || {}
    options.context.originalValue = value

    const result = wrap({})
    if (Joi.isSchema(schema)) {
      const { error } = schema.validate(value, {
        stripUnknown: false,
        abortEarly: false,
        allowUnknown: true,
        errors: { label: false },
        messages: {
          'any.required': 'Required',
          'any.only': 'Required',
          'number.base': 'Required',
          'string.base': 'Required',
          'boolean.base': 'Required',
        },
        ...options,
      })

      error?.details?.forEach(er => result.set(er.path, [er.message]))
      errorsRawSet(error)
    }
    errorsSet(result.value())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return [errors, errorsRaw]
}

export default useValidation
