import {
  BuildingClasses,
  DocumentType,
  FloorUsesType,
  IncomeSectionTypes,
  PropertyType,
  PropertyUsage,
  TAStatuses,
  TAWizardSections,
  TAWizardSteps,
  UseByApplicant,
} from 'constants/dicti'
import { capitalizeFirstLetter, getFiscalPeriods } from 'helpers/utils'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { StepsConfig } from 'hooks/wizard/useStepsConfig'
import StepFillingTypeSelection from 'ui-framework/components/patterns/Wizard/StepFillingTypeSelection/'
import StepUpload from 'ui-framework/components/patterns/Wizard/StepUpload/'
import StepReportingPeriod from 'ui-framework/components/patterns/Wizard/StepReportingPeriod/'
import {
  asOfJanuary,
  getForamttedDateRangeFromTo,
} from 'pages/Services/Rpie/RpieFormPage/wizard/common'
import StepGeneralInformation from '../steps/PropertyDescription/StepGeneralInformation'
import StepGeneralMainInfo from '../steps/PropertyDescription/StepGeneralMainInfo'
import StepPropertyParking from '../steps/PropertyDescription/StepPropertyParking'
import StepPropertyStatus from '../steps/PropertyDescription/StepPropertyStatus'
import StepPropertyStatusCovid from '../steps/PropertyDescription/StepPropertyStatusCovid'
import StepPropertyUsesAndFloorAreas from '../steps/PropertyDescription/StepPropertyUsesAndFloorAreas'
import StepFurnitureFixturesAndEquipment from 'ui-framework/components/patterns/Wizard/hotel/StepFurnitureFixturesAndEquipment'
import StepHotelOperationInformation from 'ui-framework/components/patterns/Wizard/hotel/StepHotelOperationInformation'
import StepNonDepartmentalOperation from 'ui-framework/components/patterns/Wizard/hotel/StepNonDepartmentalOperation'
import StepRoomRates from 'ui-framework/components/patterns/Wizard/hotel/StepRoomRates'
import StepPropertyDetailsExpense from '../steps/PropertyDetails/IncomeExpense/StepPropertyDetailsExpense'
import StepPropertyDetailsIncome from '../steps/PropertyDetails/IncomeExpense/StepPropertyDetailsIncome'
import StepRentalIncome from '../steps/PropertyDetails/IncomeExpense/StepRentalIncome'
import StepPropertyDetailsConstruction from '../steps/PropertyDetails/StepPropertyDetailsConstruction'
import StepPropertyDetailsConstructionCosts from '../steps/PropertyDetails/StepPropertyDetailsConstructionCosts'
import StepPropertyDetailsNonResidential from '../steps/PropertyDetails/StepPropertyDetailsNonResidential'
import StepPropertyDetailsResidential from '../steps/PropertyDetails/StepPropertyDetailsResidential'
import StepPropertyMainResidentialInfo from '../steps/PropertyDetails/StepPropertyMainResidentialInfo'
import StepSubmit from '../steps/Submit/StepSubmit'
import StepSummary from '../steps/Submit/StepSummary'
import InfoBox from 'ui-framework/components/primitives/InfoBox'
import { WizardConfig } from 'ui-framework/components/patterns/Wizard/ServiceWizard'
import HelpDescriptionGeneralStep from '../assistant/HelpDescriptionGeneralStep'
import HelpGreatJobNextStep from '../assistant/HelpGreatJobNextStep'
import HelpDescriptionStatusUseByApplicantHelp from '../assistant/HelpDescriptionStatusUseByApplicantHelp'
import HelpDescriptionStatusWasAnyConstructionHelp from '../assistant/HelpDescriptionStatusWasAnyConstructionHelp'
import HelpDescriptionConstructionStepHelp from '../assistant/HelpDescriptionConstructionStepHelp'
import HelpDescriptionConstructionCostStepHelp from '../assistant/HelpDescriptionConstructionCostStepHelp'
import HelpDetailsRentRollUploadHelp from '../assistant/HelpDetailsRentRollUploadHelp'
import HelpDetailsRentRollUploadNext from '../assistant/HelpDetailsRentRollUploadNext'
import HelpDetailsResidRentWereVacantHelp from '../assistant/HelpDetailsResidRentWereVacantHelp'
import HelpDetailsIncomeStep from '../assistant/HelpDetailsIncomeStep'
import HelpDetailsExpenseStep from '../assistant/HelpDetailsExpenseStep'
import HelpSummaryStep from '../assistant/HelpSummaryStep'
import PrintPageButton from 'ui-framework/components/primitives/PrintPageButton'
import { negate } from 'lodash-es'
import { ExcelFileFormatMessage } from 'ui-framework/components/patterns/UploadDropZone/UploadDropZoneUI'

export enum TAWizardCustomAction {
  PARTIALLY_SUBMIT = 'partitalSubmit',
  FULL_SUBMIT = 'fullSubmit',
}

function isPartiallySubmitted(property) {
  return [TAStatuses.TC101Ready, TAStatuses.TC101Imported].includes(
    property?.statusCS
  )
}

function isSubmitted(property) {
  return [TAStatuses.Submit].includes(property?.statusCS)
}

function needPartiallySubmission(data, context, construction) {
  return (
    !isPartiallySubmitted(context) &&
    data?.general.useByApplicantCS !== UseByApplicant.Part &&
    data?.general.wasAnyConstruction === construction
  )
}

function getPartiallySubmitNextButtonTitle(data, context, construction) {
  return needPartiallySubmission(data, context, construction)
    ? 'Submit General Info'
    : undefined
}

export const getIESectionIndexByType = (sections, type) =>
  sections.findIndex(s => type.includes(s.typeCS))

const hasPrevValueInIncomeSection = sectionType => (data: TaFormData) => {
  if (!data?.income?.sections) return false
  const sectionIndex = getIESectionIndexByType(
    data.income.sections,
    sectionType
  )

  if (sectionIndex === -1) return false
  return data.income.sections[sectionIndex].items.some(
    i => i.prevYearAmount !== null
  )
},
  hasPrevValueInIncomeFromRentalTenants = hasPrevValueInIncomeSection(
    IncomeSectionTypes.HotelRental
  ),
  hasPrevValueInIncomeFromCondoRental = hasPrevValueInIncomeSection(
    IncomeSectionTypes.CondoRental
  )

export const hasFloorNonResUses = (data, key) =>
  data.uses.usesTables[key].some(
    use => use.useCategoryCS !== FloorUsesType.Resident
  )

//TODO: Move it to the module that implements Application (as entity) business logic
export const isConsolidatedNotRoot = context =>
  !context.isRootConsolidateBBL && context.isConsolidated,
  isNotConsolidatedOrRoot = context =>
    !context.isConsolidated ? true : context.isRootConsolidateBBL

const hideByWasAnyRentalIncome = (data, context) =>
  !data.general.wasAnyRentalIncome &&
  context?.bblsData.propertyTypeCS !== PropertyType.CondoRelation7
  && context?.bblsData.propertyTypeCS !== PropertyType.Coop

export const hidePropertyDetailsSection = (data: TaFormData, context) =>
  data.general.useByApplicantCS === UseByApplicant.Entire ||
  //
  (data.general.useByApplicantCS === UseByApplicant.No &&
    hideByWasAnyRentalIncome(data, context)) ||
  //
  (isConsolidatedNotRoot(context) &&
    [
      BuildingClasses.HB,
      BuildingClasses.HH,
      BuildingClasses.HR,
      BuildingClasses.HS,
      BuildingClasses.H1,
      BuildingClasses.H2,
      BuildingClasses.H3,
      BuildingClasses.H4,
      BuildingClasses.H5,
      BuildingClasses.H6,
      BuildingClasses.H7,
      BuildingClasses.H8,
      BuildingClasses.H9,
    ].includes(context.bblsData.buildingClass as BuildingClasses))

export const consolidatedLotsInfo =
  (params: { options?: object; title?: string } = {}) =>
    (data, context) => {
      const {
        options = { m: '0 0 36px 54px' },
        title = 'Provide combined income & expense info for all consolidated lots below:',
      } = params
      return (
        (context?.consolidatedLots || []).length > 0 && (
          <InfoBox title={title} items={context?.consolidatedLots} {...options} />
        )
      )
    }

const baseFlow = (data, context) =>
  data.general.isNewPropertyForThisYear ||
  [null, true].includes(context?.serviceMetaData?.baseFlow),
  notBaseFlow = negate(baseFlow)

const submitHelp = {
  title: `Submit Data to MGNY`,
  description:
    'Note that you are not submitting the application to the City yet. Our office will be reviewing all the data prior to submission. If any changes are needed, we will contact you.',
}

const getTAWizardSteps = (data: any, context?: any): StepsConfig => {
  const { fiscalYear, reportingYear } = getFiscalPeriods(context),
    forTheYear = year => `For the Year of ${year}`,
    nonResRR = (id, key) => ({
      id,
      section: TAWizardSections.PropertyDetails,
      flows: [PropertyType.Single, PropertyType.CondoRelation1],
      title: 'Non-Residential Uses and Occupancy Status',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyDetailsNonResidential,
      //TODO maybe we can remove callbacks for hidden | disabled ??
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        !hasFloorNonResUses(data, key),
      dataMap: {
        $usesFloorId: key,
        usesTables: 'uses.usesTables',
        // wereAnyVacancies: `uses.usesTables.wereAnyVacancies${capitalizeFirstLetter(
        //   key
        // )}`,
        useByApplicantCS: 'general.useByApplicantCS',
        portionUsedByApplicantCS: 'general.portionUsedByApplicantCS',
        propertyUsageCS: 'general.propertyUsageCS',
      },
      help: {
        vacantSF: {
          title: 'Vacant Space',
          description: `What is the square footage of vacant space as of January 5, ${fiscalYear}, per each use?`,
        },
        applicantSF: {
          title: 'Owner-Occupied Space',
          description: `What is the square footage of the space occupied by an owner / related party, as of January 5, ${fiscalYear}, per each use?`,
        },
        rentedSF: {
          title: 'Rented Space',
          description: `What is the square footage of rented space, as of January 5, ${fiscalYear}, per each use?`,
        },
        $step: {
          title: 'Non-Residential Information',
          description: `Provide detailed information, as of January 5, ${fiscalYear}, about the non-residential space for each requested floor level (uses, square footage, owner-occupancy, and vacancy details).`,
        },
        $next: HelpGreatJobNextStep,
      },
    }),
    getFormattedPeriod = ({ income: { reportingPeriod } }: TaFormData) =>
      reportingPeriod &&
      getForamttedDateRangeFromTo(
        reportingPeriod.startDate,
        reportingPeriod.endDate
      ),
    requiredFieldsInFinalJSON = {
      basement: 'uses.usesTables.basement',
      firstFloor: 'uses.usesTables.firstFloor',
      secondFloor: 'uses.usesTables.secondFloor',
      thirdAndAboveFloors: 'uses.usesTables.thirdAndAboveFloors',
      otherUsesProcessed: 'income.$otherUsesProcessed',
    }

  return [
    // GI ===============
    {
      id: TAWizardSteps.DescriptionReveiw,
      section: TAWizardSections.GeneralInformation,
      flows: ['*'],
      hidden: baseFlow,
      disabled: (data, context) => isPartiallySubmitted(context),
      title: 'Review and Confirm General Information',
      description: asOfJanuary(fiscalYear),
      component: StepGeneralMainInfo,
      nextStepOptions: () => ({
        nextButtonTitle: () => `Confirm & Go to Next Step`,
      }),
      dataMap: {
        // TODO: revert to partial use of general section
        // applicant: 'general.applicant',
        // signatory: 'general.signatory',
        // propertyDescription: 'general.propertyDescription',
        // parking: 'general.parking',
        general: 'general',
        uses: 'uses',
      },
      help: {
        $step: {
          title: 'General',
          description: <HelpDescriptionGeneralStep />,
        },
      },
    },
    {
      id: TAWizardSteps.DescriptionMainInfo,
      section: TAWizardSections.GeneralInformation,
      flows: ['*'],
      hidden: notBaseFlow,
      disabled: (data, context) => isPartiallySubmitted(context),
      title: 'Review and Confirm General Information',
      description: asOfJanuary(fiscalYear),
      component: StepGeneralInformation,
      // nextStepOptions: () => ({
      //   onBefore: () => ({
      //     action: TAWizardCustomAction.PARTIALLY_SUBMIT,
      //   })
      // }),
      dataMap: {
        applicantName: 'general.applicant.name',
        applicantRelation: 'general.applicant.relationCS',
        signatoryName: 'general.signatory.name',
        signatoryTitleCS: 'general.signatory.signatoryTitleCS',
        numberOfBuildings: 'general.propertyDescription.numberOfBuildings',
        numberOfFloorAboveGrade:
          'general.propertyDescription.numberOfFloorAboveGrade',
        yearConstruction: 'general.propertyDescription.yearConstruction',
        yearPurchase: 'general.propertyDescription.yearPurchase',
        residentialInfo: 'uses.residentialInfo',
        nonResidentialInfo: 'uses.nonResidentialInfo',
        isPropertyToNetLease: 'general.isPropertyToNetLease',
      },
      help: {
        $step: {
          title: 'General Information',
          description: <HelpDescriptionGeneralStep />,
        },
        applicantName: {
          title: 'Applicant',
          description:
            'Legal owner or other taxpayer on the tax appeal application.',
        },
        applicantRelation: {
          title: 'Relation',
          description:
            "Applicant's relationship to the property (i.e. owner, net lessee, buyer under contract, condo board, etc.)",
        },
        signatoryName: {
          title: 'Signatory',
          description:
            'Name of the person authorized to sign on behalf of the Applicant who is available to execute tax appeal forms before a notary.',
        },
        signatoryTitle: {
          title: 'Title',
          description:
            "Signatory's official title in relation to the Applicant.",
        },
      },
    },
    {
      id: TAWizardSteps.DescriptionAreas,
      section: TAWizardSections.GeneralInformation,
      flows: ['*'],
      hidden: notBaseFlow,
      disabled: (data, context) => isPartiallySubmitted(context),
      title: 'Review and Confirm Uses and Floor Area',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyUsesAndFloorAreas,
      dataMap: {},
      help: {
        $next: HelpGreatJobNextStep,
      },
    },
    {
      id: TAWizardSteps.DescriptionParking,
      section: TAWizardSections.GeneralInformation,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.CondoRelation7,
        PropertyType.Coop,
        PropertyType.Hotel,
      ],
      title: 'Provide Parking Info',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyParking,
      hidden: notBaseFlow,
      disabled: (data, context) => isPartiallySubmitted(context),
      dataMap: {
        parking: 'general.parking',
      },
      help: {
        $step: {
          description: 'Provide Parking Information.',
        },
        'parking.indoorParkingUses.numberOfPaidParkingSpots': {
          title: '# Of paid indoor parking spaces',
          description:
            'Out of the total # of indoor parking spaces, how many spaces generate income?',
        },
        'parking.outdoorParkingUses.numberOfPaidParkingSpots': {
          title: '# Of paid outdoor parking spaces',
          description:
            'Out of the total # of outdoor parking spaces, how many spaces generate income?',
        },
        $next: HelpGreatJobNextStep,
        'parking.indoorParkingUses.totalNumberOfParkingSpots': {
          title: '# Of Indoor Parking',
          description:
            'Include parking spaces that are entirely enclosed (i.e. spaces inside an enclosed garage).',
        },
        'parking.outdoorParkingUses.totalNumberOfParkingSpots': {
          title: '# Of Outdoor Parking',
          description:
            'Include parking spaces that are outdoor, even if they are only partially enclosed.',
        },
      },
    },
    {
      id: TAWizardSteps.DescriptionCovid,
      section: TAWizardSections.GeneralInformation,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.Hotel,
      ],
      hidden: (data, context) => isConsolidatedNotRoot(context),
      disabled: (data, context) => isPartiallySubmitted(context),
      // title: 'Provide Details About Vacancy and COVID-19',
      title: 'Provide Details About Vacancy',
      component: StepPropertyStatusCovid,
      infoBlock: consolidatedLotsInfo({
        title:
          'Provide combined information about vacancies for all consolidated lots below:',
      }),
      dataMap: {
        $covidFilesPath: `uploads/Covid`,
        propertyUsageCS: 'general.propertyUsageCS',
        applicantName: 'general.applicant.name',
        isCovid: 'general.covid.isCovid',
        covidFiles: 'general.covid.covidFiles',
        useByApplicant: 'general.useByApplicantCS',
        wereAnySubstantialVacancies: 'general.wereAnySubstantialVacancies',
        whatPartsWereAffected: 'general.whatPartsWereAffected',
        whatTriggeredLowCollection: 'general.whatTriggeredLowCollection',
        howMuchIncomeWasLost: 'general.howMuchIncomeWasLost',
        portionVacantTroubleTenantCS: 'general.portionVacantTroubleTenantCS',
      },
      help: {
        $next: HelpGreatJobNextStep,
        $step: {
          // title: 'Provide Details About Vacancy and COVID-19',
          title: 'Provide Details About Vacancy',
          description:
            'Provide information about current and past vacancies with as many details as possible.',
        },
        covidAttentionBox: {
          description:
            'If you do not have any files to upload at this time, you can move forward to the next steps!',
        },
      },
    },
    {
      id: TAWizardSteps.DescriptionStatus,
      section: TAWizardSections.GeneralInformation,
      flows: ['*'],
      disabled: (data, context) => isPartiallySubmitted(context),
      title: 'Provide Property Status Information',
      description: 'Review and Submit Information Below',
      nextStepOptions: (data, context) => ({
        nextButtonTitle: data =>
          getPartiallySubmitNextButtonTitle(data, context, false),
        onBefore: data => {
          if (needPartiallySubmission(data, context, false)) {
            return {
              action: TAWizardCustomAction.PARTIALLY_SUBMIT,
            }
          }
        },
      }),
      component: StepPropertyStatus,
      dataMap: {
        propertyUsage: 'general.propertyUsageCS',
        applicantName: 'general.applicant.name',
        useByApplicant: 'general.useByApplicantCS',
        portionUsedByApplicantCS: 'general.portionUsedByApplicantCS',
        wasAnyConstruction: 'general.wasAnyConstruction',
        wasAnyRentalIncome: 'general.wasAnyRentalIncome',
        wasIncomeFromRentalTenants: 'hotel.wasIncomeFromRentalTenants',
        construction: 'construction',
      },
      help: {
        $step: {
          title: 'Property Status',
          description:
            'Answer each question before proceeding to the next page. Certains terms are defined in the hover over boxes.',
        },
        $next: HelpGreatJobNextStep,
        useByApplicant: {
          description: <HelpDescriptionStatusUseByApplicantHelp />,
        },
        wasAnyConstruction: {
          description: <HelpDescriptionStatusWasAnyConstructionHelp />,
        },
      },
    },
    {
      id: TAWizardSteps.DescriptionConstruction,
      section: TAWizardSections.GeneralInformation,
      flows: ['*'],
      title: 'Construction Details',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyDetailsConstruction,
      hidden: (data: TaFormData) => !data.general.wasAnyConstruction,
      disabled: (data, context) => isPartiallySubmitted(context),
      dataMap: {
        startConstructionDate: 'construction.startConstructionDate',
        completionDate: 'construction.completionDate',
        workDescription: 'construction.workDescription',
        floorAreaNewSpace: 'construction.floorAreaNewSpace',
        floorAreaRenovatedSpace: 'construction.floorAreaRenovatedSpace',
        percentWorkCompleted: 'construction.percentWorkCompleted',
      },
      help: {
        startConstructionDate: {
          title: 'Start Date',
          description: 'When was the permit pulled for the construction work?',
        },
        workDescription: {
          title: 'Construction Work',
          description:
            'What kind of construction work was performed and what type of property was built / improved?',
        },
        floorAreaNewSpace: {
          title: 'New Floor Area',
          description: `If the floor area of the property increased as a result of construction work, add New Floor Area, as of January 5, ${fiscalYear}. If none, put "0."`,
        },
        floorAreaRenovatedSpace: {
          title: 'Renovated Floor Area',
          description: `If any existing floor area was renovated, add Renovated Floor Area, as of January 5, ${fiscalYear}. If none, put "0."`,
        },
        percentWorkCompleted: {
          title: '% of Completion',
          description: `Input the approximate percentage (%) of work completed as of January 5, ${fiscalYear}?`,
        },
        $step: {
          title: 'Construction Information',
          description: <HelpDescriptionConstructionStepHelp />,
        },
        $next: HelpGreatJobNextStep,
      },
    },
    {
      id: TAWizardSteps.DescriptionConstructionCosts,
      section: TAWizardSections.GeneralInformation,
      flows: ['*'],
      hidden: (data: TaFormData) => !data.general.wasAnyConstruction,
      title: 'Construction Costs',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyDetailsConstructionCosts,
      disabled: (data, context) => isPartiallySubmitted(context),
      nextStepOptions: (data, context) => ({
        nextButtonTitle: getPartiallySubmitNextButtonTitle(data, context, true),
        onBefore: data => {
          if (needPartiallySubmission(data, context, true)) {
            return {
              action: TAWizardCustomAction.PARTIALLY_SUBMIT,
            }
          }
        },
      }),
      dataMap: {
        contractCost: 'construction.costs.contractCost',
        financingCost: 'construction.costs.financingCost',
        professionalFee: 'construction.costs.professionalFee',
        otherCost: 'construction.costs.otherCost',
      },
      help: {
        contractCost: {
          title: 'Contract Cost',
          description: `How much was expended in construction costs, as of January 5, ${fiscalYear}?`,
        },
        financingCost: {
          title: 'Financing Costs',
          description: (
            <>
              How much was expended in financing costs, as of January 5,{' '}
              {fiscalYear}? <b>(if none - put "0")</b>
            </>
          ),
        },
        professionalFee: {
          title: 'Professional Fees',
          description: (
            <>
              How much was expended in professional fees, as of January 5,{' '}
              {fiscalYear}? <b>(if none - put "0")</b>
            </>
          ),
        },
        otherCost: {
          title: 'Other Costs',
          description: (
            <>
              How much was expended in all other fees and costs related to this
              project, as of January 5, {fiscalYear}? <b>(if none - put "0")</b>
            </>
          ),
        },
        $step: {
          title: 'Construction Costs',
          description: <HelpDescriptionConstructionCostStepHelp />,
        },
        $next: HelpGreatJobNextStep,
      },
    },

    // RES RR ================
    {
      id: TAWizardSteps.DetailsRentRollFillTypeSelection,
      section: TAWizardSections.PropertyDetails,
      flows: [PropertyType.Single, PropertyType.CondoRelation1],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.propertyUsageCS === PropertyUsage.NonResidential,
      component: StepFillingTypeSelection,
      title: 'Provide Residential Rent Roll Data',
      description: `As of December ${reportingYear} / January ${fiscalYear}`,
      dataMap: {
        fillingType: 'uses.showUploadArea',
        $completeFillingText: 'Complete Manually',
        $infoText: `The monthly rent-roll information should be provided as of December ${reportingYear} / January ${fiscalYear}, with tenant names, unit numbers, and rents.`,
        $questionText:
          'How would you like to provide your residential rent roll data?',
        $docName: 'Rent Roll',
        $docType: DocumentType.ResRentRoll,
      },
      help: {
        manual: {
          title: 'Manual Entry Option',
          description: `You can manually provide rent roll information, as of December ${reportingYear} / January ${fiscalYear}, on our webform, such as monthly rent for the regulated (rent stabilized / rent controlled) and unregulated (market rate) apartments, owner-occupancy and vacancy information.`,
        },
        upload: {
          title: 'Upload Option',
          description: <HelpDetailsRentRollUploadHelp />,
        },
        $next: {
          title: 'Provide Residential Rent Roll Data',
          description: <HelpDetailsRentRollUploadNext />,
        },
      },
    },
    {
      id: TAWizardSteps.DetailsRentRollUpload,
      section: TAWizardSections.PropertyDetails,
      flows: [PropertyType.Single, PropertyType.CondoRelation1],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.propertyUsageCS === PropertyUsage.NonResidential ||
        !data.uses.showUploadArea,
      component: StepUpload,
      title: 'Upload Residential Rent Roll',
      description: `As of December ${reportingYear} / January ${fiscalYear}`,
      dataMap: {
        $docType: DocumentType.ResRentRoll,
        $fileFormatsMessage: <ExcelFileFormatMessage />,
      },
      help: {
        $next: {
          title: 'Go to Next Step',
          description: 'If all files are uploaded, proceed to the Next Step.',
        },
        $step: {
          title: 'Upload files',
          description: 'You may upload more than 1 file.',
        },
      },
    },
    {
      id: TAWizardSteps.DetailsResidentialMain,
      flows: [PropertyType.Single, PropertyType.CondoRelation1],
      section: TAWizardSections.PropertyDetails,
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.propertyUsageCS === PropertyUsage.NonResidential ||
        data.uses.showUploadArea,
      title: 'Main Residential Information',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyMainResidentialInfo,
      dataMap: {
        checkTotalNumberOfResidUnits:
          'uses.residentialInfo.$checkTotalNumberOfResidUnits',
        numberOfResidentialUnit:
          'uses.residentialInfo.residentialUnits.numberOfResidentialUnit',
        numberOfRegulatedUnits:
          'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfUnits',
        numberOfUnRegulatedUnits:
          'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfUnits',
        useByApplicantCS: 'general.useByApplicantCS',
        portionUsedByApplicantCS: 'general.portionUsedByApplicantCS',
        ownerOccupied: 'uses.residentialInfo.ownerOccupied.numberTotalUnits',
        location: 'uses.residentialInfo.ownerOccupied.ownerFloors',
      },
      help: {
        $step: {
          title: 'Residential Information',
          description: `Confirm the total number of apartments as of January 5, ${fiscalYear}
            and provide the breakdown by type.`,
        },
        $next: HelpGreatJobNextStep,
        numberOfRegulatedUnits: {
          title: 'Regulated Units',
          description:
            'These apartments are subject to Rent Stabilization law and must be annually registered with DHCR.',
        },
        numberOfUnRegulatedUnits: {
          title: 'Unregulated Units',
          description:
            'These apartments are free market and ARE NOT subject to any rent regulations.',
        },
      },
    },
    {
      id: TAWizardSteps.DetailsResidentialRent,
      flows: [PropertyType.Single, PropertyType.CondoRelation1],
      section: TAWizardSections.PropertyDetails,

      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.propertyUsageCS === PropertyUsage.NonResidential ||
        data.uses.showUploadArea,
      title: 'Residential Rent Information',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyDetailsResidential,
      dataMap: {
        residentialUnits: 'uses.residentialInfo.residentialUnits',
        portionVacantTroubleTenantCS: 'general.portionVacantTroubleTenantCS',
      },
      help: {
        wereAnyUnitsVacant: {
          title: `Have any apartments been vacant as of January 5, ${fiscalYear}?`,
          description: <HelpDetailsResidRentWereVacantHelp />,
        },
        regulatedNumberOfVacantUnits: {
          title: 'Vacant Units',
          description: `How many units have been vacant as of January 5, ${fiscalYear} in each category?`,
        },
        regulatedMonthlyRentLossDueVacant: {
          title: 'Monthly Rent Loss Due to Vacancy',
          description:
            'How much rent ($) will be lost each month from all vacant units?',
        },
        regulatedNumberOfRentedUnits: {
          title: 'Rented Units',
          description: `How many units have been rented out as of January 5, ${fiscalYear} in each category?`,
        },
        monthlyRent: {
          title: 'Total Monthly Rent from All Rented Units',
          description: `How much rent/month was due from all rented units as of January 5, ${fiscalYear}?`,
        },
        unregulatedNumberOfVacantUnits: {
          title: 'Vacant Units',
          description: `How many units have been vacant as of January 5, ${fiscalYear} in each category?`,
        },
        unregulatedMonthlyRentLossDueVacant: {
          title: 'Monthly Rent Loss Due to Vacancy',
          description:
            'How much rent ($) will be lost each month from all vacant units?',
        },
        unregulatedNumberOfRentedUnits: {
          title: 'Rented Units',
          description: `How many units have been rented out as of January 5, ${fiscalYear} in each category?`,
        },
        vacantApartmentNumber3Month: {
          title: 'Unit Numbers of Apartments',
          description: 'Example: Unit 12-A, Suite # 1, 4C.',
        },
        $next: HelpGreatJobNextStep,
      },
    },

    // NONRES RR =============
    nonResRR(TAWizardSteps.DetailsNonResidentialBasement, 'basement'),
    nonResRR(TAWizardSteps.DetailsNonResidentialFirst, 'firstFloor'),
    nonResRR(TAWizardSteps.DetailsNonResidentialSecond, 'secondFloor'),
    nonResRR(TAWizardSteps.DetailsNonResidentialThird, 'thirdAndAboveFloors'),

    // HOTELS ================
    {
      id: TAWizardSteps.HotelOperation,
      flows: [PropertyType.Hotel],
      hidden: (data, context) => hidePropertyDetailsSection(data, context),
      section: TAWizardSections.PropertyDetails,
      title: 'Hotel Operation Information',
      component: StepHotelOperationInformation,
      description: asOfJanuary(fiscalYear),
      dataMap: {
        applicantName: 'general.applicant.name',
        hotelName: 'hotel.hotelName',
        isManagedByUnrelatedEntity: 'hotel.managedByUnrelatedEntity',
        haveProprietaryRights: 'hotel.haveProprietaryRights',
        haveProprietaryRightsDescription:
          'hotel.haveProprietaryRightsDescription',
        totalRoomsNumber: 'hotel.totalRoomsNumber',
        keysNumber: 'hotel.keysNumber',
        transientRoomsNumber: 'hotel.transientRoomsNumber',
        permanentRoomsNumber: 'hotel.permanentRoomsNumber',
        occupancyRate: 'hotel.occupancyRate',
      },
      help: {
        $step: {
          title: 'Hotel Operation Information',
          description: 'Provide information related to hotel operations.',
        },
        $next: HelpGreatJobNextStep,
        hotelName: {
          title: 'Hotel Name',
          description:
            'Indicate the brand name of the hotel or franchise name.',
        },
        isManagedByUnrelatedEntity: {
          title: 'Managed By An Entity',
          description:
            'Respond Yes if hotel is managed and operated by a different entity unrelated to the entity appealing the assessment.',
        },
        haveProprietaryRights: {
          title: 'Proprietary Rights',
          description:
            'Answer Yes if any additional entity has rights to occupy or receive proceeds from any of the rooms.',
        },
        haveProprietaryRightsDescription: {
          title: 'Proprietary Rights Description',
          description: 'Provide the names of the owners and room numbers.',
        },
        totalRoomsNumber: {
          title: '# of All Rooms',
          description: 'Provide the total number of rooms in the hotel.',
        },
        keysNumber: {
          title: '# of Keys',
          description:
            'For number of keys, list the total number of hotel keys available. For example, a 2-room suite counts as 1 key.',
        },
        transientRoomsNumber: {
          title: '# of Transient Rooms',
          description:
            'List the number of rooms that are available to be rented out nightly.',
        },
        permanentRoomsNumber: {
          title: '# of Permanent Rooms',
          description:
            'List the number of rooms that have permanent or long-term tenants.',
        },
        occupancyRate: {
          title: `Average Occupancy Rate for ${reportingYear} (%)`,
          description: 'Provide the average occupancy rate for the year. ',
        },
      },
    },
    {
      id: TAWizardSteps.HotelRoomRates,
      flows: [PropertyType.Hotel],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        !data.hotel.totalRoomsNumber,
      section: TAWizardSections.PropertyDetails,
      component: StepRoomRates,
      title: 'Room Rates',
      description: asOfJanuary(fiscalYear),
      dataMap: {
        roomRates: 'hotel.roomRates',
        totalRoomsNumber: 'hotel.totalRoomsNumber',
      },
      help: {
        $step: {
          title: 'Room Rates',
          description: `Provide the relevant information for each room type and rate as of January 5, ${fiscalYear}.`,
        },
      },
    },

    // IE ====================
    {
      id: TAWizardSteps.ReportingPeriod,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.CondoRelation7,
        PropertyType.Coop,
        PropertyType.Hotel,
      ],
      section: TAWizardSections.PropertyDetails,
      title: 'Reporting Period and Accounting Basis',
      description: forTheYear(reportingYear),
      component: StepReportingPeriod,
      infoBlock: consolidatedLotsInfo(),
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        // data.income.showUploadArea ||
        data.general.isPropertyToNetLease ||
        hideByWasAnyRentalIncome(data, context) ||
        isConsolidatedNotRoot(context),
      dataMap: {
        $reportingYear: reportingYear,
        reportingPeriodTypeCS: 'income.reportingPeriod.reportingPeriodTypeCS',
        startDate: 'income.reportingPeriod.startDate',
        endDate: 'income.reportingPeriod.endDate',
        accountingBasisCS: 'income.reportingPeriod.accountingBasisCS',
        hasCustomPeriod: 'income.reportingPeriod.hasCustomPeriod',
      },
      help: {
        reportingPeriodTypeCS: {
          title: 'Reporting Period Year',
          description:
            'Select the reporting period year for which the I&E will be provided.',
        },
        reportingPeriodCS: {
          title: 'Reporting Period',
          description: 'Select the reporting period for the I&E.',
        },
        accountingBasisCS: {
          title: 'Accounting Basis',
          description: 'Select the accounting basis for the I&E.',
        },
        $step: {
          title: 'Reporting Period Year',
          description:
            'Select the reporting period year for which the I&E will be provided.',
        },
        $next: HelpGreatJobNextStep,
      },
    },
    {
      id: TAWizardSteps.IncomeFillTypeSelection,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.CondoRelation7,
        PropertyType.Coop,
        PropertyType.Hotel,
      ],
      hidden: (data: TaFormData, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.isPropertyToNetLease ||
        hideByWasAnyRentalIncome(data, context) ||
        isConsolidatedNotRoot(context),
      section: TAWizardSections.PropertyDetails,

      component: StepFillingTypeSelection,
      title: 'Provide Income and Expense Data',
      description: forTheYear(reportingYear),
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        fillingType: 'income.showUploadArea',
        $completeFillingText: 'Complete Manually',
        $questionText:
          'How would you like to provide your income and expense data?',
        $docName: 'P&L',
        $docType: DocumentType.Income,
      },
      help: {
        manual: {
          title: 'Income & Expense Info',
          description: `You can provide the I&E for ${reportingYear} by completing data manually for specific I&E categories required by the Tax Commission.`,
        },
        upload: {
          title: 'Profit & Loss Statement',
          description: `You can upload a copy of your ${reportingYear} Annual Profit and Loss Statement, and our team will enter the information into the system.`,
        },
        $next: {
          title: 'Income & Expense',
          description: `The income & expense information should be provided for the year of ${reportingYear}.`,
        },
      },
    },
    {
      id: TAWizardSteps.IncomeUpload,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.CondoRelation7,
        PropertyType.Coop,
        PropertyType.Hotel,
      ],
      section: TAWizardSections.PropertyDetails,
      component: StepUpload,
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        !data.income.showUploadArea ||
        data.general.isPropertyToNetLease ||
        hideByWasAnyRentalIncome(data, context) ||
        isConsolidatedNotRoot(context),
      title: (data: TaFormData) =>
        data.income.files?.length > 0
          ? 'Your P&L is Uploaded'
          : 'Upload Your P&L',
      description: forTheYear(reportingYear),
      dataMap: {
        $docType: DocumentType.Income,
        $fileFormatsMessage: <ExcelFileFormatMessage />,
      },
      help: {
        $next: {
          title: 'Next Step',
          description: 'If all files are uploaded, proceed to the Next Step.',
        },
        $step: {
          title: 'Upload files',
          description: 'You may upload more than 1 file.',
        },
      },
    },

    {
      id: TAWizardSteps.HotelNonDepartmentalOp,
      flows: [PropertyType.Hotel],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.isPropertyToNetLease,
      section: TAWizardSections.PropertyDetails,
      component: StepNonDepartmentalOperation,
      title: 'Non-Departmental Operation',
      description: asOfJanuary(fiscalYear),
      dataMap: {
        isNewPropertyForThisYear: 'general.isNewPropertyForThisYear',
        $hasPrevValueInIncomeFromRentalTenants:
          hasPrevValueInIncomeFromRentalTenants(data),
        wasIncomeFromRentalTenants: 'hotel.wasIncomeFromRentalTenants',
        isAnySpaceRelatedToOperator: 'hotel.isAnySpaceRelatedToOperator',
        anySpaceRelatedToOperatorDescription:
          'hotel.anySpaceRelatedToOperatorDescription',
      },
      help: {
        wasIncomeFromRentalTenants: {
          title: 'Income collected from rental tenants',
          description:
            'Answer Yes if there are apartments, stores, restaurants, offices and any other leased areas within this property.',
        },
        $step: {
          title: 'Income collected from rental tenants',
          description:
            'Provide information related to non-departmental operations.',
        },
      },
    },
    {
      id: TAWizardSteps.RentalIncome,
      flows: [PropertyType.CondoRelation7, PropertyType.Coop],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.income.showUploadArea ||
        // !(
        //   data.general.isNewPropertyForThisYear &&
        //   hasPrevValueInIncomeFromCondoRental(data)
        // ) ||
        data.general.isPropertyToNetLease ||
        !data.general.wasAnyRentalIncome,
      section: TAWizardSections.PropertyDetails,
      component: StepRentalIncome,
      title: 'Rental Income',
      description: getFormattedPeriod,
      dataMap: {
        wasIncomeFromRentalTenants: 'hotel.wasIncomeFromRentalTenants',
      },
      help: {
        $step: {
          title: 'Rental Income',
          description: 'Provide information about rental income.',
        },
        wasIncomeFromRentalTenants: {
          title: 'Rental Income',
          description:
            'Answer Yes, if condominium received rental income from commercial and residential spaces within the building.',
        },
        $next: HelpGreatJobNextStep,
      },
    },
    {
      id: TAWizardSteps.Income,
      section: TAWizardSections.PropertyDetails,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.CondoRelation7,
        PropertyType.Coop,
        PropertyType.Hotel,
      ],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.income.showUploadArea ||
        data.general.isPropertyToNetLease ||
        hideByWasAnyRentalIncome(data, context) ||
        isConsolidatedNotRoot(context),
      title: 'Income',
      description: getFormattedPeriod,
      component: StepPropertyDetailsIncome,
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        otherUsesProcessed: 'income.$otherUsesProcessed',
        sections: 'income.sections',
        usesTables: 'uses.usesTables',
        isResidentialUploaded: 'uses.showUploadArea',
        numberOfResidentialUnit:
          'uses.residentialInfo.residentialUnits.numberOfResidentialUnit',
        numberOfRegulatedUnits:
          'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfUnits',
        numberOfUnregulatedUnits:
          'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfUnits',
        portionVacantTroubleTenantCS: 'general.portionVacantTroubleTenantCS',
        wasIncomeFromRentalTenants: 'hotel.wasIncomeFromRentalTenants',
      },
      help: {
        $step: {
          title: 'Income Info',
          description: <HelpDetailsIncomeStep />,
        },
        $next: HelpGreatJobNextStep,
      },
    },
    {
      id: TAWizardSteps.Expense,
      section: TAWizardSections.PropertyDetails,
      flows: [
        PropertyType.Single,
        PropertyType.CondoRelation1,
        PropertyType.CondoRelation7,
        PropertyType.Coop,
        PropertyType.Hotel,
      ],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.income.showUploadArea ||
        data.general.isPropertyToNetLease ||
        hideByWasAnyRentalIncome(data, context) ||
        isConsolidatedNotRoot(context),
      title: 'Provide Expense Information',
      description: getFormattedPeriod,
      component: StepPropertyDetailsExpense,
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        sections: 'expense.sections',
      },
      help: {
        $step: {
          title: 'Expenses Info',
          description: <HelpDetailsExpenseStep />,
        },
        $next: HelpGreatJobNextStep,
      },
    },
    {
      id: TAWizardSteps.HotelFurnitureFixturesAndEquipment,
      section: TAWizardSections.PropertyDetails,
      flows: [PropertyType.Hotel],
      hidden: (data, context) =>
        hidePropertyDetailsSection(data, context) ||
        data.general.isPropertyToNetLease,
      component: StepFurnitureFixturesAndEquipment,
      title: 'Furniture, Fixtures and Equipment',
      description: getFormattedPeriod,
      dataMap: {
        isThereFurniture: 'hotel.furniture.isThereFurniture',
        furniture: 'hotel.furniture.items',
      },
      help: {
        $step: {
          title: 'Furniture, Fixtures and Equipment',
          description:
            'Provide information about Furniture, Fixtures and Equipment.',
        },
        isThereFurniture: {
          title: 'Provide information about FFE',
          description:
            'Answer Yes, if there is a reserve for replacement of movable furniture, fixtures or other equipment that have no permanent connection to the structure of the building or utilities',
        },
        $next: HelpGreatJobNextStep,
      },
    },

    // SUBMIT =============
    {
      id: TAWizardSteps.Summary,
      flows: ['*'],
      section: TAWizardSections.ReviewAndSubmit,
      actions: <PrintPageButton>Print Summary Page</PrintPageButton>,
      title: 'Summary Page',
      component: StepSummary,
      dataMap: {},
      help: {
        $step: {
          title: 'Summary Page',
          description: <HelpSummaryStep />,
        },
      },
    },
    {
      id: TAWizardSteps.Submit,
      flows: ['*'],
      section: TAWizardSections.ReviewAndSubmit,
      hidden: (data, context) => isSubmitted(context) && !context.isAdmin,
      title: `Submit Information for ${fiscalYear} Tax Appeal Application`,
      nextStepOptions: () => ({
        nextButtonTitle: () => 'Submit Data to MGNY',
        onBefore: () => ({
          action: TAWizardCustomAction.FULL_SUBMIT,
        }),
      }),
      component: StepSubmit,
      dataMap: {
        ...requiredFieldsInFinalJSON,
        certified: '$certified',
      },
      help: {
        helpForTheHelp: submitHelp,
        $step: submitHelp,
        $next: submitHelp,
      },
    },
  ]
}

const taWizardConfig: WizardConfig = {
  steps: getTAWizardSteps as StepsConfig,
  flowFilterValue: 'bblsData.propertyTypeCS',
  assistant: {
    title: 'Tax Appeal Assistant',
  },
}

export default taWizardConfig
