import React, { ReactNode } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Tabs, { TabItem } from 'ui-framework/components/primitives/Tabs'
import { OnChangeHandler } from 'ui-framework/common/types'
import { Box, Flex } from '@chakra-ui/layout'
import { chakra, Divider } from '@chakra-ui/react'

/**
 * PageGridLayoutTemplate component
 */

const ActionsContainer = chakra('div', {
  baseStyle: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '80px',
    '& > :first-of-type': {
      top: '-9px',
    },
  },
})

const PageTitle = chakra('h1', {
  baseStyle: {
    textStyle: 'h4',
    color: 'fontnavy',
    paddingLeft: '40px',
  },
})

type PageTabbedLayoutProps = {
  title: any
  tabs?: TabItem[]
  activeTab: string
  tabVariant?: string
  onTabChange: OnChangeHandler<string>
  actionBar?: ReactNode
  mainActions?: ReactNode
  message?: any
  leftAction?: React.ReactChild
} & BaseComponentProps

const PageTabbedLayout = ({
  title,
  tabs,
  activeTab,
  onTabChange,
  actionBar,
  mainActions,
  message,
  leftAction,
  tabVariant,
  ...props
}: PageTabbedLayoutProps) => {
  return (
    <Box mt="36px" className={props.className}>
      {leftAction && <Box pl={`40px`}>{leftAction}</Box>}
      <PageTitle>{title}</PageTitle>
      <Box m="39px 0 12px">
        <Flex>
          {tabs && (
            <Tabs
              variant={tabVariant}
              items={tabs}
              active={activeTab}
              onClick={onTabChange}
              width={'100%'}
            />
          )}
          {mainActions && <ActionsContainer>{mainActions}</ActionsContainer>}
        </Flex>
        <Divider mt="-8px" />
      </Box>
      {props.children}
    </Box>
  )
}

export default PageTabbedLayout
