type ColorsStructure = {
  bg: string
  subBg: string
}

export const Colors: ColorsStructure[] = [
  { bg: '#FFF9FD', subBg: 'red.opacity' },
  { bg: '#FDF4F4', subBg: '#F7E7E7' },
  { bg: '#FEF9F6', subBg: 'orange.opacity' },
  { bg: '#FFFDF4', subBg: '#FFFBE6' },
  { bg: '#FBFFF4', subBg: 'green.opacity' },
  { bg: '#F5FDF8', subBg: '#E7F7ED' },
  { bg: '#F3FBFE', subBg: '#E7F3F7' },
  { bg: '#F8F8FD', subBg: '#E7E9F7' },
  { bg: '#F9F4FC', subBg: '#F0E7F7' },
  { bg: '#FEF6FB', subBg: '#F7E7F1' },
]
