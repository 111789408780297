import React, { useMemo, useCallback, ReactNode } from 'react'

import {
  AnyFunction,
  getCallback,
  mapObjectToIdNameItems,
  getTotalDiffWithText,
  isIndexOutOfArray,
  isCallback,
  getNameAndErrorProps,
} from 'helpers/utils'

import { del, set, push } from 'object-path-immutable'

import { integerPositiveThousands } from 'helpers/formats'
import Icon from 'ui-framework/components/primitives/Icon'
import Input from 'ui-framework/components/primitives/Input'
import Button from 'ui-framework/components/primitives/Button'

import DropdownList from 'ui-framework/components/primitives/DropdownList'
import { getDictiName, IncomeCategories } from 'constants/dicti'
import TableWithTitle from 'ui-framework/components/patterns/TableWithTitle'
import { OnChangeHandler } from 'ui-framework/common/types'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import { basicTextFooterSubtotal } from 'ui-framework/components/patterns/Wizard/SectionsTable'
import { formatToCurrencyOrDash } from 'helpers/formatter'
import HelpTarget from '../HelpTarget'
import useSectionPrompts from './useSectionPrompts'
import { Box } from '@chakra-ui/react'

export enum categoriesEditTypeCS {
  list = 'CS_FIELD_TYPE_FOR_EDIT_LIST',
  manually = 'CS_FIELD_TYPE_FOR_EDIT_MANUALLY',
}

type SectionItem = {
  lastYearAmount: number | null
  prevYearAmount: number | null
  [key: string]: any
}

type Sections = Array<{
  typeCS: string
  items: SectionItem[]
}>

const getFooter = ({ totalLast, totalCurrent, totalDiff }) => (
  <tr style={{ height: '70px', verticalAlign: 'top' }}>
    <td />
    <td>
      <TextFromParams params={basicTextFooterSubtotal}>Subtotal</TextFromParams>
    </td>
    <td>
      <TextFromParams params={basicTextFooterSubtotal}>
        {formatToCurrencyOrDash(totalLast)}
      </TextFromParams>
    </td>
    <td>
      <TextFromParams params={basicTextFooterSubtotal}>
        {formatToCurrencyOrDash(totalCurrent)}
      </TextFromParams>
    </td>
    <td colSpan={3}>
      <TextFromParams params={basicTextFooterSubtotal}>
        {totalDiff}
      </TextFromParams>
    </td>
  </tr>
)

const useSections = (
  sections: Sections,
  preparedSections: any[],
  disabled?: boolean,
  colSpan?: number,
  onChange?: OnChangeHandler<any>,
  hasError?: any
): ReactNode => {
  const getPromptByItem = useSectionPrompts(preparedSections)

  const handleAddCategory = useCallback(
    (item, sectionIndex) => {
      getCallback(onChange)(
        push({ sections }, ['sections', sectionIndex, 'items'], item)
      )
    },
    [onChange, sections]
  ),
    handleManuallyChange = useCallback(
      (index, sectionIndex, empty) => (value, name) => {
        if (isIndexOutOfArray(sections[sectionIndex].items, index)) {
          handleAddCategory(set(empty, name, value), sectionIndex)
        } else {
          getCallback(onChange)(
            value,
            `sections.${sectionIndex}.items.${index}.${name}`
          )
        }
      },
      [handleAddCategory, onChange, sections]
    ),
    handleDelete = useCallback(
      (index, sectionIndex) => () => {
        getCallback(onChange)(
          del({ sections }, ['sections', sectionIndex, 'items', index])
        )
      },
      [onChange, sections]
    ),
    nameAndErrorProps = useCallback(
      (name, sectionIndex, index) =>
        getNameAndErrorProps(
          name,
          hasError,
          `sections.${sectionIndex}.items.${index}`
        ),
      [hasError]
    ),
    createView = useCallback(
      section => {
        const {
          visible = true,
          items,
          title,
          sectionIndex,
          originalSectionItemsCount,
          categoriesEditType,
          categoriesForList,
          newLineOptions,
          lastYearPrompt,
          manuallyPrompt,
          getSectionContent,
          readOnly = false,
        } = section,
          getListOfItems = categoriesCS =>
            mapObjectToIdNameItems(categoriesForList).map(category => {
              category['$show'] =
                !categoriesCS.includes(category.id) //&&
              // TODO: #14547 make unable to select TenantCharges category. remove after season!!!
              // category.id !== IncomeCategories.TenantCharges
              return category
            }),
          totals = getTotalDiffWithText(
            items,
            'prevYearAmount',
            'lastYearAmount',
            basicTextFooterSubtotal
          ),
          createInputByFieldNameAndParams = (
            item,
            index,
            fieldName = 'lastYearAmount',
            fieldParams: object = {
              prefix: '$',
              type: 'number',
              format: integerPositiveThousands,
            }
          ) =>
            readOnly ? (
              formatToCurrencyOrDash(item[fieldName])
            ) : (
              <Input
                width="calc(100% - 20px)"
                maxWidth="240px"
                size="sm"
                value={item[fieldName]}
                disabled={disabled}
                errorTooltip
                onChange={handleManuallyChange(
                  index,
                  sectionIndex,
                  newLineOptions?.empty
                )}
                postfix={
                  isCallback(lastYearPrompt) &&
                  getCallback(lastYearPrompt)(item, index, totals.diffsValues)
                }
                {...nameAndErrorProps(fieldName, sectionIndex, index)}
                {...fieldParams}
              />
            ),
          getFieldByType = (item, index) => {
            const { categoryCS, categoryName } = item
            const valueShow = () => (
              <HelpTarget name={categoryCS}>
                {getDictiName(categoryCS, categoryCS || categoryName)}
              </HelpTarget>
            )

            if (readOnly) return valueShow()
            switch (categoriesEditType) {
              case categoriesEditTypeCS.manually:
                return createInputByFieldNameAndParams(
                  item,
                  index,
                  'categoryName',
                  {
                    postfix:
                      isCallback(manuallyPrompt) &&
                      getCallback(manuallyPrompt)(item, index),
                    type: 'text',
                    placeholder: 'Enter category',
                  }
                )
              case categoriesEditTypeCS.list:
                return (
                  <DropdownList
                    maxWidth="280px"
                    width="calc(100% - 20px)"
                    size="sm"
                    value={categoryCS}
                    disabled={disabled}
                    listWidth="280px"
                    listMaxHeight="230px"
                    itemId="id"
                    itemValue="name"
                    items={getListOfItems(items.map(item => item.categoryCS))}
                    errorTooltip
                    onChange={handleManuallyChange(
                      index,
                      sectionIndex,
                      newLineOptions?.empty
                    )}
                    {...nameAndErrorProps('categoryCS', sectionIndex, index)}
                  />
                )
              default:
                return valueShow()
            }
          },
          hasRemoveRowButton =
            newLineOptions && !!sections[sectionIndex].items.length,
          createRemoveRowButton = (condition: boolean) => (index: number) =>
            condition && (
              <Icon
                size="sm"
                color="fontandicongray"
                tabIndex={0}
                onClick={newLineOptions.confirmDelete(
                  handleDelete(index, sectionIndex)
                )}
              >
                close
              </Icon>
            ),
          createDefaultContent = () =>
            items.map((item: SectionItem, index: number) => {
              if (item.$show === false) return null

              const [cellView, prompt] = getPromptByItem(
                item.categoryCS,
                getFieldByType(item, index),
                colSpan
              )

              return (
                <>
                  <tr
                    key={`${index}-${item.categoryCS}`}
                    style={{ height: '56px' }}
                    className={prompt ? 'noborder' : ''}
                  >
                    <td>{cellView}</td>
                    <td>{formatToCurrencyOrDash(item.prevYearAmount)}</td>
                    <td>{createInputByFieldNameAndParams(item, index)}</td>
                    <td>{totals.diffs[index]}</td>
                    <td>
                      {createRemoveRowButton(!readOnly && hasRemoveRowButton)(
                        index
                      )}
                    </td>
                    <CellWithoutBorder />
                  </tr>
                  {prompt}
                </>
              )
            }),
          content = getSectionContent
            ? getCallback(getSectionContent)(
              items,
              totals,
              getFieldByType,
              createInputByFieldNameAndParams,
              createRemoveRowButton(!readOnly && hasRemoveRowButton),
              getPromptByItem,
              readOnly
            )
            : createDefaultContent()

        return (
          visible && (
            <TableWithTitle
              categoryTitle={title}
              items={items}
              footer={content?.footer || getFooter(totals)}
            >
              {content?.body || content}
              {newLineOptions && !readOnly && (
                <tr>
                  <td colSpan={colSpan}>
                    <Button
                      disabled={
                        disabled ||
                        (newLineOptions?.getDisabled &&
                          (newLineOptions?.getDisabled as AnyFunction)(
                            originalSectionItemsCount
                          ))
                      }
                      onClick={() =>
                        handleAddCategory(newLineOptions?.empty, sectionIndex)
                      }
                      variant="textPrimary"
                    >
                      {newLineOptions?.text || 'Add new category'}
                    </Button>
                  </td>
                </tr>
              )}
            </TableWithTitle>
          )
        )
      },
      [
        sections,
        colSpan,
        disabled,
        handleAddCategory,
        handleDelete,
        handleManuallyChange,
        nameAndErrorProps,
        getPromptByItem,
      ]
    ),
    view = useMemo(
      () => preparedSections.map(createView),
      [preparedSections, createView]
    )
  return view
}

export default useSections

//TODO: replace all width="calc(100% - 20px)" in this file with wrapper
