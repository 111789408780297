import React, {
  ReactNode,
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled/macro'

import Subtitle from '../Subtitle'

import BaseComponentProps from 'common/BaseComponentProps'
import {
  CustomWizardAction,
  Step as StepConfig,
} from 'hooks/wizard/useStepsConfig'
import { Box, chakra, TooltipProps } from '@chakra-ui/react'
import { OnChangeHandler } from 'ui-framework/common/types'
import BackAndNext from 'ui-framework/components/layout/BackAndNext'
import { AnyFunction, getCallback, IsAdmin, isCallback } from 'helpers/utils'
import useStepState from 'hooks/wizard/useStepState'
import { negate, pick } from 'lodash-es'

export const StepWrapper = styled('div')<{}>(props => ({}))

//===================================================
type Props = {
  showBack: boolean
  step: StepConfig
  serviceData: any
  autosaveEnabled: boolean
  autosaveEnabledSet: OnChangeHandler<boolean>
  onChange: OnChangeHandler<any>
  onCustomAction?: (customAction: CustomWizardAction) => Promise<boolean>
} & BaseComponentProps

/**
 * Step component
 */
const Step = ({
  showBack,
  step,
  serviceData,
  autosaveEnabled,
  autosaveEnabledSet,
  onChange,
  onCustomAction,
  // onNext,
  // onBack,
  ...props
}: Props) => {
  const admin = IsAdmin(),
    Component = step.component,
    disabledNext = useMemo(
      () =>
        Boolean(
          admin &&
          !autosaveEnabled &&
          step &&
          step.nextStepOptions?.nextButtonTitle &&
          step.nextStepOptions?.onBefore
        ),
      [admin, autosaveEnabled, step]
    )

  const [triedToGoNext, triedToGoNextSet] = useState(false),
    [allowNext, allowNextSet] = useState(false),
    [isCurrentStepValid, isCurrentStepValidSet] = useState(true),
    {
      props: stepProps,
      onChange: stepOnChange,
      getUpdatedData,
    } = useStepState(serviceData, pick(step, ['id', 'dataMap'])),
    //
    resultData = useMemo(() => {
      if (!allowNext) return null
      return getUpdatedData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowNext]),
    nextButtonText = useMemo(
      () =>
        isCallback(step?.nextStepOptions?.nextButtonTitle)
          ? getCallback(step?.nextStepOptions?.nextButtonTitle)(
            getUpdatedData()
          )
          : step?.nextStepOptions?.nextButtonTitle,
      [getUpdatedData, step?.nextStepOptions?.nextButtonTitle]),
    handleOnNext = useCallback(() => {
      allowNextSet(isCurrentStepValid)
      triedToGoNextSet(true)
    }, [isCurrentStepValid]),
    handleOnBack = useCallback(() => {
      getCallback(onChange)(getUpdatedData(), -1)
    }, [getUpdatedData, onChange])

  useEffect(() => {
    if (resultData !== null) {
      getCallback(onChange)(resultData, 1)
      allowNextSet(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData])

  useEffect(() => {
    triedToGoNextSet(false)
    allowNextSet(false)
    isCurrentStepValidSet(true)
  }, [step.id])

  if (!step || step.id !== stepProps.stepId) return null
  return (
    <StepWrapper id={step?.id} data-type="step" className={props.className}>
      <Subtitle
        title={step.title as string}
        description={step.description as string}
        buttons={step.buttons}
        margin={'0 0 0 54px'}
      />
      <chakra.div pt="12px">
        <>
          {step?.infoBlock}
          <Component
            {...stepProps}
            onChange={stepOnChange}
            applyValidation={triedToGoNext}
            onValidation={v => isCurrentStepValidSet(!v)}
            disabled={step?.disabled}
          />
        </>
      </chakra.div>
      <Box
        width="100%"
        paddingLeft="55px"
        paddingRight="24px"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <BackAndNext
          nextButtonText={nextButtonText || 'Next Step'}
          backButtonText="Back"
          showBack={showBack}
          onClick={handleOnNext}
          onBackClick={handleOnBack}
          // onSave={handleSave}
          onChangeAutosave={autosaveEnabledSet}
          autosave={autosaveEnabled}
          disabled={disabledNext}
          promptCfg={
            {
              label: `Enable autosave in order to submit`,
              hidden: !disabledNext,
              placement: 'bottom',
            } as TooltipProps
          }
        >
          {step?.actions}
        </BackAndNext>
      </Box>
    </StepWrapper>
  )
}

export default Step
