import { ComponentStyleConfig } from '@chakra-ui/theme'
import { getTabFocusSelectors } from 'helpers/utils'

const DropdownListStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'wrapperOuter', 'valueWrapper', 'bottomBlock'],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      width: '100%',
      input: {
        textTransform: props.useListFilter ? 'none' : 'capitalize',
        cursor: props.useListFilter ? 'text' : 'pointer',
      },
      '.icon': {
        '&.up, &.down': {
          svg: {
            width: '10px',
            height: '10px',
          },
        },
      },
    },
    wrapperOuter: {
      overflowY: 'auto',
      boxShadow: '0 0 0 .1px rgba(0, 0, 0, 0.12)',
      bgColor: 'white100',
      borderRadius: '6px',
    },
    valueWrapper: {
      padding: '12px 10px 0',
      textStyle: 'body.regular',
      outline: 'none',
      cursor: 'pointer',
      _hover: {
        bgColor: 'secondary.sideMenu',
        '& .chakra-text': {
          color: props.disabled ? 'primary.disabled' : 'fontnavy',
        },
      },
      '&.active': {
        bgColor: 'secondary.base',
        '& .chakra-text': {
          color: 'primary.pressed',
        },
        _hover: {
          bgColor: 'secondary.base',
        },
      },
      '& .icon': {
        svg: {
          fill: 'primary.pressed',
        },
      },
      '& .chakra-text': {
        color: props.disabled ? 'primary.disabled' : 'fontnavy',
        textTransform: 'capitalize',
      },
      '& .chakra-divider': {
        marginTop: '12px',
      },
      ...getTabFocusSelectors({
        outlineColor: 'secondary.base',
      }),
      _active: {
        bgColor: 'secondary.sideMenu',
        '& .chakra-text': {
          color: props.disabled ? 'primary.disabled' : 'primary.pressed',
        },
      },
    },
    dropdownItem: {
      outline: 'none',
    },
    bottomBlock: {
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '14px 12px',
      bgColor: 'white100',
      'a, button': {
        minWidth: '43px',
      },
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      wrapperOuter: {
        width: props.listWidth || '245px',
        maxHeight: props.listMaxHeight || '233px',
      },
      valueWrapper: {
        cursor: props.disabled ? 'default' : 'pointer',
      },
    }),
    sm: props => ({
      wrapperOuter: {
        width: props.listWidth || '160px',
        maxHeight: props.listMaxHeight || '145px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {
    normal: props => ({}),
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}

export default DropdownListStyle
