import React, { useCallback, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { get, set, wrap } from 'object-path-immutable'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import Table from 'ui-framework/components/patterns/Table'
import Input from 'ui-framework/components/primitives/Input'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { FloorUse, TaFormData, UsesTables } from 'hooks/api/ta/TaFormData'

import { integerPositiveThousands } from 'helpers/formats'
import {
  capitalizeFirstLetter,
  getCallback,
  getFiscalPeriods,
  getNameAndErrorProps,
  log,
} from 'helpers/utils'
import { formatToNumberITS } from 'helpers/formatter'
import { Box, Text, VStack } from '@chakra-ui/react'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import {
  getDictiName,
  FloorUsesType,
  PortionUsedByApplicant,
  PropertyUsage,
  UseByApplicant,
} from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'

//====================================================
type Props = {
  usesTables: UsesTables
  usesFloorId: string
  // wereAnyVacancies: boolean
  useByApplicantCS: UseByApplicant
  portionUsedByApplicantCS: PortionUsedByApplicant
  propertyUsageCS: PropertyUsage
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyDetailsNonResidential component
 */
const StepPropertyDetailsNonResidential = ({
  usesTables,
  usesFloorId,
  // wereAnyVacancies,
  useByApplicantCS,
  portionUsedByApplicantCS,
  propertyUsageCS,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context),
    [wereAnyApplicant, isApplicantSFShown] = useMemo(
      () => [
        useByApplicantCS !== UseByApplicant.No,
        propertyUsageCS !== PropertyUsage.Residential &&
        useByApplicantCS === UseByApplicant.Part &&
        portionUsedByApplicantCS !== PortionUsedByApplicant.Residential,
      ],
      [portionUsedByApplicantCS, propertyUsageCS, useByApplicantCS]
    ),
    wereAnyVacancies = useMemo(() => usesTables[`wereAnyVacancies${capitalizeFirstLetter(usesFloorId)}`], [usesFloorId, usesTables]),
    uses = useMemo(() => usesTables[usesFloorId], [usesFloorId, usesTables]),
    nonResUses = useMemo(
      () => uses.filter(item => item.useCategoryCS !== FloorUsesType.Resident),
      [uses]
    ),
    headers = [
      {
        width: '54px',
        bgColor: '#F9FAFF',
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              m: '18px auto',
              textAlign: 'left',
            }}
          >
            Uses
          </TextFromParams>
        ),
        width: '200px',
        bgColor: '#F9FAFF',
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              m: '8px auto',
              maxWidth: '100px',
            }}
          >
            Total Square Footage, SF
          </TextFromParams>
        ),
        bgColor: '#F9FAFF',
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              m: '18px auto',
            }}
          >
            Vacant, SF
          </TextFromParams>
        ),
        hide: !wereAnyVacancies,
        bgColor: '#F9FAFF',
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              m: '8px auto',
              maxWidth: '170px',
            }}
          >
            Occupied by Applicant / Related Party, SF
          </TextFromParams>
        ),
        hide: !isApplicantSFShown,
        bgColor: '#F9FAFF',
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              m: '18px auto',
              textAlign: 'center',
            }}
          >
            Rented, SF
          </TextFromParams>
        ),
        // width: '160px',
        bgColor: '#F9FAFF',
      },
      {
        width: '24px',
        bgColor: '#F9FAFF',
      },
    ],
    validationData = useMemo(
      () => ({
        nonResUses,
        uses,
        useByApplicantCS,
        propertyUsageCS,
        portionUsedByApplicantCS,
        usesFloorId,
        usesTables,
        wereAnyVacancies,
      }),
      [
        nonResUses,
        uses,
        useByApplicantCS,
        propertyUsageCS,
        portionUsedByApplicantCS,
        usesFloorId,
        usesTables,
        wereAnyVacancies,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  const floorSectionName = useMemo(() => {
    return usesFloorId === 'firstFloor'
      ? 'Floor 1'
      : usesFloorId === 'secondFloor'
        ? 'Floor 2'
        : usesFloorId === 'thirdAndAboveFloors'
          ? 'Floor 3 and above'
          : usesFloorId === 'basement'
            ? 'Basement'
            : ''
  }, [usesFloorId]),
    onSFChange = useCallback(
      index => (value, name) => {
        getCallback(onChange)(
          value,
          `usesTables.${usesFloorId}.${index}.${name}`
        )
      },
      [onChange, usesFloorId]
    ),
    onWereAnyVacanciesChange = useCallback(
      (value: boolean) => {
        const result = wrap({ usesTables, })
        result.set(`usesTables.wereAnyVacancies${capitalizeFirstLetter(usesFloorId)}`, value)

        if (!value) {
          result.set(
            `usesTables.${usesFloorId}`,
            uses.map(i => ({ ...i, vacantSF: 0 }))
          )
        }

        getCallback(onChange)(result.value())
      },
      [onChange, uses, usesFloorId]
    )

  useEffect(() => {
    if (!wereAnyVacancies) {
      log('wereAnyVacancies', wereAnyVacancies)
      usesTables[usesFloorId].forEach((u, i) => {
        getCallback(onChange)(0, `usesTables.${usesFloorId}.${i}.vacantSF`)
      })
    }
  }, [wereAnyVacancies])

  return (
    <div className={props.className}>
      <VStack spacing="37px" align="left">
        <Box pl="54px">
          <SelectsGroup
            label={
              <Text textStyle="body.regular" color="fontnavy" lineHeight="25px">
                Was any portion of the non-residential space vacant in{' '}
                <b>{floorSectionName}</b> as of <b>January 5, {fiscalYear}</b>?
              </Text>
            }
            error={hasError('wereAnyVacancies')}
            disabled={disabled}
          >
            <Radiobutton
              value={wereAnyVacancies}
              name="wereAnyVacancies"
              selected={false}
              disabled={disabled}
              onChange={onWereAnyVacanciesChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={wereAnyVacancies}
              name="wereAnyVacancies"
              selected={true}
              disabled={disabled}
              onChange={onWereAnyVacanciesChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </Box>
        <Table
          headers={headers}
          items={nonResUses}
          rowsHeadingHeight="56px"
          rowsHeight="56px"
          cellSidePaddings="20px"
        >
          {(item: FloorUse, i: number) => {
            if (item.useCategoryCS === FloorUsesType.Resident) return
            const useIndex = item.$checkTotalUsePercent

            const getError = name =>
              hasError(`nonResUses.${i}.${name}`) ||
              hasError(`nonResUses.${i}.$checkTotalUsePercent`)

            const nameAndErrorProps = name =>
              getNameAndErrorProps(name, getError)

            return (
              <tr>
                <CellWithoutBorder></CellWithoutBorder>
                <td>
                  <TextFromParams
                    params={{
                      textAlign: 'left',
                    }}
                  >
                    {getDictiName(item.usesTypeCS)}
                  </TextFromParams>
                </td>
                <td>
                  <TextFromParams>
                    {formatToNumberITS(item.squareFootage)}
                  </TextFromParams>
                </td>
                {wereAnyVacancies && (
                  <td>
                    <HelpTarget name="vacantSF" justifyContent={`center`}>
                      <Input
                        size="sm"
                        width="160px"
                        placeholder="Enter SF"
                        value={item.vacantSF}
                        type="number"
                        disabled={disabled || !wereAnyVacancies}
                        format={integerPositiveThousands}
                        onChange={onSFChange(useIndex)}
                        errorTooltip
                        {...nameAndErrorProps('vacantSF')}
                      />
                    </HelpTarget>
                  </td>
                )}
                {isApplicantSFShown && (
                  <td>
                    <HelpTarget name="applicantSF" justifyContent={`center`}>
                      <Input
                        size="sm"
                        width="160px"
                        placeholder="Enter SF"
                        value={item.applicantSF}
                        type="number"
                        disabled={disabled || !wereAnyApplicant}
                        format={integerPositiveThousands}
                        onChange={onSFChange(useIndex)}
                        errorTooltip
                        {...nameAndErrorProps('applicantSF')}
                      />
                    </HelpTarget>
                  </td>
                )}
                <td>
                  <HelpTarget name="rentedSF" justifyContent={`center`}>
                    <Input
                      size="sm"
                      width="160px"
                      placeholder="Enter SF"
                      value={item.rentedSF}
                      type="number"
                      format={integerPositiveThousands}
                      disabled={disabled}
                      onChange={onSFChange(useIndex)}
                      errorTooltip
                      {...nameAndErrorProps('rentedSF')}
                    />
                  </HelpTarget>
                </td>
                <CellWithoutBorder></CellWithoutBorder>
              </tr>
            )
          }}
        </Table>
      </VStack>
    </div>
  )
}

export default StepPropertyDetailsNonResidential
