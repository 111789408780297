import { useEffect } from 'react'

const clearPrefilled = (input: HTMLInputElement) => {
    const style = window.getComputedStyle(input)

    if (!style.border.startsWith('0') && !input.value) input.value = ''
  },
  checkAutofill = (inputs: NodeListOf<HTMLInputElement>) => {
    if (inputs?.length) inputs.forEach(clearPrefilled)
  }

const useBrowserAutofill = () => {
  const inputs = document.querySelectorAll('input')

  useEffect(() => {
    let count = 0
    const intervalId = setInterval(() => {
        checkAutofill(inputs)
        if (count >= inputs.length) clear()
        count += 1
      }, 100),
      clear = () => clearInterval(intervalId)
    return clear
  }, [inputs])
}

export default useBrowserAutofill
