import React from 'react'
import { Avatar, useMultiStyleConfig, chakra } from '@chakra-ui/react'
import { IsAdmin } from 'helpers/utils'

type AvatarUIProps = {
  src?: string
}

const AvatarUI = (props: AvatarUIProps) => {
  const { src } = props,
    admin = IsAdmin(),
    style = useMultiStyleConfig('MGNYAvatar', { admin, ...props })

  return <Avatar {...props} src={src} sx={style.image} />
}

export default chakra(AvatarUI)
