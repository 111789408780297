import { ComponentStyleConfig } from '@chakra-ui/theme'

const ModalDialogStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['modalFooter', 'modalContent', 'modalHeader', 'modalBody'],
  // Styles for the base style
  baseStyle: props => ({
    modalContent: {
      overflow: 'hidden',
      borderRadius: '11px',
    },
    modalHeader: {
      bgColor: '#F9FAFF',
      color: 'fontnavy',
      fontSize: 'body',
      textStyle: 'body.semibold',
    },
    modalBody: {
      textStyle: 'body.regular',
      color: 'fontnavy',
      maxH: '660px',
      overflowY: 'scroll',
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({}),
    w1100: props => ({
      modalContent: {
        width: '1100px',
      },
    }),
    w860: props => ({
      modalContent: {
        width: '860px',
      },
    }),
    w640: props => ({
      modalContent: {
        width: '640px',
      },
    }),
    w580: props => ({
      modalContent: {
        width: '580px',
      },
    }),
    w560: props => ({
      modalContent: {
        width: '560px',
      },
    }),
    w540: props => ({
      modalContent: {
        width: '540px',
      },
    }),
    w500: props => ({
      modalContent: {
        width: '500px',
      },
    }),
    w484: props => ({
      modalContent: {
        width: '484px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {
    normal: {
      modalContent: {
        mt: '120px',
      },
      modalFooter: {
        p: '20px',
      },
      modalBody: {
        p: '20px 20px 0',
      },
      modalHeader: {
        p: '22px 20px',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}

export default ModalDialogStyle
