import useQueryParamState from 'hooks/useQueryParamState'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { TableHeaderItems, TableHeader } from './TableHeaders'
//
export type FilterSettingsItem = {
  path: string
  value: string[]
}

export type FilterSettings =
  // Record<string, FilterSettingsItem>
  {
    [name: string]: FilterSettingsItem
  }

export type SortingDirection = 'asc' | 'desc'

export type SortSetting = {
  name: string
  path: string
  direction: SortingDirection
}

function serialize(
  filters?: FilterSettings,
  sorting?: SortSetting
): [string | undefined, string | undefined] {
  const filterResult = filters
    ? Object.entries(filters)
        .map(fnv => {
          const name = fnv[0],
            f = fnv[1]
          return `${name}-${
            f.value &&
            (Array.isArray(f.value)
              ? f.value.map(v => String(v).trim()).join('.')
              : String(f.value).trim())
          }`
        })
        .join('~')
    : undefined

  const sortingResult = sorting
    ? `${sorting.name}-${sorting.direction}`
    : undefined

  return [filterResult, sortingResult]
}
function deserialize(
  headers: TableHeader[],
  filters: string,
  sorting: string
): [FilterSettings | undefined, SortSetting | undefined] {
  const filterResult = filters
    ?.split('~')
    .map(f => {
      const [name, value] = f.split('-', 2),
        valueSplitted = value.split('.').filter(v => v !== ''),
        header = headers.find(h => h.id === name)
      return {
        name: name,
        path: header?.path,
        value: valueSplitted,
      }
    })
    .filter(f => f.value.length && f.name && f.path)
    .reduce((acc, curr) => {
      acc[curr.name] = { path: curr.path, value: curr.value }
      return acc
    }, {})

  const [sortName, sortDirection] = sorting ? sorting.split('-', 2) : [],
    sortHeader = headers.find(h => h.id === sortName),
    sortingResult: SortSetting | undefined =
      sortName && sortDirection && sortHeader?.path
        ? {
            name: sortName,
            path: sortHeader?.path,
            direction: sortDirection as SortingDirection,
          }
        : undefined

  return [filterResult as FilterSettings, sortingResult]
}

function useFilterSettings(
  headers: TableHeaderItems,
  promote: boolean = false
): [
  FilterSettings | undefined,
  Dispatch<SetStateAction<FilterSettings | undefined>>,
  SortSetting | undefined,
  Dispatch<SetStateAction<SortSetting | undefined>>
] {
  const [activeFilters, activeFiltersSet] = useState<FilterSettings>(),
    [activeSorting, activeSortingSet] = useState<SortSetting>(),
    [queryFilters, queryFiltersSet] = useQueryParamState('filters'),
    [querySort, querySortSet] = useQueryParamState('sort')

  useEffect(() => {
    if (promote && headers.length) {
      const [f, s] = deserialize(headers.flat(99), queryFilters, querySort)
      activeFiltersSet(f)
      activeSortingSet(s)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (promote) {
      const [f, s] = serialize(activeFilters, activeSorting)
      activeFilters && queryFiltersSet(f)
      activeSorting && querySortSet(s)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, activeSorting, promote])

  return [activeFilters, activeFiltersSet, activeSorting, activeSortingSet]
}

export default useFilterSettings
export { serialize, deserialize }
