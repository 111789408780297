import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'

type ToastFunction = (title?: string, desciption?: string) => void

function useToast(): {
  error: ToastFunction
  info: ToastFunction
  success: ToastFunction
  warning: ToastFunction
  custom: (options?: UseToastOptions) => any
} {
  const toast = useChakraToast({
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    }),
    toaster = (status: UseToastOptions['status']) => (title, message) =>
      toast({ title: title, description: message, status: status })

  return {
    error: toaster('error'),
    info: toaster('info'),
    success: toaster('success'),
    warning: toaster('warning'),
    custom: toast,
  }
}

export default useToast
