import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  ReactElement,
} from 'react'

import { merge, get } from 'object-path-immutable'
import BaseComponentProps from 'common/BaseComponentProps'
import Step from 'ui-framework/components/patterns/Wizard/Step'

import useStepsConfig, {
  CustomWizardAction,
  StepsConfig,
} from 'hooks/wizard/useStepsConfig'

import { AnyFunction, getCallback, IsAdmin, log } from 'helpers/utils'
import { Flex } from '@chakra-ui/react'
import WizardLayout from 'ui-framework/components/layout/WizardLayout'
import { getBBLString, getShortAddress } from 'helpers/business'
import Chips from 'ui-framework/components/primitives/Chips'
import ScrollToTop from 'ui-framework/components/primitives/ScrollToTop'
import { ServiceFormDataHook } from 'services/common/useFormDataHook'
import { UseApplicationResult } from 'hooks/api/firebase/useApplication'
import { ReactNode } from 'react'
import useRegisterStepViewAnalytics from 'hooks/analytics/useRegisterStepViewAnalytics'
import useSelectUserByApplicationDialog from 'pages/Services/hooks/useSelectUserByApplicationDialog'
import PropertyNotFoundPage from 'pages/Errors/PropertyNotFoundPage'
import useNavigationProgressBar from 'ui-framework/components/patterns/Wizard/useNavigationProgressBar'

import { useNavigate } from 'react-router-dom'
import useJustWait from 'hooks/useJustWait'
import useApplicationData from 'ui-framework/components/patterns/Wizard/useApplicationData'
import ServiceWizardProvider from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'

import useStep from 'hooks/wizard/useStep'
import { useAssistantShown } from 'ui-framework/components/patterns/HelpBlock/useAssistant'
import YourFilingManager from 'ui-framework/components/primitives/YourFilingManager'
import useWizardMachine, { Directions, WizardEvents } from 'hooks/wizard/useWizardMachine'
import useConcurrentEdit from 'hooks/wizard/useConcurrentEdit'
import { onAuthStateChanged } from 'firebase/auth'

const prepareShortAddress = property => {
  if (!property) return null
  const { isRootConsolidateBBL, filingManager } = property,
    shortAddress = getShortAddress(property)

  return (
    <Flex alignItems={`center`}>
      {shortAddress}
      <YourFilingManager managerEmail={filingManager} />
      {isRootConsolidateBBL && (
        <Chips ml={`16px`} prefix={true}>
          Main Tax Lot
        </Chips>
      )}
    </Flex>
  )
}


//===================================================
export type AssistantConfig = {
  title: string
}

export type WizardConfig = {
  steps: StepsConfig
  flowFilterValue?: string
  assistant: AssistantConfig
}

type Props<FormData> = {
  bblid: string
  propertyListUrl: string
  config: WizardConfig
  isFinished: boolean
  application: UseApplicationResult<FormData>
  useServiceFormDataHook: ServiceFormDataHook<FormData>
  propertyPringlsLine?: ReactNode
  onCustomAction?: (customAction: CustomWizardAction) => Promise<boolean>
} & BaseComponentProps

/**
 * ServiceWizard component
 */
const ServiceWizard: <FormData>(
  props: Props<FormData>
) => ReactElement | null = ({
  bblid,
  propertyListUrl,
  config,
  application,
  isFinished,
  useServiceFormDataHook,
  propertyPringlsLine,
  onCustomAction,
  ...props
}) => {
    const admin = IsAdmin()
    const [autosaveEnabled, autosaveEnabledSet] = useState<boolean>(!admin)
    const [handleBackLinkClick, userSelectDialog] = useSelectUserByApplicationDialog(
      application,
      propertyListUrl
    )
    const [applicationServiceData, applicationServiceMetaData] =
      useApplicationData(application)
    const { isPending, updatedOutside } = application
    const isLoaded = Boolean(applicationServiceData)

    const stepConfigContext = useMemo(() => ({
      isAdmin: admin, ...application?.data,
      //TODO find another way - needs for work with document files (useDocumentFiles)
      ref: application?.ref, serviceMetaDataSet: application?.serviceMetadataSet,
    }), [application, admin])

    const [serviceData, serviceDataSet] = useServiceFormDataHook(
      applicationServiceData, stepConfigContext
    )

    const [steps, stepsSections] = useStepsConfig(
      config.steps,
      get(stepConfigContext, config?.flowFilterValue),
      serviceData,
      stepConfigContext
    )

    const onAfterStepChange = (context) => {
      // log('STEP_AFTER', autosaveEnabled, !currentStep?.nextStepOptions?.onBefore, state)
      const doReady = () => {
        if (autosaveEnabled) application.dataSave()
        send({ type: WizardEvents.READY })
      }

      if (context.$gotoStepId !== Directions.NEXT
        || !currentStep?.nextStepOptions?.onBefore) {
        doReady()
      } else {
        // log('CUSTOM ACTION')
        getCallback(onCustomAction)(
          currentStep.nextStepOptions.onBefore(serviceData)
        )
          .then(() => {
            // log('CUSTOM ACTION ready')
            doReady()
          })
          .catch(() => {
            // log('CUSTOM ACTION goto')
            send({ type: WizardEvents.GOTO, stepId: currentStepId })
          })
          .finally(() => {
          })
      }
    }

    const [state, send] = useWizardMachine(
      steps,
      applicationServiceMetaData?.currentStepId,
      onAfterStepChange
    )
    // @ts-ignore
    const { currentStep, currentStepId, currentStepIndex } = state.context

    const changeStep = useCallback((stepId: string | number = steps[0].id) => {
      // log('changeStep', stepId)
      if (typeof stepId === 'number') {
        if (stepId === 1)
          send({ type: WizardEvents.NEXT })
        if (stepId === -1)
          send({ type: WizardEvents.BACK })
      } else {
        send({ type: WizardEvents.GOTO, stepId })
      }
    }, [steps])

    // ============================================================
    // i don't like this approach, but it is to risky to make a global change
    // in the middle of the high season
    const [dir, dirSet] = useState<Directions>()
    const handleOnChange = useCallback((data, stepId) => {
      // log('handleOnChange', data, stepId)
      serviceDataSet(merge(serviceData, undefined, data))
      dirSet(stepId)
      // setTimeout(() => { changeStep(stepId) }, 0)
    }, [serviceDataSet])
    useEffect(() => {
      if (dir) {
        changeStep(dir)
        dirSet(undefined)
      }
    }, [dir])

    // ============================================================

    useEffect(() => {
      log('!!! serviceMetadataSet', currentStepId, stepsSections)
      application?.serviceMetadataSet({
        currentStepId: currentStepId,
        steps: stepsSections,
      })
    }, [currentStepId])

    useEffect(() => {
      log('!!! serviceDataSet', serviceData)
      application.serviceDataSet(serviceData)
    }, [serviceData])

    const [concurrentEditModal, checkConcurent] = useConcurrentEdit(
      isFinished,
      updatedOutside,
      autosaveEnabled
    )

    const navigationBarCfg = useNavigationProgressBar(
      admin, stepsSections, steps, currentStep, currentStepIndex,
      application.remoteData?.serviceMetaData?.currentStepId,
      autosaveEnabled, changeStep
    )

    useRegisterStepViewAnalytics(currentStepId)

    const wait = useJustWait(1000)
    if (wait && !isPending && !isLoaded)
      return <PropertyNotFoundPage fallback={handleBackLinkClick} />

    return (
      <ServiceWizardProvider
        serviceData={serviceData}
        context={stepConfigContext}
        changeStep={changeStep}
      >
        <WizardLayout
          title={prepareShortAddress(application?.data || {})}
          subtitle={getBBLString(application?.data || {})}
          backLinkOrCallback={propertyListUrl}
          stepsSections={stepsSections}
          currentStepIndex={currentStepIndex || 0}
          currentStep={currentStep}
          pending={isPending || !isLoaded}//|| !wait}
          propertyPringlsLine={propertyPringlsLine}
          assistantHeader={config.assistant.title}
          navigationBarCfg={navigationBarCfg}
        >
          {currentStep && (
            <Flex direction="column">
              <ScrollToTop trigger={currentStepId} />
              <Step
                showBack={currentStepIndex !== 0}
                step={currentStep}
                serviceData={serviceData}
                autosaveEnabled={autosaveEnabled}
                autosaveEnabledSet={autosaveEnabledSet}
                onChange={checkConcurent(handleOnChange)}
                onCustomAction={onCustomAction}
              />
            </Flex>
          )}
          {userSelectDialog}
          {concurrentEditModal}
        </WizardLayout>
      </ServiceWizardProvider>
    )
  }

export default ServiceWizard
