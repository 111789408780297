import React, { ReactNode } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { chakra } from '@chakra-ui/react'

const ContentWrapper = chakra('div', {
  baseStyle: {
    color: 'fontnavy',
    textStyle: 'body.semibold',
    lineHeight: '25px',
    bgColor: 'green.opacity',
    borderRadius: '10px',
    padding: '12px 16px',
    w: '600px',
  },
})
const ContentUI = chakra('div', {
  baseStyle: {
    textStyle: 'body.regular',
    lineHeight: '25px',
    color: 'fontnavy',
  },
})

type InfoBoxProps = {
  title?: string | ReactNode
  items?: string[]
} & BaseComponentProps

const InfoBox = (props: InfoBoxProps) => {
  const { title, items = [], children, ...rest } = props
  return (
    <ContentWrapper {...rest}>
      {title}
      {items.map((lot: string, i: number) => (
        <ContentUI key={i}>&ndash;&nbsp;{lot}</ContentUI>
      ))}
      {children}
    </ContentWrapper>
  )
}
export default chakra(InfoBox)
