import React, { memo, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'
import Progress from '../../primitives/Progress'
import ProgressTitle from '../../primitives/Progress/ProgressTitle'
import ProgressBar from 'ui-framework/components/primitives/Progress/ProgressBar'
import ProgressBackground from '../../primitives/Progress/ProgressBackground'
import isEqual from 'lodash/isEqual'
import { Sections } from 'hooks/wizard/useStepsConfig'
import { NavBarItems } from 'ui-framework/components/primitives/Progress/NavBar'

const MultisectionalProgressWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  '& > div': {
    minWidth: '100px',
    width: '100%',
    ':not(:last-child)': {
      marginRight: '8px',
    },
  },
})

//===========================================

export type NavBarSectionItems = { [key: number]: NavBarItems }

type Props = {
  /**
   * Sections array containing name and max value fo section
   */
  sections: Sections
  /**
   * Current value
   */
  current?: number
  navigationBarCfg?: NavBarSectionItems
} & BaseComponentProps

/**
 * MultisectionalProgress component
 */
const MultisectionalProgress = ({
  sections,
  current = 0,
  navigationBarCfg = {},
  ...props
}: Props) => {
  const sectionsWithMin = useMemo(() => {
      let min = 0
      return sections?.map(s => {
        const result = { ...s, min, stepsCount: s.stepsCount + min }
        min += s.stepsCount
        return result
      })
    }, [sections]),
    title = section => {
      const cur = current + 1,
        sectionTotal = section.stepsCount - section.min,
        steps =
          cur > section.min && cur <= section.stepsCount
            ? ` (${cur - section.min}/${sectionTotal})`
            : section.min < cur
            ? ` (${sectionTotal}/${sectionTotal})`
            : ''
      return `${section.sectionTitle}${steps}`
    }

  return (
    <MultisectionalProgressWrapper className={props.className}>
      {sectionsWithMin?.map((section, i) => (
        <Progress
          key={section.sectionTitle}
          min={section.min}
          max={section.stepsCount}
          current={current}
        >
          <ProgressTitle>{title(section)}</ProgressTitle>
          <ProgressBackground overflow={navigationBarCfg[i] && 'visible'}>
            <ProgressBar navCfg={navigationBarCfg[i]} />
          </ProgressBackground>
        </Progress>
      ))}
    </MultisectionalProgressWrapper>
  )
}

export default memo(MultisectionalProgress, isEqual)
