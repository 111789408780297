import styled from '@emotion/styled/macro'
import { chakra } from '@chakra-ui/react'

const FooterUI = chakra('div', {
  baseStyle: {
    color: 'fontandicongray',
    textStyle: 'caption.regular',
  },
})

const FooterTop = styled('div')<{}>(() => ({
  marginBottom: '13px',
  display: 'flex',
  justifyContent: 'center',
  div: {
    width: 'fit-content',
    display: 'inline-block',
  },
}))

const FooterTitle = styled('div')<{}>(() => ({
  marginRight: '5px',
}))

const VersionController = styled('div')<{}>(() => ({
  userSelect: 'none',
  marginRight: '5px',
}))

export { FooterTop, FooterTitle, VersionController }
export default FooterUI
