import { ReactNode, useMemo } from 'react'
import TAsrc from './assets/TA.png'
import RPIEsrc from './assets/RPIE.png'
import Button from 'ui-framework/components/primitives/Button'
import { AnyFunction } from 'helpers/utils'
import Progress from 'ui-framework/components/primitives/Progress'
import ProgressTitle from 'ui-framework/components/primitives/Progress/ProgressTitle'
import ProgressBackground from 'ui-framework/components/primitives/Progress/ProgressBackground'
import ProgressBar from 'ui-framework/components/primitives/Progress/ProgressBar'
import { RPIEStatuses, Services, TAStatuses } from 'constants/dicti'
import useAction from './useAction'
import useContent from './useContent'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

//==========================================================

type HomePageConfig = {
  src?: string
  title: string | ReactNode
  description: string | ReactNode
  getToolbar?: AnyFunction
}

type ProgressAction = {
  current?: any
  max?: any
  actionText?: string
  action?: AnyFunction
  hasAgreement?: boolean
}

const getProgessWithAction = ({
  current,
  max,
  actionText,
  action,
  hasAgreement = false,
}: ProgressAction) => (
  <>
    <div style={{ height: '36px', width: '100%' }}>
      {max > 0 && action && hasAgreement && (
        <Progress max={max} current={current} onlyFinished>
          <ProgressTitle color={'fontnavy'}>
            Number of properties submitted so far ({`${current}/${max}`})
          </ProgressTitle>
          <ProgressBackground>
            <ProgressBar />
          </ProgressBackground>
        </Progress>
      )}
    </div>
    <Button width={'471px'} disabled={!action} onClick={action}>
      {actionText}
    </Button>
  </>
)

const createToolbar =
  ({ serviceType, status, actionFn }) =>
  data => {
    const applications = data?.filter(app => app.serviceCS === serviceType),
      finished = applications?.filter(app =>
        status.includes(app.statusCS)
      )?.length
    const actions = actionFn(serviceType)

    return getProgessWithAction({
      max: applications?.length,
      current: finished,
      ...actions,
    })
  }

const useHomePage = () => {
  const [makeAction, checkAgreement] = useAction()
  const getContent = useContent(checkAgreement)

  const homePageConfig: HomePageConfig[] = useMemo(
    () => [
      getContent(Services.TaxAppeal, SUPPORT_CONTACTS.supportTAEmail, {
        src: TAsrc,
        title: 'Tax Appeal',
        description:
          'This is where you manage information exchange related to annual tax protests. The application period is between January 15th and late February of each year.',
        getToolbar: createToolbar({
          serviceType: Services.TaxAppeal,
          actionFn: makeAction,
          status: [
            TAStatuses.Imported,
            TAStatuses.SubmitOutOfPortal,
            TAStatuses.Submit,
          ],
        }),
      }),
      getContent(Services.RPIE, SUPPORT_CONTACTS.supportRPIEEmail, {
        src: RPIEsrc,
        title: 'RPIE',
        description:
          'The NYC Department of Finance (DOF) requires an Annual Real Property Income and Expense (RPIE) filing for most income-producing properties with an Assessed Value over $40,000.',
        getToolbar: createToolbar({
          serviceType: Services.RPIE,
          actionFn: makeAction,
          status: [RPIEStatuses.Imported, RPIEStatuses.Submitted],
        }),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [TAsrc, RPIEsrc, createToolbar, checkAgreement, getContent]
  )

  return homePageConfig
}

export default useHomePage
