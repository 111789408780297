import styled from '@emotion/styled/macro'

type DividerProps = {
  margin?: string
}

const Divider = styled('div')<DividerProps>(props => ({
  backgroundColor: props.theme.colors.gray1,
  width: 'auto',
  height: '1px',
  position: 'relative',
  top: '100%',
  margin: props.margin,
}))

export default Divider
