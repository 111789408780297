import dayjs from 'dayjs'
import Utc from 'dayjs/plugin/utc'
import { mergeConfigs } from 'helpers/config/utils'
import { Config } from './utils'

dayjs.extend(Utc)

export type ConfigSchedule<T> = {
  [date: string]: Partial<T>
}

const scheduler =
  (...configs: ConfigSchedule<Config>[]) =>
  date => {
    const configSchedule = mergeConfigs(...configs)
    const dd = dayjs.utc(date),
      diff = Object.keys(configSchedule)
        .map(c => ({
          key: c,
          diff: dayjs.utc(c).diff(dd, 'days'),
        }))
        .filter(c => c.diff <= 0)
        .sort((a, b) => a.diff - b.diff),
      currentKey = diff[diff.length - 1],
      result = configSchedule[currentKey?.key]

    // You can get alldate config here
    // console.log(configSchedule)
    return result
  }

export default scheduler
