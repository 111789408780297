import { ApiObject } from 'helpers/apiHelper'
import firebaseApp from 'helpers/firebaseInit'
import { Application } from 'hooks/api/firebase/useApplication'
import { RpieFormData } from 'hooks/api/rpie/RpieFormData'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { createContext, useEffect, useReducer, useState } from 'react'
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  CollectionReference,
  doc,
  getDoc,
} from 'firebase/firestore'
import { useAppConfig, useCheckedContext } from 'hooks/useApplicationContext'
import { usePendingWrapperObject } from 'hooks/api/firebase/usePendingWrapper'
import useAuth from 'hooks/useAuth'
import { useParams } from 'react-router-dom'
import useSelectProfileDialog from 'pages/Services/hooks/useSelectProfileDialog'
import useQueryParamState from 'hooks/useQueryParamState'
import { IsAdmin } from 'helpers/utils'

type ServiceApplication = Application<TaFormData | RpieFormData>
type MyApplicationsState = ApiObject<ServiceApplication[] | null>
const defaultState: MyApplicationsState = {
  error: null,
  isPending: true,
  data: null,
}

const db = getFirestore(firebaseApp)
const MyApplicationsContext = createContext(defaultState)
const MyApplicationsDispatchContext = createContext({})

enum MyApplicationsActionKind {
  SET,
  SET_STATUS,
}

interface MyApplicationsAction {
  type: MyApplicationsActionKind
  payload: any
}

const myApplicationReducer = (
  state: MyApplicationsState,
  action: MyApplicationsAction
) => {
  switch (action.type) {
    case MyApplicationsActionKind.SET: {
      return { ...state, data: action.payload }
    }
    case MyApplicationsActionKind.SET_STATUS: {
      return { ...state, isPending: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const getUsersFromApplicationForAdmin = async id => {
  const d = await getDoc<Application<any>>(doc(db, 'applications', id) as any)
  if (!d.exists()) return []
  return d.data().users
}

const useAdminApplicationRequest = onMatch => {
  const [subject] = useQueryParamState('subject')
  const admin = IsAdmin(),
    { bblid } = useParams(),
    [applicationId, applicationIdSet] = useState<string>(),
    [users, usersSet] = useState<string[]>()

  const [onOpen, dialogNode] = useSelectProfileDialog(users, onMatch)

  useEffect(() => {
    if (!!bblid) {
      applicationIdSet(bblid)
    } else {
      applicationIdSet(undefined)
      usersSet(undefined)
    }
  }, [bblid])

  useEffect(() => {
    if (applicationId) {
      getUsersFromApplicationForAdmin(applicationId).then(users => {
        usersSet(users)
      })
    }
  }, [applicationId])

  useEffect(() => {
    if (!subject && admin) {
      users !== undefined && !users?.length ? onMatch('null') : onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  return dialogNode
}

const MyApplicationsProvider = ({ children }) => {
  const appconf = useAppConfig(),
    { effectiveProfile, effectiveProfileReset, showUnowned } = useAuth(),
    effectiveUserEmail = effectiveProfile?.email,
    [state, dispatch] = useReducer(myApplicationReducer, defaultState)

  const selectSubjectDialog = useAdminApplicationRequest(effectiveProfileReset)

  // Load the products from the Firestore database when the component mounts
  useEffect(() => {
    if (!effectiveUserEmail) return

    const byProfileOrUnowned = showUnowned
      ? where('users', '==', [])
      : where('users', 'array-contains', effectiveUserEmail)

    const q = query<ServiceApplication>(
      collection(db, 'applications') as CollectionReference<ServiceApplication>,
      where('fiscalYear', '==', appconf.fiscalYear),
      byProfileOrUnowned
      // where('applicationSFStatusCS', '!=', SFApplicationStatus.Void),
      // where('users', 'array-contains', effectiveUserEmail) // user or subject
      // where('users', '==', []) // user or subject
    )

    const unsubscribe = onSnapshot<ServiceApplication>(q, querySnapshot => {
      const applications: ServiceApplication[] = []
      querySnapshot.forEach(doc => {
        // @ts-ignore
        applications.push({ id: doc.id, ref: doc.ref, ...doc.data() })
      })
      dispatch({ type: MyApplicationsActionKind.SET, payload: applications })
      dispatch({ type: MyApplicationsActionKind.SET_STATUS, payload: false })
    })

    return unsubscribe
  }, [appconf, effectiveUserEmail, showUnowned])

  return (
    <MyApplicationsContext.Provider value={state}>
      <MyApplicationsDispatchContext.Provider value={dispatch}>
        {children}
        {selectSubjectDialog}
      </MyApplicationsDispatchContext.Provider>
    </MyApplicationsContext.Provider>
  )
}

const useMyApplications = (): MyApplicationsState => {
  const appls = useCheckedContext<MyApplicationsState>(MyApplicationsContext)
  return usePendingWrapperObject(appls)
}

const useMyApplication = (
  applicationId: string
): ApiObject<Application<any> | undefined> => {
  const applications = useMyApplications()
  // console.log(applications)
  return {
    ...applications,
    data: applications.data?.find(a => a.id === applicationId),
  }
}

export default MyApplicationsProvider
export { useMyApplications, useMyApplication }
