import React, { useMemo, useCallback } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import PageTitle from 'ui-framework/components/patterns/PageTitle'

import { Box, Flex, HStack } from '@chakra-ui/layout'
import { URL_SERVICETAFORM, URL_SERVICETALIST } from 'constants/URLS'
import { getBBLString, getShortAddress } from 'helpers/business'
import Subtitle from 'ui-framework/components/patterns/Subtitle'
import Button, {
  ButtonVariantProps,
} from 'ui-framework/components/primitives/Button'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import useApplication from 'hooks/api/firebase/useApplication'
import SummaryTable from 'ui-framework/components/patterns/Wizard/SummaryTable'
import { TASummarySectionsConfig } from '../TAFormPage/steps/Submit/TASummarySectionsConfig'
import { TAStatuses } from 'constants/dicti'
import { useRedirectIfNotThisApplicationStatuses } from 'pages/Services/hooks/useRedirectSubmittedApplication'
import PrintPageButton from 'ui-framework/components/primitives/PrintPageButton'
import useSelectProfileAndRedirect from 'pages/Services/hooks/useSelectUserByApplicationDialog'
import { useParams } from 'react-router-dom'

//===================================================

type Props = {

} & BaseComponentProps

/**
 * TASummaryPage component
 */
const TASummaryPage = (props: Props) => {
  const { bblid = '' } = useParams(),
    application = useApplication(bblid, TAStatuses.InProgress),
    [formData, context] = useMemo(
      () => [application?.data?.serviceData as TaFormData, application?.data],
      [application]
    )
  const [handleBack, userSelectDialog] = useSelectProfileAndRedirect(
    application,
    URL_SERVICETALIST
  ),
    generateBackButton = useCallback(
      (
        variant: ButtonVariantProps = 'textSecondary',
        width: string = 'auto'
      ) => (
        <Button
          w={width}
          variant={variant}
          prefix={`left`}
          onClick={handleBack}
        >
          Back to Property List
        </Button>
      ),
      [handleBack]
    )

  // useRedirectIfNotThisApplicationStatuses(
  //   application,
  //   [TAStatuses.Submit, TAStatuses.Imported],
  //   URL_SERVICETAFORM
  // )

  return (
    <Flex direction="column" grow={1}>
      <PageTitle
        title={context ? getShortAddress(context) : 'Loading...'}
        subtitle={context && getBBLString(context)}
        leftAction={generateBackButton()}
        sticky
      />
      <Flex grow={1} justifyContent="space-between" width="100%">
        <Box width="100%">
          {formData && context && (
            // TODO: after refreshing validation of RPIE steps, props 'validation' should be deleted from here and from the type
            <>
              <Subtitle title="Summary Page" margin="0 0 0 54px" />
              <SummaryTable
                data={formData}
                context={context}
                sectionsConfig={TASummarySectionsConfig}
              />

              <HStack
                h="80px"
                m="40px 24px 80px 54px"
                alignItems="center"
                justifyContent="right"
                sx={{
                  borderTop: '1px solid',
                  borderColor: 'secondary.divider',
                  '@media print': {
                    display: 'none',
                  },
                }}
                spacing={'20px'}
              >
                {generateBackButton('secondary', '200px')}
                <PrintPageButton>Print Summary Page</PrintPageButton>
              </HStack>
            </>
          )}
        </Box>
      </Flex>
      {userSelectDialog}
    </Flex>
  )
}

export default TASummaryPage
