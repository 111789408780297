import { ExpenseCategories } from 'constants/dicti'
import { SectionPrompts } from 'ui-framework/components/patterns/Wizard/SectionsTable'

export const expenseMainPrompts: SectionPrompts = {
  [ExpenseCategories.Fuel]: {
    content: [{ description: 'Con Ed steam, Gas for heating, Oil' }],
  },
  [ExpenseCategories.Light]: {
    content: [
      {
        description:
          'City and State utility tax, Electricity, Gas for cooking stove, NYC and NYS utility tax.',
      },
    ],
  },
  [ExpenseCategories.Cleaning]: {
    content: [{ description: 'Cleaning service contract.' }],
  },
  [ExpenseCategories.Wages]: {
    content: [
      {
        title: 'D-M',
        description: `Disability welfare, Employee benefits, Federal unemployment insurance, Federal, State and City withholding tax, FICA social security tax, Health insurance, Hospitalization, Major medical, Management commissions.`,
      },
      {
        title: 'N-W',
        description:
          'New York State unemployment insurance, Payroll Tax, Pension, Salaries (except directors & officers), State unemployment insurance, Union dues, Workmen`s compensation.',
      },
    ],
  },
  [ExpenseCategories.Repairs]: {
    content: [
      {
        title: 'A-F',
        description:
          'A/C repairs or upkeep, Air conditioning repairs or upkeep, Alarm system maintenance, Appliance repairs, Asbestos maintenance, Asphalt repair, Boiler repairs, Building repairs, Burglar and fire alarm system maintenance, Carpenters, Chemicals for cleaning, Cleaning Service, Cleaning Supplies.',
      },
      {
        title: 'J-R',
        description:
          'Janitorial Services, Janitorial Supplies, Landscaping, Lawn, Lobby Maintenance, Locksmiths, Masonry, Outside labor, Parking lot repairs, Plastering, Plumbers, Plumbing repairs, Pointing ($500 or less), Pollution repairs, Refrigeration repairs, Roof repairs.',
      },
      {
        title: 'E-I',
        description:
          'Electrical system repairs, Electricians, Elevator repairs, Emergency repair service, Equipment rental, Exterior painting, Exterminator/Pest Control, Gardening, Gas service, General maintenance and repairs, Glaziers, Graffiti removal, Hall maintenance, Hardware, HVAC, Insecticide, Intercom repairs, Iron work.',
      },
      {
        title: 'S-Z',
        description:
          'Safety devices, Security, Sidewalk repairs, Smoke detectors, Snow removal, Sprinkler system maintenance, Stairwell maintenance, Supplies necessary for maintenance and repairs, Swimming pool maintenance, Tile repairs, Waterproofing, Welders, Window cleaning, Window guards.',
      },
    ],
  },
  [ExpenseCategories.Management]: {
    content: [
      {
        title: 'A-D',
        description:
          'Accounting Fees, Administrative Fees, ADT computer payroll service, Association dues, Auditing, BID fees, Bookkeeping fees, Building registration fee, Carting, Certified mail, Collection fees, Computer processing, Consultation fees, Credit Card Fees, Credit Check, Data processing costs, DHCR Monitoring, Directory service, Dispossess filing fees, Dues.',
      },
      {
        title: 'N-R',
        description:
          'Office expense, Office Supplies, Outside management, Outside services (other than subcontracted labor). Permits, Post Office Box fee, Postage, Professional Fees, Protection, Real Estate Publications and Journals, Realty Advisory Board fees, Rent collection fees, Rent stabilization association fee, Rubbish removal.',
      },
      {
        title: 'E-M',
        description: `Elevator service contract, Environmental protection, Escalation billing service, Eviction fees (except $1000 and under), Food for watchdogs, General office expense, Inspections (boilers, elevator, fire, etc.), Interim Multiple Dwelling filing fee, Keys, Legal Fees, Loft Board fees, Management agent fees, Management fees, Marshall's fees, Maximum base rent filing fee, Membership fees, Messenger ($200 or less), Meter reading service (water meters, electric meters, etc.).`,
      },
      {
        title: 'S-Z',
        description: `Scavenger service, Security Guards, Security Service, Service charges, Service contracts, Settlement, Small property owners association, Stationery, Superintendent's telephone, Tank registration, Telecommunication, Telephone, Tenant relations, Trash/Garbage/Rubbish removal, Uniforms, Uniforms (purchase and cleaning), Vault tax, Water conditioning, Water purification, Water treatment service.`,
      },
    ],
  },
  [ExpenseCategories.Insurance]: {
    content: [
      {
        description:
          'Boiler explosion premium, Fire premium, Liability premium, Rent fidelity bonds premium, Theft premium.',
      },
    ],
  },
  [ExpenseCategories.Water]: {
    content: [
      {
        description:
          'Frontage, Sewer charges or taxes, Water charges or taxes.',
      },
    ],
  },
  [ExpenseCategories.Advertising]: {
    content: [
      {
        description: `Advertising related to specific property rentals, Newspaper ads, NYC illuminated sign charge, Promotional ads, Television ads.`,
      },
    ],
  },
  [ExpenseCategories.Interior]: {
    content: [
      {
        description: `Brushes, Decorating, Interior Painting, Labor for interior decorating, Paint, Painting and Plastering, Spackling, Wallpaper.`,
      },
    ],
  },
  [ExpenseCategories.Leasing]: {
    content: [
      {
        description: `Amortized leasing commissions, Brokers fees, Consultants fees, Leasing agent's fees, Leasing contracts, Prorated leasing commissions.`,
      },
    ],
  },
}
