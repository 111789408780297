import React, { useCallback, useEffect, useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'

import { apiDateFormat } from 'helpers/formats'
import dayjs from 'dayjs'
import { getCallback, getNameAndErrorProps } from 'helpers/utils'

import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import { wrap } from 'object-path-immutable'
import StepBasic from '../StepBasic'
import { getForamttedDateRange } from 'pages/Services/Rpie/RpieFormPage/wizard/common'
import { HStack, VStack } from '@chakra-ui/react'
import DateInput from 'ui-framework/components/primitives/Calendar/DateInput'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { AccountingBasis, ReportingPeriodType } from 'constants/dicti'
import useReportingPeriod from './useReportingPeriod'
import { appBuildYear } from 'services/common/config/calendar'

//==================================================

type StepReportingPeriodProps = {
  reportingYear: number
  startDate?: Date | null
  endDate?: Date | null
  reportingPeriodTypeCS: ReportingPeriodType
  accountingBasisCS: AccountingBasis
  hasCustomPeriod: boolean
} & BaseComponentProps &
  StepPropsInterface<any>

export const setDefaultPeriod = (
  wrappedData: WrappedObject<any>,
  year: number
): WrappedObject<any> =>
  wrappedData
    .set('startDate', dayjs().year(year).startOf('year').format(apiDateFormat))
    .set('endDate', dayjs().year(year).endOf('year').format(apiDateFormat))

/**
 * StepReportingPeriod component
 */
const StepReportingPeriod = ({
  reportingYear = appBuildYear - 1,
  reportingPeriodTypeCS,
  startDate,
  endDate,
  accountingBasisCS,
  hasCustomPeriod = false,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: StepReportingPeriodProps) => {
  const [startDateConfig, endDateConfig] = useReportingPeriod({
    startDate,
    endDate,
    reportingYear,
    reportingPeriodTypeCS,
    disabled,
  })
  const getChangedValues = useCallback(
    (data: object, clearedDates: boolean) => {
      const result = wrap(data)
      if (clearedDates) setDefaultPeriod(result, reportingYear)
      return result.value()
    },
    [reportingYear]
  ),
    handleOnCorrectnessChange = (value: boolean) => {
      const v = getChangedValues(
        {
          hasCustomPeriod: value,
        },
        !value
      )

      getCallback(onChange)(v)
    },
    handleDateChange = (value: Date | null | undefined, name?: string) => {
      if (!name) return
      getCallback(onChange)({
        [name]: dayjs(value).format(apiDateFormat),
      })
    },
    handleTypeChange = useCallback(
      (val, name) =>
        getCallback(onChange)(getChangedValues({ [name]: val }, true)),
      [getChangedValues, onChange]
    ),
    validationData = useMemo(
      () => ({
        reportingPeriodTypeCS,
        startDate,
        endDate,
        accountingBasisCS,
        hasCustomPeriod,
      }),
      [
        reportingPeriodTypeCS,
        startDate,
        endDate,
        accountingBasisCS,
        hasCustomPeriod,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError),
    nonCustomPeriodText = useMemo(() => {
      // rangeOfYear(reportingYear)
      return getForamttedDateRange(startDate, endDate)
    }, [endDate, startDate])

  // if endDate from endDateConfig is changed, we need call onDateChange to update the value
  useEffect(() => {
    if (endDateConfig && endDateConfig.value !== endDate) {
      handleDateChange(endDateConfig.value, 'endDate')
    }
  }, [endDateConfig.value])

  return (
    <StepBasic className={props.className}>
      <VStack spacing={'36px'} w={`690px`} align={'flex-start'}>
        <HelpTarget name={'isPeriodCorrect'}>
          <SelectsGroup
            variant={'horizontal'}
            error={hasError('hasCustomPeriod')}
            label={
              <>
                Is the Reporting Period of <b>{nonCustomPeriodText}</b> correct?
              </>
            }
          >
            <Radiobutton
              name={'hasCustomPeriod'}
              selected={false}
              value={hasCustomPeriod}
              onChange={handleOnCorrectnessChange}
              disabled={disabled}
            >
              Yes
            </Radiobutton>
            <Radiobutton
              name={'hasCustomPeriod'}
              selected={true}
              value={hasCustomPeriod}
              onChange={handleOnCorrectnessChange}
              disabled={disabled}
            >
              No
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        {hasCustomPeriod && (
          <>
            <HelpTarget name={'reportingPeriodTypeCS'}>
              <SelectsGroup
                label={`Type of Reporting Period Year`}
                disabled={disabled}
                error={hasError('reportingPeriodTypeCS')}
              >
                <Radiobutton
                  value={reportingPeriodTypeCS}
                  selected={ReportingPeriodType.Calendar}
                  name={'reportingPeriodTypeCS'}
                  disabled={disabled}
                  onChange={handleTypeChange}
                >
                  Calendar
                </Radiobutton>

                <Radiobutton
                  value={reportingPeriodTypeCS}
                  selected={ReportingPeriodType.Fiscal}
                  name={'reportingPeriodTypeCS'}
                  disabled={disabled}
                  onChange={onChange}
                >
                  Fiscal
                </Radiobutton>

                <Radiobutton
                  value={reportingPeriodTypeCS}
                  selected={ReportingPeriodType.Partial}
                  name={'reportingPeriodTypeCS'}
                  disabled={disabled}
                  onChange={onChange}
                >
                  Partial
                </Radiobutton>
              </SelectsGroup>
            </HelpTarget>

            <HStack spacing="36px">
              <HelpTarget name={'reportingPeriodStart'}>
                <DateInput
                  label={'Start of Reporting Period'}
                  onChange={handleDateChange}
                  width={'200px'}
                  {...startDateConfig}
                  {...nameAndErrorProps('startDate')}
                />
              </HelpTarget>

              <HelpTarget name={'reportingPeriodEnd'}>
                <DateInput
                  label={'End of Reporting Period'}
                  onChange={handleDateChange}
                  width={'200px'}
                  {...endDateConfig}
                  {...nameAndErrorProps('endDate')}
                />
              </HelpTarget>
            </HStack>
          </>
        )}

        <HelpTarget name={'accountingBasisCS'}>
          <SelectsGroup
            label={`Accounting Basis`}
            disabled={disabled}
            error={hasError('accountingBasisCS')}
          >
            <Radiobutton
              value={accountingBasisCS}
              selected={AccountingBasis.Cash}
              name={'accountingBasisCS'}
              disabled={disabled}
              onChange={onChange}
            >
              Cash
            </Radiobutton>
            <Radiobutton
              value={accountingBasisCS}
              selected={AccountingBasis.Accrual}
              name={'accountingBasisCS'}
              disabled={disabled}
              onChange={onChange}
            >
              Accrual
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
      </VStack>
    </StepBasic>
  )
}

export default StepReportingPeriod
