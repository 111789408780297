import React from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'

import Footer from 'ui-framework/components/patterns/Footer'
import { chakra } from '@chakra-ui/react'

//===================================================
const SimpleLayoutWrapper = chakra('div', {
  baseStyle: {
    bgColor: 'secondary.sideMenu',
    height: '100%',
  },
})

type ContentProps = {}
const Content = styled('div')<ContentProps>(props => ({
  boxSizing: 'border-box',
  minHeight: '100%',
  paddingBottom: 36,
}))

type FooterWrapperProps = {}
const FooterWrapper = styled('div')<FooterWrapperProps>(props => ({
  height: 36,
  marginTop: -36,
}))

type Props = {} & BaseComponentProps

/**
 * SimpleLayout component
 */
const SimpleLayout = (props: Props) => {
  return (
    <SimpleLayoutWrapper className={props.className}>
      <Content>{props.children}</Content>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </SimpleLayoutWrapper>
  )
}

export default SimpleLayout
