import { Services } from 'constants/dicti'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import useUserState from 'hooks/useUserState'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebase } from 'helpers/firebaseInit'
import { useAppConfig } from 'hooks/useApplicationContext'
import { isCallback } from 'helpers/utils'
import { get } from 'object-path-immutable'

//=============================================

const subscribe = setFn => () =>
    setFn({
      answerOnApplicationId: null,
      answerDate: firebase.firestore.FieldValue.serverTimestamp(),
      wasInterested: true,
      processedByBackEnd: false,
    }),
  year = dayjs().year().toString()

const useAction = () => {
  const navigate = useNavigate()
  const { profile } = useAuth(),
    appconf = useAppConfig()

  const checkAgreement = useCallback(
    key => profile?.availableAgreementServices?.includes(key),
    [profile]
  )

  const [rpieState, rpieStateSet] = useUserState(
    'rpieMarketing',
    undefined,
    'years',
    year
  )
  const [taState, taStateSet] = useUserState(
    'taxAppealMarketing',
    undefined,
    'years',
    year
  )

  const subsribedToServiceWithoutAgreement = useCallback(
    serviceType => {
      const notAgreement = !checkAgreement(serviceType)

      switch (serviceType) {
        case Services.TaxAppeal:
          return taState?.answerDate && notAgreement
        case Services.RPIE:
          return rpieState?.answerDate && notAgreement
        default:
          return null
      }
    },
    [checkAgreement, rpieState, taState]
  )
  const getActionByKeyWithAgr = useCallback(
    (key, fallbackUrl) =>
      fallbackUrl
        ? checkAgreement(key)
          ? () => navigate(fallbackUrl)
          : subscribe(
              [key].includes(Services.TaxAppeal) ? taStateSet : rpieStateSet
            )
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subsribedToServiceWithoutAgreement, navigate]
  )

  const actions = useMemo(
    () => key => {
      const hasAgreement = checkAgreement(key)
      const actionTextConf = get(appconf, ['actionText', key])
      const actionText = isCallback(actionTextConf)
        ? actionTextConf(hasAgreement)
        : actionTextConf
      return {
        actionText,
        hasAgreement,
        action: getActionByKeyWithAgr(key, get(appconf, ['fallbackUrl', key])),
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, appconf]
  )
  return [actions, subsribedToServiceWithoutAgreement]
}

export default useAction
