import BaseComponentProps from 'common/BaseComponentProps'
import Button from 'ui-framework/components/primitives/Button'

type PrintPageButtonProps = BaseComponentProps

const PrintPageButton = (props: PrintPageButtonProps) => (
  <Button w={`188px`} variant={'secondary'} onClick={() => window.print()}>
    {props.children}
  </Button>
)

export default PrintPageButton
