import { ComponentStyleConfig } from '@chakra-ui/react'

const SelectsGroupStyle: ComponentStyleConfig = {
  parts: [
    'wrapper',
    'title',
    'groupWrapper',
    'radioWrapper',
    'radio',
    'radioTitle',
    'message',
  ],
  baseStyle: props => ({
    wrapper: {},
    title: {
      color: 'fontnavy',
    },
    radioWrapper: {
      cursor: 'pointer',
      alignItems: 'center',
      'input[type="radio"]': {
        display: 'none',
      },
      _hover: {
        '&.normal > *': {
          borderColor: 'primary.base',
        },
      },
      '&.disabled, &.disabledChecked': {
        cursor: 'default',
        '& > *': {
          color: 'fontandicongray',
          borderColor: 'fontandicongray',
        },
      },
      '&.disabledChecked': {
        '& :before': {
          bgColor: 'primary.disabled',
        },
      },
      '&.checked': {
        '& > *': {
          borderColor: 'primary.base',
        },
        '& :before': {
          bgColor: 'primary.base',
        },
      },
    },
    radioTitle: {
      color: 'fontnavy',
    },
    radio: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:before': {
        content: "''",
      },
    },
  }),
  sizes: {
    md: props => ({
      radio: {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: '1px solid',
        borderColor: 'fontandicongray',
        '&:before': {
          width: '8px',
          height: '8px',
          borderRadius: '6px',
        },
      },
      title: {
        textStyle: 'body.regular',
      },
      radioTitle: {
        marginLeft: '12px',
        textStyle: 'body.regular',
      },
    }),
  },
  variants: {
    horizontal: props => ({
      groupWrapper: {
        flexDirection: 'row',
      },
      radioWrapper: {
        margin: '12px 24px 0 0',
      },
    }),
    vertical: props => ({
      groupWrapper: {
        flexDirection: 'column',
      },
      radioWrapper: {
        margin: '12px 0 0 0',
        alignItems: 'flex-start',
      },
      radio: {
        margin: '5px 0 0 0',
      },
      radioTitle: {
        width: '100%',
        lineHeight: '24px',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'horizontal',
  },
}

export default SelectsGroupStyle
