import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpStepSFInfoProps = {} & BaseComponentProps

/**
 * HelpStepSFInfo component
 */
const HelpStepSFInfo = (props: HelpStepSFInfoProps) => {
  return (
    <p>
      Enter Tenant's contact information. If not available, click{' '}
      <b>Contact Information is not available.</b>
    </p>
  )
}

export default HelpStepSFInfo
