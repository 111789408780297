import styled from '@emotion/styled/macro'

type TableRowProps = {
  clickable?: boolean
}
const TableRow = styled('tr')<TableRowProps>(
  ({ clickable }) =>
    clickable && {
      cursor: 'pointer',
    }
)

export default TableRow
