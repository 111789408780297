import React from 'react'
import { ReactComponent as Mgny } from './mgny_logo.svg'
import { Box } from '@chakra-ui/react'

const Logo = props => {
  return (
    <Box className={props.className} {...props}>
      <Mgny {...props} />
    </Box>
  )
}

export default Logo
