import React from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import CenterForm from './CenterForm'
import { Text, VStack, chakra } from '@chakra-ui/react'
import Button from 'ui-framework/components/primitives/Button'

import { URL_AUTH_LOGIN } from 'constants/URLS'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'
import { useNavigate } from 'react-router-dom'

//===================================================
type EmailSentNotificationProps = {
  email: string
} & BaseComponentProps

/**
 * EmailSentNotification component
 */
const EmailSentNotification = (props: EmailSentNotificationProps) => {
  const navigate = useNavigate()

  return (
    <CenterForm title="Instructions Sent">
      <VStack spacing="24px" width="100%">
        <VStack
          spacing="12px"
          bgColor="green.opacity"
          borderRadius="10px"
          p="12px"
        >
          <Text>
            We've sent an email to{' '}
            <chakra.b whiteSpace="nowrap">{props.email}</chakra.b> with password
            reset instructions.
          </Text>
          <Text>
            If the email doesn't show up soon, check your spam folder. We sent
            it from{' '}
            <chakra.b whiteSpace="nowrap">
              no-reply@portal.mgnyconsulting.com
            </chakra.b>
          </Text>
        </VStack>

        <Button w="100%" onClick={() => navigate(URL_AUTH_LOGIN)}>
          Back to Log In
        </Button>

        <Button
          variant="textPrimary"
          to={`mailto:${SUPPORT_CONTACTS.contactUsEmail}`}
        >
          Contact Us
        </Button>
      </VStack>
    </CenterForm>
  )
}

export default EmailSentNotification
