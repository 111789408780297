import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'
import dayjs from 'dayjs'

import Grid from 'ui-framework/components/layout/Grid'
import Day, { DaySelectedState } from './Day'
import Cell from 'ui-framework/components/layout/Grid/Cell'
import WeekDay from './WeekDay'

import BaseComponentProps from 'common/BaseComponentProps'
import { OnChangeHandler } from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'

//===================================================
type MonthViewWrapperProps = {}
const MonthViewWrapper = styled(Grid)<MonthViewWrapperProps>(props => ({
  width: '100%',
  userSelect: 'none',
}))

type Props = {
  value?: Date | null
  endValue?: Date
  interval?: boolean
  min?: Date
  max?: Date
  year: number
  month: number
  period?: any
  onChange: OnChangeHandler<Date>
} & BaseComponentProps

/**
 * MonthView component
 */
const MonthView = ({
  value,
  endValue,
  interval,
  max,
  min,
  year,
  month,
  onChange,
  period,
  ...props
}: Props) => {
  const firstDate = useMemo(
    () => dayjs(new Date(year, month, 1)),
    [month, year]
  )

  const startDate = useMemo(
      () => dayjs(firstDate).subtract(firstDate.day(), 'day'),
      [firstDate]
    ),
    daysOfWeek = 7

  const renderDay = (i, j) => {
    const cd = dayjs(startDate).add(j, 'day'),
      inRange = period
        ? cd.isAfter(dayjs(period.start)) &&
          cd.isBefore(dayjs(period.end).startOf('day'))
        : false,
      disabled =
        cd.isAfter(firstDate.endOf('month')) ||
        cd.isBefore(firstDate) ||
        (max && cd.isAfter(dayjs(max))) ||
        (min && cd.isBefore(dayjs(min))),
      single =
        (cd.isAfter(firstDate.startOf('month')) &&
          cd.isBefore(firstDate.endOf('month'))) ||
        cd.isSame(firstDate.startOf('month'))

    let selected: DaySelectedState | undefined
    if (value && dayjs(value).startOf('day').isSame(cd.startOf('day')))
      selected = endValue && interval ? 'start' : 'single'
    else if (
      endValue &&
      interval &&
      dayjs(endValue).startOf('day').isSame(cd.startOf('day'))
    )
      selected = 'end'
    else if (
      value &&
      endValue &&
      interval &&
      cd.startOf('day').isAfter(dayjs(value).startOf('day')) &&
      cd.startOf('day').isBefore(dayjs(endValue).startOf('day'))
    )
      selected = 'continuous'

    if (period) {
      selected =
        cd.isSame(dayjs(period.end).startOf('day')) ||
        cd.isSame(dayjs(period.start).startOf('day'))
    }

    const item =
      single || !disabled ? (
        <Day
          selected={selected}
          disabled={!interval ? disabled : false}
          date={cd.toDate()}
          inRange={inRange}
          onClick={() => getCallback(onChange)(cd)}
        />
      ) : undefined

    return (j <= daysOfWeek || item) && <Cell key={j}>{item}</Cell>
  }

  return (
    <MonthViewWrapper
      ref={props.ref}
      className={props.className}
      columns={daysOfWeek}
      gap={'0px'}
    >
      {[0, 1, 2, 3, 4, 5, 6].map(i => (
        <Cell key={i}>
          <WeekDay day={i} />
        </Cell>
      ))}
      {Array(42).fill(null).map(renderDay)}
    </MonthViewWrapper>
  )
}

export default MonthView
