import React, { useRef, ChangeEvent, useMemo } from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'

import { OnChangeHandler } from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'
import UploadDropZoneUI from './UploadDropZoneUI'
import FileList from './FileList'
import { validateFile } from 'helpers/utils'
import { UploadFiles, Files } from 'hooks/api/documents/useDocumentFiles'
import { SUPPORTED_FILES_TYPES } from 'constants/CONSTANTS'

type UploadDropZoneTitleProps = {}
const UploadDropZoneTitle = styled('span')<UploadDropZoneTitleProps>(props => ({
  fontSize: (props.theme as any).fontSizes.body,
  color: (props.theme as any).colors.fontnavy,
  alignSelf: 'start',
  fontFamily: (props.theme as any).fonts.body,
}))
//===================================================
type Props = {
  files: Files
  docName?: string
  fileFormatsMessage?: React.ReactNode
  disabled?: boolean
  error?: string
  onUpload?: OnChangeHandler<File[]>
  onDownload?: OnChangeHandler<any>
  onDelete?: OnChangeHandler<UploadFiles>
  onRetry?: OnChangeHandler<UploadFiles>
} & BaseComponentProps

/**
 * UploadDropZone component
 */
const UploadDropZone = ({
  docName,
  fileFormatsMessage,
  disabled,
  files,
  error,
  onUpload,
  onDownload,
  onDelete,
  onRetry,
  ...props
}: Props) => {
  const fileInput = useRef<HTMLInputElement>(null)

  const showFiles = useMemo(() => files.length > 0, [files.length])

  const handleClick = () => fileInput.current?.click()

  const onFilesUpload = (e: ChangeEvent<HTMLInputElement> | any) => {
    if (disabled) return

    const { dataTransfer, currentTarget } = e,
      files: File[] = [],
      uploadItems = dataTransfer
        ? Object.values(dataTransfer?.items || dataTransfer?.files)
        : currentTarget?.files
        ? Array.from(currentTarget.files)
        : []

    const hasFilesToUpload = data => {
      if (!data?.length) return false
      e.preventDefault()
      const addItem = elem => {
        const file = elem.kind === 'file' ? elem.getAsFile() : elem
        if (validateFile(file)) files.push(file as File)
      }

      data.forEach(addItem)

      return files.length
    }

    if (hasFilesToUpload(uploadItems)) getCallback(onUpload)(files)
  }

  return (
    <div className={props.className}>
      {docName && (
        <UploadDropZoneTitle>
          Upload your {docName} Statement
        </UploadDropZoneTitle>
      )}
      <div style={{ margin: '24px 0 0' }}>
        <UploadDropZoneUI
          fileFormatsMessage={fileFormatsMessage}
          disabled={disabled}
          error={error}
          onClickFilesToUpload={handleClick}
          onDrop={onFilesUpload}
        />
        {showFiles ? (
          <FileList
            files={files}
            onDelete={onDelete}
            onRetry={onRetry}
            onDownload={onDownload}
            disabled={disabled}
          />
        ) : undefined}
      </div>
      <input
        ref={fileInput}
        type="file"
        multiple
        onChange={onFilesUpload}
        accept={SUPPORTED_FILES_TYPES}
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default UploadDropZone
