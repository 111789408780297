import { ComponentStyleConfig } from '@chakra-ui/theme'

const ErrorInfoStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'wrapperInner'],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      '.icon': {
        fill: 'red.base',
      },
    },
    tooltip: {
      borderRadius: '8px',
      padding: '8px',
      textStyle: `caption.regular`,
    },
    wrapperInner: {
      position: 'absolute',
      right: props.rightPosition || '8px',
    },
  }),
  // Styles for the size variations
  sizes: {
    sm: props => ({}),
    md: props => ({}),
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default ErrorInfoStyle
