import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { getCurrentYearDuration, getFiscalPeriods } from 'helpers/utils'
import Joi from 'joi'
import { StorefrontUnitOccupancyType } from 'constants/dicti'

const occupantIntervalItemSchema = {
  occupantTypeCS: schemaRules.NOT_EMPTY_STRING,
  startDate: schemaRules.NOT_EMPTY_STRING,
  endDate: schemaRules.NOT_EMPTY_DATE.min(Joi.ref('startDate'))
    .custom((v, helpers) => {
      const { context } = helpers?.prefs,
        value = context?.originalValue?.occupants

      const { reportingYear } = getFiscalPeriods(context)

      const { getDuration, daysOfYear } = getCurrentYearDuration(reportingYear),
        filledDays = value.reduce(
          (acc, v) => (acc += getDuration(v.startDate, v.endDate)),
          1
        ),
        filledType = value.every(op => op.occupantTypeCS)

      if (daysOfYear >= filledDays && filledType)
        return helpers.error('custom.range')
    })
    .messages({
      'date.greater': 'End date should be greater than start date',
      'custom.range':
        'A full year is required! Add another interval or change the End Date.',
    }),
  vacantType: {
    hadConstruction: Joi.when('..occupantTypeCS', {
      is: StorefrontUnitOccupancyType.Vacant,
      then: schemaRules.BOOLEAN.optional(),
      otherwise: schemaRules.ANY,
    }),
  },
}

const schema = schemaWrapper({
  occupants: schemaRules.ITEMS(occupantIntervalItemSchema),
})

export default schema
