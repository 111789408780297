import React from 'react'
import useApplicationContext from 'hooks/useApplicationContext'
import UserInfo from 'ui-framework/components/primitives/UserInfo'
import MainMenu from './MainMenu'
import {
  useMultiStyleConfig,
  Box,
  Flex,
  Divider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { ReactComponent as Logo } from './mgny_logo.svg'
import Icon from 'ui-framework/components/primitives/Icon'
import UpdateNotification from './UpdateNotification'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import NotificationBar from 'ui-framework/components/patterns/NotificationBar'
import Link from 'ui-framework/components/primitives/Link'
import { URL_HOME } from 'constants/URLS'
import { useMainMenu } from 'hooks/ui/MainMenuProvider'
import EffectiveProfileInfo from 'ui-framework/components/primitives/UserInfo/EffectiveProfileInfo'
import { IsAdmin } from 'helpers/utils'

const MainLayout = props => {
  const menu = useMainMenu(),
    variant = menu.isOpen ? 'open' : 'hidden',
    toogleIcon = !menu.isOpen ? 'right' : 'left',
    {
      update: { hasUpdate, update },
      nbar: { nbar },
    } = useApplicationContext(),
    isAdmin = IsAdmin(),
    style = useMultiStyleConfig('MGNYMainLayout', {
      variant,
      hasUpdate,
      isAdmin,
    })

  return (
    <>
      <Box sx={style.wrapper}>
        <Flex sx={style.menu}>
          <Box>
            <Link to={URL_HOME} decorate={false}>
              <Logo />
            </Link>
          </Box>
          <Box
            as={`button`}
            sx={style.switcher}
            onClick={menu.onToggle}
            tabIndex={0}
          >
            <Icon size={`xs`} color={isAdmin ? 'red.base' : undefined}>
              {toogleIcon}
            </Icon>
            <Text className="update_descr">Portal update available</Text>
          </Box>
          <Box sx={style.menuItem}>
            <MainMenu />
          </Box>
          <Box sx={style.menuFooter}>
            <VStack spacing="18px">
              {hasUpdate && <UpdateNotification onUpdate={update} />}
              <Divider />
              {isAdmin && <EffectiveProfileInfo />}
              <UserInfo />
            </VStack>
          </Box>
        </Flex>
        <Flex sx={style.boxContent} id={`content`}>
          <NotificationBar variant={nbar?.type} text={nbar?.message} />
          {props.children}
        </Flex>
      </Box>
      <ModalDialog
        isOpen={hasUpdate === 'force'}
        title="New Version of the Portal"
        actionText="Update Now"
        onAction={update}
      >
        Our portal has recently been updated. To continue, click the update now
        button. <b>All of the previous data you entered will be saved</b>.
      </ModalDialog>
    </>
  )
}

export default MainLayout
