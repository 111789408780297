import { ComponentStyleConfig } from '@chakra-ui/theme'

const PromptStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'tooltip'],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      alignItems: 'center',
      '.icon': {
        svg: {
          fill: 'fontandicongray',
          _hover: {
            fill: 'primary.base',
          },
        },
      },
    },
    tooltip: {
      color: 'fontnavy',
      fontSize: '13px',
      borderRadius: '8px',
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      wrapper: {},
      tooltip: {
        p: '8px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default PromptStyle
