import { chakra, Portal } from '@chakra-ui/react'
import BaseComponentProps from 'common/BaseComponentProps'
import { MutableRefObject, useLayoutEffect } from 'react'
import FocusTrap from 'focus-trap-react'

const Background = chakra('div', {
  baseStyle: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    left: 0,
    top: 0,
    zIndex: '999',
    width: '100vw',
    height: '100vh',
    userSelect: 'none',
  },
})

const Popup = chakra('div', {
  baseStyle: {
    zIndex: '999',
    position: 'absolute',
    top: 0,
  },
})
const Wrapper = chakra('div', {
  baseStyle: {
    '*:focus': {
      outline: 'none',
    },
  },
})

type Props = {
  isOpen: boolean
  targetRef: MutableRefObject<HTMLDivElement>
  pointRef: MutableRefObject<HTMLDivElement>
} & BaseComponentProps

const OnboardingPopup = ({
  children,
  isOpen,
  targetRef,
  pointRef,
  ...props
}: Props) => {
  useLayoutEffect(() => {
    const content = document.querySelector('#content') as HTMLDivElement
    if (targetRef.current)
      if (isOpen) {
        // disable user scroll
        content.style.overflow = 'hidden'
        // set zIndex to target and popup
        targetRef.current.style.zIndex = '1000'
      } else {
        content.style.overflow = ''
        targetRef.current.style.zIndex = ''
      }
  }, [pointRef, isOpen, targetRef])

  if (!isOpen || !targetRef.current) return null
  return (
    <Portal>
      <Background
      // onKeyUpCapture={stop}
      // onKeyDownCapture={stop}
      // onClickCapture={stop}
      />
      <FocusTrap active={true}>
        <Wrapper>
          <Wrapper tabIndex={0}></Wrapper>
          <Popup
            tabIndex={1}
            top={targetRef.current.offsetTop + 10}
            right={targetRef.current.offsetWidth + 20}
          >
            {children}
          </Popup>
        </Wrapper>
      </FocusTrap>
    </Portal>
  )
}

export default OnboardingPopup
