import { BuildingClasses, TAStatuses } from 'constants/dicti'
import { hasIntersections } from 'helpers/utils'
import { Application } from 'hooks/api/firebase/useApplication'
import { floorsKeys } from './useTaFormDataHook'

export enum PropertyListAction {
  NONE = 'none',
  RESET = 'reset',
  START = 'start',
  CONTINUE = 'continue',
  VIEW = 'view',
}

export enum PropertyListStatusType {
  IN_PROGRESS = 'inprogress',
  SUBMITTED = 'submitted',
  NEW = 'new',
  COMPLETE = 'complete',
  NO_SUBMISSSION = 'nosubmission',
  TC101_READY = 'tc101ready',
  TC101_IMPORTED = 'tc101imported',
  IMPORTED = 'imported',
  FILED = 'filed',
}

type StatusTypeMap = {
  [status: string]: PropertyListStatusType
}

const statusTypeMap: StatusTypeMap = {
  [TAStatuses.AwaitVerification]: PropertyListStatusType.IN_PROGRESS,
  [TAStatuses.Imported]: PropertyListStatusType.IMPORTED,

  [TAStatuses.InProgress]: PropertyListStatusType.IN_PROGRESS,
  null: PropertyListStatusType.NEW,
  [TAStatuses.New]: PropertyListStatusType.NEW,
  [TAStatuses.NoSubmission]: PropertyListStatusType.NO_SUBMISSSION,
  [TAStatuses.Submit]: PropertyListStatusType.SUBMITTED,
  [TAStatuses.SubmitOutOfPortal]: PropertyListStatusType.SUBMITTED,
  [TAStatuses.SubmitPendingDocs]: PropertyListStatusType.SUBMITTED,
  [TAStatuses.TC101Imported]: PropertyListStatusType.IN_PROGRESS,
  [TAStatuses.TC101Ready]: PropertyListStatusType.IN_PROGRESS,
}

const typeActionMap = {
  [PropertyListStatusType.IN_PROGRESS]: PropertyListAction.CONTINUE,
  [PropertyListStatusType.COMPLETE]: PropertyListAction.NONE,
  [PropertyListStatusType.NEW]: PropertyListAction.START,
  [PropertyListStatusType.NO_SUBMISSSION]: PropertyListAction.NONE,
  [PropertyListStatusType.SUBMITTED]: PropertyListAction.VIEW,
  [PropertyListStatusType.IMPORTED]: PropertyListAction.VIEW,
}

const businessClassesWithAllowedEmptyUses = [
  BuildingClasses.V0,
  BuildingClasses.V1,
  BuildingClasses.V2,
  BuildingClasses.V3,
  BuildingClasses.V4,
  BuildingClasses.V5,
  BuildingClasses.V6,
  BuildingClasses.V7,
  BuildingClasses.V8,
  BuildingClasses.V9,

  BuildingClasses.G6,
  BuildingClasses.G7,

  BuildingClasses.Q1,
  BuildingClasses.Q2,
  BuildingClasses.Q3,
  BuildingClasses.Q4,
  BuildingClasses.Q5,
  BuildingClasses.Q6,
  BuildingClasses.Q7,
  BuildingClasses.Q8,
  BuildingClasses.Q9,
]

type UseServiceStatusResult = {
  getStatusAction: (application: Application<any>) => PropertyListAction
  getStatusType: (application: Application<any>) => PropertyListStatusType
}

export type UseServiceStatus = () => UseServiceStatusResult

const useTaStatus: UseServiceStatus = () => {
  const initialStatus = TAStatuses.New
  
  const getStatusType = (
    application: Application<any>
    ): PropertyListStatusType => {
    const status = application.statusCS || initialStatus
    return status
      ? statusTypeMap[status]
      : PropertyListStatusType.NO_SUBMISSSION
  }

  const getStatusAction = (
    application: Application<any>
  ): PropertyListAction => {
    // disable actions if the applications is not for vacant land and don't have any uses
    const disableActions =
        floorsKeys.every(
          floorKey =>
            application.defaultServiceData.uses.usesTables[floorKey].length ===
            0
        ) &&
        !hasIntersections(
          application.bblsData.buildingClasses,
          businessClassesWithAllowedEmptyUses
        ),
      action =
        typeActionMap[getStatusType(application)] || PropertyListAction.NONE

    return disableActions ? PropertyListAction.NONE : action
  }

  return { getStatusAction, getStatusType }
}

export default useTaStatus
