import { ComponentStyleConfig } from '@chakra-ui/theme'
//
const HelpBlockStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['wrapper', 'boxHeader', 'switcher', 'boxDesc', 'innerWrapper'],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      padding: '0 16px',
      backgroundColor: 'secondary.sideMenu',
      flexDirection: 'column',
      position: 'relative',
      borderLeft: '1px solid',
      borderColor: 'secondary.divider',
      overflow: 'hidden',
      '@media print': {
        display: 'none',
      },
    },
    innerWrapper: {
      position: props.fixed ? 'fixed' : 'absolute',
      maxHeight: '100%',
      top: 0,
      bottom: 0,
      width: '290px',
    },
    boxHeader: {
      padding: '28px 0',
      width: '95%',
      textStyle: 'body.semibold',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: 'fontnavy',
      '.icon': {
        border: 'none',
        svg: {
          fill: 'fontandicongray',
        },
      },
    },
    boxDesc: {
      textStyle: 'body.regular',
      lineHeight: '25px',
      color: 'fontandicongray',
      overflowY: 'scroll',
      p: {
        hyphens: 'auto',
      },
      'p:not(:last-child)': {
        marginBottom: '24px',
      },
      'ul, ol': {
        marginLeft: '16px',
      },
      ul: {
        listStyleType: "'-'",
      },
      li: {
        paddingLeft: '8px',
      },
      'li:not(:last-child)': {
        marginBottom: '12px',
      },
    },
    switcher: {
      borderRadius: '6px 6px 0 0',
      height: '40px',
      width: '107px',
      position: 'fixed',
      transform: 'rotate(-90deg) translate(-33px, 0)',
      top: '200px',

      textStyle: 'body.semibold',
      backgroundColor: 'secondary.base',
      display: 'none',
      color: 'primary.base',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  }),
  // Styles for the size variations
  sizes: {
    md: {
      wrapper: {},
      boxHeader: {},
      switcher: {},
      boxDesc: {},
    },
  },
  // Styles for the visual style variations
  variants: {
    open: {
      wrapper: {
        width: '400px',
      },
    },
    hidden: {
      innerWrapper: {
        display: 'none',
      },
      switcher: {
        display: 'flex',
      },
      wrapper: {
        width: '0',
        padding: '18px 0 0 0',
        right: '75px',
        marginLeft: '50px',
        border: 'none',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'close',
  },
}

export default HelpBlockStyle
