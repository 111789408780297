import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Text, HStack } from '@chakra-ui/react'
import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import useModalContent, {
  Answer,
  DISCOUNT_ANSWER,
  DISCUSS_ANSWER,
  THINK_ABOUT_IT_ANSWER,
} from './useModalContent'
import useUserState from 'hooks/useUserState'
import useAuth from 'hooks/useAuth'
import { BuildingClasses, Services, TaxClasses } from 'constants/dicti'
import { AnyFunction } from 'helpers/utils'
import { firebase } from 'helpers/firebaseInit'
import useQueryParamState from 'hooks/useQueryParamState'

const ASSESMENT_VALUE_FOR_SHOW_DISCOUNT_DIALOG: number = 40000,
  includes = (data: string[] = [], params: string[] = []): boolean =>
    data.some(param => params.includes(param))

const useAfterSubmitRPIEDiscountDialog = (
  data?: any,
  onAction?: AnyFunction
): [() => void, ReactNode] => {
  const discountDialog = useDisclosure(),
    [, holdRedirectSet] = useQueryParamState('hr', false),
    { profile } = useAuth(),
    [savedRpieMarketingDialogData, stateSet] = useUserState(
      'rpieMarketing',
      undefined,
      'years',
      `${data?.fiscalYear}`
    ),
    [answer, answerSet] = useState<Answer>(DISCOUNT_ANSWER),
    modalContent = useModalContent(answer),
    shouldShow = useMemo(() => {
      const arentAlreadyAnswered = !savedRpieMarketingDialogData?.answerDate,
        availableAgreementServices = profile?.availableAgreementServices,
        hasNoRpieAgreement =
          availableAgreementServices &&
          !availableAgreementServices.includes(Services.RPIE),
        hasProperAv =
          (data?.bblsData?.assesmentData?.tentativeActualAV || 0) >
          ASSESMENT_VALUE_FOR_SHOW_DISCOUNT_DIALOG,
        hasTaxClass24 = includes(data?.bblsData?.taxClass, [
          TaxClasses._2,
          TaxClasses._4,
        ]),
        hasTaxClass2A2B = includes(data?.bblsData?.taxClass, [
          TaxClasses._2A,
          TaxClasses._2B,
        ]),
        hasBuildingClass = includes(data?.bblsData?.buildingClasses, [
          BuildingClasses.C7,
          BuildingClasses.S9,
        ])

      return (
        arentAlreadyAnswered &&
        hasNoRpieAgreement &&
        hasProperAv &&
        (hasTaxClass24 || (hasTaxClass2A2B && hasBuildingClass))
      )
    }, [
      data?.bblsData?.assesmentData?.tentativeActualAV,
      data?.bblsData?.buildingClasses,
      data?.bblsData?.taxClass,
      profile,
      savedRpieMarketingDialogData,
    ]),
    openModalHandler = useCallback(() => {
      if (shouldShow) {
        holdRedirectSet(true)
        discountDialog.onOpen()
      } //else if (onAction) getCallback(onAction)()
    }, [shouldShow, holdRedirectSet, discountDialog]),
    answerHandler = useCallback(
      answer => {
        answerSet(answer)
        stateSet({
          answerOnApplicationId: data?.id,
          answerDate: firebase.firestore.FieldValue.serverTimestamp(),
          wasInterested: answer === DISCUSS_ANSWER,
          processedByBackEnd: false,
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data?.id, holdRedirectSet, stateSet]
    )

  return [
    openModalHandler,
    <ModalDialog
      key="discountDialog"
      isLazy
      isOpen={discountDialog.isOpen}
      title={modalContent?.image}
      size="w540"
      footerActions={
        answer !== DISCOUNT_ANSWER ? (
          <Button onClick={onAction} width={'100%'}>
            {modalContent.actionText}
          </Button>
        ) : (
          <HStack align={`right`} spacing={'20px'}>
            <Button
              variant={`textPrimary`}
              onClick={() => answerHandler(THINK_ABOUT_IT_ANSWER)}
            >
              I'll Think About it
            </Button>
            <Button onClick={() => answerHandler(DISCUSS_ANSWER)}>
              {modalContent.actionText}
            </Button>
          </HStack>
        )
      }
    >
      <Text
        textStyle={'body.semibold'}
        lineHeight={'33px'}
        margin={'0 0 16px'}
        fontSize={'22px'}
      >
        {modalContent.title}
      </Text>
      <Text lineHeight={'25px'}>{modalContent.body}</Text>
    </ModalDialog>,
  ]
}

export default useAfterSubmitRPIEDiscountDialog
