import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import TestPage from 'pages/Other/TestPage'

import {
  URL_TEST,
  URL_HOME,
  URL_SERVICERPIELIST,
  URL_AUTH_REGISTRATION,
  URL_AUTH_LOGIN,
  URL_AUTH_RESET,
  URL_SERVICERPIEFORMSUMMARY,
  URL_SERVICERPIEFORM,
  URL_DOCUMENTS,
  URL_ERROR404,
  URL_SERVICETAFORM,
  URL_SERVICETALIST,
  URL_SERVICETAFORMSUMMARY,
  URL_ACCESS_DENIED,
  URL_HANDLING,
  URL_ROOT,
} from 'constants/URLS'
import RegistrationPage from 'pages/Auth/RegistrationPage'
import LoginCustomPage from 'pages/Auth/LoginCustomPage'
import PasswordResetPage from 'pages/Auth/PasswordResetPage'
import MainLayout from 'ui-framework/components/layout/MainLayout'
import RpieSummaryPage from 'pages/Services/Rpie/RpieSummaryPage/RpieSummaryPage'
import RpieWizard from 'pages/Services/Rpie/RpieFormPage/RpieWizard'
import RpieListPage from 'pages/Services/Rpie/RpieListPage/RpieListPage.new'
import HomePage from 'pages/Services/home/HomePage'
import DocumentCenter from 'pages/DocumentCenter/DocumentCenter'
import NotFoundPage from 'pages/Errors/NotFoundPage'
import NoPermissionPage from 'pages/Errors/NoPermissionPage'
import TAWizard from '../pages/Services/TA/TAFormPage/TAWizard'
import TAListPage from 'pages/Services/TA/TAListPage/TAListPage.new'
import TASummaryPage from 'pages/Services/TA/TASummaryPage/TASummaryPage'
import useRegisterPageViewAnalytics from 'hooks/analytics/useRegisterPageViewAnalytics'
import Handling from 'pages/Other/Handling'
import { Resource } from 'constants/grants'
import { isProduction } from 'helpers/utils'
import useAuth from 'hooks/useAuth'
import { useAppConfig } from 'hooks/useApplicationContext'
import Loader from 'ui-framework/components/primitives/Loader'
import RequireAuth from 'routing/common/RequireAuth'
import useTrackAccessEvent from 'hooks/analytics/useTrackAccessEvent'

//==================================================

type Props = {}

/**
 * Routes component
 */
const ApplicationRoutes = (props: Props) => {
  const { isPending } = useAuth(),
    config = useAppConfig()
  useRegisterPageViewAnalytics()
  useTrackAccessEvent()

  if (isPending || !config) return <Loader height="100vh" />
  return (
    <Routes>
      {!isProduction() && <Route path={URL_TEST} element={<TestPage />} />}

      <Route path={URL_ROOT} element={<Navigate to={URL_HOME} replace />} />

      <Route path={URL_AUTH_REGISTRATION} element={<RegistrationPage />} />

      <Route path={URL_AUTH_LOGIN} element={<LoginCustomPage />} />

      <Route path={URL_AUTH_RESET} element={<PasswordResetPage />} />

      <Route
        path={URL_HOME}
        element={
          <RequireAuth>
            <MainLayout>
              <HomePage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path={URL_SERVICERPIEFORMSUMMARY}
        element={
          <RequireAuth>
            <MainLayout>
              <RpieSummaryPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path={URL_SERVICERPIEFORM}
        element={
          <RequireAuth resource={Resource.RPIE_FORM}>
            <MainLayout>
              <RpieWizard />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path={URL_SERVICERPIELIST}
        element={
          <RequireAuth resource={Resource.RPIE_PROPERTYLIST}>
            <MainLayout>
              <RpieListPage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path={URL_SERVICETAFORMSUMMARY}
        element={
          <RequireAuth>
            <MainLayout>
              <TASummaryPage />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path={URL_SERVICETAFORM}
        element={
          <RequireAuth resource={Resource.TA_FORM}>
            <MainLayout fixed>
              <TAWizard />
            </MainLayout>
          </RequireAuth>
        }
      />
      <Route
        path={URL_SERVICETALIST}
        element={
          <RequireAuth resource={Resource.TA_PROPERTYLIST}>
            <MainLayout>
              <TAListPage />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path={URL_DOCUMENTS}
        element={
          <RequireAuth>
            <MainLayout>
              <DocumentCenter />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path={URL_HANDLING}
        element={
          <RequireAuth resource={Resource.ADMIN}>
            <MainLayout>
              <Handling />
            </MainLayout>
          </RequireAuth>
        }
      />

      <Route
        path={URL_ACCESS_DENIED}
        element={
          <MainLayout>
            <NoPermissionPage />
          </MainLayout>
        }
      />
      <Route
        path={URL_ERROR404}
        element={
          <MainLayout>
            <NotFoundPage />
          </MainLayout>
        }
      />
      <Route
        element={
          <MainLayout>
            <NotFoundPage />
          </MainLayout>
        }
      />
    </Routes>
  )
}

export default ApplicationRoutes
