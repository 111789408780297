import { ComponentStyleConfig } from '@chakra-ui/theme'

const AttentionBoxStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'title', 'warningMark', 'contentWrapper'],
  baseStyle: props => ({
    wrapper: {
      color: 'fontnavy',
      borderRadius: '10px',
      overflow: 'hidden',
    },
    title: {
      textStyle: 'body.semibold',
      lineHeight: '25px',
      mb: '12px',
    },
    contentWrapper: {
      p: '16px',
    },
    warningMark: {
      alignItems: 'center',
      justifyContent: 'center',
      bgColor: 'orange.base',
      display: 'none',
    },
  }),
  sizes: {
    sm: props => ({}),
    md: props => ({
      warningMark: {
        w: '24px',
        p: '0 4px',
      },
    }),
  },
  variants: {
    primary: props => ({ wrapper: { bgColor: 'white100' } }),
    warning: props => ({
      wrapper: {
        bgColor: 'orange.opacity',
      },
      warningMark: {
        display: 'flex',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
}

export default AttentionBoxStyle
