import React, { useState, useEffect, useCallback } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import Input from 'ui-framework/components/primitives/Input'
import Button from 'ui-framework/components/primitives/Button'
import { OnChangeHandler } from 'ui-framework/common/types'
import { getCallback, getNameAndErrorProps } from 'helpers/utils'
import { VStack, Divider, Flex } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/react'
import useShowPassword from 'hooks/useShowPassword'
import NeedHelpBlock from 'ui-framework/components/patterns/HelpBlock/NeedHelpBlock'
import CenterForm from './CenterForm'
import { get, wrap } from 'object-path-immutable'
import useDoubleClickValidationCallback from 'hooks/useDoubleClickValidationCallback'
import { registerSchema } from 'pages/Auth/schema'

type Props = {
  firstName?: string
  lastName?: string
  email?: string
  onRegister?: OnChangeHandler<any>
} & BaseComponentProps

/**
 * RegistrationForm component
 */
const RegistrationForm = ({
  firstName = '',
  lastName = '',
  email = '',
  onRegister,
  ...props
}: Props) => {
  const [registerInfo, registerInfoSet] = useState({
      firstName,
      lastName,
      email,
      password: '',
    }),
    [type, showIcon] = useShowPassword()

  const handleRegister = useCallback(
    regData =>
      getCallback(onRegister)({
        email: registerInfo.email,
        password: registerInfo.password,
      }),
    [onRegister, registerInfo.email, registerInfo.password]
  )

  const [errors, handleCreatePassword] = useDoubleClickValidationCallback(
      registerInfo,
      registerSchema,
      handleRegister
    ),
    getError = path => get(errors, path)

  const handleChange = (v, n) => {
    const value = wrap(registerInfo).set(n, v).value()
    registerInfoSet(value)
  }

  useEffect(() => {
    if (email !== registerInfo.email) {
      registerInfoSet({
        ...registerInfo,
        firstName,
        lastName,
        email,
      })
    }
  }, [email, firstName, lastName, registerInfo])

  return (
    <CenterForm title="Create Password" padding="24px 24px 40px">
      <VStack align="unset" spacing="24px" width="100%">
        <Input
          label="Password"
          type={type as any}
          value={registerInfo?.password}
          onChange={handleChange}
          width={'100%'}
          postfix={showIcon}
          {...getNameAndErrorProps('password', getError)}
        />
        <Box>
          <Divider marginTop={errors?.password ? `12px` : 0} />
        </Box>
        <Flex justifyContent="space-between">
          <Input
            label="First name"
            disabled={true}
            value={firstName}
            width={'170px'}
          />
          <Input
            label="Last name"
            disabled={true}
            value={lastName}
            width={'170px'}
          />
        </Flex>
        <Input label="Email" disabled={true} value={email} width={'100%'} />
        <Button onClick={handleCreatePassword} width={'100%'}>
          Create Password
        </Button>
        <NeedHelpBlock />
      </VStack>
    </CenterForm>
  )
}

export default RegistrationForm
