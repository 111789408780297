import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { firebase } from 'helpers/firebaseInit'

const schema = schemaWrapper({
  files: schemaRules
    .MIN_ARRAY(1)
    .custom((value, helpers) => {
      let errorMsg = 'Waiting for upload.'
      const { files } = helpers?.prefs?.context?.originalValue,
        { RUNNING, ERROR } = firebase.storage.TaskState,
        showErrorMsg = msg => helpers.message(msg)

      if (
        files.some(
          f =>
            f.uploadProgress ||
            f.state === RUNNING ||
            (ERROR === f.state &&
              (errorMsg =
                'Remove file with error or try to reload / download new file.'))
        )
      )
        return showErrorMsg(errorMsg)
    })
    .messages({ 'array.min': 'Please Upload at least one file.' }),
})

export default schema
