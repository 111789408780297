import { IsAdmin } from 'helpers/utils'
import useQueryParamState from 'hooks/useQueryParamState'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useHistory } from 'react-router-dom'

const useConditionalRedirect = (route, condition) => {
  const isAdmin = IsAdmin(),
    navigate = useNavigate(),
    [holdRedirect] = useQueryParamState('hr', false)

  useEffect(() => {
    !isAdmin && !holdRedirect && condition && navigate(route)
  }, [condition])
}

export default useConditionalRedirect
