import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'

const lessOrEqual = (fieldName, label) => (value, helpers) => {
    const dependValue = helpers.prefs.context?.originalValue[fieldName],
      msg = `Must be less or equal ${label}`

    if (dependValue < value) return helpers.message(msg)
  },
  checkTotalCounts = (v, helpers) => {
    const {
        unitsApplicant = 0,
        numberOfResRegulatedUnit = 0,
        numberOfResUnregulatedUnit = 0,
      } = helpers.prefs.context?.originalValue,
      msg =
        'The sum of Owner-occupied, Regulated and Unregulated Units must equal Total # of Residential Units'
    if (
      v !==
      unitsApplicant + numberOfResRegulatedUnit + numberOfResUnregulatedUnit
    )
      return helpers.message(msg)

    return v
  }

const schema = schemaWrapper({
  numberOfResidentialUnit:
    schemaRules.NOT_EMPTY_NUMBER.custom(checkTotalCounts),
  unitsApplicant: Joi.when('numberOfResidentialUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules
      .MIN_MAX_NUMBER(0, Joi.ref('numberOfResidentialUnit'))
      .message(
        'The # of owner-occupied units must be less than # of Residential Units'
      ),
    otherwise: schemaRules.ANY,
  }),
  numberOfResRegulatedUnit: Joi.when('numberOfResidentialUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.NOT_EMPTY_NUMBER,
    otherwise: schemaRules.ANY,
  }),
  vacantRegulated: Joi.when('numberOfResRegulatedUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.NOT_EMPTY_NUMBER.custom(
      lessOrEqual('numberOfResRegulatedUnit', '# of Regulated Units')
    ),
    otherwise: schemaRules.ANY,
  }),
  numberOfResUnregulatedUnit: Joi.when('numberOfResidentialUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.NOT_EMPTY_NUMBER,
    otherwise: schemaRules.ANY,
  }),
  vacantUnregulated: Joi.when('numberOfResUnregulatedUnit', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.NOT_EMPTY_NUMBER.custom(
      lessOrEqual('numberOfResUnregulatedUnit', '# of Unregulated Units')
    ),
    otherwise: schemaRules.ANY,
  }),
  hasSuperUnit: Joi.when('unitsApplicant', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.BOOLEAN,
    otherwise: schemaRules.ANY,
  }),
})

export default schema
