const SIZE_50MB: number = 52428800

const SUPPORTED_FILES_TYPES: string = `
  image/jpeg,image/png,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/msword,application/vnd.ms-excel, 
  application/pdf,.pdf,.doc,.jpg`

const KEYCODES: { [key: string]: number } = {
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
  ESC: 27,
  LEFT_ARROW: 37,
  UP: 38,
  RIGHT_ARROW: 39,
  DOWN: 40,
}

const REGEXPS = {
  MATCH_FROM_SECOND_WORD: /\s+\S+/g,
  MATCH_FIRST_WORD: /^\S+\s/,
  MATCH_SOME_SYMBOL: /\S{1,}/,
  MATCH_LETTER_OR_NUMBER: /^(?=.*[A-Za-z0-9]).{1,}$/,
  MATCH_DASH: /\//g,
}

const SUPPORT_CONTACTS = {
  contactUsEmail: 'taxappeal@mgnyconsulting.com',
  supportTel: '212-343-1111',
  supportTAEmail: 'taxappeal@mgnyconsulting.com',
  supportRPIEEmail: 'rpie@mgnyconsulting.com',
}

export { SIZE_50MB, SUPPORTED_FILES_TYPES, KEYCODES, REGEXPS, SUPPORT_CONTACTS }
