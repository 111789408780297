import React, { useCallback, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'

import { UsesTables, TaFormData, IncomeSection } from 'hooks/api/ta/TaFormData'

import { isDivisibleBy, mapDictiNames } from 'helpers/utils'

import {
  IncomeCategories,
  IncomeSectionTypes,
  PortionRentFromAnyTenants,
} from 'constants/dicti'
import Prompt from 'ui-framework/components/primitives/Prompt'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import SectionsTable, {
  emptyForList,
  emptyForManually,
} from 'ui-framework/components/patterns/Wizard/SectionsTable'
import { categoriesEditTypeCS } from 'ui-framework/components/patterns/Wizard/SectionsTable/useSections'

//===================================================
export const additionalCategories = mapDictiNames([
  IncomeCategories.OperatingEscalation,
  IncomeCategories.TaxEscalation,
  IncomeCategories.SaleUtility,
  IncomeCategories.SaleOther,
  IncomeCategories.GovernmentRent,
  IncomeCategories.Signage,
  IncomeCategories.CellTower,
  // IncomeCategories.LateFees,
  // IncomeCategories.TenantCharges,
])

const MAX_OTHER_ITEMS = 6,
  COUNT_FOR_DISPLAY_DIFFERENCE_WARNING = -10

const isRegOrNonReg = (categoryCS: IncomeCategories): boolean =>
  [IncomeCategories.ResRegulated, IncomeCategories.ResUnregulated].includes(
    categoryCS
  )

type Props = {
  sections: IncomeSection[]
  usesTables: UsesTables
  isResidentialUploaded: boolean
  numberOfResidentialUnit: number
  numberOfRegulatedUnits: number
  numberOfUnregulatedUnits: number
  portionVacantTroubleTenantCS: PortionRentFromAnyTenants
  wasIncomeFromRentalTenants: boolean | null
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyDetailsIncome component
 */
const StepPropertyDetailsIncome = (props: Props) => {
  const {
    sections,
    isResidentialUploaded,
    numberOfResidentialUnit,
    portionVacantTroubleTenantCS,
    wasIncomeFromRentalTenants,
    applyValidation,
    onValidation,
  } = props,
    [isResidentialOnly, isNonResidentialOnly] = useMemo(
      () => [
        portionVacantTroubleTenantCS === PortionRentFromAnyTenants.Residential,
        portionVacantTroubleTenantCS ===
        PortionRentFromAnyTenants.NonResidential,
      ],
      [portionVacantTroubleTenantCS]
    ),
    lastYearPrompt = useCallback(
      (item, index, diffsValues) => {
        const regOrNonReg = isRegOrNonReg(item.categoryCS as IncomeCategories),
          isBelow =
            (isResidentialOnly || isNonResidentialOnly) &&
            diffsValues[index] <= COUNT_FOR_DISPLAY_DIFFERENCE_WARNING &&
            item.prevYearAmount &&
            item.categoryCS &&
            (isNonResidentialOnly ? regOrNonReg : !regOrNonReg)

        return (
          ((item.lastYearAmount && isDivisibleBy(item.lastYearAmount, 100)) ||
            isBelow) && (
            <Prompt
              iconParams={{ className: 'warning' }}
              maxW={`203px`}
              label={
                isBelow
                  ? `Attention! No vacancies were reported on a previous step.`
                  : "Tax Commission doesn't accept rounded numbers!"
              }
              type={`warning`}
            />
          )
        )
      },
      [isResidentialOnly, isNonResidentialOnly]
    ),
    validationData = useMemo(
      () => ({
        isResidentialUploaded,
        numberOfResidentialUnit,
        sections,
        wasIncomeFromRentalTenants,
      }),
      [
        isResidentialUploaded,
        numberOfResidentialUnit,
        sections,
        wasIncomeFromRentalTenants,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    items = useMemo(
      () => [
        {
          type: IncomeSectionTypes.IncomeMain,
          title: 'Rental',
          params: {
            lastYearPrompt,
          },
        },
        {
          type: IncomeSectionTypes.IncomeAdditional,
          title: 'Ancillary',
          params: {
            lastYearPrompt,
            categoriesEditType: categoriesEditTypeCS.list,
            categoriesForList: additionalCategories,
            newLineOptions: {
              empty: emptyForList,
              getDisabled: originalSectionItemsCount =>
                originalSectionItemsCount === 0 ||
                originalSectionItemsCount >=
                Object.keys(additionalCategories).length,
            },
          },
        },
        {
          type: IncomeSectionTypes.HotelMain,
          title: 'Departmental',
          params: {
            lastYearPrompt,
          },
        },
        {
          type: IncomeSectionTypes.HotelRental,
          title: 'Income from rental tenants',
          params: {
            lastYearPrompt,
            visible: wasIncomeFromRentalTenants,
          },
        },
        {
          type: IncomeSectionTypes.CondoMain,
          title: 'Income from unit owners',
          params: {
            lastYearPrompt,
          },
        },
        {
          type: IncomeSectionTypes.CondoRental,
          title: 'Rental',
          params: {
            lastYearPrompt,
            visible: wasIncomeFromRentalTenants,
          },
        },
        {
          type: [
            IncomeSectionTypes.HotelOther,
            IncomeSectionTypes.CondoOther,
            IncomeSectionTypes.IncomeOther,
          ],
          title: 'Other',
          params: {
            categoriesEditType: categoriesEditTypeCS.manually,
            lastYearPrompt,
            newLineOptions: {
              empty: emptyForManually,
              getDisabled: originalSectionItemsCount =>
                originalSectionItemsCount === 0 ||
                originalSectionItemsCount >= MAX_OTHER_ITEMS,
            },
          },
        },
      ],
      [wasIncomeFromRentalTenants, lastYearPrompt]
    )

  return (
    <SectionsTable
      {...props}
      sections={sections}
      items={items}
      hasError={hasError}
      modalContent={`Caution! You are removing an income source previously added. Confirm that it needs to be removed.`}
      totalFooterLabel={`Total Gross Income`}
      tableParams={{
        isSticky: true,
        colorChildIndexes: [0, 2, 4],
      }}
    />
  )
}

export default StepPropertyDetailsIncome
