import { useEffect, useState } from "react";


const useUnsupportedBrowsers = () => {
  const [isSupported, isSupportedSet] = useState(true);

  useEffect(() => {
    if (!/(Edg|Edge|Chrome|Firefox|Safari)/.test(navigator.userAgent)) {
      // window.location.href = '/supporting'
      isSupportedSet(false);
    }
  }, []);

  return isSupported;
}

export default useUnsupportedBrowsers;