import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Button from 'ui-framework/components/primitives/Button'
import { Flex, Box } from '@chakra-ui/react'
// import useApplicationContext from 'hooks/useApplicationContext'

//===================================================
type Props = {} & BaseComponentProps

/**
 * NeedHelpBlock component
 */
const NeedHelpBlock = (props: Props) => {
  // const { openChat } = useApplicationContext()

  const callOpenClick = () => {
    const frame = document.createElement('iframe')

    frame.setAttribute(
      'src',
      'https://mgny.as.me/schedule.php?appointmentType=2322190'
    )
    frame.setAttribute('width', '100%')
    frame.setAttribute('height', '100%')
    frame.setAttribute('frameBorder', '0')
    window.open('', '_blank', '')?.document.body.appendChild(frame)
  }

  return (
    <Box className={props.className} id={props.id}>
      <Flex justifyContent="space-between">
        {/* <Button variant={`textPrimary`} onClick={openChat} height={`auto`}>
          Chat with us
        </Button> */}
        <Button variant={`textPrimary`} onClick={callOpenClick} height={`auto`}>
          Schedule a call
        </Button>
      </Flex>
    </Box>
  )
}

export default NeedHelpBlock
