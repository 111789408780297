import styled from '@emotion/styled/macro'

type PaginationWrapperProps = {}
const PaginationWrapperUI = styled.div<PaginationWrapperProps>(props => ({
  margin: '15px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& button:nth-child(even)': {
    margin: '0 12px',
  },
}))

export default PaginationWrapperUI
