import React, { useMemo, memo } from 'react'
import {
  useMultiStyleConfig,
  Flex,
  Tooltip,
  ChakraProps,
  TooltipProps,
} from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'
import Icon from '../Icon'
import isEqual from 'lodash/isEqual'

type ErrorInfoProps = {
  hasError: boolean
  message?: string
  rightPosition?: string
  info?: ChakraProps
  tooltipParams?: TooltipProps
} & ChakraComponentProps

//TODO dont create component if we need only children (???)
const ErrorWithInfo = (props: ErrorInfoProps) => {
  const { hasError, message = 'Required', tooltipParams } = props,
    label = useMemo(
      () => (Array.isArray(message) ? message[0] : message),
      [message]
    ),
    style = useMultiStyleConfig('MGNYErrorInfo', props)

  return (
    <Flex className={props.className} sx={style.wrapper}>
      {props.children}
      {hasError ? (
        <Tooltip
          sx={style.tooltip}
          label={label}
          maxW={'230px'}
          arrowSize={8}
          bg={`red.base`}
          hasArrow
          isLazy
          {...tooltipParams}
        >
          <Flex sx={{ ...style.wrapperInner, ...props.info }}>
            <Icon>info-circle</Icon>
          </Flex>
        </Tooltip>
      ) : undefined}
    </Flex>
  )
}

export default memo(ErrorWithInfo as any, isEqual)
