import { getTabFocusSelectors } from 'helpers/utils'
import colors from './colors'

const globalStyles = {
  global: {
    'html, body, #root': {
      height: '100%',
      maxWidth: '100%',
      margin: '0 !important',
      padding: '0 !important',
    },
    '*': {
      boxSizing: 'border-box',
    },
    body: {
      width: '100vw',
      textStyle: 'body.regular',
    },
    '[tabindex="0"]': getTabFocusSelectors(),
    'a[tabindex="0"], [role="tab"], [type="checkbox"] + [tabindex="0"]':
      getTabFocusSelectors({
        outlineOffset: '-1px',
      }),
    '*::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '*::-webkit-scrollbar-track': {
      background: colors.black10,
      border: `5px solid ${colors.white100}`,
      '&:hover': {
        backgroundColor: colors.black20,
      },
      '&:active': {
        backgroundColor: colors.primary50,
      },
    },
    '*::-webkit-scrollbar-thumb': {
      display: 'block',
      borderRadius: '99px',
      backgroundColor: colors.primary30,
      '&:hover': {
        backgroundColor: colors.primary70,
      },
      '&:active': {
        backgroundColor: colors.primary100,
      },
    },
    '@media print': {
      '*': {
        WebkitPrintColorAdjust: 'exact',
      },
      'html, body, #root': {
        overflow: 'visible',
      },
      '#content': {
        height: 'auto',
        overflow: 'visible',
      },
      '@page': {
        size: 'landscape',
      },
    },
  },
}

export default globalStyles
