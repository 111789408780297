import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods } from 'helpers/utils'

//===================================================
type HelpSummaryStepProps = {} & BaseComponentProps

/**
 * HelpSummaryStep component
 */
const HelpSummaryStep = (props: HelpSummaryStepProps) => {
  const { context } = useServiceWizard(),
    { fiscalYear, nextFiscalYear } = getFiscalPeriods(context)
  return (
    <>
      <p>
        Carefully review the information provided for your {fiscalYear}/
        {nextFiscalYear} Property Tax Appeal application.
      </p>
      <p>
        If you need to correct certain information, go "Back" and edit the
        appropriate fields.
      </p>
    </>
  )
}

export default HelpSummaryStep
