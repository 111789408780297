import React, { useEffect, useState, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { getNameAndErrorProps } from 'helpers/utils'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import { nanoid } from 'nanoid'
import { integerMax, integerPositive } from 'helpers/formats'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { HStack, VStack } from '@chakra-ui/react'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'

//===================================================
type Props = {
  numberOfResidentialUnit: number
  numberOfResRegulatedUnit: number
  numberOfResUnregulatedUnit: number
  unitsApplicant: number
  vacantRegulated: number
  vacantUnregulated: number
  hasSuperUnit: boolean
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepGeneralResidentialDescription component
 */
const StepGeneralResidentialDescription = ({
  numberOfResidentialUnit,
  numberOfResRegulatedUnit,
  numberOfResUnregulatedUnit,
  unitsApplicant,
  vacantRegulated,
  vacantUnregulated,
  hasSuperUnit,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  // Solution for #6161
  const validationData = useMemo(
      () => ({
        numberOfResidentialUnit,
        unitsApplicant,
        numberOfResRegulatedUnit,
        vacantRegulated,
        numberOfResUnregulatedUnit,
        vacantUnregulated,
        hasSuperUnit,
      }),
      [
        numberOfResidentialUnit,
        unitsApplicant,
        numberOfResRegulatedUnit,
        vacantRegulated,
        numberOfResUnregulatedUnit,
        vacantUnregulated,
        hasSuperUnit,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  useEffect(() => {
    if (!unitsApplicant) onChange(false, 'hasSuperUnit')
  }, [unitsApplicant, onChange])

  useEffect(() => {
    if (numberOfResidentialUnit === unitsApplicant) {
      onChange(0, 'numberOfResRegulatedUnit')
      onChange(0, 'numberOfResUnregulatedUnit')
    }
  }, [unitsApplicant, onChange, numberOfResidentialUnit])

  useEffect(() => {
    if (!numberOfResRegulatedUnit) onChange(0, 'vacantRegulated')
  }, [unitsApplicant, onChange, numberOfResRegulatedUnit])

  useEffect(() => {
    if (!numberOfResUnregulatedUnit) onChange(0, 'vacantUnregulated')
  }, [unitsApplicant, onChange, numberOfResUnregulatedUnit])

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px">
        <ReviewLastYearDataWarningBlock />

        <HelpTarget name="numberOfResidentialUnit">
          <Input
            label="Total # of Residential Units"
            format={integerMax(999)}
            type="number"
            value={numberOfResidentialUnit}
            placeholder="Enter #"
            disabled={disabled}
            fixedHeight
            onChange={onChange}
            errorTooltip
            {...nameAndErrorProps('numberOfResidentialUnit')}
          />
        </HelpTarget>
        {numberOfResidentialUnit > 0 && (
          <>
            <HelpTarget name="unitsApplicant">
              <Input
                label="# of Owner-occupied units"
                format={integerMax(999)}
                type="number"
                value={unitsApplicant}
                placeholder="Enter #"
                disabled={disabled}
                fixedHeight
                onChange={onChange}
                errorTooltip
                {...nameAndErrorProps('unitsApplicant')}
              />
            </HelpTarget>
            {unitsApplicant < numberOfResidentialUnit &&
              unitsApplicant !== null && (
                <>
                  <HStack spacing="36px">
                    <HelpTarget name="numberOfResRegulatedUnit">
                      <Input
                        label="# of Regulated Units"
                        type="number"
                        format={integerPositive}
                        value={numberOfResRegulatedUnit}
                        placeholder="Enter #"
                        disabled={disabled}
                        fixedHeight
                        onChange={onChange}
                        errorTooltip
                        {...nameAndErrorProps('numberOfResRegulatedUnit')}
                      />
                    </HelpTarget>
                    {numberOfResRegulatedUnit > 0 && (
                      <HelpTarget name="vacantRegulated">
                        <Input
                          label="# of Regulated Vacant Units"
                          type="number"
                          value={vacantRegulated}
                          placeholder="Enter #"
                          disabled={disabled || !(numberOfResRegulatedUnit > 0)}
                          fixedHeight
                          onChange={onChange}
                          errorTooltip
                          {...nameAndErrorProps('vacantRegulated')}
                        />
                      </HelpTarget>
                    )}
                  </HStack>
                  <HStack spacing="36px">
                    <HelpTarget name="numberOfResUnregulatedUnit">
                      <Input
                        label="# of Unregulated Units"
                        type="number"
                        value={numberOfResUnregulatedUnit}
                        placeholder="Enter #"
                        disabled={disabled}
                        fixedHeight
                        onChange={onChange}
                        errorTooltip
                        {...nameAndErrorProps('numberOfResUnregulatedUnit')}
                      />
                    </HelpTarget>
                    {numberOfResUnregulatedUnit > 0 && (
                      <HelpTarget name="vacantUnregulated">
                        <Input
                          label="# of Unregulated Vacant Units"
                          type="number"
                          value={vacantUnregulated}
                          placeholder="Enter #"
                          disabled={
                            disabled || !(numberOfResUnregulatedUnit > 0)
                          }
                          fixedHeight
                          onChange={onChange}
                          errorTooltip
                          {...nameAndErrorProps('vacantUnregulated')}
                        />
                      </HelpTarget>
                    )}
                  </HStack>
                </>
              )}
            {unitsApplicant > 0 && (
              <HelpTarget name="hasSuperUnit">
                <SelectsGroup
                  label="Is there a super unit?"
                  error={hasError('hasSuperUnit')}
                >
                  <Radiobutton
                    value={hasSuperUnit}
                    name="hasSuperUnit"
                    selected={false}
                    disabled={disabled}
                    onChange={onChange}
                  >
                    No
                  </Radiobutton>
                  <Radiobutton
                    value={hasSuperUnit}
                    name="hasSuperUnit"
                    selected={true}
                    disabled={disabled}
                    onChange={onChange}
                  >
                    Yes
                  </Radiobutton>
                </SelectsGroup>
              </HelpTarget>
            )}
          </>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepGeneralResidentialDescription
