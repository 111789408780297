import dayjs from 'dayjs'
import { isNullOrWhiteSpace } from 'helpers/utils'
import { useCallback, useEffect, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'

const getDate = () => dayjs().format('YYYY-MM-DD')

const useCurrentDate = () => {
  const [override, overrideSet] = useState(false)
  const [currentDate, currentDateSet] = useState(getDate())
  const setCD = useCallback(() => {
    !override && currentDateSet(getDate())
  }, [override])
  const overrideDate = useCallback(
    date => {
      overrideSet(!isNullOrWhiteSpace(date))
      currentDateSet(date)
      return date
    },
    [overrideSet, currentDateSet]
  )

  useEffect(() => {
    setCD()
    const timer = setInterval(setCD, 1000 * 60)

    return () => clearInterval(timer)
  }, [setCD])

  // make the func public for testing purposes
  window['currentDate'] = overrideDate

  return currentDate
}

export default singletonHook(undefined, useCurrentDate)
