import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

const schema = schemaWrapper({
  costs: Joi.object({
    contractCost: schemaRules.NOT_EMPTY_NUMBER,
    financingCost: schemaRules.NOT_EMPTY_NUMBER,
    professionalFee: schemaRules.NOT_EMPTY_NUMBER,
    otherCost: schemaRules.NOT_EMPTY_NUMBER,
  })
    .custom(
      (value, helpers) =>
        0 +
          value.contractCost +
          value.financingCost +
          value.professionalFee +
          value.otherCost ===
          0 && helpers.error('any.sum')
    )
    .messages({ 'any.sum': 'The total cost must be more than zero' }),
})

export default schema
