import { ComponentStyleConfig } from '@chakra-ui/theme'

const CardStyle: ComponentStyleConfig = {
  // parts: [''],
  // Styles for the base style
  baseStyle: props => ({
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.08)',
    color: 'fontnavy',
    textStyle: 'body.regular',
    bgColor: 'white100',
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({}),
    w510: props => ({
      w: '510px',
    }),
  },
  // Styles for the visual style variations
  variants: {
    normal: props => ({
      borderRadius: '12px',
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'w510',
    variant: 'normal',
  },
}

export default CardStyle
