import { get, set } from 'object-path-immutable'
import { generatePath } from 'react-router-dom'
import { pathToRegexp, Key } from 'path-to-regexp'
import {
  SIZE_50MB,
  SUPPORTED_FILES_TYPES,
  KEYCODES,
  REGEXPS,
} from 'constants/CONSTANTS'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import keys from 'lodash/keys'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'

import dayjs from 'dayjs'
import Empty from 'ui-framework/components/primitives/Empty'
import usePermission from 'hooks/usePermission'
import { UserRole } from 'constants/grants'
import { ChakraProps } from '@chakra-ui/react'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { getDictiName } from 'constants/dicti'
import firebaseApp from 'helpers/firebaseInit'
import { UserProfile } from 'hooks/useAuth'
import { OrNull } from 'services/common/useFormDataHook'
import { constant } from 'lodash/fp'
import { DurationUnitType } from 'dayjs/plugin/duration'
import { intersection } from 'lodash-es'
import { TabItem } from 'ui-framework/components/primitives/Tabs'

type DescriptionParams = {
  first: string
  others: Array<string>
  default: string
}

type item = {
  id: string
  name: string
}

const getDescription = (
  data: object,
  ind: number,
  params: DescriptionParams,
  showFirst: boolean,
  showOther: boolean
): string => {
  const first = data[params.first as string],
    other = params.others.reduce(
      (acc, other) => (data[other] ? (acc += ` ${data[other]}`) : ''),
      ''
    )

  if (!showFirst && showOther) {
    return `${other}`
  } else if (showFirst && !showOther) {
    return `${first}`
  } else if (showFirst && showOther) {
    return `${first},${other}`
  }
  return `${params.default}${ind + 1}`
}

function groupBy(original: any[], key: string) {
  return original.reduce(function (prev, curr) {
    const i = get(curr, key)
    prev[i] = prev[i] || []
    prev[i].push(curr)
    return prev
  }, {})
}

function isNullOrWhiteSpace(value: any): boolean {
  return value === undefined || value === null || !/\S/.test(value)
}

function dashIfEmpty(value: any, dashtype: string = 'hyphen'): any {
  return !isNullOrWhiteSpace(value) ? value : <Empty type={dashtype} />
}

const getSum =
  (field?: string) =>
    (prev: number, item: any): number =>
      prev + (field ? item[field as string] || 0 : item)

// if useQueryParamStateParams is true then all keys from params which are not
// included in pattern the function will interprets as queryString
const generatePortalPath = (
  pattern: string,
  params: Record<string, any> = {},
  useQueryParamStateParams: boolean | Record<string, any> = false
): string => {
  const keys: Key[] = []
  pathToRegexp(pattern, keys)

  const urlParamsKeyNames = keys.map(i => i.name),
    queryParams =
      typeof useQueryParamStateParams === 'boolean'
        ? useQueryParamStateParams
          ? omit(params, urlParamsKeyNames)
          : {}
        : useQueryParamStateParams,
    searchQuery = new URLSearchParams(omitBy(queryParams, isUndefined)),
    searchQueryResult = searchQuery.toString(),
    urlResults = generatePath(pattern, params)

  return urlResults + (searchQueryResult && '?' + searchQueryResult)
}

const hasError = (
  item: object,
  index: number,
  fields: Array<string>
): 'error' | undefined => {
  if (item) {
    return !item[index]
      ? undefined
      : fields.some(fld => item[index][fld])
        ? 'error'
        : undefined
  }
}

const capitalizeFirstLetter = word =>
  isNullOrWhiteSpace(word) ? word : word.charAt(0).toUpperCase() + word.slice(1)

export type AnyFunction = (...args: any[]) => any

const isCallback = (cb: any): boolean => !!cb && typeof cb === 'function'

const getCallback = (cb: any): AnyFunction =>
  isCallback(cb) ? (cb as AnyFunction) : (arg: any) => arg

const isObject = (val: any): boolean => val === Object(val)

const getDuration = (
  startDate: Date,
  endDate: Date,
  durationCondition: DurationUnitType = 'days'
): number => {
  if (!startDate || !endDate) return 0
  const range = dayjs(endDate).diff(dayjs(startDate)),
    duration = Math.round(dayjs.duration(range).as(durationCondition))
  return duration
}

const getCurrentYearDuration = (checkingYear: number) => {
  const year = dayjs().year(checkingYear),
    daysOfYear = getDuration(
      year.startOf('year') as any,
      year.endOf('year') as any
    )
  return { getDuration, daysOfYear }
}

const getMapping = (items: any, key: string) =>
  items.map(i => set(i, key, i[key].toLowerCase()))

const APP_VERSION = `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_COMMIT}`

const getAmountDiff = (
  prevV: string | number | null,
  newV: string | number | null,
  message: string
): boolean | string => {
  const diff = (100 * (Number(newV) - Number(prevV))) / Number(prevV)
  if (diff) {
    const $diff = diff === Infinity ? 100 : Math.round(diff),
      getMsg = (type: string): string =>
        message.replace('$diff', `${$diff}% ${type}`)

    return diff >= 50 ? getMsg('higher') : diff <= -50 ? getMsg('lower') : false
  }
  return false
}

const validateFile = (file: File): boolean =>
  !!file.type &&
  SUPPORTED_FILES_TYPES.includes(file.type) &&
  file.size <= SIZE_50MB

const keyboardEventListener = (event, handler) =>
  [KEYCODES.ENTER, KEYCODES.SPACE].includes(event.keyCode) &&
  handler &&
  handler()

const trace = tag => v => {
  // eslint-disable-next-line no-console
  log(tag, v)
  return v
}

const IsAdmin = (): boolean => {
  const permission = usePermission(),
    isAdmin = permission.hasRole(UserRole.ADMIN)
  return isAdmin
}

const getTabFocusSelectors = (styles?: ChakraProps): object => {
  const defaultFocusStyles = {
    borderRadius: '6px',
    backgroundColor: 'secondary.base',
    outline: 'solid 1px',
    outlineColor: 'primary.base',
    outlineOffset: '0',
  }

  return {
    '@supports not selector(:focus-visible)': {
      _focus: {
        ...(styles || defaultFocusStyles),
      },
    },
    ':focus-visible': {
      ...(styles || defaultFocusStyles),
    },
  }
}

const getObjectDifference = (source, other) => {
  return reduce(
    source,
    function (result, value, key) {
      if (isObject(value) && isObject(other[key])) {
        result[key] = getObjectDifference(value, other[key])
      } else if (!isEqual(value, other[key])) {
        result[key] = other[key]
      }
      return result
    },
    omit(other, keys(source))
  )
}

const getDateValue = (value: any): Date | undefined => {
  const getDate = v => dayjs(v).toDate()
  return [null].includes(value) ? undefined : getDate(value)
}

const getNumberParams = (
  v: number,
  precision: number = 1
): { rounded: number; isNegative: boolean; isZero: boolean } => {
  const rounded = Number(v.toFixed(precision))
  return {
    rounded,
    isNegative: rounded < 0,
    isZero: rounded === 0,
  }
}

const getDifference = (last: number, current: number) =>
  (100 * (current - last)) / last

const getTotalDiffWithText = (
  items: Array<any> = [],
  lastFld: string,
  currentFld: string,
  textParams: ChakraProps = {
    textAlign: 'left',
  }
) => {
  const createText = (v, isNegative, isZero, textStyle = 'body.regular') => (
    <TextFromParams
      params={{
        ...textParams,
        color: `${isZero ? 'fontnavy' : isNegative ? 'red.base' : 'green.base'
          }`,
        textStyle: textStyle,
      }}
    >
      {isZero ? '0%' : `${isNegative ? '' : '+'}${v}%`}
    </TextFromParams>
  ),
    showDashOrValue = (v, shownValueCb) => (v ? shownValueCb() : <Empty />),
    { diffs, totalLast, totalCurrent, diffsValues } = items.reduce(
      ({ diffs, totalLast, totalCurrent, diffsValues }, item) => {
        const last = +item[lastFld],
          current = +item[currentFld],
          isItemVisible = item.$show !== false,
          difference =
            last && !isNullOrWhiteSpace(current)
              ? getDifference(last, current)
              : 0,
          { rounded, isNegative, isZero } = getNumberParams(difference)

        return {
          diffs: [
            ...diffs,
            showDashOrValue(last, () =>
              createText(rounded, isNegative, isZero)
            ),
          ],
          diffsValues: [...diffsValues, rounded],
          totalLast: totalLast + (isItemVisible ? last : 0),
          totalCurrent: totalCurrent + current,
        }
      },
      {
        diffs: [] as Array<any>,
        diffsValues: [] as Array<number>,
        totalLast: 0,
        totalCurrent: 0,
      }
    ),
    { rounded, isNegative, isZero } = getNumberParams(
      totalLast && !isNullOrWhiteSpace(totalLast)
        ? getDifference(totalLast, totalCurrent)
        : 0
    ),
    totalDiff = showDashOrValue(rounded, () =>
      createText(rounded, isNegative, isZero, 'body.semibold')
    )

  return {
    diffs,
    totalDiff,
    totalLast: showDashOrValue(totalLast, () => totalLast),
    totalCurrent,
    diffsValues,
  }
}

const isDivisibleBy = (value: number, divisor: number): boolean =>
  value % divisor === 0

const isIndexOutOfArray = (arr: Array<any>, index: number) => {
  return index < 0 || index > arr.length - 1
}

export function enumKeys(E: any): string[] {
  return Object.keys(E).filter(k => isNaN(Number(k)))
}

export function enumValues(E: any) {
  return enumKeys(E).map(k => E[k as any])
}

const mapDictiNames = (obj: object | any[]) =>
  (Array.isArray(obj) ? obj : Object.values(obj)).reduce((acc, o) => {
    acc[o] = getDictiName(o)

    return acc
  }, {})

const mapObjectToIdNameItems = (types: object): item[] =>
  Object.entries(types).map(([id, name]) => ({
    id,
    name,
  }))

const dictiToIdNameItem = (constant?: OrNull<string>) =>
  constant
    ? mapObjectToIdNameItems(mapDictiNames([constant]))[0]
    : //TODO: make an global empty IdName constant
    {
      id: null,
      name: null,
    }

const createItemsByDicti = dicti => mapObjectToIdNameItems(mapDictiNames(dicti))

//TODO: create an intarface after rpie refactor (ex: type {} & interfase {fiscalYear: number})
const getFiscalPeriods = (param: { [key: string]: any } = {}) => {
  const { fiscalYear = 0 } = param
  return {
    nextFiscalYear: fiscalYear + 1,
    fiscalYear,
    reportingYear: fiscalYear - 1,
    prevReportingYear: fiscalYear - 2,
  }
}

export type QueryItem = {
  field: string
  condition: string
  value: any
}

const createFiltersForRef = (ref, queryItems: QueryItem[]) =>
  queryItems.length
    ? queryItems.reduce(
      (acc, { field, condition, value }) =>
        acc.where(field, condition, value),
      ref
    )
    : ref

const getDownloadLink = async (filePath: string) =>
  window.open(
    await firebaseApp.storage().ref(filePath).getDownloadURL(),
    '_blank'
  )

const getFullNameByProfile = (userProfile?: UserProfile): string => {
  return `${userProfile?.firstName || 'Unknown Name'} ${userProfile?.lastName || ''
    }`
}

const getFilteredDataByFieldAndCondition = (
  data: { [key: string]: any }[],
  filteredBy: string = '$show',
  condition: any = false
) => data.filter(item => item[filteredBy] !== condition)

const fplog =
  text =>
    fn =>
      (...args) => {
        const ret = fn(...args)
        //TODO: decide whether we need this console:
        // eslint-disable-next-line no-console
        log(`LOG ${text}`, ret, args)
        return ret
      }

const createFakeElement = (role = 'fake', elementType = 'span') => {
  let element = document.querySelector(`[role="${role}"]`)
  if (!element) {
    element = document.createElement(elementType)
    element.setAttribute('role', role)
    document.body.appendChild(element)
  }

  return element as HTMLElement
}

const getNameAndErrorProps = (
  name: string,
  getErrorCallback: AnyFunction,
  path: string = ''
) => ({
  name,
  error: getErrorCallback(path ? `${path}.${name}` : name),
})

const replaceByRuleToItem = (
  value: string,
  item: any = ' - ',
  rule: RegExp = REGEXPS.MATCH_DASH
): string => value.replace(rule, item)

const removeMatched = (value: string, rule: RegExp): string =>
  replaceByRuleToItem(value, '', rule)

const moreOrEqualCount = (data: object | any[], count: number): boolean =>
  Object.keys(data).length >= count

const isProduction = constant(process.env.NODE_ENV === 'production'),
  isStaging = constant(process.env.REACT_APP_STAGING === 'true'),
  getCurrentEnv = isStage => (isStage ? 'staging' : process.env.NODE_ENV)

const log = (...args) => !isProduction() && console.log(getCurrentEnv(isStaging), ...args)

const getItemFromUriByPosition = (
  path: string,
  position: number = 0
): string | undefined => path.split('/').filter(Boolean)[position]

function sanitizePath(input: string, replacement = '') {
  const illegalRe = /[/?<>\\:*|":]/g,
    // controlRe = /[\\x00-\\x1f\\x80-\\x9f]/g,
    reservedRe = /^\.+$/,
    windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i,
    longSpaces = /\s{2,}/g,
    SINGLE_SPACE = ' ',
    sanitized = input
      .replace(illegalRe, replacement)
      .replace(reservedRe, replacement)
      .replace(windowsReservedRe, replacement)
      .replace(longSpaces, SINGLE_SPACE)
  return sanitized.trim()
}

const hasIntersections = (...args) => intersection(...args).length > 0

const onlyPositiveValue = v => (!v || v < 0 ? 0 : v)

const getExceedDOFEstimatedTextForIncome = (percent) => {
  return <>Reported Income exceeds DOF Estimated Income by <b>{percent}%</b>.</>
}

const getExceedDOFEstimatedTextForExpenses = (percent) => {
  return <>Reported Expenses exceed DOF Estimated Expenses by <b>{percent}%</b>.</>
}

const getLowerDOFEstimatedTextForIncome = (percent) => {
  return <>Reported Income is <b>{percent}%</b> lower than DOF Estimated Income.</>
}

const getLowerDOFEstimatedTextForExpenses = (percent) => {
  return <>Reported Expenses are <b>${percent}%</b> lower than DOF Estimated Expenses.</>
}

export {
  groupBy,
  isNullOrWhiteSpace,
  generatePortalPath,
  hasError,
  capitalizeFirstLetter,
  isCallback,
  getCallback,
  isObject,
  getCurrentYearDuration,
  dashIfEmpty,
  getMapping,
  getDescription,
  APP_VERSION,
  getAmountDiff,
  validateFile,
  getSum,
  keyboardEventListener,
  IsAdmin,
  getTabFocusSelectors,
  trace,
  getObjectDifference,
  removeMatched,
  getDateValue,
  getDifference,
  getTotalDiffWithText,
  isDivisibleBy,
  isIndexOutOfArray,
  mapObjectToIdNameItems,
  mapDictiNames,
  dictiToIdNameItem,
  getFiscalPeriods,
  createFiltersForRef,
  getDownloadLink,
  getFullNameByProfile,
  getFilteredDataByFieldAndCondition,
  createItemsByDicti,
  fplog,
  createFakeElement,
  getNameAndErrorProps,
  replaceByRuleToItem,
  moreOrEqualCount,
  isProduction,
  isStaging,
  getCurrentEnv,
  getDuration,
  getItemFromUriByPosition,
  sanitizePath,
  hasIntersections,
  onlyPositiveValue,
  log,
  getExceedDOFEstimatedTextForIncome,
  getExceedDOFEstimatedTextForExpenses,
  getLowerDOFEstimatedTextForIncome,
  getLowerDOFEstimatedTextForExpenses,
}
