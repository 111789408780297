import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { getSum } from 'helpers/utils'

const checkTotal = (values, helpers) => {
  const { roomRates = [], totalRoomsNumber } =
      helpers.prefs.context?.originalValue,
    totalEachNumber = roomRates.reduce(getSum('numberOfeach'), 0)

  if (totalRoomsNumber !== totalEachNumber) {
    return helpers.error('section.totalEachNumber')
  }
}

const roomRate = {
  roomTypeCS: schemaRules.NOT_EMPTY_STRING,
  numberOfeach: schemaRules.GREATER_NUMBER().custom(checkTotal).messages({
    'section.totalEachNumber':
      'The sum in the column "Number of Each" must equal # of All Rooms.',
  }),
  singleRate: schemaRules.NOT_EMPTY_NUMBER,
  doubleRate: schemaRules.NOT_EMPTY_NUMBER,
}

const schema = schemaWrapper({
  totalRoomsNumber: schemaRules.NUMBER,
  roomRates: schemaRules.ITEMS(roomRate),
})

export default schema
