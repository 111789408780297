import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useRegisterEvent from './useRegisterEvent'

const useRegisterPageViewAnalytics = () => {
  const location = useLocation(),
    registerPageView = useRegisterEvent('page_view')

  useEffect(() => {
    registerPageView()
  }, [location, registerPageView])
}

export default useRegisterPageViewAnalytics
