import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

const paidParkingSpotsValidator = inOut => ({
  totalNumberOfParkingSpots: schemaRules.NOT_EMPTY_NUMBER,
  numberOfPaidParkingSpots: Joi.when('....onlyTotal', {
    is: false,
    then: schemaRules
      .MIN_MAX_NUMBER(0, Joi.ref('totalNumberOfParkingSpots'))
      .messages({
        'number.max': `Must be less or equal than # of Total ${inOut} Parking Spaces`,
        'any.ref': `Must be less or equal than # of Total  ${inOut} Parking Spaces`,
      }),
  }),
})

const schema = schemaWrapper({
  parking: {
    indoorParkingUses: paidParkingSpotsValidator('Indoor'),
    outdoorParkingUses: paidParkingSpotsValidator('Outdoor'),
  },
})

export default schema
