import React, { useCallback, useDebugValue, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { Construction, TaFormData } from 'hooks/api/ta/TaFormData'
import { VStack } from '@chakra-ui/react'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import {
  PropertyUsage,
  UseByApplicant,
  PortionUsedByApplicant,
  HotelBuildingClasses,
} from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getCallback, getFiscalPeriods, hasIntersections } from 'helpers/utils'
import {
  addPortionUsedByApplicantQuestion,
  addUseByApplicantQuestion,
  addWasAnyRentalIncomeQuestion,
} from 'services/common/useFormDataHook'

export const useWasAnyRentalIncomeQuestionTitle = (
  buildingClasses,
  reportingYear
) =>
  useMemo(
    () =>
      hasIntersections(HotelBuildingClasses, buildingClasses)
        ? `Was there any income from hotel operations in ${reportingYear}?`
        : `Was there any rental income in ${reportingYear}?`,
    [buildingClasses, reportingYear]
  )

//===================================================
type Props = {
  propertyUsage: PropertyUsage
  applicantName: string
  useByApplicant: UseByApplicant
  portionUsedByApplicantCS: string
  wasAnyConstruction: boolean | null
  wasAnyRentalIncome: boolean | null
  wasIncomeFromRentalTenants?: boolean | null
  construction: Construction | null
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyStatus component
 */
const StepPropertyStatus = ({
  propertyUsage,
  applicantName,
  useByApplicant,
  portionUsedByApplicantCS,
  wasAnyConstruction,
  wasAnyRentalIncome,
  wasIncomeFromRentalTenants,
  construction,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { reportingYear, prevReportingYear } = getFiscalPeriods(context),
    propertyTypeCS = context?.bblsData.propertyTypeCS,
    buildingClasses = context?.bblsData.buildingClasses,
    showUseByApplicantQuestion = useMemo(
      () => addUseByApplicantQuestion(propertyTypeCS, propertyUsage),
      [propertyTypeCS, propertyUsage]
    ),
    showPortionUsedByApplicantQuestion = useMemo(
      () =>
        addPortionUsedByApplicantQuestion(
          propertyTypeCS,
          propertyUsage,
          useByApplicant
        ),
      [propertyTypeCS, propertyUsage, useByApplicant]
    ),
    showWasAnyRentalIncome = useMemo(
      () =>
        addWasAnyRentalIncomeQuestion(
          propertyUsage,
          useByApplicant,
          propertyTypeCS
        ),
      [propertyUsage, useByApplicant, propertyTypeCS]
    ),
    validationData = useMemo(
      () => ({
        showUseByApplicantQuestion,
        showPortionUsedByApplicantQuestion,
        useByApplicantCS: useByApplicant,
        propertyUsageCS: propertyUsage,
        portionUsedByApplicantCS,
        wasAnyConstruction,
        wasAnyRentalIncome,
        showWasAnyRentalIncome,
      }),
      [
        showUseByApplicantQuestion,
        showPortionUsedByApplicantQuestion,
        useByApplicant,
        propertyUsage,
        portionUsedByApplicantCS,
        wasAnyConstruction,
        wasAnyRentalIncome,
        showWasAnyRentalIncome,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    wasAnyRentalIncomeQuestionTitle = useWasAnyRentalIncomeQuestionTitle(
      buildingClasses,
      reportingYear
    ),
    onWasAnyConstructionChange = useCallback(
      (value, name) => {
        if (!value) {
          getCallback(onChange)(null, 'construction')
        }
        getCallback(onChange)(value, name)
      },
      [onChange]
    )

  useEffect(() => {
    if (!showWasAnyRentalIncome)
      getCallback(onChange)(null, 'wasAnyRentalIncome')
    else if (wasAnyRentalIncome === null)
      getCallback(onChange)(true, 'wasAnyRentalIncome')
  }, [onChange, showWasAnyRentalIncome])

  useEffect(() => {
    if (!showPortionUsedByApplicantQuestion)
      getCallback(onChange)(null, 'portionUsedByApplicantCS')
  }, [onChange, showPortionUsedByApplicantQuestion])

  useEffect(() => {
    if (wasAnyRentalIncome === false && wasIncomeFromRentalTenants !== false)
      getCallback(onChange)(false, 'wasIncomeFromRentalTenants')
  }, [onChange, wasAnyRentalIncome])

  return (
    <StepBasic className={props.className}>
      <VStack spacing={'36px'} w={`690px`} align="flex-start">
        {showUseByApplicantQuestion && (
          <HelpTarget name="useByApplicant">
            <SelectsGroup
              label={`In ${reportingYear}, was any part of this property used by ${applicantName} or a related party?`}
              disabled={disabled}
              error={hasError('useByApplicantCS')}
            >
              <Radiobutton
                value={useByApplicant}
                selected={UseByApplicant.No}
                name="useByApplicant"
                disabled={disabled}
                onChange={onChange}
              >
                No
              </Radiobutton>
              <Radiobutton
                value={useByApplicant}
                selected={UseByApplicant.Part}
                name="useByApplicant"
                disabled={disabled}
                onChange={onChange}
              >
                Yes, part of the property
              </Radiobutton>
              <Radiobutton
                value={useByApplicant}
                selected={UseByApplicant.Entire}
                name="useByApplicant"
                disabled={disabled}
                onChange={onChange}
              >
                Yes, entire property
              </Radiobutton>
            </SelectsGroup>
          </HelpTarget>
        )}

        {showPortionUsedByApplicantQuestion && (
          <SelectsGroup
            label={
              <>
                Which portion of the property was used by {applicantName} or a
                related party in <b>{reportingYear}</b>?
              </>
            }
            error={hasError('portionUsedByApplicantCS')}
          >
            <Radiobutton
              value={portionUsedByApplicantCS}
              selected={PortionUsedByApplicant.Residential}
              name="portionUsedByApplicantCS"
              disabled={disabled}
              onChange={onChange}
            >
              Residential only
            </Radiobutton>
            <Radiobutton
              value={portionUsedByApplicantCS}
              selected={PortionUsedByApplicant.NonResidential}
              name="portionUsedByApplicantCS"
              disabled={disabled}
              onChange={onChange}
            >
              Non-residential only
            </Radiobutton>
            <Radiobutton
              value={portionUsedByApplicantCS}
              selected={PortionUsedByApplicant.Both}
              name="portionUsedByApplicantCS"
              disabled={disabled}
              onChange={onChange}
            >
              Both
            </Radiobutton>
          </SelectsGroup>
        )}

        <HelpTarget name="wasAnyConstruction">
          <SelectsGroup
            label={
              <>
                After <b>January 5, {prevReportingYear}</b>, was the property
                subject to any major construction, demolition or major
                alteration (or have plans for demolition or a new building been
                filed with the Department of Buildings)?
              </>
            }
            error={hasError('wasAnyConstruction')}
          >
            <Radiobutton
              value={wasAnyConstruction}
              selected={false}
              name="wasAnyConstruction"
              disabled={disabled}
              onChange={onWasAnyConstructionChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={wasAnyConstruction}
              selected={true}
              name="wasAnyConstruction"
              disabled={disabled}
              onChange={onWasAnyConstructionChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        {showWasAnyRentalIncome && (
          <SelectsGroup
            label={wasAnyRentalIncomeQuestionTitle}
            disabled={disabled}
            error={hasError('wasAnyRentalIncome')}
          >
            <Radiobutton
              value={wasAnyRentalIncome}
              selected={false}
              name="wasAnyRentalIncome"
              disabled={disabled}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={wasAnyRentalIncome}
              selected={true}
              name="wasAnyRentalIncome"
              disabled={disabled}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepPropertyStatus
