import { ComponentStyleConfig } from '@chakra-ui/theme'

const NotificationBarStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['wrapper', 'slide', 'box', 'texts'],
  // The base styles for each part
  baseStyle: {
    wrapper: {
      position: 'relative',
      height: '20px',
      // set display to none to hide the component for print view
      '@media print': {
        display: 'none',
      },
    },
    slide: {
      position: 'absolute',
      zIndex: 10,
      top: 0,
    },
    box: {
      width: '100%',
      lineHeight: '20px',
      textStyle: 'caption.regular',
      textAlign: 'center',
      a: {
        textStyle: 'caption.regular',
      },
    },
  },
  // The size styles for each part
  sizes: {
    md: {},
  },
  // The variant styles for each part
  variants: {
    error: {
      box: {
        backgroundColor: 'red.base',
      },
    },
    warning: {
      box: {
        backgroundColor: 'orange.opacity',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default NotificationBarStyle
