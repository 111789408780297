import { usePrevious } from '@chakra-ui/hooks'
import { getCallback } from 'helpers/utils'
import useValidation from 'hooks/useValidation'
import { get } from 'object-path-immutable'
import { useEffect, useState } from 'react'

const useStepValidation = (value, schema, applyValidation, onValidation) => {
  const [validationErrors, validationErrorsRaw] = useValidation(value, schema),
    hasErrors = validationErrorsRaw?.details?.length > 0,
    previousHasErrors = usePrevious(hasErrors),
    [emptyValidationErrors] = useState({})

  useEffect(() => {
    if (previousHasErrors !== hasErrors) getCallback(onValidation)(hasErrors)
  }, [validationErrorsRaw, onValidation, previousHasErrors, hasErrors])

  const validation = applyValidation ? validationErrors : emptyValidationErrors

  return path => get(validation, path)
}

export default useStepValidation
