/* eslint-disable no-console */
import React, { useCallback, useState } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import Button from 'ui-framework/components/primitives/Button'
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import firebaseApp from 'helpers/firebaseInit'
import Input from 'ui-framework/components/primitives/Input'
import {
  currency,
  dateFormat,
  integerPositive,
  integerPositiveThousands,
  phone,
} from 'helpers/formats'
import { HStack, VStack } from '@chakra-ui/react'
import { IncomeSectionTypes, RPIEStatuses, Services, SFApplicationStatus, TAStatuses, TAWizardSteps, UseByApplicant } from 'constants/dicti'
import { Application } from 'hooks/api/firebase/useApplication'
import { RpieFormData } from 'hooks/api/rpie/RpieFormData'
import { IncomeSection, TaFormData } from 'hooks/api/ta/TaFormData'
import { Income } from 'hooks/api/rpie/RpieFormData'
import { log } from 'helpers/utils'

const db = getFirestore(firebaseApp)

//===================================================
type Props = {} & BaseComponentProps

/**
 * TestPage component
 */

const TestPage = () => {
  const [documents, documentsSet] = useState<DocumentData[]>([])

  // query all application with not validated fields of residential Rent Roll
  const handleFindApplicationOfUser = useCallback(() => {
    // return;
    const flagForFloor = {
      basement: ['wereAnyVacanciesBasement', TAWizardSteps.DetailsNonResidentialBasement],
      firstFloor: ['wereAnyVacanciesFirstFloor', TAWizardSteps.DetailsNonResidentialFirst],
      secondFloor: ['wereAnyVacanciesSecondFloor', TAWizardSteps.DetailsNonResidentialSecond],
      thirdAndAboveFloors: ['wereAnyVacanciesThirdAndAboveFloors', TAWizardSteps.DetailsNonResidentialThird],
    }

    log('handleFindApplicationOfUser')
    const allpCollection = collection(db, 'applications')
    const allpDoc = query(
      allpCollection,
      where('serviceCS', '==', Services.TaxAppeal),
      where('fiscalYear', '==', 2024),
      where('statusCS', 'in', [TAStatuses.Imported, TAStatuses.Submit]),
      where('serviceData.general.wereAnySubstantialVacancies', '==', false),
    )

    const result: DocumentData[] = []

    getDocs(allpDoc).then(querySnapshot => {
      for (const doc of querySnapshot.docs as any) {
        const data = { id: doc.id, ...doc.data() } as Application<TaFormData>

        log(data.id, data.statusCS, data.serviceMetaData.currentStepId)

        if (//data?.serviceData?.general.useByApplicantCS === UseByApplicant.No &&
          [
            "basement",
            "firstFloor",
            "secondFloor",
            "thirdAndAboveFloors",
          ].some((floorId) => {
            const [vac, stepId] = flagForFloor[floorId]
            return data?.serviceData?.uses.usesTables[vac] === false
              && data?.serviceData?.uses.usesTables[floorId].some(use => use.rentedSF === null)
              && data?.serviceMetaData?.steps.some((sect) =>
                sect?.stepsIds?.includes(stepId))
          }))
          result.push(data)

        // if (data.serviceMetaData.steps.some((sect) =>
        //   sect?.stepsIds?.includes(data.serviceMetaData.currentStepId))) continue


        // updateDoc(doc(db, 'applications', data.id), {
        //   serviceData: data.defaultServiceData,
        //   statusCS: RPIEStatuses.Imported,
        // })

      }
    })

    log('fin')
    documentsSet(result)
  }, [])



  return (
    <VStack spacing={5}>
      <Button onClick={handleFindApplicationOfUser}>get</Button>
      <div>
        {documents.length} documents
      </div>
      <div>
        {documents.map((doc, index) => (
          <HStack key={index}>
            <div>{doc.id}</div>
            <div>{doc.statusCS}</div>
            <div>
              {/* <Button onClick={()=>updateDocument(doc)}>update</Button> */}
            </div>

          </HStack>
        ))}
      </div>
    </VStack>
  )
}

export default TestPage
