import React, { useCallback, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import IntervalBar from 'ui-framework/components/patterns/IntervalBar'
import Input from 'ui-framework/components/primitives/Input'
import {
  getCallback,
  getFiscalPeriods,
  getNameAndErrorProps,
} from 'helpers/utils'
import { push, set } from 'object-path-immutable'
import dayjs from 'dayjs'
import {
  Construction,
  OccupancyPeriod,
  Occupant,
} from 'hooks/api/rpie/RpieFormData'
import Icon from 'ui-framework/components/primitives/Icon'
import Table from 'ui-framework/components/patterns/Table'
import Button from 'ui-framework/components/primitives/Button'
import { TableHeader } from 'ui-framework/components/patterns/Table/TableHeaders'
import { VStack } from '@chakra-ui/layout'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getDictiName } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import DateInput from 'ui-framework/components/primitives/Calendar/DateInput'
import { apiDateFormat } from 'helpers/formats'
import { nanoid } from 'nanoid'
import StorefrontUnitOccupantsIntervalBar from 'pages/Services/Rpie/RpieFormPage/steps/storefront/StorefrontUnitOccupantsIntervalBar'

//===================================================
function convertDate(ochh) {
  return (v, n) => ochh(dayjs(v).format(apiDateFormat), n)
}

type Props = {
  unitNumber: number
  occupantNumber: number
  hadConstruction: boolean
  constructionJobs: Construction[]
  _occStartDate: string
  _occEndDate: string
  _occLabel: string
  occupants: Occupant[]
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepStorefrontUnitOccupantDOBJobsStep component
 */
const StepStorefrontUnitOccupantDOBJobsStep = ({
  unitNumber,
  occupantNumber,
  hadConstruction,
  constructionJobs,
  _occStartDate,
  _occEndDate,
  _occLabel,
  occupants,

  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context),
    validationData = useMemo(
      () => ({ constructionJobs, hadConstruction }),
      [constructionJobs, hadConstruction]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  const handleAdd = useCallback(() => {
      getCallback(onChange)(
        push({ constructionJobs }, ['constructionJobs'], {
          $id: nanoid(),
          jobNumber: null,
          projectStartDate: null,
          projectEndDate: null,
        })
      )
    }, [constructionJobs, onChange]),
    handleOnChange = useCallback(
      index => (value, name) =>
        getCallback(onChange)(value, `constructionJobs.${index}.${name}`),
      [onChange]
    ),
    handleOnDelete = useCallback(
      jobNumber => {
        getCallback(onChange)(
          set(
            { constructionJobs },
            'constructionJobs',
            constructionJobs.filter(j => jobNumber !== j.jobNumber)
          )
        )
      },
      [constructionJobs, onChange]
    )

  useEffect(() => {
    if (constructionJobs.length === 0) handleAdd()
  }, [constructionJobs.length, handleAdd])

  return (
    <StepBasic className={props.className}>
      <VStack w="680px" align="start" spacing="36px">
        <StorefrontUnitOccupantsIntervalBar
          occupants={occupants}
          occupantNumber={occupantNumber}
          reportingYear={reportingYear}
        />

        <Table
          items={constructionJobs}
          headers={
            [
              { text: 'Job Number', width: '30%' },
              { text: 'Start Date', width: '33%' },
              { text: 'End Date', width: '33%' },
              { text: '', width: '3%' },
            ] as TableHeader[]
          }
          footer={
            <tr>
              <td colSpan={3}>
                <Button variant="textPrimary" onClick={handleAdd}>
                  Add new job
                </Button>
              </td>
            </tr>
          }
        >
          {(j: Construction, i) => {
            const nameAndErrorProps = name =>
              getNameAndErrorProps(name, hasError, `constructionJobs.${i}`)

            return (
              <tr key={(j as any).$id || i}>
                <td>
                  <HelpTarget name="jobNumber">
                    <Input
                      size="sm"
                      // width="280px"
                      type="text"
                      value={j.jobNumber}
                      errorTooltip
                      placeholder="Enter # of DOB Job"
                      disabled={disabled}
                      onChange={handleOnChange(i)}
                      {...nameAndErrorProps('jobNumber')}
                    />
                  </HelpTarget>
                </td>
                <td>
                  <HelpTarget name="projectStartDate">
                    <DateInput
                      size={`sm`}
                      value={
                        j.projectStartDate
                          ? dayjs(j.projectStartDate).toDate()
                          : null
                      }
                      // max={new Date(reportingYear, 0, 5)}
                      disabled={disabled}
                      onChange={convertDate(handleOnChange(i))}
                      width={'200px'}
                      min={new Date(reportingYear, 0, 1)}
                      max={new Date(reportingYear, 11, 31)}
                      placeholder={'Select'}
                      errorTooltip
                      {...nameAndErrorProps('projectStartDate')}
                    />
                  </HelpTarget>
                </td>
                <td>
                  <HelpTarget name="projectEndDate">
                    <DateInput
                      size={`sm`}
                      value={
                        j.projectEndDate
                          ? dayjs(j.projectEndDate).toDate()
                          : null
                      }
                      // min={dayjs(startDate || startOfYear)
                      //   .add(6, 'months')
                      //   .toDate()}
                      placeholder={'Select'}
                      disabled={disabled}
                      onChange={convertDate(handleOnChange(i))}
                      width={'200px'}
                      errorTooltip
                      min={new Date(reportingYear, 0, 1)}
                      max={new Date(reportingYear, 11, 31)}
                      {...nameAndErrorProps('projectEndDate')}
                    />
                  </HelpTarget>
                </td>
                <td>
                  <Icon
                    size="sm"
                    color="fontandicongray"
                    onClick={() => handleOnDelete(j.jobNumber)}
                  >
                    close
                  </Icon>
                </td>
              </tr>
            )
          }}
        </Table>
      </VStack>
    </StepBasic>
  )
}

export default StepStorefrontUnitOccupantDOBJobsStep
