import { ComponentStyleConfig } from '@chakra-ui/theme'

const MainItemStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['title'],
  // Styles for the base style
  baseStyle: {
    title: {
      fontFamily: 'body',
      color: 'fontandicongray',
      fontWeight: 'regular',
      textTransform: 'capitalize',
    },
  },
  // Styles for the size variations
  sizes: {
    md: {
      title: {
        fontSize: 'body',
        lineHeight: 'body',
        margin: '31px 0 8px',
      },
    },
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default MainItemStyle
