import dayjs from 'dayjs'
import {
  FloorUse,
  IncomeItem,
  TaFormData,
  UsesTables,
} from 'hooks/api/ta/TaFormData'
import { apiDateFormat } from 'helpers/formats'

import {
  IncomeCategories,
  PortionUsedByApplicant,
  IncomeSectionTypes,
  PropertyUsage,
  UseByApplicant,
  TAWizardSteps,
  ReportingPeriodType,
  AccountingBasis,
  getDictiName,
  FloorUsesType,
  PropertyType,
} from 'constants/dicti'
import { push, set, wrap } from 'object-path-immutable'
import { getIESectionIndexByType } from 'pages/Services/TA/TAFormPage/wizard/taWizardConfig'
import { compose } from 'lodash/fp'
import useFormDataHook, {
  addPortionUsedByApplicantQuestion,
  addWasAnyRentalIncomeQuestion,
  autoFillingW,
  conditionalSetOrRestore,
  FormDataHookChangeHandlers,
  isResOrNonRes,
  ServiceFormDataHook,
  FormDataHookChangeHandler,
} from '../common/useFormDataHook'
import { uniq } from 'lodash-es'

export const emptyRoomRateItem = {
  roomTypeCS: '',
  numberOfeach: null,
  singleRate: null,
  doubleRate: null,
}

export const floorsKeys = [
  'basement',
  'firstFloor',
  'secondFloor',
  'thirdAndAboveFloors',
]

const handleSetFirstStep: FormDataHookChangeHandler<TaFormData> = value => {
  if (!value.currentStepId) {
    return set(value, 'currentStepId', TAWizardSteps.DescriptionMainInfo)
  }
  return value
}

const handleReportingPeriodDefaultValues: (
  TaFormData
) => FormDataHookChangeHandler<TaFormData> = currentProperty => v => {
  const value = wrap(v)

  if (!v.income.reportingPeriod.reportingPeriodTypeCS)
    value.set(
      'income.reportingPeriod.reportingPeriodTypeCS',
      ReportingPeriodType.Calendar
    )
  if (!v.income.reportingPeriod.accountingBasisCS)
    value.set('income.reportingPeriod.accountingBasisCS', AccountingBasis.Cash)
  if (!v.income.reportingPeriod.startDate)
    value.set(
      'income.reportingPeriod.startDate',
      dayjs(new Date((currentProperty?.fiscalYear || 0) - 1, 1, 1))
        .startOf('year')
        .format(apiDateFormat)
    )

  if (!v.income.reportingPeriod.endDate)
    value.set(
      'income.reportingPeriod.endDate',
      dayjs(new Date((currentProperty?.fiscalYear || 0) - 1, 1, 1))
        .endOf('year')
        .format(apiDateFormat)
    )
  if (!v.income.reportingPeriod?.hasCustomPeriod)
    value.set('income.reportingPeriod.hasCustomPeriod', false)

  return value.value()
}

const handleAddServiceFields: FormDataHookChangeHandler<TaFormData> = v => {
  const value = wrap(v)
  value.set('uses.residentialInfo.$checkTotalNumberOfResidUnits', 1)

  if (!v.hasOwnProperty('$certified')) {
    value.set('$certified', false)
  }

  floorsKeys.forEach(f => {
    v.uses.usesTables[f].forEach((u, i) => {
      value.set(['uses', 'usesTables', f, i, '$checkTotalUsePercent'], i)
    })
  })

  return value.value()
}

const handleUseByApplicantChanges: (
  any
) => FormDataHookChangeHandler<TaFormData> = currentProperty => v => {
  const value = wrap(v),
    droppedOrRestoreValue = conditionalSetOrRestore(
      currentProperty?.defaultServiceData,
      value
    )

  droppedOrRestoreValue(
    v,
    !addPortionUsedByApplicantQuestion(
      currentProperty?.bblsData.propertyTypeCS,
      v.general.propertyUsageCS,
      v.general.useByApplicantCS
    ),
    'general.portionUsedByApplicantCS'
  )
  droppedOrRestoreValue(
    v,
    !addWasAnyRentalIncomeQuestion(
      v.general.propertyUsageCS,
      v.general.useByApplicantCS,
      currentProperty?.bblsData.propertyTypeCS
    ),
    'general.wasAnyRentalIncome'
  )

  if (
    v.general.useByApplicantCS === UseByApplicant.No ||
    v.general.portionUsedByApplicantCS === PortionUsedByApplicant.NonResidential
  ) {
    value
      .set('uses.residentialInfo.ownerOccupied.numberTotalUnits', null)
      .set('uses.residentialInfo.ownerOccupied.ownerFloors', null)
  }

  const setUseSF = field => value => use => {
    use[field] = value
    return use
  },
    setApplicantUse = setUseSF('applicantSF'),
    setApplicantUseToZero = setApplicantUse(0),
    setApplicantUseToFull = use => setApplicantUse(use.squareFootage)(use),
    setRentedUseToNull = setUseSF('rentedSF')(null),
    setVacantUseToNull = setUseSF('vacantSF')(null),
    setOnlyApplicantUseToFull = compose(
      setApplicantUseToFull,
      setRentedUseToNull,
      setVacantUseToNull
    ),
    processEachUseForEachFloor = fn => {
      floorsKeys.forEach(f => v.uses.usesTables[f].forEach(fn))
      value.set('uses.usesTables', v.uses.usesTables)
    }

  if (
    v.general.useByApplicantCS !== UseByApplicant.Part ||
    v.general.portionUsedByApplicantCS === PortionUsedByApplicant.Residential ||
    v.general.propertyUsageCS === PropertyUsage.Residential
  ) {
    processEachUseForEachFloor(setApplicantUseToZero)
  }

  if (
    v.general.useByApplicantCS === UseByApplicant.Entire &&
    (v.general.propertyUsageCS === PropertyUsage.NonResidential ||
      v.general.propertyUsageCS === PropertyUsage.Mixed)
  ) {
    processEachUseForEachFloor(setOnlyApplicantUseToFull)
  }

  return value.value()
}

//TODO: decide whether we need this function
// const handlePropertyUsageChange: (
//   //http://dev.mgnyconsulting.com:8081/redmine/issues/12661
//   TaFormData
// ) => FormDataHookChangeHandler<TaFormData> = defaults => v => {
//   const value = wrap(v),
//     droppedOrRestoreValue = conditionalSetOrRestore(defaults, value)

//   droppedOrRestoreValue(
//     v,
//     v.general.propertyUsageCS === PropertyUsage.Vacant,
//     'general.wasAnyRentalIncome'
//   )
//   return value.value()
// }

const handleHotelChange: FormDataHookChangeHandler<TaFormData> = v => {
  if (!v.hotel.roomRates.length) {
    return push(v, 'hotel.roomRates', emptyRoomRateItem)
  }
  if (
    !v?.hotel?.haveProprietaryRights &&
    v?.hotel?.haveProprietaryRightsDescription
  ) {
    return set(v, 'hotel.haveProprietaryRightsDescription', null)
  }
  if (
    !v?.hotel?.isAnySpaceRelatedToOperator &&
    v?.hotel?.anySpaceRelatedToOperatorDescription
  ) {
    return set(v, 'hotel.anySpaceRelatedToOperatorDescription', null)
  }

  return v
}

const handleFillIsWereAnySubstantialVacancies: (
  TaFormData
) => FormDataHookChangeHandler<TaFormData> = defaults => v => {
  const value = wrap(v),
    droppedOrRestoreValue = conditionalSetOrRestore(defaults, value)

    ;[
      'general.covid.isCovid',
      'general.whatPartsWereAffected',
      'general.whatTriggeredLowCollection',
      'general.howMuchIncomeWasLost',
    ].forEach(p => {
      droppedOrRestoreValue(v, !v.general.wereAnySubstantialVacancies, p)
    })

  droppedOrRestoreValue(
    v,
    (isResOrNonRes(v.general.propertyUsageCS) &&
      v.general.wereAnySubstantialVacancies) ||
    !v.general.wereAnySubstantialVacancies,
    'general.portionVacantTroubleTenantCS'
  )
    ;[
      'wereAnyVacanciesBasement',
      'wereAnyVacanciesFirstFloor',
      'wereAnyVacanciesSecondFloor',
      'wereAnyVacanciesThirdAndAboveFloors',
    ].forEach(field =>
      value.set(['uses', 'usesTables', field], Boolean(v.uses.usesTables[field]))
    )

  return value.value()
}

const handleMainIncomeCategories: FormDataHookChangeHandler<TaFormData> = v => {
  const value = wrap(v)

  const filterUses =
    ffn =>
      (values: FloorUse[]): FloorUse[] =>
        values.filter(ffn),
    filterUsesWithIncomeCategories = filterUses(
      c => !!c.incomeCategoryCS && c.incomeCategoryCS !== IncomeCategories.Other
    ),
    filterUsesWithOutIncomeCategories = filterUses(
      c => c.incomeCategoryCS === IncomeCategories.Other
    ),
    filterIncomeCategories = (values: FloorUse[]): IncomeCategories[] =>
      filterUsesWithIncomeCategories(values).map(
        c => c.incomeCategoryCS
      ) as IncomeCategories[],
    getRegulatedCategories = () => {
      const regUnregCategories: Array<IncomeCategories> = []
      if (
        v.uses.residentialInfo.residentialUnits.regulatedUnits.numberOfUnits >
        0 ||
        v.uses.showUploadArea
      )
        regUnregCategories.push(IncomeCategories.ResRegulated)

      if (
        v.uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfUnits >
        0 ||
        v.uses.showUploadArea
      )
        regUnregCategories.push(IncomeCategories.ResUnregulated)

      return regUnregCategories
    },
    getCategoriesToShow = (usesTables: UsesTables) =>
      getRegulatedCategories().concat(
        filterIncomeCategories(usesTables.firstFloor),
        filterIncomeCategories(usesTables.secondFloor),
        filterIncomeCategories(usesTables.thirdAndAboveFloors),
        filterIncomeCategories(usesTables.basement)
      ),
    getItemsToShow = categoriesToShow => (items?: IncomeItem[]) =>
      items
        ? items.map(i => {
          i.$show = categoriesToShow.includes(i.categoryCS)
          return i
        })
        : []

  const mainSectionIndex = getIESectionIndexByType(
    v.income.sections,
    IncomeSectionTypes.IncomeMain
  ),
    otherSectionIndex = [
      IncomeSectionTypes.IncomeOther,
      IncomeSectionTypes.CondoOther,
      IncomeSectionTypes.HotelOther,
    ].reduce((acc, st) => {
      if (acc === -1) acc = getIESectionIndexByType(v.income.sections, st)
      return acc
    }, -1)

  // get uniq uses with income category Other
  const getUsesWithoutIncomeCategory = () =>
    uniq(
      ([] as FloorUse[])
        .concat(
          filterUsesWithOutIncomeCategories(v.uses.usesTables.firstFloor)
        )
        .concat(
          filterUsesWithOutIncomeCategories(v.uses.usesTables.secondFloor)
        )
        .concat(
          filterUsesWithOutIncomeCategories(
            v.uses.usesTables.thirdAndAboveFloors
          )
        )
        .concat(filterUsesWithOutIncomeCategories(v.uses.usesTables.basement))
        .map(u => u.usesTypeCS)
    ),
    createCustomIncomeRecords = () =>
      getUsesWithoutIncomeCategory()
        // Don't add Hotel/Motel for hotels
        .filter(
          u =>
            !(
              v.income.sections[otherSectionIndex].typeCS ===
              IncomeSectionTypes.HotelOther && u === FloorUsesType.Hotel
            )
        )
        .map(u => ({
          $otherUse: true,
          categoryName: getDictiName(u),
          prevYearAmount: null,
          lastYearAmount: null,
        }))

  //TODO: move this logic to defaultServiceData filled on backend
  if (!v.income.$otherUsesProcessed && otherSectionIndex !== -1) {
    const records = createCustomIncomeRecords()
    if (records.length) {
      // IncomeCategoryCustom
      value.set(
        ['income', 'sections', otherSectionIndex, 'items'],
        createCustomIncomeRecords()
      )
    }
    value.set(['income', '$otherUsesProcessed'], true)
  }

  if (mainSectionIndex !== -1)
    value.set(
      ['income', 'sections', mainSectionIndex, 'items'],
      getItemsToShow(getCategoriesToShow(v.uses.usesTables))(
        v.income.sections[mainSectionIndex].items
      )
    )

  return value.value()
}

const handleIncomeRegulatedUnregulated: FormDataHookChangeHandler<
  TaFormData
> = (v, prevState) => {
  // NOTE: rewrite set lastYearAmount of reg & unreg categories
  const value = wrap(v),
    ru = v.uses.residentialInfo.residentialUnits,
    incomeMain = v.income.sections.find(
      s => s.typeCS === IncomeSectionTypes.IncomeMain
    ),
    regCat = incomeMain?.items.find(
      c => c.categoryCS === IncomeCategories.ResRegulated
    ),
    uregCat = incomeMain?.items.find(
      c => c.categoryCS === IncomeCategories.ResUnregulated
    ),
    globalNeeded = v.uses.showUploadArea && ru.numberOfResidentialUnit > 0

  if (regCat && !(globalNeeded || ru.regulatedUnits.numberOfUnits > 0)) {
    regCat.lastYearAmount = null
  }

  if (uregCat && !(globalNeeded || ru.unRegulatedUnits.numberOfUnits > 0)) {
    uregCat.lastYearAmount = null
  }

  const field = r => f => ['uses', 'residentialInfo', 'residentialUnits', r, f],
    fieldR = field('regulatedUnits'),
    fieldUr = field('unRegulatedUnits')

  if (ru.regulatedUnits.numberOfVacantUnits === 0) {
    value.set(fieldR('monthlyRentLossDueVacant'), null)
  }

  if (ru.regulatedUnits.numberOfRentedUnits === 0) {
    value.set(fieldR('monthlyRent'), null)
  }

  if (ru.unRegulatedUnits.numberOfVacantUnits === 0) {
    value.set(fieldUr('monthlyRentLossDueVacant'), null)
  }

  if (ru.unRegulatedUnits.numberOfRentedUnits === 0) {
    value.set(fieldUr('monthlyRent'), null)
  }

  return value.value()
}

const handleResidentialUses: FormDataHookChangeHandler<TaFormData> = (
  v,
  prevState
) => {
  const value = wrap(v),
    { unRegulatedUnits, regulatedUnits, wereAnyUnitsVacant } =
      v.uses?.residentialInfo?.residentialUnits

  if (!wereAnyUnitsVacant)
    value
      .set(
        'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfRentedUnits',
        unRegulatedUnits?.numberOfUnits
      )
      .set(
        'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfRentedUnits',
        regulatedUnits?.numberOfUnits
      )

  autoFillingW(
    value,
    prevState,
    'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfUnits',
    [
      'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfVacantUnits',
      'uses.residentialInfo.residentialUnits.regulatedUnits.numberOfRentedUnits',
    ]
  )

  autoFillingW(
    value,
    prevState,
    'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfUnits',
    [
      'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfVacantUnits',
      'uses.residentialInfo.residentialUnits.unRegulatedUnits.numberOfRentedUnits',
    ]
  )

  return value.value()
}

// const handleNonResUsesPercents: (
//   currentProperty
// ) => FormDataHookChangeHandler<TaFormData> =
//   currentProperty => (v, prevState) => {
//     if (
//       [PropertyType.Hotel, PropertyType.CondoRelation7].includes(
//         currentProperty.bblsData.propertyTypeCS
//       ) &&
//       v.general.useByApplicantCS === UseByApplicant.Part
//     ) {
//       return v
//     }

//     const value = wrap(v),
//       setSF = (vacantFlag, floorKey) => (u: FloorUse, i) => {
//         const isApplicantSFShown =
//           v.general.propertyUsageCS !== PropertyUsage.Residential &&
//           v.general.useByApplicantCS === UseByApplicant.Part &&
//           v.general.portionUsedByApplicantCS !==
//             PortionUsedByApplicant.Residential
//         const areThereVacant = v?.uses?.usesTables[vacantFlag]
//         const field = f => ['uses', 'usesTables', floorKey, i, f]

//         if (v.general.useByApplicantCS === UseByApplicant.Entire) {
//           value
//             .set(field('applicantSF'), u.squareFootage)
//             .set(field('rentedSF'), 0)
//             .set(field('vacantSF'), 0)
//         } else {
//           if (!areThereVacant) {
//             value.set(field('vacantSF'), 0)
//             if (!isApplicantSFShown) {
//               value.set(field('rentedSF'), u.squareFootage)
//             }
//             autoFillingW(value, prevState, field('squareFootage'), [
//               field('rentedSF'),
//               field('applicantSF'),
//             ])
//           } else if (!isApplicantSFShown)
//             autoFillingW(value, prevState, field('squareFootage'), [
//               field('rentedSF'),
//               field('vacantSF'),
//             ])
//         }
//       }

//     v.uses.usesTables.basement.forEach(
//       setSF('wereAnyVacanciesBasement', 'basement')
//     )
//     v.uses.usesTables.firstFloor.forEach(
//       setSF('wereAnyVacanciesFirstFloor', 'firstFloor')
//     )
//     v.uses.usesTables.secondFloor.forEach(
//       setSF('wereAnyVacanciesSecondFloor', 'secondFloor')
//     )
//     v.uses.usesTables.thirdAndAboveFloors.forEach(
//       setSF('wereAnyVacanciesThirdAndAboveFloors', 'thirdAndAboveFloors')
//     )

//     return value.value()
//   }

const handleParking: FormDataHookChangeHandler<TaFormData> = v => {
  const value = wrap(v)

  if (v.general.parking.indoorParkingUses.totalNumberOfParkingSpots === null)
    value.set('general.parking.indoorParkingUses.totalNumberOfParkingSpots', 0)

  if (v.general.parking.indoorParkingUses.numberOfPaidParkingSpots === null)
    value.set('general.parking.indoorParkingUses.numberOfPaidParkingSpots', 0)

  if (v.general.parking.outdoorParkingUses.totalNumberOfParkingSpots === null)
    value.set('general.parking.outdoorParkingUses.totalNumberOfParkingSpots', 0)

  if (v.general.parking.outdoorParkingUses.numberOfPaidParkingSpots === null)
    value.set('general.parking.outdoorParkingUses.numberOfPaidParkingSpots', 0)

  return value.value()
}


// TODO: add tests to useTaFormDataHook
const useTaFormDataHook: ServiceFormDataHook<TaFormData> = (
  valueProp,
  currentProperty
) => {
  const handlers: FormDataHookChangeHandlers<TaFormData> = [
    handleSetFirstStep,
    handleReportingPeriodDefaultValues(currentProperty),
    handleAddServiceFields,
    handleUseByApplicantChanges(currentProperty),
    // handlePropertyUsageChange(currentProperty?.defaultServiceData),
    handleHotelChange,
    handleFillIsWereAnySubstantialVacancies(
      currentProperty?.defaultServiceData
    ),
    handleMainIncomeCategories,
    handleIncomeRegulatedUnregulated,
    handleResidentialUses,
    // handleNonResUsesPercents(currentProperty),
    handleParking
  ]

  return useFormDataHook(valueProp, handlers)
}

export default useTaFormDataHook
