import React, { ReactNode } from 'react'
import { Config } from 'helpers/config/utils'
import { NBAR_TYPE } from 'ui-framework/components/patterns/NotificationBar'

export type NBAR = {
  type: NBAR_TYPE
  message: string | ReactNode
} | null

export interface IApplicationContext {
  config: Config
  effectiveUserEmail?: string
  nbar: {
    set: (value?: NBAR) => void
    reset: () => void
    nbar?: NBAR
  }
  update: {
    hasUpdate: boolean | 'force'
    update: () => void
  }
  // openChat: () => void
}

const ApplicationContext = React.createContext<IApplicationContext | undefined>(
  undefined
)

export { ApplicationContext }
