import React, { ReactNode, useMemo, memo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Table from 'ui-framework/components/patterns/Table'
import { chakra } from '@chakra-ui/react'
import {
  createFakeElement,
  isCallback,
  isNullOrWhiteSpace,
} from 'helpers/utils'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { get } from 'object-path-immutable'
import Empty from 'ui-framework/components/primitives/Empty'
import { getDictiName, FloorUsesType } from 'constants/dicti'
import isEqual from 'lodash/isEqual'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { formatToNumberITS } from 'helpers/formatter'

type Props = {
  staticData: TaFormData
  headerColor?: string
} & BaseComponentProps

type Params = {
  resUses: any[]
  nonResUses: any[]
  resUsesWidth: number
  nonResUsesWidth: number
}

const sfParams: Params = {
  resUses: [],
  nonResUses: [],
  resUsesWidth: 0,
  nonResUsesWidth: 0,
}

const getMax = data => {
  const max = Math.max(...data)
  return isFinite(max) ? max : 0
}

const createTitle =
  (text: string, maxDisplayingFloor: number) => (value: any) =>
    `${text}${
      value > maxDisplayingFloor
        ? `s ${maxDisplayingFloor} - ${value}`
        : ` ${maxDisplayingFloor}`
    }`

const dataObjects = [
  {
    path: 'uses.usesTables.thirdAndAboveFloors',
    title: createTitle('Floor', 3),
    totalSfPath: 'uses.usesTables.grossFloorArea.areaFloor3AndAboove',
    postfix: 'sf',
  },
  {
    path: 'uses.usesTables.secondFloor',
    title: 'Floor 2',
    totalSfPath: 'uses.usesTables.grossFloorArea.areaFloor2',
    postfix: 'sf',
  },
  {
    path: 'uses.usesTables.firstFloor',
    title: 'Floor 1',
    totalSfPath: 'uses.usesTables.grossFloorArea.areaFloor1',
    postfix: 'sf',
  },
  {
    path: 'uses.usesTables.basement',
    title: 'Basement',
    totalSfPath: 'uses.usesTables.grossFloorArea.areaBasement',
    postfix: 'sf',
  },
  {
    path: 'uses.usesTables.outdoorSpace.outdoorUses',
    title: 'Outdoors',
    totalSfPath: null,
    postfix: null,
  },
]

//TODO review logic and refactor if it needed
const UsesAndFloorAreasTable = ({
  staticData,
  headerColor = '#F9FAFF',
}: Props) => {
  const numberOfFloorAboveGrade = get(
      staticData,
      'general.propertyDescription.numberOfFloorAboveGrade'
    ),
    basementTotalSF = get(staticData, 'uses.usesTables.basement').reduce(
      (acc, u) => acc + u.squareFootage,
      0
    ),
    totalGross = get(staticData, 'uses.usesTables.grossFloorArea.areaTotal'),
    baseText = {
      h: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    headers = [
      {
        width: '54px',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              ...baseText,
            }}
          >
            Floor Level
          </TextFromParams>
        ),
        width: '10%',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              ...baseText,
            }}
          >
            Residential (Area and Uses)
          </TextFromParams>
        ),
        width: '35%',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              ...baseText,
            }}
          >
            Non-Residential (Area and Uses)
          </TextFromParams>
        ),
        width: '35%',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              justifyContent: 'flex-end',
              ...baseText,
            }}
          >
            Total Gross Floor Area
          </TextFromParams>
        ),
        width: '20%',
        bgColor: headerColor,
      },
      {
        width: '25px',
        bgColor: headerColor,
      },
    ]

  const getDisplayValue = (
    value,
    empty: any = <Empty />,
    isTotal?: boolean,
    isRes?: boolean,
    postfix?: string | ReactNode
  ) => {
    if (isNullOrWhiteSpace(isTotal)) {
      return value || empty
    }

    return value ? (
      <TextFromParams
        params={
          isTotal
            ? {
                textStyle: 'body.semibold',
                textAlign: 'right',
              }
            : { textAlign: 'left' }
        }
      >
        {isTotal ? (
          `${formatToNumberITS(value)} ${postfix}`
        ) : (
          <>
            <span
              style={{
                display: 'inline-block',
                width: `${
                  isRes ? sfParams.resUsesWidth : sfParams.nonResUsesWidth
                }px`,
                whiteSpace: 'nowrap',
              }}
            >
              <b>
                {formatToNumberITS(value.squareFootage)} {postfix}
              </b>
            </span>
            &nbsp; &nbsp;
            <Empty type="hyphen" />
            &nbsp; &nbsp;
            {getDictiName(value.usesTypeCS)}
          </>
        )}
      </TextFromParams>
    ) : (
      empty
    )
  }

  const groupUses = floor => {
    const getMaxSf = key => {
      sfParams[key].push(getMax(groupedUses[key].map(use => use.squareFootage)))
    }

    const groupedUses = floor.reduce(
      (groups, use) =>
        use.useCategoryCS === FloorUsesType.Resident
          ? (groups.resUses.push(use), groups)
          : (groups.nonResUses.push(use), groups),
      { resUses: [], nonResUses: [] }
    )

    getMaxSf('resUses')
    getMaxSf('nonResUses')

    return groupedUses
  }

  const items = useMemo(() => {
    const fakeElement = createFakeElement('sf', 'b')

    const recordWidth = key => {
      const v = getMax(sfParams[key])
      if (v) {
        fakeElement.textContent = `${v} sf`
        sfParams[`${key}Width`] = fakeElement.offsetWidth
      }
    }
    const preparedItems = dataObjects.reduce((acc, obj) => {
      const { title, totalSfPath, postfix, path } = obj,
        value = get(staticData, path) || [],
        { resUses, nonResUses } = groupUses(getDisplayValue(value))

      return (
        acc.push({
          resUses,
          nonResUses,
          isResGreater: resUses.length > nonResUses.length,
          postfix: postfix,
          title: isCallback(title)
            ? (title as any)(numberOfFloorAboveGrade)
            : title,
          totalSf: totalSfPath && getDisplayValue(get(staticData, totalSfPath)),
        }),
        acc
      )
    }, [] as any[])

    recordWidth('resUses')
    recordWidth('nonResUses')

    fakeElement.remove()

    return preparedItems
  }, [staticData, numberOfFloorAboveGrade])

  return (
    <Table
      rowsHeadingHeight="56px"
      rowsHeight="56px"
      headers={headers}
      footer={
        totalGross && (
          <tr>
            <CellWithoutBorder />
            <td></td>
            <td></td>
            <chakra.td textStyle="body.semibold">
              Total Gross Floor Area Above Ground
            </chakra.td>
            <td>
              {getDisplayValue(
                totalGross - basementTotalSF,
                undefined,
                true,
                false,
                'sf'
              )}
            </td>
            <CellWithoutBorder />
          </tr>
        )
      }
    >
      <tbody>
        {items.map(floor =>
          (floor.isResGreater ? floor.resUses : floor.nonResUses).map(
            (use, i, arr) => {
              const showBorder = i === arr.length - 1 ? '' : 'none'

              return (
                <tr key={use.usesTypeCS}>
                  <CellWithoutBorder />
                  <td style={{ border: showBorder }}>
                    {i === 0 && floor.title}
                  </td>

                  <td style={{ border: showBorder }}>
                    {getDisplayValue(
                      get(floor.resUses, i),
                      <Empty type="hyphen" />,
                      false,
                      true,
                      floor.postfix
                    )}
                  </td>

                  <td style={{ border: showBorder }}>
                    {getDisplayValue(
                      get(floor.nonResUses, i),
                      <Empty type="hyphen" />,
                      false,
                      false,
                      floor.postfix
                    )}
                  </td>

                  <td style={{ border: showBorder }}>
                    {i === 0 && floor.totalSf && (
                      <TextFromParams
                        params={{
                          textStyle: 'body.semibold',
                          textAlign: 'right',
                        }}
                      >
                        {formatToNumberITS(floor.totalSf)} {floor.postfix}
                      </TextFromParams>
                    )}
                  </td>
                  <CellWithoutBorder />
                </tr>
              )
            }
          )
        )}
      </tbody>
    </Table>
  )
}

export default memo(UsesAndFloorAreasTable, isEqual)
