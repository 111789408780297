import React from 'react'
import { useMultiStyleConfig, Box, Flex } from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'

type EmptyMessageProps = {
  title?: string
  message?: string
  margin?: string
} & ChakraComponentProps

const EmptyMessage = (props: EmptyMessageProps) => {
  const style = useMultiStyleConfig('MGNYEmptyMessage', props),
    { title, message } = props

  return (
    <Flex sx={style.wrapper}>
      {title && <Box sx={style.title}>{title}</Box>}
      {message && <Box sx={style.message}>{message}</Box>}
    </Flex>
  )
}

export default EmptyMessage
