import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import {
  UseByApplicant,
  PortionUsedByApplicant,
  PropertyUsage,
} from 'constants/dicti'
import { FloorUse } from 'hooks/api/ta/TaFormData'
import { atLeastOneGreaterZero } from 'services/common/validationHelpers'

const sfValidation = (field: string, text: string) =>
    schemaRules.NOT_EMPTY_NUMBER.custom(
      atLeastOneGreaterZero('nonResUses', field)
    ).messages({
      'any.custom': `At least one ${text} use SF must be above zero`,
    }),
  flagForFloor = {
    basement: 'wereAnyVacanciesBasement',
    firstFloor: 'wereAnyVacanciesFirstFloor',
    secondFloor: 'wereAnyVacanciesSecondFloor',
    thirdAndAboveFloors: 'wereAnyVacanciesThirdAndAboveFloors',
  }

const usesFloor = {
  applicantSF: Joi.alternatives()
    .conditional('/propertyUsageCS', {
      is: PropertyUsage.Residential,
      then: 0,
    })
    .conditional('/useByApplicantCS', {
      is: UseByApplicant.Part,
      then: Joi.when('/portionUsedByApplicantCS', {
        not: PortionUsedByApplicant.Residential,
        then: schemaRules.NOT_EMPTY_NUMBER,
      }),
      otherwise: 0,
    }),

  rentedSF: Joi.when('/useByApplicantCS', {
    is: UseByApplicant.Entire,
    then: 0,
    otherwise: schemaRules.MIN_NUMBER(0), // sfValidation('rentedSF', 'Rented'),
  }),

  vacantSF: Joi.when('/useByApplicantCS', {
    is: UseByApplicant.Entire,
    then: 0,
    otherwise: Joi.when(`/wereAnyVacancies`, {
      is: true,
      then: sfValidation('vacantSF', 'Vacant'),
      otherwise: 0,
    }),
  }),

  $checkTotalUsePercent: schemaRules.NOT_EMPTY_NUMBER.custom(
    (value, helpers) => {
      const useApplicant =
          helpers.prefs.context?.originalValue.useByApplicantCS !==
          UseByApplicant.No,
        { uses, usesFloorId, usesTables } =
          helpers.prefs.context?.originalValue,
        useVacant = usesTables[flagForFloor[usesFloorId]],
        record: FloorUse = uses[value],
        vacant = record.vacantSF || 0,
        applicant = record.applicantSF || 0,
        rented = record.rentedSF || 0,
        total =
          (useApplicant ? applicant : 0) + rented + (useVacant ? vacant : 0)

      if (total !== record.squareFootage)
        return helpers.error('section.totalPercent')
    }
  ).messages({
    'section.totalPercent':
      'Try again! The sum must match the total floor area.',
  }),
}

const schema = schemaWrapper({
  wereAnyVacancies: schemaRules.BOOLEAN,
  useByApplicantCS: schemaRules.ANY,
  propertyUsageCS: schemaRules.ANY,
  portionUsedByApplicantCS: schemaRules.ANY,
  nonResUses: schemaRules.ITEMS(usesFloor),
})

export default schema
