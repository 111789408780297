import { ComponentStyleConfig } from '@chakra-ui/react'

const TAOnboardingBodyStyle: ComponentStyleConfig = {
  parts: [
    'step',
    'container',
    'header',
    'title',
    'subTitle',
    'content',
    'footer',
  ],
  baseStyle: props => ({
    container: {
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '4px',

      padding: '12px',

      backgroundColor: 'secondary.sideMenu',
      borderBottom: '1px solid',
      borderBottomColor: 'secondary.divider',
    },
    title: {
      color: 'fontnavy',
      fontSize: 'h6',
      fontWeight: 'semibold',
      lineHeight: 'h4',
      textAlign: 'center',
    },
    subTitle: {
      color: 'fontnavy',
      fontSize: 'body',
      lineHeight: '24px',
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      padding: '0 32px 27px',

      backgroundColor: 'white100',
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '24px',

      color: 'fontnavy',
      fontFamily: 'Open Sans',
      fontSize: 'body',
      lineHeight: '25px',
      a: {
        color: 'primary.base',
        outline: 'none',
      },
      img: {
        marginLeft: '16px',
      },
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      backgroundColor: 'secondary.sideMenu',
      borderTop: '1px solid',
      borderTopColor: 'secondary.divider',
    },
  }),
  variants: {
    normal: props => ({
      content: {
        width: '100%',
        height: '390px',
      },
      step: {
        width: '438px',
        height: '100%',
        img: {
          boxSize: '72px',
        },
        p: {
          width: '350px',
        },
      },
      footer: {
        width: '100%',
        height: '80px',
      },
    }),
  },
  defaultProps: {
    variant: 'normal',
  },
}

export default TAOnboardingBodyStyle
