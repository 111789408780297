import { ComponentStyleConfig } from '@chakra-ui/theme'

const EmptyMessageStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'title', 'message'],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: props.margin || '0 auto 0',
    },
    title: {
      textStyle: 'body.semibold',
      color: 'fontnavy',
    },
    message: {
      textStyle: 'body.regular',
      color: 'fontandicongray',
      marginTop: '8px',
    },
  }),
  // Styles for the size variations
  sizes: {
    sm: props => ({}),
    md: props => ({}),
  },
  // Styles for the visual style variations
  variants: {
    normal: props => ({}),
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}

export default EmptyMessageStyle
