import { useDisclosure } from '@chakra-ui/hooks'
import { getCallback } from 'helpers/utils'
import useCurrentDate from 'hooks/useCurrentDate'
import { useCallback, useEffect, useMemo } from 'react'
import createPersistedState from 'use-persisted-state'
import FilingDeadlineWarningDialog from '.'

const useFilingDeadlineWarningShown = createPersistedState(
  'filing_deadline_warning_shown'
)

const useFilingDeadlineWarningDialog = (deadlineMessage, deadlineAction) => {
  const dialog = useDisclosure(),
    [isShown, isShownSet] = useFilingDeadlineWarningShown(),
    currentDate = useCurrentDate(),
    canShow = useMemo(() => {
      return (
        deadlineMessage &&
        getCallback(deadlineAction)(currentDate) &&
        currentDate !== isShown
      )
    }, [deadlineMessage, deadlineAction, isShown, currentDate]),
    handleOnClose = useCallback(() => {
      isShownSet(currentDate)
      dialog.onClose()
    }, [dialog, isShownSet, currentDate])

  useEffect(() => {
    canShow ? dialog.onOpen() : dialog.isOpen && dialog.onClose()
  }, [canShow, dialog])

  return (
    <FilingDeadlineWarningDialog
      isOpen={dialog.isOpen}
      message={deadlineMessage}
      onClose={handleOnClose}
    />
  )
}

export default useFilingDeadlineWarningDialog
