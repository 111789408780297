import React, { useMemo } from 'react'
import { chakra } from '@chakra-ui/react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import {
  RpieFormData,
  VacantOwnerTableSection,
} from 'hooks/api/rpie/RpieFormData'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { IncomeSection } from 'hooks/api/ta/TaFormData'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { dashIfEmpty, getExceedDOFEstimatedTextForIncome, getFiscalPeriods, getLowerDOFEstimatedTextForIncome, getSum } from 'helpers/utils'
import { IncomeSectionTypes, IncomeCategories } from 'constants/dicti'
import { additionalCategories } from 'pages/Services/TA/TAFormPage/steps/PropertyDetails/IncomeExpense/StepPropertyDetailsIncome'
import { integerPositiveThousands } from 'helpers/formats'
import { CellWithoutBorder } from '../StepExpenseTable'
import SectionsTable, {
  basicTextFooterSubtotal,
  basicTextHeader,
  emptyForList,
  emptyForManually,
  StyledCell,
} from 'ui-framework/components/patterns/Wizard/SectionsTable'
import { categoriesEditTypeCS } from 'ui-framework/components/patterns/Wizard/SectionsTable/useSections'
import {
  formatToCurrencyOrDash,
  formatToNumberITSOrDash,
} from 'helpers/formatter'
import Prompt from 'ui-framework/components/primitives/Prompt'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { Text, VStack } from '@chakra-ui/react'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'

//===================================================

type Props = {
  vacantOwnerTable?: VacantOwnerTableSection
  sections: IncomeSection[]
  readOnly?: boolean
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

const MAX_OTHER_ITEMS = 6,
  isRegOrUnreg = (v: IncomeCategories | undefined): boolean =>
    [IncomeCategories.ResRegulated, IncomeCategories.ResUnregulated].includes(
      v as any
    ),
  getTableFooter =
    (totalUnit) =>
      ([totalPrev, totalLast, totalDiff]: any[], totalFooterLabel) => {
        return (
          <tr style={{ height: '56px' }}>
            <StyledCell />
            <StyledCell>
              <TextFromParams params={basicTextFooterSubtotal}>
                {totalFooterLabel}
              </TextFromParams>
            </StyledCell>
            <StyledCell>
              <TextFromParams params={basicTextFooterSubtotal}>
                {formatToNumberITSOrDash(totalUnit)}
              </TextFromParams>
            </StyledCell>
            <StyledCell>
              <TextFromParams params={basicTextFooterSubtotal}>
                {formatToCurrencyOrDash(totalPrev)}
              </TextFromParams>
            </StyledCell>
            <StyledCell>
              <TextFromParams params={basicTextFooterSubtotal}>
                {formatToCurrencyOrDash(totalLast)}
              </TextFromParams>
            </StyledCell>
            <StyledCell colSpan={3}>{totalDiff}</StyledCell>
          </tr>
        )
      },
  getSectionContent = (
    items,
    totals,
    getFieldByType,
    createInputByFieldNameAndParams,
    createRemoveRowButton,
    getPromptByItem,
    readOnly
  ) => {
    const totalNumberOfUnits = items.reduce(getSum('numberOfUnits'), 0)

    return {
      body: items.map(
        (item, index) =>
          item.$show !== false && (
            <tr key={`${index}-${item?.categoryCS}`} style={{ height: '56px' }}>
              <td>{getFieldByType(item, index)}</td>
              <td>
                {isRegOrUnreg(item?.categoryCS) || readOnly
                  ? formatToNumberITSOrDash(item?.numberOfUnits)
                  : createInputByFieldNameAndParams(
                    item,
                    index,
                    'numberOfUnits',
                    {
                      type: 'number',
                      format: integerPositiveThousands,
                    }
                  )}
              </td>
              <td>{formatToCurrencyOrDash(item.prevYearAmount)}</td>
              <td>{createInputByFieldNameAndParams(item, index)}</td>
              <td>{totals.diffs[index]}</td>
              <td>{createRemoveRowButton(index)}</td>
              <CellWithoutBorder />
            </tr>
          )
      ),
      footer: (
        <tr style={{ height: '70px', verticalAlign: 'top' }}>
          <td />
          <td>
            <TextFromParams params={basicTextFooterSubtotal}>
              Subtotal
            </TextFromParams>
          </td>
          <td>
            <TextFromParams params={basicTextFooterSubtotal}>
              {formatToNumberITSOrDash(totalNumberOfUnits)}
            </TextFromParams>
          </td>
          <td>
            <TextFromParams params={basicTextFooterSubtotal}>
              {formatToCurrencyOrDash(totals.totalLast)}
            </TextFromParams>
          </td>
          <td>
            <TextFromParams params={basicTextFooterSubtotal}>
              {formatToCurrencyOrDash(totals.totalCurrent)}
            </TextFromParams>
          </td>
          <td colSpan={3}>
            <TextFromParams params={basicTextFooterSubtotal}>
              {totals.totalDiff}
            </TextFromParams>
          </td>
        </tr>
      ),
    }
  }

/**
 * StepIncomeTable component
 */
const StepIncomeTable = (props: Props) => {
  const {
    vacantOwnerTable,
    sections,
    applyValidation,
    onValidation,
    readOnly = false,
  } = props,
    {
      context,
      serviceData: {
        general: { numberOfResRegulatedUnit, numberOfResUnregulatedUnit },
      },
    } = useServiceWizard(),
    { prevReportingYear, reportingYear } = getFiscalPeriods(context),
    hasRegOrUnreg =
      numberOfResRegulatedUnit > 0 || numberOfResUnregulatedUnit > 0,
    validationData = useMemo(
      () => ({
        sections,
        vacantOwnerTable,
        total: sections.reduce(
          (acc, section) =>
            acc +
            section.items.reduce((acc, i) => acc + (i.lastYearAmount || 0), 0),
          0
        ),
      }),
      [sections, vacantOwnerTable]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    headers = useMemo(
      () => [
        {
          width: '54px',
        },
        {
          children: (
            <HelpTarget name="categories">
              <TextFromParams params={basicTextHeader}>
                Categories
              </TextFromParams>
            </HelpTarget>
          ),
          width: readOnly ? '300px' : '350px',
        },
        {
          children: (
            <TextFromParams params={basicTextHeader}>
              {hasRegOrUnreg && !readOnly ? (
                <Prompt
                  iconParams={{ margin: '0 0 0 12px' }}
                  maxW={'262px'}
                  label={`This value was entered in the General Section. To edit your entry, return to the General section using the "Back" button.`}
                >
                  # of Units
                </Prompt>
              ) : (
                '# of Units'
              )}
            </TextFromParams>
          ),
          width: '130px',
        },
        {
          children: (
            <TextFromParams params={basicTextHeader}>
              {prevReportingYear} Annual
            </TextFromParams>
          ),
          width: '40%',
        },
        {
          children: (
            <TextFromParams params={basicTextHeader}>
              {reportingYear} Annual
            </TextFromParams>
          ),
          width: '40%',
        },
        {
          children: (
            <TextFromParams params={basicTextHeader}>
              Change (YoY)
            </TextFromParams>
          ),
          width: '20%',
        },
        {
          width: '14px',
        },
        {
          width: '24px',
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reportingYear, prevReportingYear, hasRegOrUnreg]
    ),
    items = useMemo(
      () => [
        {
          type: IncomeSectionTypes.IncomeMain,
          title: 'Rental',
          readOnly,
          params: {
            getSectionContent,
          },
        },
        {
          type: IncomeSectionTypes.IncomeAdditional,
          title: 'Ancillary',
          readOnly,
          params: {
            getSectionContent,
            categoriesEditType: categoriesEditTypeCS.list,
            categoriesForList: additionalCategories,
            newLineOptions: {
              empty: emptyForList,
              getDisabled: originalSectionItemsCount =>
                originalSectionItemsCount === 0 ||
                originalSectionItemsCount >=
                Object.keys(additionalCategories).length,
            },
          },
        },
        {
          type: IncomeSectionTypes.HotelMain,
          title: 'Departmental',
          readOnly,
          params: {
            getSectionContent,
          },
        },
        {
          type: IncomeSectionTypes.HotelRental,
          title: 'Income from rental tenants',
          readOnly,
          params: {
            getSectionContent,
          },
        },
        {
          type: IncomeSectionTypes.CondoMain,
          title: 'Income from unit owners',
          readOnly,
          params: {
            getSectionContent,
          },
        },
        {
          type: IncomeSectionTypes.CondoRental,
          title: 'Rental',
          readOnly,
          params: { getSectionContent },
        },
        {
          type: [
            IncomeSectionTypes.HotelOther,
            IncomeSectionTypes.CondoOther,
            IncomeSectionTypes.IncomeOther,
          ],
          title: 'Other',
          readOnly,
          params: {
            getSectionContent,
            categoriesEditType: categoriesEditTypeCS.manually,
            newLineOptions: {
              empty: emptyForManually,
              getDisabled: originalSectionItemsCount =>
                originalSectionItemsCount === 0 ||
                originalSectionItemsCount >= MAX_OTHER_ITEMS,
            },
          },
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    totalUnitsCount = sections.reduce(
      (acc, section) =>
        acc + (section.items.reduce(getSum('numberOfUnits'), 0) || 0),
      0
    )


  const dofWarning = useMemo(() => {
    console.log('sections', sections)
    const dofEstimated = context?.external?.dofIncome
    const totalLast =
      sections.reduce((acc, section) => {
        return acc + section.items.reduce((acc, item) => {
          return acc + (item.lastYearAmount || 0)
        }, 0)
      }, 0)

    const dofDiffPercent = dofEstimated ? Math.round((dofEstimated - totalLast) * 100 / dofEstimated) : 0
    const showDOFWarning = dofEstimated > 0 && totalLast > 0 && (dofDiffPercent > 10 || dofDiffPercent < -10)
    const message = (dofDiffPercent > 0 ? getLowerDOFEstimatedTextForIncome : getExceedDOFEstimatedTextForIncome)(Math.abs(dofDiffPercent))

    if (!showDOFWarning) return null
    return <AttentionBox title={null} w={`516px`} variant="warning">
      <p>DOF Estimated Income is <b>{formatToCurrencyOrDash(dofEstimated)}</b>.</p>
      <p>{message}</p>
    </AttentionBox>
  }, [sections, context?.external?.dofIncome])

  return (
    <>
      <SectionsTable
        {...props}
        sections={sections}
        items={items}
        hasError={hasError}
        modalContent={`Caution! You are removing an income source previously added. Confirm that it needs to be removed.`}
        totalFooterLabel={`Total Gross Income`}
        tableParams={{
          isSticky: !readOnly,
          headers,
          getTableFooter: getTableFooter(totalUnitsCount),
          colorChildIndexes: [0, 2, 4],
        }}
      />
      {hasError('total') && (
        <Text mt="1em" ml="54px" color="error">
          Total income must be greater than 0
        </Text>
      )}
      <chakra.div sx={{ margin: "20px 0 0 50px" }}>
        {dofWarning}
      </chakra.div>
    </>
  )
}

export default StepIncomeTable
