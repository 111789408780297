import React from 'react'
import { chakra, Box, Flex, Divider } from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'

type CirleMarkProps = {
  aroundBg: string
  circleBg: string
  topLine?: boolean
  bottomLine?: boolean
} & ChakraComponentProps

const CircleMark = ({
  topLine,
  bottomLine,
  aroundBg,
  circleBg,
  ...rest
}: CirleMarkProps) => (
  <Flex flexDirection="column" alignItems="center" {...rest}>
    <Divider orientation="vertical" opacity={topLine ? 1 : 0} />
    <Flex
      minW={'24px'}
      minH={'24px'}
      alignItems="center"
      justifyContent="center"
      borderRadius={'50%'}
      bg={aroundBg}
    >
      <Box w={'9px'} h={'9px'} borderRadius={'50%'} bg={circleBg} />
    </Flex>
    <Divider orientation="vertical" opacity={bottomLine ? 1 : 0} />
  </Flex>
)

export default chakra(CircleMark)
