import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { Box, chakra, useStyles } from '@chakra-ui/react'

//===================================================
type ProgressTitleProps = {} & BaseComponentProps

/**
 * ProgressTitle component
 */
const ProgressTitle = (props: ProgressTitleProps) => {
  const styles = useStyles()
  return (
    <Box sx={styles.title} {...props}>
      {props.children}
    </Box>
  )
}

export default chakra(ProgressTitle)
