import React, { useEffect } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import CenterForm from './CenterForm'
import { Text, VStack } from '@chakra-ui/react'
import Button from 'ui-framework/components/primitives/Button'

import { useNavigate } from 'react-router-dom'
import { URL_AUTH_LOGIN } from 'constants/URLS'

//===================================================
type PasswordChangedNotificationProps = {} & BaseComponentProps

/**
 * PasswordChangedNotification component
 */
const PasswordChangedNotification = (
  props: PasswordChangedNotificationProps
) => {
  const navigate = useNavigate()

  useEffect(() => {
    const cl = setTimeout(() => navigate(URL_AUTH_LOGIN), 10000)

    return () => clearTimeout(cl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CenterForm title="Password has been updated">
      <VStack spacing="24px" width="100%">
        <Text>We will automatically redirect you to the login page.</Text>
        <Button w="100%" onClick={() => navigate(URL_AUTH_LOGIN)}>
          Go to Log In
        </Button>
      </VStack>
    </CenterForm>
  )
}

export default PasswordChangedNotification
