import { Services } from 'constants/dicti'
import { URL_SERVICERPIELIST } from 'constants/URLS'
import { Config, getFullConfig } from 'helpers/config/utils'
import { PRECONF_FILTER_DC_RPIECONFIRMONLY } from 'hooks/api/my/MyDocumentsContext'

type RpieConfig = {
  showRPIE: boolean
  showDC?: boolean
  serviceCS: Services.RPIE
  docCenterDefaultFilter?: string
} & Config

const rpieConfig: RpieConfig = {
  homeUrl: URL_SERVICERPIELIST,
  showRPIE: true,
  serviceCS: Services.RPIE,
},
  rpieCalendar = {
    '2024-03-23': {},
    '2024-05-01': {
      showDC: true,
      docCenterDefaultFilter: PRECONF_FILTER_DC_RPIECONFIRMONLY,
    },
    '2024-07-01': {
      showRPIE: false,
      showDC: false,
      docCenterDefaultFilter: undefined,
    },
  }

export default getFullConfig<RpieConfig>(rpieConfig, rpieCalendar)
