import { useState, useCallback, useMemo } from 'react'
import { FileStatus, FileSources, DocumentType } from 'constants/dicti'
import firebaseApp, { firebase } from 'helpers/firebaseInit'
import { getDownloadLink as handleDownload } from 'helpers/utils'
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  setDoc,
} from 'firebase/firestore'
import { negate, omit } from 'lodash-es'
import { useMyDocument } from 'hooks/api/my/MyDocumentsContext'
import { DocFile } from 'hooks/api/documents/useGetDocs'

const FILE_UPLOAD_PATH = `uploads`
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
type FileMetadata = Optional<DocFile, 'id' | 'ref'>

export type UploadFiles = {
  uploadProgress?: number
  state?: firebase.storage.TaskState
} & FileMetadata

export type Files =
  | any[]
  | UploadFiles[]
type RecordObjectFiles = {
  files: Files
  removeOrAddDoc: (isDocumentsAdd: boolean) => void
  handleFilesUpload: (filesForUpload: File[]) => void
  handleFilesDelete: (fileData: FileMetadata) => Promise<void> | void
  handleDownload: (path: string) => Promise<any>
  handleRetryUpload: (fileData: FileMetadata) => void
}

export const useDocumentFiles = (
  contextRef: DocumentReference<DocumentData>,
  documentType: DocumentType
): RecordObjectFiles => {
  const storageRef = firebaseApp.storage().ref(),
    document = useMyDocument(contextRef, documentType)

  const { documentRef, files, filesRef } = useMemo(() => {
    return {
      documentRef: document.ref,
      files: document?.files,
      filesRef: collection(document.ref, 'files'),
    }
  }, [document?.files, document.ref])

  const [uploadTasks, uploadTasksSet] = useState<firebase.storage.UploadTask[]>(
    []
  ),
    getTask = useCallback(
      (path: string) =>
        uploadTasks[
        uploadTasks.findIndex(x => path === x.snapshot?.ref?.fullPath)
        ],
      [uploadTasks]
    ),
    getFullPath = useCallback(
      fname =>
        `${FILE_UPLOAD_PATH}/${contextRef.path}/${documentType}/${fname}`,
      [documentType, contextRef]
    ),
    removeUploadTask = useCallback(
      ({ fileName, name }) =>
        uploadTasksSet(tasks =>
          tasks.filter(
            task =>
              task?.snapshot?.ref?.fullPath !== getFullPath(name || fileName)
          )
        ),
      [uploadTasksSet, getFullPath]
    ),
    setDocument = useCallback(
      (ref, docValue: object | FileMetadata = {}) =>
        setDoc(ref, docValue, { merge: true }),
      []
    ),
    deleteFile = useCallback(
      file => {
        const { filePath, fileName } = file
        if (filePath) {
          removeUploadTask(file)
          storageRef
            .child(filePath)
            .delete()
            .then(() => deleteDoc(doc(filesRef!, fileName)))
            //TODO: decide whether we need this console:
            // eslint-disable-next-line no-console
            .catch(err => console.log(err, 'DELETE FILE ERROR'))
        }
      },
      [storageRef, filesRef, removeUploadTask]
    ),
    deleteDocument = useCallback(
      (ref, fileMeta?: FileMetadata) => {
        deleteDoc(ref).then(() => {
          fileMeta ? deleteFile(fileMeta) : files.forEach(deleteFile)
        })
      },
      [files, deleteFile]
    ),
    preparedFiles = useCallback(
      (f, params?: object) =>
      ({
        isAllowDeleting: false,
        fileName: f.name,
        filePath: getFullPath(f.name),
        statusCS: FileStatus.Uploaded,
        wasDeleted: false,
        sfDocumentId: null,
        fileSource: FileSources.WEB,
        ...(params && params),
      } as FileMetadata),
      [getFullPath]
    ),
    removeOrAddDoc = useCallback(
      (isDocumentsAdd: boolean) => {
        if (isDocumentsAdd)
          setDocument(documentRef, {
            statusCS: document.statusCS,
            typeCS: document.typeCS,
          })
        else {
          deleteDocument(documentRef)
        }
      },
      [
        document.statusCS,
        document.typeCS,
        documentRef,
        deleteDocument,
        setDocument,
      ]
    ),
    fileUpload = useCallback(
      file => {
        const task = storageRef.child(getFullPath(file.name)).put(file)
        task.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {
            setDocument(documentRef, omit(document, ['id', 'ref', 'files']))
            setDocument(
              doc(filesRef, file.name),
              preparedFiles(file, {
                uploadProgress:
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                state: firebase.storage.TaskState.RUNNING,
              })
            )
          },
          err => {
            //TODO: decide whether we need this console:
            // eslint-disable-next-line no-console
            console.log(err, `File upload ERROR => ${file.name}`)
            setDocument(doc(filesRef, file.name), {
              state: firebase.storage.TaskState.ERROR,
              uploadProgress: firebase.firestore.FieldValue.delete(),
            })
          },
          () => {
            setDocument(doc(filesRef, file.name), {
              uploadProgress: firebase.firestore.FieldValue.delete(),
              state: firebase.firestore.FieldValue.delete(),
            })
            removeUploadTask(file)
          }
        )
        task.resume()
        return task
      },
      [
        storageRef,
        getFullPath,
        setDocument,
        documentRef,
        document,
        filesRef,
        preparedFiles,
        removeUploadTask,
      ]
    ),
    handleFilesUpload = useCallback(
      (filesForUpload: File[]) => {
        const isAlreadyInFiles = file =>
          files.map(x => x.fileName).includes(file.name),
          newFilesForUpload = filesForUpload.filter(negate(isAlreadyInFiles)),
          tasks = newFilesForUpload.map(fileUpload)

        uploadTasksSet(prev => prev.concat(tasks))
      },
      [fileUpload, files]
    ),
    handleFilesDelete = useCallback(
      (fileData: FileMetadata) => {
        const task = getTask(fileData.filePath),
          isProccessing =
            task && task?.snapshot?.state !== firebase.storage.TaskState.SUCCESS

        if (isProccessing) {
          task.cancel()
        }
        deleteDocument(doc(filesRef, fileData.fileName), fileData)
      },
      [filesRef, getTask, deleteDocument]
    ),
    handleRetryUpload = useCallback(
      fileData => {
        let task = getTask(fileData.filePath),
          retryUpload = () => fileUpload(storageRef.child(fileData.filePath))
        if (task) task = retryUpload()
        else uploadTasksSet(prev => prev.concat(retryUpload()))
      },
      [fileUpload, getTask, storageRef]
    )

  return {
    files,
    removeOrAddDoc,
    handleFilesUpload,
    handleFilesDelete,
    handleDownload,
    handleRetryUpload,
  }
}
