import { memo } from 'react'
import {
  useMultiStyleConfig,
  chakra,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  Flex,
  ChakraProps,
  Placement,
} from '@chakra-ui/react'
import Icon from '../Icon'
import { ChakraComponentProps } from 'ui-framework/common/types'
import isEqual from 'lodash/isEqual'

type InformationDialogProps = {
  icon?: string
  triggerProps?: ChakraProps
  placement?: Placement
} & ChakraComponentProps

/*
For usage need add to parent component hover effect 
sx={{
  _hover: {
    div: {
      display: 'flex',
    },
  },
}}
*/

const InformationDialog = (props: InformationDialogProps) => {
  const { icon = 'info-circle', triggerProps } = props,
    style = useMultiStyleConfig('MGNYInformationDialog', props)

  return (
    <Popover placement={props.placement || 'bottom-end'}>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Flex sx={style.wrapper} {...props}>
          <Flex sx={style.triggerWrapper} {...triggerProps}>
            <Icon size="sm">{icon}</Icon>
          </Flex>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent sx={style.modalContent}>
          {props.children}
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default memo(chakra(InformationDialog) as any, isEqual)
