import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { APP_VERSION } from './utils'
import { flow, cond } from 'lodash/fp'
import { isProduction, isStaging, getCurrentEnv } from 'helpers/utils'

const init = cond([
    [
      isProduction,
      (env: string) =>
        Sentry.init({
          dsn: process.env.REACT_APP_SENTRY_DSN,
          environment: env,
          release: APP_VERSION,
          // This sets the sample rate to be 10%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.1,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 1.0,
          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0,
          integrations: [new BrowserTracing({}), new Sentry.Replay()],
        }),
    ],
  ]),
  initSentry = flow(isStaging, getCurrentEnv, init)

initSentry()
