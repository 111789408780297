import FirstStep from './assets/step_1.png'
import SecondStep from './assets/step_2.png'
import ThirdStep from './assets/step_3.png'

type RPIEOnboardingStepsObject = {
  title: string
  imageSrc: string
}

export const RPIEOnboardingSteps: Array<RPIEOnboardingStepsObject> = [
  {
    title: 'Pay your invoice via a credit card or send a check',
    imageSrc: FirstStep,
  },
  {
    title: ' Provide missing data for each of your properties',
    imageSrc: SecondStep,
  },
  {
    title: 'Review and Submit your data',
    imageSrc: ThirdStep,
  },
]
