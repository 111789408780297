import styled from '@emotion/styled/macro'

const HelpBlockWrapper = styled('div')<{}>(props => ({
  padding: props.theme.spacing.space24,
  border: `1px dashed ${props.theme.colors.primary50}`,
  boxSizing: 'border-box',
  borderRadius: '2px',
  backgroundColor: props.theme.colors.white100,
  // position: 'relative',
  color: props.theme.colors.black70,
  transition: 'opacity  2s ease',
  opacity: 1,
  visibility: 'visible',
}))

type ButtonTopProps = {}
const ButtonsTop = styled('div')<ButtonTopProps>(props => ({
  display: 'flex',
  justifyContent: 'center',
  'button:not(:first-of-type)': {
    marginLeft: '12px',
  },
}))

export { HelpBlockWrapper, ButtonsTop }
