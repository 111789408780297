import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { getFiscalPeriods } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'

//===================================================
type HelpDetailsRentRollUploadHelpProps = {} & BaseComponentProps

/**
 * HelpDetailsRentRollUploadHelp component
 */
const HelpDetailsRentRollUploadHelp = (
  props: HelpDetailsRentRollUploadHelpProps
) => {
  const { context } = useServiceWizard(),
    { reportingYear, fiscalYear } = getFiscalPeriods(context)
  return (
    <p>
      You can upload a copy of your monthly rent-roll, as of December{' '}
      {reportingYear} / January {fiscalYear}. The rent roll must clearly show
      (i) which apartments are regulated (rent stabilized / rent controlled),
      unregulated (market rate), owner-occupied, and vacant, (ii) monthly rents
      for each unit, and (iii) total monthly rent.
    </p>
  )
}

export default HelpDetailsRentRollUploadHelp
