import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpRRStepFillingTypeSelectionProps = {} & BaseComponentProps

/**
 * HelpRRStepFillingTypeSelection component
 */
const HelpRRStepFillingTypeSelection = (
  props: HelpRRStepFillingTypeSelectionProps
) => {
  return (
    <>
      <p>
        Commercial Rent Roll submission is required for all NYC properties with
        at least 1 non-residential unit and an assessed value greater than
        $750,000.
      </p>
      <p>
        In this section, you will be guided to provide details about each
        commercial tenant{' '}
        <b>
          <u>excluding</u> tenants of storefront units, as that data was
          collected in the previous section.
        </b>
      </p>
    </>
  )
}

export default HelpRRStepFillingTypeSelection
