import { URL_HOME } from 'constants/URLS'
import { CalendarConfig } from 'helpers/config/utils'

export const appBuildYear: number = 2024

export const mainConfig: CalendarConfig = {
  [`2022-11-30`]: {
    homeUrl: URL_HOME,
    fiscalYear: appBuildYear,
  },
}
