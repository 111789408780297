import { merge } from 'object-path-immutable'
import { useMemo } from 'react'

// merge serviceData with defaultServiceData to get full data model
const useApplicationData = application =>
  useMemo(
    () => [
      application.data
        ? merge(
            { serviceData: application.data.defaultServiceData },
            'serviceData',
            application.data.serviceData || {}
          ).serviceData
        : null,
      application.data?.serviceMetaData,
    ],
    [application.data]
  )

export default useApplicationData
