import { ComponentStyleConfig } from '@chakra-ui/react'

const RPIEOnboardingBodyStyle: ComponentStyleConfig = {
  parts: [
    'contentWrapper',
    'contactsWrapper',
    'contentBox',
    'title',
    'subtitle',
  ],
  baseStyle: props => ({
    contentBox: {
      p: `32px`,
      borderLeft: '1px solid',
      borderColor: 'secondary.divider',
    },
    subtitle: {
      textStyle: 'body.regular',
      color: 'fontnavy',
      lineHeight: '24px',
      mt: '8px',
    },
    contactsWrapper: {
      m: '20px 0 32px',
      textStyle: 'body.regular',
      color: 'fontnavy',
      lineHeight: '25px',
      a: {
        color: 'primary.base',
        outline: 'none',
      },
    },
    contentWrapper: {
      p: `20px 0`,
      textStyle: 'body.semibold',
      lineHeight: '25px',
      color: 'fontnavy',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      color: 'fontnavy',
      textStyle: 'h6',
    },
  }),
  variants: {
    normal: props => ({
      contentBox: {
        w: '100%',
      },
    }),
  },
  defaultProps: {
    variant: 'normal',
  },
}

export default RPIEOnboardingBodyStyle
