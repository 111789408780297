import React from 'react'

import { chakra, Flex, Divider } from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'

//===================================================
const SummaryBlockWrapper = chakra('div', {
  baseStyle: {
    bg: 'transparent',
    textStyle: 'body.semibold',
    color: 'fontnavy',
    lineHeight: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
})

type SummaryBlockProps = {
  title: string
  value: string
} & ChakraComponentProps

/**
 * SummaryBlock component
 */
const SummaryBlock = ({ title, value, ...props }: SummaryBlockProps) => {
  return (
    <SummaryBlockWrapper className={props.className} {...props}>
      <Divider />
      <Flex mt={'12px'}>
        {title}: {value}
      </Flex>
      {props.children}
    </SummaryBlockWrapper>
  )
}

export default chakra(SummaryBlock)
