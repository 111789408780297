import { useMemo, useCallback } from 'react'
import { PaginationProps } from 'ui-framework/components/primitives/Pagination'
import useQueryParamState from 'hooks/useQueryParamState'

type UsePaginationOptionsProps = {
  itemsPerPage?: number
  initialPage?: number
  promote?: boolean | string
}

function usePagination<T = any>(
  items: T[] = [],
  {
    itemsPerPage = 10,
    initialPage = 1,
    promote = false,
  }: UsePaginationOptionsProps
): [T[], PaginationProps] {
  const [currentPageString, currentPageSet] = useQueryParamState(
      'page',
      initialPage
    ),
    currentPage = Number(currentPageString),
    totalPages = useMemo(
      () => Math.ceil(items.length / itemsPerPage),
      [items.length, itemsPerPage]
    ),
    handleOnPageChange = useCallback(
      page => {
        const p = page > totalPages ? totalPages : page < 1 ? 1 : page
        if (p !== currentPage) {
          currentPageSet(page)
        }
        promote && currentPageSet(page)
      },
      [currentPage, currentPageSet, totalPages, promote]
    ),
    paginationSettings = useMemo<PaginationProps>(
      () => ({
        currentPage: currentPage as number,
        itemsPerPage,
        totalItems: items.length,
        onChange: handleOnPageChange,
        onNext: () => handleOnPageChange(currentPage + 1),
        onPrev: () => handleOnPageChange(currentPage - 1),
        onFirst: () => handleOnPageChange(1),
        onLast: () => handleOnPageChange(totalPages),
      }),
      [currentPage, handleOnPageChange, items.length, itemsPerPage, totalPages]
    ),
    slicedItems = useMemo(() => {
      const offset = [
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      ]
      return items.slice(...offset)
    }, [currentPage, items, itemsPerPage])

  if (totalPages && currentPage > totalPages) {
    handleOnPageChange(initialPage)
  }

  return [slicedItems, paginationSettings]
}

export default usePagination
