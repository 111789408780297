import React from 'react'

import { InputState } from '../Input/styles/InputStyles'
import {
  OnChangeHandler,
  ChakraComponentProps,
} from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'
import { chakra } from '@chakra-ui/react'

//===================================================

const ValueCounter = chakra('div', {
  baseStyle: {
    textStyle: 'body.regular',
    color: 'fontandicongray',
    position: 'absolute',
    right: 0,
    bottom: '-22px',
  },
})

const TextareaBaseWrapper = chakra('div', {
  baseStyle: {},
})

export type TextareaBaseProps = {
  state?: InputState
  width?: string
  resize?: 'horizontal' | 'vertical' | 'both'
  /**
   * Value for controlled input
   */
  value?: string | null
  /**
   * Name of input.
   */
  name?: string
  rows?: number
  /**
   * Placeholder of input.
   */
  placeholder?: string
  /**
   * show outline
   * @default true
   */
  outline?: boolean
  /**
   * Disabled state
   * @default false
   */
  disabled?: boolean
  /**
   * read only state
   * @default false
   */
  readonly?: boolean
  /**
   * onChange handler
   */
  onChange?: OnChangeHandler<string>
  /**
   * onFocus handler
   */
  onFocus?: any
  showCounter?: boolean
  maxLength?: number
} & ChakraComponentProps

/**
 * TextareaBase component
 */
const TextareaBase = ({
  value,
  name,
  rows = 3,
  placeholder,
  disabled = false,
  readonly = false,
  showCounter = true,
  maxLength = 1024,
  onChange,
  ...props
}: TextareaBaseProps) => {
  return (
    <TextareaBaseWrapper className={props.className}>
      <textarea
        value={value || undefined}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        rows={rows}
        onChange={v => getCallback(onChange)(v.target.value, name)}
        maxLength={maxLength}
      />
      {showCounter ? (
        <ValueCounter>
          {value ? value.length : 0} / {maxLength}
        </ValueCounter>
      ) : undefined}
    </TextareaBaseWrapper>
  )
}

export default TextareaBase
