import { memo, ReactChild } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Checkbox from 'ui-framework/components/primitives/Checkbox'
import { CellWithoutBorder } from '../income/StepExpenseTable'
import isEqual from 'lodash/isEqual'
import { Utilities } from 'constants/dicti'
import { RentRollResidentialUnitEx } from './StepRentRollResidentialTable'
import Empty from 'ui-framework/components/primitives/Empty'
import {
  formatToCurrencyOrLongdash,
  formatToNumberITSOrLongdash,
} from 'helpers/formatter'
import { getUtilityState } from './StepRentRollResidentialTable'

type RowProps = {
  rowNum: number
  unit: RentRollResidentialUnitEx
  editRowBtn?: ReactChild
} & BaseComponentProps

const showValueOrDash = v => v || <Empty type={'longdash'} />

const Row = ({ rowNum, unit, className = '', editRowBtn }: RowProps) => {
  return (
    <tr className={className}>
      <CellWithoutBorder />
      <td style={{ whiteSpace: 'nowrap' }}>{rowNum}</td>
      <td>
        <p>{showValueOrDash(unit.unitApartmentNumber)}</p>
      </td>
      <td>
        <p>{showValueOrDash(unit.floorNumber)}</p>
      </td>
      <td>
        <p>{showValueOrDash(unit.bedroomsCount)}</p>
      </td>
      <td>
        <p>{formatToCurrencyOrLongdash(unit.monthlyRentAmount)}</p>
      </td>

      <td>
        <p>{formatToNumberITSOrLongdash(unit.rentableNetAreaSF)}</p>
      </td>
      <td>
        <p>{formatToNumberITSOrLongdash(unit.vacantMonths)}</p>
      </td>

      <td>
        <p>
          <Checkbox checked={!!unit.isRegulated} disabled />
        </p>
      </td>

      <td>
        <p>
          <Checkbox checked={getUtilityState(unit, Utilities.Water)} disabled />
        </p>
      </td>

      <td>
        <p>
          <Checkbox checked={getUtilityState(unit, Utilities.Heat)} disabled />
        </p>
      </td>
      <td>
        <p>
          <Checkbox
            checked={getUtilityState(unit, Utilities.Electricity)}
            disabled
          />
        </p>
      </td>
      <td>{editRowBtn}</td>
      <CellWithoutBorder />
    </tr>
  )
}

export default memo(Row, isEqual)
