import React, { useMemo } from 'react'

import Radiobutton from 'ui-framework/components/primitives/Radiobutton'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'

//====================================================
type StepRentalIncomeProps = {
  wasIncomeFromRentalTenants: boolean | null
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepRentalIncome component
 */
const StepRentalIncome = ({
  wasIncomeFromRentalTenants,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: StepRentalIncomeProps) => {
  const validationData = useMemo(
      () => ({ wasIncomeFromRentalTenants }),
      [wasIncomeFromRentalTenants]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  return (
    <StepBasic className={props.className}>
      <HelpTarget name="wasIncomeFromRentalTenants">
        <SelectsGroup
          label={'Was there any income collected from rental tenants?'}
          error={hasError('wasIncomeFromRentalTenants')}
        >
          <Radiobutton
            name="wasIncomeFromRentalTenants"
            value={wasIncomeFromRentalTenants}
            selected={false}
            onChange={onChange}
            disabled={disabled}
          >
            No
          </Radiobutton>

          <Radiobutton
            name="wasIncomeFromRentalTenants"
            value={wasIncomeFromRentalTenants}
            selected={true}
            onChange={onChange}
            disabled={disabled}
          >
            Yes
          </Radiobutton>
        </SelectsGroup>
      </HelpTarget>
    </StepBasic>
  )
}

export default StepRentalIncome
