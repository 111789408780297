import React from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import SummaryTable from 'ui-framework/components/patterns/Wizard/SummaryTable'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { TASummarySectionsConfig } from './TASummarySectionsConfig'

//==================================================

type StepSummaryProps = {} & BaseComponentProps & StepPropsInterface<TaFormData>

/**
 * StepSummary component
 */
const StepSummary = (props: StepSummaryProps) => {
  const { serviceData, context, changeStep } = useServiceWizard()
  return (
    <SummaryTable
      {...props}
      data={serviceData}
      context={context}
      sectionsConfig={TASummarySectionsConfig}
      changeStep={changeStep}
    />
  )
}

export default StepSummary
