import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { VStack, HStack } from '@chakra-ui/react'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { get } from 'object-path-immutable'
import UsesAndFloorAreasTable from './UsesAndFloorAreasTable'
import { getDictiName } from 'constants/dicti'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

//====================================================
type Props = {} & BaseComponentProps & StepPropsInterface<TaFormData>

/**
 * StepPropertyUsesAndFloorAreas component
 */
const StepPropertyUsesAndFloorAreas = ({ ...props }: Props) => {
  const { serviceData } = useServiceWizard()

  return (
    <StepBasic className={props.className} padding="0">
      <VStack spacing="36px" align="flex-start">
        <HStack spacing="24px" pl="55px">
          <AttentionBox title={false} w="360px" h="136px" variant="warning">
            <TextFromParams
              params={{
                textAlign: 'left',
                lineHeight: '25px',
              }}
            >
              If you wish to make changes to any data displayed on this page,
              please call
              <br />
              {SUPPORT_CONTACTS.supportTel}.
            </TextFromParams>
          </AttentionBox>

          <AttentionBox title={false} w="360px" h="136px" bgColor={'#F9FAFF'}>
            <VStack w="100%" spacing="6px">
              <HStack w="100%" justify="space-between">
                <p>Occupancy Type:</p>
                <TextFromParams
                  params={{
                    flexBasis: '50%',
                    textStyle: 'body.semibold',
                    textAlign: 'right',
                    textTransform: 'capitalize',
                  }}
                >
                  {getDictiName(serviceData.general.propertyUsageCS)}
                </TextFromParams>
              </HStack>

              <HStack w="100%" justify="space-between">
                <p>Residential Units:</p>
                <TextFromParams
                  params={{
                    flexBasis: '50%',
                    textStyle: 'body.semibold',
                    textAlign: 'right',
                  }}
                >
                  {get(
                    serviceData,
                    'uses.residentialInfo.residentialUnits.numberOfResidentialUnit'
                  ) || '0'}
                </TextFromParams>
              </HStack>

              <HStack w="100%" justify="space-between">
                <p>Non-Residential Units:</p>
                <TextFromParams
                  params={{
                    flexBasis: '50%',
                    textStyle: 'body.semibold',
                    textAlign: 'right',
                  }}
                >
                  {get(
                    serviceData,
                    'uses.nonResidentialInfo.numberOfNonResidentialUnit'
                  ) || '0'}
                </TextFromParams>
              </HStack>

              <HStack w="100%" pl="24px" justify="space-between">
                <p>Retail Units:</p>
                <TextFromParams
                  params={{
                    flexBasis: '50%',
                    textStyle: 'body.semibold',
                    textAlign: 'right',
                  }}
                >
                  {get(
                    serviceData,
                    'uses.nonResidentialInfo.numberOfRetailUnit'
                  ) || '0'}
                </TextFromParams>
              </HStack>
            </VStack>
          </AttentionBox>
        </HStack>

        <UsesAndFloorAreasTable staticData={serviceData} />
      </VStack>
    </StepBasic>
  )
}

export default StepPropertyUsesAndFloorAreas
