import { CSSObject } from '@emotion/react'

const basicType: CSSObject = {
  fontFamily: `'Open Sans', sans-serif`,
  fontStyle: 'normal',
}

export type Typography = {
  h1: CSSObject
  h2: CSSObject
  h3: CSSObject
  h4: CSSObject
  h5: CSSObject
  body1: CSSObject
  body2semibold: CSSObject
  body2: CSSObject
  caption1: CSSObject
  caption1semibold: CSSObject
  caption2: CSSObject
  captionMicro: CSSObject
  caption2semibold: CSSObject
}

const typography = {
  h1: {
    ...basicType,
    fontWeight: 700,
    fontSize: '46px',
    lineHeight: '54px',
  },
  h2: {
    ...basicType,
    fontWeight: 700,
    fontSize: '38px',
    lineHeight: '46px',
  },
  h3: {
    ...basicType,
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '38px',
  },
  h4: {
    ...basicType,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
  },
  h5: {
    ...basicType,
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
  },
  body1: {
    ...basicType,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2semibold: {
    ...basicType,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
  },
  body2: {
    ...basicType,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
  caption1: {
    ...basicType,
    fontSize: '12px',
    lineHeight: '20px',
  },
  caption1semibold: {
    ...basicType,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
  },
  caption2: {
    ...basicType,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '18px',
  },
  captionMicro: {
    ...basicType,
    fontWeight: 400,
    fontSize: '8px',
    lineHeight: '12px',
  },
  caption2semibold: {
    ...basicType,
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '18px',
  },
}

export default typography
