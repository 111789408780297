import React, { useRef, useMemo } from 'react'
import dayjs from 'dayjs'
import useElementNav from 'hooks/useElementNav'
import InputBase, {
  InputTypes,
} from 'ui-framework/components/primitives/Input/InputBase'
import Calendar from '.'
import Icon from '../Icon'
import {
  OnChangeHandler,
  ChakraComponentProps,
} from 'ui-framework/common/types'
import { getCallback, getDateValue } from 'helpers/utils'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import AbstractInput from '../AbstractInput'
import { dateFormat } from 'helpers/formats'
import useOnClickOutside from 'hooks/useClickOutside'
import FocusTrap from '../FocusTrap'

type Props = {
  /**
   * Value for controlled input
   */
  value?: Date | null
  /**
   * Restrictions of input.
   */
  defaultDate?: Date | string
  max?: Date
  min?: Date
  /**
   * Name of input.
   */
  name?: string
  /**
   * Type of input.
   */
  type?: InputTypes
  /**
   * Visual state of the input
   */
  error?: string
  /**
   * Disabled state
   * @default false
   */
  disabled?: boolean
  /**
   * read only state
   * @default false
   */
  readonly?: boolean
  /**
   * Label of the input
   */
  label?: string
  /**
   * Placeholder text
   */
  placeholder?: string
  /**
   * Width
   * @default '100%'
   */
  width?: string | undefined
  /**
   * onChange handler
   */
  onChange?: OnChangeHandler<Date>
  errorTooltip?: boolean | object
  /**
   * Preserve height of the label and the message
   */
  fixedHeight?: boolean
} & ChakraComponentProps

/**
 * DateInput component
 */
const DateInput = (props: Props) => {
  const {
    value,
    defaultDate,
    max,
    min,
    name,
    disabled = false,
    readonly = false,
    placeholder,
    error,
    label,
    width = '100%',
    fixedHeight = true,
    onChange,
    errorTooltip,
    size,
  } = props
  const selector = useDisclosure(),
    inputRef = useRef<HTMLInputElement>(),
    popoverRef = useRef<any>(),
    preparedValue = useMemo(() => {
      return getDateValue(value)
    }, [value])

  useOnClickOutside([inputRef, popoverRef], selector.onClose)

  const handleOnChange = value => {
      getCallback(onChange)(value, name)
      selector.onClose()
    },
    handleOnManualChange = value => {
      const addValue = () => getCallback(onChange)(value.toDate(), name),
        minDate = dayjs(min).toDate(),
        maxDate = dayjs(max).toDate()

      if (value && typeof value === 'object') {
        if (min && !max && value.isAfter(minDate)) addValue()
        else if (max && !min && value.isBefore(maxDate)) addValue()
        else if (
          min &&
          max &&
          value.isAfter(minDate) &&
          value.isBefore(maxDate)
        )
          addValue()
        else if (!max && !min) addValue()
      }
    }

  const [handleOnElementEvent, handleOnElementClose] = useElementNav(
    selector,
    popoverRef
  )

  return (
    <Box position="relative" width={width}>
      <Popover
        isOpen={!readonly && !disabled && selector.isOpen}
        closeOnBlur={false}
        closeOnEsc={true}
        initialFocusRef={inputRef as any}
        isLazy
        placement="bottom-start"
      >
        {/* @ts-ignore */}
        <PopoverTrigger>
          <div>
            <AbstractInput
              value={preparedValue}
              errorTooltip={errorTooltip}
              size={size}
              width={width}
              disabled={disabled}
              readonly={true}
              error={error}
              label={label}
              fixedHeight={fixedHeight}
              postfix={
                <Icon size="sm" onClick={selector.onOpen}>
                  calendar
                </Icon>
              }
            >
              <InputBase
                inputRef={inputRef}
                placeholder={!selector.isOpen && placeholder}
                type="date"
                value={
                  preparedValue &&
                  (dayjs(preparedValue).format('MM/DD/YYYY') as any)
                }
                format={selector.isOpen ? dateFormat : undefined}
                name={props.name}
                disabled={props.disabled}
                readonly={props.readonly || !selector.isOpen}
                onChange={handleOnManualChange}
                onFocus={selector.onOpen}
                onKeyDown={handleOnElementEvent}
              />
            </AbstractInput>
          </div>
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={popoverRef} onKeyUp={handleOnElementClose}>
            <Calendar
              value={preparedValue}
              defaultDate={dayjs(
                preparedValue || min || defaultDate || undefined
              )
                .startOf('month')
                .toDate()}
              min={min ? dayjs(min).startOf('day').toDate() : undefined}
              max={max}
              onChange={handleOnChange}
            />
            <FocusTrap targetFocusRef={inputRef} />
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}

export default DateInput
