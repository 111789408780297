import { Flex, useDisclosure } from '@chakra-ui/react'
import { AnyFunction } from 'helpers/utils'
import { ReactNode, useCallback } from 'react'
import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'

const useSelectProfileDialog = (
  users: string[] = [],
  onSelect: AnyFunction
): [AnyFunction, ReactNode] => {
  const listOfUsersDialog = useDisclosure(),
    handleOpenModalOrReturnSingle = useCallback(() => {
      if (!users.length) return
      const singleEmail = users?.length === 1 && users[0]

      if (!singleEmail) listOfUsersDialog.onOpen()
      else {
        onSelect(singleEmail)
        listOfUsersDialog.onClose()
      }
    }, [users, listOfUsersDialog, onSelect])

  return [
    handleOpenModalOrReturnSingle,
    users?.length > 0 && (
      <ModalDialog
        title={`Choose a user`}
        isOpen={listOfUsersDialog.isOpen}
        onClose={listOfUsersDialog.onClose}
        size={'w560'}
      >
        <Flex direction={'column'} align={'center'}>
          {users?.map((email, i) => (
            <Button
              key={email}
              width={'350px'}
              variant={'secondary'}
              margin={'8px'}
              onClick={() => {
                onSelect(email)
                listOfUsersDialog.onClose()
              }}
            >
              {email}
            </Button>
          ))}
        </Flex>
      </ModalDialog>
    ),
  ]
}

export default useSelectProfileDialog
