import { Services } from 'constants/dicti'
import dayjs from 'dayjs'
import {
  CalendarConfig,
  getDateByItems as gdi,
  makeItemsForKey,
} from 'helpers/config/utils'
import { formatFullDate } from 'helpers/formatter'
import { appBuildYear } from 'services/common/config/calendar'

const getDateByItems = gdi(appBuildYear)

const config: CalendarConfig = {
    '2023-12-15': {
      deadlineMessage: formatFullDate(getDateByItems(1, 1)),
      deadlineAction: currentDate =>
        dayjs(currentDate).isSameOrAfter(getDateByItems(1, 0, false)),
    },
    '2024-02-02': {
      deadlineMessage: formatFullDate(getDateByItems(7, 1)),
      deadlineAction: null,
    },
    '2024-02-08': {
      deadlineMessage: formatFullDate(getDateByItems(14, 1)),
    },
    '2024-02-15': {
      deadlineMessage: formatFullDate(getDateByItems(21, 1)),
    },
    '2024-02-22': {
      deadlineMessage: formatFullDate(getDateByItems(25, 1)),
    },
    '2024-02-26': {
      deadlineMessage: null,
    },
  },
  configTADeadlines = makeItemsForKey<Services.TaxAppeal, CalendarConfig>(
    Services.TaxAppeal,
    config
  )

export { configTADeadlines }
