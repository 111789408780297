import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import UploadDropZone from 'ui-framework/components/patterns/UploadDropZone'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useDocumentFiles } from 'hooks/api/documents/useDocumentFiles'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { DocumentType } from 'constants/dicti'

//===================================================
type Props = {
  docName: string
  fileFormatsMessage?: React.ReactNode
  docType: DocumentType
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepUpload component
 */
const StepUpload = ({
  docType,
  docName,
  fileFormatsMessage,
  disabled,
  onChange,
  applyValidation,
  onValidation,
  ...props
}: Props) => {
  const { context } = useServiceWizard()
  const {
      files,
      handleFilesUpload,
      handleFilesDelete,
      handleDownload,
      handleRetryUpload,
    } = useDocumentFiles(context.ref, docType),
    validationData = useMemo(() => ({ files }), [files]),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  return (
    <StepBasic className={props.className} paddingRight="24px">
      <UploadDropZone
        files={files}
        error={hasError('files')}
        docName={docName}
        fileFormatsMessage={fileFormatsMessage}
        onUpload={handleFilesUpload}
        onDelete={handleFilesDelete}
        onRetry={handleRetryUpload}
        onDownload={f => f.filePath && handleDownload(f.filePath)}
        disabled={disabled}
      />
    </StepBasic>
  )
}

export default StepUpload
