import React, { useMemo, useContext, useRef } from 'react'
import Table, { TableProps, TableChildrenFunc } from '../Table'
import { createFakeElement, isCallback } from 'helpers/utils'
import { chakra, ChakraProps, Flex } from '@chakra-ui/react'
import { GroupedTablesContext } from '../GroupedTables'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import InformationTooltipWithLabel from 'ui-framework/components/primitives/InformationTooltipWithLabel'

type TableWithTitleProps = {
  categoryTitle: any
} & TableProps

const TitleCell = chakra('td', {
    baseStyle: {
      position: 'relative',
    },
  }),
  TitleWrapper = chakra(Flex, {
    baseStyle: {
      position: 'absolute',
      h: '100%',
      alignItems: 'center',
      w: `32px`,
      top: 0,
      ':hover': {
        use: {
          fill: 'primary.base',
        },
      },
    },
  }),
  basicTitleParams = {
    transform: 'rotate(-90deg)',
    textStyle: 'body.semibold',
    whiteSpace: 'nowrap',
    as: 'span',
    // height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as ChakraProps,
  getNumberFromString = v => parseFloat(v),
  createTitle = (height: number, text: string) => {
    const fakeElement = createFakeElement(),
      removeFake = () => fakeElement.remove(),
      innerPaddings = 12

    fakeElement.textContent = text

    if (height > fakeElement.offsetWidth + innerPaddings) {
      removeFake()
      return <TextFromParams params={basicTitleParams}>{text}</TextFromParams>
    } else {
      removeFake()
      return (
        <InformationTooltipWithLabel
          infoMessage={text}
          iconWrapperParams={{ transform: basicTitleParams.transform }}
        />
      )
    }
  }

const TableWithTitle = (props: TableWithTitleProps) => {
  const {
      categoryTitle,
      headers,
      rowsHeadingHeight = '0px',
      rowsHeight = '56px',
      items,
      footer,
    } = props,
    tbodyRef = useRef<HTMLTableSectionElement | null>(null),
    tbodyRedChElCount = (tbodyRef?.current as ParentNode)?.childElementCount,
    totalRowCount = useMemo(
      () => (!tbodyRef?.current ? 0 : tbodyRedChElCount),
      [tbodyRedChElCount]
    ),
    titleView = useMemo(() => {
      const title = categoryTitle?.type ? (
        <TextFromParams params={basicTitleParams}>
          {categoryTitle}
        </TextFromParams>
      ) : (
        createTitle(
          getNumberFromString(rowsHeight) * (totalRowCount - 1),
          categoryTitle
        )
      )

      return (
        <TitleCell rowSpan={totalRowCount + 1} className={`nohovered`}>
          <TitleWrapper className={`title`}>{title}</TitleWrapper>
        </TitleCell>
      )
    }, [rowsHeight, categoryTitle, totalRowCount]),
    context = useContext(GroupedTablesContext),
    calculatedHeaders = useMemo(
      () =>
        context?.headers
          ? context?.headers.map(h => ({
              width: h.width,
              text: '',
              noborder: true,
            }))
          : headers,
      [headers, context?.headers]
    )

  return (
    <Table
      headers={calculatedHeaders}
      rowsHeadingHeight={rowsHeadingHeight}
      rowsHeight={rowsHeight}
      footer={footer}
      items={items}
      // http://dev.mgnyconsulting.com:8081/redmine/issues/10881
      // rowsHeadingHeight option is not working in this case.why? ¯\ _(ツ)_ /¯
      // probably emotion cache has a bug
      sx={{
        'thead td, thead th': {
          paddingBottom: '0px !important',
        },
      }}
    >
      <tbody ref={tbodyRef}>
        <>
          {titleView}
          {isCallback(props.children)
            ? items?.map((item, i) =>
                (props.children as TableChildrenFunc)(item, i)
              )
            : props.children}
        </>
      </tbody>
    </Table>
  )
}

export default TableWithTitle
