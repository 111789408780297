import { chakra, VStack } from '@chakra-ui/react'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'
import { useCallback } from 'react'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import ThankYouSrc from './assets/thank-you.jpg'

//====================
const Link = chakra('a', {
  baseStyle: {
    color: 'primary.base',
  },
})

const getThankYouConfig = (supportEmailByService, { title }) => ({
  src: ThankYouSrc,
  title: `Thank you for your interest in MGNY's ${title} Service`,
  description: (
    <VStack spacing={'12px'} align={'left'}>
      <TextFromParams params={{ fontSize: 'unset', textAlign: 'left' }}>
        Our {title} Advisor will reach out to you shortly.
      </TextFromParams>
      <TextFromParams params={{ fontSize: 'unset', textAlign: 'left' }}>
        If you have any questions, please contact us at{' '}
        <Link
          style={{ whiteSpace: 'nowrap' }}
          href={`tel:${SUPPORT_CONTACTS.supportTel}`}
        >
          {SUPPORT_CONTACTS.supportTel}
        </Link>{' '}
        or email us at{' '}
        <Link
          style={{ whiteSpace: 'nowrap' }}
          href={`mailto:${supportEmailByService}`}
        >
          {supportEmailByService}.
        </Link>
      </TextFromParams>
    </VStack>
  ),
})

const useContent = conditionFn =>
  useCallback(
    (serviceType, supportEmailByService, defaultContent) =>
      conditionFn(serviceType)
        ? getThankYouConfig(supportEmailByService, defaultContent)
        : defaultContent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conditionFn, getThankYouConfig]
  )

export default useContent
