import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { integerPositiveThousands } from 'helpers/formats'
import IntervalBar from 'ui-framework/components/patterns/IntervalBar'
import {
  OccupancyPeriod,
  Occupant,
  RpieFormData,
} from 'hooks/api/rpie/RpieFormData'
import { VStack } from '@chakra-ui/layout'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods, getNameAndErrorProps } from 'helpers/utils'
import { getDictiName } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { getForamttedDateRange } from 'pages/Services/Rpie/RpieFormPage/wizard/common'
import StorefrontUnitOccupantsIntervalBar from 'pages/Services/Rpie/RpieFormPage/steps/storefront/StorefrontUnitOccupantsIntervalBar'

//===================================================
type Props = {
  unitNumber: number
  occupantNumber: number
  grossRentAmount: number
  baseRentAmount: number
  grossRentAmountRequired: boolean
  occupantTypeCS: string
  _occStartDate: string
  _occEndDate: string
  _occLabel: string
  occupants: Occupant[]
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

/**
 * StepStorefrontUnitOccupantRentInformationStep component
 */
const StepStorefrontUnitOccupantRentInformationStep = ({
  unitNumber,
  occupantNumber,
  grossRentAmount,
  baseRentAmount,
  occupantTypeCS,
  grossRentAmountRequired,
  _occStartDate,
  _occEndDate,
  _occLabel,
  occupants,

  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context),
    dateInterval = useMemo(
      () => getForamttedDateRange(_occStartDate, _occEndDate),
      [_occStartDate, _occEndDate]
    ),
    validationData = useMemo(
      () => ({
        grossRentAmount,
        baseRentAmount,
        occupantTypeCS,
        grossRentAmountRequired,
      }),
      [grossRentAmount, baseRentAmount, occupantTypeCS, grossRentAmountRequired]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px" w="680px">
        <StorefrontUnitOccupantsIntervalBar
          occupants={occupants}
          occupantNumber={occupantNumber}
          reportingYear={reportingYear}
        />
        <HelpTarget name="grossRentAmount">
          <Input
            label={
              <>
                Annual Gross Rent Paid During <b>{dateInterval}</b>
              </>
            }
            type="number"
            prefix="$"
            format={integerPositiveThousands}
            value={grossRentAmount}
            disabled={disabled}
            onChange={onChange}
            {...nameAndErrorProps('grossRentAmount')}
          />
        </HelpTarget>
        {grossRentAmountRequired && (
          <HelpTarget name="baseRentAmount">
            <Input
              label={
                <>
                  Annual Base Rent Paid During <b>{dateInterval}</b>
                </>
              }
              type="number"
              prefix="$"
              format={integerPositiveThousands}
              value={baseRentAmount}
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('baseRentAmount')}
            />
          </HelpTarget>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepStorefrontUnitOccupantRentInformationStep
