import React from 'react'

//===================================================
const HelpGreatWorkMoveToTheNext = {
  title: 'Go to Next Step',
  description: (
    <p>
      Great work! If everything looks correct, move to the <b>next step.</b>
    </p>
  ),
}

export default HelpGreatWorkMoveToTheNext
