import { ComponentStyleConfig } from '@chakra-ui/theme'
import { getTabFocusSelectors } from 'helpers/utils'

const CheckboxStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'title', 'checkbox'],
  // Styles for the base style
  baseStyle: props => {
    const { disabled, hasTitle, error } = props

    return {
      wrapper: {
        position: 'relative',
        cursor: disabled ? 'default' : ' pointer',
        alignItems: 'center',
        'input[type="checkbox"]': {
          opacity: '0',
          display: 'none',
        },
        '[tabindex="0"]': getTabFocusSelectors({
          borderRadius: '3px',
        }),
      },
      title: {
        display: !hasTitle && 'none',
        color: disabled ? 'fontandicongray' : 'fontnavy',
      },
      checkbox: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      },
      tooltip: {
        borderRadius: '8px',
        padding: '8px',
        textStyle: `caption.regular`,
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
      wrapperInner: {
        position: 'absolute',
        right: '-24px',
      },
      message: {
        display: !error && 'none',
        position: 'absolute',
        top: '16px',
        textStyle: 'caption.regular',
        width: 'max-content',
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
    }
  },
  // Styles for the size variations
  sizes: {
    md: props => ({
      title: {
        textStyle: 'body.regular',
        marginLeft: '12px',
      },
      checkbox: {
        width: '16px',
        height: '16px',
        borderRadius: '3px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {
    normal: props => {
      const { disabled, checked, indeterminate, error } = props

      return {
        wrapper: {
          _hover: {
            '& > *': {
              borderColor: indeterminate
                ? 'transparent'
                : disabled
                ? 'primary.disabled'
                : error
                ? 'red.base'
                : 'primary.base',
            },
          },
        },
        checkbox: {
          border: '1px solid',
          borderColor: indeterminate
            ? 'transparent'
            : error
            ? 'red.base'
            : disabled
            ? 'primary.disabled'
            : checked
            ? 'primary.base'
            : 'fontandicongray',
          bgColor:
            indeterminate || (checked && !error && !disabled)
              ? 'primary.base'
              : disabled
              ? 'primary.disabled'
              : checked && error
              ? 'red.base'
              : checked
              ? 'secondary.sideMenu'
              : error
              ? 'red.opacity'
              : 'transparent',
          '.icon': {
            svg: {
              fill: indeterminate || checked ? 'white100' : 'transparent',
            },
          },
        },
      }
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}

export default CheckboxStyle
