import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  APP_VERSION,
  getObjectDifference,
  isCallback,
  isNullOrWhiteSpace,
} from 'helpers/utils'
import { PropertyUsage, UseByApplicant, PropertyType } from 'constants/dicti'
import { get, set } from 'object-path-immutable'
import useStatePromise from 'hooks/useStatePromise'
import { cloneDeep } from 'lodash-es'

export type OrNull<T> = T | null
export type Path = string | ReadonlyArray<number | string>
export type ServiceFormDataHook<T> = (
  valueProp: OrNull<T>,
  currentProperty: OrNull<{ [key: string]: any }>
) => [OrNull<T>, Dispatch<SetStateAction<OrNull<T>>>]

export type FormDataHookChangeHandler<T> = (
  valueToSet: T,
  prevState?: OrNull<T>
) => T

export type FormDataHookChangeHandlers<T> = FormDataHookChangeHandler<T>[]

export const addPortionUsedByApplicantQuestion = (
  propertyType,
  propertyUsage,
  useByApplicant
) =>
  propertyUsage === PropertyUsage.Mixed &&
  useByApplicant === UseByApplicant.Part &&
  propertyType !== PropertyType.Coop &&
  propertyType !== PropertyType.CondoRelation7,

  addUseByApplicantQuestion = (propertyType, propertyUsage) =>
    propertyUsage !== PropertyUsage.Vacant &&
    // propertyType !== PropertyType.Hotel && // https://mgny.atlassian.net/browse/TP-15657
    propertyType !== PropertyType.Coop &&
    propertyType !== PropertyType.CondoRelation7,

  addWasAnyRentalIncomeQuestion = (
    propertyUsage,
    useByApplicant,
    propertyTypeCS
  ) =>
    propertyUsage !== PropertyUsage.Vacant &&
    useByApplicant !== UseByApplicant.Entire &&
    propertyTypeCS !== PropertyType.CondoRelation7,

  isResOrNonRes = (propertyUsageCS: PropertyUsage) =>
    [PropertyUsage.NonResidential, PropertyUsage.Residential].includes(
      propertyUsageCS
    ),

  autoFilling = (
    prevValue: object,
    currentValue: object,
    dependParams: { [key: string]: string[] }
  ) => {
    const changingObj = getObjectDifference(prevValue, currentValue)
    Object.entries(dependParams).forEach(([totalKey, dependFlds]) =>
      dependFlds.forEach(p => {
        if (get(changingObj, p) !== undefined) {
          const fillingField = get(currentValue, p),
            dependFldPath = dependFlds.find(f => f !== p),
            { path, fldKey } = getSplitedPathParams(dependFldPath || ''),
            total = get(currentValue, totalKey),
            newV =
              total > fillingField ? Number(total) - Number(fillingField) : 0

          changeDepentValue(
            get(currentValue, path),
            true,
            fldKey as string,
            newV
          )
        }
      })
    )
  },
  getSplitedPathParams = (p: string) => {
    const path = p.split('.')
    return {
      path,
      fldKey: path.pop(),
    }
  },
  changeDepentValue = (
    ownObj: object,
    condition: boolean,
    dependFldKey: string,
    newValue: any = null
  ) => condition && (ownObj[dependFldKey] = newValue),
  conditionalSetW = <T = object>(
    ownObj: WrappedObject<T>,
    condition: boolean,
    path: Path,
    newValue: any = null
  ) => {
    condition && ownObj.set(path, newValue)
    return ownObj
  },
  //TODO: need to think about concept
  autoFillingW = <T>(
    curr: WrappedObject<T>,
    prev: T,
    totalPath: Path,
    dependPairPaths: [Path, Path]
  ) => {
    const getPath = (path: Path): Path =>
      Array.isArray(path) ? path : (path as string).split('.'),
      splitPath = (path: Path): { root: string | number; path: Path } => ({
        root: getPath(path)[0],
        path: getPath(path).slice(1),
      }),
      totalP = splitPath(totalPath),
      dependPairPathsP = dependPairPaths.map(splitPath),
      isSameRoot =
        totalP.root === dependPairPathsP[0].root &&
        totalP.root === dependPairPathsP[1].root

    if (!isSameRoot) throw new Error('Not same root')

    return curr.update([totalP.root], c => {
      const total = get(c, totalP.path),
        d1c = get(c, dependPairPathsP[0].path),
        d1p = get(prev, dependPairPaths[0]),
        d2c = get(c, dependPairPathsP[1].path),
        changedIndex = d1c !== d1p ? 0 : 1,
        invert = idx => (idx === 0 ? 1 : 0),
        value = total - (changedIndex === 0 ? d1c : d2c),
        valuePath = dependPairPathsP[invert(changedIndex)].path

      return set(c, valuePath, value)
    })
  },
  conditionalSetOrRestore =
    <T>(defaults: T, wrapped: WrappedObject<T>) =>
      (current: T, condition: boolean, path: Path, newValue: any = null) =>
        // set if condition or path value are empty
        conditionalSetW(
          wrapped,
          condition || isNullOrWhiteSpace(get(current, path)),
          path,
          condition ? newValue : get(defaults, path, newValue)
        )

const useFormDataHook = <FormDataType>(
  valueProp: OrNull<FormDataType>,
  handlers: FormDataHookChangeHandlers<FormDataType> = []
): [OrNull<FormDataType>, Dispatch<SetStateAction<OrNull<FormDataType>>>] => {
  const [value, valueSet] = useState<OrNull<FormDataType>>(valueProp)

  const processValue = useCallback(
    value => (prevState: OrNull<FormDataType>) => {
      if (!value) return value

      const valueToSet = isCallback(value) ? value(prevState) : value

      if (valueToSet) {
        valueToSet.buildVersion = APP_VERSION
        valueToSet.userAgent = navigator.userAgent
        return handlers.reduce((value, h) => h(value, prevState), valueToSet)
      }
      return valueToSet
    },
    [handlers]
  )

  const handleSetValue: Dispatch<SetStateAction<OrNull<FormDataType>>> =
    useCallback(v => valueSet(processValue(v)), [processValue, valueSet])

  useEffect(() => {
    if (!value) handleSetValue(valueProp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp])

  //@ts-ignore
  handleSetValue.then = () => { }
  return [value, handleSetValue]
}

export default useFormDataHook
