import { ReactNode } from 'react'
import { Image } from '@chakra-ui/react'
import DiscussBg from './discussBg.png'
import AnswerBg from './answerBg.png'

export const DISCOUNT_ANSWER = 'CS_DISCOUNT',
  THINK_ABOUT_IT_ANSWER = 'CS_THINK_ABOUT_IT',
  DISCUSS_ANSWER = 'CS_DISCUSS'

export type Answer =
  | typeof DISCOUNT_ANSWER
  | typeof THINK_ABOUT_IT_ANSWER
  | typeof DISCUSS_ANSWER

type Item = string | ReactNode
type ContentItem = {
  image?: ReactNode
  title: Item
  body: Item
  actionText: string
}
type Content = {
  [key: string]: ContentItem
}

const getImage = (src: string): ReactNode => (
  <Image src={src} height={'300px'} mt={'-5px'} />
)

//TODO: refactor this
const content: Content = {
  [DISCOUNT_ANSWER]: {
    image: getImage(DiscussBg),
    title: 'Tax Appeal + RPIE Bundle Discount',
    body: (
      <>
        We are extending a special offer to our Tax Appeal clients.
        <br />
        <b>Save 20% on RPIE filing fee</b>. MGNY can streamline your RPIE filing
        process utilizing much of the information provided for Tax Appeal.
      </>
    ),
    actionText: "Let's Discuss",
  },
  [THINK_ABOUT_IT_ANSWER]: {
    image: getImage(AnswerBg),
    title: 'Thank you!',
    body: 'Reach out if you need RPIE compliance help this year.',
    actionText: 'Back to Property List',
  },
  [DISCUSS_ANSWER]: {
    image: getImage(AnswerBg),
    title: (
      <>
        Thank you for your interest in <br />
        MGNY's RPIE Service
      </>
    ),
    body: 'Our RPIE Advisor will reach out to you shortly.',
    actionText: 'Back to Property List',
  },
}

const useModalContent = (answer: Answer): ContentItem => content[answer]

export default useModalContent
