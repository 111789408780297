import { ReactNode, memo } from 'react'
import { chakra, Box, useMultiStyleConfig, Flex } from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'
import Icon from '../Icon'
import isEqual from 'lodash/isEqual'

type AttentionBoxProps = {
  title?: string | ReactNode
} & ChakraComponentProps

const AttentionBox = ({
  title = 'Attention!',
  ...props
}: AttentionBoxProps) => {
  const style = useMultiStyleConfig('MGNYAttentionBox', props)
  return (
    <Flex sx={style.wrapper} {...props}>
      <Flex sx={style.warningMark}>
        <Icon color="white100">attention</Icon>
      </Flex>
      <Box sx={style.contentWrapper} flexGrow={1}>
        {title && <Box sx={style.title}>{title}</Box>}
        {props.children}
      </Box>
    </Flex>
  )
}

export default memo(chakra(AttentionBox) as any, isEqual)
