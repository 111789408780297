import { useMemo } from 'react'
import { getBBLWithAddressString } from 'helpers/business'
import { useApplications } from './useApplications'
import { ApiObject } from 'helpers/apiHelper'
import { Services } from 'constants/dicti'

export const PRECONF_FILTER_PL_TC150ONLY = 'PRECONF_FILTER_PL_TC150ONLY'

const usePropertyList = (serviceType: Services): ApiObject<any> => {
  const { data, isPending, error } = useApplications(serviceType),
    applications = useMemo(() => {
      if (data)
        // TODO: make common logic to transform application data
        return data.map(q => {
          return {
            ...q,
            $bbl: getBBLWithAddressString(q),
          }
        })
    }, [data])

  return {
    data: applications,
    isPending,
    error,
  }
}

export default usePropertyList
