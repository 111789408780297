import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import ServicePropertyList from 'ui-framework/components/patterns/PropertyList/ServicePropertyList'
import RPIEOnboardingBody from 'services/rpie/onboarding/index'
import {
  URL_HOME,
  URL_SERVICERPIEFORM,
  URL_SERVICERPIEFORMSUMMARY,
} from 'constants/URLS'
import useRpieStatus from 'services/rpie/useRpieStatus'
import { Services } from 'constants/dicti'

//===================================================
type RpieListPageProps = {} & BaseComponentProps

/**
 * RpieListPage component
 */
const RpieListPage = (props: RpieListPageProps) => {
  return (
    <ServicePropertyList
      className={props.className}
      backLink={URL_HOME}
      backLinkText={`Back to Home`}
      formLink={URL_SERVICERPIEFORM}
      formSummaryLink={URL_SERVICERPIEFORMSUMMARY}
      useServiceStatus={useRpieStatus}
      onboarding={{
        template: <RPIEOnboardingBody />,
        styles: { minWidth: '720px' },
      }}
      serviceType={Services.RPIE}
    ></ServicePropertyList>
  )
}

export default RpieListPage
