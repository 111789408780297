import firebaseApp from 'helpers/firebaseInit'
import {
  useEffect,
  useCallback,
  createContext,
  useReducer,
  useMemo,
} from 'react'
import pick from 'lodash/pick'
import { FunctionNames, Services } from 'constants/dicti'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useCheckedContext } from 'hooks/useApplicationContext'
import useEffectiveProfileEmail from 'hooks/useEffectiveProfileEmail'

const flushInvites = firebaseApp
  .functions()
  .httpsCallable(FunctionNames.FLUSH_INVITES)

const auth = getAuth(firebaseApp),
  db = getFirestore(firebaseApp)

export type User = {
  uid: string | null
  displayName: string | null
  email: string
  isAnonymous: boolean
  availableAgreementServices?: Array<Services>
}

export type UserProfile = {
  id?: string
  firstName: string
  lastName: string
  email: string
  availableAgreementServices?: Services[]
  roles: Array<string>
}

//===================================================
type AuthProviderState = {
  isLogged: boolean
  isPending: boolean
  error: any | null
  user?: User
  profile?: UserProfile
  effectiveProfile?: UserProfile
  showUnowned: boolean
  logout: () => void
  effectiveProfileReset: (email?: string) => void
}
const defaultState: AuthProviderState = {
  isLogged: false,
  isPending: true,
  error: null,
  user: undefined,
  profile: undefined,
  effectiveProfile: undefined,
  showUnowned: false,
  logout: () => {},
  effectiveProfileReset: email => {},
}
const AuthProviderContext = createContext<AuthProviderState>(defaultState)

enum ActionsKind {
  SET_IS_LOGGED,
  SET_IS_PENDING,
  SET_ERROR,
  SET_USER,
  SET_PROFILE,
  SET_EPROFILE,
  SET_UNOWNED,
  LOGOUT,
}
type Actions<A, P> = { action: A; payload: P }
type SetUnownedAction = Actions<ActionsKind.SET_UNOWNED, boolean>
type SetIsLoggedAction = Actions<ActionsKind.SET_IS_LOGGED, boolean>
type SetIsPendingAction = Actions<ActionsKind.SET_IS_PENDING, boolean>
type SetErrorAction = Actions<ActionsKind.SET_ERROR, any>
type SetUserAction = Actions<ActionsKind.SET_USER, User | undefined>
type SetProfileAction = Actions<
  ActionsKind.SET_PROFILE,
  UserProfile | undefined
>
type SetEffProfileAction = Actions<
  ActionsKind.SET_EPROFILE,
  UserProfile | undefined
>
type LogoutAction = Actions<ActionsKind.LOGOUT, undefined>

type AuthStateReducerAction =
  | SetIsLoggedAction
  | SetIsPendingAction
  | SetErrorAction
  | SetUserAction
  | SetProfileAction
  | SetEffProfileAction
  | LogoutAction
  | SetUnownedAction

const authStateReducer = (
  state: AuthProviderState,
  { action, payload }: AuthStateReducerAction
): AuthProviderState => {
  switch (action) {
    case ActionsKind.SET_IS_LOGGED: {
      return { ...state, isLogged: payload }
    }
    case ActionsKind.SET_IS_PENDING: {
      return { ...state, isPending: payload }
    }
    case ActionsKind.SET_ERROR: {
      return { ...state, error: payload }
    }
    case ActionsKind.SET_USER: {
      return { ...state, user: payload }
    }
    case ActionsKind.SET_PROFILE: {
      return { ...state, profile: payload }
    }
    case ActionsKind.SET_EPROFILE: {
      return { ...state, effectiveProfile: payload }
    }
    case ActionsKind.SET_UNOWNED: {
      return { ...state, showUnowned: payload }
    }
    case ActionsKind.LOGOUT: {
      return { ...defaultState, isPending: false }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

/**
 * AuthProvider component
 */
type AuthProviderProps = {} & BaseComponentProps
const AuthProvider = (props: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authStateReducer, defaultState),
    effectiveProfileEmail = useEffectiveProfileEmail(state.profile),
    login = useCallback(u => {
      if (u) {
        const newuser = pick(u, ['uid', 'displayName', 'email', 'isAnonymous'])
        // console.log('on tkn chcg', newuser)
        // signin
        dispatch({ action: ActionsKind.SET_USER, payload: newuser })

        flushInvites()
      } else {
        // console.log('on tkn chcg', u)
        // signout
        dispatch({ action: ActionsKind.SET_USER, payload: undefined })
        dispatch({ action: ActionsKind.SET_IS_PENDING, payload: false })
        // userProfileSet(undefined)
      }
    }, []),
    logout = useCallback(
      () =>
        signOut(auth).then(() => {
          effectiveProfileEmail.reset()
          dispatch({ action: ActionsKind.LOGOUT, payload: undefined })
        }),
      []
    ),
    resultState = useMemo(
      () => ({
        ...state,
        logout,
        effectiveProfileReset: effectiveProfileEmail.reset,
      }),
      [state, logout, effectiveProfileEmail.reset]
    )

  useEffect(() => {
    return onAuthStateChanged(auth, login)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onProfileSnapshot = useCallback(
    (email, action) =>
      onSnapshot(doc(db, 'profiles', email), snapshot => {
        if (!snapshot.exists) {
          // console.error('Profile was removed!')
        } else {
          const profile: UserProfile = {
            id: snapshot.id,
            ...(snapshot.data() as UserProfile),
          }

          // console.log('on prf chcg', profile)

          dispatch({ action, payload: profile })
        }
      }),
    []
  )

  useEffect(() => {
    if (state.user?.email && state.user?.uid) {
      // console.log('try to setup snapshot listener', user)

      return onProfileSnapshot(state.user.email, ActionsKind.SET_PROFILE)
    }
  }, [onProfileSnapshot, state.user])

  useEffect(() => {
    if (
      !effectiveProfileEmail.email ||
      effectiveProfileEmail.email === 'null' ||
      effectiveProfileEmail.email === state.user?.email
    ) {
      dispatch({ action: ActionsKind.SET_EPROFILE, payload: state.profile })
      // set show unowned applications
      dispatch({
        action: ActionsKind.SET_UNOWNED,
        payload: effectiveProfileEmail.email === 'null',
      })
      return
    }
    // console.log('try to setup snapshot listener', user)
    return onProfileSnapshot(
      effectiveProfileEmail.email,
      ActionsKind.SET_EPROFILE
    )
  }, [
    effectiveProfileEmail.email,
    onProfileSnapshot,
    state.profile,
    state.user,
  ])

  useEffect(() => {
    const isL = !!state.user && !!state.profile
    dispatch({
      action: ActionsKind.SET_IS_LOGGED,
      payload: isL,
    })
    if (isL) dispatch({ action: ActionsKind.SET_IS_PENDING, payload: false })
  }, [state.isPending, state.profile, state.user])

  return (
    <AuthProviderContext.Provider value={resultState}>
      {props.children}
    </AuthProviderContext.Provider>
  )
}

const useAuth = () => useCheckedContext<AuthProviderState>(AuthProviderContext)

export { AuthProvider }

export default useAuth
