import { AnyFunction } from 'helpers/utils'
import { ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import useConcurentUpdateNotification from 'ui-framework/components/patterns/Wizard/useConcurentUpdateNotification'
import useConcurrentEditModal from 'ui-framework/components/patterns/Wizard/useConcurrentEditModal'


const useConcurrentEdit = (
  isFinished,
  updatedOutside,
  autosaveEnabled
): [ReactNode, AnyFunction] => {
  useConcurentUpdateNotification(updatedOutside)

  const navigate = useNavigate(),
    refresh = () => navigate(0),
    [concurrentEditModal, concurrentEditModalOpen] =
      useConcurrentEditModal(isFinished),
    checkConcurent = useCallback(
      fn =>
        (...args) => {
          if (updatedOutside && autosaveEnabled) {
            concurrentEditModalOpen(() => fn(...args), refresh)
          } else fn(...args)
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [updatedOutside, autosaveEnabled]
    )
  return [concurrentEditModal, checkConcurent]
}

export default useConcurrentEdit