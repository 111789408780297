import { useEffect } from 'react'
import useRegisterEvent from './useRegisterEvent'

const useRegisterStepViewAnalytics = stepId => {
  const registerStepView = useRegisterEvent('step_view')
  useEffect(
    () => {
      stepId && registerStepView({ step_id: stepId })
    },
    [stepId, registerStepView]
  )
}

export default useRegisterStepViewAnalytics
