import React, { useMemo, useState, useCallback, ReactNode } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'

import { set } from 'object-path-immutable'

import { VStack, chakra, ChakraProps, Flex, HStack } from '@chakra-ui/react'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Table from 'ui-framework/components/patterns/Table'
import Empty from 'ui-framework/components/primitives/Empty'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import Button from 'ui-framework/components/primitives/Button'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import {
  AnyFunction,
  getDownloadLink,
  getFilteredDataByFieldAndCondition,
  isCallback,
} from 'helpers/utils'
import { nanoid } from 'nanoid'
import { TableHeaderItems } from '../../Table/TableHeaders'
import { useMyDocuments } from 'hooks/api/my/MyDocumentsContext'
import { DocFile } from 'hooks/api/documents/useGetDocs'

//==================================================

type Child = {
  name?: string | ReactNode | Array<string | ReactNode>
  value?: any
  hidden?: boolean
  filesId?: string
  isTotal?: true
}

export type Section = {
  id: string | number | null
  title: string | ReactNode
  hidden?: boolean
  childrens: Child[]
  expanded?: boolean
  maxContent?: boolean
}

type SummaryTableProps = {
  data?: object
  context?: any
  sectionsConfig: AnyFunction | Section[]
  fullSize?: boolean
  withoutTitleRow?: boolean
  changeStep?: AnyFunction
} & BaseComponentProps

const StyledCell = chakra('td', {
    baseStyle: {
      bgColor: '#F9FAFF',
      p: '0 !important',
      h: '56px',
    },
  }),
  baseLabelStyle = {
    textStyle: 'body.regular',
    color: 'fontandicongray',
    textAlign: 'left',
  } as ChakraProps,
  baseFooterStyle = {
    textStyle: 'body.semibold',
    color: 'fontnavy',
    textAlign: 'right',
  } as ChakraProps,
  baseValueStyle = {
    textStyle: 'body.regular',
    color: 'fontnavy',
    textAlign: 'right',
  } as ChakraProps,
  createHeaders = isFullSize => [
    {
      text: '',
      width: isFullSize ? 0 : '54px',
      noborder: true,
    },
    {
      text: '',
      width: '31%',
      noborder: true,
    },
    {
      text: '',
      width: '31%',
      noborder: true,
    },
    {
      text: '',
      width: '38%',
      noborder: true,
    },
    {
      text: '',
      width: isFullSize ? 0 : '24px',
      noborder: true,
    },
  ],
  isDefined = v => v !== undefined,
  checkValue = value =>
    value === 0
      ? 0
      : isDefined(value)
      ? value || <Empty type="longdash" />
      : value,
  getDisplayItem =
    styles =>
    (val: any, isValue: boolean = false, isTotal?: boolean) => {
      const styleParams = isValue
          ? { ...(isTotal ? baseFooterStyle : baseValueStyle) }
          : { ...(isTotal ? baseFooterStyle : baseLabelStyle) },
        createItems = (v, i, arr) => {
          const textParams = {
              textAlign: arr.length - 1 === i ? 'right' : 'left',
              w: !i ? '30%' : '20%',
              ...(styles[i] || styles),
            },
            displayParams = isValue
              ? { ...styleParams, ...textParams }
              : { ...styleParams, ...textParams }

          return (
            <TextFromParams params={displayParams as ChakraProps}>
              {checkValue(v)}
            </TextFromParams>
          )
        }

      return Array.isArray(val) ? (
        val.map(createItems)
      ) : (
        <TextFromParams params={{ ...styleParams, ...(styles[0] || styles) }}>
          {checkValue(val)}
        </TextFromParams>
      )
    }

/**
 * SummaryTable component
 */
const SummaryTable = ({
  data,
  context,
  sectionsConfig,
  fullSize = false,
  withoutTitleRow = false,
  ...props
}: SummaryTableProps) => {
  const [sections, expandSectionSet] = useState(
      getFilteredDataByFieldAndCondition(
        isCallback(sectionsConfig)
          ? (sectionsConfig as AnyFunction)(context, data, props.changeStep)
          : sectionsConfig,
        'hidden',
        true
      )
    ),
    headers = useMemo(() => createHeaders(fullSize), [fullSize]),
    {
      data: { all: allDocuments },
    } = useMyDocuments(),
    documents = useMemo(() => {
      const current = allDocuments?.find(doc => doc.parentId === context.id)

      return current ? [current] : []
    }, [allDocuments, context.id]),
    files = useMemo(
      () =>
        (documents || []).reduce((acc, docs) => {
          for (const doc of docs.documents) {
            acc[doc.typeCS] = doc.files
          }
          return acc
        }, {} as { [key: string]: DocFile[] }),
      [documents]
    ),
    toggle = useCallback(
      (section, key) => () =>
        expandSectionSet(prev =>
          set(prev, [key, 'expanded'], !section.expanded)
        ),
      [expandSectionSet]
    ),
    createFilesView = useCallback(
      files => (
        <>
          {files.map(f => (
            <Button
              key={f.fullPath}
              variant="textPrimary"
              size="sm"
              height="auto"
              onClick={() => getDownloadLink(f.filePath)}
              mt="8px"
            >
              - {f.fileName}
            </Button>
          ))}
        </>
      ),
      []
    ),
    createItems = useCallback(
      parent => (child, i, arr) => {
        const isLastItem = arr.length - 1 === i,
          { name, value, filesId, isTotal = false } = child,
          { maxContent = false, childrenStyles = [] } = parent,
          isTableView =
            (isDefined(name) && !isDefined(value)) ||
            (isDefined(value) && !isDefined(name))

        return (
          <tr key={nanoid()}>
            {isLastItem ? <td></td> : <CellWithoutBorder />}
            <td
              colSpan={
                maxContent || (fullSize && isTableView) ? headers.length - 2 : 2
              }
            >
              <Flex alignItems="center" justifyContent="space-between">
                {isDefined(name) && getDisplayItem(childrenStyles)(name)}
                {isDefined(value) &&
                  getDisplayItem(childrenStyles)(value, true, isTotal)}
              </Flex>
              {files[filesId] && createFilesView(files[filesId])}
            </td>
            {!maxContent && <td colSpan={headers.length - 4} />}
            {isLastItem ? <td></td> : <CellWithoutBorder />}
          </tr>
        )
      },
      [files, createFilesView, fullSize, headers.length]
    ),
    createRow = useCallback(
      (parent, i: number) => {
        return (
          <>
            {!withoutTitleRow && (
              <tr>
                <StyledCell />
                <StyledCell colSpan={2}>
                  <HStack>
                    <TextFromParams
                      params={{
                        ...baseFooterStyle,
                        textAlign: 'left',
                        verticalAlign: 'middle',
                      }}
                    >
                      {parent.title}
                    </TextFromParams>
                    <Button
                      variant="textPrimary"
                      sx={{
                        '@media print': {
                          display: 'none',
                        },
                      }}
                      onClick={toggle(parent, i)}
                    >
                      {parent.expanded ? 'Show' : 'Hide'}
                    </Button>
                  </HStack>
                </StyledCell>
                <StyledCell colSpan={headers.length - 3} />
              </tr>
            )}
            {!parent.expanded &&
              getFilteredDataByFieldAndCondition(
                parent.childrens,
                'hidden',
                true
              ).map(createItems(parent))}
          </>
        )
      },
      [toggle, createItems, headers.length, withoutTitleRow]
    ),
    content = useMemo(() => sections.map(createRow), [sections, createRow]),
    styles = {
      borderTop: '1px solid',
      borderBottom: sections[sections.length - 1]?.expanded
        ? '1px solid'
        : 'none',
      borderColor: 'secondary.divider',
    }

  return (
    <StepBasic className={props.className} pl="0">
      <VStack spacing="0" align="left">
        {sections.length > 0 && (
          <Table
            styles={styles}
            headers={headers as TableHeaderItems}
            items={sections}
            rowsHeadingHeight="0px"
          >
            <tbody>{content}</tbody>
          </Table>
        )}
      </VStack>
    </StepBasic>
  )
}
// SummaryTable.whyDidYouRender = true

export default SummaryTable
