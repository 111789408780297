import { useState, useCallback } from 'react'
import { Text, Tooltip, chakra, TooltipProps } from '@chakra-ui/react'
import BaseComponentProps from 'common/BaseComponentProps'
import { createFakeElement } from 'helpers/utils'

export type TextWithTooltipProps = {
  tooltipProps?: Omit<TooltipProps, 'children'>
  tooltipForTruncated?: true
} & BaseComponentProps

const TextWithTooltip = (props: TextWithTooltipProps) => {
  const { children, tooltipProps, tooltipForTruncated = false, ...rest } = props
  const [message, messageSet] = useState<string | null>(null)
  const refCallback = useCallback(
    elem => {
      const fakeElement = createFakeElement()

      if (elem) {
        const textContent = elem?.childNodes[0]?.value || elem.textContent
        fakeElement.textContent = textContent

        fakeElement.offsetWidth > elem.clientWidth + 1
          ? message !== textContent && messageSet(textContent)
          : message && messageSet(null)

        fakeElement.remove()
      }
    },
    [message]
  )

  return (
    <Tooltip
      isLazy
      hasArrow
      bg={'white100'}
      color={'fontnavy'}
      textStyle={'body.regular'}
      p={'8px'}
      borderRadius={'6px'}
      textTransform={'capitalize'}
      placement={'top'}
      label={message}
      {...tooltipProps}
    >
      <Text
        isTruncated
        ref={tooltipForTruncated ? refCallback : null}
        {...rest}
      >
        {children}
      </Text>
    </Tooltip>
  )
}

export default chakra(TextWithTooltip)
