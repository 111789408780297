import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods } from 'helpers/utils'

//===================================================
type HelpStepSFIntervalsProps = {} & BaseComponentProps

/**
 * HelpStepSFIntervals component
 */
const HelpStepSFIntervals = (props: HelpStepSFIntervalsProps) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context)

  return (
    <>
      <p>Select one of the following occupancy types:</p>
      <p>
        <b>Tenant </b> - if unit was rented to a tenant
      </p>
      <p>
        <b> Owner </b> - if unit was occupied by an owner or a related party
      </p>
      <p>
        <b> Vacant </b> - if unit was vacant
      </p>
      <p>
        After the occupancy type is selected, specify the corresponding
        occupancy interval for that occupancy type.
      </p>

      <p>
        <b> EXAMPLE: </b>
      </p>
      <ul>
        <li>
          <b>
            Occupancy Type <u>Tenant</u> from January 1, {reportingYear} to
            March 15, {reportingYear}
          </b>
        </li>
        <li>
          <b>
            Occupancy Type <u>Vacant</u> from March 16, {reportingYear} to
            December 31, {reportingYear}
          </b>
        </li>
      </ul>
    </>
  )
}

export default HelpStepSFIntervals
