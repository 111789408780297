import { ComponentStyleConfig } from '@chakra-ui/theme'
import { getTabFocusSelectors } from 'helpers/utils'

const LinkStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['link'],
  baseStyle: props => ({
    link: {
      textStyle: 'body.regular',
      color: props.disabled ? 'black30' : 'primary.base',
      textDecoration: props.decorate ? 'underline' : 'none',
      cursor: props.disabled ? 'text' : 'pointer',
      pointerEvents: props.disabled ? 'none' : '',
      transition: 'none',
      ':visited': {
        color: props.disabled ? 'black30' : '',
      },
      ':hover': {
        boxShadow: 'unset',
        textDecoration: props.decorate ? 'underline' : 'none',
      },
      ':focus': {
        boxShadow: !props.decorate ? 'none' : undefined,
      },
      ...getTabFocusSelectors({
        boxShadow: 'unset',
      }),
      '&:has(>button)': {
        textDecoration: 'none',
      },
    },
  }),

  // The size styles for each part
  sizes: {
    xs: {
      link: {
        textStyle: 'caption.regular',
      },
    },
    sm: {
      link: {
        textStyle: 'caption.semibold',
      },
    },
    md: {},
    lg: {
      link: {
        textStyle: 'subtitle',
      },
    },
  },

  variants: {
    menuPrimary: {
      link: {
        display: 'flex',
        height: '36px',
        width: '247px',
        color: 'fontnavy',
        alignItems: 'center',
        borderRadius: '4px',
        boxShadow: 'unset',
        ':hover, &.active': {
          color: 'primary.base',
          backgroundColor: 'secondary.base',
          '& .icon': {
            fill: 'primary.base',
          },
        },
      },
    },
  },

  // The default `size` or `variant` values
  defaultProps: {
    decorate: true,
    size: 'md',
  },
}

export default LinkStyle
