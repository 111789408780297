import React, { useState } from 'react'
import { getFullNameByProfile, keyboardEventListener } from 'helpers/utils'

import useAuth from 'hooks/useAuth'
import Icon from '../Icon'
import { useMultiStyleConfig, Box, Flex } from '@chakra-ui/react'
import AvatarUI from '../AvatarUI'

const UserInfo = props => {
  const [toggle, setToggle] = useState<boolean>(false),
    { profile, logout } = useAuth(),
    style = useMultiStyleConfig('MGNYUserInfo', { ...props, toggle })

  return (
    <Flex sx={style.wrapper}>
      <Flex sx={style.info}>
        <AvatarUI />
        <Flex sx={style.about}>
          <Box sx={style.labelName}>{getFullNameByProfile(profile)}</Box>
          <Box sx={style.labelEmail}>{profile?.email}</Box>
        </Flex>
        <Box
          sx={style.trigger}
          onClick={() => setToggle(!toggle)}
          onKeyDown={e => keyboardEventListener(e, () => setToggle(!toggle))}
          tabIndex={0}
        >
          <Icon size={`md`}>arrows</Icon>
        </Box>
      </Flex>
      {toggle && (
        <Flex
          onClick={logout}
          sx={style.signOut}
          tabIndex={0}
          onKeyDown={e => keyboardEventListener(e, logout)}
        >
          <Icon size={'md'}>logout</Icon>
          Log out
        </Flex>
      )}
    </Flex>
  )
}

export default UserInfo
