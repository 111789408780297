import React, { useCallback, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { Box, Center, chakra } from '@chakra-ui/react'
import {
  RentRollResidentialUnit,
  RpieFormData,
} from 'hooks/api/rpie/RpieFormData'
import Table from 'ui-framework/components/patterns/Table'

import Checkbox from 'ui-framework/components/primitives/Checkbox'
import { set, wrap } from 'object-path-immutable'
import { getCallback } from 'helpers/utils'
import { CellWithoutBorder } from '../income/StepExpenseTable'
import compact from 'lodash/compact'
import { Utilities } from 'constants/dicti'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import Row from './Row'
import schema, { getIsRegulatedErrorMsg } from './schema'
import useEditRowModal from 'ui-framework/components/patterns/Wizard/useEditRowModal'
import useRentRollResContent from './useRentRollResContent'
import Prompt from 'ui-framework/components/primitives/Prompt'

//===================================================
export type RentRollResidentialUnitEx = RentRollResidentialUnit & {
  key: string
  index: number
}

const TitleRow = chakra('tr', {
  baseStyle: {
    td: {
      textStyle: 'body.caption',
      color: 'fontnavy',
      p: '4px 0 !important',
    },
  },
})

export const rowGroups = {
  isSuperUnit: { color: 'purple.opacity', title: 'Super' }, //TODO need to use RRUnitTypes
  currentlyOwnerOccupied: { color: 'orange.opacity', title: 'Owner' },
  wasVacant: { color: 'green.opacity', title: 'Vacant' },
  currentlyTenantOccupied: { color: '#E2F7FB', title: 'Tenant' },
}

export const setUtility = (unit, name) => {
    const res = wrap(unit),
      setUtilities = value => res.set('utilitiesCS', value)
    if (getUtilityState(unit, name))
      setUtilities(unit.utilitiesCS.filter(ut => ut !== name))
    else setUtilities(unit.utilitiesCS.concat(name))
    return res.value()
  },
  getUtilityState = (unit, utilityName) =>
    unit?.utilitiesCS?.includes(utilityName)

type Props = {
  units: RentRollResidentialUnitEx[]
  numberOfResRegulatedUnit: number
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>
/**
 * StepRentRollResidentialTable component
 */
const StepRentRollResidentialTable = ({
  units,
  numberOfResRegulatedUnit,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
    () => ({ units, numberOfResRegulatedUnit }),
    [numberOfResRegulatedUnit, units]
  )
  const handleOnChange = useCallback(
    (value, name) => {
      getCallback(onChange)(set({ units }, `units.${value.index}`, value))
    },
    [onChange, units]
  )

  const handleSetUtilityBatch = useCallback(
    (value = false, name) => {
      const result = wrap({ units })
      units.forEach((unit, index) => {
        if (name === 'isRegulated') {
          result.set(`units.${index}.isRegulated`, value)
        } else {
          result.set(`units.${index}`, setUtility(unit, name))
        }
      })
      getCallback(onChange)(result.value())
    },
    [onChange, units]
  )

  const getEditContent = useRentRollResContent(disabled)

  const [getEditButton, hasError, editingRowModal] = useEditRowModal({
    schema,
    onValidation,
    applyValidation,
    validationData,
    onChange: handleOnChange,
    getEditContent,
  })

  const [rentAll, waterAll, heatAll, electricityAll] = useMemo(() => {
    const every = field =>
        units.every(i => i?.utilitiesCS.find(u => u === field))
          ? true
          : units.some(i => i?.utilitiesCS.find(u => u === field))
          ? undefined
          : false,
      everyRent = () =>
        units.every(i => i.isRegulated)
          ? true
          : units.some(i => i.isRegulated)
          ? undefined
          : false

    return [
      everyRent(),
      every(Utilities.Water),
      every(Utilities.Heat),
      every(Utilities.Electricity),
    ]
  }, [units])

  const newGroupingItems = useMemo<any>(() => {
    return Object.entries(rowGroups).reduce((acc, [groupKey]) => {
      acc[groupKey] = compact(
        units.map((u, index) => (u[groupKey] === true ? { ...u, index } : null))
      )
      return acc
    }, {})
  }, [units])

  const hasUnitError = useCallback(
    index => {
      const error = hasError(`units.${index}`),
        getMsg = withRegulatedError => {
          const errorsCount =
            Object.keys(error).length - (withRegulatedError ? 1 : 0)
          return errorsCount
            ? `There are (${errorsCount}) errors in this row. Click "Edit" to edit data in the row.`
            : ''
        },
        errorMsg = error
          ? error.isRegulated
            ? `# of Regulated Units must equal (${numberOfResRegulatedUnit}).
            ${getMsg(true)}`
            : getMsg(false)
          : ''

      return errorMsg
    },
    [numberOfResRegulatedUnit, hasError]
  )

  let tableRowNumber = 0

  return (
    <chakra.div className={props.className} position={`relative`}>
      <ReviewLastYearDataWarningBlock m="0 0 36px 54px" />

      <chakra.div
        bg="#F9FAFF"
        height="92px"
        position="absolute"
        left="0"
        right="0"
        zIndex="0"
      ></chakra.div>
      <Box
        as="div"
        sx={{
          table: {
            position: 'relative',
            backgroundColor: 'transparent',
            thead: {
              backgroundColor: '#F9FAFF',
              th: {
                textStyle: 'caption.regular',
                color: 'fontnavy',
              },
            },
            tbody: {
              td: {
                p: {
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                },
              },
            },
          },
        }}
      >
        <Table cellSidePaddings={'16px'} isSticky={true}>
          <colgroup>
            <col width="54px" />
            <col width="30px" />
            <col width="10%" />
            <col width="16%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="6%" />
            <col width="6%" />
            <col width="6%" />
            <col width="96px" />
            <col width="6%" />
          </colgroup>

          <thead>
            <tr style={{ border: 'none' }}>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th rowSpan={2}>Rent Regulated?</th>
              {/* @ts-ignore */}
              <chakra.th pt="12px" pb="0px !important" colSpan={3}>
                <b>Utilities Paid By Tenant:</b>
              </chakra.th>
              <th></th>
              <th></th>
            </tr>
            <tr style={{ border: 'none' }}>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Water</th>
              <th>Heat</th>
              <th>Electricity</th>
              <th></th>
              <th></th>
            </tr>
            <tr style={{ borderBottom: '1px solid #E6E8F0' }}>
              <th></th>
              <th></th>
              <th>Apt #</th>
              <th>Floor Level</th>
              <th>Bedrooms</th>
              <th>Monthly Rent</th>
              <th>Net Rentable Area</th>
              <th>Vacant Month</th>
              <th>
                <Center>
                  <Checkbox
                    indeterminate={rentAll === undefined}
                    checked={rentAll}
                    onChange={v => handleSetUtilityBatch(v, 'isRegulated')}
                  />
                  <Prompt
                    label={getIsRegulatedErrorMsg(numberOfResRegulatedUnit)}
                    maxW={'210px'}
                    wrapper={{
                      position: 'absolute',
                      marginLeft: '44px',
                    }}
                  />
                </Center>
              </th>
              <th>
                <Center>
                  <Checkbox
                    indeterminate={waterAll === undefined}
                    checked={waterAll}
                    onChange={v => handleSetUtilityBatch(v, Utilities.Water)}
                  />
                </Center>
              </th>
              <th>
                <Center>
                  <Checkbox
                    indeterminate={heatAll === undefined}
                    checked={heatAll}
                    onChange={v => handleSetUtilityBatch(v, Utilities.Heat)}
                  />
                </Center>
              </th>
              <th>
                <Center>
                  <Checkbox
                    indeterminate={electricityAll === undefined}
                    checked={electricityAll}
                    onChange={v =>
                      handleSetUtilityBatch(v, Utilities.Electricity)
                    }
                  />
                </Center>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* {groupingItems} */}
            {Object.entries(newGroupingItems).map(
              ([groupKey, units], currentGroupIndex, groups) =>
                (units as RentRollResidentialUnitEx[]).map(
                  (unit, unitIndex) => {
                    tableRowNumber += 1

                    return (
                      <>
                        {unitIndex === 0 && (
                          <TitleRow bgColor={rowGroups[groupKey].color}>
                            <CellWithoutBorder />
                            <CellWithoutBorder colSpan={13}>
                              {rowGroups[groupKey].title}
                            </CellWithoutBorder>
                          </TitleRow>
                        )}

                        <Row
                          key={unit.index}
                          rowNum={tableRowNumber}
                          unit={unit}
                          editRowBtn={getCallback(getEditButton)(
                            unit,
                            hasUnitError(unit.index)
                          )}
                        />
                      </>
                    )
                  }
                )
            )}
          </tbody>
        </Table>
      </Box>
      {editingRowModal}
    </chakra.div>
  )
}

export default StepRentRollResidentialTable
//   memo(StepRentRollResidentialTable, (prev, next) =>
//   ['units', 'validation', 'disabled', 'onChange'].every(v =>
//     isEqual(prev[v], next[v])
//   )
// )
