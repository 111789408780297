import { SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import useAuth from './useAuth'
import firebaseApp, { firebase } from 'helpers/firebaseInit'
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'

const useUserState: any = (field, defaultValue, context = null, contextValue = null) => {
  const { user } = useAuth(),
    userDataRef = useMemo(() => {
      if (user) {
        const userDataColl = collection(getFirestore(firebaseApp), 'usersData')
        const ref = doc(userDataColl, user.email)

        return context && contextValue
          ? doc(ref, context, contextValue)
          : ref
      }
    }, [context, contextValue, user]),
    [userData] = useDocumentData(userDataRef),
    state = useMemo(
      () => (userData && userData[field]) || defaultValue,
      [defaultValue, field, userData]
    ),
    stateSet = useCallback(
      value => {
        userDataRef && setDoc(userDataRef, { [field]: value }, { merge: true })
      },
      [field, userDataRef]
    )

  useEffect(() => {
    if (userDataRef && defaultValue !== undefined && !userData) {
      stateSet(defaultValue)
    }
  }, [userData, defaultValue, stateSet, userDataRef])

  return [state, stateSet, userDataRef]
}

export default useUserState
