import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

export enum AuthErrors {
  WrongPassword = 'auth/wrong-password',
  WrongPasswordHash = 'auth/invalid-password-hash',
  WrongPasswordSalt = 'auth/invalid-password-salt',
  InvalidEmail = 'auth/invalid-email',
  InvalidEmailVerified = 'auth/invalid-email-verified',
  UserNotFound = 'auth/user-not-found',
  InvalidUserImport = 'auth/invalid-user-import',
  MaxUserCountExceeded = 'auth/maximum-user-count-exceeded',
}

const MIN_PASSWORD_LENGTH = 8,
  passwordRule = schemaRules.MIN_STRING(
    MIN_PASSWORD_LENGTH,
    `${MIN_PASSWORD_LENGTH} characters minimum`
  ),
  emailRule = Joi.string()
    .empty(null)
    .email({ tlds: { allow: false } })
    .message('Incorrect email address')
    .required()

export const registerSchema = schemaWrapper({
  email: emailRule,
  password: passwordRule,
})

export const resetPasswordSchema = schemaWrapper({
  password: passwordRule,
})

export const resetEmailSchema = schemaWrapper({
  email: emailRule,
})

export const loginSchema = schemaWrapper({
  loginEmail: schemaRules
    .ANY_INVALID(
      AuthErrors.InvalidEmail,
      AuthErrors.InvalidEmailVerified,
      AuthErrors.UserNotFound,
      AuthErrors.InvalidUserImport,
      AuthErrors.MaxUserCountExceeded
    )
    .messages({ 'any.invalid': 'Incorrect email address' }),
  loginPassword: schemaRules
    .ANY_INVALID(
      AuthErrors.WrongPassword,
      AuthErrors.WrongPasswordHash,
      AuthErrors.WrongPasswordSalt
    )
    .messages({ 'any.invalid': 'Incorrect password' }),
})
