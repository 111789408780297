import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'
import { StorefrontLeaseConcessions } from 'constants/dicti'
import { REGEXPS } from 'constants/CONSTANTS'
import { getDuration } from 'helpers/utils'
import { uiDateMonthFormat } from 'helpers/formats'
import { getForamttedDateRange } from 'pages/Services/Rpie/RpieFormPage/wizard/common'

const schema = schemaWrapper({
  hasLeaseInformation: schemaRules.BOOLEAN,
  existLeaseInformationCS: Joi.when('hasLeaseInformation', {
    switch: [
      {
        is: true,
        then: schemaRules.NOT_EMPTY_STRING,
      },
      {
        is: false,
        then: StorefrontLeaseConcessions.None,
      },
    ],
    otherwise: schemaRules.NOT_EMPTY_STRING,
  }),
  monthNumber: Joi.when('existLeaseInformationCS', {
    is: StorefrontLeaseConcessions.RentFree,
    then: schemaRules.MIN_MAX_NUMBER(1, 12).custom((value, helpers) => {
      const { _occStartDate, _occEndDate } =
        helpers?.prefs?.context?.originalValue,
        duration = getDuration(_occStartDate, _occEndDate, 'months'),
        showErrorMsg = msg => helpers.message(msg)

      if (value > duration) {
        const selectedInterval = getForamttedDateRange(
          _occStartDate,
          _occEndDate,
          uiDateMonthFormat
        )
        return showErrorMsg(
          `Number of Months Rent-Free should not exceed selected Occupancy interval ${selectedInterval}.`
        )
      }
    }),
    otherwise: schemaRules.ANY,
  }),
  reducedRentAmount: Joi.when('existLeaseInformationCS', {
    is: StorefrontLeaseConcessions.StartRent,
    then: schemaRules.MIN_NUMBER(),
    otherwise: schemaRules.ANY,
  }),
  improvementCashAmount: Joi.when('existLeaseInformationCS', {
    is: StorefrontLeaseConcessions.Improve,
    then: schemaRules.MIN_NUMBER(),
    otherwise: schemaRules.ANY,
  }),
  otherDescriptionOfConcession: Joi.when('existLeaseInformationCS', {
    is: StorefrontLeaseConcessions.Other,
    then: schemaRules.NOT_EMPTY_STRING.pattern(REGEXPS.MATCH_SOME_SYMBOL).rule({
      message: 'We need words, not spaces!',
    }),
    otherwise: schemaRules.ANY,
  }),
  isIncreaseThisYear: schemaRules.BOOLEAN,
  isIncreaseAfterThisYear: schemaRules.BOOLEAN,
  isOccupiedByTenantOn1231: Joi.when('showIsOccupiedByTenantOn1231', {
    is: true,
    then: schemaRules.BOOLEAN,
    otherwise: schemaRules.ANY,
  }),
})

export default schema
