import Joi from 'joi'
import { REGEXPS } from 'constants/CONSTANTS'
import { AnyFunction } from 'helpers/utils'

const shortMsg = 'Too short',
  someWordMsg = 'We need words, not spaces!'

export const schemaWrapper = rules => Joi.object({ ...rules })

export const schemaRules = {
  NUMBER: Joi.number(),
  STRING: Joi.string(),
  DATE: Joi.date().empty(null).required(),
  BOOLEAN: Joi.boolean().empty(null).required(),
  NOT_EMPTY_STRING: Joi.string().empty(['', null]).required(),
  NOT_EMPTY_NUMBER: Joi.number().empty(null).required(),
  NOT_EMPTY_DATE: Joi.date().empty(null).required(),
  ANY: Joi.any(),
  MIN_STRING: (count: number, message: string = '') =>
    schemaRules.NOT_EMPTY_STRING.min(count).messages({
      'string.min': message || shortMsg,
    }),
  MIN_MAX_STRING: (min: number, max: number, message: string = '') =>
    schemaRules.NOT_EMPTY_STRING.min(min).max(max).messages({
      'string.min': message,
      'string.max': message,
    }),
  MAX_NUMBER: max => schemaRules.NOT_EMPTY_NUMBER.max(max),
  MIN_NUMBER: (min = 0) => schemaRules.NOT_EMPTY_NUMBER.min(min),
  MIN_MAX_NUMBER: (min, max) => schemaRules.NOT_EMPTY_NUMBER.min(min).max(max),
  MIN_ARRAY: (count: number) => Joi.array().min(count).required(),
  GREATER_NUMBER: (greater = 0) =>
    schemaRules.NOT_EMPTY_NUMBER.greater(greater),
  SOME_SYMBOL_STRING: (
    minLength = 3,
    pattern = REGEXPS.MATCH_SOME_SYMBOL,
    rule = { message: someWordMsg }
  ) => schemaRules.MIN_STRING(minLength).pattern(pattern).rule(rule),
  ITEMS: items => Joi.array().items(items),
  VALID_STRING: (...args) =>
    Joi.string()
      .valid(...args)
      .empty(['', null])
      .required(),
  VALID: (...args) =>
    Joi.valid(...args)
      .empty(args.includes(null) ? undefined : null)
      .required(),
  ANY_INVALID: (...args) => schemaRules.ANY.invalid(...args),
  CUSTOM: (fn: AnyFunction) => Joi.custom(fn),
  OBJECT: objectType => Joi.object(objectType).empty(null).required(),
}
