import React, { useCallback, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { getNameAndErrorProps } from 'helpers/utils'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import {
  RentRollCommercialUnit,
  RpieFormData,
} from 'hooks/api/rpie/RpieFormData'
import Table from 'ui-framework/components/patterns/Table'
import TableRow from 'ui-framework/components/patterns/Table/elements/TableRow'
import TableCell from 'ui-framework/components/patterns/Table/elements/TableCell'
import Checkbox from 'ui-framework/components/primitives/Checkbox'
import {
  getCallback,
  getFiscalPeriods,
  createItemsByDicti,
  mapDictiNames,
  mapObjectToIdNameItems,
} from 'helpers/utils'
import { set, wrap } from 'object-path-immutable'
import { getFloorsArray } from 'helpers/business'
import { integerPositiveThousands } from 'helpers/formats'
import Icon from 'ui-framework/components/primitives/Icon'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { TableHeader } from 'ui-framework/components/patterns/Table/TableHeaders'
import { Box, Center, Flex } from '@chakra-ui/react'
import Input from 'ui-framework/components/primitives/Input'
import Empty from 'ui-framework/components/primitives/Empty'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import {
  BusinessActivity,
  StorefrontUnitOccupancyType,
} from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'

//====================================================
type Props = {
  units: RentRollCommercialUnit[]
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

/**
 * StepRentRollCommercialTable component
 */
const StepRentRollCommercialTable = ({
  units,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context),
    [primaryBusinessActivityItems, statusItems] = useMemo(
      () => [
        createItemsByDicti(BusinessActivity),
        mapObjectToIdNameItems(
          mapDictiNames([
            StorefrontUnitOccupancyType.Owner,
            StorefrontUnitOccupancyType.Tenant,
          ])
        ),
      ],
      []
    ),
    validationData = useMemo(() => ({ units }), [units]),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  //TODO: change type to TableHeaderItems
  const headers: TableHeader[] = [
    { text: '', width: '3%' },
    { text: 'Unit #', width: '90px' },
    { text: 'Floor', width: '10%' },
    { text: 'Net Rentable Area', width: '10%' },
    { text: 'Vacant', width: '10%', align: 'center' },
    { text: 'Vacant Month', width: '15%' },
    { text: 'Primary Business Activity', width: '30%' },
    {
      text: `Status as of Jan 5, ${fiscalYear}`,
      width: '10%',
    },
    { text: 'Gross Rent', width: '11%' },
    { text: 'Base Rent', width: '11%' },
  ]

  const handleOnChange = useCallback(
    index => (value, name) => {
      getCallback(onChange)(set({ units }, `units.${index}.${name}`, value))
    },
    [onChange, units]
  )
  const changeDependField = useCallback(
    (index: number, fieldNames: string | string[], newValue: any = null) => {
      const result = wrap({ units }),
        setValue = name => result.set(`units.${index}.${name}`, newValue)

      Array.isArray(fieldNames)
        ? fieldNames.forEach(field => setValue(field))
        : setValue(fieldNames)

      getCallback(onChange)(result.value())
    },
    [units, onChange]
  )

  const handleVacantChange = useCallback(
    index => (value, name) => {
      const result = wrap({ units })
      result.set(`units.${index}.wasVacant`, value)
      if (value) {
        result
          .set(
            `units.${index}.primaryBusinessActivityCS`,
            null // BusinessActivity.Vacant
          )
          .set(`units.${index}.otherPrimaryBusinessActivityDescription`, null)
          .set(`units.${index}.currentlyOwnerOccupied`, false)
          .set(`units.${index}.currentlyTenantOccupied`, false)
          .set(`units.${index}.grossRentAmount`, 0)
          .set(`units.${index}.baseRentAmount`, 0)
      } else
        result
          .set(`units.${index}.vacantMonths`, null)
          .set(`units.${index}.primaryBusinessActivityCS`, null)
      getCallback(onChange)(result.value())
    },
    [onChange, units]
  )

  const handleSetStatus = useCallback(
    index => (value, name) => {
      const result = wrap({ units })
      if (value === StorefrontUnitOccupancyType.Owner) {
        result
          .set(`units.${index}.currentlyOwnerOccupied`, true)
          .set(`units.${index}.currentlyTenantOccupied`, false)

          .set(`units.${index}.grossRentAmount`, 0)
          .set(`units.${index}.baseRentAmount`, 0)
      } else if (value === StorefrontUnitOccupancyType.Tenant) {
        result
          .set(`units.${index}.currentlyOwnerOccupied`, false)
          .set(`units.${index}.currentlyTenantOccupied`, true)
      } else {
        result
          .set(`units.${index}.currentlyOwnerOccupied`, null)
          .set(`units.${index}.currentlyTenantOccupied`, null)
          .set(`units.${index}.grossRentAmount`, null)
          .set(`units.${index}.baseRentAmount`, null)
      }
      getCallback(onChange)(result.value())
    },
    [onChange, units]
  )

  return (
    <StepBasic className={props.className}>
      <ReviewLastYearDataWarningBlock mb="36px" />
      <AttentionBox variant="warning" w={`300px`} title={false} mb="36px">
        <TextFromParams params={{ textAlign: 'left' }}>
          Please do not include Storefronts as part of rent roll below.
        </TextFromParams>
      </AttentionBox>

      <Box>
        <Table
          headers={headers}
          items={units}
          cellSidePaddings={'10px'}
          isSticky={true}
        >
          {(unit: RentRollCommercialUnit, index: number) => {
            const errorPath = `units.${index}`,
              nameAndErrorProps = name =>
                getNameAndErrorProps(name, hasError, errorPath)

            const hasStatusDropdownError =
              hasError(`${errorPath}.currentlyOwnerOccupied`) ||
              hasError(`${errorPath}.currentlyTenantOccupied`)

            const onChangeHandler = handleOnChange(index)
            const otherBusiness = BusinessActivity.Other,
              miscellaneousOtherServiceBusiness =
                BusinessActivity.MiscellaneousOtherService

            return (
              <TableRow key={index}>
                <td style={{ whiteSpace: 'nowrap' }}>{index + 1}</td>
                <TableCell hidePaddings>
                  <HelpTarget name="unitApartmentNumber">
                    <Input
                      width="100%"
                      size="sm"
                      errorTooltip
                      value={unit.unitApartmentNumber}
                      placeholder="###"
                      disabled={disabled}
                      onChange={onChangeHandler}
                      {...nameAndErrorProps('unitApartmentNumber')}
                    />
                  </HelpTarget>
                </TableCell>
                <TableCell hidePaddings>
                  <DropdownList
                    size="sm"
                    width="100%"
                    value={unit.floorNumber}
                    errorTooltip
                    listWidth="150px"
                    disabled={disabled}
                    items={getFloorsArray(20)}
                    onChange={onChangeHandler}
                    {...nameAndErrorProps('floorNumber')}
                  />
                </TableCell>
                <TableCell hidePaddings>
                  <HelpTarget name="rentableNetAreaSF">
                    <Input
                      width="100%"
                      size="sm"
                      errorTooltip
                      value={unit.rentableNetAreaSF}
                      type="number"
                      format={integerPositiveThousands}
                      placeholder="SF"
                      disabled={disabled}
                      onChange={onChangeHandler}
                      {...nameAndErrorProps('rentableNetAreaSF')}
                    />
                  </HelpTarget>
                </TableCell>
                <TableCell hidePaddings align="center">
                  <Center w={`100%`}>
                    <Checkbox
                      checked={!!unit.wasVacant}
                      disabled={disabled}
                      onChange={handleVacantChange(index)}
                      {...nameAndErrorProps('wasVacant')}
                    />
                  </Center>
                </TableCell>
                <TableCell hidePaddings>
                  {unit.wasVacant ? (
                    <DropdownList
                      width="100%"
                      size="sm"
                      value={
                        unit.vacantMonths !== null
                          ? String(unit.vacantMonths)
                          : null
                      }
                      listWidth="100px"
                      disabled={disabled || !unit.wasVacant}
                      items={[
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                      ]}
                      errorTooltip
                      onChange={onChangeHandler}
                      {...nameAndErrorProps('vacantMonths')}
                    />
                  ) : (
                    <Empty type={'longdash'} />
                  )}
                </TableCell>
                <TableCell hidePaddings align="center">
                  {!unit.wasVacant ? (
                    [otherBusiness, miscellaneousOtherServiceBusiness].includes(
                      unit.primaryBusinessActivityCS as any
                    ) ? (
                      <Flex grow={1} alignItems="center">
                        <Input
                          width="100%"
                          size="sm"
                          value={unit.otherPrimaryBusinessActivityDescription}
                          type="text"
                          placeholder="Enter description"
                          errorTooltip
                          disabled={disabled}
                          onChange={onChangeHandler}
                          {...nameAndErrorProps(
                            'otherPrimaryBusinessActivityDescription'
                          )}
                        />
                        <Flex ml="5px">
                          <Icon
                            size="sm"
                            tabIndex={0}
                            onClick={() =>
                              changeDependField(index, [
                                'primaryBusinessActivityCS',
                                'otherPrimaryBusinessActivityDescription',
                              ])
                            }
                          >
                            close
                          </Icon>
                        </Flex>
                      </Flex>
                    ) : (
                      <DropdownList
                        size="sm"
                        width="100%"
                        value={unit.primaryBusinessActivityCS}
                        listWidth="200px"
                        disabled={unit.wasVacant || disabled}
                        itemId="id"
                        itemValue="name"
                        items={primaryBusinessActivityItems}
                        onChange={onChangeHandler}
                        errorTooltip
                        {...nameAndErrorProps('primaryBusinessActivityCS')}
                      />
                    )
                  ) : (
                    <Empty type={'hyphen'} />
                  )}
                </TableCell>
                <TableCell hidePaddings>
                  {!unit.wasVacant ? (
                    <DropdownList
                      size="sm"
                      width="100%"
                      listWidth="150px"
                      listMaxHeight={`100px`}
                      value={
                        (unit.currentlyOwnerOccupied &&
                          StorefrontUnitOccupancyType.Owner) ||
                        (unit.currentlyTenantOccupied &&
                          StorefrontUnitOccupancyType.Tenant) ||
                        null
                      }
                      items={statusItems}
                      error={hasStatusDropdownError}
                      errorTooltip
                      name="status"
                      itemId="id"
                      itemValue="name"
                      disabled={unit.wasVacant || disabled}
                      onChange={handleSetStatus(index)}
                    />
                  ) : (
                    <Empty type={'hyphen'} />
                  )}
                </TableCell>
                <TableCell hidePaddings>
                  <Input
                    width="80%"
                    errorTooltip
                    value={unit.grossRentAmount}
                    type="number"
                    prefix="$"
                    format={integerPositiveThousands}
                    disabled={
                      disabled ||
                      !!unit.currentlyOwnerOccupied ||
                      !!unit.wasVacant
                    }
                    onChange={onChangeHandler}
                    size="sm"
                    {...nameAndErrorProps('grossRentAmount')}
                  />
                </TableCell>
                <TableCell hidePaddings>
                  <Input
                    width="80%"
                    errorTooltip
                    value={unit.baseRentAmount}
                    type="number"
                    prefix="$"
                    format={integerPositiveThousands}
                    disabled={
                      disabled ||
                      !!unit.currentlyOwnerOccupied ||
                      !!unit.wasVacant
                    }
                    onChange={onChangeHandler}
                    size="sm"
                    {...nameAndErrorProps('baseRentAmount')}
                  />
                </TableCell>
              </TableRow>
            )
          }}
        </Table>
      </Box>
    </StepBasic>
  )
}

export default StepRentRollCommercialTable
