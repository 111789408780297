import React from 'react'
import { ChakraComponentProps, OnClickHandler } from 'ui-framework/common/types'
import Affix from 'ui-framework/components/patterns/Table/typecells/Affix'
import Icon from 'ui-framework/components/primitives/Icon'
import { useStyleConfig, chakra } from '@chakra-ui/system'
import { Box, Text } from '@chakra-ui/layout'
import isString from 'lodash/isString'
import AvatarUI from '../AvatarUI'
import { isBoolean } from 'lodash-es'

type ChipsProps = {
  prefix?: React.ReactNode
  postfix?: string
  disabled?: boolean
  onClick?: OnClickHandler
  variant?: string
  size?: string
  withState?: boolean
} & ChakraComponentProps

const Chips = (props: ChipsProps) => {
  const { prefix, postfix, disabled = false, onClick, ...rest } = props
  const style = useStyleConfig('MGNYChips', {
      ...props,
      prefix: isBoolean(prefix) && prefix ? true : prefix,
    }),
    affixes = {
      prefix: isString(prefix) ? <AvatarUI src={prefix} /> : prefix,
      postfix: postfix && (
        <Icon size={'xs'} onClick={onClick}>
          {postfix}
        </Icon>
      ),
    }

  return (
    // @ts-ignore
    <Box sx={style} className="chips" {...rest} disabled={disabled}>
      <Affix {...affixes}>
        <Text isTruncated>{props.children}</Text>
      </Affix>
    </Box>
  )
}

export default chakra(Chips)
