import React, { createContext } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useCheckedContext } from 'hooks/useApplicationContext'

type ServiceWizardProviderState<T = any> = {
  serviceData: T | null
  context: any
  changeStep: (stepId) => void
}

const defaultContext: ServiceWizardProviderState = {
  serviceData: null,
  context: null,
  changeStep: stepId => {},
}

const ServiceWizardProviderContext = createContext(defaultContext)

//===================================================
type ServiceWizardProviderProps<T> = ServiceWizardProviderState<T> &
  Pick<BaseComponentProps, 'children'>

/**
 * ServiceWizardProvider component
 */
const ServiceWizardProvider = <T,>({
  children,
  ...props
}: ServiceWizardProviderProps<T>) => {
  return (
    <ServiceWizardProviderContext.Provider value={props}>
      {children}
    </ServiceWizardProviderContext.Provider>
  )
}

const useServiceWizard = () =>
  useCheckedContext<ServiceWizardProviderState>(ServiceWizardProviderContext)

export default ServiceWizardProvider
export { useServiceWizard }
