import { ComponentStyleConfig } from '@chakra-ui/theme'
import { getTabFocusSelectors } from 'helpers/utils'
import Link from 'ui-framework/components/primitives/Link'

const MainLayoutStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: [
    'wrapper',
    'menu',
    'logo',
    'switcher',
    'boxContent',
    'menuItem',
    'menuFooter',
  ],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      width: '100%',
      display: 'flex',
    },
    logo: {
      border: '1px solid',
      borderColor: 'secondary.divider',
      borderRadius: '6px',
      backgroundColor: 'white100',
    },
    menu: {
      backgroundColor: 'secondary.sideMenu',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      borderRight: '1px solid',
      borderColor: props.isAdmin ? 'red.opacity' : 'secondary.divider',
      '@media print': {
        display: 'none',
      },
    },
    switcher: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '2',
      border: '1px solid',
      borderColor: props.isAdmin ? 'red.base' : 'secondary.divider',
      borderRadius: '50%',
      backgroundColor: props.isAdmin ? 'red.opacity' : 'white100',
      transition: 'background-color .3s',
      position: 'absolute',
      ...getTabFocusSelectors({
        borderRadius: '50%',
      }),
      '&::after': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',
      },
      '.update_descr': {
        display: 'none',
        height: '38px',
        width: '161px',
        padding: '8px 8px 8px 13px',
        boxShadow: '2px 2px 10px 0 rgba(0,0,0,0.08)',
        position: 'absolute',
        left: '36px',
        textStyle: 'caption.regular',
        backgroundColor: 'white',
        lineHeight: '22px',
        borderRadius: '8px',
      },
      '.icon': {
        svg: {
          fill: 'fontandicongray',
        },
      },
    },
    boxContent: {
      flex: 1,
      backgroundColor: 'white100',
      '#content': {
        overflow: 'auto',
      },
    },
    menuItem: {
      [Link as any]: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        color: 'fontnavy',
        fontFamily: 'body',
        fontWeight: 'regular',
        overflow: 'hidden',
        '.icon': {
          svg: {
            fill: 'fontnavy',
          },
        },
        '&.active': {
          color: 'primary.base',
          backgroundColor: 'secondary.base',
          '.icon': {
            svg: {
              fill: 'primary.base',
            },
          },
        },
      },
    },
    menuFooter: {
      position: 'absolute',
      bottom: '0',
      paddingBottom: '16px',
      backgroundColor: 'secondary.sideMenu',
      boxShadow: '0px -7px 14px 0px var(--chakra-colors-secondary-sideMenu)',
    },
  }),
  // Styles for the size variations
  sizes: {
    sm: {
      menu: {
        position: 'sticky',
        top: 0,
        height: '100vh',
        padding: '24px 16px 0',
        zIndex: 99,
      },
      logo: {
        width: '72px',
        height: '72px',
        padding: '8px 10px',
        svg: {
          width: '50px',
          height: '52px',
        },
      },
      switcher: {
        width: '32px',
        height: '32px',
        top: '36px',
        left: 'calc(100% - 16px)',
        '&::after': {
          width: '72px',
          height: '72px',
        },
      },
      menuItem: {
        maxHeight: '400px',
        overflow: 'auto',
        width: '247px',
        fontSize: 'body',
        lineHeight: 'body',
        '.icon': {
          margin: '0 13px',
        },
        [Link as any]: {
          fontSize: 'body',
          borderRadius: '4px',
          padding: '8px 0',
        },
      },
      menuFooter: {
        width: 'calc(100% - 32px)',
      },
      boxContent: {
        minHeight: '100vh',
        flexDirection: 'column',
        overflowX: 'hidden',
      },
    },
  },
  // Styles for the visual style variations
  variants: {
    open: props => ({
      switcher: {
        backgroundColor: props.isAdmin
          ? 'red.opacity'
          : props.hasUpdate
          ? 'orange.opacity'
          : 'white',
        _hover: {
          backgroundColor: props.hasUpdate ? 'orange.opacity' : 'white',
        },
      },
      menu: {
        width: '279px',
      },
    }),
    hidden: props => ({
      switcher: {
        borderColor: props.isAdmin
          ? 'red.base'
          : props.hasUpdate
          ? 'primary.base'
          : 'secondary.divider',

        '.icon': {
          svg: {
            fill: props.hasUpdate ? 'primary.base' : 'fontandicongray',
          },
        },
        _hover: {
          '.update_descr': {
            display: props.hasUpdate ? 'flex' : 'none',
          },
          backgroundColor: props.hasUpdate ? 'orange.opacity' : 'white',
          borderColor: props.isAdmin
            ? 'red.base'
            : props.hasUpdate
            ? 'orange.base'
            : 'secondary.divider',
          '.icon': {
            svg: {
              fill: props.hasUpdate ? 'orange.base' : 'fontandicongray',
            },
          },
        },
      },
      menu: {
        width: '25px',
        '& > *': {
          display: 'none',
        },
        '& > button': {
          display: 'flex',
        },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'sm',
    variant: 'open',
  },
}

export default MainLayoutStyle
