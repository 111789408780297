import { useState, useEffect } from 'react'

interface ScrollPosition {
  x: number
  y: number
}

const isBrowser = typeof window !== `undefined`

function getScrollPosition(): ScrollPosition {
  return isBrowser
    ? { x: window.pageXOffset, y: window.pageYOffset }
    : { x: 0, y: 0 }
}

export function useScrollPosition(el = window): ScrollPosition {
  const [position, setScrollPosition] = useState<ScrollPosition>(
    getScrollPosition()
  )

  useEffect(() => {
    let requestRunning: number | null = null
    function handleScroll(e) {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition())
          requestRunning = null
        })
      }
    }

    if (isBrowser) {
      const elem = el || window
      elem.addEventListener('scroll', handleScroll)
      return () => elem.removeEventListener('scroll', handleScroll)
    }
  }, [el])

  return position
}

export function useScrollXPosition(): number {
  const { x } = useScrollPosition()
  return x
}

export function useScrollYPosition(): number {
  const { y } = useScrollPosition()
  return y
}
