import { useMemo, useCallback, ReactNode } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { AnyFunction, generatePortalPath, IsAdmin } from 'helpers/utils'
import { UseApplicationResult } from 'hooks/api/firebase/useApplication'
import { useMainMenu } from 'hooks/ui/MainMenuProvider'
import useSelectProfileDialog from 'pages/Services/hooks/useSelectProfileDialog'
import { useNavigate } from 'react-router-dom'

// ================
type UseSelectProfileAndRedirectResult = [AnyFunction, ReactNode]

const useSelectProfileAndRedirect = <T extends any>(
  application: UseApplicationResult<T>,
  redirectToRoute: string
): UseSelectProfileAndRedirectResult => {
  const menu = useMainMenu(),
    listOfUsersDialog = useDisclosure(),
    users = useMemo(() => application?.data?.users || [], [application]),
    admin = IsAdmin(),
    navigate = useNavigate(),
    redirectTo = useCallback(
      (email?: string) => {
        const path = generatePortalPath(
          redirectToRoute,
          { subject: admin && email ? email : undefined },
          true
        )
        menu.onOpen()
        listOfUsersDialog.onClose()
        navigate(path)
      },
      [redirectToRoute, admin, menu, listOfUsersDialog, navigate]
    ),
    [onOpen, dialogNode] = useSelectProfileDialog(users, redirectTo),
    result = useMemo(() => {
      return (
        admin ? [onOpen, dialogNode] : [redirectTo, null]
      ) as UseSelectProfileAndRedirectResult
    }, [admin, dialogNode, onOpen, redirectTo])

  return result
}

export default useSelectProfileAndRedirect
