import React, { useCallback, useEffect } from 'react'
import styled from '@emotion/styled/macro'

import ApplicationRoutes from 'routing/ApplicationRoutes'

import { ApplicationContext } from 'hooks/ApplicationContext'
import { useApplicationContextValue } from 'hooks/useApplicationContext'
import useLiveAgent from 'hooks/useLiveAgent'
import ScrollToTop from 'ui-framework/components/primitives/ScrollToTop'
import { useLocation } from 'react-router-dom'
import { throttle } from 'lodash'
import useUserState from './hooks/useUserState'
import useNetwork from 'hooks/network/useNetwork'
import { useAssistantOnboardingState } from 'ui-framework/components/patterns/HelpBlock/useAssistantOnboarding'
import MainMenuProvider from 'hooks/ui/MainMenuProvider'
import { AuthProvider } from 'hooks/useAuth'
import useUnsupportedBrowsers from 'hooks/useUnsupportedBrowsers'
import Supporting from 'pages/Other/Supporting'

const AppWrapper = styled('div')(props => ({
  display: 'inline',
  minHeight: '100%',
}))

function App() {
  const contextValue = useApplicationContextValue(),
    { pathname } = useLocation(),
    looseConnectionDialog = useNetwork(),
    isSupported = useUnsupportedBrowsers()

  if (!isSupported) return <Supporting />

  return (
    <AppWrapper className="App">
      <ScrollToTop trigger={pathname} />
      <ApplicationContext.Provider value={contextValue}>
        <AuthProvider>
          <MainMenuProvider>
            <ApplicationRoutes />
          </MainMenuProvider>
        </AuthProvider>
      </ApplicationContext.Provider>
      {looseConnectionDialog}
    </AppWrapper>
  )
}

export default App
