import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpFloorSizeStepSFGeneral2Props = { year: number } & BaseComponentProps

/**
 * HelpFloorSizeStepSFGeneral2 component
 */
const HelpFloorSizeStepSFGeneral2 = (
  props: HelpFloorSizeStepSFGeneral2Props
) => {
  return (
    <p>
      Enter net floor area of the unit. (i.e. the{' '}
      <b>interior rentable floor area</b> for this storefront unit)
    </p>
  )
}

export default HelpFloorSizeStepSFGeneral2
