import React, { useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { nanoid } from 'nanoid'

import { FilterSettings, SortSetting } from './useFilterSettings'

import { OnChangeHandler } from 'ui-framework/common/types'
import { Box, chakra, Text } from '@chakra-ui/react'
import Icon from 'ui-framework/components/primitives/Icon'
import { getCallback } from 'helpers/utils'

//===================================================

export type TableHeader = {
  // TODO: remove required id of a header and remove it from all tables
  // TODO: Id field must be required if filterable === true. Throw error otherwise.
  id?: string
  text?: string
  align?: any
  hide?: boolean
  width?: string
  colspan?: number
  rowspan?: number
  path?: string
  bgColor?: string
  sortable?: boolean
  filterable?: boolean
  filterValues?: any[]
  noborder?: boolean
  children?: React.ReactChild | false
}

export type TableHeaderItems = Array<TableHeader> | Array<TableHeader[]>

type TableHeadersProps = {
  items: TableHeaderItems
  sorting?: SortSetting
  filters?: FilterSettings
  onFilterChange?: any
  onSortClick?: OnChangeHandler<TableHeader>
} & BaseComponentProps

/**
 * TableHeaders component
 */
const TableHeaders = ({
  items,
  sorting,
  filters,
  onFilterChange,
  onSortClick,
  ...props
}: TableHeadersProps) => {
  const multilineHeaders = useMemo<Array<TableHeader[]>>(
    () =>
      items.length && Array.isArray(items[0])
        ? (items as Array<TableHeader[]>)
        : [items as TableHeader[]],
    [items]
  )

  return (
    <>
      {multilineHeaders.length === 1 && (
        <colgroup>
          {multilineHeaders.map(row =>
            row.map(
              header =>
                !header.hide && (
                  <Box
                    as={`col`}
                    key={header.id || nanoid()}
                    width={header.width || 'auto'}
                  />
                )
            )
          )}
        </colgroup>
      )}
      {multilineHeaders.length > 0 && (
        <thead>
          {multilineHeaders.map(row => (
            <tr key={nanoid()}>
              {row.map(
                header =>
                  !header.hide && (
                    <chakra.td
                      key={header.id || nanoid()}
                      padding="0"
                      border={header.noborder ? 'none !important' : 'auto'}
                      width={header.width || 'auto'}
                      bgColor={header.bgColor || 'transparent'}
                    >
                      <Text align={header.align} position="relative">
                        {header.text}
                        {header.id && header.path && (
                          <Box
                            as="button"
                            position="absolute"
                            bottom="-3px"
                            ml="2px"
                            onClick={() => getCallback(onSortClick)(header)}
                          >
                            <Icon
                              color={
                                sorting && sorting.name === header.id
                                  ? 'primary.base'
                                  : 'fontandicongray'
                              }
                            >
                              sorting
                            </Icon>
                          </Box>
                        )}
                      </Text>

                      {header.children}
                    </chakra.td>
                    // <TableHeader
                    //   rowSpan={header.rowspan}
                    //   colSpan={header.colspan}
                    //   key={header.id || nanoid()}
                    //   width={header.width}
                    //   align={header.align}
                    //   filterable={header.filterable}
                    //   sortable={header.sortable}
                    //   sorting={
                    //     header.sortable && sorting?.name === header.id
                    //       ? sorting
                    //       : undefined
                    //   }
                    //   headerId={header.id}
                    //   filter={
                    //     filters && header.id ? filters[header.id] : undefined
                    //   }
                    //   availableFilterValues={header.filterValues}
                    //   onFilterChange={v =>
                    //     getCallback(onFilterChange)(header.id, {
                    //       ...v,
                    //       path: header.path,
                    //     })
                    //   }
                    //   onSortClick={() => getCallback(onSortClick)(header)}
                    // >
                    //   {header.text}
                    // </TableHeader>
                  )
              )}
            </tr>
          ))}
        </thead>
      )}
    </>
  )
}

export default TableHeaders
