import { useMemo, useContext, useState } from 'react'
import {
  IApplicationContext,
  ApplicationContext,
  NBAR,
} from './ApplicationContext'
import useQueryParamState from './useQueryParamState'
import createPersistedState from 'use-persisted-state'
import useAppUpdater from './useAppUpdater'
// import { openChat } from './useLiveAgent'
import useCurrentDate from 'hooks/useCurrentDate'
import scheduler from 'helpers/config/scheduler'
import calendarConfigTA from 'services/ta/config/calendar'
import calendarConfigRPIE from 'services/rpie/config/calendar'
import { mainConfig } from 'services/common/config/calendar'
import { configTADeadlines } from 'services/ta/config/configTADeadlines'
import { configRPIEDeadlines } from 'services/rpie/config/configRPIEDeadlines'
import { configHomePageTAActions } from 'services/ta/config/configHomePageTAActions'
import { configHomePageRPIEActions } from 'services/rpie/config/configHomePageRPIEActions'

const useMenuShown = createPersistedState('main_menu_sidebar_collapsed')

// think about separate contexts on state and dispach https://habr.com/ru/post/522896/
const useApplicationContextValue = () => {
  const [subjectKey] = useQueryParamState('subject', undefined)
  const [collapsed, setCollapsed] = useMenuShown(false)
  const [hasUpdate, update] = useAppUpdater()
  const [nbar, nbarSet] = useState<NBAR>()
  const currentDate = useCurrentDate()

  const nbarSection = useMemo(
    () => ({
      set: nbarSet,
      reset: () => nbarSet(null),
      nbar,
    }),
    [nbar]
  )

  const menuSection = useMemo(
    () => ({
      collapsed,
      setCollapsed,
    }),
    [collapsed, setCollapsed]
  )

  const updateSection = useMemo(
    () => ({
      hasUpdate,
      update,
    }),
    [hasUpdate, update]
  )

  const appCtxData = useMemo<IApplicationContext>(() => {
    return {
      config: scheduler(
        mainConfig,
        calendarConfigTA,
        configTADeadlines,
        configHomePageTAActions,
        calendarConfigRPIE,
        configRPIEDeadlines,
        configHomePageRPIEActions
      )(currentDate),
      effectiveUserEmail: subjectKey,
      menu: menuSection,
      update: updateSection,
      nbar: nbarSection,
      // openChat,
    }
  }, [currentDate, subjectKey, menuSection, updateSection, nbarSection])

  return appCtxData
}

export const useCheckedContext = <T>(context) => {
  const contextVal = useContext<T>(context)
  if (contextVal === undefined) {
    throw new Error('Context must be defined')
  }

  return contextVal
}

const useApplicationContext = () => {
  const context = useCheckedContext(ApplicationContext)
  return context as IApplicationContext
}

const useAppConfig = () => {
  const context = useApplicationContext()
  return context.config
}

export type UseAppAuthResult = {
  effectiveUserEmail?: string
  isLogged: boolean
}

export default useApplicationContext
export { useApplicationContextValue, useAppConfig }
