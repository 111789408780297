import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { getCallback, keyboardEventListener } from 'helpers/utils'
import { OnChangeHandler } from 'ui-framework/common/types'
import { Flex, Box, useStyles } from '@chakra-ui/react'
import useButton from '../useButton'

type Props = {
  /**
   * description of prop
   * @disabled boolean
   */
  disabled?: boolean
  /**
   * description of prop
   * @disabled boolean
   */
  value?: any
  /**
   * description of prop
   * @disabled boolean
   */
  selected?: any
  /**
   * compare function
   */
  compareFn?: (value, selected) => boolean
  /**
   * onChange handler
   */
  onChange?: OnChangeHandler<any>
  /**
   * type of name
   * @name string
   */
  name?: string
} & BaseComponentProps

const Radiobutton = ({
  value,
  disabled = false,
  compareFn,
  selected,
  name,
  onChange,
  ...props
}: Props) => {
  const checked = useMemo<boolean>(
      () => (compareFn ? compareFn(value, selected) : value === selected),
      [compareFn, value, selected]
    ),
    className = disabled
      ? checked
        ? 'disabledChecked'
        : 'disabled'
      : checked
      ? 'checked'
      : 'normal',
    { radioWrapper, radio, radioTitle } = useStyles()

  return (
    <Flex
      as={`label`}
      sx={radioWrapper}
      onClick={useButton(() => getCallback(onChange)(selected, name), disabled)}
      onKeyDown={e =>
        keyboardEventListener(e, () => getCallback(onChange)(selected, name))
      }
      className={className}
      tabIndex={0}
    >
      <input
        checked={checked}
        value={value}
        name={name}
        type="radio"
        onChange={() => {}}
      />
      <Box sx={radio} />
      <Box sx={radioTitle}>{props.children}</Box>
    </Flex>
  )
}

export default Radiobutton
