import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpStepReportingPeriodProps = { year: number } & BaseComponentProps

/**
 * HelpStepReportingPeriod component
 */
const HelpStepReportingPeriod = ({ year }: HelpStepReportingPeriodProps) => {
  return (
    <>
      <p>
        Annual income and expense information must be reported for this property
        for the latest reporting year (typically Jan - Dec of {year}). Although
        Finance Department relies on the reported income to calculate the
        assessment, there are almost always adjustments made to the reported
        income for the assessment purposes. For example, if the reported income
        is significantly lower than the income from properties that are similar
        in size and usage, then the Finance Department will adjust the numbers.
      </p>
      <p>
        Please report as accurately as you can, and{' '}
        <b>do not round up or round down to</b> the nearest $100. Instead, round
        down to the nearest <b>$1</b>.
      </p>
    </>
  )
}

export default HelpStepReportingPeriod
