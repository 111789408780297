import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'

const jobItemSchema = {
  jobNumber: schemaRules.NOT_EMPTY_STRING.$.pattern(/\d*/)
    .length(9)
    .rule({ message: 'Must be valid DOB job number' }),
  projectStartDate: schemaRules.NOT_EMPTY_DATE,
  projectEndDate: schemaRules.NOT_EMPTY_DATE.greater(
    Joi.ref('projectStartDate')
  ).rule({ message: 'Must be greater than Start Date' }),
}

const schema = schemaWrapper({
  hadConstruction: schemaRules.BOOLEAN,
  constructionJobs: Joi.when('hadConstruction', {
    is: true,
    then: schemaRules.ITEMS(jobItemSchema).min(1),
    otherwise: schemaRules.ANY,
  }),
})

export default schema
