import React from 'react'
import { Box, Center, Text } from '@chakra-ui/layout'
import { ChakraComponentProps, OnClickHandler } from 'ui-framework/common/types'
import { useStyleConfig, chakra } from '@chakra-ui/system'
import Affix from 'ui-framework/components/patterns/Table/typecells/Affix'
import Icon from 'ui-framework/components/primitives/Icon'
import Loader from 'ui-framework/components/primitives/Loader'
import { Link, LinkProps } from 'react-router-dom'

//===================================================
export type ButtonVariantProps =
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'warning'
type ButtonProps = {
  variant?: ButtonVariantProps
  size?: 'md' | 'sm'
  disabled?: boolean
  prefix?: string
  postfix?: string
  pending?: boolean
  name?: string
  onClick?: OnClickHandler
} & ChakraComponentProps &
  Partial<LinkProps>

/**
 * Button component
 */
const ChakraLink = chakra(Link),
  ButtonWrapper = props => {
    const style = useStyleConfig('MGNYButton', props)
    return props.to ? (
      <ChakraLink
        className={props.className}
        to={props.to}
        // @ts-ignore
        disabled={props.disabled}
        tabIndex={0}
        sx={style}
      >
        {props.children}
      </ChakraLink>
    ) : props.href ? (
      <chakra.a
        className={props.className}
        href={props.href}
        // @ts-ignore
        disabled={props.disabled}
        tabIndex={0}
        sx={style}
      >
        {props.children}
      </chakra.a>
    ) : (
      <Box
        as={`button`}
        disabled={props.disabled}
        tabIndex={0}
        sx={style}
        className={props.className}
        name={props.name}
        onClick={props.onClick}
      >
        {props.children}
      </Box>
    )
  },
  Button = (props: ButtonProps) => {
    const { prefix, postfix, pending = false } = props,
      affixes = {
        prefix: prefix && <Icon size="sm">{prefix}</Icon>,
        postfix: postfix && <Icon size="sm">{postfix}</Icon>,
      }

    return (
      <ButtonWrapper {...props}>
        <Affix {...affixes} w="100%">
          <Center w="100%">
            <Text isTruncated as="div">
              {props.children}
            </Text>
          </Center>
        </Affix>
        {pending && <Loader />}
      </ButtonWrapper>
    )
  }

export default chakra(Button)
