import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import 'url-search-params-polyfill'

function useQueryParamState(
  key: string,
  defaultValue?: any
): [string, (newValue) => void] {
  const [query, querySet] = useSearchParams()

  const value = useMemo(
    () => query.get(key) || defaultValue,
    [defaultValue, key, query]
  )

  const handleOnQuerySet = useCallback(
    newValue => {
      const q = new URLSearchParams(query)
      if (newValue && newValue !== defaultValue) q.set(key, newValue)
      else q.delete(key)
      querySet(q, { replace: true })
    },

    [defaultValue, key, query, querySet]
  )

  return [value, handleOnQuerySet]
}

export default useQueryParamState
