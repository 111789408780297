import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpDetailsRentRollUploadNextProps = {} & BaseComponentProps

/**
 * HelpDetailsRentRollUploadNext component
 */
const HelpDetailsRentRollUploadNext = (
  props: HelpDetailsRentRollUploadNextProps
) => {
  return (
    <>
      <p>
        In the <b>Property Details</b> section you will be asked to provide
        details about occupancy, property uses, rent, and income and expense
        information, where applicable.
      </p>
      <p>Keep going! You are on your way to completing the application!</p>
    </>
  )
}

export default HelpDetailsRentRollUploadNext
