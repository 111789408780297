import { useEffect, useState } from 'react'

//===============================================

const useBlobFromLink = (link: string) => {
  const [blob, blobSet] = useState<string>()

  useEffect(() => {
    fetch(link)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
      )
      .then(dataUrl => blobSet(dataUrl as string))
  }, [link])

  return blob
}

export default useBlobFromLink
