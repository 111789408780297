import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { PortionRentFromAnyTenants } from 'constants/dicti'

const residentialUnitValidation = (unitType: string) => ({
  numberOfUnits: schemaRules.NOT_EMPTY_NUMBER,
  numberOfVacantUnits: Joi.when('...wereAnyUnitsVacant', {
    is: true,
    then: Joi.when('/vacantUnitsSum', {
      is: 0,
      then: schemaRules.GREATER_NUMBER(),
      otherwise: schemaRules.NOT_EMPTY_NUMBER.equal(
        Joi.expression('{[numberOfUnits]-[numberOfRentedUnits]}')
      ),
    }).messages({
      'any.only': `Number of Vacant and Rented Units must equal Total Number of ${unitType} Units (${Joi.expression(
        '{[numberOfUnits]}'
      )})`,
      'number.greater': 'At least 1 vacant unit should be reported',
    }),
    otherwise: schemaRules.ANY,
  }),
  numberOfRentedUnits: Joi.when('...wereAnyUnitsVacant', {
    is: true,
    then: schemaRules.NOT_EMPTY_NUMBER.equal(
      Joi.expression('{[numberOfUnits]-[numberOfVacantUnits]}')
    ),
    otherwise: schemaRules.NOT_EMPTY_NUMBER.equal(
      Joi.expression('{[numberOfUnits]}')
    ),
  }).messages({
    'any.only': `Number of Vacant and Rented Units must equal Total Number of ${unitType} Units (${Joi.expression(
      '{[numberOfUnits]}'
    )})`,
  }),
  monthlyRent: Joi.when('numberOfRentedUnits', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.GREATER_NUMBER().messages({
      'number.greater':
        'Monthly rent cannot be $0, as some apartments were rented',
    }),
    otherwise: schemaRules.VALID(null, 0),
  }),
  monthlyRentLossDueVacant: Joi.when('...wereAnyUnitsVacant', {
    is: true,
    then: Joi.when('numberOfVacantUnits', {
      is: schemaRules.GREATER_NUMBER(),
      then: schemaRules.GREATER_NUMBER().messages({
        'number.greater':
          'If there are vacant apartments, monthly rent loss cannot be $0',
      }),
      otherwise: schemaRules.VALID(null, 0),
    }),
    otherwise: null,
  }),
})

const schema = schemaWrapper({
  residentialUnits: {
    numberOfResidentialUnit: schemaRules.NOT_EMPTY_NUMBER,
    wereAnyUnitsVacant: schemaRules.BOOLEAN,
    regulatedUnits: residentialUnitValidation('Regulated'),
    unRegulatedUnits: residentialUnitValidation('Unregulated'),
    wereAnyUnitsVacant3Month: schemaRules.BOOLEAN,
    vacantApartmentNumber3Month: Joi.when('wereAnyUnitsVacant3Month', {
      is: true,
      then: schemaRules.NOT_EMPTY_STRING,
      otherwise: Joi.when('/general.portionVacantTroubleTenantCS', {
        switch: [
          {
            is: PortionRentFromAnyTenants.Residential,
            then: schemaRules.NOT_EMPTY_STRING,
          },
          {
            is: PortionRentFromAnyTenants.Both,
            then: schemaRules.NOT_EMPTY_STRING,
          },
        ],
        otherwise: schemaRules.ANY,
      }),
    }),
  },
})

export default schema
