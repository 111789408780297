import firebaseApp from 'helpers/firebaseInit'
import useAuth from 'hooks/useAuth'
import { useLocation } from 'react-router-dom'

const useRegisterEvent = name => {
  const location = useLocation(),
    { user } = useAuth()

  return (data = {}) => {
    const eventProperties = {
      page_path: location.pathname + location.search,
      page_name: location.pathname,
      ...data,
    }

    // console.log(111, name, eventProperties)
    firebaseApp.analytics().setUserId(user?.email || 'unknown')
    firebaseApp.analytics().setCurrentScreen(eventProperties.page_name)
    firebaseApp.analytics().logEvent(name, { ...eventProperties, ...data })
  }
}
export default useRegisterEvent
