import { useEffect } from 'react'

const ScrollToTop = ({ trigger }) => {
  const content = document.querySelector('#content')

  useEffect(() => {
    if (content) {
      content.scrollTo(0, 0)
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  return null
}

export default ScrollToTop
