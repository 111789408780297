import { Services } from 'constants/dicti'
import { URL_SERVICETALIST } from 'constants/URLS'
import { Config, getFullConfig, ItemsForKey } from 'helpers/config/utils'
import { PRECONF_FILTER_PL_TC150ONLY } from 'hooks/api/firebase/usePropertyList'
import {
  PRECONF_FILTER_DC_TC_NOTFILED,
  PRECONF_FILTER_DC_TCFORMSONLY,
} from 'hooks/api/my/MyDocumentsContext'

type TAConfig = {
  showTA: boolean
  showDC: boolean
  serviceCS: Services.TaxAppeal
  docCenterDefaultFilter?: string
  propertyListDefaultFilter?: ItemsForKey<string, 'propertyListDefaultFilter'>
} & Config

const taConfig: TAConfig = {
  homeUrl: URL_SERVICETALIST,
  showTA: true,
  showDC: true,
  serviceCS: Services.TaxAppeal,
  docCenterDefaultFilter: PRECONF_FILTER_DC_TCFORMSONLY,
},
  taCalendar = {
    '2023-12-01': {},
    '2024-03-02': {
      docCenterDefaultFilter: PRECONF_FILTER_DC_TC_NOTFILED,
      propertyListDefaultFilter: {
        [Services.TaxAppeal]: PRECONF_FILTER_PL_TC150ONLY,
      },
    },
    '2024-05-01': {
      showTA: false,
      propertyListDefaultFilter: {
        [Services.TaxAppeal]: undefined,
      },
    },
    '2024-12-01': {},
  }

export default getFullConfig<TAConfig>(taConfig, taCalendar)
