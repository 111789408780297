import React from 'react'
import { chakra } from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'

//===================================================
type Props = {} & ChakraComponentProps

/**
 * StepBasic component
 */
const StepBasic = (props: Props) => {
  return (
    <chakra.div
      className={props.className}
      pl="55px"
      position="relative"
      {...props}
    >
      {props.children}
    </chakra.div>
  )
}

export default chakra(StepBasic)
