import React, { useMemo } from 'react'

type EmptyProps = {
  type?: string
}

const Empty = ({ type = 'hyphen', ...props }: EmptyProps) => {
  const empty = useMemo((): HTMLElement => {
    const display = {
      longdash: <>&#8212;</>,
      hyphen: <>&#8208;</>,
    }

    return display[type as string]
  }, [type])

  return <>{empty}</>
}

export default Empty
