import { ComponentStyleConfig } from '@chakra-ui/theme'

const PageTitleStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'action', 'title', 'subtitle'],
  // Styles for the base style
  baseStyle: {
    wrapper: {
      height: '130px',
      flexDirection: 'column',
    },
    action: {
      alignItems: 'center',
      button: {
        '.icon': {
          svg: {
            width: '10px',
            height: '10px',
          },
        },
      },
      '@media print': {
        display: 'none',
      },
    },
    title: {
      color: 'fontnavy',
      fontWeight: 'semibold',
    },
    subtitle: {
      fontFamily: 'body',
      fontWeight: 'regular',
      color: 'fontnavy',
    },
  },
  // Styles for the size variations
  sizes: {
    md: {
      wrapper: {
        padding: '20px 0 24px 54px',
      },
      action: {
        paddingBottom: '12px',
        button: {
          height: 'auto',
        },
      },
      title: {
        fontSize: 'h6',
        lineHeight: '33px',
      },
      subtitle: {
        fontSize: 'body',
        lineHeight: 'body',
      },
    },
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default PageTitleStyle
