import styled from '@emotion/styled/macro'

type CellProps = {
  // className: string
  width?: number
  height?: number
  top?: number | string
  left?: number | string
  middle?: boolean
  space?: string
  align?: string
  center?: boolean
  area?: string
}

const Cell = styled.div<CellProps>(
  props => ({
    height: '100%',
    minWidth: 0,
    gridColumnEnd: props.width ? `span ${props.width}` : `span 1`,
    gridRowEnd: props.height ? `span ${props.height}` : `span 1`,
    gridColumnStart: props.left,
    gridRowStart: props.top,
    textAlign: props.center ? 'center' : undefined,
    gridArea: props.area,
    position: 'relative',
  }),
  props =>
    props.middle && {
      display: 'inline-flex',
      flexFlow: 'column wrap',
      justifyContent: 'center',
      justifySelf: 'stretch',
    },
  props =>
    props.space && {
      display: 'inline-flex',
      flexFlow: 'column',
      justifyContent: props.space,
      justifySelf: 'stretch',
    },
  props =>
    props.align && {
      display: 'inline-flex',
      flexFlow: 'column',
      alignItems: props.align,
      justifySelf: 'stretch',
    }
)

export default Cell
