import React, { ReactNode } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { useMultiStyleConfig, Flex, Divider } from '@chakra-ui/react'

//===================================================
export type PageTitleProps = {
  sticky?: boolean
  /**
   * Href for the BACK button. If it doesn't set dont show BACK button.
   */
  leftAction?: React.ReactChild
  /**
   * Title of the page
   */
  title?: string | ReactNode
  /**
   * Subtitle of the page
   */
  subtitle?: string | React.ReactNode
  /**
   * Desccription of the page
   */
  description?: string
  /**
   * Actions
   */
  actions?: React.ReactNode
  /**
   * Message surface
   */
  message?: any
} & BaseComponentProps

/**
 * PageTitle component
 */
const PageTitle = ({
  sticky,
  title,
  subtitle,
  description,
  leftAction,
  actions,
  message,
  ...props
}: PageTitleProps) => {
  const style = useMultiStyleConfig('MGNYPageTitle', props)

  return (
    <>
      <Flex sx={style.wrapper}>
        <Flex sx={style.action}>{leftAction}</Flex>
        <Flex sx={style.title}>{title}</Flex>
        <Flex sx={style.subtitle}>{subtitle}</Flex>
      </Flex>
      <Divider />
    </>
  )
}

export default PageTitle
