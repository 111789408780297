import React, { useMemo, useState } from 'react'
import { get } from 'object-path-immutable'

import BaseComponentProps from 'common/BaseComponentProps'
import CenterForm from './CenterForm'
import { Text } from '@chakra-ui/react'
import Input from 'ui-framework/components/primitives/Input'
import Button from 'ui-framework/components/primitives/Button'

import { AnyFunction, getNameAndErrorProps } from 'helpers/utils'
import useDoubleClickValidationCallback from 'hooks/useDoubleClickValidationCallback'
import { resetPasswordSchema } from 'pages/Auth/schema'

//===================================================
type PasswordResetConfirmFormProps = {
  onReset: AnyFunction
} & BaseComponentProps

/**
 * PasswordResetConfirmForm component
 */
const PasswordResetConfirmForm = ({
  onReset,
}: PasswordResetConfirmFormProps) => {
  const [password, passwordSet] = useState<string>('')

  const validationData = useMemo(() => ({ password }), [password]),
    [errors, onValidation] = useDoubleClickValidationCallback(
      validationData,
      resetPasswordSchema,
      onReset
    ),
    getError = path => get(errors, path)

  return (
    <CenterForm title="Reset Password">
      <Text align="center" mb="36px">
        Almost done. Enter your new password.
      </Text>

      <Input
        placeholder={`Enter your password`}
        type="password"
        label="Password"
        value={password}
        autocomplete="off"
        onChange={passwordSet}
        width={'100%'}
        fixedHeight={false}
        {...getNameAndErrorProps('password', getError)}
      />
      <Button w="100%" mt={`48px`} onClick={onValidation}>
        Reset Password
      </Button>
    </CenterForm>
  )
}

export default PasswordResetConfirmForm
