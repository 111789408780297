import { ApiObject, ApiError } from 'helpers/apiHelper'
import { useState } from 'react'

function useApiObject<T = any, E = ApiError | null>(
  initial?: ApiObject<T>
): [
  ApiObject<T | null, E>,
  (value: ApiObject<T>) => void,
  (value: T) => void,
  (value: boolean) => void,
  (value: E) => void
] {
  const [dataObject, dataObjectSet] = useState<ApiObject<T | null, E>>(
    initial || {
      isPending: false,
      data: null,
      error: null,
    }
  )

  const dataSet = value => dataObjectSet(pv => ({ ...pv, data: value }))
  const pendingSet = value => dataObjectSet(pv => ({ ...pv, isPending: value }))
  const errorSet = value => dataObjectSet(pv => ({ ...pv, error: value }))

  return [dataObject, dataObjectSet, dataSet, pendingSet, errorSet]
}

export default useApiObject
