import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import Checkbox from 'ui-framework/components/primitives/Checkbox'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import styled from '@emotion/styled/macro'
import { getCallback, getNameAndErrorProps } from 'helpers/utils'
import { Box } from '@chakra-ui/layout'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

//===================================================
type Props = {
  certified: boolean
} & BaseComponentProps &
  StepPropsInterface

type CardWrapperProps = {
  error: boolean
  certified: boolean
}
//TODO remove styled
const CardWrapper = styled('div')<CardWrapperProps>(props => ({
  width: '640px',
  borderRadius: '10px',
  padding: '16px',
  backgroundColor: props.certified
    ? '#F1F7E7'
    : props.error
    ? '#FFE9E8'
    : (props.theme as any).colors.secondary.sideMenu,
  color: (props.theme as any).colors.fontnavy,
}))

const schema = schemaWrapper({
  certified: schemaRules.BOOLEAN.valid(true),
})

/**
 * StepSubmit component
 */
const StepSubmit = ({
  certified = false,
  applyValidation,
  onValidation,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({
        certified,
      }),
      [certified]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    getError = name => Boolean(hasError(name))

  return (
    <StepBasic className={props.className}>
      <CardWrapper error={getError('certified')} certified={certified}>
        <Box as="div" mb="12px" lineHeight="24px">
          <b>
            If you are confident that all data is accurate, please read the
            certification below and press submit. Once submitted, the data will
            be reviewed, processed, and submitted to NYC. Should you require any
            edits, please reach out to MGNY immediately.
          </b>
        </Box>
        <Box as="div" mb="12px" lineHeight="24px">
          I certify that all information provided herein is true and correct to
          the best of my knowledge and belief, and I understand that the
          information is being relied upon by MGNY Consulting Corp. and the City
          of New York.
        </Box>
        <Checkbox
          mt="4px"
          checked={certified}
          onChange={getCallback(onChange)}
          {...getNameAndErrorProps('certified', getError)}
        >
          I Agree
        </Checkbox>
      </CardWrapper>
    </StepBasic>
  )
}

export default StepSubmit
