import firebaseApp from 'helpers/firebaseInit'

import useApiObject from './useApiObject'
import { ApiObject } from 'helpers/apiHelper'

function useRegisterUser(): [
  ApiObject<boolean | null, any | null>,
  (user: any) => void
] {
  const [register, , registerDataSet, registerPendingSet, registerErrorSet] =
      useApiObject<boolean | null>({
        data: null,
        isPending: false,
        error: null,
      }),
    handleRegister = async user => {
      registerPendingSet(true)
      registerDataSet(null)
      registerErrorSet(null)
      firebaseApp
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(() => {
          registerDataSet(true)
        })
        .catch(reason => {
          registerErrorSet(reason)
          registerDataSet(false)
        })
        .finally(() => {
          registerPendingSet(false)
        })
    }

  return [register, handleRegister]
}

export default useRegisterUser
