import { useState } from 'react'
import { chakra } from '@chakra-ui/react'
import AccordionItem from './AccordionItem'
import { set } from 'object-path-immutable'

//=============================

export enum AccordionExpandModes {
  single = 'SINGLE',
  multi = 'MULTI',
}

const handleToggle = (toggleFn, mode) => (item, key) => () =>
  toggleFn(prevState =>
    mode.includes(AccordionExpandModes.multi)
      ? set(prevState, [key, 'expanded'], !item.expanded)
      : prevState.map((x, i) => ({
          ...x,
          expanded: i !== key ? false : !item.expanded,
        }))
  )

const Accordion = ({
  items,
  expandMode = AccordionExpandModes.multi,
  ...rest
}) => {
  const [list, listSet] = useState(items)
  const toggleFn = handleToggle(listSet, expandMode)

  return list.map((item, index) => (
    <AccordionItem
      key={index}
      handleToggle={toggleFn(item, index)}
      title={item.title}
      expanded={item.expanded}
      {...rest}
    >
      {item.content}
    </AccordionItem>
  ))
}
export default chakra(Accordion)
