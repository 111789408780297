import React, { useCallback, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Textarea from 'ui-framework/components/primitives/Textarea'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'
import {
  getCallback,
  getFiscalPeriods,
  getNameAndErrorProps,
} from 'helpers/utils'
import UploadDropZone from 'ui-framework/components/patterns/UploadDropZone'
import { FileUploaded } from 'hooks/useFirebaseFiles'
import { VStack, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import {
  PortionRentFromAnyTenants,
  PropertyUsage,
  DocumentType,
  PropertyType,
} from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { isResOrNonRes } from 'services/common/useFormDataHook'
import { useDocumentFiles } from 'hooks/api/documents/useDocumentFiles'

//===================================================
type Props = {
  propertyUsageCS: PropertyUsage
  applicantName: string
  wereAnySubstantialVacancies: boolean | null
  isCovid: boolean | null
  covidFiles: FileUploaded[]
  covidFilesPath: string
  whatPartsWereAffected: string | null
  whatTriggeredLowCollection: string | null
  howMuchIncomeWasLost: string | null
  portionVacantTroubleTenantCS: string | null
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyStatusCovid component
 */
const StepPropertyStatusCovid = ({
  propertyUsageCS,
  applicantName,
  wereAnySubstantialVacancies,
  isCovid,
  covidFiles: filesProp,
  covidFilesPath,
  whatPartsWereAffected,
  whatTriggeredLowCollection,
  howMuchIncomeWasLost,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  portionVacantTroubleTenantCS,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    isHotel = context?.bblsData?.propertyTypeCS === PropertyType.Hotel,
    { reportingYear } = getFiscalPeriods(context),
    isPortionVacantQuestionShown = useMemo(
      () => wereAnySubstantialVacancies && !isResOrNonRes(propertyUsageCS),
      [wereAnySubstantialVacancies, propertyUsageCS]
    ),
    validationData = useMemo(
      () => ({
        isPortionVacantQuestionShown,
        propertyUsageCS,
        isCovid,
        wereAnySubstantialVacancies,
        portionVacantTroubleTenantCS,
        whatTriggeredLowCollection,
        whatPartsWereAffected,
        howMuchIncomeWasLost,
      }),
      [
        isPortionVacantQuestionShown,
        propertyUsageCS,
        isCovid,
        wereAnySubstantialVacancies,
        portionVacantTroubleTenantCS,
        whatTriggeredLowCollection,
        whatPartsWereAffected,
        howMuchIncomeWasLost,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError),
    {
      handleFilesUpload,
      files,
      removeOrAddDoc,
      handleFilesDelete,
      handleDownload,
      handleRetryUpload,
    } = useDocumentFiles(context?.ref, DocumentType.Covid),
    handleCovidChange = useCallback(
      (isChecked, name) => {
        const covidOnChange = getCallback(onChange)

        covidOnChange(isChecked, name)
        if (isChecked && !whatTriggeredLowCollection) {
          covidOnChange('COVID-19', 'whatTriggeredLowCollection')
        }

        removeOrAddDoc(isChecked)
      },
      [onChange, removeOrAddDoc, whatTriggeredLowCollection]
    )

  useEffect(() => {
    if (wereAnySubstantialVacancies === true) {
      if (isCovid === null)
        getCallback(onChange)(false, 'isCovid')
      if (isPortionVacantQuestionShown && portionVacantTroubleTenantCS === null)
        getCallback(onChange)(PortionRentFromAnyTenants.Residential, 'portionVacantTroubleTenantCS')
    } else {
      getCallback(onChange)(null, 'isCovid')
      getCallback(onChange)(null, 'portionVacantTroubleTenantCS')

      getCallback(onChange)(null, 'whatTriggeredLowCollection')
      getCallback(onChange)(null, 'whatPartsWereAffected')
      getCallback(onChange)(null, 'howMuchIncomeWasLost')
    }
  }, [onChange, wereAnySubstantialVacancies])

  return (
    <StepBasic className={props.className} paddingRight="24px">
      <VStack spacing={`36px`} align="left" width={'685px'}>
        <SelectsGroup
          label={
            isHotel
              ? `In ${reportingYear}, were hotel reservations significantly low on average?`
              : `Throughout ${reportingYear}, were there any substantial vacancies longer than 3 months or difficulties collecting rent from any tenants?`
          }
          error={hasError('wereAnySubstantialVacancies')}
        >
          <Radiobutton
            value={wereAnySubstantialVacancies}
            name="wereAnySubstantialVacancies"
            selected={false}
            disabled={disabled}
            onChange={onChange}
          >
            No
          </Radiobutton>
          <Radiobutton
            value={wereAnySubstantialVacancies}
            name="wereAnySubstantialVacancies"
            selected={true}
            disabled={disabled}
            onChange={onChange}
          >
            Yes
          </Radiobutton>
        </SelectsGroup>

        {isPortionVacantQuestionShown && (
          <SelectsGroup
            label={`Which portion of the property was vacant or had difficulties collecting rent from any tenants in ${reportingYear}?`}
            error={hasError('portionVacantTroubleTenantCS')}
          >
            <Radiobutton
              value={portionVacantTroubleTenantCS}
              name="portionVacantTroubleTenantCS"
              selected={PortionRentFromAnyTenants.Residential}
              disabled={disabled}
              onChange={onChange}
            >
              Residential only
            </Radiobutton>
            <Radiobutton
              value={portionVacantTroubleTenantCS}
              name="portionVacantTroubleTenantCS"
              selected={PortionRentFromAnyTenants.NonResidential}
              disabled={disabled}
              onChange={onChange}
            >
              Non-residential only
            </Radiobutton>
            <Radiobutton
              value={portionVacantTroubleTenantCS}
              name="portionVacantTroubleTenantCS"
              selected={PortionRentFromAnyTenants.Both}
              disabled={disabled}
              onChange={onChange}
            >
              Both
            </Radiobutton>
          </SelectsGroup>
        )}
        {wereAnySubstantialVacancies && (
          <>
            {/* <SelectsGroup
              label={`Were ${
                isHotel
                  ? 'low hotel reservations'
                  : 'the vacancies / lower collections'
              } related to COVID-19?`}
              error={hasError('wereAnySubstantialVacancies')}
            >
              <Radiobutton
                value={isCovid}
                selected={false}
                name="isCovid"
                disabled={disabled}
                onChange={handleCovidChange}
              >
                No
              </Radiobutton>
              <Radiobutton
                value={isCovid}
                selected={true}
                name="isCovid"
                disabled={disabled}
                onChange={handleCovidChange}
              >
                Yes
              </Radiobutton>
            </SelectsGroup> */}

            <VStack spacing={`36px`} align="left">
              <Textarea
                label={`What triggered ${isHotel
                    ? 'low hotel reservations'
                    : 'the vacancy / lower collections'
                  }?`}
                value={whatTriggeredLowCollection}
                disabled={disabled}
                onChange={onChange}
                showCounter={false}
                width="560px"
                placeholder={`Enter`}
                rows={5}
                {...nameAndErrorProps('whatTriggeredLowCollection')}
              />
              <Textarea
                label="Specifically what parts of the property were affected and for how long?"
                value={whatPartsWereAffected}
                disabled={disabled}
                onChange={onChange}
                showCounter={false}
                width="560px"
                placeholder={`Enter`}
                rows={5}
                {...nameAndErrorProps('whatPartsWereAffected')}
              />
              <Textarea
                label={
                  isHotel ? (
                    <>
                      Approximately how much income from room reservations was
                      lost in {reportingYear} <br />
                      as a result?
                    </>
                  ) : (
                    <>
                      Approximately how much rental income was lost in{' '}
                      {reportingYear} as a result of vacancy
                      <br />/ lower collections?
                    </>
                  )
                }
                showCounter={false}
                value={howMuchIncomeWasLost}
                disabled={disabled}
                onChange={onChange}
                width="560px"
                placeholder={`Enter`}
                rows={5}
                {...nameAndErrorProps('howMuchIncomeWasLost')}
              />
            </VStack>

            {isCovid && (
              <HelpTarget name="covidAttentionBox">
                <AttentionBox
                  title={`If you have any documents which can substantiate COVID-19 related losses, you can upload them now. ${isHotel ? 'For example:' : ''
                    }`}
                  w="560px"
                  variant="warning"
                >
                  <Text
                    textStyle={`body.regular`}
                    color={`fontnavy`}
                    lineHeight={`25px`}
                  >
                    {isHotel ? (
                      <UnorderedList styleType={"'-'"} ml="4px">
                        <ListItem pl="8px">
                          an overall statement of how the pandemic has affected
                          the income of the hotel this year;
                        </ListItem>
                        <ListItem pl="8px">
                          a report showing a decrease in bookings and/or average
                          daily rates or that documents cancellations
                        </ListItem>
                      </UnorderedList>
                    ) : (
                      <>
                        By documents it means: i.e., reports of tenants'
                        arrears, requests for rent payments relief, lease
                        amendments, and related communications.
                      </>
                    )}
                  </Text>
                </AttentionBox>
              </HelpTarget>
            )}
          </>
        )}
      </VStack>
      {isCovid && (
        <UploadDropZone
          files={files}
          onUpload={handleFilesUpload}
          disabled={disabled}
          onDelete={handleFilesDelete}
          onRetry={handleRetryUpload}
          onDownload={f => f.filePath && handleDownload(f.filePath)}
        />
      )}
    </StepBasic>
  )
}

export default StepPropertyStatusCovid
