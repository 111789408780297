import React from 'react'
import { useMultiStyleConfig, Box } from '@chakra-ui/react'
import { TabItem } from '..'
import { OnClickHandler } from 'ui-framework/common/types'
import { keyboardEventListener } from 'helpers/utils'
import { chakra, ChakraProps } from '@chakra-ui/react'

type TabProps = {
  active?: boolean
  onClick?: OnClickHandler
  variant?: string
} & TabItem &
  ChakraProps

const Tab = (props: TabProps) => {
  const { title, disabled, onClick, label, ...rest } = props,
    style = useMultiStyleConfig('MGNYTab', props)

  return (
    <Box
      role="tab"
      sx={style.tab}
      // @ts-ignore
      disabled={disabled}
      onClick={onClick}
      onKeyDown={e => keyboardEventListener(e, onClick)}
      tabIndex={0}
      {...rest}
    >
      <Box sx={style.title}>{title}</Box>
      {label && <Box sx={style.label}>({label})</Box>}
    </Box>
  )
}

export default chakra(Tab)
