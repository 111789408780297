import React, { memo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { chakra, ChakraProps, Flex } from '@chakra-ui/react'

//===================================================

const HelpTargetWrapper = chakra(Flex)

type Props = {
  name: string
  targetSelector?: string
  area?: 'tl' | 'tm' | 'tr' | 'ml' | 'mr' | 'bl' | 'bm' | 'br'
  direction?: 'ml' | 'mr' | 'tl' | 'tr' | 'bl' | 'br' | 'tm' | 'bm'
} & BaseComponentProps & ChakraProps

/**
 * HelpTarget component
 */
const HelpTarget = (props: Props) => {
  const { name, targetSelector, area, children, direction, ...rest } = props
  return (
    <HelpTargetWrapper
      className={props.className}
      data-arrow-name={name}
      data-arrow-target={targetSelector}
      data-arrow-target-area={area}
      data-arrow-direction={direction}
      flexGrow={1}
      {...rest}
    >
      {children}
    </HelpTargetWrapper>
  )
}

export default memo(chakra(HelpTarget))
