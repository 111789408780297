import React, { useState, useMemo, useEffect, useCallback } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import Button from 'ui-framework/components/primitives/Button'
import Input from 'ui-framework/components/primitives/Input'
import firebaseApp from 'helpers/firebaseInit'
import { URL_HOME, URL_AUTH_RESET } from 'constants/URLS'
import { VStack, Flex } from '@chakra-ui/layout'
import useShowPassword from 'hooks/useShowPassword'
import CenterForm from './CenterForm'
import useRegisterEvent from 'hooks/analytics/useRegisterEvent'
import useInvite from 'hooks/useInvite'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { set, wrap } from 'object-path-immutable'
import useBrowserAutofill from 'hooks/useBrowserAutofill'
import useValidation from 'hooks/useValidation'
import { loginSchema } from 'pages/Auth/schema'
import useAuth from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'

//====================================================
type Props = {} & BaseComponentProps
type Credentials = {
  email?: string
  password?: string
}
type CatchData = {
  loginEmail?: string
  loginPassword?: string
}
/**
 * LoginForm component
 */
const LoginForm = (props: Props) => {
  const navigate = useNavigate(),
    { profile, isPending: isPendingInvite } = useInvite(),
    { isLogged } = useAuth(),
    [credentials, credentialsSet] = useState<Credentials>(),
    [type, showIcon] = useShowPassword(),
    messageForExistingUserFromInvite = useMemo(
      () =>
        profile?.hasCreds && (
          <Flex
            p={'16px'}
            borderRadius={'10px'}
            bg={'purple.opacity'}
            border={'1px solid'}
            borderColor={'primary.base'}
            mb={'24px'}
          >
            <TextFromParams params={{ lineHeight: '25px', textAlign: 'left' }}>
              There's an existing user associated with this email. If you forgot
              your password - you may reset it by clicking "Forgot password"
              below.
            </TextFromParams>
          </Flex>
        ),
      [profile?.hasCreds]
    )

  const [catchData, catchDataSet] = useState<CatchData>(),
    [errors] = useValidation(catchData, loginSchema),
    clearErrors = () => {
      catchDataSet({
        loginEmail: '',
        loginPassword: '',
      })
    },
    handleCatch = useCallback(
      ({ code }) => {
        catchDataSet({
          loginEmail: code,
          loginPassword: code,
        })
      },
      [catchDataSet]
    ),
    handleCredentialsChange = useCallback((value, name) => {
      credentialsSet(prev => set(prev, name, value))
      clearErrors()
    }, [])

  const handleRedirect = () => {
      const desired_url = localStorage.getItem('desired_url') || URL_HOME
      localStorage.removeItem('desired_url')
      navigate(desired_url)
    },
    registerReset = useRegisterEvent('reset_password_clicked'),
    handleReset = () => {
      registerReset()
      navigate(URL_AUTH_RESET)
    },
    signIn = e => {
      e.preventDefault()
      firebaseApp
        .auth()
        .signInWithEmailAndPassword(
          credentials?.email as string,
          credentials?.password as string
        )
        .catch(handleCatch)
    },
    clearLoginForm = () => {
      credentialsSet(undefined)
      clearErrors()
    },
    clearPassword = () => {
      credentialsSet(prev => ({
        email: prev?.email,
        password: '',
      }))
      clearErrors()
    }

  useEffect(() => {
    if (profile?.email) credentialsSet({ email: profile?.email })
  }, [profile?.email])

  isLogged && handleRedirect()

  useBrowserAutofill()

  if (isPendingInvite) return null //<Loader />

  return (
    <CenterForm title="Log in">
      {messageForExistingUserFromInvite}
      <VStack spacing="36px" width="100%">
        <Input
          placeholder={`Enter your email`}
          label="Email"
          name="email"
          value={credentials?.email || null}
          onChange={handleCredentialsChange}
          width={'100%'}
          error={errors?.loginEmail}
          onClear={clearLoginForm}
        />
        <Input
          placeholder={`Enter your password`}
          label="Password"
          type={type as any}
          name="password"
          value={credentials?.password || null}
          onChange={handleCredentialsChange}
          error={errors?.loginPassword}
          width={'100%'}
          postfix={showIcon}
          onClear={clearPassword}
        />
        <Flex width={'100%'} direction="column" alignItems="center">
          <Button
            disabled={!credentials?.email || !credentials?.password}
            onClick={signIn}
            width={'100%'}
          >
            Log In
          </Button>
          <Button
            variant={`textPrimary`}
            marginTop={`24px`}
            height={`auto`}
            onClick={handleReset}
          >
            Forgot password
          </Button>
        </Flex>
      </VStack>
    </CenterForm>
  )
}

export default LoginForm
