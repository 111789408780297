import dayjs, { unix } from 'dayjs'
import { MutableRefObject, useCallback, useMemo, useRef } from 'react'
import createPersistedState from 'use-persisted-state'

export const useAssistantOnboardingShown = createPersistedState(
  'assistant_onboarding_shown'
)

export const useAssistantOnboardingState = () => {
  const [assistantOnboardingDate, assistantOnboardingDateSet] =
      useAssistantOnboardingShown<string | boolean>(false),
    update = useCallback(
      () => assistantOnboardingDateSet(dayjs().add(10, 'days').unix()),
      [assistantOnboardingDateSet]
    ),
    shouldShow = useMemo(
      () =>
        !assistantOnboardingDate ||
        unix(assistantOnboardingDate).isBefore(dayjs()),
      [assistantOnboardingDate]
    ),
    updateOnLogin = useCallback(
      () => !shouldShow && update(),
      [shouldShow, update]
    )

  return { shouldShow, update, updateOnLogin }
}

type UseAssistantOnboarding = (
  isOpen: boolean
) => [MutableRefObject<HTMLDivElement | undefined>, boolean, () => void]

const useAssistantOnboarding: UseAssistantOnboarding = isOpen => {
  const assistantOnboardingState = useAssistantOnboardingState(),
    assistantRef = useRef<HTMLDivElement>(),
    showOnboardingPopup = useMemo(
      () => isOpen && assistantOnboardingState.shouldShow,
      [assistantOnboardingState.shouldShow, isOpen]
    )

  return [assistantRef, showOnboardingPopup, assistantOnboardingState.update]
}

export default useAssistantOnboarding
