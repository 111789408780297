import React, { ReactNode } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import { ButtonsTop } from '../HelpBlock/styles/HelpBlock'
import Chips from 'ui-framework/components/primitives/Chips'
import { chakra, Flex, Text } from '@chakra-ui/react'

type SubtitleWrapperProps = {
  margin?: string | number
}

export const SubtitleWrapper = chakra('div', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'space-between',
    padding: `24px 78px 24px 0`,
    color: 'fontnavy',
  },
})

//===================================================
type Props = {
  title?: string
  description?: string
  buttons?: ReactNode
} & BaseComponentProps &
  SubtitleWrapperProps

/**
 * Subtitle component
 */
const Subtitle = ({ title, description, buttons, margin, ...props }: Props) => {
  return (
    <SubtitleWrapper className={props.className} margin={margin}>
      <Flex alignItems="center">
        <Text as="h2" textStyle="h6">
          {title}
        </Text>
        {description && (
          <Chips prefix={true} variant="orange" ml="16px">
            {description}
          </Chips>
        )}
      </Flex>
      <ButtonsTop>{buttons}</ButtonsTop>
    </SubtitleWrapper>
  )
}

export default Subtitle
