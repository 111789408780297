import IMask from 'imask'
import { integerPositiveThousands, uiFullDateFormat } from './formats'
import { isNullOrWhiteSpace } from 'helpers/utils'
import Empty from 'ui-framework/components/primitives/Empty'
import { flow } from 'lodash'
import dayjs from 'dayjs'

const prefixWith = prefix => value => `${prefix}${value}`,
  formatValue = formatterFn => value => formatterFn(value)

const orEmpty =
  (fn, emptyValue: any = <Empty />) =>
  value =>
    isNullOrWhiteSpace(value) || typeof value === 'object' || value === 0
      ? emptyValue
      : fn(value)

const formatterIMask =
  format =>
  (value: string | number): string =>
    IMask.pipe(String(value), format)

const formatterCurrency = flow(
  formatterIMask(integerPositiveThousands),
  prefixWith('$')
)

const formatterDate = (date: string, format = uiFullDateFormat) =>
  dayjs(date).format(format)

const formatToCurrency = formatValue(formatterCurrency),
  formatToNumberITS = formatValue(formatterIMask(integerPositiveThousands))

// orEmpty decorator may be used to construct formatters as needed
const formatToCurrencyOrDash = orEmpty(formatToCurrency),
  formatToNumberITSOrDash = orEmpty(formatToNumberITS),
  formatToCurrencyOrLongdash = orEmpty(
    formatToCurrency,
    <Empty type={`longdash`} />
  ),
  formatToNumberITSOrLongdash = orEmpty(
    formatToNumberITS,
    <Empty type={`longdash`} />
  )
const formatFullDate = formatValue(formatterDate)
export {
  formatToCurrency,
  formatToNumberITS,
  formatToCurrencyOrDash,
  formatToNumberITSOrDash,
  formatToCurrencyOrLongdash,
  formatToNumberITSOrLongdash,
  orEmpty,
  prefixWith,
  formatValue,
  formatFullDate,
}
