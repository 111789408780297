import {
  URL_SERVICETALIST,
  URL_DOCUMENTS,
  URL_SERVICERPIELIST,
  URL_HANDLING,
} from 'constants/URLS'
import { Resource, Action } from 'constants/grants'
import { Config } from 'helpers/config/utils'
import { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { generatePortalPath } from 'helpers/utils'

export type Navigation = NavigationGroup[]

type NavigationGroup = {
  subTitle: string
  children: NavigationItem[]
}

type NavigationItem = {
  module: string | ReactNode
  resource: string
  action: string
  route: string
  hidden: boolean
  icon?: string
}

const navigation = (config: Config, hasNewDocs: boolean): Navigation => [
  {
    subTitle: 'services',
    children: [
      // {
      //   module: 'Home',
      //   route: URL_HOME,
      //   icon: 'home',
      //   resource: Resource.PUBLIC,
      //   action: Action.READ,
      //   hidden: false,
      // },
      {
        module: 'Tax Appeal',
        route: URL_SERVICETALIST,
        icon: 'bag',
        resource: Resource.TA_PROPERTYLIST,
        action: Action.READ,
        hidden: !config.showTA,
      },
      {
        module: 'RPIE',
        route: URL_SERVICERPIELIST,
        icon: 'home',
        resource: Resource.RPIE_PROPERTYLIST,
        action: Action.READ,
        hidden: !config.showRPIE,
      },
    ],
  },
  {
    subTitle: 'documents',
    children: [
      {
        module: (
          <Flex
            align={'center'}
            justify={'space-between'}
            w={'100%'}
            marginRight={'4px'}
          >
            <span>Document Center</span>
            {hasNewDocs && (
              <Box
                sx={{
                  background: '#4D59EB',
                  borderRadius: '4px',
                  padding: '4px',
                  color: 'white',
                }}
              >
                New
              </Box>
            )}
          </Flex>
        ),
        route: generatePortalPath(
          URL_DOCUMENTS,
          hasNewDocs
            ? {
                tab: 'Download Now',
              }
            : {},
          true
        ),
        icon: 'docs',
        resource: Resource.DOCUMENT_CENTER,
        action: Action.READ,
        hidden: !config.showDC,
      },
    ],
  },
  {
    subTitle: 'other',
    children: [
      {
        module: 'Handling',
        route: URL_HANDLING,
        icon: 'patch',
        resource: Resource.ADMIN,
        action: Action.ANY,
        hidden: false,
      },
    ],
  },
]

export default navigation
