import { ConfigSchedule } from './scheduler'
import dayjs from 'dayjs'
import { isObject } from 'helpers/utils'

//Defaults=================================================================================
export interface Config {
  homeUrl: string
  [key: string]: any
}

export type CalendarConfig = ConfigSchedule<Config>

//Actions==================================================================================

const mergeConfigs = (...args) => {
  const setFilledValues = data => {
    const keys = Object.keys(data).sort()
    keys.forEach((k, i) => {
      const prevValue = data[keys[i - 1]]
      data[k] = { ...prevValue, ...data[k] }
      if (prevValue) {
        Object.entries(data[k]).forEach(([fld, value]) => {
          if (isObject(value)) {
            data[k][fld] = { ...prevValue[fld], ...data[k][fld] }
          }
        })
      }
    })
  }

  return args.reduce((result, arg, ind, arr) => {
    if (arg) {
      Object.keys(arg).forEach(k => (result[k] = { ...result[k], ...arg[k] }))
      if (ind === arr.length - 1) setFilledValues(result)
    }
    return result
  }, {})
}

const getFullConfig = <T extends Config>(
  defaultConfig: ConfigSchedule<T>,
  calendarItems: object
): ConfigSchedule<T> =>
  Object.entries(calendarItems).reduce((result, [item, value]) => {
    result[item] = value ? { ...defaultConfig, ...value } : {}

    return result
  }, {})
type Key = string | number | symbol
export type ItemsForKey<K extends Key, T> = Record<
  K,
  { [name: Key]: { [key: Key]: T } }
>

const makeItemsForKey = <K extends Key, T extends object>(
  key: K,
  items: T
): ConfigSchedule<ItemsForKey<K, T>> =>
  Object.entries(items).reduce((res, [itemKey, value]) => {
    const makeItem = ([k, v]) =>
      (res[itemKey] = { ...res[itemKey], [k]: { [key]: v } })

    Object.entries(value).forEach(makeItem)
    return res
  }, {})

//TODO review logic
const getDateByItems =
  appBuildYear =>
  (day, month, endOf: boolean = true, yearStep = 0) => {
    const year = appBuildYear + yearStep
    const date = dayjs().year(year).month(month).date(day)
    return date[endOf ? 'endOf' : 'startOf']('day')
  }

export { mergeConfigs, getFullConfig, getDateByItems, makeItemsForKey }
