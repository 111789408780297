import HelpWhatToExpectDescription from './HelpWhatToExpectDescription'

//===================================================

const HelpWhatToExpect = {
  title: 'What to Expect',
  description: <HelpWhatToExpectDescription />,
}

export default HelpWhatToExpect
