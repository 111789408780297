import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import dayjs from 'dayjs'
import { AccountingBasis, ReportingPeriodType } from 'constants/dicti'

const schema = schemaWrapper({
  hasCustomPeriod: schemaRules.BOOLEAN,
  reportingPeriodTypeCS: Joi.when('hasCustomPeriod', {
    is: true,
    then: schemaRules.VALID_STRING(
      ReportingPeriodType.Calendar,
      ReportingPeriodType.Fiscal,
      ReportingPeriodType.Partial
    ),
    otherwise: schemaRules.ANY,
  }),
  startDate: schemaRules.DATE,
  endDate: schemaRules.DATE.greater(Joi.ref('startDate'))
    .custom((value, helpers) => {
      const { reportingPeriodTypeCS, endDate, startDate } =
        helpers?.prefs?.context?.originalValue,
        datesRangeInMonths = dayjs(endDate).diff(dayjs(startDate), 'month')

      if (!reportingPeriodTypeCS) return

      if (
        reportingPeriodTypeCS === ReportingPeriodType.Fiscal &&
        !dayjs(endDate)
          .endOf('day')
          .isSame(
            dayjs(startDate).add(1, 'year').subtract(1, 'day').endOf('day')
          )
      ) {
        return helpers.error('custom.fiscalyear')
      }

      if (
        reportingPeriodTypeCS === ReportingPeriodType.Partial &&
        (datesRangeInMonths < 3 || datesRangeInMonths >= 12)
      ) {
        return helpers.error('custom.paritalyear')
      }
    })
    .messages({
      'custom.fiscalyear': 'The fiscal year must be one full year.',
      'custom.paritalyear':
        'Incorrect period. Partial Year must be more than 3 and less than 12 months.',
      'date.greater': 'Must be greater than Start Date',
    }),
  accountingBasisCS: schemaRules.VALID_STRING(
    AccountingBasis.Accrual,
    AccountingBasis.Cash
  ),
})

export default schema
