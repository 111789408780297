import { useCallback, useEffect, useMemo, useState } from 'react'
import firebaseApp from 'helpers/firebaseInit'
import { ApiObject } from 'helpers/apiHelper'
import {
  ActualAVType,
  PropertyType,
  RPIEStatuses,
  Services,
  SFApplicationStatus,
  TAStatuses,
} from 'constants/dicti'
import { APP_VERSION, log } from 'helpers/utils'
import useLocalObjectState from 'hooks/useLocalObjectState'
import {
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { useMyApplication } from 'hooks/api/my/MyApplicationsContext'
import { del, merge, set, wrap } from 'object-path-immutable'
import { OrNull } from 'services/common/useFormDataHook'
import useApplicationReducer, { APP_ACTIONS } from 'hooks/api/firebase/useApplicationReducer'

export type DBApiObject<T> = {
  ref: DocumentReference<DocumentData>
} & ApiObject<T>

export interface DBApplication { }

export type PropertyBBL = {
  block: number
  borough: number
  boroughName: string
  houseNum: string
  id: string
  isCondoRange: boolean
  lot: string
  state: string
  street: string
  zip: string
  assesmentData: {
    actualAVFiscalYear: number
    actualAVTypeCS: ActualAVType
    //TODO: rename tentativeActualAV to actualAV on backend and frontend
    tentativeActualAV: number | null
    tentativeTaxesAmount: number | null
  }
  propertyTypeCS: PropertyType
  taxClass: string | Array<string>
  buildingClasses: Array<string>

  // address: string
  // shortAddress: string
  // taxClass: string
  // buildingClass: string
  // applicantName: string | null
  // serviceID: number
  // serviceName: string
  // coreBBLE_ID: number
}

type MetaData = { userAgent: string; buildVersion: string; currentStepId: string; steps: any }

export type ServiceMetaData<T = {}> = T & MetaData

export type Application<T, E = {}> = {
  id: string
  ref: DocumentReference<Application<T, E>>
  accountID: string
  filingManager: OrNull<string>
  bblsData: PropertyBBL

  fiscalYear: number
  hasUnpaidInvoice: boolean
  isAllowedForWeb: boolean
  isConsolidated: boolean
  isRootConsolidateBBL: boolean
  rootConsolidateCoreBBLE_ID: string
  unpaidInvoicePageURL: string
  applicationSFStatusCS: SFApplicationStatus
  hasTC150Tag: boolean

  serviceCS: Services
  statusCS: string
  defaultServiceData: T
  serviceData?: T | null
  serviceMetaData: ServiceMetaData<E>
  users: string[]
  stag: string
}

export type UseApplicationResult<T, E = {}> = DBApiObject<
  Application<T, E> | undefined | null
> & {
  updatedOutside: boolean
  remoteData?: Application<T, E>
  dataSave: () => any
  serviceDataSet: (serviceData: OrNull<T>) => any
  statusSet: (statusCS: string) => any
  serviceMetadataSet: (value: Partial<E>) => any | void
}

const db = getFirestore(firebaseApp)

// =========================================================

const useApplication = <T extends DBApplication, E = {}>(
  applicationId: string,
  initialStatus: string
): UseApplicationResult<T, E> => {
  const {
    data: remoteData,
    isPending,
    error,
  } = useMyApplication(applicationId),
    ref = useMemo(() =>
      remoteData?.ref || doc(db, 'applications', applicationId)
      , [remoteData]),
    [localData, dispatch, updatedOutside]
      = useApplicationReducer<T, E>(remoteData)

  const dataSave = useCallback(() => {
    if (!localData) return
    log('useApplication SAVE', localData)
    dispatch({ type: APP_ACTIONS.SAVE, update: true })
    return Promise.resolve()
  }, [localData]),
    serviceDataSet = useCallback(
      (serviceData: OrNull<T>) => {
        if (!localData) return
        return dispatch({
          type: APP_ACTIONS.SET_SERVICE_DATA,
          serviceData,
          update: false,
        })
      },
      [localData]
    ),
    statusSet = useCallback(
      (statusCS: string) => {
        if (!localData) return

        return dispatch({
          type: APP_ACTIONS.SET_STATUS,
          statusCS,
          update: false,
        })
      },
      [localData]
    ),
    serviceMetadataSet = useCallback(
      (value: Partial<E>) => {
        if (!localData) return
        return dispatch({
          type: APP_ACTIONS.SET_SERVICE_METADATA,
          serviceMetaData: value,
          update: false,
        })
      },
      [localData]
    )

  useEffect(() => {
    if (localData?.stag && remoteData?.stag !== localData?.stag) {
      log('useApplication SAVED', localData)
      setDoc(ref, localData)
    }
  }, [localData?.stag])

  return {
    data: localData,
    isPending,
    error,
    dataSave,
    serviceDataSet,
    statusSet,
    serviceMetadataSet,
    remoteData: remoteData as Application<T, E>,
    ref,
    updatedOutside,
  }
}

export default useApplication
