import React, { useMemo, ReactNode, memo } from 'react'
import { get } from 'object-path-immutable'
import { chakra, Flex } from '@chakra-ui/react'
import Chips from '../Chips'
import { AnyFunction, isCallback } from 'helpers/utils'
import { nanoid } from 'nanoid'
import isEqual from 'lodash/isEqual'

type DependItem = {
  title: string | AnyFunction | ReactNode
  field: string
  notEqual: Array<any>
  chipVariant: string
}

type PringlsLineProps = {
  data: any
  depends: Array<DependItem>
}

const PringlsLine = (props: PringlsLineProps) => {
  const { data, depends, ...rest } = props,
    items = useMemo(() => {
      return depends
        .map(depend => {
          const { field, notEqual, chipVariant = 'gray', title } = depend,
            value = get(data, field)

          if (!notEqual.includes(value)) {
            return (
              <Chips variant={chipVariant} m="8px 8px 0 0" key={nanoid()}>
                {isCallback(title) ? (title as AnyFunction)(value) : title}
              </Chips>
            )
          }
          return undefined
        })
        .filter(x => x)
    }, [data, depends])

  return !!items.length && data ? (
    <>
      <Flex alignItems="center" flexWrap={`wrap`} {...rest}>
        {items}
      </Flex>
    </>
  ) : null
}

export default memo(chakra(PringlsLine) as any, isEqual)
