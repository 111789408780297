import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { Box, useStyles } from '@chakra-ui/react'

//===================================================
type ProgressBackgroundProps = {
  overflow?: string
} & BaseComponentProps

/**
 * ProgressBackground component
 */
const ProgressBackground = (props: ProgressBackgroundProps) => {
  const styles = useStyles()
  return (
    <Box sx={styles.background} overflow={props.overflow || 'hidden'}>
      {props.children}
    </Box>
  )
}

export default ProgressBackground
