import React, {
  useCallback,
  useMemo,
  useEffect,
  forwardRef,
  MutableRefObject,
  useState,
} from 'react'

import { useIMask, IMask } from 'react-imask'

import BaseComponentProps from 'common/BaseComponentProps'

import { getCallback, isNullOrWhiteSpace, log } from 'helpers/utils'
import { OnChangeHandler } from 'ui-framework/common/types'
import { integerPositive } from 'helpers/formats'

import useSharedRef from 'hooks/useSharedRef'

import { Box } from '@chakra-ui/layout'
import TextWithTooltip, { TextWithTooltipProps } from '../TextWithTooltip'
import { cloneDeep } from 'lodash-es'

//===================================================

export type InputTypes = 'text' | 'number' | 'password' | 'date'

type InputValueByType =
  | {
    type?: 'text' | 'password'
    value?: string | null
    onChange?: OnChangeHandler<string>
  }
  | {
    type: 'number'
    value?: number | null
    onChange?: OnChangeHandler<number>
  }
  | {
    type: 'date'
    value?: Date | null
    onChange?: OnChangeHandler<Date>
  }

export type InputBaseProps = {
  // /**
  //  * Value for controlled input
  //  */
  // value?: string | number | Date | null
  /**
   * Name of input.
   */
  name?: string
  // /**
  //  * Type of input.
  //  */
  // type?: InputTypes
  /**
   * Input mask settings
   * @default { mask: /^.*$/ }
   */
  format?: any
  /**
   * Disabled state
   * @default false
   */
  disabled?: boolean
  /**
   * read only state
   * @default false
   */
  readonly?: boolean
  /**
   * Autocomplete setting
   */
  autocomplete?: 'on' | 'off'
  /**
   * onChange handler
   */
  // onChange?: OnChangeHandler<number | string | Date>
  /**
   * onFocus handler
   */
  onFocus?: any
  onBlur?: any
  onKeyDown?: any
  inputRef?: MutableRefObject<HTMLInputElement | undefined>
  placeholder?: string | false | null
  state?: string
  maxLength?: number
} & BaseComponentProps &
  InputValueByType &
  Pick<TextWithTooltipProps, 'tooltipForTruncated'>

/**
 * InputBase component
 */
const InputBase = forwardRef(
  (
    {
      value,
      name,
      type = 'text',
      format: formatProp = {},
      placeholder = false,
      disabled = false,
      readonly = false,
      autocomplete = 'off',
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      inputRef,
      maxLength,
      ...props
    }: InputBaseProps,
    ref
  ) => {
    const format = useMemo(() => {
      // if format for number is not set then return default number mask
      if (type === 'number' && formatProp.mask !== Number)
        return cloneDeep(integerPositive)
      return cloneDeep(formatProp)
    }, [formatProp, type]),
      // =====
      inputType = useMemo(() => (type === 'password' ? type : 'text'), [type]),
      // =====
      isTyped = useMemo(() => type === 'date' || type === 'number', [type]),
      onAccept = useCallback(
        (value, maskRef, e?) => {
          if (!maskRef.masked.isComplete)
            if (isTyped && !maskRef.typedValue)
              getCallback(onChange)(maskRef.typedValue, name)
        },
        [isTyped, name, onChange]
      ),
      onComplete = useCallback(
        (value, maskRef, e) => {
          const toValue = () => {
            if (isTyped)
              return isNullOrWhiteSpace(maskRef.unmaskedValue)
                ? null
                : maskRef.typedValue
            return isNullOrWhiteSpace(maskRef.unmaskedValue)
              ? ''
              : maskRef.unmaskedValue
          }

          getCallback(onChange)(toValue(), name)
        },
        [isTyped, name, onChange]
      ),
      opts = useMemo(() => ({ onAccept, onComplete }), [onAccept, onComplete])

    const {
      ref: iRef,
      maskRef,
      value: valueInternal,
      setValue,
      typedValue,
      setTypedValue,
      unmaskedValue,
      setUnmaskedValue,
    } = useIMask(format, opts)

    // ======
    const fromValue = useMemo(() => {
      if (isTyped) return isNullOrWhiteSpace(value) ? null : value
      return isNullOrWhiteSpace(value) ? '' : String(value)
    }, [isTyped, value]),
      // ======
      handleOnChange = useCallback(
        e => {
          if (!maskRef.current)
            // @ts-ignore
            getCallback(onChange)(iRef!.current!.value, name)
        },
        [iRef, maskRef, name, onChange]
      )

    const inputSharedRef = useSharedRef(
      null,
      inputRef ? [inputRef, iRef as any] : [iRef]
    )

    useEffect(() => {
      if (maskRef.current) {
        if (isTyped) {
          if (fromValue !== typedValue) {
            // edge case. imask do not change 0 to null value. it leaves 0 in interal
            // so i need to change it manually in the strange way
            if (typedValue === 0) {
              setValue('')
              setTimeout(() => setTypedValue(null))
            } else
              setTypedValue(fromValue as any)
          }
        } else if (fromValue !== unmaskedValue) {
          setUnmaskedValue(fromValue as string)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromValue])

    const vvv = !maskRef.current ? { value: fromValue as string } : {}

    // @ts-ignore
    // log(name, 'v=', value, 't=', typedValue, 'u', unmaskedValue, 'i=', valueInternal, 's=', inputSharedRef.current?.value)
    return (
      <Box className={props.className}>
        <TextWithTooltip tooltipForTruncated={props.tooltipForTruncated}>
          <input
            ref={inputSharedRef}
            type={inputType}
            name={name}
            disabled={disabled}
            autoComplete={autocomplete}
            data-testid="input"
            readOnly={readonly}
            placeholder={placeholder || undefined}
            onChange={handleOnChange}
            onFocus={onFocus}
            onBlur={onBlur}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            {...vvv}
          />
        </TextWithTooltip>
      </Box>
    )
  }
)

export default InputBase
