// import './helpers/wdyr'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorkerRegistration'
import 'helpers/sentryInit'
import 'helpers/firebaseInit'
import 'helpers/assetsInit'

import dayjs from 'dayjs'
import Timezone from 'dayjs/plugin/timezone'
import Duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import reportWebVitals from './reportWebVitals'
import { ErrorBoundary } from '@sentry/react'
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'
import defaultTheme from 'ui-framework/theme/default.theme'
import SomethingWentWrongPage from 'pages/Errors/SomethingWentWrongPage'
import StubPage from 'pages/Errors/StubPage'

// Set default timezone for all new dayjs objects
dayjs.extend(Timezone)
dayjs.extend(Duration)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.tz.setDefault('America/New_York')

const isMobileOrTablet =
    // eslint-disable-next-line max-len
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    ),
  fontStyles = css(
    `@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');`
  )

const container = document.getElementById('root'),
  root = createRoot(container!)

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<SomethingWentWrongPage />}>
      <BrowserRouter>
        <Global styles={fontStyles} />
        <ChakraProvider theme={defaultTheme}>
          <ErrorBoundary fallback={<SomethingWentWrongPage />}>
            {isMobileOrTablet ? <StubPage /> : <App />}
          </ErrorBoundary>
        </ChakraProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker
  .unregister
  // {
  // onUpdate: registration => {
  //   console.log(111, registration)
  // },
  // }
  ()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
