import React, { useMemo, useState, useCallback, ReactNode } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'

import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'

import {
  IncomeItem,
  ExpenseItem,
  IncomeSection,
  ExpenseSection,
} from 'hooks/api/ta/TaFormData'

import {
  AnyFunction,
  getCallback,
  getTotalDiffWithText,
  isNullOrWhiteSpace,
  getFiscalPeriods,
  getFilteredDataByFieldAndCondition,
} from 'helpers/utils'

import { ChakraProps, chakra, useDisclosure } from '@chakra-ui/react'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import GroupedTables, {
  GroupedTableProps,
} from 'ui-framework/components/patterns/GroupedTables'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getIESectionIndexByType } from 'pages/Services/TA/TAFormPage/wizard/taWizardConfig'
import useSections from './useSections'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { formatToCurrencyOrDash } from 'helpers/formatter'

//=====================================================================================

type HelpItemProps = {
  title?: string | any
  description: string | any
}

export type SectionPrompts = {
  [fieldName: string]: {
    hidden?: boolean
    content: Array<HelpItemProps>
  }
}

type Item = {
  type: string | string[]
  title: string | ReactNode
  params?: SectionParams
  prompts?: SectionPrompts
  readOnly?: boolean
}
type TableParams = {
  children?: any
} & Omit<GroupedTableProps, 'children'>

type SectionsTableProps = {
  sections: Array<IncomeSection | ExpenseSection>
  items: Item[]
  totalFooterLabel: string
  modalParams?: { [key: string]: any }
  tableParams?: TableParams
  modalContent?: ReactNode | string
  hasError: AnyFunction
} & BaseComponentProps &
  StepPropsInterface<any> //TODO Add type instead of any

export type RemovedParams = {
  callback: AnyFunction
}

export type SectionParams = {
  categoriesEditType?: string | null
  categoriesForList?: { [key: string]: string } | null
  newLineOptions?: { [key: string]: any } | null
  visible?: boolean | null
  lastYearPrompt?: AnyFunction
  getSectionContent?: AnyFunction
}

const getStyledCell = (params = {}, elem = 'td' as any) =>
    chakra(elem, {
      baseStyle: {
        bgColor: '#F9FAFF',
        ...params,
      },
    }),
  StyledCell = getStyledCell(),
  basicText = {
    textAlign: 'left',
    color: 'fontandicongray',
  },
  basicTextHeader = {
    ...basicText,
    mt: '12px',
    mb: '6px',
  } as ChakraProps,
  basicTextFooter = {
    ...basicText,
    m: '14px 0px',
    textStyle: 'body.semibold',
    color: 'fontnavy',
  } as ChakraProps,
  basicTextFooterSubtotal = {
    ...basicTextFooter,
    textStyle: 'body.semibold',
    color: 'fontnavy',
  } as ChakraProps,
  emptyForManually = {
    categoryName: '',
    prevYearAmount: null,
    lastYearAmount: null,
  },
  emptyForList: IncomeItem | ExpenseItem = {
    categoryCS: null,
    prevYearAmount: null,
    lastYearAmount: null,
  },
  getTableFooter = (
    [totalPrev, totalLast, totalDiff]: any[],
    totalFooterLabel
  ) => (
    <tr style={{ height: '56px' }}>
      <StyledCell />
      <StyledCell>
        <TextFromParams params={basicTextFooter}>
          {totalFooterLabel}
        </TextFromParams>
      </StyledCell>
      <StyledCell>
        <TextFromParams params={basicTextFooter}>
          {formatToCurrencyOrDash(totalPrev)}
        </TextFromParams>
      </StyledCell>
      <StyledCell>
        <TextFromParams params={basicTextFooter}>
          {formatToCurrencyOrDash(totalLast)}
        </TextFromParams>
      </StyledCell>
      <StyledCell colSpan={3}>{totalDiff}</StyledCell>
    </tr>
  )

/**
 * SectionsTable component
 */
const SectionsTable = ({
  items,
  modalContent,
  modalParams = {},
  tableParams = {} as GroupedTableProps,
  totalFooterLabel,
  ...props
}: SectionsTableProps) => {
  const { context } = useServiceWizard(),
    { reportingYear, prevReportingYear } = getFiscalPeriods(context),
    cautionDialog = useDisclosure(),
    [onDeleteHandler, onDeleteHandlerSet] = useState<AnyFunction>(),
    confirmDelete = useCallback(
      onDeleteHandler => () => {
        onDeleteHandlerSet(() => onDeleteHandler)
        cautionDialog.onOpen()
      },
      [cautionDialog, onDeleteHandlerSet]
    ),
    handleDeleteClick = useCallback(() => {
      getCallback(onDeleteHandler)()
      cautionDialog.onClose()
    }, [cautionDialog, onDeleteHandler]),
    preparedSections = useMemo(() => {
      const getItems = (
        type: string[] | string,
        sectionTitle: any,
        sectionParams: SectionParams = {},
        prompts,
        readOnly
      ) => {
        const sectionIndex = getIESectionIndexByType(props.sections, type),
          isExist = sectionIndex !== -1,
          { newLineOptions } = sectionParams,
          createItems = () => {
            const { items } = props.sections[sectionIndex],
              empty = items.length === 0
            return newLineOptions && empty ? [newLineOptions?.empty] : items
          },
          createSection = () => {
            const items = createItems(),
              originalSectionItemsCount =
                props.sections[sectionIndex].items.length
            return {
              sectionIndex,
              originalSectionItemsCount,
              items,
              prompts,
              readOnly,
              title: sectionTitle,
              visible: getFilteredDataByFieldAndCondition(items)?.length,
              ...sectionParams,
            }
          }

        if (newLineOptions) newLineOptions.confirmDelete = confirmDelete

        return isExist && createSection()
      }
      return items.reduce((acc, item: Item) => {
        const i = getItems(
          item.type,
          item.title,
          item.params,
          item.prompts,
          item.readOnly
        )
        if (i) acc = [...acc, i]
        return acc
      }, [] as Array<any>)
    }, [items, props.sections, confirmDelete]),
    totals = useMemo(() => {
      const allCategories = preparedSections.reduce(
          (acc, section) =>
            section?.visible || isNullOrWhiteSpace(section?.visible)
              ? [
                  ...acc,
                  ...getFilteredDataByFieldAndCondition(
                    section?.items as IncomeItem[]
                  ),
                ]
              : acc,
          [] as any[]
        ),
        { totalDiff, totalLast, totalCurrent } = getTotalDiffWithText(
          allCategories,
          'prevYearAmount',
          'lastYearAmount',
          basicTextFooter
        )
      return [totalLast, totalCurrent, totalDiff]
    }, [preparedSections]),
    headers = useMemo(
      () =>
        tableParams.headers || [
          {
            width: '54px',
          },
          {
            children: (
              <TextFromParams params={basicTextHeader}>
                Categories
              </TextFromParams>
            ),
            width: preparedSections.some(section => section.readOnly)
              ? '350px'
              : '430px',
          },
          {
            children: (
              <TextFromParams params={basicTextHeader}>
                {prevReportingYear} Annual
              </TextFromParams>
            ),
            width: '40%',
          },
          {
            children: (
              <TextFromParams params={basicTextHeader}>
                {reportingYear} Annual
              </TextFromParams>
            ),
            width: '40%',
          },
          {
            children: (
              <TextFromParams params={basicTextHeader}>
                Change (YoY)
              </TextFromParams>
            ),
            width: '20%',
          },
          {
            width: '14px',
          },
          {
            width: '24px',
          },
        ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reportingYear, prevReportingYear]
    ),
    content = useSections(
      props.sections,
      preparedSections,
      props?.disabled,
      headers.length || tableParams?.headers?.length,
      props?.onChange,
      props?.hasError
    )

  return (
    <StepBasic className={props.className} p={0}>
      <GroupedTables
        headers={headers}
        rowsHeight="56px"
        footer={
          tableParams.getTableFooter
            ? getCallback(tableParams.getTableFooter)(
                totals,
                totalFooterLabel,
                basicTextFooter
              )
            : getTableFooter(totals, totalFooterLabel)
        }
        {...tableParams}
      >
        {content}
      </GroupedTables>
      <ModalDialog
        title={`Caution!`}
        actionText="Yes, Remove"
        actionVariant="warning"
        onAction={handleDeleteClick}
        isOpen={cautionDialog.isOpen}
        onClose={cautionDialog.onClose}
        size="w560"
        {...modalParams}
      >
        {modalContent}
      </ModalDialog>
    </StepBasic>
  )
}

export default SectionsTable
export {
  basicTextFooterSubtotal,
  emptyForManually,
  emptyForList,
  StyledCell,
  basicTextHeader,
}
