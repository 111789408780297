import { AnyFunction } from 'helpers/utils'
import { useCallback } from 'react'

const useDelayedCallback = (callback: AnyFunction, delay: number) =>
  useCallback(
    (startTime: number = new Date().getTime()) =>
      () => {
        const executionTime = new Date().getTime() - startTime
        executionTime >= delay
          ? callback()
          : setTimeout(callback, delay - executionTime)
      },
    [delay, callback]
  )

export default useDelayedCallback
