import React, { ReactNode, useEffect, useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'

import { get, wrap } from 'object-path-immutable'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import Input from 'ui-framework/components/primitives/Input'
import Table from 'ui-framework/components/patterns/Table'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import {
  getCallback,
  getFiscalPeriods,
  getNameAndErrorProps,
} from 'helpers/utils'
import { formatToCurrency, formatToNumberITS } from 'helpers/formatter'
import { integerPositiveThousands } from 'helpers/formats'
import {
  ResidentialInfo,
  ResidentialUnits,
  TaFormData,
} from 'hooks/api/ta/TaFormData'
import { chakra, ChakraProps, Text, Tooltip, VStack } from '@chakra-ui/react'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import Empty from 'ui-framework/components/primitives/Empty'
import { PortionRentFromAnyTenants } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'

//====================================================
type Props = {
  residentialUnits: ResidentialUnits
  portionVacantTroubleTenantCS: PortionRentFromAnyTenants
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

const BoxWrapper = chakra('div', {
  baseStyle: {
    pl: '54px',
  },
}),
  tooltipStyle = {
    color: 'fontnavy',
    fontSize: 'caption',
    borderRadius: '8px',
    p: '8px',
  },
  boldParams = {
    textStyle: 'body.semibold',
    color: 'fontnavy',
    textAlign: 'left',
  } as ChakraProps,
  headerColor = '#F9FAFF',
  crateTextWithTooltip = (
    text: string,
    label: string = "This value was entered on the Main Residential Information step. To edit your entry, return to the Main Residential Information step using the 'Back' button."
  ): ReactNode => (
    <Tooltip
      sx={tooltipStyle}
      label={label}
      placement="top-start"
      arrowSize={8}
      bg={`white100`}
      hasArrow
    // isLazy
    >
      <span tabIndex={0}>{text}</span>
    </Tooltip>
  )

/**
 * StepPropertyDetailsResidential component
 */
const StepPropertyDetailsResidential = ({
  residentialUnits,
  portionVacantTroubleTenantCS,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const wereAnyUnitsVacant = residentialUnits.wereAnyUnitsVacant,
    regulatedNumberOfVacantUnits =
      residentialUnits.regulatedUnits.numberOfVacantUnits,
    unregulatedNumberOfVacantUnits =
      residentialUnits.unRegulatedUnits.numberOfVacantUnits,
    regulatedNumberOfRentedUnits =
      residentialUnits.regulatedUnits.numberOfRentedUnits,
    unregulatedNumberOfRentedUnits =
      residentialUnits.unRegulatedUnits.numberOfRentedUnits,
    regulatedMonthlyRent = residentialUnits.regulatedUnits.monthlyRent || 0,
    unregulatedMonthlyRent = residentialUnits.unRegulatedUnits.monthlyRent || 0,
    regulatedMonthlyRentLossDueVacant =
      residentialUnits.regulatedUnits.monthlyRentLossDueVacant || 0,
    unregulatedMonthlyRentLossDueVacant =
      residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant || 0,
    wereAnyUnitsVacant3Month = residentialUnits.wereAnyUnitsVacant3Month,
    vacantApartmentNumber3Month = residentialUnits.vacantApartmentNumber3Month,
    numberOfRegulatedUnits = residentialUnits.regulatedUnits.numberOfUnits,
    numberOfUnRegulatedUnits = residentialUnits.unRegulatedUnits.numberOfUnits

  const { context } = useServiceWizard(),
    { fiscalYear, reportingYear } = getFiscalPeriods(context),
    headers = [
      {
        width: '54px',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              h: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
            }}
          >
            Units Type
          </TextFromParams>
        ),
        width: wereAnyUnitsVacant ? '25%' : '33%',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              h: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              width: '83px',
            }}
          >
            # of Vacant Units
          </TextFromParams>
        ),
        width: wereAnyUnitsVacant ? '25%' : '33%',
        bgColor: headerColor,
        hide: !wereAnyUnitsVacant,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              h: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              w: '129px',
            }}
          >
            Monthly Rent Loss Due to Vacancy
          </TextFromParams>
        ),
        width: wereAnyUnitsVacant ? '25%' : '33%',
        bgColor: headerColor,
        hide: !wereAnyUnitsVacant,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              h: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              w: '83px',
            }}
          >
            # of Rented Units
          </TextFromParams>
        ),
        width: wereAnyUnitsVacant ? '25%' : '33%',
        bgColor: headerColor,
      },
      {
        children: (
          <TextFromParams
            params={{
              color: 'fontandicongray',
              h: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'left',
              w: '150px',
            }}
          >
            Monthly Rent from All Rented Units
          </TextFromParams>
        ),
        width: wereAnyUnitsVacant ? '25%' : '33%',
        bgColor: headerColor,
      },
      {
        width: '24px',
        bgColor: headerColor,
      },
    ],
    [isResidentialOnly, isNonResidentialOnly, isBoth] = useMemo(
      () => [
        portionVacantTroubleTenantCS === PortionRentFromAnyTenants.Residential,
        portionVacantTroubleTenantCS ===
        PortionRentFromAnyTenants.NonResidential,
        portionVacantTroubleTenantCS === PortionRentFromAnyTenants.Both,
      ],
      [portionVacantTroubleTenantCS]
    ),
    vacantUnitsSum = useMemo(
      () => (regulatedNumberOfVacantUnits || 0) + (unregulatedNumberOfVacantUnits || 0),
      [regulatedNumberOfVacantUnits, unregulatedNumberOfVacantUnits]
    )

  function onWereVacantChange(value) {
    const res = wrap({ residentialUnits })
    if (!value) {
      res.set('residentialUnits.regulatedUnits.numberOfVacantUnits', null)
      res.set('residentialUnits.unRegulatedUnits.numberOfVacantUnits', null)
      res.set('residentialUnits.regulatedUnits.monthlyRentLossDueVacant', null)
      res.set('residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant', null)
    }

    res.set('residentialUnits.wereAnyUnitsVacant', value)
    getCallback(onChange)(res.value())
  }

  function onVacant3MonthChange(value) {
    const res = wrap({ residentialUnits })
    if (!value) {
      res.set('residentialUnits.vacantApartmentNumber3Month', null)
    }

    res.set('residentialUnits.wereAnyUnitsVacant3Month', value)
    getCallback(onChange)(res.value())
  }

  function onRegulatedNumberOfVacantUnitsChange(value) {
    const res = wrap({ residentialUnits })
    if (!residentialUnits.regulatedUnits.numberOfVacantUnits) {
      res.set('residentialUnits.regulatedUnits.monthlyRentLossDueVacant', null)
    }
    res.set('residentialUnits.regulatedUnits.numberOfVacantUnits', value)
    getCallback(onChange)(res.value())
  }

  function onUnegulatedNumberOfVacantUnitsChange(value) {
    const res = wrap({ residentialUnits })
    if (!residentialUnits.unRegulatedUnits.numberOfVacantUnits) {
      res.set('residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant', null)
    }
    res.set('residentialUnits.unRegulatedUnits.numberOfVacantUnits', value)
    getCallback(onChange)(res.value())
  }

  function onRegulatedNumberOfRentedUnitsChange(value) {
    const res = wrap({ residentialUnits })
    if (!residentialUnits.regulatedUnits.numberOfRentedUnits) {
      res.set('residentialUnits.regulatedUnits.monthlyRent', null)
    }
    res.set('residentialUnits.regulatedUnits.numberOfRentedUnits', value)
    getCallback(onChange)(res.value())
  }

  function onUnegulatedNumberOfRentedUnitsChange(value) {
    const res = wrap({ residentialUnits })
    if (!residentialUnits.unRegulatedUnits.numberOfRentedUnits) {
      res.set('residentialUnits.unRegulatedUnits.monthlyRent', null)
    }
    res.set('residentialUnits.unRegulatedUnits.numberOfRentedUnits', value)
    getCallback(onChange)(res.value())
  }



  const validationData = useMemo(
    () => ({
      residentialUnits,
      vacantUnitsSum,
    }),
    [residentialUnits, vacantUnitsSum]
  ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  return (
    <div className={props.className}>
      <VStack spacing="36px" align="left">
        <BoxWrapper>
          <HelpTarget name="wereAnyUnitsVacant">
            <SelectsGroup
              label={`Have any apartments been vacant as of January 5, ${fiscalYear}?`}
              error={hasError('residentialUnits.wereAnyUnitsVacant')}
              disabled={disabled}
            >
              <Radiobutton
                value={wereAnyUnitsVacant}
                name="residentialUnits.wereAnyUnitsVacant"
                selected={false}
                disabled={disabled}
                onChange={onWereVacantChange}
              >
                No
              </Radiobutton>
              <Radiobutton
                value={wereAnyUnitsVacant}
                name="residentialUnits.wereAnyUnitsVacant"
                selected={true}
                disabled={disabled}
                onChange={onWereVacantChange}
              >
                Yes
              </Radiobutton>
            </SelectsGroup>
          </HelpTarget>
        </BoxWrapper>
        {!isResidentialOnly && !isNonResidentialOnly && (
          <BoxWrapper>
            <SelectsGroup
              label={`Were any apartments vacant for more than 3 months in ${reportingYear}?`}
              error={hasError('residentialUnits.wereAnyUnitsVacant3Month')}
              disabled={disabled}
            >
              <Radiobutton
                value={wereAnyUnitsVacant3Month}
                name="residentialUnits.wereAnyUnitsVacant3Month"
                selected={false}
                disabled={disabled}
                onChange={onVacant3MonthChange}
              >
                No
              </Radiobutton>
              <Radiobutton
                value={wereAnyUnitsVacant3Month}
                name="residentialUnits.wereAnyUnitsVacant3Month"
                selected={true}
                disabled={disabled}
                onChange={onVacant3MonthChange}
              >
                Yes
              </Radiobutton>
            </SelectsGroup>
          </BoxWrapper>
        )}
        {(wereAnyUnitsVacant3Month || isResidentialOnly || isBoth) && (
          <BoxWrapper>
            <HelpTarget name="vacantApartmentNumber3Month">
              <Input
                width="200px"
                label={
                  <Text
                    textStyle="body.regular"
                    lineHeight="25px"
                    color="fontnavy"
                  >
                    List the unit numbers of apartments which have been vacant
                    <br /> for more than 3 months in {reportingYear}.
                  </Text>
                }
                value={vacantApartmentNumber3Month}
                type="text"
                disabled={disabled}
                onChange={onChange}
                {...getNameAndErrorProps(
                  'residentialUnits.vacantApartmentNumber3Month',
                  hasError
                )}
              />
            </HelpTarget>
          </BoxWrapper>
        )}
        <Table
          headers={headers}
          rowsHeadingHeight="56px"
          rowsHeight="56px"
          cellSidePaddings="20px"
          footer={
            <tr>
              <CellWithoutBorder />
              <td>
                <TextFromParams params={boldParams}>Total</TextFromParams>
              </td>
              {wereAnyUnitsVacant && (
                <>
                  <td>
                    <TextFromParams params={boldParams}>
                      {formatToNumberITS(
                        (regulatedNumberOfVacantUnits || 0) +
                        (unregulatedNumberOfVacantUnits || 0)
                      )}
                    </TextFromParams>
                  </td>
                  <td>
                    <TextFromParams params={boldParams}>
                      {formatToCurrency(
                        regulatedMonthlyRentLossDueVacant +
                        unregulatedMonthlyRentLossDueVacant
                      )}
                    </TextFromParams>
                  </td>
                </>
              )}
              <td>
                <TextFromParams params={boldParams}>
                  {formatToNumberITS(
                    regulatedNumberOfRentedUnits +
                    unregulatedNumberOfRentedUnits
                  )}
                </TextFromParams>
              </td>
              <td>
                <TextFromParams params={boldParams}>
                  {formatToCurrency(
                    regulatedMonthlyRent + unregulatedMonthlyRent
                  )}
                </TextFromParams>
              </td>
              <CellWithoutBorder />
            </tr>
          }
        >
          <tbody>
            <tr>
              <CellWithoutBorder />
              <td>
                {crateTextWithTooltip(`Regulated (${numberOfRegulatedUnits})`)}
              </td>

              {wereAnyUnitsVacant && (
                <>
                  <td>
                    <HelpTarget name="regulatedNumberOfVacantUnits">
                      <Input
                        width="160px"
                        size="sm"
                        value={regulatedNumberOfVacantUnits}
                        name="residentialUnits.regulatedUnits.numberOfVacantUnits"
                        type="number"
                        format={integerPositiveThousands}
                        disabled={disabled}
                        onChange={onRegulatedNumberOfVacantUnitsChange}
                        errorTooltip
                        error={hasError(
                          'residentialUnits.regulatedUnits.numberOfVacantUnits'
                        )}
                      />
                    </HelpTarget>
                  </td>
                  <td>
                    {!regulatedNumberOfVacantUnits ? (
                      <Empty type="hyphen" />
                    ) : (
                      <HelpTarget name="regulatedMonthlyRentLossDueVacant">
                        <Input
                          width="160px"
                          size="sm"
                          value={regulatedMonthlyRentLossDueVacant}
                          name="residentialUnits.regulatedUnits.monthlyRentLossDueVacant"
                          type="number"
                          prefix="$"
                          format={integerPositiveThousands}
                          disabled={disabled}
                          onChange={onChange}
                          errorTooltip
                          error={hasError(
                            'residentialUnits.regulatedUnits.monthlyRentLossDueVacant'
                          )}
                        />
                      </HelpTarget>
                    )}
                  </td>
                </>
              )}
              <td>
                <HelpTarget name="regulatedNumberOfRentedUnits">
                  <Input
                    width="160px"
                    size="sm"
                    value={regulatedNumberOfRentedUnits}
                    name="residentialUnits.regulatedUnits.numberOfRentedUnits"
                    type="number"
                    format={integerPositiveThousands}
                    disabled={disabled}
                    onChange={onRegulatedNumberOfRentedUnitsChange}
                    errorTooltip
                    error={hasError(
                      'residentialUnits.regulatedUnits.numberOfRentedUnits'
                    )}
                  />
                </HelpTarget>
              </td>
              <td>
                {!regulatedNumberOfRentedUnits ? (
                  <Empty type="hyphen" />
                ) : (
                  <HelpTarget name="monthlyRent">
                    <Input
                      width="160px"
                      size="sm"
                      value={regulatedMonthlyRent}
                      name="residentialUnits.regulatedUnits.monthlyRent"
                      prefix="$"
                      type="number"
                      format={integerPositiveThousands}
                      disabled={disabled}
                      onChange={onChange}
                      errorTooltip
                      error={hasError(
                        'residentialUnits.regulatedUnits.monthlyRent'
                      )}
                    />
                  </HelpTarget>
                )}
              </td>
              <CellWithoutBorder />
            </tr>
            <tr>
              <CellWithoutBorder />
              <td>
                {crateTextWithTooltip(
                  `Unregulated (${numberOfUnRegulatedUnits})`
                )}
              </td>
              {wereAnyUnitsVacant && (
                <>
                  <td>
                    <HelpTarget name="unregulatedNumberOfVacantUnits">
                      <Input
                        width="160px"
                        size="sm"
                        value={unregulatedNumberOfVacantUnits}
                        name="residentialUnits.unRegulatedUnits.numberOfVacantUnits"
                        type="number"
                        format={integerPositiveThousands}
                        disabled={disabled}
                        onChange={onUnegulatedNumberOfVacantUnitsChange}
                        errorTooltip
                        error={hasError(
                          'residentialUnits.unRegulatedUnits.numberOfVacantUnits'
                        )}
                      />
                    </HelpTarget>
                  </td>
                  <td>
                    {!unregulatedNumberOfVacantUnits ? (
                      <Empty type="hyphen" />
                    ) : (
                      <HelpTarget name="unregulatedMonthlyRentLossDueVacant">
                        <Input
                          size="sm"
                          width="160px"
                          value={unregulatedMonthlyRentLossDueVacant}
                          name="residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant"
                          type="number"
                          prefix="$"
                          format={integerPositiveThousands}
                          disabled={disabled}
                          onChange={onChange}
                          errorTooltip
                          error={hasError(
                            'residentialUnits.unRegulatedUnits.monthlyRentLossDueVacant'
                          )}
                        />
                      </HelpTarget>
                    )}
                  </td>
                </>
              )}
              <td>
                <HelpTarget name="unregulatedNumberOfRentedUnits">
                  <Input
                    size="sm"
                    width="160px"
                    value={unregulatedNumberOfRentedUnits}
                    errorTooltip
                    error={hasError(
                      'residentialUnits.unRegulatedUnits.numberOfRentedUnits'
                    )}
                    name="residentialUnits.unRegulatedUnits.numberOfRentedUnits"
                    type="number"
                    format={integerPositiveThousands}
                    disabled={disabled}
                    onChange={onUnegulatedNumberOfRentedUnitsChange}
                  />
                </HelpTarget>
              </td>
              <td>
                {!unregulatedNumberOfRentedUnits ? (
                  <Empty type="hyphen" />
                ) : (
                  <HelpTarget name="monthlyRent">
                    <Input
                      size="sm"
                      width="160px"
                      errorTooltip
                      error={hasError(
                        'residentialUnits.unRegulatedUnits.monthlyRent'
                      )}
                      value={unregulatedMonthlyRent}
                      name="residentialUnits.unRegulatedUnits.monthlyRent"
                      type="number"
                      prefix="$"
                      format={integerPositiveThousands}
                      disabled={disabled}
                      onChange={onChange}
                    />
                  </HelpTarget>
                )}
              </td>
              <CellWithoutBorder />
            </tr>
          </tbody>
        </Table>
      </VStack>
    </div>
  )
}

export default StepPropertyDetailsResidential
