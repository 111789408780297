import { useMachine } from '@xstate/react';
import { log } from 'helpers/utils';
import { useCallback, useEffect } from 'react';
import { assign, createMachine } from 'xstate';

export enum WizardStates {
  INITIAL = 'initial',
  WIZ = 'wiz',
  FINISHED = 'finished',
  STEP_BEFORE = '_step',
  STEP = 'step',
  STEP_AFTER = 'step_',
}

export enum WizardEvents {
  READY = 'READY',
  FINISH = 'FINISH',
  BACK = 'BACK',
  NEXT = 'NEXT',
  GOTO = 'GOTO',
}

export enum Directions {
  BACK,
  NEXT,
}

type WizardMachineContext = {
  currentStep: any,
  currentStepId: any,
  currentStepIndex: any,
  nextStepId: any,
  prevStepId: any,
  $gotoStepId: any,
}

enum MachineActions {
  INIT = 'init',
  CHANGE_STEP = 'goNext',
  SET_NEXT_STEP_NEXT = 'setNextStepNext',
  SET_PREV_STEP_NEXT = 'setPrevStepNext',
  STEP_BEFORE = 'beforeStep',
  STEP_AFTER = 'afterStep',
}

const wizardMachine = createMachine<WizardMachineContext>({
  /** @xstate-layout N4IgpgJg5mDOIC5QHcCWAvAhgJwgYgHEB5AFSIG0AGAXUVAAcB7WVAF1UYDs6QAPRALQB2ACwA6ABwBGSpQDM8gKyKRlAJwA2IQBoQAT0RSjYkXIBM0ymbVCpGxUIC+j3Wiy48AMQCSAOW8AygASVLRIIEws7Fw8-AgiZroGCCoaYooWciIS0so5is6uGDgQYqicbKiYADZ4AEoAogCCACIAmqE8kZUx4XEiQoqSlBKKSgMaErIaSYjmQ2qjoxoWlCIrBS4gbiViAPqwrGD09c3tneHd0dx9iANDU6OmcmoimmpqswhmZuJyihoNGozHI5BIFEI5IVtsVcGJDsc8L4GgANEgXBjMHo3UBxOxydKUOxCX5gklCElfESqMRSQY2EHqVRCDTQnZwhEnABCTQAwgBpDERLHXWJzaliV7SLLgswONSUGb6QSUMQaOQUxUOOxmDRSFRSNmw0qcvanVodGhdEUcHF8O6DYajcYsqaKqmmQlgzJCIkkszOLacRgQOA8dkQa1RW1ihACKQ-SQyeRKFTqLRfeOKCS0qRqOmAtSgyiuo3uUrlSo1KPY2NySaSUTZgaUZR6zO+yVyKQSKVA0w-Mu7A5Heg10W3BAJglaCRCaRaUEkiQiL4fEwKbK9xSvD66occ0fjmOTjVmMT151CRbZ9Qgr5SUGSPJmabmIGjA8m0d7Y+9XGIPKYi+vIkIaiIGSjGuQjpHmGSPhk84QRIX5iAAZpWsAABaQH+dp4uqhLEqSc5mBSiTKikEGwesO6jD23abM4QA */
  id: 'wizard',
  initial: WizardStates.INITIAL,
  context: {
    currentStep: null,
    currentStepId: null,
    currentStepIndex: null,
    nextStepId: null,
    prevStepId: null,
    $gotoStepId: null,
  },
  states: {
    [WizardStates.INITIAL]: {
      on: {
        [WizardEvents.READY]: {
          target: WizardStates.STEP,
          actions: MachineActions.INIT
        },
      }
    },
    [WizardStates.STEP]: {
      on: {
        [WizardEvents.NEXT]: {
          target: WizardStates.STEP_AFTER,
          actions: MachineActions.SET_NEXT_STEP_NEXT
        },
        [WizardEvents.BACK]: {
          target: WizardStates.STEP_AFTER,
          actions: MachineActions.SET_PREV_STEP_NEXT
        },
      }
    },
    [WizardStates.STEP_AFTER]: {
      entry: [
        // MachineActions.CHANGE_STEP,
        MachineActions.STEP_AFTER
      ],
      on: {
        [WizardEvents.READY]: {
          target: WizardStates.STEP,
          actions: MachineActions.CHANGE_STEP,
        },
      },
    },
    [WizardStates.FINISHED]: {
      type: "final",
    },
  },
  on: {
    [WizardEvents.GOTO]: {
      target: WizardStates.STEP,
      actions: MachineActions.INIT
    },
    [WizardEvents.FINISH]: {
      target: WizardStates.FINISHED,
    },
  }
});


const useWizardMachine = (steps, currentStepId, onAfterStep) => {

  const recalculateMachineContext = useCallback((stepId) => {
    if (!stepId || !steps.length) {
      log('empty steps')
      return {}
    }
    const idx = steps.findIndex(s => s.id === stepId),
      currentStepIndex = idx === -1 ? 0 : idx,
      isFirstStep = currentStepIndex === 0,
      isLastStep = currentStepIndex === steps.length - 1,
      nextStepId = steps[currentStepIndex + (!isLastStep ? 1 : 0)].id,
      prevStepId = steps[currentStepIndex - (!isFirstStep ? 1 : 0)].id

    log('recalculateMachineContext')
    return {
      currentStep: steps[currentStepIndex],
      currentStepId: stepId,
      currentStepIndex,
      isFirstStep,
      isLastStep,
      nextStepId,
      prevStepId,
      $gotoStepId: null,
    }
  }, [steps])

  const machine = useMachine(wizardMachine, {
    actions: {

      [MachineActions.INIT]: assign((_, event) => {
        log(MachineActions.INIT, event, steps)
        return recalculateMachineContext(event.stepId || currentStepId || steps[0].id)
      }),

      [MachineActions.CHANGE_STEP]: assign((context) => {
        // get updated context (steps[] can change)
        const ctx: any = recalculateMachineContext(context.currentStepId)
        const ns =
          context.$gotoStepId === Directions.NEXT
            ? ctx.nextStepId
            : context.$gotoStepId === Directions.BACK
              ? ctx.prevStepId
              : context.$gotoStepId

        log(MachineActions.CHANGE_STEP, context.$gotoStepId, ns)
        return recalculateMachineContext(ns)
      }),

      [MachineActions.SET_NEXT_STEP_NEXT]: assign((context) => {
        log(MachineActions.SET_NEXT_STEP_NEXT, context.nextStepId)
        return {
          $gotoStepId: Directions.NEXT
        }
      }),

      [MachineActions.SET_PREV_STEP_NEXT]: assign((context) => {
        log(MachineActions.SET_PREV_STEP_NEXT, context.prevStepId)
        return {
          $gotoStepId: Directions.BACK
        }
      }),

      [MachineActions.STEP_AFTER]: (context) => {
        log(MachineActions.STEP_AFTER, context)
        onAfterStep(context)
      },
    },
    guards: {
      // someGuard: (context, event) => {
      //   return context.points > 99;
      // },
    }
  });
  const [state, send, service] = machine

  useEffect(() => {
    log('context', state.context)
  }, [state.context])

  // prevent to run machine with empty steps array
  useEffect(() => {
    if (steps.length > 0 && state.value === WizardStates.INITIAL) {
      send({ type: WizardEvents.READY })
    }
  }, [steps])

  return machine
}


export default useWizardMachine