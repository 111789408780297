import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import SimpleLayoutCentered from 'ui-framework/components/layout/SimpleLayout/SimpleLayoutCentered'
import Logo from 'ui-framework/components/layout/MainLayout/Logo'
import LoginForm from './LoginForm'

//===================================================
type Props = {} & BaseComponentProps
/**
 * LoginCustomPage component
 */
const LoginCustomPage = (props: Props) => {
  return (
    <SimpleLayoutCentered className={props.className}>
      <Logo width={'88px'} height={'80px'} margin={'36px auto'} />
      <LoginForm />
    </SimpleLayoutCentered>
  )
}

export default LoginCustomPage
