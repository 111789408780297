import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods } from 'helpers/utils'

//===================================================
type HelpDetailsExpenseStepProps = {} & BaseComponentProps

/**
 * HelpDetailsExpenseStep component
 */
const HelpDetailsExpenseStep = (props: HelpDetailsExpenseStepProps) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context)
  return (
    <>
      <p>
        Provide {reportingYear} expense information for each applicable expense
        category separately (<b>if none - put "0"</b>
        ). Round up to the nearest dollar, but DO NOT round up to the nearest
        hundred.
      </p>
      <p>
        You might see expense amounts for the previous reporting year, for your
        reference, which you cannot edit.
      </p>
    </>
  )
}

export default HelpDetailsExpenseStep
