import { StorefrontUnitDescriptionType } from 'constants/dicti'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

const schema = schemaWrapper({
  floorSizeSF: schemaRules.GREATER_NUMBER().required(),
  descriptionTypeCS: schemaRules.VALID_STRING(
    StorefrontUnitDescriptionType.GroundFloorUnitWithInteriorEntrance,
    StorefrontUnitDescriptionType.GroundFloorUnitWithStreetEntrance,
    StorefrontUnitDescriptionType.SecondFloorUnitWithInteriorEntrance,
    StorefrontUnitDescriptionType.SecondFloorUnitWithStreetEntrance
  ),
})

export default schema
