import React, { useCallback, useMemo } from 'react'
import { wrap } from 'object-path-immutable'
import { Text, VStack, Center } from '@chakra-ui/react'

import { getDictiName } from 'constants/dicti'
import { AnyFunction, getCallback, groupBy } from 'helpers/utils'
import { formatToNumberITS } from 'helpers/formatter'
import { getNameAndErrorProps } from 'helpers/utils'
import { integerPositiveThousands } from 'helpers/formats'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { NonResidentialUse } from 'hooks/api/rpie/RpieFormData'

import BaseComponentProps from 'common/BaseComponentProps'
import Table from 'ui-framework/components/patterns/Table'
import Input from 'ui-framework/components/primitives/Input'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import Checkbox from 'ui-framework/components/primitives/Checkbox'
import Empty from 'ui-framework/components/primitives/Empty'

//===================================================
type NonResidentialUseEx = {
  $name: string
  $nameHas: string
  $useIndex: number
} & NonResidentialUse

type Props = {
  uses: NonResidentialUse[]
  usesType: 'owner' | 'vacant'
  hasError: AnyFunction
  groupedKey?: string
} & BaseComponentProps &
  Pick<StepPropsInterface, 'disabled' | 'onChange'>

/**
 * StepGeneralCommercialDescriptionUseTQ component
 */
const headers = isOwnerType => [
  { text: 'Use Type', width: '30%' },
  {
    text: isOwnerType ? 'Total SF' : 'Non-owner occupied SF',
    width: '15%',
  },
  {
    text: isOwnerType ? 'Owner-occupied' : 'Vacant',
    align: 'center',
    width: '30%',
  },
  {
    text: isOwnerType ? 'Owner-occupied SF' : 'Vacant SF',
    width: '25%',
  },
],
  sfTotal = (
    use: NonResidentialUseEx,
    isOwnerType
  ) =>
    isOwnerType
      ? use.sfTotal
      : use.sfTotal - (use.sfApplicant || 0)

const createContent =
  (makeOtherContentFn, groupedKey, headerCnt) => (use, index) => {
    if (groupedKey) {
      const [title, items] = use
      return items.map((item, i) => (
        <>
          {!i && title && (
            <tr style={{ height: '25px' }}>
              <td
                className={`nohovered`}
                colSpan={headerCnt}
                style={{ background: '#EDEEFD' }}
              >
                {title}
              </td>
            </tr>
          )}
          {makeOtherContentFn(item, item.$useIndex)}
        </>
      ))
    }
    return makeOtherContentFn(use, index)
  }

const StepGeneralCommercialDescriptionUseTQ = ({
  usesType,
  uses,
  groupedKey,
  hasError,
  disabled,
  onChange,
  ...props
}: Props) => {
  const [isOwnerType, $nameHas, $name] = useMemo(() => {
    const isOwner = usesType === 'owner',
      $nameHas = isOwner ? 'hasApplicantSF' : 'hasVacantSF',
      $name = isOwner ? 'sfApplicant' : 'sfVacant'
    return [isOwner, $nameHas, $name]
  }, [usesType])

  const usesWithData = useMemo(() => {
    const data = (uses || []).map((use, idx) => ({
      ...use,
      floorCS: use.floorCS || 'All floors',
      $nameHas,
      $name,
      $useIndex: idx,
    }))
    return groupedKey ? Object.entries(groupBy(data, groupedKey)) : data
  }, [uses, $nameHas, $name, groupedKey])

  const handleOnChange = useCallback(
    index => (value, name) => {
      const res = wrap({ uses }),
        getFieldPath = (field: string) => `uses.${index}.${field}`,
        change = v => getCallback(onChange)(v)
      if ($nameHas === name && !value) res.set(getFieldPath($name), 0)

      res.set(getFieldPath(name), value)
      change(res.value())
    },
    [$nameHas, $name, onChange, uses]
  )

  const createOther = useCallback(
    (use: NonResidentialUseEx, index) => {
      const nameAndErrorProps = name =>
        getNameAndErrorProps(name, hasError, `uses.${index}`),
        handleChange = handleOnChange(index)
      return (
        <tr key={use.$useIndex}>
          <td>{getDictiName(use.usesTypeCS)}</td>
          <td>
            {formatToNumberITS(
              sfTotal(use, isOwnerType)
            )}
          </td>
          <td>
            <Center>
              <Checkbox
                checked={use[use.$nameHas]}
                onChange={handleChange}
                {...nameAndErrorProps(use.$nameHas)}
              />
            </Center>
          </td>
          <td>
            {use[use.$nameHas] ? (
              <HelpTarget name={$name}>
                <Input
                  width="100%"
                  size="sm"
                  type="number"
                  format={integerPositiveThousands}
                  placeholder="Enter a value"
                  value={use[use.$name]}
                  onChange={handleChange}
                  errorTooltip
                  {...nameAndErrorProps(use.$name)}
                />
              </HelpTarget>
            ) : (
              <Empty type="hyphen" />
            )}
          </td>
        </tr>
      )
    },
    [
      $name,
      handleOnChange,
      hasError,
      isOwnerType,
    ]
  )

  const tableHeaders = useMemo(() => headers(isOwnerType), [isOwnerType])

  return (
    <VStack spacing="30px" w="680px" align="start">
      <HelpTarget
        name={isOwnerType ? 'useTableOwnerOccupiedOrRelated' : 'useTableVacant'}
      >
        <Text>
          {isOwnerType
            ? 'Indicate floor area subject to owner-occupancy or being rented to a related party.'
            : 'Indicate floor area that was vacant.'}
        </Text>
      </HelpTarget>

      <Table headers={tableHeaders} items={usesWithData} rowsHeight="56px">
        {createContent(createOther, groupedKey, tableHeaders.length)}
      </Table>
    </VStack>
  )
}

export default StepGeneralCommercialDescriptionUseTQ
