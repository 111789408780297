import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { nanoid } from 'nanoid'
import Link from '../Link'
import Checkbox from '../Checkbox'
import Button from '../Button'

import {
  OnChangeHandler,
  ChakraComponentProps,
} from 'ui-framework/common/types'
import { getCallback, keyboardEventListener } from 'helpers/utils'
import useElementNav from 'hooks/useElementNav'
import Icon from '../Icon'
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useMultiStyleConfig,
  Divider,
  Flex,
} from '@chakra-ui/react'
import useOnClickOutside from 'hooks/useClickOutside'
import Input from '../Input'
import FocusTrap from '../FocusTrap'
import TextWithTooltip from '../TextWithTooltip'

const MIN_FILTERING_AMOUNT = 5

type ListItems = Array<any>

type Props = {
  /**
   * Is multiselect
   */
  multiselect?: boolean
  /**
   * items type
   */
  items: ListItems
  /**
   * Value type
   */
  value?: string | string[] | object | object[] | null
  /**
   * itemId type
   */
  itemId?: string
  /**
   * itemValue type
   */
  itemValue?: string
  /**
   * Name of input.
   */
  name?: string
  /**
   * Visual state of the input
   */
  error?: string
  tabIndex?: number
  /**
   * Disabled state
   * @default false
   */
  disabled?: boolean
  /** Placeholder text
   */
  placeholder?: string
  /**
   * Preserve height of the label and the message
   */
  preserveHeight?: boolean
  /**
   * Draw outline
   */
  outline?: boolean
  /**
   * Width of the list
   */
  listWidth?: string
  /**
   * Max height of the list
   */
  listMaxHeight?: string
  /**
   * onChange handler
   */
  onChange?: OnChangeHandler<string | string[] | any | any[]>
  width?: string
  maxWidth?: string
  label?: string
  useFilter?: number | boolean
  errorTooltip?: boolean
  returnObject?: true
} & ChakraComponentProps

const DropdownList = (props: Props) => {
  const {
    multiselect = false,
    disabled = false,
    placeholder = 'Select',
    name,
    items,
    value,
    itemId, // valueToSet,
    itemValue, // valueToShow,
    returnObject, //IF TRUE selected value would be like one of items
    error,
    onChange,
    size = 'md',
    width,
    maxWidth,
    label,
    useFilter = MIN_FILTERING_AMOUNT,
    errorTooltip,
  } = props

  const selector = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef()
  const containerRef = useRef()

  const [listFilter, listFilterSet] = useState<string | null>(null),
    useListFilter = useMemo(
      () => Boolean(useFilter) && items.length >= MIN_FILTERING_AMOUNT,
      [items, useFilter]
    ),
    clearListFilter = useCallback(
      () => useListFilter && listFilterSet(null),
      [useListFilter, listFilterSet]
    )

  const style = useMultiStyleConfig('MGNYDropdownList', {
    ...props,
    useListFilter,
  })

  const [handleOnElementEvent, handleOnElementClose] = useElementNav(
    selector,
    wrapperRef
  )

  const onSelectorToggle = () => !disabled && selector.onToggle()

  const getByKey = useCallback(
      (item: any, key: string = itemId || '') =>
        (item || {})[key] || item || null,
      [itemId]
    ),
    isSelected = useCallback(
      item =>
        !value
          ? false
          : Array.isArray(value)
          ? value.findIndex(v => getByKey(v) === getByKey(item)) !== -1
          : getByKey(value) === getByKey(item),
      [value, getByKey]
    ),
    getSelected = useCallback(
      () =>
        getByKey(
          items.find(item => getByKey(item) === getByKey(value)),
          itemValue
        ),
      [items, value, itemValue, getByKey]
    )

  const getValue = useCallback(
      (item?: string | object | null): string | null =>
        getByKey(item, itemValue),
      [itemValue, getByKey]
    ),
    setValue = useCallback(
      v => getCallback(onChange)(returnObject ? v : getByKey(v), name),
      [name, onChange, returnObject, getByKey]
    ),
    handleValueSet = useCallback(
      item => {
        if (multiselect) {
          if (isSelected(item))
            setValue(
              (value as Array<string | any>).filter(
                val => getByKey(val) !== getByKey(item)
              )
            )
          else setValue(((value as any[]) || []).concat(item))
        } else {
          setValue(item)
          selector.onClose()
        }
        clearListFilter()
      },
      [
        getByKey,
        setValue,
        isSelected,
        clearListFilter,
        multiselect,
        selector,
        value,
      ]
    )

  const listItems = useMemo(
    () =>
      listFilter
        ? items.filter(item =>
            getByKey(item, itemValue)
              ?.toString()
              ?.toLowerCase()
              ?.includes(listFilter?.toLowerCase())
          )
        : items,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, listFilter]
  )

  useEffect(() => {
    if (disabled) selector.onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  useOnClickOutside([wrapperRef, containerRef], () => {
    clearListFilter()
    selector.onClose()
  })

  return (
    <Box sx={style.wrapper} ref={containerRef as any}>
      <Popover
        isOpen={!disabled && selector.isOpen}
        closeOnBlur={false}
        isLazy
        placement="bottom-start"
        initialFocusRef={inputRef as any}
      >
        {/* @ts-ignore */}
        <PopoverTrigger>
          <div>
            <Input
              tooltipForTruncated
              label={label}
              inputRef={inputRef as any}
              value={listFilter || getSelected()}
              placeholder={placeholder}
              disabled={disabled}
              error={error}
              size={size}
              width={width}
              maxWidth={maxWidth}
              onFocus={selector.onOpen}
              readonly={!useListFilter}
              onChange={listFilterSet}
              onKeyDown={handleOnElementEvent}
              onClear={handleValueSet}
              errorTooltip={errorTooltip}
              postfix={
                <Icon size={'xs'} onClick={onSelectorToggle}>
                  {selector.isOpen ? 'up' : 'down'}
                </Icon>
              }
            />
          </div>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            ref={wrapperRef as any}
            onKeyUp={handleOnElementClose}
            maxWidth={props.listWidth}
          >
            <Box sx={style.wrapperOuter}>
              {listItems.length ? (
                (listItems as ListItems).map(v => {
                  const selected = isSelected(v),
                    valueToShow = (
                      <TextWithTooltip tooltipForTruncated>
                        {getValue(v)}
                      </TextWithTooltip>
                    )
                  return (
                    v.$show !== false && (
                      <Box
                        sx={style.valueWrapper}
                        key={nanoid()}
                        onClick={() => handleValueSet(v)}
                        onKeyDown={e =>
                          keyboardEventListener(e, () => handleValueSet(v))
                        }
                        className={selected ? 'active' : ''}
                        tabIndex={0}
                      >
                        {multiselect ? (
                          <Checkbox checked={selected}>
                            {valueToShow}
                            <Divider />
                          </Checkbox>
                        ) : (
                          <>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              {valueToShow}
                              {selected && <Icon size={`md`}>successful</Icon>}
                            </Flex>
                            <Divider />
                          </>
                        )}
                      </Box>
                    )
                  )
                })
              ) : (
                <Flex
                  margin={'12px'}
                  alignItems="center"
                  justifyContent="center"
                >
                  No matches
                </Flex>
              )}
              {items && selector.isOpen && multiselect && (
                <Flex sx={style.bottomBlock}>
                  <Link to={'#'} size="xs" onClick={() => setValue(items)}>
                    Select all
                  </Link>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={selector.onClose}
                  >
                    APPLY
                  </Button>
                </Flex>
              )}
            </Box>
            <FocusTrap targetFocusRef={inputRef} />
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}

export default DropdownList
