import {
  RpieFormData,
  RentRollResidentialUnit,
} from 'hooks/api/rpie/RpieFormData'
import {
  IncomeCategories,
  IncomeSectionTypes,
  ReportingPeriodType,
} from 'constants/dicti'
import useFormDataHook, {
  FormDataHookChangeHandlers,
  FormDataHookChangeHandler,
  ServiceFormDataHook,
} from '../common/useFormDataHook'
import { get, set, wrap } from 'object-path-immutable'
import { isCallback, onlyPositiveValue } from 'helpers/utils'
import { setDefaultPeriod } from 'ui-framework/components/patterns/Wizard/StepReportingPeriod'

const useRpieFormDataHook: ServiceFormDataHook<RpieFormData> = (
  valueProp,
  currentProperty
) => {
  /**
  |--------------------------------------------------
  | CHANGE HANDLERS
  |--------------------------------------------------
  */

  const handleCommercialUnits: FormDataHookChangeHandler<RpieFormData> = v => {
    if (!v.general.numberOfCommercialUnit) {
      return set(v, 'storefront.totalUnitNumber', 0)
    }
    return v
  }

  const handleWasAnyRentalIncome: FormDataHookChangeHandler<
    RpieFormData
  > = v => {
    if (v.general.wasAnyRentalIncome) {
      return set(v, 'general.exclusionCS', null)
    }
    return v
  }

  const handleRentRollResUnits: FormDataHookChangeHandler<RpieFormData> = (
    v,
    prevState
  ) => {
    // const isCurrentStepResDescription =
    //     v?.currentStepId === RPIEWizardSteps.ResidentialDescription,
    //   isPrevStepResDescription =
    //     prevState?.currentStepId === RPIEWizardSteps.ResidentialDescription

    // if (!isCurrentStepResDescription && isPrevStepResDescription) {
    const currentUnits = v?.rentRollResidential?.units || [],
      defaultUnits =
        currentProperty?.defaultServiceData?.rentRollResidential?.units || []

    let emptyUnits: RentRollResidentialUnit[] = []

    const createEmptyUnitTypes = (amount: number, type: string) =>
      Array(onlyPositiveValue(amount)).fill(type),
      getRentRollData = units => {
        const coutnUnits = propName =>
          units.reduce((pr: any, c) => (c[propName] ? 1 + pr : pr), 0)

        return [
          coutnUnits('currentlyOwnerOccupied'),
          coutnUnits('currentlyTenantOccupied'),
          coutnUnits('wasVacant'),
          units.some(u => u.isSuperUnit),
        ]
      }

    const {
      general: {
        numberOfResidentialUnit = 0,
        numberOfResRegulatedUnit = 0,
        numberOfResUnregulatedUnit = 0,
        hasSuperUnit = false,
      },
      vacantOwnerTable: {
        usesResidential: {
          regulatedUnitsVacant = 0,
          unregulatedUnitsVacant = 0,
          unitsApplicant = 0,
        },
      },
    } = v

    const ownerCount = hasSuperUnit ? unitsApplicant - 1 : unitsApplicant,
      vacantCount =
        Number(regulatedUnitsVacant) + Number(unregulatedUnitsVacant),
      tenantCount =
        numberOfResRegulatedUnit -
        Number(regulatedUnitsVacant) +
        numberOfResUnregulatedUnit -
        Number(unregulatedUnitsVacant)

    const [rrOwner, rrTenant, rrVacant, hasRrSuper] =
      getRentRollData(currentUnits)

    // const [currentOwner, currentTenant, currentVacant, hasCurrentSuper] =
    //   getRentRollData(currentUnits)

    const rrDataActual =
      currentUnits?.length === numberOfResidentialUnit &&
      rrOwner === ownerCount &&
      rrTenant === tenantCount &&
      rrVacant === vacantCount &&
      hasRrSuper === hasSuperUnit

    if (!rrDataActual) {
      // refill units array
      emptyUnits = [
        ...createEmptyUnitTypes(hasSuperUnit ? 1 : 0, 'super'),
        ...createEmptyUnitTypes(ownerCount, 'owner'),
        ...createEmptyUnitTypes(vacantCount, 'vacant'),
        ...createEmptyUnitTypes(tenantCount, 'tenant'),
      ].map(
        (u, index): RentRollResidentialUnit => ({
          unitApartmentNumber: (index + 1).toString(),
          currentlyOwnerOccupied: u === 'owner',
          currentlyTenantOccupied: u === 'tenant',
          isSuperUnit: u === 'super',
          wasVacant: u === 'vacant',
          monthlyRentAmount: u === 'tenant' ? null : 0,
          vacantMonths: null,
          floorNumber: null,
          rentableNetAreaSF: null,
          isRegulated: null,
          hasOneMoreBathrooms: null,
          bathroomsCount: null,
          bedroomsCount: null,
          utilitiesCS: [],
        })
      )

      return set(v, 'rentRollResidential.units', emptyUnits)
    }

    return v
  }

  const handleStorefrontTenantContactInfo: FormDataHookChangeHandler<
    RpieFormData
  > = v => {
    const value = wrap(v)
    v.storefront?.units?.forEach((unit, ui) =>
      unit?.occupants?.forEach((occupant, oi) => {
        if (occupant?.noVacantType?.isContactInformationUnavailable) {
          const field = f => [
            'storefront',
            'units',
            ui,
            'occupants',
            oi,
            'noVacantType',
            f,
          ]
          value
            .set(field('contactName'), null)
            .set(field('primaryPhone'), null)
            .set(field('emailAddress'), null)
        }
      })
    )
    return value.value()
  }

  const prefillStorefrontUnit: FormDataHookChangeHandler<RpieFormData> = (
    v,
    prev
  ) => {
    const value = wrap(v)
    if (!prev && currentProperty) {
      const {
        bblsData: { houseNum, street },
        defaultServiceData,
      } = currentProperty,
        prefilledFields = {
          unitApartmentNumber: index => `${index + 1}`,
          houseNo: houseNum,
          streetName: street,
        },
        prefillUnitFn = unitIndex => (fieldName, defaultValue) =>
          get(defaultServiceData, [
            'storefront',
            'units',
            unitIndex,
            fieldName,
          ]) || defaultValue

      v?.storefront?.units?.forEach((unit, index) => {
        const fillFn = prefillUnitFn(index)
        Object.entries(prefilledFields).forEach(([k, v]) => {
          if (!unit[k])
            value.set(
              ['storefront', 'units', index, k],
              fillFn(k, isCallback(v) ? v(index) : v)
            )
        })
      })
    }
    return value.value()
  }

  const handleResidentialUnits: FormDataHookChangeHandler<RpieFormData> = (
    v,
    prevValue
  ) => {
    const value = wrap(v)

    function checkVacant() {
      if (v.general.numberOfResRegulatedUnit === 0)
        value.set('vacantOwnerTable.usesResidential.regulatedUnitsVacant', null)
      if (v.general.numberOfResUnregulatedUnit === 0)
        value.set(
          'vacantOwnerTable.usesResidential.unregulatedUnitsVacant',
          null
        )
    }

    function checkIncomeRegUnreg() {
      const sections = v?.income?.sections || [],
        getSection = sectionType =>
          sections.find(section => section.typeCS === sectionType),
        getCategoryItem = categoryType =>
          getSection(IncomeSectionTypes.IncomeMain)?.items.find(
            item => item.categoryCS === categoryType
          )

      sections.forEach(s => s.items.forEach(i => (i.$show = true)))

      const regIncome = getCategoryItem(IncomeCategories.ResRegulated),
        unregIncome = getCategoryItem(IncomeCategories.ResUnregulated)

      if (regIncome) {
        regIncome.numberOfUnits = v.general.numberOfResRegulatedUnit
        regIncome.$show = v.general.numberOfResRegulatedUnit > 0
        if (regIncome.numberOfUnits === 0) regIncome.lastYearAmount = null
      }

      if (unregIncome) {
        unregIncome.numberOfUnits = v.general.numberOfResUnregulatedUnit
        unregIncome.$show = v.general.numberOfResUnregulatedUnit > 0
        if (unregIncome.numberOfUnits === 0) unregIncome.lastYearAmount = null
      }

      value.set('income.sections', sections)
    }

    if (v.vacantOwnerTable.usesResidential.unitsApplicant === 0) {
      value.set('general.hasSuperUnit', false)
    }

    checkVacant()
    checkIncomeRegUnreg()
    return value.value()
  }

  // const handleCalculateRegAndUnregUnitsOnResedential: FormDataHookChangeHandler<
  //   RpieFormData
  // > = (v, prevState) => {
  //   if (!prevState) return v

  //   const value = wrap(v)

  //   if (
  //     prevState?.general.numberOfResidentialUnit !==
  //     v.general.numberOfResidentialUnit
  //   ) {
  //     value
  //       .set('vacantOwnerTable.usesResidential.unitsApplicant', 0)
  //       .set('general.numberOfResRegulatedUnit', 0)
  //       .set('general.numberOfResUnregulatedUnit', 0)
  //   } else if (
  //     prevState.vacantOwnerTable.usesResidential.unitsApplicant !==
  //     v.vacantOwnerTable.usesResidential.unitsApplicant
  //   ) {
  //     value
  //       .set('general.numberOfResRegulatedUnit', 0)
  //       .set('general.numberOfResUnregulatedUnit', 0)
  //   }

  //   const allowedSumResUnres =
  //     v.general.numberOfResidentialUnit -
  //     v.vacantOwnerTable.usesResidential.unitsApplicant

  //   if (
  //     prevState?.general.numberOfResRegulatedUnit !==
  //     v.general.numberOfResRegulatedUnit
  //   ) {
  //     const numberOfResRegulatedUnit = Math.min(
  //         v.general.numberOfResRegulatedUnit,
  //         allowedSumResUnres
  //       ),
  //       numberOfResUnregulatedUnit =
  //         allowedSumResUnres - v.general.numberOfResRegulatedUnit

  //     value
  //       .set('general.numberOfResRegulatedUnit', numberOfResRegulatedUnit)
  //       .set('general.numberOfResUnregulatedUnit', numberOfResUnregulatedUnit)
  //   } else if (
  //     prevState?.general?.numberOfResUnregulatedUnit !==
  //     v.general.numberOfResUnregulatedUnit
  //   ) {
  //     const numberOfResUnregulatedUnit = Math.min(
  //         v.general.numberOfResUnregulatedUnit,
  //         allowedSumResUnres
  //       ),
  //       numberOfResRegulatedUnit =
  //         allowedSumResUnres - v.general.numberOfResUnregulatedUnit
  //     value
  //       .set('general.numberOfResRegulatedUnit', numberOfResRegulatedUnit)
  //       .set('general.numberOfResUnregulatedUnit', numberOfResUnregulatedUnit)
  //   }

  //   return value.value()
  // }

  const handleRentRollComStatus: FormDataHookChangeHandler<
    RpieFormData
  > = v => {
    const value = wrap(v)
    if (Array.isArray(v.rentRollCommercial.units))
      v.rentRollCommercial.units.forEach((u, i) => {
        if (u.currentlyOwnerOccupied) {
          value
            .set(['rentRollCommercial', 'units', i, 'grossRentAmount'], 0)
            .set(['rentRollCommercial', 'units', i, 'baseRentAmount'], 0)
        }
      })
    return value.value()
  }

  const handleAddServiceFields: FormDataHookChangeHandler<
    RpieFormData
  > = value => {
    if (!value.hasOwnProperty('$certified')) {
      return set(value, '$certified', false)
    }
    return value
  }

  const handleDefaultReportingPeriod: FormDataHookChangeHandler<
    RpieFormData
  > = v => {
    if (v.general.isPropertyToNetLease) {
      const result = setDefaultPeriod(
        wrap(v.income.reportingPeriod)
          .set('reportingPeriodTypeCS', ReportingPeriodType.Calendar)
          .set('hasCustomPeriod', false),
        currentProperty?.fiscalYear - 1
      )
      return set(v, 'income.reportingPeriod', result.value())
    }

    const value = wrap(v)
    if (!v.income.reportingPeriod?.hasCustomPeriod)
      value.set('income.reportingPeriod.hasCustomPeriod', false)

    return value.value()
  }

  //TODO: Make handle functions pure and make tests for them
  const handlers: FormDataHookChangeHandlers<RpieFormData> = [
    handleCommercialUnits,
    handleWasAnyRentalIncome,
    handleRentRollResUnits,
    handleStorefrontTenantContactInfo,
    prefillStorefrontUnit,
    handleResidentialUnits,
    // handleCalculateRegAndUnregUnitsOnResedential,
    handleRentRollComStatus,
    handleAddServiceFields,
    handleDefaultReportingPeriod,
  ]

  return useFormDataHook(
    valueProp,
    // []
    handlers
  )
}

export default useRpieFormDataHook

// const sectionsRequirements = useCallback(
//   (value: RpieFormData): SectionRequirementsSection => {
//     if (!value || !currentProperty)
//       return {
//         general: false,
//         vacantOwnerTable: false,
//         income: false,
//         expense: false,
//         storefront: false,
//         rentRollCommercial: false,
//         rentRollResidential: false,
//         _sfGrossRentRequired: false,
//       }

//     const general = !(
//       value.serviceInputs.hasTC10x && value.serviceInputs.hasTC20x
//     )
//     const income =
//       !value.serviceInputs.hasTC20x &&
//       !value.general.isPropertyToNetLease &&
//       (!currentProperty.isConsolidated
//         ? true
//         : currentProperty.isRootConsolidateBBL) &&
//       // #5489 RPIE Portal - Hide Income and Expense Section - "6 or Fewer Not More Than 1 Comm"
//       // && !(value.serviceInputs.has6PimaryResid1CommercialExclusion && value.storefront.totalUnitNumber >= 0)
//       // #5732 [RPIE] If any exclusion on the property we need to hide I&E tab on the Portal
//       value.serviceInputs.rpieExclusionCS === null
//     // &&      !!(!value.income.showUploadArea || isAdmin)

//     const storefront =
//       value.general.numberOfCommercialUnit > 0 &&
//       value.storefront.totalUnitNumber > 0
//     // value.serviceInputs.totalStoreFrontUnitsInCore !== 0

//     const rentRollCommercial =
//       value.serviceInputs.ActualAV >= 750000 &&
//       value.general.numberOfCommercialUnit > 0 &&
//       value.general.numberOfCommercialUnit - value.storefront.totalUnitNumber > 0

//     const rentRollResidential =
//       value.serviceInputs.ActualAV >= 750000 &&
//       value.general.numberOfResidentialUnit > 0

//     const _sfGrossRentRequired =
//       value.serviceInputs.ActualAV >= 750000 &&
//       value.general.numberOfCommercialUnit > 0

//     return {
//       general,
//       vacantOwnerTable: general,
//       income,
//       expense: income,
//       storefront,
//       rentRollCommercial,
//       rentRollResidential,
//       _sfGrossRentRequired,
//     }
//   },
//   [currentProperty]
// )
