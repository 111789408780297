import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { getCallback } from 'helpers/utils'
import { refObject } from 'ui-framework/common/types'

//===================================================
export type OnFocusHandler = (event?: React.FocusEvent<HTMLInputElement>) => any
type FocusTrapProps = {
  targetFocusRef?: React.RefObject<HTMLElement> | refObject
  onFocus?: OnFocusHandler
} & BaseComponentProps

/**
 * FocusTrap component
 */
const FocusTrap = (props: FocusTrapProps) => {
  const handleFocus = e => {
    props.targetFocusRef?.current
      ? props.targetFocusRef.current.focus()
      : getCallback(props.onFocus)(e)
  }
  return (
    <div
      tabIndex={0}
      style={{
        opacity: '0',
      }}
      onFocusCapture={handleFocus}
    />
  )
}

export default FocusTrap
