import { ChakraProps } from '@chakra-ui/system'
import { AnyFunction } from 'helpers/utils'
import React, { ReactElement, useMemo } from 'react'
import Table, { TableProps } from '../Table'
import { TableHeaderItems } from '../Table/TableHeaders'
import TableWithTitle from '../TableWithTitle'
import { Colors } from './Colors'

export type GroupedTableProps = {
  colorChildIndexes?: number[]
  getTableFooter?: AnyFunction
} & TableProps

const defaultValue = {
    headers: [] as TableHeaderItems | undefined,
  },
  isAcceptableChild = child => child?.type?.name === TableWithTitle.name

export const GroupedTablesContext = React.createContext(defaultValue)

/* Attention! `noborder` for headers in GroupedTables doesn't work, cause border for header removed HERE! */

const GroupedTables = (props: GroupedTableProps) => {
  const { children, colorChildIndexes = [], ...rest } = props,
    { calculatedStyles, content } = useMemo(() => {
      const clearedItems = ((children as ReactElement[]) || []).filter(ch => ch)
      return clearedItems.reduce(
        (acc, child, ind) => {
          const { calculatedStyles, content } = acc,
            { subBg, bg } = Colors[colorChildIndexes[ind] || ind]

          return isAcceptableChild(child)
            ? {
                calculatedStyles: {
                  ...calculatedStyles,
                  [`& div:nth-of-type(${ind + 1}) > table > tbody`]: {
                    td: {
                      bg,
                      '& > div.title': {
                        bg: subBg,
                      },
                    },
                  },
                },
                content: [...content, child],
              }
            : acc
        },
        { calculatedStyles: {} as ChakraProps, content: [] as ReactElement[] }
      )
    }, [children, colorChildIndexes]),
    styles = useMemo(
      () => ({
        ...calculatedStyles,
        'div:not(:first-of-type) > table': {
          borderTop: '1px solid',
          borderColor: 'secondary.divider',
        },
      }),
      [calculatedStyles]
    )

  return (
    <Table {...rest} styles={styles}>
      <tr>
        <td colSpan={props?.headers?.length} style={{ padding: '0' }}>
          <GroupedTablesContext.Provider
            value={{
              headers: props.headers,
            }}
          >
            {content}
          </GroupedTablesContext.Provider>
        </td>
      </tr>
    </Table>
  )
}

export default GroupedTables
