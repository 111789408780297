import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'
import { BusinessActivity } from 'constants/dicti'

export const commonUnitSchema = {
  unitApartmentNumber: schemaRules.NOT_EMPTY_STRING.max(10)
    .custom((value, helpers) => {
      const originalValue = helpers.prefs.context?.originalValue,
        editingUnit = originalValue?.unit,
        samesUnitApartmentNumbers = originalValue?.units?.filter(
          (e, i) => editingUnit?.index !== i && e.unitApartmentNumber === value
        ),
        hasSame = value => value?.length >= (editingUnit ? 1 : 2)

      if (hasSame(samesUnitApartmentNumbers))
        return helpers.error('custom.exists')
    })
    .messages({
      'custom.exists': 'You listed the same apartment number more than once',
    }), //req
  currentlyOwnerOccupied: Joi.when('wasVacant', {
    is: true,
    then: schemaRules.ANY,
    otherwise: Joi.when('currentlyTenantOccupied', {
      is: true,
      then: schemaRules.ANY,
      otherwise: Joi.when('isSuperUnit', {
        is: true,
        then: schemaRules.ANY,
        otherwise: schemaRules.VALID(true).empty(['', null, false]),
      }),
    }),
  }), // req
  currentlyTenantOccupied: Joi.when('wasVacant', {
    is: true,
    then: schemaRules.ANY,
    otherwise: Joi.when('currentlyOwnerOccupied', {
      is: true,
      then: schemaRules.ANY,
      otherwise: Joi.when('isSuperUnit', {
        is: true,
        then: schemaRules.ANY,
        otherwise: schemaRules.VALID(true).empty(['', null, false]),
      }),
    }),
  }), // req
  floorNumber: schemaRules.NOT_EMPTY_STRING, // req
}

const commercialUnitSchema = {
  ...commonUnitSchema,
  rentableNetAreaSF: schemaRules.GREATER_NUMBER(),
  wasVacant: schemaRules.ANY, // req
  vacantMonths: Joi.when('wasVacant', {
    is: true,
    then: schemaRules.MAX_NUMBER(12),
    otherwise: schemaRules.ANY,
  }),
  grossRentAmount: schemaRules.NOT_EMPTY_NUMBER.positive()
    .precision(2)
    .when('currentlyOwnerOccupied', {
      is: true,
      then: Joi.allow(0),
      otherwise: Joi.when('wasVacant', {
        is: true,
        then: Joi.allow(0),
        otherwise: schemaRules.GREATER_NUMBER(),
      }),
    }),
  baseRentAmount: schemaRules.NOT_EMPTY_NUMBER.positive()
    .precision(2)
    .when('currentlyOwnerOccupied', {
      is: true,
      then: Joi.allow(0),
      otherwise: Joi.when('wasVacant', {
        is: true,
        then: Joi.allow(0),
        otherwise: schemaRules.GREATER_NUMBER(),
      }),
    }), //req
  primaryBusinessActivityCS: Joi.when('wasVacant', {
    switch: [
      {
        is: null,
        then: schemaRules.NOT_EMPTY_STRING,
      },
      {
        is: false,
        then: schemaRules.NOT_EMPTY_STRING,
      },
    ],
    otherwise: schemaRules.ANY,
  }), //req
  otherPrimaryBusinessActivityDescription: Joi.when(
    'primaryBusinessActivityCS',
    {
      switch: [
        {
          is: BusinessActivity.Other,
          then: schemaRules.NOT_EMPTY_STRING.max(60),
        },
        {
          is: BusinessActivity.MiscellaneousOtherService,
          then: schemaRules.NOT_EMPTY_STRING.max(60),
        },
      ],
      otherwise: schemaRules.ANY,
    }
  ),
}

const schema = schemaWrapper({
  units: schemaRules.ITEMS(commercialUnitSchema).min(0),
})

export default schema
