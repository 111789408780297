import React from 'react'
import dayjs from 'dayjs'
import { getCallback, keyboardEventListener } from 'helpers/utils'
import { Flex } from '@chakra-ui/react'
import { ChakraComponentProps, OnClickHandler } from 'ui-framework/common/types'
import { useCalendarStyles } from 'ui-framework/components/primitives/Calendar'

//===================================================
export type DaySelectedState =
  | 'single'
  | 'start'
  | 'end'
  | 'continuous'
  | boolean

type Props = {
  date?: Date
  selected?: DaySelectedState
  inRange?: boolean
  onClick?: OnClickHandler
  disabled?: boolean
} & ChakraComponentProps

/**
 * Day component
 */
const Day = ({
  date,
  selected,
  onClick,
  inRange,
  disabled,
  ...props
}: Props) => {
  const style = useCalendarStyles(),
    className = selected
      ? 'selected'
      : disabled
      ? 'disabled'
      : inRange
      ? 'range'
      : ''
  return (
    <Flex
      sx={style.dayContentWrapper}
      className={props.className}
      onClick={() => !disabled && getCallback(onClick)()}
      onKeyDown={e =>
        !disabled && keyboardEventListener(e, getCallback(onClick))
      }
      tabIndex={0}
    >
      <Flex sx={style.dayContent} className={className}>
        {dayjs(date).date()}
      </Flex>
    </Flex>
  )
}

export default Day
