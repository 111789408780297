import React from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'
import SimpleLayout from '.'

//===================================================
type SimpleLayoutWrapperProps = {}
const SimpleLayoutWrapper = styled('div')<SimpleLayoutWrapperProps>(props => ({
  height: 'calc(100vh - 36px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

type Props = {} & BaseComponentProps

/**
 * SimpleLayoutCentered component
 */
const SimpleLayoutCentered = (props: Props) => {
  return (
    <SimpleLayout className={props.className}>
      <SimpleLayoutWrapper>{props.children}</SimpleLayoutWrapper>
    </SimpleLayout>
  )
}

export default SimpleLayoutCentered
