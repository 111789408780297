import React, { useState } from 'react'
import Icon from 'ui-framework/components/primitives/Icon'

const useShowPassword = (): [string, any] => {
  const [isShow, isShowSet] = useState<boolean>(false),
    type = isShow ? 'text' : 'password',
    showIcon = (
      <Icon onClick={() => isShowSet(!isShow)}>{`eye${
        !isShow ? '' : '-slash'
      }`}</Icon>
    )

  return [type, showIcon]
}

export default useShowPassword
