import { HStack, Image, VStack, Box, Text } from '@chakra-ui/react'
import styled from '@emotion/styled/macro'

import useHomePage from './useHomePage'
import Card from 'ui-framework/components/primitives/Card'
import { nanoid } from 'nanoid'
import { useApplications } from 'hooks/api/firebase/useApplications'
import useAuth from 'hooks/useAuth'
import { getCallback, isCallback } from 'helpers/utils'
import Loader from 'ui-framework/components/primitives/Loader'

const BlockCard = styled(Card)({
    width: 510,
    height: 584,
    '@media(min-width: 1680px)': {
      width: 550,
      height: 624,
    },
    '@media(min-width: 1920px)': {
      width: 590,
      height: 664,
    },
  }),
  Description = styled.div({
    '@media(min-width: 1680px)': {
      '&, *': {
        fontSize: '20px',
        lineHeight: '26px',
      },
    },
    '@media(min-width: 1920px)': {
      '&, *': {
        fontSize: '22px',
        lineHeight: '28px',
      },
    },
  })

const HomePage = props => {
  const { user } = useAuth(),
    { data: applications, isPending } = useApplications(),
    homePageConfig = useHomePage()

  return (
    <Box
      className={props.className}
      p={'0 76px 0 40px'}
      bgColor={'white100'}
      h={'100vh'}
    >
      {!isPending && user ? (
        <HStack
          spacing={'24px'}
          justifyContent={`center`}
          mt={'78px'}
          minW={`1045px`}
        >
          {homePageConfig.map(item => (
            <BlockCard key={nanoid()}>
              <VStack flexDirection={'column'} h={`100%`}>
                <Image h={'300px'} w={'100%'} src={item.src} />
                <VStack
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  h={`100%`}
                >
                  <VStack p={'16px 20px 0'} spacing={'16px'}>
                    <Text
                      textStyle="body.semibold"
                      color="fontnavy"
                      w="100%"
                      textAlign="center"
                      lineHeight="h4"
                      fontSize="18px"
                      sx={{
                        '@media(min-width: 1920px)': {
                          fontSize: 'h6',
                        },
                      }}
                    >
                      {item.title}
                    </Text>
                    <Description>{item.description}</Description>
                  </VStack>
                  {isCallback(item?.getToolbar) ? (
                    <VStack p={'24px 20px 20px'} spacing={'24px'} w={`100%`}>
                      {getCallback(item.getToolbar)(applications)}
                    </VStack>
                  ) : (
                    <div style={{ height: '100px' }} />
                  )}
                </VStack>
              </VStack>
            </BlockCard>
          ))}
        </HStack>
      ) : (
        <Loader height={'100%'} />
      )}
    </Box>
  )
}

export default HomePage
