import { log } from 'helpers/utils'
import { cloneDeep } from 'lodash-es'
import { get, merge, set, wrap } from 'object-path-immutable'
import { useCallback, useEffect, useMemo, useState } from 'react'

const testDataMapKey = (key: string): [boolean, string] => {
  const readOnly = key.length > 1 && key[0] === '$'
  return [readOnly, readOnly ? key.slice(1) : key]
},
  tryDeepDataMapKey = (dataMap, key) => {
    const mapKey = Object.keys(dataMap).find(k => key.indexOf(k) === 0)
    if (mapKey) {
      const mk = Array.isArray(dataMap[mapKey])
        ? dataMap[mapKey].join('.')
        : dataMap[mapKey],
        result = key.replace(mapKey, mk)
      return result
    }
  }

type UseStepsStateResult = {}

function useStepState<T>(data, { id, dataMap = {} }) {
  const buildProps = useCallback((data, dataMap, id) => {
    const ps = wrap({})
    Object.entries(dataMap).map(([propKey, dataKey]) => {
      // is this the $-readonly key
      const [readOnly, key] = testDataMapKey(propKey)
      // if readonly use dataKey as value otherwise...
      // use data[dataKey] as value
      const value = readOnly ? dataKey : get(data, dataKey as any, null)

      ps.set(key, value)
    })

    ps.set('stepId', id)
    return ps.value()
  }, [])

  const [state, stateSet] = useState<any>(buildProps(data, dataMap, id))

  useEffect(() => {
    stateSet(buildProps(data, dataMap, id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onChange = useCallback((value, name) => {
    // case of multichange or single change
    // multichange value: Partial<Props>; name = undefined
    // singlechange value: Pick<Props, 'some'>, name = 'some'
    stateSet(prevState => {
      const changeFunc = !!name ? set : merge
      // log('useStepState.onChange', value, name, changeFunc(prevState, name, value))
      return changeFunc(prevState, name, value)
    })
  }, [])

  const getUpdatedData = useCallback(() => {
    let newData = wrap(data)
    Object.entries(dataMap).forEach(([propKey, dataKey]) => {
      const [readOnly, key] = testDataMapKey(propKey)
      if (!readOnly) {
        newData.set(dataKey as any, get(state, key, null))
      }
    })

    const result = newData.value()
    // log('useStepState.getUpdatedData/', result, dataMap, state)
    return result
  }, [data, dataMap, state])

  const result = useMemo(
    () => ({
      props: state,
      onChange,
      getUpdatedData,
    }),
    [state, onChange, getUpdatedData]
  )

  // log('useStepState.getUpdatedData()', result.props, result.getUpdatedData().uses.residentialInfo)
  return result
}

export default useStepState
