import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { chakra } from '@chakra-ui/react'

//===================================================

const CenterFormWrapper = chakra('div', {
  baseStyle: {
    width: '411px',
    borderRadius: '12px',
    bgColor: 'white100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.08)',
    color: 'fontnavy',
  },
})

const CenterFormTitle = chakra('div', {
  baseStyle: {
    textStyle: 'body.semibold',
    fontSize: 'h6',
    color: 'fontnavy',
    lineHeight: 'h6',
    marginBottom: '24px',
  },
})

type CenterFormProps = {
  title?: string
} & BaseComponentProps

/**
 * CenterForm component
 */
const CenterForm = (props: CenterFormProps) => {
  return (
    <CenterFormWrapper className={props.className}>
      {props.title && <CenterFormTitle>{props.title}</CenterFormTitle>}
      {props.children}
    </CenterFormWrapper>
  )
}

export default chakra(CenterForm)
