import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'

import BaseComponentProps from 'common/BaseComponentProps'
import { useTheme } from '@chakra-ui/system'

/**
|--------------------------------------------------
| STYLES
|--------------------------------------------------
*/
type LoaderStyledProps = {
  height?: number | string | null
}
const LoaderWrapper = styled.div<LoaderStyledProps>(props => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: props.height || undefined,
}))

// ============================================================
type Props = {
  /**
   * Toggles inverted colors
   * @default false
   */
  invert?: boolean
  /**
   * Specify size 'small' | 'normal' | 'large'
   * @default 'normal'
   */
  size?: 'small' | 'normal' | 'large' | 'extra'
  height?: number | string
} & BaseComponentProps

// ============================================================

function Loader({ size = 'normal', invert = false, height, ...props }: Props) {
  const { colors } = useTheme()

  const loaderColors = useMemo(
    () => ({
      arc: invert ? colors.white100 : colors.primary.base,
    }),
    [colors.primary.base, colors.white100, invert]
  )

  const sizePx = useMemo(
    () =>
      ({
        small: 24,
        normal: 40,
        large: 60,
        extra: 130,
      }[size]),
    [size]
  )

  return (
    <LoaderWrapper className={props.className} data-cy="loader" height={height}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: 'auto',
          background: 'none',
          display: 'block',
          shapeRendering: 'auto',
          width: sizePx,
          height: sizePx,
        }}
        viewBox="16 16 68 68"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.68359375s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(45 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.5859375s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.48828125s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(135 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.390625s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.29296875s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(225 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.1953125s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="-0.09765625s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(315 50 50)">
          <rect
            x="46.5"
            y="16.5"
            rx="3.5"
            ry="4.5600000000000005"
            width="7"
            height="19"
            fill={loaderColors.arc}
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.78125s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </LoaderWrapper>
  )
}

export default Loader
export { LoaderWrapper }
