import { ReactNode } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Image } from '@chakra-ui/react'
import { AnyFunction } from 'helpers/utils'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import ConnectionLost from './assets/connection-lost.png'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import useBlobFromLink from 'hooks/useBlobFromLink'

//==================================

const useLooseConectionDialog = (): [AnyFunction, AnyFunction, ReactNode] => {
  const looseConnectionDialog = useDisclosure()
  const image = useBlobFromLink(ConnectionLost)

  return [
    looseConnectionDialog.onOpen,
    looseConnectionDialog.onClose,
    <ModalDialog
      key={'looseConnectionDialog'}
      isOpen={looseConnectionDialog.isOpen}
      title={<Image h={'250px'} src={image} />}
      size={'w500'}
    >
      <div style={{ paddingBottom: '20px' }}>
        <TextFromParams
          params={{
            textStyle: 'body.semibold',
            lineHeight: 'h4',
            fontSize: 'h6',
          }}
        >
          No Internet Connection
        </TextFromParams>
        <div style={{ width: '100%', marginTop: '16px' }}>
          <TextFromParams
            params={{
              textAlign: 'left',
            }}
          >
            It looks like you don't have an internet connection.
          </TextFromParams>
          <p style={{ margin: '8px 0 6px' }}>
            <b>Please try:</b>
          </p>
          <ul style={{ margin: '0 0 0 16px', paddingLeft: '8px' }}>
            <li>Checking the network cables, modem, and router;</li>
            <li>Reconnecting to Wi-Fi.</li>
          </ul>
        </div>
      </div>
    </ModalDialog>,
  ]
}

export default useLooseConectionDialog
