import React, { useState, useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import CenterForm from './CenterForm'
import { Text, VStack, HStack } from '@chakra-ui/react'
import Input from 'ui-framework/components/primitives/Input'
import Button from 'ui-framework/components/primitives/Button'

import { URL_AUTH_LOGIN } from 'constants/URLS'
import dayjs, { unix } from 'dayjs'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'
import { AnyFunction } from 'helpers/utils'
import useDoubleClickValidationCallback from 'hooks/useDoubleClickValidationCallback'
import { resetEmailSchema } from 'pages/Auth/schema'
import { useNavigate } from 'react-router-dom'

//===================================================
type PasswordResetFormProps = {
  blocked?: string
  retry?: boolean
  onReset: AnyFunction
} & BaseComponentProps

/**
 * PasswordResetForm component
 */
const PasswordResetForm = ({
  retry,
  blocked: blockedProp,
  onReset,
}: PasswordResetFormProps) => {
  const navigate = useNavigate()
  const [email, emailSet] = useState<string>()

  const blocked = useMemo(() => {
    return blockedProp && unix(Number(blockedProp)).diff(dayjs(), 'minutes')
  }, [blockedProp])

  const validationData = useMemo(() => ({ email }), [email])
  const [errors, onValidation] = useDoubleClickValidationCallback(
    validationData,
    resetEmailSchema,
    onReset
  )

  return (
    <CenterForm title={retry ? 'The Reset Link Expired' : 'Reset Password'}>
      <VStack spacing="24px" width="100%">
        {retry ? (
          <Text w="300px" align="center">
            If you want to receive the reset link again, please enter your email
            address below.
          </Text>
        ) : (
          <Text w="213px" align="center">
            We’ll email you instructions to reset your password.
          </Text>
        )}
        <VStack spacing="36px" w="100%">
          <Input
            placeholder={`Enter your email`}
            label="Email"
            value={email}
            onChange={emailSet}
            width={'100%'}
            error={errors?.email}
            fixedHeight={false}
          />
          <Button w="100%" disabled={!!blocked} onClick={onValidation}>
            {blocked ? `Next try in ${blocked} mins` : `Reset Password`}
          </Button>
        </VStack>
        <HStack justifyContent="space-between" w="100%">
          <Button
            variant="textPrimary"
            onClick={() => navigate(URL_AUTH_LOGIN)}
          >
            Back to Log In
          </Button>
          <Button
            variant="textPrimary"
            to={`mailto:${SUPPORT_CONTACTS.contactUsEmail}`}
          >
            Contact Us
          </Button>
        </HStack>
      </VStack>
    </CenterForm>
  )
}

export default PasswordResetForm
