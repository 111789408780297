import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'

import { IncomeSectionTypes } from 'constants/dicti'

//TODO: decide whether we need it
// const checkValid = isRegulated => (value, helpers) => {
//   const {
//       prefs: {
//         context: {
//           numberOfResRegulatedUnit,
//           numberOfResUnegulatedUnit,
//           originalValue: {
//             vacantOwnerTable: {
//               usesResidential: { regulatedUnitsVacant, unregulatedUnitsVacant },
//             },
//           },
//         },
//       },
//     } = helpers,
//     isNull = isNullOrWhiteSpace(value),
//     isEqual = isRegulated
//       ? numberOfResRegulatedUnit === regulatedUnitsVacant
//       : numberOfResUnegulatedUnit === unregulatedUnitsVacant,
//     hasValue = isRegulated
//       ? numberOfResRegulatedUnit > 0
//       : numberOfResUnegulatedUnit > 0,
//     rule =
//       hasValue && ((isEqual && isNull) || (!isEqual && (isNull || value === 0)))

//   if (rule) return helpers.message(`Must be ${!isEqual ? 1 : 0} or greater`)
// }

const mainSectionItem = {
  $show: schemaRules.BOOLEAN.optional(),
  categoryCS: schemaRules.NOT_EMPTY_STRING,
  isNumberOfUnitsRequired: Joi.boolean(),
  numberOfUnits: Joi.when('..$show', {
    not: false,
    then: Joi.when('isNumberOfUnitsRequired', {
      is: true,
      then: schemaRules.NOT_EMPTY_NUMBER,
      otherwise: schemaRules.ANY,
    }),
    otherwise: schemaRules.ANY,
  }),
  lastYearAmount: Joi.when('..$show', {
    not: false,
    then: schemaRules.NOT_EMPTY_NUMBER,
    // switch: [
    //   {
    //     is: IncomeCategories.ResRegulated,
    //     then: schemaRules.CUSTOM(checkValid(true)),
    //   },
    //   {
    //     is: IncomeCategories.ResUnregulated,
    //     then: schemaRules.CUSTOM(checkValid(false)),
    //   },
    //   {
    //     is: schemaRules.STRING,
    //     then: schemaRules.NOT_EMPTY_NUMBER,
    //   },
    // ],
    otherwise: schemaRules.ANY,
  }),
}

const prevYearRequired = {
  prevYearAmount: schemaRules.ANY,
  lastYearAmount: Joi.when('prevYearAmount', {
    is: schemaRules.GREATER_NUMBER(),
    then: schemaRules.NOT_EMPTY_NUMBER,
    otherwise: schemaRules.ANY,
  }),
}

const ancillaryItem = {
  categoryCS: schemaRules.NOT_EMPTY_STRING,
  ...prevYearRequired,
}
const otherItem = {
  categoryName: schemaRules.SOME_SYMBOL_STRING(3),
  ...prevYearRequired,
}

const additionalSectionItem = {
  categoryCS: schemaRules.NOT_EMPTY_STRING,
  lastYearAmount: schemaRules.NOT_EMPTY_NUMBER,
  isNumberOfUnitsRequired: Joi.boolean(),
  numberOfUnits: Joi.when('isNumberOfUnitsRequired', {
    is: true,
    then: schemaRules.NOT_EMPTY_NUMBER,
    otherwise: schemaRules.ANY,
  }),
}

const incomeSectionSchema = {
  wasIncomeFromRentalTenants: schemaRules.ANY,
  typeCS: schemaRules.NOT_EMPTY_STRING,
  items: Joi.when('typeCS', {
    switch: [
      {
        is: IncomeSectionTypes.IncomeMain,
        then: schemaRules.ITEMS(mainSectionItem),
      },
      {
        is: IncomeSectionTypes.HotelMain,
        then: schemaRules.ITEMS(mainSectionItem),
      },
      {
        is: IncomeSectionTypes.CondoMain,
        then: schemaRules.ITEMS(mainSectionItem),
      },
      {
        is: IncomeSectionTypes.IncomeAdditional,
        then: schemaRules.ITEMS(ancillaryItem),
      },
      {
        is: IncomeSectionTypes.HotelRental,
        then: schemaRules.ITEMS(additionalSectionItem),
      },
      {
        is: IncomeSectionTypes.CondoRental,
        then: schemaRules.ITEMS(additionalSectionItem),
      },
      {
        is: IncomeSectionTypes.IncomeOther,
        then: schemaRules.ITEMS(otherItem),
      },
      {
        is: IncomeSectionTypes.HotelOther,
        then: schemaRules.ITEMS(otherItem),
      },
      {
        is: IncomeSectionTypes.CondoOther,
        then: schemaRules.ITEMS(otherItem),
      },
    ],
    otherwise: schemaRules.ANY,
  }),
}

const schema = schemaWrapper({
  sections: schemaRules.ITEMS(incomeSectionSchema),
  total: schemaRules.GREATER_NUMBER(0),
})

export default schema
