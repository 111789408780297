import React, { ReactElement } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  useMultiStyleConfig,
  chakra,
  ChakraProps,
} from '@chakra-ui/react'
import Button from '../Button'
import { ChakraComponentProps } from 'ui-framework/common/types'

type OnboardingDialogProps = {
  show: boolean
  showSet: (value: boolean) => void
  styles?: ChakraProps
} & ChakraComponentProps

const OnboardingDialog = ({ styles, ...props }: OnboardingDialogProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure(),
    { show, showSet } = props,
    style = useMultiStyleConfig('MGNYOnboardingDialog', props),
    onCloseHandle = () => {
      show && showSet(false)
      onClose()
    }

  return (
    <>
      <Button
        onClick={onOpen}
        prefix={'info-circle'}
        sx={style.showBtn}
        variant={`textSecondary`}
      />
      <Modal isOpen={show ? !isOpen : isOpen} onClose={onCloseHandle}>
        <ModalOverlay />
        <ModalContent sx={{ ...style.wrapper, ...styles }}>
          <ModalBody p={0}>
            {React.cloneElement(props.children as ReactElement, {
              onCloseHandle,
            })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default chakra(OnboardingDialog)
