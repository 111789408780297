import { ComponentStyleConfig } from '@chakra-ui/react'

const navBarDefault = {
  display: 'flex',
  borderRadius: '6px',
  cursor: 'pointer',
  bgColor: 'primary.base',
  _hover: {
    height: '12px',
    mt: '-3px',
    borderRadius: '6px',
    bgColor: '#282D9A',
  },
}

const ProgressStyles: ComponentStyleConfig = {
  parts: [
    'wrapper',
    'background',
    'bar',
    'navBar',
    'navBarActive',
    'navBarVisited',
  ],
  baseStyle: props => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'start',
      position: 'relative',
    },
    background: {
      height: '6px',
      width: '100%',
      display: 'flex',
      backgroundColor: 'secondary.inputGray',
      position: 'relative',
      borderRightRadius: props.radiusEnd ? '6px' : 'none',
      borderLeftRadius: props.radiusStart ? '6px' : 'none',
    },
    bar: {
      width: `${props.complete}%`,
      transition: 'all .2s',
      display: 'flex',
      borderLeftRadius: props.radiusStart ? '6px' : 'none',
    },
    navBar: {
      bgColor: 'secondary.currentStep',
      cursor: 'pointer',
      borderLeftRadius: props.radiusStart ? '6px' : 'none',
      borderRightRadius: props.radiusEnd ? '6px' : 'none',
      _hover: navBarDefault._hover,
    },
    navBarVisited: navBarDefault,
    navBarActive: {
      ...navBarDefault,
      height: '12px',
      mt: '-3px',
    },
    current: {
      width: props.showCurrent ? `${props.stepPercent}%` : '0',
      backgroundColor: props.onlyFinished
        ? 'transparent'
        : 'secondary.currentStep',
      borderRightRadius:
        props.radiusEnd && props.complete === 100 ? '6px' : 'none',
      transition: 'all .2s',
    },
    title: {
      width: '100%',
      fontFamily: 'body',
      fontSize: 'body',
      lineHeight: 'body',
      fontWeight: 'regular',
      color: props.complete > 0 ? 'primary.base' : 'fontandicongray',
      marginBottom: '10px',
      whiteSpace: 'nowrap',
      // textTransform: 'capitalize'
      // backgroundColor: 'rgba(255,255,255,.8)',
    },
  }),
  variants: {
    normal: props => ({
      bar: {
        backgroundColor:
          props.complete > 0 ? 'primary.base' : 'secondary.inputGray',
      },
    }),
    active: props => ({
      bar: {
        backgroundColor:
          props.complete > 0 ? 'orange.base' : 'secondary.inputGray',
      },
    }),
  },
  defaultProps: {
    variant: 'normal',
  },
}

export default ProgressStyles
