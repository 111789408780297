import { useDisclosure, VStack } from '@chakra-ui/react'
import { AnyFunction } from 'helpers/utils'
// import useApplicationContext from 'hooks/useApplicationContext'
import { ReactNode, useMemo, useState } from 'react'
import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'

const createBlockedPopupIssueDialogConfig = (link: string) => ({
  title: 'Enable your pop-ups!',
  children: (
    <TextFromParams
      params={{
        lineHeight: '25px',
        textAlign: 'left',
      }}
    >
      <VStack spacing={'12px'} align={'left'}>
        <VStack spacing={'16px'} align={'left'}>
          <p>
            There was an issue with the download because the settings of your
            browser do not allow pop-ups.
          </p>
          <p>
            You may download your{' '}
            <Button
              display="inline-block"
              to={link}
              variant={`textPrimary`}
              height={'20px'}
              width={'95px'}
              textDecoration="underline"
            >
              ZIP FILE HERE
            </Button>
            , or follow the instructions bellow to enable pop-ups in your
            browser and then try to download the file from the portal again:{' '}
          </p>
        </VStack>
        <p>
          <b>Step 1.</b> Click on the Pop-Up Blocker icon in the top right
          corner of your browser;{' '}
        </p>
        <p>
          <b>Step 2.</b> Choose the option “Always allow pop-ups and redirects
          from...”;{' '}
        </p>
        <p>
          <b>Step 3.</b> Press “Done”.{' '}
        </p>
      </VStack>
    </TextFromParams>
  ),
})

const useBlockedPopupIssueDialog = (): [ReactNode, AnyFunction] => {
  //TODO: review code of this hook and modal to improve it
  // const { openChat } = useApplicationContext()
  const [link, linkSet] = useState('')
  const issueModal = useDisclosure()
  const { title, children } = useMemo(
    () => createBlockedPopupIssueDialogConfig(link),
    [link]
  )

  const blockedPopupIssueDialog = (
    <ModalDialog
      izLazy
      title={title}
      isOpen={issueModal.isOpen}
      size="w580"
      // footerActions={
      //   <Button
      //     className={'circleRollButton'}
      //     variant={`textPrimary`}
      //     onClick={openChat}
      //   >
      //     Contact us
      //   </Button>
      // }
      onAction={issueModal.onClose}
      actionText={'I Understand'}
    >
      {children}
    </ModalDialog>
  )

  // const openLink = (uri, name) => {
  //   var downloadLink = document.createElement('a')
  //   downloadLink.href = uri
  //   downloadLink.download = name
  //   downloadLink.target = '_blank'

  //   document.body.appendChild(downloadLink)
  //   // console.log(uri, name)
  //   downloadLink.click()
  //   document.body.removeChild(downloadLink)
  // }

  const checkBrowserBlock = (downloadLink: string) => {
    const downloadWindow = window.open(downloadLink, '_blank')
    // openLink(downloadLink, 'ddd.zip')
    if (!downloadWindow || downloadWindow?.closed) {
      linkSet(downloadLink)
      issueModal.onOpen()
    }
  }

  return [blockedPopupIssueDialog, checkBrowserBlock]
}

export default useBlockedPopupIssueDialog
