import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Button from 'ui-framework/components/primitives/Button'
import Popover from 'ui-framework/components/primitives/Popover'
import { Flex } from '@chakra-ui/react'
import { OnClickHandler } from 'ui-framework/common/types'

//===================================================
type AboutAssistantTooltipProps = {
  onClose: OnClickHandler
} & BaseComponentProps

/**
 * AboutAssistantTooltip component
 */
const AboutAssistantTooltip = ({
  children,
  onClose,
  ...props
}: AboutAssistantTooltipProps) => {
  return (
    <Popover
      title="Meet the Assistant!"
      footer={
        <Flex justifyContent="flex-end">
          <Button size={'sm'} onClick={onClose}>
            Got it
          </Button>
        </Flex>
      }
    >
      <p>
        Here you can review helpful information for each step of the
        application. If you need a more detailed explanation of any particular
        field, you can hover over it and refer to the Assistant!
      </p>
      <p>You can open and close the Assistant at any time.</p>
    </Popover>
  )
}

export default AboutAssistantTooltip
