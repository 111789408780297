export const URL_ROOT: string = '/'
export const URL_HOME: string = '/home'
export const URL_AUTH_LOGIN: string = '/auth/login'
export const URL_AUTH_RESET: string = '/auth/reset'
export const URL_AUTH_REGISTRATION: string = '/auth/signup'

export const URL_PROPERTYSEARCH: string = '/public/property-search'
export const URL_PROPERTYDETAILS: string = '/public/property-details'
export const URL_ACCOUNTHISTORYDETAILS: string =
  '/public/account-history-details'
export const URL_DASHBOARD: string = '/dashboard/main'
export const URL_ALERTS: string = '/dashboard/alerts'
export const URL_DOCUMENTS: string = '/documents'
export const URL_HANDLING: string = '/handling'

export const URL_SERVICERPIELIST: string = '/rpie'
export const URL_SERVICERPIEFORM: string = '/rpie/:bblid'
export const URL_SERVICERPIEFORMSUMMARY: string = '/rpie/:bblid/summary'
export const URL_SERVICERPIETRACKING: string = '/rpie/tracking'

export const URL_SERVICETALIST: string = '/taxappeal'
export const URL_SERVICETAFORM: string = '/taxappeal/:bblid'
export const URL_SERVICETAFORMSUMMARY: string = '/taxappeal/:bblid/summary'
export const URL_SERVICETATRACKING: string = '/taxappeal/tracking'

export const URL_SERVICECONDOABATEMENTS: string = '/condoabat/buildings'
export const URL_PROPERTYLIST: string = '/property-list'
export const URL_ADMRPIETRACKING: string = '/rpie/tracking'
export const URL_ADMCLIENTWARNINGS: string =
  '/clientwarningstracking/:serviceTypeConst/:year?'

export const URL_ERROR404: string = '/404'
export const URL_ERROR503: string = '/503'
export const URL_TEST: string = '/test'
export const URL_ACCESS_DENIED = '/access-denied'

export const URL_API_LOGIN: string = '/API/auth/login'
