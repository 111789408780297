import { Masked, MaskedNumber } from 'imask'
import { isNumber } from 'lodash-es'

class NullableMaskedNumber extends MaskedNumber {
  // @ts-ignore
  get typedValue() {
    return this.unmaskedValue !== ''
      ? // @ts-ignore
      super.typedValue
      : null
  }
  // @ts-ignore
  set typedValue(num) {
    // @ts-ignore
    super.typedValue = num
  }

  // @ts-ignore
  get max() {
    return this.unmaskedValue !== ''
      ? // @ts-ignore
      super.max
      : undefined
  }

  // @ts-ignore
  set max(num) {
    // @ts-ignore
    super.max = num
  }

  // @ts-ignore
  get min() {
    return this.unmaskedValue !== ''
      ? // @ts-ignore
      super.min
      : undefined
  }

  // @ts-ignore
  set min(num) {
    // @ts-ignore
    super.min = num
  }

  format = (n) => {
    return isNumber(n) ? n.toLocaleString('en-US', {
      useGrouping: false,
      maximumFractionDigits: 20
    }) : ''
  }
}

export default NullableMaskedNumber
