const spacing = {
  space2: '2px',
  space4: '4px',
  space6: '6px',
  space8: '8px',
  space12: '12px',
  space16: '16px',
  space24: '24px',
  space32: '32px',
  space48: '48px',
  space72: '72px',
  space96: '96px',
  space120: '120px',
}

export default spacing
