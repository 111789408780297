import { getDictiName, RPIEStatuses } from 'constants/dicti'
import { Application } from 'hooks/api/firebase/useApplication'
import { UseServiceStatus } from '../ta/useTaStatus'

export enum PropertyListAction {
  NONE = 'none',
  RESET = 'reset',
  START = 'start',
  CONTINUE = 'continue',
  VIEW = 'view',
}

export enum PropertyListStatusType {
  IN_PROGRESS = 'inprogress',
  SUBMITTED = 'submitted',
  NEW = 'new',
  COMPLETE = 'complete',
  NO_SUBMISSSION = 'nosubmission',
  TC101_READY = 'tc101ready',
  TC101_IMPORTED = 'tc101imported',
  IMPORTED = 'imported',
  FILED = 'filed',
}

type StatusTypeMap = {
  [status: string]: PropertyListStatusType
}

const statusTypeMap: StatusTypeMap = {
  null: PropertyListStatusType.NEW,
  [RPIEStatuses.NotStarted]: PropertyListStatusType.NEW,
  [RPIEStatuses.InProgress]: PropertyListStatusType.IN_PROGRESS,
  [RPIEStatuses.Imported]: PropertyListStatusType.IMPORTED,
  [RPIEStatuses.NoSubmission]: PropertyListStatusType.NO_SUBMISSSION,
  [RPIEStatuses.Submitted]: PropertyListStatusType.SUBMITTED,
  [RPIEStatuses.DataUpdateRequested]: PropertyListStatusType.SUBMITTED,
  [RPIEStatuses.SubmittedPendingDocumentReview]:
    PropertyListStatusType.SUBMITTED,
  [RPIEStatuses.RPIEFiled]: PropertyListStatusType.FILED,
}

const typeActionMap = {
  [PropertyListStatusType.IN_PROGRESS]: PropertyListAction.CONTINUE,
  [PropertyListStatusType.COMPLETE]: PropertyListAction.NONE,
  [PropertyListStatusType.FILED]: PropertyListAction.NONE,
  [PropertyListStatusType.NEW]: PropertyListAction.START,
  [PropertyListStatusType.NO_SUBMISSSION]: PropertyListAction.NONE,
  [PropertyListStatusType.SUBMITTED]: PropertyListAction.VIEW,
  [PropertyListStatusType.IMPORTED]: PropertyListAction.VIEW,
}

const useRpieStatus: UseServiceStatus = () => {
  const initialStatus = RPIEStatuses.NotStarted

  const getStatusType = (
    application: Application<any>
  ): PropertyListStatusType => {
    const status = application.statusCS || initialStatus
    return status
      ? statusTypeMap[status]
      : PropertyListStatusType.NO_SUBMISSSION
  }

  const getStatusAction = (
    application: Application<any>
  ): PropertyListAction => {
    return typeActionMap[getStatusType(application)] || PropertyListAction.NONE
  }

  return { getStatusAction, getStatusType }
}

export default useRpieStatus
