import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { getFiscalPeriods } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'

//===================================================
type HelpDescriptionConstructionStepHelpProps = {} & BaseComponentProps

/**
 * HelpDescriptionConstructionStepHelp component
 */
const HelpDescriptionConstructionStepHelp = (
  props: HelpDescriptionConstructionStepHelpProps
) => {
  const { context } = useServiceWizard(),
    { prevReportingYear } = getFiscalPeriods(context)
  return (
    <p>
      If, after January 5, {prevReportingYear}, there has been any construction,
      demolition or major alteration work or plan for any construction,
      demolition, major alteration or a new building have been filed with the
      Department of Buildings, provide construction information.
    </p>
  )
}

export default HelpDescriptionConstructionStepHelp
