import React from 'react'
import { ChakraProps, Text } from '@chakra-ui/react'
import BaseComponentProps from 'common/BaseComponentProps'

type TextFromParamsProps = {
  params?: ChakraProps
} & BaseComponentProps

const TextFromParams = ({ params = {}, children }: TextFromParamsProps) => {

  return (
    <Text
      textStyle="body.regular"
      color="fontnavy"
      w="100%"
      textAlign="center"
      {...params}
    >
      {children}
    </Text>
  )
}

export default TextFromParams
