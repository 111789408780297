import React from 'react'
import {
  useMultiStyleConfig,
  Image,
  Flex,
  Box,
  Divider,
  chakra,
} from '@chakra-ui/react'
import Side from './assets/side.png'
import { ChakraComponentProps } from 'ui-framework/common/types'
import { RPIEOnboardingSteps } from './RPIEOnboardingSteps'
import { AnyFunction } from 'helpers/utils'
import Button from 'ui-framework/components/primitives/Button'
import { useAppConfig } from 'hooks/useApplicationContext'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

type RPIEOnboardingBodyProps = {
  onCloseHandle?: AnyFunction
} & ChakraComponentProps

const RPIEOnboardingBody = ({
  onCloseHandle,
  ...props
}: RPIEOnboardingBodyProps) => {
  const appconf = useAppConfig()
  const style = useMultiStyleConfig('MGNYRPIEOnboardingBody', props)
  const side = <Image src={Side} w={`200px`} />,
    steps = RPIEOnboardingSteps.map((step, i) => (
      <Box key={i}>
        <Flex sx={style.contentWrapper}>
          <Box maxW={`295px`}>
            Step {i + 1}. {step.title}
          </Box>
          <Image src={step.imageSrc} boxSize={`72px`} />
        </Flex>
        <Divider />
      </Box>
    )),
    contacts = (
      <Box sx={style.contactsWrapper}>
        Need Help? Reach us by email at{" "}
        <chakra.a href="mailto:rpie@mgnyconsulting.com">
          rpie@mgnyconsulting.com
        </chakra.a>
        , or by phone at <b>{SUPPORT_CONTACTS.supportTel}</b>.
      </Box>
    )

  return (
    <Flex>
      {side}
      <Box sx={style.contentBox}>
        <Box sx={style.title}>Welcome to the MGNY RPIE Portal!</Box>
        <Box sx={style.subtitle}>
          In just a few simple steps you can complete your{' '}
          {appconf.fiscalYear}
          <br />
          filing requirement:
        </Box>
        {steps}
        {contacts}
        <Button onClick={onCloseHandle} w={`100%`}>
          Go to RPIE Filing
        </Button>
      </Box>
    </Flex>
  )
}

export default chakra(RPIEOnboardingBody)
