import React from 'react'
import ErrorPage from 'pages/Errors/ErrorPage'
import PageNotFoundImage from './PageNotFoundImage.png'
import { URL_HOME } from 'constants/URLS'
import { useNavigate } from 'react-router-dom'

/**
 * NotFoundPage component
 */
const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <ErrorPage
      image={PageNotFoundImage}
      title="404 Page Not Found"
      description="We couldn't find the page you are looking for, please contact us if you have any questions."
      fallback={() => navigate(URL_HOME)}
      fallbackText="Go Home"
    />
  )
}

export default NotFoundPage
