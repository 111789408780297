import React from 'react'
import { Link as L, useMultiStyleConfig } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { ChakraComponentProps, OnClickHandler } from 'ui-framework/common/types'
import { omit } from 'lodash-es'

type LinkqProps = {
  to: string
  decorate?: boolean
  disabled?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  end?: boolean
  variant?: string
  onClick?: OnClickHandler
} & Omit<ChakraComponentProps, 'size'>

const Link = (props: LinkqProps) => {
  const style = useMultiStyleConfig('MGNYLink', props)

  return (
    <L tabIndex={0} as={NavLink} sx={style.link} {...omit(props, 'decorate')}>
      {props.children}
    </L>
  )
}

export default Link
