import { useCallback } from 'react'
import { HStack, VStack } from '@chakra-ui/react'

import Checkbox from 'ui-framework/components/primitives/Checkbox'
import { getCallback, getNameAndErrorProps } from 'helpers/utils'
import { Utilities } from 'constants/dicti'
import Input from 'ui-framework/components/primitives/Input'
import { isNullOrWhiteSpace } from 'helpers/utils'
import { getFloorsArray } from 'helpers/business'
import { integerPositiveThousands } from 'helpers/formats'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import {
  rowGroups,
  setUtility,
  getUtilityState,
  RentRollResidentialUnitEx,
} from './StepRentRollResidentialTable'

const floors = getFloorsArray(20),
  getTitle = unit =>
    Object.keys(rowGroups).reduce(
      (acc, item) =>
        unit[item]
          ? (acc += ` ${unit.index + 1} - ${rowGroups[item].title}`)
          : acc,
      'Editing Unit'
    )

const useRentRollResContent = disabled => {
  const handleSetUtility = useCallback(
    (handleChange, unit) => (_, name) =>
      getCallback(handleChange)(setUtility(unit, name)),
    []
  )

  return (unit: RentRollResidentialUnitEx, getError, handleChange) => {
    const nameAndErrorProps = name => getNameAndErrorProps(name, getError)

    return {
      title: unit && getTitle(unit),
      body: unit && (
        <VStack spacing={'36px'} alignItems={'flex-start'}>
          <HStack w={'100%'} spacing={'24px'}>
            <Input
              label="Apt #"
              width="132px"
              errorTooltip
              value={unit.unitApartmentNumber}
              placeholder="###"
              disabled={disabled}
              onChange={handleChange}
              {...nameAndErrorProps('unitApartmentNumber')}
            />
            <Input
              label="Bedrooms"
              width="132px"
              errorTooltip
              value={unit.bedroomsCount}
              type="number"
              placeholder="##"
              disabled={disabled}
              onChange={handleChange}
              {...nameAndErrorProps('bedroomsCount')}
            />
            <Input
              label="Vacant Months"
              width="132px"
              errorTooltip
              value={unit.vacantMonths}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              onChange={handleChange}
              {...nameAndErrorProps('vacantMonths')}
            />
          </HStack>
          <DropdownList
            label="Floor"
            width="100%"
            value={unit.floorNumber}
            listWidth="444px"
            disabled={disabled}
            errorTooltip
            items={floors}
            listMaxHeight="230px"
            onChange={handleChange}
            {...nameAndErrorProps('floorNumber')}
          />
          <HStack w={`100%`} spacing={'24px'}>
            <Input
              label="Monthly Rent"
              width="210px"
              errorTooltip
              value={unit.monthlyRentAmount}
              type="number"
              format={integerPositiveThousands}
              prefix="$"
              disabled={
                disabled ||
                (!isNullOrWhiteSpace(unit.currentlyTenantOccupied) &&
                  !unit.currentlyTenantOccupied)
              }
              onChange={handleChange}
              {...nameAndErrorProps('monthlyRentAmount')}
            />
            <Input
              label="Net Rentable Area"
              width="210px"
              errorTooltip
              value={unit.rentableNetAreaSF}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              onChange={handleChange}
              {...nameAndErrorProps('rentableNetAreaSF')}
            />
          </HStack>
          <Checkbox
            checked={!!unit.isRegulated}
            disabled={disabled}
            name={'isRegulated'}
            onChange={handleChange}
          >
            Rent Regulated?
          </Checkbox>
          <VStack spacing={'16px'} w={'100%'} alignItems={'flex-start'}>
            <span>Utilities Paid by Tenant:</span>
            <HStack w={`100%`} spacing={`24px`}>
              <Checkbox
                checked={getUtilityState(unit, Utilities.Water)}
                disabled={disabled}
                name={Utilities.Water}
                onChange={handleSetUtility(handleChange, unit)}
              >
                Water
              </Checkbox>
              <Checkbox
                checked={getUtilityState(unit, Utilities.Heat)}
                disabled={disabled}
                name={Utilities.Heat}
                onChange={handleSetUtility(handleChange, unit)}
              >
                Heat
              </Checkbox>
              <Checkbox
                checked={getUtilityState(unit, Utilities.Electricity)}
                disabled={disabled}
                name={Utilities.Electricity}
                onChange={handleSetUtility(handleChange, unit)}
              >
                Electricity
              </Checkbox>
            </HStack>
          </VStack>
        </VStack>
      ),
    }
  }
}

export default useRentRollResContent
