import { ComponentStyleConfig } from '@chakra-ui/theme'

const ShifterStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'contentWrapper'],
  // Styles for the base style
  baseStyle: props => ({
    wrapper: {
      alignItems: 'center',
      justifyContent: 'space-between',
      textStyle: 'body.semibold',
      color: 'fontnavy',
      '.icon': {
        svg: {
          fill: 'fontandicongray',
        },
      },
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      wrapper: {},
      contentWrapper: {},
    }),
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default ShifterStyle
