import { ComponentStyleConfig } from '@chakra-ui/theme'

const IntervalItemStyle: ComponentStyleConfig = {
  parts: [
    'wrapper',
    'boxDivider',
    'boxLabel',
    'boxDefault',
    'boxInterval',
    'boxTitle',
    'boxEmpty',
  ],
  // Styles for the base style
  baseStyle: {
    wrapper: {
      position: 'relative',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      '.interval:first-of-type': {
        borderRadius: '6px 0 0 6px',
      },
      '.interval:last-of-type': {
        borderRadius: '0 6px 6px 0',
      },
      // '.intervalDivider:last-of-type': {
      //   backgroundColor: 'transparent'
      // },
    },
    boxDivider: {
      backgroundColor: 'secondary.divider',
    },
    boxLabel: {
      color: 'fontnavy',
    },
    boxEmpty: {
      backgroundColor: 'secondary.inputGray',
      position: 'absolute',
    },
    boxDefault: {
      backgroundColor: 'orange.base',
    },
    boxInterval: {
      backgroundColor: 'primary.base',
      width: '100%',
      height: '6px',
    },
    boxTitle: {
      textStyle: 'body.regular',
      color: 'fontandicongray',
    },
  },
  // Styles for the size variations
  sizes: {
    md: {
      wrapper: {},
      boxDivider: {
        minWidth: '3px',
        height: '26px',
        position: 'absolute',
        right: '0',
      },
      boxLabel: {
        height: '6px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        top: '-30px',
        textStyle: 'body.regular',
      },
      boxEmpty: {
        borderRadius: '6px',
        height: '6px',
        width: '100%',
      },
      boxDefault: {
        width: '20px',
        borderRadius: '6px',
        height: '6px',
      },
    },
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default IntervalItemStyle
