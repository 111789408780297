import React from 'react'
import { Center } from '@chakra-ui/react'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import Button from 'ui-framework/components/primitives/Button'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

/**
 * NoPermissionPage component
 */
const NoPermissionPage = props => {
  return (
    <Center h={`100vh`}>
      <div>
        <TextFromParams
          params={{
            lineHeight: '24px',
            fontSize: '22px',
            textStyle: 'body.semibold',
          }}
        >
          No access
        </TextFromParams>
        <TextFromParams params={{ lineHeight: '25px', m: '4px 0 24px' }}>
          You do not have permission to view this page. <br /> Contact us if you
          have any questions.
        </TextFromParams>
        <Button
          variant={'secondary'}
          width={'129px'}
          margin={'0 auto'}
          to={`mailto:${SUPPORT_CONTACTS.contactUsEmail}`}
        >
          Contact Us
        </Button>
      </div>
    </Center>
  )
}

export default NoPermissionPage
