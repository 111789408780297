import { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { ExpenseSection, TaFormData } from 'hooks/api/ta/TaFormData'
import { isDivisibleBy } from 'helpers/utils'
import { ExpenseSectionTypes } from 'constants/dicti'
import Prompt from 'ui-framework/components/primitives/Prompt'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import SectionsTable, {
  emptyForManually,
} from 'ui-framework/components/patterns/Wizard/SectionsTable'
import { categoriesEditTypeCS } from 'ui-framework/components/patterns/Wizard/SectionsTable/useSections'

//====================================================
const MAX_OTHER_ITEMS = 8

type Props = {
  sections: ExpenseSection[]
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyDetailsExpense component
 */
const StepPropertyDetailsExpense = (props: Props) => {
  const { sections, applyValidation, onValidation, disabled } = props,
    lastYearPrompt = item =>
      item.lastYearAmount &&
      isDivisibleBy(item.lastYearAmount, 100) && (
        <Prompt
          iconParams={{ className: 'warning' }}
          label={"Tax Commission doesn't accept rounded numbers!"}
          type={`warning`}
        />
      ),
    manuallyPrompt = item => {
      const hasForbiddenWords = value =>
        ['interest', 'depreciation', 'mortgage', 'lease', 'rent'].includes(
          value?.toLowerCase()
        )
      return (
        hasForbiddenWords(item.categoryName) && (
          <Prompt
            iconParams={{ className: 'warning' }}
            label={'Tax Commission might not accept this type of expense!'}
            type={`warning`}
          />
        )
      )
    },
    validationData = useMemo(() => ({ sections }), [sections]),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    items = useMemo(
      () => [
        {
          type: ExpenseSectionTypes.ExpenseMain,
          title: 'Main',
          params: { lastYearPrompt },
        },
        {
          type: ExpenseSectionTypes.CondoMain,
          title: 'Main',
          params: { lastYearPrompt },
        },
        {
          type: ExpenseSectionTypes.HotelDepartmental,
          title: 'Departmental',
          params: { lastYearPrompt },
        },
        {
          type: ExpenseSectionTypes.HotelFinancial,
          title: 'Fixed',
          params: { lastYearPrompt },
        },
        {
          type: ExpenseSectionTypes.HotelUndistributed,
          title: 'Operating',
          params: { lastYearPrompt },
        },
        {
          type: [
            ExpenseSectionTypes.HotelOther,
            ExpenseSectionTypes.CondoOther,
            ExpenseSectionTypes.ExpenseOther,
          ],
          title: 'Other',
          params: {
            categoriesEditType: categoriesEditTypeCS.manually,
            lastYearPrompt,
            manuallyPrompt,
            newLineOptions: {
              empty: emptyForManually,
              getDisabled: originalSectionItemsCount =>
                disabled || originalSectionItemsCount >= MAX_OTHER_ITEMS,
            },
          },
        },
      ],
      [disabled]
    )

  return (
    <SectionsTable
      {...props}
      sections={sections}
      items={items}
      hasError={hasError}
      modalContent={`Caution! You are removing an expense source previously added. Confirm that it needs to be removed.`}
      totalFooterLabel={`Total Expense`}
      tableParams={{
        isSticky: true,
      }}
    />
  )
}

export default StepPropertyDetailsExpense
