import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import ServiceWizard from 'ui-framework/components/patterns/Wizard/ServiceWizard'
import { URL_SERVICERPIEFORMSUMMARY, URL_SERVICERPIELIST } from 'constants/URLS'
import rpieWizardConfig from './wizard/rpieWizardConfig'
import useRpieFormDataHook from 'services/rpie/useRpieFormDataHook'
import { RpieFormData } from 'hooks/api/rpie/RpieFormData'
import useApplication from 'hooks/api/firebase/useApplication'
import { CustomWizardAction } from 'hooks/wizard/useStepsConfig'
import { CUSTOM_WIZARD_ACTION_RPIE_FULL_SUBMIT } from './wizard/rpieWizardConfig'
import { RPIEStatuses } from 'constants/dicti'
import { useRedirectIfThisApplicationStatuses } from 'pages/Services/hooks/useRedirectSubmittedApplication'
import { useNavigate, useParams } from 'react-router-dom'

//===================================================
type TAWizardProps = {} & BaseComponentProps

/**
 * RpieWizard component
 */
const RpieWizard = (props: TAWizardProps) => {
  const { bblid = '' } = useParams(),
    navigate = useNavigate(),
    application = useApplication<RpieFormData>(bblid, RPIEStatuses.InProgress),
    isFinished = [RPIEStatuses.Imported, RPIEStatuses.Submitted].includes(
      application.remoteData?.statusCS as any
    ),
    onFullSubmit = () => {
      application.statusSet(RPIEStatuses.Submitted)
      return Promise.resolve()
    }

  const handleCustomAction = (
    customActionParams: CustomWizardAction
  ): Promise<boolean> =>
    new Promise((resolve, reject) => {
      switch (customActionParams.action) {
        case CUSTOM_WIZARD_ACTION_RPIE_FULL_SUBMIT:
          onFullSubmit()
          // navigate(URL_SERVICERPIELIST)
          return resolve(false)
        default:
          return resolve(true)
      }
    })

  useRedirectIfThisApplicationStatuses(
    application,
    [RPIEStatuses.Submitted, RPIEStatuses.Imported],
    URL_SERVICERPIEFORMSUMMARY
  )

  return (
    <>
      <ServiceWizard<RpieFormData>
        className={props.className}
        bblid={bblid}
        propertyListUrl={URL_SERVICERPIELIST}
        config={rpieWizardConfig}
        application={application}
        isFinished={isFinished}
        useServiceFormDataHook={useRpieFormDataHook}
        onCustomAction={handleCustomAction}
      />
    </>
  )
}

export default RpieWizard
