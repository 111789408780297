import React, { ReactNode } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { chakra } from '@chakra-ui/react'

const PopoverWrapper = chakra('div', {
  baseStyle: {
    width: 400,
    padding: '16px',
    backgroundColor: 'secondary.sideMenu',
    borderRadius: '12px',
    boxShadow: '0px 4px 8px rgba(4, 7, 31, 0.151235)',
    position: 'relative',
  },
})

const PopoverHeader = chakra('div', {
  baseStyle: {
    textStyle: 'h6',
    marginBottom: '12px',
  },
})
const PopoverContent = chakra('div', {
  baseStyle: {
    p: {
      marginBottom: '12px',
    },
  },
})
const PopoverFooter = chakra('div', {
  baseStyle: {},
})
const PopoverArrow = chakra('div', {
  baseStyle: {
    transform: 'rotate(45deg)',
    // boxShadow: '0px 4px 8px rgba(4, 7, 31, 0.151235)',
    position: 'absolute',
    right: '-8px',
    backgroundColor: 'secondary.sideMenu',
    width: '26px',
    height: '26px',
  },
})

//===================================================
type PopoverProps = {
  title: string
  footer: ReactNode
} & BaseComponentProps

/**
 * Popover component
 */
const Popover = ({ title, children, footer, ...props }: PopoverProps) => {
  return (
    <PopoverWrapper className={props.className}>
      <PopoverArrow />
      <PopoverHeader>{title}</PopoverHeader>
      <PopoverContent>{children}</PopoverContent>
      <PopoverFooter>{footer}</PopoverFooter>
    </PopoverWrapper>
  )
}

export default Popover
