import React, { useCallback, useState } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Input from 'ui-framework/components/primitives/Input'
import Button from 'ui-framework/components/primitives/Button'
import testFunction from 'pages/Other/Handling/functions/testFunction'
import { Box, HStack, VStack } from '@chakra-ui/react'

//===================================================
type HandlingProps = {} & BaseComponentProps

/**
 * Handling component
 */
const Handling = (props: HandlingProps) => {
  const [task, taskSet] = useState<string>(),
    [result, resultSet] = useState<string>(),
    handleTask = useCallback(async () => {
      switch (task) {
        case 'testFunction':
          resultSet(String(await testFunction()))
          return
        default:
          return
      }
    }, [task])

  return (
    <VStack className={props.className} p="2em" width="50%" align="flex-start">
      <HStack width="100%">
        <Input autocomplete="off" width="100%" onChange={taskSet} />
        <Button onClick={handleTask}>Do</Button>
      </HStack>
      <Box p="1em" bgColor="aqua">
        {result}
      </Box>
    </VStack>
  )
}

export default Handling
