import React, { useState, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import SimpleLayoutCentered from 'ui-framework/components/layout/SimpleLayout/SimpleLayoutCentered'
import Logo from 'ui-framework/components/layout/MainLayout/Logo'
import PasswordResetForm from './PasswordResetForm'
import firebaseApp from 'helpers/firebaseInit'
import EmailSentNotification from './EmailSentNotification'
import useQueryParamState from 'hooks/useQueryParamState'
import PasswordResetConfirmForm from './PasswordResetConfirmForm'
import PasswordChangedNotification from './PasswordChangedNotification'
import createPersistedState from 'use-persisted-state'
import dayjs, { unix } from 'dayjs'
import { FunctionNames } from 'constants/dicti'
import { AuthErrors } from './schema'
import { log } from 'helpers/utils'

//===================================================
const useResetDisabled = createPersistedState('r_disabled_until')
const useResetCount = createPersistedState('r_count')

type PasswordResetPageProps = {} & BaseComponentProps

/**
 * PasswordResetPage component
 */
const PasswordResetPage = (props: PasswordResetPageProps) => {
  const [mode] = useQueryParamState('mode'),
    [code] = useQueryParamState('oobCode'),
    [emailSent, emailSentSet] = useState<string>(''),
    [changed, changedSet] = useState<boolean>(false),
    [codeWasExpired, codeWasExpiredSet] = useState<boolean>(),
    [tryes, tryesSet] = useResetCount<number>(0),
    [resetDisabledUntil, resetDisabledUntilSet] = useResetDisabled<string>(),
    handleReset = ({ email }) => {
      if (email)
        firebaseApp
          .auth()
          .sendPasswordResetEmail(email)
          .catch(error => {
            error.code === AuthErrors.UserNotFound &&
              firebaseApp
                .functions()
                .httpsCallable(FunctionNames.RESET_PASSWORD_USER_NOT_FOUND)({
                  email,
                })
          })
          .finally(() => {
            emailSentSet(email)
            tryesSet(tryes + 1)
            firebaseApp
              .functions()
              .httpsCallable(FunctionNames.SEND_NOTIFICATION_FOR_ADMIN)({
                email,
              })
          })
    },
    handlePasswordChange = ({ password }) => {
      if (password && code)
        firebaseApp
          .auth()
          .confirmPasswordReset(code, password)
          .finally(() => changedSet(true))
    }

  useEffect(() => {
    if (code)
      firebaseApp
        .auth()
        .checkActionCode(code)
        .then(data => codeWasExpiredSet(false))
        .catch(() => codeWasExpiredSet(true))
    else codeWasExpiredSet(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tryes >= 5) {
      resetDisabledUntilSet(dayjs().add(10, 'minutes').unix())
      tryesSet(0)
    }
  }, [tryes, resetDisabledUntilSet, tryesSet])
  const resetDisabled = useMemo(() => {
    return resetDisabledUntil && unix(resetDisabledUntil).isAfter(dayjs())
  }, [resetDisabledUntil])

  return (
    <SimpleLayoutCentered className={props.className}>
      <Logo width={'88px'} height={'80px'} margin={'36px auto'} />
      {mode === 'resetPassword' && !!code ? (
        changed ? (
          <PasswordChangedNotification />
        ) : (
          <PasswordResetConfirmForm onReset={handlePasswordChange} />
        )
      ) : !!emailSent ? (
        <EmailSentNotification email={emailSent} />
      ) : codeWasExpired === undefined ? (
        <></>
      ) : (
        <PasswordResetForm
          blocked={resetDisabled && resetDisabledUntil}
          onReset={handleReset}
          retry={codeWasExpired}
        />
      )}
    </SimpleLayoutCentered>
  )
}

export default PasswordResetPage
