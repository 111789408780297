import React, { useCallback, useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { integerPositiveThousands } from 'helpers/formats'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { HStack, Text, VStack } from '@chakra-ui/react'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import { PortionUsedByApplicant, UseByApplicant } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { getNameAndErrorProps } from 'helpers/utils'

//===================================================
type Props = {
  checkTotalNumberOfResidUnits: number
  numberOfResidentialUnit: number
  numberOfRegulatedUnits: number
  numberOfUnRegulatedUnits: number
  useByApplicantCS: UseByApplicant
  portionUsedByApplicantCS: PortionUsedByApplicant
  ownerOccupied: number
  location: number
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyMainResidentialInfo component
 */
const StepPropertyMainResidentialInfo = ({
  checkTotalNumberOfResidUnits,
  numberOfResidentialUnit,
  numberOfRegulatedUnits,
  numberOfUnRegulatedUnits,
  useByApplicantCS,
  portionUsedByApplicantCS,
  ownerOccupied,
  location,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const isOwnerOccupiedShown = useMemo(
    () =>
      useByApplicantCS === UseByApplicant.Part &&
      portionUsedByApplicantCS !== PortionUsedByApplicant.NonResidential,
    [portionUsedByApplicantCS, useByApplicantCS]
  ),
    validationData = useMemo(
      () => ({
        numberOfResidentialUnit,
        numberOfRegulatedUnits,
        numberOfUnRegulatedUnits,
        ownerOccupied,
        checkTotalNumberOfResidUnits,
        location,
        isOwnerOccupiedShown,
      }),
      [
        numberOfResidentialUnit,
        numberOfRegulatedUnits,
        numberOfUnRegulatedUnits,
        ownerOccupied,
        checkTotalNumberOfResidUnits,
        location,
        isOwnerOccupiedShown,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    hasFieldError = path =>
      hasError(path) || hasError('checkTotalNumberOfResidUnits'),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasFieldError)

  // const parkingChangeHandler = useCallback(
  //   (value, name) => getCallback(onChange)(set({ parking }, name, value)),
  //   [onChange, parking]
  // )

  return (
    <StepBasic className={props.className}>
      <VStack width="560px" spacing="36px" align="flex-start">
        <ReviewLastYearDataWarningBlock />

        <Text>
          Provide the breakdown of <b>{numberOfResidentialUnit} apartments</b>{' '}
          by type:
        </Text>

        <HelpTarget name="numberOfRegulatedUnits">
          <Input
            width="200px"
            label="Regulated Units"
            placeholder="Enter #"
            value={numberOfRegulatedUnits}
            type="number"
            format={integerPositiveThousands}
            disabled={disabled}
            errorTooltip
            onChange={onChange}
            {...nameAndErrorProps('numberOfRegulatedUnits')}
          />
        </HelpTarget>

        <HelpTarget name="numberOfUnRegulatedUnits">
          <Input
            width="200px"
            label="Unregulated Units"
            placeholder="Enter #"
            value={numberOfUnRegulatedUnits}
            type="number"
            // format={integerPositiveThousands}
            disabled={disabled}
            errorTooltip
            onChange={onChange}
            {...nameAndErrorProps('numberOfUnRegulatedUnits')}
          />
        </HelpTarget>

        {isOwnerOccupiedShown && (
          <HStack spacing="76px" align="flex-start">
            <HelpTarget name="ownerOccupied">
              <Input
                width="200px"
                label="Owner - Occupied Units"
                placeholder="Enter #"
                value={ownerOccupied}
                type="number"
                format={integerPositiveThousands}
                disabled={disabled}
                errorTooltip
                onChange={onChange}
                {...nameAndErrorProps('ownerOccupied')}
              />
            </HelpTarget>

            <HelpTarget name="location">
              <Input
                width="400px"
                label="Location"
                placeholder="Example: Basement"
                value={location}
                type="text"
                disabled={disabled}
                onChange={onChange}
                {...getNameAndErrorProps('location', hasError)}
              />
            </HelpTarget>
          </HStack>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepPropertyMainResidentialInfo
