import { get } from 'object-path-immutable'

const AtLeastOne = (arr, fn, helpers) => {
  const res = arr.some(fn)
  if (!res) return helpers.messages('any.custom')
}

const checkAtLeastOne = (path, fieldName) => (value, helpers) =>
  AtLeastOne(
    get(helpers.prefs.context?.originalValue, path),
    u => u[fieldName] === true,
    helpers
  )

const atLeastOneGreaterZero = (path, fieldName) => (value, helpers) =>
  AtLeastOne(
    get(helpers.prefs.context?.originalValue, path),
    u => u[fieldName] > 0,
    helpers
  )

export { checkAtLeastOne, atLeastOneGreaterZero }
