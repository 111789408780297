import React, { useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import SummaryBlock from 'ui-framework/components/patterns/SummaryBlock'
import { integerPositiveThousands } from 'helpers/formats'
import { getNameAndErrorProps } from 'helpers/utils'

import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { VStack, HStack } from '@chakra-ui/react'
import Message from 'ui-framework/components/primitives/Message'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import { formatToCurrency } from 'helpers/formatter'

//==================================================

type StepPropertyDetailsConstructionCostsProps = {
  contractCost: string
  financingCost: string
  professionalFee: string
  otherCost: string
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyDetailsConstructionCosts component
 */
const StepPropertyDetailsConstructionCosts = ({
  contractCost,
  financingCost,
  professionalFee,
  otherCost,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: StepPropertyDetailsConstructionCostsProps) => {
  const validationData = useMemo(
      () => ({
        costs: {
          contractCost,
          financingCost,
          professionalFee,
          otherCost,
        },
      }),
      [contractCost, financingCost, otherCost, professionalFee]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError, 'costs')

  return (
    <StepBasic className={props.className}>
      <VStack width="560px" spacing="36px" align="left">
        <ReviewLastYearDataWarningBlock />

        <HStack spacing={'36px'} align="left">
          <HelpTarget name="contractCost">
            <Input
              width="240px"
              label="Contract Costs"
              value={contractCost}
              type="number"
              format={integerPositiveThousands}
              prefix="$"
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('contractCost')}
            />
          </HelpTarget>
          <HelpTarget name="financingCost">
            <Input
              width="240px"
              label="Financing Costs"
              value={financingCost}
              type="number"
              format={integerPositiveThousands}
              prefix="$"
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('financingCost')}
            />
          </HelpTarget>
        </HStack>

        <HStack spacing={'36px'} align="left">
          <HelpTarget name="professionalFee">
            <Input
              width="240px"
              label="Professional Fees"
              value={professionalFee}
              type="number"
              format={integerPositiveThousands}
              prefix="$"
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('professionalFee')}
            />
          </HelpTarget>
          <HelpTarget name="otherCost">
            <Input
              width="240px"
              label="Other Costs"
              value={otherCost}
              type="number"
              format={integerPositiveThousands}
              prefix="$"
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('otherCost')}
            />
          </HelpTarget>
        </HStack>

        <SummaryBlock
          mt={'36px'}
          title="Total Costs"
          value={formatToCurrency(
            contractCost + financingCost + professionalFee + otherCost
          )}
        >
          {Array.isArray(hasError('costs')) && (
            <Message error={hasError('costs')} width="auto">
              {hasError('costs')}
            </Message>
          )}
        </SummaryBlock>
      </VStack>
    </StepBasic>
  )
}

export default StepPropertyDetailsConstructionCosts
