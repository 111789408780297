import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'
import { BusinessActivity } from 'constants/dicti'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

const schema = schemaWrapper({
  canOmitContactInformation: Joi.boolean(),
  isContactInformationUnavailable: Joi.when('canOmitContactInformation', {
    is: true,
    then: Joi.boolean(),
    otherwise: schemaRules.ANY,
  }),
  contactName: Joi.when('isContactInformationUnavailable', {
    is: true,
    then: schemaRules.ANY,
    otherwise: schemaRules
      .MIN_STRING(3)
      .trim()
      .allow(null)
      .max(100)
      // eslint-disable-next-line no-useless-escape
      .pattern(/^[a-zA-z.\s\'-]+$/)
      .rule({ message: 'Must be a valid contact name.' }),
  }),
  emailAddress: Joi.when('isContactInformationUnavailable', {
    is: true,
    then: schemaRules.ANY,
    otherwise: schemaRules.NOT_EMPTY_STRING.email({ tlds: false }),
  }),
  primaryPhone: Joi.when('isContactInformationUnavailable', {
    is: true,
    then: schemaRules.ANY,
    otherwise: schemaRules
      .MIN_STRING(10)
      .trim()
      .allow(null)
      .max(10)
      .rule({ message: `Must be a valid phone number. Ex. ${SUPPORT_CONTACTS.supportTel}` }),
  }),
  alternativePhone: Joi.when('isContactInformationUnavailable', {
    is: true,
    then: schemaRules.ANY,
    otherwise: schemaRules.STRING.empty(null)
      .trim()
      .allow(null, '')
      .$.min(10)
      .max(10)
      .rule({ message: `Must be a valid phone number. Ex. ${SUPPORT_CONTACTS.supportTel}` }),
  }),
  primaryBusinessActivityCS: schemaRules.NOT_EMPTY_STRING,
  otherDescriptionOfBA: Joi.when('primaryBusinessActivityCS', {
    switch: [
      {
        is: BusinessActivity.Other,
        then: schemaRules.NOT_EMPTY_STRING.empty(null)
          .$.max(60)
          .pattern(/^[\D]+$/)
          .rule({
            message: 'maximum 60 characters, no digits',
          }),
      },
      {
        is: BusinessActivity.MiscellaneousOtherService,
        then: schemaRules.NOT_EMPTY_STRING.$.max(60)
          .pattern(/^[\D]+$/)
          .rule({
            message:
              'Must be a valid description of business activity (max 60 length, no digits)',
          }),
      },
    ],
    otherwise: schemaRules.ANY,
  }),
  businessName: schemaRules.NOT_EMPTY_STRING,
})

export default schema
