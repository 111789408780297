const colors = {
  primary100: '#3F597F',
  primary90: '#637997',
  primary80: '#657A99',
  primary70: '#798BA5',
  primary60: '#8C9BB2',
  primary50: '#9FACBF',
  primary40: '#B2BDCC',
  primary30: '#C5CDD9',
  primary20: '#D9DEE5',
  primary10: '#ECEEF2',

  secondary100: '#FF8300',
  secondary90: '#FF8F1A',
  secondary80: '#FF9C33',
  secondary70: '#FFA84D',
  secondary60: '#FFB566',
  secondary50: '#FFC180',

  black100: '#000000',
  black90: '#1A1A1A',
  black80: '#333333',
  black70: '#4D4D4D',
  black60: '#666666',
  black50: '#808080',
  black40: '#999999',
  black30: '#B3B3B3',
  black20: '#CCCCCC',
  black10: '#E5E5E5',

  gray1: '#F2F2F2',
  gray2: '#FBFBFB',

  white100: '#FFFFFF',
  white50: '#FFFFFF80',

  error: '#EE6962',
  warning: '#FCC949',
  success: '#18854A',
}

export default colors
