import { ApiObject } from 'helpers/apiHelper'
import { useEffect, useState } from 'react'

// The hook prevents the data load hook from setting isPending to false
// before the data or error value
const usePendingWrapper = ([data, isPending, error]: [
  data: any,
  isPending: boolean,
  error: any
]) => {
  const [isPendingOut, isPendingOutSet] = useState(isPending)

  useEffect(() => {
    if (!isPending && (data || error)) {
      isPendingOutSet(isPending)
    } else if (isPending) isPendingOutSet(isPending)
  }, [data, error, isPending])

  return [data, isPendingOut, error]
}

const usePendingWrapperObject = ({
  data,
  isPending,
  error,
}: ApiObject): ApiObject => {
  const [d, p, e] = usePendingWrapper([data, isPending, error])

  return { data: d, isPending: p, error: e }
}

export default usePendingWrapper
export { usePendingWrapperObject }
