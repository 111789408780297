import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpResRRStepFillingTypeSelectionProps = {} & BaseComponentProps

/**
 * HelpResRRStepFillingTypeSelection component
 */
const HelpResRRStepFillingTypeSelection = (
  props: HelpResRRStepFillingTypeSelectionProps
) => {
  return (
    <>
      <p>
        Residential Rent Roll submission is required for all NYC properties with
        at least 1 apartment and an assessed value greater than $750,000.
      </p>
      <p>
        In this section, you will be asked to provide details about all
        apartments.
      </p>
    </>
  )
}

export default HelpResRRStepFillingTypeSelection
