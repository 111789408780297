import React, { useCallback, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import ServiceWizard from 'ui-framework/components/patterns/Wizard/ServiceWizard'
import { URL_SERVICETAFORMSUMMARY, URL_SERVICETALIST } from 'constants/URLS'
import useTaFormDataHook from 'services/ta/useTaFormDataHook'
import { Text } from '@chakra-ui/react'
import PringlsLine from 'ui-framework/components/primitives/PringlsLine'
import useApplication from 'hooks/api/firebase/useApplication'
import { TaFormData, TaServiceMetaData } from 'hooks/api/ta/TaFormData'
import { CustomWizardAction } from 'hooks/wizard/useStepsConfig'
import { useState } from 'react'
import { getCallback, log } from 'helpers/utils'
import { formatToCurrency } from 'helpers/formatter'
import { ActualAVType, TAStatuses } from 'constants/dicti'
import taWizardConfig, { TAWizardCustomAction } from './wizard/taWizardConfig'
import useTaPartialSubmitDialog from './wizard/useTaPartialSubmitDialog'
import useAfterSubmitRPIEDiscountDialog from './wizard/useAfterSubmitRPIEDiscountDialog'
import { useRedirectIfThisApplicationStatuses } from 'pages/Services/hooks/useRedirectSubmittedApplication'
import { useMainMenu } from 'hooks/ui/MainMenuProvider'
import { useNavigate, useParams } from 'react-router-dom'

//===================================================
type TAWizardProps = {} & BaseComponentProps

/**
 * TAWizard component
 */
const TAWizard = (props: TAWizardProps) => {
  const { bblid = '' } = useParams(),
    navigate = useNavigate(),
    menu = useMainMenu(),
    application = useApplication<TaFormData, TaServiceMetaData>(
      bblid,
      TAStatuses.InProgress
    ),
    isFinished = [TAStatuses.Imported, TAStatuses.Submit].includes(
      application.remoteData?.statusCS as any
    ),
    handleGoToPropertyList = useCallback(() => {
      navigate(URL_SERVICETALIST)
      menu.onOpen()
    }, [navigate, menu]),
    onPartialSubmit = useCallback(
      (redirect = false) => {
        log('onPartialSubmit')
        application.statusSet(TAStatuses.TC101Ready)
        application.dataSave()
        return Promise.resolve()//.then(() => {
        // redirect && handleGoToPropertyList()
        // })
      },
      [application, handleGoToPropertyList]
    ),
    [openDiscountDialog, afterSubmitRPIEDiscountDialog] =
      useAfterSubmitRPIEDiscountDialog(
        application?.data,
        handleGoToPropertyList
      ),
    onFullSubmit = useCallback(() => {
      application.statusSet(TAStatuses.Submit)
      openDiscountDialog()
      return Promise.resolve()
    }, [application, openDiscountDialog]),
    onBaseFlowChange = useCallback(
      data => {
        application.serviceMetadataSet(data)
        return application.dataSave()
      },
      [application]
    ),
    [onOpen, submitDialog] = useTaPartialSubmitDialog(handleGoToPropertyList),
    handleCustomAction = useCallback(
      (customActionParams: CustomWizardAction): Promise<boolean> => {
        const result = new Promise<boolean>((resolve, reject) => {
          log('handleCustomAction', customActionParams)
          switch (customActionParams?.action) {
            case TAWizardCustomAction.PARTIALLY_SUBMIT:
              log('on part sbmt')
              onPartialSubmit()
              onOpen()
              return resolve(false)
            case TAWizardCustomAction.FULL_SUBMIT:
              log('on full sbmt')
              onFullSubmit()
              return resolve(false)
            default:
              return resolve(true)
          }
        })
        return result
      },
      [onOpen, onFullSubmit, onBaseFlowChange]
    ),
    createTitle = (text: string) => (v: any) =>
    (
      <Text>
        {text} <b>{formatToCurrency(Math.round(v))}</b>
      </Text>
    ),
    avTitle = useMemo(() => {
      const assd = application.data?.bblsData.assesmentData
      if (!assd) return 'Actual Assessed Value'
      return assd?.actualAVTypeCS === ActualAVType.Tentative
        ? 'Tentative Actual Assessed Value'
        : `Final ${assd?.actualAVFiscalYear} Actual Assessed Value`
    }, [application.data?.bblsData.assesmentData]),
    depends = useMemo(
      () => [
        {
          title: 'Net Lease Property',
          field: 'serviceData.general.isPropertyToNetLease',
          notEqual: [false, null, undefined],
          chipVariant: 'green',
        },
        {
          title: createTitle(`${avTitle}:`),
          field: 'bblsData.assesmentData.tentativeActualAV',
          notEqual: [null, undefined, 0],
        },
        {
          title: createTitle('Projected Taxes (before abatements):'),
          field: 'bblsData.assesmentData.tentativeTaxesAmount',
          notEqual: [null, undefined, 0],
        },
      ],
      [avTitle]
    )

  useRedirectIfThisApplicationStatuses(
    application,
    [TAStatuses.Submit, TAStatuses.Imported],
    URL_SERVICETAFORMSUMMARY
  )

  return (
    <>
      <ServiceWizard<TaFormData>
        className={props.className}
        bblid={bblid}
        propertyListUrl={URL_SERVICETALIST}
        config={taWizardConfig}
        application={application}
        isFinished={isFinished}
        useServiceFormDataHook={useTaFormDataHook}
        propertyPringlsLine={
          <PringlsLine
            depends={depends}
            data={application?.data}
            p={`4px 54px 12px`}
          />
        }
        onCustomAction={handleCustomAction}
      />
      {submitDialog}
      {afterSubmitRPIEDiscountDialog}
    </>
  )
}

export default TAWizard
