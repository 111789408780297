import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpDescriptionStatusUseByApplicantHelpProps = {} & BaseComponentProps

/**
 * HelpDescriptionStatusUseByApplicantHelp component
 */
const HelpDescriptionStatusUseByApplicantHelp = (
  props: HelpDescriptionStatusUseByApplicantHelpProps
) => {
  return (
    <>
      <p>Related Party is any of the following:</p>
      <ul>
        <li>individual related to Applicant by blood, marriage or adoption;</li>
        <li>
          business entity controlled or owned by Applicant or by Applicant's
          principals (i.e. common ownership);
        </li>
        <li>fiduciaries or beneficiaries of Applicant.</li>
      </ul>
    </>
  )
}

export default HelpDescriptionStatusUseByApplicantHelp
