import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpDescriptionStatusWasAnyConstructionHelpProps = {} & BaseComponentProps

/**
 * HelpDescriptionStatusWasAnyConstructionHelp component
 */
const HelpDescriptionStatusWasAnyConstructionHelp = (
  props: HelpDescriptionStatusWasAnyConstructionHelpProps
) => {
  return (
    <>
      <p>Any work which:</p>
      <ul>
        <li>
          Increases the enclosed floor area or cubic content of a building, or
        </li>
        <li>Renovates a building that had been predominately vacant, or</li>
        <li>Changes the use of one or more floors of building, or</li>
        <li>Affects at least 25% of building area, or</li>
        <li>
          Involves new installation or replacement of any one of HVAC,
          elevators, electric wiring or plumbing, or
        </li>
        <li>
          Involves replacement of at least one of the exterior faces of the
          building.
        </li>
      </ul>
    </>
  )
}

export default HelpDescriptionStatusWasAnyConstructionHelp
