import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { ExpenseSectionTypes } from 'constants/dicti'
import { otherSectionItem } from 'pages/Services/TA/TAFormPage/steps/PropertyDetails/IncomeExpense/StepPropertyDetailsExpense/schema'

const sectionItem = {
  categoryCS: schemaRules.NOT_EMPTY_STRING,
  // isAmountOptional: Joi.boolean(),
  lastYearAmount: Joi.when('isAmountOptional', {
    is: true,
    then: schemaRules.NUMBER.min(0).allow(null, ''),
    otherwise: schemaRules.NOT_EMPTY_NUMBER,
  }),
}

const expenseSectionSchema = {
  typeCS: schemaRules.NOT_EMPTY_STRING,
  items: Joi.when('typeCS', {
    switch: [
      {
        is: ExpenseSectionTypes.ExpenseMain,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: ExpenseSectionTypes.ExpenseNonDeductable,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: ExpenseSectionTypes.CondoMain,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: ExpenseSectionTypes.HotelDepartmental,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: ExpenseSectionTypes.HotelFinancial,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: ExpenseSectionTypes.HotelUndistributed,
        then: schemaRules.ITEMS(sectionItem),
      },
      {
        is: ExpenseSectionTypes.ExpenseOther,
        then: schemaRules.ITEMS(otherSectionItem),
      },
      {
        is: ExpenseSectionTypes.HotelOther,
        then: schemaRules.ITEMS(otherSectionItem),
      },
      {
        is: ExpenseSectionTypes.CondoOther,
        then: schemaRules.ITEMS(otherSectionItem),
      },
    ],
    otherwise: schemaRules.ANY,
  }),
}

const schema = schemaWrapper({
  sections: schemaRules.ITEMS(expenseSectionSchema),
})

export default schema
