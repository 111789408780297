
//TODO create CONSTANTS in enum's
export enum UserRole {
  ADMIN = 'admin',
  USER_WEB = 'client-web',
  USER_EMAIL = 'client-email',
  ANONYMOUS = 'anonymous',
}

export enum Resource {
  ANY = '*',
  PUBLIC = 'GRANTS_RESOURCE_PUBLIC',
  TA_FORM = 'GRANTS_RESOURCE_TA_FORM',
  TA_PROPERTYLIST = 'GRANTS_RESOURCE_TA_PROPERTYLIST',
  RPIE_TRACKING = 'GRANTS_RESOURCE_RPIE_TRACKING',
  TA_TRACKING = 'GRANTS_RESOURCE_TA_TRACKING',
  DOCUMENT_CENTER = 'GRANTS_RESOURCE_DOCUMENT_CENTER',
  ADMIN = 'GRANTS_RESOURCE_ADMIN',
  RPIE_FORM = 'GRANTS_RESOURCE_RPIE_FORM',
  RPIE_PROPERTYLIST = 'GRANTS_RESOURCE_RPIE_PROPERTYLIST',
}

export enum Action {
  ANY = '*',
  IMPORT = 'GRANTS_ACTION_IMPORT',
  SUBMIT = 'GRANTS_ACTION_SUBMIT',
  LIST = 'GRANTS_ACTION_LIST',
  READ = 'GRANTS_ACTION_READ',
  WRITE = 'GRANTS_ACTION_WRITE',
  DELETE = 'GRANTS_ACTION_DELETE',
  GETANYPROPERTY = 'GRANTS_ACTION_GETANYPROPERTY',
}

// use it for inverting any grant or attribute
// function not(grantOrAttribute: string) {
//   return `!${grantOrAttribute}`
// }

const grants = {
  [UserRole.ADMIN]: {
    grants: [
      {
        resource: Resource.ANY,
        action: Action.ANY,
        attributes: [''],
      },
    ],
  },
  [UserRole.USER_WEB]: {
    grants: [
      {
        resource: Resource.TA_PROPERTYLIST,
        action: Action.READ,
        attributes: [''],
        // condition: {
        //   Fn: 'LIST_CONTAINS',
        //   args: {
        //     services: Services.TaxAppeal,
        //   },
        // },
      },
      {
        resource: Resource.TA_FORM,
        action: [Action.READ, Action.WRITE],
        attributes: [''],
        // condition: {
        //   Fn: 'LIST_CONTAINS',
        //   args: {
        //     services: Services.TaxAppeal,
        //   },
        // },
      },
      {
        resource: Resource.PUBLIC,
        action: Action.ANY,
        attributes: [''],
      },
      {
        resource: Resource.RPIE_PROPERTYLIST,
        action: Action.READ,
        attributes: [''],
        // condition: {
        //   Fn: 'LIST_CONTAINS',
        //   args: {
        //     services: Services.RPIE,
        //   },
        // },
      },
      {
        resource: Resource.RPIE_FORM,
        action: [Action.READ, Action.WRITE],
        attributes: [''],
        // condition: {
        //   Fn: 'LIST_CONTAINS',
        //   args: {
        //     services: Services.RPIE,
        //   },
        // },
      },
      {
        resource: Resource.DOCUMENT_CENTER,
        action: Action.ANY,
        attributes: [''],
      },
    ],
  },
  [UserRole.USER_EMAIL]: {
    grants: [
      {
        resource: Resource.PUBLIC,
        action: Action.ANY,
        attributes: [''],
      },
      {
        resource: Resource.DOCUMENT_CENTER,
        action: Action.ANY,
        attributes: [''],
      },
    ],
  },
  [UserRole.ANONYMOUS]: {
    grants: [
      {
        resource: Resource.PUBLIC,
        action: Action.ANY,
        attributes: [''],
      },
    ],
  },
}

export default grants

/*   
  user: {
    grants: [
      {
        resource: 'video',
        action: 'create',
        attributes: ['*'],
      },
      {
        resource: 'video',
        action: 'read',
        attributes: ['*'],
      },
      {
        resource: 'video',
        action: 'update',
        attributes: ['*'],
      },
      {
        resource: 'video',
        action: 'delete',
        attributes: ['*'],
      },
    ],
  },
  'sports/editor': {
    grants: [
      {
        resource: 'article',
        action: '*',
        attributes: ['*'],
        condition: {
          Fn: 'EQUALS',
          args: {
            category: 'sports',
          },
        },
      },
    ],
  },
  'sports/writer': {
    grants: [
      {
        resource: 'article',
        action: ['create', 'update'],
        attributes: ['*', '!status'],
        condition: {
          Fn: 'EQUALS',
          args: {
            category: 'sports',
          },
        },
      },
    ],
  },
 */
