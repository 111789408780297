import {
  NavBarItems,
  NavBarItemVariants,
} from 'ui-framework/components/primitives/Progress/NavBar'
import { NavBarSectionItems } from '../MultisectionalProgress'

//==================================================

const getCurrentStepIndex = (
  steps,
  autosaveEnabled,
  savedStepId,
  currentStepIndex
) => {
  const lastSavedStepId = steps.findIndex(x => x.id === savedStepId)

  return autosaveEnabled || lastSavedStepId < currentStepIndex
    ? currentStepIndex
    : lastSavedStepId
}

const useNavigationProgressBar = (
  condition,
  stepsSections,
  steps,
  currentStep,
  currentStepIndex,
  lastSavedStepId,
  autosave,
  hanleGoToStep
): NavBarSectionItems => {
  return condition
    ? stepsSections.reduce((result, { stepsIds }, i) => {
        const getStepTitle = v => steps?.find(s => s.id === v)?.title,
          alredyVisited = v =>
            steps.findIndex(s => s?.id === v) <=
            getCurrentStepIndex(
              steps,
              autosave,
              lastSavedStepId,
              currentStepIndex
            ),
          getVariant = (v, visited) =>
            currentStep?.id === v
              ? NavBarItemVariants.ACTIVE
              : visited
              ? NavBarItemVariants.VISITED
              : NavBarItemVariants.NETURAL,
          getItem = val => {
            const visited = alredyVisited(val)
            return (
              visited && {
                id: val,
                label: (
                  <>
                    <b>Click to go back to step: </b>
                    {getStepTitle(val)}
                  </>
                ),
                variant: getVariant(val, visited),
                navHandler: () => hanleGoToStep(val),
                width: `${100 / stepsIds.length}%`,
              }
            )
          },
          insertItem = v => {
            const item = getItem(v)
            if (item)
              result[i]
                ? result[i].push(item)
                : (result[i] = [item] as NavBarItems)
            else
              (() => {
                const clear = data =>
                  data &&
                  (data[data.length - 1].variant = NavBarItemVariants.NETURAL)
                clear(result[i] || result[i - 1])
              })()
          }

        stepsIds?.forEach(insertItem)
        return result
      }, {})
    : undefined
}

export default useNavigationProgressBar
