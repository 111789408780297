import React from 'react'
import styled from '@emotion/styled/macro'

import Icon from '../Icon'
import { OnClickHandler, ChakraComponentProps } from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'
import { useMultiStyleConfig, Flex, Box } from '@chakra-ui/react'
import { KEYCODES } from 'constants/CONSTANTS'

//===================================================

type ControlIconProps = { disabled?: boolean }
const ControlIcon = styled(Icon)<ControlIconProps>(
  props => ({
    fill: props.disabled
      ? props.theme.colors.black50
      : props.theme.colors.primary100,
  }),
  props =>
    !props.disabled &&
    {
      // add shadows
    }
)

export type ShifterUIProps = {
  disabledNext?: boolean
  disabledPrev?: boolean

  onNext?: OnClickHandler
  onPrev?: OnClickHandler
} & ChakraComponentProps

/**
 * ShifterUI component
 */
const ShifterUI = ({
  disabledNext = false,
  disabledPrev = false,
  onPrev,
  onNext,
  ...props
}: ShifterUIProps) => {
  const style = useMultiStyleConfig('MGNYShifter', props),
    onKeyUp = (e, keyCode, cb) => keyCode.includes(e.keyCode) && cb()
  return (
    <Flex className={props.className} sx={style.wrapper}>
      <ControlIcon
        size="xs"
        disabled={disabledPrev}
        onClick={() => !disabledPrev && getCallback(onPrev)()}
        tabIndex={0}
        onKeyUp={e =>
          onKeyUp(e, [KEYCODES.LEFT_ARROW, KEYCODES.ENTER], getCallback(onPrev))
        }
      >
        left
      </ControlIcon>

      <Box sx={style.contentWrapper}>{props.children}</Box>

      <ControlIcon
        size="xs"
        disabled={disabledNext}
        onClick={() => !disabledNext && getCallback(onNext)()}
        tabIndex={0}
        onKeyUp={e =>
          onKeyUp(
            e,
            [KEYCODES.RIGHT_ARROW, KEYCODES.ENTER],
            getCallback(onNext)
          )
        }
      >
        right
      </ControlIcon>
    </Flex>
  )
}

export default ShifterUI
