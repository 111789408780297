import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

const schema = schemaWrapper({
  checkTotalNumberOfResidUnits: Joi.custom((value, helpers) => {
    const {
      numberOfResidentialUnit,
      numberOfRegulatedUnits,
      numberOfUnRegulatedUnits,
      ownerOccupied,
    } = helpers.prefs.context?.originalValue

    if (
      0 + numberOfRegulatedUnits + numberOfUnRegulatedUnits + ownerOccupied !==
      numberOfResidentialUnit
    ) {
      return helpers.error('section.residunits')
    }
  }).messages({
    'section.residunits': `The sum of Units should be equal to Total Number of Residential Units (${Joi.expression(
      '{[numberOfResidentialUnit]}'
    )})`,
  }),
  numberOfRegulatedUnits: schemaRules.NOT_EMPTY_NUMBER,
  numberOfUnRegulatedUnits: schemaRules.NOT_EMPTY_NUMBER,
  isOwnerOccupiedShown: schemaRules.BOOLEAN,
  ownerOccupied: Joi.when('isOwnerOccupiedShown', {
    is: true,
    then: schemaRules.GREATER_NUMBER(),
    otherwise: null,
  }),

  location: Joi.when('isOwnerOccupiedShown', {
    is: true,
    then: schemaRules.NOT_EMPTY_STRING,
    otherwise: null,
  }),
})

export default schema
