import useApplicationContext from 'hooks/useApplicationContext'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { NBAR_TYPE } from 'ui-framework/components/patterns/NotificationBar'

const useConcurentUpdateNotification = updatedOutside => {
  const navigate = useNavigate(),
    { nbar } = useApplicationContext()

  useEffect(() => {
    if (updatedOutside) {
      nbar.set({
        type: NBAR_TYPE.WARNING,
        message: (
          <>
            Another portal session is active on this property now!{' '}
            <Link to={'#'} onClick={() => navigate(0)}>
              See changes (Reload)
            </Link>
          </>
        ),
      })
    } else {
      nbar.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedOutside])
}

export default useConcurentUpdateNotification
