import styled, { CSSObject } from '@emotion/styled/macro'

import Label from '../../Label'
import Message from '../../Message'
import { ComponentStyleConfig } from '@chakra-ui/react'

export type InputState = 'normal' | 'error' | 'warning' | 'success'

// ============================================================
type PlaceholderProps = {
  disabled: boolean
}
const Placeholder = styled('span')<PlaceholderProps>(
  ({ disabled, ...props }) => ({
    position: 'absolute',
    color: disabled ? props.theme.colors.black30 : props.theme.colors.black50,
    // backgroundColor: disabled ? 'transparent' : props.theme.colors.white100,
    pointerEvents: 'none',
    paddingLeft: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 42px)',
  })
)

// ============================================================
type InputWrapperProps = {
  state: InputState
  filled: boolean
  disabled: boolean
  width: string | number
  preserveHeight: boolean
}

const InputWrapper = styled('div')<InputWrapperProps>(
  (props): CSSObject => ({
    ...props.theme.typography.body2,
    position: 'relative',
    minHeight: props.preserveHeight ? '78px' : '34px',
    minWidth: '80px',
    width: props.width,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    ...props.theme.typography.body2,
    [Label as any]: {
      marginBottom: 4,
    },
    [Message as any]: {
      marginTop: 2,
    },
  })
)

const InputInnerWrapper = styled('div')<{
  state: InputState
  filled: boolean
  disabled: boolean
  outline: boolean
}>(
  (props): CSSObject => ({
    'input, textarea': {
      ...props.theme.typography.body2,
      border: 'none',
      width: '100%',
      outline: 'none',
      background: 'transparent',
      '&[type="text" i]': {
        padding: 0,
      },
    },
  }),
  ({ filled, state, disabled, ...props }): CSSObject => ({
    position: 'relative',
    display: 'flex',
    // alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '34px',
    padding: '6px 12px',
    // margin: '24px 0 20px 0', // place for label and message
    // pointerEvents: disabled ? 'none' : 'all',
    transition: 'all ease .3s, border .1s',
    outline: 'none',
    backgroundColor: disabled
      ? props.theme.colors.gray1
      : props.theme.colors.white100,
    'input, textarea': {
      color: filled ? props.theme.colors.black70 : props.theme.colors.black50,
      // ...props.theme.typography.body2,
    },
    ':focus-within': {
      'input, textarea': {
        // color: filled ? props.theme.colors.black100 : undefined,
        color: props.theme.colors.black100,
      },
      [Placeholder as any]: {
        display: 'none',
      },
    },
  }),
  ({ filled, state, outline, disabled, ...props }) =>
    outline && {
      border: `1px solid`,
      borderRadius: '2px',
      borderColor: disabled
        ? props.theme.colors.gray1
        : {
            normal: props.theme.colors.primary50,
            error: props.theme.colors.error,
            warning: props.theme.colors.warning,
          }[state],
      ':hover': {
        borderColor: disabled ? 'transparent' : props.theme.colors.primary70,
      },
      ':focus-within': {
        borderColor: {
          normal: filled
            ? props.theme.colors.primary100
            : props.theme.colors.primary70,
          error: props.theme.colors.error,
          warning: props.theme.colors.warning,
        }[state],
        boxShadow: `0px 0px 5px ${
          {
            normal: props.theme.colors.primary100,
            error: props.theme.colors.error,
            warning: props.theme.colors.warning,
          }[state]
        }`,
      },
    }
)

const MGNYInput: ComponentStyleConfig = {
  baseStyle: props => ({
    input: {},
    '.close': {
      display: 'none',
    },
    ':hover': {
      '.close': {
        display: 'inline-flex',
      },
    },
  }),
}

export { InputWrapper, InputInnerWrapper, Placeholder, MGNYInput }
