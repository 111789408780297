import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { HStack, VStack } from '@chakra-ui/react'
import { integerMax, integerPositiveThousands } from 'helpers/formats'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { getFiscalPeriods, getNameAndErrorProps } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import AdditionalInformation from './AdditionalInformation'

export type StepHotelOperationInformationProps = {
  applicantName: string
  hotelName: string
  isManagedByUnrelatedEntity: boolean
  haveProprietaryRights: boolean
  haveProprietaryRightsDescription: string
  totalRoomsNumber: number
  keysNumber: number
  transientRoomsNumber: number
  permanentRoomsNumber: number
  occupancyRate: number
  additionalHidden?: true
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepHotelOperationInformation component
 */
const StepHotelOperationInformation = (
  props: StepHotelOperationInformationProps
) => {
  const {
      hotelName,
      isManagedByUnrelatedEntity,
      haveProprietaryRights,
      haveProprietaryRightsDescription,
      totalRoomsNumber,
      keysNumber,
      transientRoomsNumber,
      permanentRoomsNumber,
      occupancyRate,
      additionalHidden,
      applyValidation,
      onValidation,
      disabled,
      onChange,
    } = props,
    { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context),
    validationData = useMemo(
      () => ({
        hotelName,
        isManagedByUnrelatedEntity,
        haveProprietaryRights,
        haveProprietaryRightsDescription,
        totalRoomsNumber,
        keysNumber,
        transientRoomsNumber,
        permanentRoomsNumber,
        occupancyRate,
        $maxPermanent: totalRoomsNumber - transientRoomsNumber,
        $maxTransient: totalRoomsNumber - permanentRoomsNumber,
        additionalHidden,
      }),
      [
        hotelName,
        isManagedByUnrelatedEntity,
        haveProprietaryRights,
        haveProprietaryRightsDescription,
        totalRoomsNumber,
        keysNumber,
        transientRoomsNumber,
        permanentRoomsNumber,
        occupancyRate,
        additionalHidden,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack width="560px" spacing="36px" align="left">
        <HelpTarget name="hotelName">
          <Input
            width="400px"
            label="Hotel Name"
            value={hotelName}
            type="text"
            disabled={disabled}
            onChange={onChange}
            {...nameAndErrorProps('hotelName')}
            errorTooltip
          />
        </HelpTarget>
        {!additionalHidden && (
          <AdditionalInformation {...props} hasError={hasError} />
        )}
        <HStack spacing={`36px`} width="400px">
          <HelpTarget name="totalRoomsNumber">
            <Input
              width="182px"
              label="# of Rooms"
              value={totalRoomsNumber}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('totalRoomsNumber')}
              errorTooltip
            />
          </HelpTarget>
          <HelpTarget name="keysNumber">
            <Input
              width="182px"
              label="# of Keys"
              value={keysNumber}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('keysNumber')}
              errorTooltip
            />
          </HelpTarget>
        </HStack>

        <HStack spacing={`36px`} width="400px">
          <HelpTarget name="transientRoomsNumber">
            <Input
              width="182px"
              label="# of Transient Rooms"
              value={transientRoomsNumber}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              errorTooltip
              onChange={onChange}
              {...nameAndErrorProps('transientRoomsNumber')}
            />
          </HelpTarget>

          <HelpTarget name="permanentRoomsNumber">
            <Input
              width="182px"
              label="# of Permanent Rooms"
              value={permanentRoomsNumber}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              errorTooltip
              onChange={onChange}
              {...nameAndErrorProps('permanentRoomsNumber')}
            />
          </HelpTarget>
        </HStack>

        <HelpTarget name="occupancyRate">
          <Input
            width="182px"
            label={`Average Occupancy Rate for ${reportingYear} (%)`}
            value={occupancyRate}
            type="number"
            format={integerMax(200)}
            disabled={disabled}
            onChange={onChange}
            {...nameAndErrorProps('occupancyRate')}
            errorTooltip
          />
        </HelpTarget>
      </VStack>
    </StepBasic>
  )
}

export default StepHotelOperationInformation
