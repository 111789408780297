import React, { useMemo } from 'react'

import { InputState } from '../Input/styles/InputStyles'
import { chakra } from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'

//===================================================
type ProgressIndicatorProps = {
  progress: number
  state?: InputState
} & ChakraComponentProps

const ProgressIndicatorWrapper = chakra('div', {
  baseStyle: {
    bg: 'transparent',
    minW: '100px',
    maxW: '130px',
    width: 'fit-content',
    borderRadius: '16px',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: 'secondary.inputGray',
  },
})

const ProgressIndicatorLine = chakra('div', {
  baseStyle: {
    padding: '5px 16px 7px',
    minHeight: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'fontnavy',
    textStyle: 'body.regular',
  },
})

/**
 * ProgressIndicator component
 */
const ProgressIndicator = (props: ProgressIndicatorProps) => {
  const { progress, state } = props,
    indicator = useMemo(() => Math.round(progress), [progress]),
    hasError = state === 'error',
    inProcessing = indicator < 100

  return (
    <ProgressIndicatorWrapper className={props.className}>
      <ProgressIndicatorLine
        w={hasError ? '100%' : `${indicator}%`}
        bg={`${hasError ? 'red' : 'green'}.opacity`}
      >
        {!hasError && indicator && inProcessing
          ? indicator + '%'
          : hasError
          ? 'Loading error'
          : state === 'success'
          ? 'Uploaded'
          : !inProcessing
          ? 'Awaiting'
          : ''}
      </ProgressIndicatorLine>
    </ProgressIndicatorWrapper>
  )
}

export default ProgressIndicator
