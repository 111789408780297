import { useCallback } from 'react'
import {
  Avatar,
  Box,
  Text,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'
import Chips from 'ui-framework/components/primitives/Chips'
import Faina from './photos/Faina_Savich.jpg'
import Melanie from './photos/Melanie_Houle.png'
import Mint from './photos/Mint_Tan.png'
import Polina from './photos/Polina_Konstantinova.png'
import Vlad from './photos/Vlad_Direktor.png'
import Dmitry from './photos/Dmitry_Irinev.png'
import Alex from './photos/Alex_Onishchenko.jpeg'
import Frank from './photos/Frank_Grampone.png'

const FilingManagersConfig = {
  'faina@mgnyconsulting.com': {
    name: 'Faina Savich',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '506',
    photo: Faina,
  },

  'melanie@mgnyconsulting.com': {
    name: 'Melanie Houle',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '508',
    photo: Melanie,
  },
  'mint@mgnyconsulting.com': {
    name: 'Mint Tan',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '503',
    photo: Mint,
  },
  'polina@mgnyconsulting.com': {
    name: 'Polina Konstantinova',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '507',
    photo: Polina,
  },
  'vlad@mgnyconsulting.com': {
    name: 'Vlad Direktor',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '509',
    photo: Vlad,
  },
  'dmitriyirinev@mgnyconsulting.com': {
    name: 'Dmitriy Irinev',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '515',
    photo: Dmitry,
  },
  'frankgrampone@mgnyconsulting.com': {
    name: 'Frank Grampone',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '566',
    photo: Frank,
  },
  'alexonishchenko@mgnyconsulting.com': {
    name: 'Alex Onishchenko',
    phone: SUPPORT_CONTACTS.supportTel,
    extension: '567',
    photo: Alex,
  },
}

const YourFilingManager = (props: { managerEmail }) => {
  const manager = FilingManagersConfig[props.managerEmail]

  const avatar = useCallback(
    size => <Avatar size={size} name={manager.name} src={manager.photo} />,
    [manager]
  )

  if (!manager) return null
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Box role="button" ml={`16px`} display={'inline-flex'}>
          <Chips variant={'primaryOutlined'} prefix={avatar('sm')}>
            <Box textStyle={'body.regular'}>Your Filing Manager</Box>
          </Chips>
        </Box>
      </PopoverTrigger>
      <PopoverContent w={'280px'}>
        <Flex
          bgColor={'white'}
          h={'104px'}
          w={'100%'}
          p={'16px'}
          boxShadow={'0px 4px 8px rgba(4, 7, 31, 0.151235)'}
          borderRadius={'12px'}
        >
          {avatar('lg')}
          <Box ml={'12px'}>
            <VStack spacing={'6px'} align={'flex-start'}>
              <Text textStyle={'body.semibold'} noOfLines={2}>
                {manager.name}
              </Text>
              <Text
                textStyle={'body.regular'}
                as="a"
                href={`tel:${manager.phone},${manager.extension}`}
              >
                {manager.phone} x.{manager.extension}
              </Text>
            </VStack>
          </Box>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

export default YourFilingManager
