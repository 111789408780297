import React, { ReactNode, useMemo, useCallback, useState } from 'react'

import Pagination, {
  PaginationProps,
} from 'ui-framework/components/primitives/Pagination'
import EmptyMessage from 'ui-framework/components/primitives/EmptyMessage'
import Loader from 'ui-framework/components/primitives/Loader'
import TableHeaders, { TableHeader, TableHeaderItems } from './TableHeaders'

import useFilterSettings, {
  FilterSettingsItem,
  SortingDirection,
} from './useFilterSettings'
import usePagination from 'hooks/usePagination'
import { isNullOrWhiteSpace } from 'helpers/utils'
import BaseComponentProps from 'common/BaseComponentProps'
import { get, set, del } from 'object-path-immutable'
import { isCallback } from 'helpers/utils'
import { useMultiStyleConfig, Box, chakra, ChakraProps } from '@chakra-ui/react'

//===================================================
function uniTypeSorting(
  items: any[],
  objectCompareFn?: (a: any, b: any) => 0 | 1 | -1
) {
  const booleans: boolean[] = [],
    numbers: number[] = [],
    strings: string[] = [],
    objects: any[] = []

  items.forEach(i => {
    if (typeof i === 'boolean') booleans.push(i)
    else if (Number.isInteger(i)) numbers.push(i)
    else if (typeof i === 'string') strings.push(i)
    else objects.push(i)
  })

  return [
    ...booleans.sort(),
    ...numbers.sort((a, b) => a - b),
    ...strings.sort(),
    ...objects.sort(objectCompareFn),
  ]
}

function getFilterValues(items, path) {
  if (!items) return []

  const options = uniTypeSorting(
    items.map(item => {
      const dataValue = get<any>(item, path),
        isEmpty =
          dataValue === null || dataValue === undefined || dataValue === ''
      return !isEmpty ? dataValue : '(empty)'
    })
  )

  return options.filter((value, index, self) => self.indexOf(value) === index)
}

export type TableChildrenFunc = (item: any, index: number) => ReactNode

export type TableProps = {
  headers?: TableHeaderItems
  items?: object[] | null
  search?: string
  hasSearch?: boolean
  children: ReactNode | TableChildrenFunc
  footer?: ReactNode
  itemsPerPage?: number
  paginationSettings?: PaginationProps
  pending?: boolean
  rowsSpacing?: number
  cellSidePaddings?: string
  isSticky?: boolean
  rowsHeight?: string
  rowsHeadingHeight?: string
  styles?: ChakraProps
  emptyMessage?: {
    title: string
    message: string
  }
  emptySearchMessage?: {
    title: string
    message: string
  }
} & Omit<BaseComponentProps, 'children'>

/**
 * Table component
 */
const Table = (props: TableProps) => {
  const {
    headers = [],
    items = [],
    search,
    footer,
    itemsPerPage,
    paginationSettings,
    pending = false,
    hasSearch = false,
    styles = {},
    emptyMessage,
    emptySearchMessage = {
      title: "We couldn't find it",
      message: 'Change your search request and try again.',
    },
  } = props
  const style = useMultiStyleConfig('MGNYTable', props),
    [filters, filtersSet, sorting, sortingSet] = useFilterSettings(
      headers,
      true
    ),
    headersWithFilterValues = useMemo(() => {
      const m = (h: TableHeader) => {
        if (!h.filterValues) {
          return set(h, 'filterValues', getFilterValues(items, h.path))
        }
        return h
      }

      return (headers as any).map(t => (Array.isArray(t) ? t.map(m) : m(t)))
    }, [headers, items]),
    handleFilterChange = useCallback(
      (id: string, filter?: FilterSettingsItem) => {
        if (
          filter &&
          filter.value &&
          Array.isArray(filter.value) &&
          filter.value.length > 0
        )
          filtersSet(set(filters, id, filter))
        else filtersSet(del(filters, id))
      },
      [filters, filtersSet]
    ),
    filteredItems = useMemo(() => {
      if (!items) return []
      const headersForFilter = headers.flat(99).filter(h => h?.path)

      const applyFilters = i => {
        if (!filters || !Object.keys(filters).length) return true

        return Object.values(filters).every(f => {
          const value = get(i, f.path)
          return f.value.some(fv =>
            fv === '(empty)'
              ? value === null || value === undefined || value === ''
              : String(value).trim() === String(fv).trim()
          )
        })
      },
        applySearch = i => {
          return (
            !search ||
            headersForFilter.some(h => {
              const value = get(i, h.path),
                result =
                  String(value).toLowerCase().indexOf(search.toLowerCase()) > -1
              return result
            })
          )
        },
        applySort = (a: any, b: any): any => {
          if (!sorting) return

          const sortFunctions = {
            number: (a, b) => a - b,
            string: (a, b) => {
              if (a === b) return 0
              if (a > b) return 1
              if (a < b) return -1
            },
            null: (a, b) => {
              if (isNullOrWhiteSpace(a) && isNullOrWhiteSpace(b)) return 0
              if (!isNullOrWhiteSpace(a) && isNullOrWhiteSpace(b)) return 1
              if (isNullOrWhiteSpace(a) && !isNullOrWhiteSpace(b)) return -1
            },
          },
            value = get(a, sorting.path),
            nextValue = get(b, sorting.path)

          let sortResult
          if (!isNullOrWhiteSpace(value) && !isNullOrWhiteSpace(nextValue)) {
            const sortFunction = sortFunctions[typeof value]
            sortResult = sortFunction ? sortFunction(value, nextValue) : 0
          } else {
            sortResult = sortFunctions.null(value, nextValue)
          }
          return sortResult * (sorting.direction === 'desc' ? -1 : 1)
        },
        result = items.filter(i => applyFilters(i) && applySearch(i))

      //apply sorting
      return !sorting ? result : result.sort(applySort)
    }, [filters, headers, items, search, sorting]),
    [paginatedFilteredItems, tablePagination] = usePagination(filteredItems, {
      itemsPerPage: itemsPerPage || filteredItems.length,
      promote: true,
    }),
    isDataReady = !pending && paginatedFilteredItems,
    needToShowEmptyMessage = emptyMessage && isDataReady && !items?.length,
    needToShowEmptySearchMessage =
      isDataReady &&
      !needToShowEmptyMessage &&
      !paginatedFilteredItems.length &&
      (search || hasSearch)

  function handleSortClick(header: TableHeader) {
    if (!header.id) return
    const getNextDirection = (
      direction: SortingDirection
    ): SortingDirection | undefined => {
      return !direction ? 'asc' : direction === 'asc' ? 'desc' : undefined
    }

    if (sorting?.name === header.id) {
      const next = getNextDirection(sorting?.direction)
      sortingSet(next ? set(sorting, 'direction', next) : undefined)
    } else {
      sortingSet({
        name: header.id,
        path: header.path as string,
        direction: 'asc',
      })
    }
  }

  return (
    <div
      className={props.className}
    // ref={tableWrapper =>
    //   scrollable &&
    //   wrapperPositionSet(tableWrapper?.getBoundingClientRect()?.top as number)
    // }
    >
      <Box as={`table`} sx={{ ...style.boxTable, ...styles }}>
        <>
          {headersWithFilterValues.length > 0 && (
            <TableHeaders
              items={headersWithFilterValues}
              filters={filters}
              sorting={sorting}
              onFilterChange={handleFilterChange}
              onSortClick={handleSortClick}
            />
          )}
          {!isCallback(props.children) ? (
            props.children
          ) : (
            <tbody>
              {isDataReady && paginatedFilteredItems.length ? (
                paginatedFilteredItems.map((item, index: number) => (
                  <React.Fragment key={index}>
                    {(props.children as TableChildrenFunc)(item, index)}
                  </React.Fragment>
                ))
              ) : !pending ? undefined : (
                <tr>
                  <chakra.td
                    height={'80px'}
                    colSpan={headersWithFilterValues.length}
                    _hover={{ bgColor: 'transparent !important' }}
                  >
                    <Loader />
                  </chakra.td>
                </tr>
              )}
            </tbody>
          )}
          {footer ? <tfoot>{footer}</tfoot> : undefined}
        </>
      </Box>

      {needToShowEmptyMessage && (
        <EmptyMessage
          title={emptyMessage?.title}
          message={emptyMessage?.message}
          margin="48px auto 0"
        />
      )}

      {needToShowEmptySearchMessage && (
        <EmptyMessage
          title={emptySearchMessage.title}
          message={emptySearchMessage.message}
          margin="48px auto 0"
        />
      )}

      {!pending && (paginationSettings || itemsPerPage) && (
        <Pagination {...(paginationSettings || tablePagination)} />
      )}
    </div>
  )
}

export default chakra(Table)
