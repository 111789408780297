import { negate, overEvery } from 'lodash/fp'
import {
  BuildingClasses,
  DocumentType,
  getDictiName,
  PropertyType,
  RPIEWizardSections,
  RPIEWizardSteps,
  StorefrontUnitOccupancyType,
  TaxClasses,
} from 'constants/dicti'
import {
  getDescription,
  getFiscalPeriods,
  hasIntersections,
} from 'helpers/utils'
import {
  RpieFormData,
  SectionRequirementsSection,
} from 'hooks/api/rpie/RpieFormData'
import { StepsConfig } from 'hooks/wizard/useStepsConfig'
import { consolidatedLotsInfo } from 'pages/Services/TA/TAFormPage/wizard/taWizardConfig'
import { WizardConfig } from 'ui-framework/components/patterns/Wizard/ServiceWizard'
import StepFillingTypeSelection from 'ui-framework/components/patterns/Wizard/StepFillingTypeSelection'
import StepReportingPeriod from 'ui-framework/components/patterns/Wizard/StepReportingPeriod'
import StepUpload from 'ui-framework/components/patterns/Wizard/StepUpload'
import HelpApplicantStepCommercialDescription from '../assistant/HelpApplicantStepCommercialDescription'
import HelpEndDateStepSFIntervals from '../assistant/HelpEndDateStepSFIntervals'
import HelpFloorSizeStepSFGeneral2 from '../assistant/HelpFloorSizeStepSFGeneral2'
import HelpGreatWorkMoveToTheNext from '../assistant/HelpGreatWorkMoveToTheNext'
import HelpNextStepFillingTypeSelection from '../assistant/HelpNextStepFillingTypeSelection'
import HelpNextStepSFGeneral1 from '../assistant/HelpNextStepSFGeneral1'
import HelpNumberStepCommercialDescription from '../assistant/HelpNumberStepCommercialDescription'
import HelpResRRStepFillingTypeSelection from '../assistant/HelpResRRStepFillingTypeSelection'
import HelpRRStepFillingTypeSelection from '../assistant/HelpRRStepFillingTypeSelection'
import HelpStepReportingPeriod from '../assistant/HelpStepReportingPeriod'
import HelpStepSFInfo from '../assistant/HelpStepSFInfo'
import HelpStepSFIntervals from '../assistant/HelpStepSFIntervals'
import HelpVacantStepCommercialDescription from '../assistant/HelpVacantStepCommercialDescription'
import HelpWhatToExpect from '../assistant/HelpWhatToExpect'
import StepGeneralCommercialDescription from '../steps/general/StepGeneralCommercialDescription'
import StepGeneralNetLeaseQuestions from '../steps/general/StepGeneralNetLeaseQuestions'
import StepGeneralPropertyDescription from '../steps/general/StepGeneralPropertyDescription'
import StepGeneralResidentialDescription from '../steps/general/StepGeneralResidentialDescription'
import StepFurnitureFixturesAndEquipment from 'ui-framework/components/patterns/Wizard/hotel/StepFurnitureFixturesAndEquipment'
import StepHotelOperationInformation from 'ui-framework/components/patterns/Wizard/hotel/StepHotelOperationInformation'
import StepRoomRates from 'ui-framework/components/patterns/Wizard/hotel/StepRoomRates'
import StepExpenseTable from '../steps/income/StepExpenseTable'
import StepIncomeTable from '../steps/income/StepIncomeTable'
import StepRentRollCommercialTable from '../steps/rentrollComm/StepRentRollCommercialTable'
import StepRentRollResidentialTable from '../steps/rentrollRes/StepRentRollResidentialTable'
import StepStorefrontUnitGeneral1Step from '../steps/storefront/StepStorefrontUnitGeneral1Step'
import StepStorefrontUnitGeneral2Step from '../steps/storefront/StepStorefrontUnitGeneral2Step'
import StepStorefrontUnitOccupancyIntervalsStep from '../steps/storefront/StepStorefrontUnitOccupancyIntervalsStep'
import StepStorefrontUnitOccupantContactInformationStep from '../steps/storefront/StepStorefrontUnitOccupantContactInformationStep'
import StepStorefrontUnitOccupantDOBJobsStep from '../steps/storefront/StepStorefrontUnitOccupantDOBJobsStep'
import StepStorefrontUnitOccupantLeaseConcessionsStep from '../steps/storefront/StepStorefrontUnitOccupantLeaseConcessionsStep'
import StepStorefrontUnitOccupantRentInformationStep from '../steps/storefront/StepStorefrontUnitOccupantRentInformationStep'
import StepSubmit from '../steps/submit/StepSubmit'
import StepSummary from '../steps/submit/StepSummary'
import { asOfJanuary, rangeOfYear, repYearDescrReal } from './common'
import { get } from 'object-path-immutable'
import StepPropertyParking from 'pages/Services/TA/TAFormPage/steps/PropertyDescription/StepPropertyParking'
import HelpGreatJobNextStep from 'pages/Services/TA/TAFormPage/assistant/HelpGreatJobNextStep'
import { RpieSummarySectionsConfig } from '../steps/submit/RpieSummarySectionsConfig'
import InformationDialogWithTrigger from 'ui-framework/components/patterns/InformationDialogWithTrigger'
import Accordion, {
  AccordionExpandModes,
} from 'ui-framework/components/primitives/Accordion'
import PrintPageButton from 'ui-framework/components/primitives/PrintPageButton'
import { ReactNode } from 'react'
import { ExcelFileFormatMessage } from 'ui-framework/components/patterns/UploadDropZone/UploadDropZoneUI'

export type RpieStepsConfigContext = {
  sectionsRequirements: SectionRequirementsSection
  [key: string]: any
}

export const CUSTOM_WIZARD_ACTION_RPIE_FULL_SUBMIT =
  'CUSTOM_WIZARD_ACTION_RPIE_FULL_SUBMIT'

const descriptionObject = {
  first: 'unitApartmentNumber',
  others: ['houseNo', 'streetName'],
  default: 'Unit #',
}

export const showUploadArea = path => data =>
  get(data, [path, 'showUploadArea'], false),
  hasExclusion = data => Boolean(data?.general?.exclusionCS),
  isProperyNotHotel = context =>
    context?.bblsData?.propertyTypeCS !== PropertyType.Hotel,
  rrComNeeded = (data, context) =>
    isProperyNotHotel(context) &&
    context?.bblsData?.assesmentData?.tentativeActualAV >= 750000 &&
    data.general.numberOfCommercialUnit > 0 &&
    !hasExclusion(data) &&
    data.general.numberOfCommercialUnit - data.storefront.totalUnitNumber > 0,
  rrResNeeded = (data, context) =>
    isProperyNotHotel(context) &&
    context?.bblsData?.assesmentData?.tentativeActualAV >= 750000 &&
    data.general.numberOfResidentialUnit > 0 &&
    !hasExclusion(data) &&
    isProperyNotHotel(context),
  grossRentNeeded = (data, context) =>
    context?.bblsData?.assesmentData?.tentativeActualAV >= 750000 &&
    data.general.numberOfCommercialUnit > 0,
  ieNeeded = (data: RpieFormData, context) =>
    data?.general?.wasAnyRentalIncome &&
    !data.general.isPropertyToNetLease &&
    !hasExclusion(data) &&
    (!context.isConsolidated ? true : context.isRootConsolidateBBL) &&
    !(context.serviceInputs.hasTC10x && context.serviceInputs.hasTC20x) &&
    !(
      (hasIntersections(context.bblsData.taxClass, [
        TaxClasses._2A,
        TaxClasses._2B,
      ]) &&
        hasIntersections(context.bblsData.buildingClasses, [
          BuildingClasses.C4,
          BuildingClasses.S3,
          BuildingClasses.S4,
          BuildingClasses.S5,
        ])) ||
      (context?.bblsData?.taxClass?.includes(TaxClasses._1) &&
        data.storefront.totalUnitNumber > 0)
    ),
  hideIE = negate(ieNeeded),
  hideIEUpload = negate(overEvery([ieNeeded, showUploadArea('income')])),
  hideIEFill = negate(overEvery([ieNeeded, negate(showUploadArea('income'))]))

const getButtonLastYear = (title, key) => data =>
  !data?.general?.isNewPropertyForThisYear && (
    <InformationDialogWithTrigger
      triggerText={`Last Year Info`}
      title={`${title} Information for Last Year`}
      sectionKey={key}
      sectionFn={RpieSummarySectionsConfig}
    />
  )

const getButtonIncExp =
  reportingYear =>
    (data, context): ReactNode => {
      if (
        !context?.serviceInputs?.hasTC10x ||
        !context?.serviceInputs?.hasTC20x
      ) {
        return
      }
      const clearSections = sections =>
        sections.filter(section => section.items.length > 0),
        emptyFn = () => { },
        stepItem = {
          onChange: emptyFn,
          onValidation: emptyFn,
          applyValidation: true,
          readOnly: true,
        },
        items = [
          {
            title: 'Income',
            expanded: false,
            content: (
              <StepIncomeTable
                sections={clearSections(data.income.sections)}
                {...stepItem}
              />
            ),
          },
          {
            title: 'Expenses',
            expanded: false,
            content: (
              <StepExpenseTable
                sections={clearSections(data.expense.sections)}
                {...stepItem}
              />
            ),
          },
        ]

      return (
        <InformationDialogWithTrigger
          triggerText={`Inc & Exp`}
          title={`Income & Expenses for ${reportingYear} Tax Appeal`}
        >
          <Accordion items={items} expandMode={AccordionExpandModes.single} />
        </InformationDialogWithTrigger>
      )
    }

const rpieWizardStepsConfig = (data: any, context?: any): StepsConfig => {
  const { fiscalYear, reportingYear, nextFiscalYear } =
    getFiscalPeriods(context)

  return [
    {
      id: RPIEWizardSteps.PropertyDescription,
      section: RPIEWizardSections.General,
      flows: ['*'],
      component: StepGeneralPropertyDescription,
      title: 'Property Description',
      description: asOfJanuary(fiscalYear),
      buttons: getButtonIncExp(reportingYear),
      dataMap: {
        numberOfBuildings: 'general.numberOfBuildings',
        yearPurchase: 'general.yearPurchase',
        isPropertyToNetLease: 'general.isPropertyToNetLease',
        wasAnyRentalIncome: 'general.wasAnyRentalIncome',
        exclusionCS: 'general.exclusionCS',
      },
      help: {
        $step: HelpWhatToExpect,
        $next: HelpGreatWorkMoveToTheNext,
        numberOfBuildings: {
          title: '# of Buildings on the Lot',
          description:
            'Count all structures on your this tax lot. Typically, the answer is 1, but sometimes there are more than one building on a single lot.',
        },
        yearPurchase: {
          title: 'Year of Purchase',
          description:
            'When did the last transfer of ownership take place? This does not include related transfers or partial transfers. Only fee interest transfers between unrelated parties are relevant here.',
        },
        isPropertyToNetLease: {
          title: 'Is the property subject to a net lease?',
          description:
            'If there is a single tenant that operates the property and is responsible' +
            ' for all or most of the expenses, answer Yes. ' +
            'Otherwise, answer No.',
        },
        wasAnyRentalIncome: {
          title: `Was there any rental income in ${reportingYear}?`,
          description: `If you collected any rent between January and December ${reportingYear}, please answer Yes. If no rent payments were collected, answer No.`,
        },
        exclusionCS: {
          title: `Provide the reason for not reporting ${reportingYear} rental income`,
          description:
            'If no income was collected, NYC requires that the RPIE Statement be filed with a claim of exclusion. Select the applicable exclusion from the drop-down menu.',
        },
      },
    },
    {
      id: RPIEWizardSteps.Parking,
      section: RPIEWizardSections.General,
      flows: ['*'],
      title: 'Provide Parking Info',
      description: asOfJanuary(fiscalYear),
      component: StepPropertyParking,
      dataMap: {
        parking: 'general.parking',
        $onlyTotal: true,
      },
      help: {
        $next: HelpGreatJobNextStep,
        'parking.indoorParkingUses.totalNumberOfParkingSpots': {
          title: '# Of Indoor Parking',
          description:
            'Include parking spaces that are entirely enclosed (i.e. spaces inside an enclosed garage).',
        },
        'parking.outdoorParkingUses.totalNumberOfParkingSpots': {
          title: '# Of Outdoor Parking',
          description:
            'Include parking spaces that are outdoor, even if they are only partially enclosed.',
        },
      },
    },
    {
      id: RPIEWizardSteps.NetLeaseFillTypeSelection,
      section: RPIEWizardSections.General,
      flows: ['*'],
      component: StepFillingTypeSelection,
      title: 'Lease Information',
      description: '',
      hidden: data => !data.general.isPropertyToNetLease,
      dataMap: {
        $completeFillingText: 'Complete Manually',
        $docType: DocumentType.LeaseCopy,
        $questionText: 'How would you like to provide your Net Lease data?',
        fillingType: 'lease.showUploadArea',
        $docName: 'Lease Information',
        $infoText: `You may complete your Net Lease information by using our guided webform, or upload your Net Lease Document.`,
      },
      help: {
        $next: {
          title: 'Upload P&L or Complete Manually',
          description:
            'A net lease refers to a contractual agreement where a lessee pays a portion or all of the taxes, insurance fees, and maintenance costs for a property in addition to rent.',
        },
        manual: {
          title: 'Complete Manually',
          description:
            'Please enter all data associated to the Net Lease manually',
        },
        upload: {
          title: 'Upload Lease Information',
          description:
            'You may upload your documents and MGNY will extract and enter the data',
        },
      },
    },
    {
      id: RPIEWizardSteps.NetLeaseUpload,
      section: RPIEWizardSections.General,
      flows: ['*'],
      component: StepUpload,
      hidden: data =>
        !data.general.isPropertyToNetLease || !data.lease.showUploadArea,
      title: 'Upload Net Lease Document',
      description: `For ${reportingYear}`,
      dataMap: {
        $docName: 'Net Lease',
        $docType: DocumentType.LeaseCopy,
      },
      help: {
        $step: {
          title: 'Upload files',
          description:
            'You may upload your documents and MGNY will extract and enter the data',
        },
        $next: HelpGreatWorkMoveToTheNext,
      },
    },
    {
      id: RPIEWizardSteps.NetLease,
      section: RPIEWizardSections.General,
      flows: ['*'],
      component: StepGeneralNetLeaseQuestions,
      title: 'Lease Information',
      description: () => rangeOfYear(reportingYear),
      hidden: data =>
        !data.general.isPropertyToNetLease || !!data.lease.showUploadArea,
      dataMap: {
        annualRentPaidToOwner: 'lease.annualRentPaidToOwner',
        payUtilityExpenses: 'lease.doesTenantPayUtilityExpenses',
        payRepairExpenses: 'lease.doesTenantPayMaintenanceRepairExpenses',
        paySpaceOccupied: 'lease.doesTenantPayPropertyTaxSpaceOccupied',
        isSubleasingProperty: 'lease.isNetLesseeRelatedPartySubleasingProperty',
        isGroundLessor: 'lease.areYouFillingGroundLessor',
        annualRent: 'lease.annualRent',
        subleasingAreaSF: 'lease.subleasingAreaSF',
        useOfSpaceCS: 'lease.useOfSpaceCS',
        groundLeaseAmount: 'lease.whatGroundLeaseAmountAreReceiving',
      },
      help: {
        isSubleasingProperty: {
          title:
            'Is the net lessee or owner related party subleasing any of the property?',
          description:
            "A sublease is the re-renting of property by an existing tenant to a new third party for a portion of the tenant's existing lease contract. The sublease agreement may also be called a sublet.",
        },
        subleasingAreaSF: {
          title: 'Subleasing Area SF',
          description: 'Enter sub-leased square footage',
        },
        useOfSpaceCS: {
          title: 'Use of Space',
          description: 'How is the leased space being used?',
        },
        annualRent: {
          title: 'Annual Rent',
          description: 'What is the annual rent of the sub-lease',
        },
        isGroundLessor: {
          title: 'Are you filling as ground lessor?',
          description:
            'A ground lease is an agreement between a landowner and a tenant, in which the tenant leases land for a new build. The lessee is the owner of the building only, and is responsible for all the expenses and costs associated to constructing and maintaining a business location on a leased piece of land. The landowner, however, is typically still responsible for the property taxes on the land itself each year.',
        },
        $next: HelpGreatWorkMoveToTheNext,
      },
    },
    {
      id: RPIEWizardSteps.ResidentialDescription,
      section: RPIEWizardSections.General,
      flows: ['*'],
      component: StepGeneralResidentialDescription,
      title: 'Residential Description',
      description: asOfJanuary(fiscalYear),
      hidden: (data, context) => !isProperyNotHotel(context),
      dataMap: {
        numberOfResidentialUnit: 'general.numberOfResidentialUnit',
        numberOfResRegulatedUnit: 'general.numberOfResRegulatedUnit',
        numberOfResUnregulatedUnit: 'general.numberOfResUnregulatedUnit',
        vacantRegulated:
          'vacantOwnerTable.usesResidential.regulatedUnitsVacant',
        vacantUnregulated:
          'vacantOwnerTable.usesResidential.unregulatedUnitsVacant',
        unitsApplicant: 'vacantOwnerTable.usesResidential.unitsApplicant',
        hasSuperUnit: 'general.hasSuperUnit',
      },
      help: {
        $step: HelpWhatToExpect,
        $next: HelpGreatWorkMoveToTheNext,
        numberOfResidentialUnit: {
          title: 'Total # of Residential Units',
          description: 'How many apartments are in the building?',
        },
        numberOfResRegulatedUnit: {
          title: '# of Regulated Units',
          description:
            'How many apartments are subject to Rent Stabilization? (i.e. have regulated rents and are subject to annual rent registrations).',
        },
        numberOfResUnregulatedUnit: {
          title: '# of Unregulated Units',
          description: `How many apartments are free market? Be sure to include all deregulated apartments as of January 5, ${fiscalYear}.`,
        },
        vacantRegulated: {
          title: '# of Regulated Vacant Units',
          description:
            'How many vacancies did you experience within regulated units?',
        },
        vacantUnregulated: {
          title: '# of Unregulated Vacant Units',
          description:
            'How many vacancies did you experience within free market units?',
        },
        unitsApplicant: {
          title: '# of Оwner-occupied Units',
          description:
            'How many apartments are occupied by owner, related party, or super?',
        },
        hasSuperUnit: {
          title: 'Is there a super unit?',
          description:
            'If there is a dedicated superintendent unit in the building, please indicate it here.',
        },
      },
    },
    {
      id: RPIEWizardSteps.CommercialDescription,
      section: RPIEWizardSections.General,
      flows: ['*'],
      component: StepGeneralCommercialDescription,
      title: 'Commercial Description',
      description: asOfJanuary(fiscalYear),
      buttons: getButtonLastYear(
        'Commercial Description',
        RPIEWizardSteps.CommercialDescription
      ),
      dataMap: {
        numberOfCommercialUnit: 'general.numberOfCommercialUnit',
        numberOfStorefrontUnit: 'storefront.totalUnitNumber',
        wasAnyNonResPortionOwnerOccupied:
          'general.wasAnyNonResPortionOwnerOccupied',
        wasAnyNonResPortionVacant: 'general.wasAnyNonResPortionVacant',
        uses: 'vacantOwnerTable.usesNonresidential',
        rentrollUnits: 'rentRollCommercial.units',
        storfrontUnits: 'storefront.units',
      },
      help: {
        $step: HelpWhatToExpect,
        $next: HelpGreatWorkMoveToTheNext,
        numberOfCommercialUnit: {
          title: '# of Commercial Units',
          description:
            'Report ALL non-residential units including community facility. The units can be tenant-occupied, owner-occupied units and vacant units. \n' +
            '\n' +
            'Each storefront unit is considered a separate commercial unit.  For example, if the retail space has four separate tenants, each of these tenancies is considered a unit.',
        },
        numberOfStorefrontUnit: {
          title: '# of Storefront Units',
          description: <HelpNumberStepCommercialDescription />,
        },
        wasAnyNonResPortionOwnerOccupied: {
          title: 'Owner-Occupied',
          description:
            'Owner-occupancy is loosely defined as occupancy by an owner or a related party, business, or entity. Even if the legal property owner is receiving rent from a related tenant, the arrangement is considered owner-occupancy.',
        },
        wasAnyNonResPortionVacant: {
          title: 'Vacant',
          description:
            'Vacancies are very important to report accurately because the City Assessors may take these into account when determining the valuation.',
        },
        sfVacant: {
          title: 'Vacant SF',
          description: <HelpVacantStepCommercialDescription />,
        },
        sfApplicant: {
          title: 'Owner-occupied SF',
          description: <HelpApplicantStepCommercialDescription />,
          details: 'If there were no Owner-occupied SF enter 0.',
        },
        useTableOwnerOccupiedOrRelated: {
          title: 'Owner-occupied SF',
          description: <HelpApplicantStepCommercialDescription />,
          details: 'If there were no Owner-occupied SF enter 0.',
        },
        useTableVacant: {
          title: 'Vacant SF',
          description: <HelpVacantStepCommercialDescription />,
        },
      },
    },
    {
      id: RPIEWizardSteps.HotelOperation,
      flows: [PropertyType.Hotel],
      section: RPIEWizardSections.General,
      title: 'Hotel Operation Information',
      component: StepHotelOperationInformation,
      hidden: hasExclusion,
      description: asOfJanuary(fiscalYear),
      dataMap: {
        applicantName: 'general.applicant.name',
        hotelName: 'hotel.hotelName',
        totalRoomsNumber: 'hotel.totalRoomsNumber',
        keysNumber: 'hotel.keysNumber',
        transientRoomsNumber: 'hotel.transientRoomsNumber',
        permanentRoomsNumber: 'hotel.permanentRoomsNumber',
        occupancyRate: 'hotel.occupancyRate',
        $additionalHidden: true,
      },
      help: {
        hotelName: {
          title: 'Hotel Name',
          description:
            'Indicate the brand name of the hotel or franchise name.',
        },
        totalRoomsNumber: {
          title: '# of All Rooms',
          description: 'Provide the total number of rooms in the hotel.',
        },
        keysNumber: {
          title: '# of Keys',
          description:
            'For number of keys, list the total number of hotel keys available. For example, a 2-room suite counts as 1 key.',
        },
        transientRoomsNumber: {
          title: '# of Transient Rooms',
          description:
            'List the number of rooms that are available to be rented out nightly.',
        },
        permanentRoomsNumber: {
          title: '# of Permanent Rooms',
          description:
            'List the number of rooms that have permanent or long-term tenants.',
        },
        occupancyRate: {
          title: `Average Occupancy Rate for ${reportingYear} (%)`,
          description: 'Provide the average occupancy rate for the year. ',
        },
      },
    },
    {
      id: RPIEWizardSteps.HotelRoomRates,
      flows: [PropertyType.Hotel],
      section: RPIEWizardSections.General,
      component: StepRoomRates,
      hidden: (data, context) =>
        true || // exclude this step for now
        hasExclusion(data) || !data.hotel.totalRoomsNumber,
      title: 'Room Rates',
      description: asOfJanuary(fiscalYear),
      dataMap: {
        roomRates: 'hotel.roomRates',
        totalRoomsNumber: 'hotel.totalRoomsNumber',
      },
      help: {
        $step: {
          title: 'Room Rates',
          description: `Provide the relevant information for each room type and rate as of January 5, ${fiscalYear}.`,
        },
      },
    },
    // I&E

    {
      id: RPIEWizardSteps.ReportingPeriod,
      section: RPIEWizardSections.IncomeExpense,
      flows: ['*'],
      component: StepReportingPeriod,
      hidden: hideIE,
      title: 'Income Schedule',
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        startDate: 'income.reportingPeriod.startDate',
        endDate: 'income.reportingPeriod.endDate',
        accountingBasisCS: 'income.reportingPeriod.accountingBasisCS',
        reportingPeriodTypeCS: 'income.reportingPeriod.reportingPeriodTypeCS',
        hasCustomPeriod: 'income.reportingPeriod.hasCustomPeriod',
      },
      help: {
        $step: {
          title: 'What to Expect',
          description: <HelpStepReportingPeriod year={reportingYear} />,
        },
        isPeriodCorrect: {
          title: `Is the reporting period of ${fiscalYear} correct?`,
          description:
            'The reporting period typically represents a 12-month period for the last full calendar year. Sometimes, the reporting period is less than 12-months, when the operation of the property began mid-year. Occasionally, the reporting period follows an alternative reporting convention/fiscal year (e.g. April 1st - March 31st)',
        },
        hasCustomPeriod: {
          title: 'Is the Reporting Period correct?',
          description:
            'The reporting period typically represents a 12-month period for the last full calendar year. Sometimes, the reporting period is less than 12-months, when the operation of the property began mid-year. Occasionally, the reporting period follows an alternative reporting convention - like April 1st - March 31st.',
        },
        reportingPeriodStart: {
          title: 'Start of Reporting Period.',
          description: 'Provide the updated reporting period.',
        },
        reportingPeriodEnd: {
          title: 'End of Reporting Period.',
          description: 'Provide the updated reporting period.',
        },
      },
    },
    {
      id: RPIEWizardSteps.IncomeFillTypeSelection,
      section: RPIEWizardSections.IncomeExpense,
      flows: ['*'],
      component: StepFillingTypeSelection,
      hidden: hideIE,
      title: 'Income & Expense',
      description: '',
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        $completeFillingText: 'Complete Manually',
        $docType: DocumentType.Income,
        $questionText:
          'How would you like to provide your income and expense information?',
        fillingType: 'income.showUploadArea',
        $docName: 'P&L',
        $infoText: `You may complete your income and expense information by using our guided webform, or upload your Annual ${reportingYear} Profit and Loss Statement.`,
      },
      help: {
        $step: {
          title: 'What to Expect',
          description: <HelpStepReportingPeriod year={reportingYear} />,
        },
        $next: {
          title: 'Upload P&L or Complete Manually',
          description: <HelpNextStepFillingTypeSelection fileName={'P&L'} />,
        },
      },
    },
    {
      id: RPIEWizardSteps.IncomeUpload,
      section: RPIEWizardSections.IncomeExpense,
      flows: ['*'],
      component: StepUpload,
      hidden: hideIEUpload,
      title: 'Income & Expense',
      description: `For ${reportingYear}`,
      dataMap: {
        $docName: `Income and Expense Information`,
        $docType: DocumentType.Income,
        $fileFormatsMessage: <ExcelFileFormatMessage />,
      },
      help: {
        $next: HelpGreatWorkMoveToTheNext,
        $step: {
          title: 'Upload files',
          description:
            'Upload the P&L for the specified reporting period.' +
            ' If non-standard reporting period was selected, be sure that the P&L matches that period. ' +
            'You may upload more than one file.',
        },
      },
    },
    {
      id: RPIEWizardSteps.Income,
      section: RPIEWizardSections.IncomeExpense,
      flows: ['*'],
      component: StepIncomeTable,
      hidden: hideIEFill,
      title: 'Income',
      description: repYearDescrReal,
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        numberOfResRegulatedUnit: 'general.numberOfResRegulatedUnit',
        numberOfResUnregulatedUnit: 'general.numberOfResUnregulatedUnit',
        vacantOwnerTable: 'vacantOwnerTable',
        sections: 'income.sections',
      },
      help: {
        annualVal: {
          description:
            'Provide income amount collected from each income source during the reporting period.',
        },
        addCategoryBtn: {
          description:
            'If you collected income from other sources at this property, you may add other income category.',
        },
        categories: {
          title: 'Categories',
          description:
            'Provide income amount collected from each income source during the reporting period.',
        },
        $next: HelpGreatWorkMoveToTheNext,
      },
    },
    {
      id: RPIEWizardSteps.Expense,
      section: RPIEWizardSections.IncomeExpense,
      flows: ['*'],
      component: StepExpenseTable,
      hidden: hideIEFill,
      title: 'Expenses',
      description: repYearDescrReal,
      infoBlock: consolidatedLotsInfo(),
      dataMap: {
        sections: 'expense.sections',
      },
      help: {
        $next: HelpGreatWorkMoveToTheNext,
        CS_EXPENSE_CATEGORY_MN_FUEL: {
          title: 'Fuel',
          description:
            'Amount paid or incurred for fuel oil, gas or steam, including gas provided to tenants.',
        },
        CS_EXPENSE_CATEGORY_MN_LIGHT: {
          title: 'Light and Power',
          description:
            'Amount paid for electricity, including electricity provided to tenants. Do not include electricity consumed by the owner or other filer(s) for personal or business use.',
        },
        CS_EXPENSE_CATEGORY_MN_WATER: {
          title: 'Water and sewer',
          description:
            'Amount paid or incurred for water and sewer frontage or usage.',
        },
        CS_EXPENSE_CATEGORY_MN_CLEANING: {
          title: 'Cleaning contracts',
          description:
            'Amount paid or incurred for contracts with cleaning-service companies or individual cleaners. ',
        },
        CS_EXPENSE_CATEGORY_MN_WAGES: {
          title: 'Wages and payroll',
          description:
            'Amount paid or incurred for all wages, related payroll taxes and employee benefits for building maintenance employees who work at the property. Do not include salaries of employees who work in any off-site management office.',
        },
        CS_EXPENSE_CATEGORY_MN_REPAIRS: {
          title: 'Repairs and maintenance',
          description:
            'Amounts paid or incurred for maintenance and repairs of the property (interior and exterior). Include any amounts that were paid for routine repair services and for material or parts used for repairs. Do not include reserves for replacements.',
        },
        CS_EXPENSE_CATEGORY_MN_MANAGEMENT: {
          title: 'Management and administration',
          description:
            'Amount paid or incurred for management. Include office expenses and legal/accounting services related to the operation of the property.',
        },
        CS_EXPENSE_CATEGORY_MN_INSURANCE: {
          title: 'Insurance (annual)',
          description:
            'Amount paid or incurred for fire, liability, and other insurance premiums paid to protect the real property. Pro-rate multi-year premiums to calculate an average annual expense. ',
        },
        CS_EXPENSE_CATEGORY_MN_ADVERTISING: {
          title: 'Advertising',
          description:
            'Amount paid or incurred for advertising space available for rent.',
        },
        CS_EXPENSE_CATEGORY_MN_INTERIOR: {
          title: 'Interior painting and decorating',
          description:
            'Amount paid or incurred for contract services and materials for interior painting and interior decoration. ',
        },
        CS_EXPENSE_CATEGORY_MN_LEASING: {
          title: 'Amortized Leasing Costs',
          description:
            'Amount paid or incurred for brokers’ commissions. If the lease is for more than one year, the total broker’s commission must be prorated to calculate the annual expense. ',
        },
        CS_EXPENSE_CATEGORY_MN_TENANT: {
          title: 'Amortized Tenant Improvement Costs',
          description:
            'Amount paid or incurred for tenant work done by the landlord. If the lease is for more than one year, the total tenant improvement costs must be pro-rated to calculate the annual expense. ',
        },
        CS_MISC_EXPENSE_CATEGORY_LEASE_BUY_OUT: {
          title: 'Lease buy-out',
          description:
            'Amount paid or incurred for costs associated with terminating a lease. This includes the payment to the agreeing party and all associated costs, such as legal fees and loss of rental income.',
        },
        CS_MISC_EXPENSE_CATEGORY_SPECIAL_ASSESSM: {
          title: 'Special assessments',
          description:
            'Amount paid or incurred for a unique charge that government units can assess against real estate parcels for certain public projects. This charge is levied in a specific geographic area known as a special assessment district (SAD).',
        },
        CS_MISC_EXPENSE_CATEGORY_SUNDRY: {
          title: 'Sundry',
          description:
            'Amount paid or incurred for small things which can not be categorized under a specific heading. They are usually infrequent, considerably low, and miscellaneous in nature. ',
        },
        CS_MISC_EXPENSE_CATEGORY_PETTY_CASH: {
          title: 'Petty Cash',
          description:
            'Amount paid in cash for small expenses without writing a check.',
        },
        CS_EXPENSE_CATEGORY_NONDD_RE_TAXES: {
          title: 'Real Estate Taxes  (optional)',
          description:
            'Amount paid or incurred for real estate taxes for the entire reporting period.',
        },
        CS_EXPENSE_CATEGORY_NONDD_BAD_DEBT: {
          title: 'Bad Debt (optional)',
          description: 'Optional. Do not need to provide!',
        },
        CS_EXPENSE_CATEGORY_NONDD_DEPRECIATION: {
          title: 'Depreciation (optional)',
          description: 'Optional. Do not need to provide!',
        },
        CS_EXPENSE_CATEGORY_NONDD_MORTGAGE: {
          title: 'Mortgage Interest (optional)',
          description: 'Optional. Do not need to provide!',
        },
      },
    },
    {
      id: RPIEWizardSteps.HotelFurnitureFixturesAndEquipment,
      section: RPIEWizardSections.IncomeExpense,
      flows: [PropertyType.Hotel],
      component: StepFurnitureFixturesAndEquipment,
      hidden: hideIE,
      title: 'Furniture, Fixtures and Equipment',
      dataMap: {
        isThereFurniture: 'hotel.furniture.isThereFurniture',
        furniture: 'hotel.furniture.items',
      },
      help: {
        $step: {
          title: 'Furniture, Fixtures and Equipment',
          description:
            'Provide information about Furniture, Fixtures and Equipment.',
        },
        isThereFurniture: {
          title: 'Provide information about FFE',
          description:
            'Answer Yes, if there is a reserve for replacement of movable furniture, fixtures or other equipment that have no permanent connection to the structure of the building or utilities',
        },
        $next: HelpGreatJobNextStep,
      },
    },

    // SF
    (data: RpieFormData, context: any) => {
      const result = !data.storefront.units
        ? []
        : data.storefront.units
          .map((unit, unitIndex) => {
            const storefrontUnitOccupantContactInfo =
              RPIEWizardSteps.StorefrontUnitOccupantContactInfo,
              storefrontUnitOccupantRentInfo =
                RPIEWizardSteps.StorefrontUnitOccupantRentInfo,
              storefrontUnitOccupantLeaseConcessions =
                RPIEWizardSteps.StorefrontUnitOccupantLeaseConcessions

            return [
              {
                id: RPIEWizardSteps.StorefrontGeneralInfo1.concat(
                  String(unitIndex)
                ),
                buttons: getButtonLastYear(
                  'Storefront',
                  RPIEWizardSteps.StorefrontGeneralInfo1.concat('0')
                ),
                section: RPIEWizardSections.Storefront,
                flows: ['*'],
                component: StepStorefrontUnitGeneral1Step,
                title: 'General Info',
                description: `Unit #${unitIndex + 1}`,
                dataMap: {
                  allUnits: 'storefront.units',
                  unitApartmentNumber: [
                    'storefront',
                    'units',
                    unitIndex,
                    'unitApartmentNumber',
                  ],
                  isAddressDifferent: [
                    'storefront',
                    'units',
                    unitIndex,
                    'isAddressDifferent',
                  ],
                  isUnitApartmentNumberDifferent: [
                    'storefront',
                    'units',
                    unitIndex,
                    'isUnitApartmentNumberDifferent',
                  ],
                  streetName: [
                    'storefront',
                    'units',
                    unitIndex,
                    'streetName',
                  ],
                  houseNo: ['storefront', 'units', unitIndex, 'houseNo'],
                  $unitNumber: unitIndex,
                },
                help: {
                  $next: {
                    title: 'What to Expect',
                    description: HelpNextStepSFGeneral1,
                  },
                  isUnitApartmentNumberDifferent: {
                    title: 'Does this unit have a specific unit designation?',
                    description:
                      'If there is a specific unit number (e.g. Store 1, COMM1, A1, etc.) that you use in your accounting/management system or in the lease, respond Yes and provide the unit number.',
                  },
                  unitApartmentNumber: {
                    title: 'Unit designation',
                    description:
                      'Provide the unit number (e.g. Store 1, COMM1, A1, etc.)',
                  },
                  isAddressDifferent: {
                    title:
                      'Does this unit have a different address than above?',
                    description:
                      'If the address differs from the main address of the property (e.g. AKA address or designated address for the unit in a range of addresses for the building), respond Yes and specify the house number and street name.',
                  },
                  houseNo: {
                    title: 'House number',
                    description:
                      'Provide the house number (e.g. AKA address or designated address for the storefront unit in a range of addresses for the building).',
                  },
                  streetName: {
                    title: 'Street Name',
                    description:
                      'Provide the street name (e.g. AKA address or designated address for the storefront unit in a range of addresses for the building).',
                  },
                },
              },
              {
                id: RPIEWizardSteps.StorefrontGeneralInfo2.concat(
                  String(unitIndex)
                ),
                buttons: getButtonLastYear(
                  'Storefront',
                  RPIEWizardSteps.StorefrontGeneralInfo1.concat('0')
                ),
                section: RPIEWizardSections.Storefront,
                flows: ['*'],
                component: StepStorefrontUnitGeneral2Step,
                title: 'General Info',
                description: getDescription(
                  unit,
                  unitIndex,
                  descriptionObject,
                  unit.isUnitApartmentNumberDifferent,
                  unit.isAddressDifferent
                ),
                dataMap: {
                  $unitNumber: unitIndex,
                  descriptionTypeCS: [
                    'storefront',
                    'units',
                    unitIndex,
                    'descriptionTypeCS',
                  ],
                  floorSizeSF: [
                    'storefront',
                    'units',
                    unitIndex,
                    'floorSizeSF',
                  ],
                },
                help: {
                  $next: HelpGreatWorkMoveToTheNext,
                  descriptionTypeCS: {
                    title: 'Unit Description',
                    description:
                      'Provide the location of the unit within the building.',
                  },
                  floorSizeSF: {
                    title: 'Floor area for Commercial Unit #',
                    description: HelpFloorSizeStepSFGeneral2,
                  },
                },
              },
              {
                id: RPIEWizardSteps.StorefrontUnitOccupancyIntervals.concat(
                  String(unitIndex)
                ),
                section: RPIEWizardSections.Storefront,
                flows: ['*'],
                component: StepStorefrontUnitOccupancyIntervalsStep,
                title: `Occupancy Intervals`,
                buttons: getButtonLastYear(
                  'Storefront',
                  RPIEWizardSteps.StorefrontGeneralInfo1.concat('0')
                ),
                description: getDescription(
                  unit,
                  unitIndex,
                  descriptionObject,
                  unit.isUnitApartmentNumberDifferent,
                  unit.isAddressDifferent
                ),
                dataMap: {
                  $unitNumber: unitIndex,
                  occupants: ['storefront', 'units', unitIndex, 'occupants'],
                },
                help: {
                  $step: {
                    title: 'Occupancy intervals',
                    description: HelpStepSFIntervals,
                  },
                  // $next: HelpSFGreatWorkMoveToTheNext,
                  occupantTypeCS: {
                    title: 'Occupancy Type',
                    description: HelpStepSFIntervals,
                  },
                  startDate: {
                    title: 'Date range # of occupancy',
                    description:
                      'Select the start date and end date of the specified Occupancy Type. More than one occupancy interval can be added.',
                  },
                  endDate: {
                    title: 'End Date',
                    description: HelpEndDateStepSFIntervals,
                  },
                  confirmRange: {
                    title: 'Confirm Range #',
                    description:
                      "Click Add Interval after you've confirmed that the occupancy type and the occupancy period for that type is correct.",
                  },
                  hadConstruction: {
                    title: 'Construction Activities',
                    description:
                      'If the vacancy was due to construction activities, check this box.',
                  },
                },
              },
              // @ts-ignore
              ...(unit.occupants || [])
                .map((occupant, occupantIndex) => {
                  const result: StepsConfig = []
                  if (
                    occupant.occupantTypeCS ===
                    StorefrontUnitOccupancyType.Owner ||
                    occupant.occupantTypeCS ===
                    StorefrontUnitOccupancyType.Tenant
                  ) {
                    result.push({
                      id: storefrontUnitOccupantContactInfo
                        .concat(String(unitIndex))
                        .concat(String(occupantIndex)),
                      section: RPIEWizardSections.Storefront,
                      flows: ['*'],
                      component:
                        StepStorefrontUnitOccupantContactInformationStep,
                      title: `${occupant.occupantTypeCS ===
                        StorefrontUnitOccupancyType.Tenant
                        ? 'Tenant'
                        : 'Owner'
                        } Contact Information`,
                      description: getDescription(
                        unit,
                        unitIndex,
                        descriptionObject,
                        unit.isUnitApartmentNumberDifferent,
                        unit.isAddressDifferent
                      ),
                      dataMap: {
                        $unitNumber: unitIndex,
                        $occupantNumber: occupantIndex,
                        businessName: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'businessName',
                        ],
                        // user can omit contact info only for tenant
                        $canOmitContactInformation:
                          occupant.occupantTypeCS ===
                          StorefrontUnitOccupancyType.Tenant,
                        isContactInformationUnavailable: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'isContactInformationUnavailable',
                        ],
                        contactName: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'contactName',
                        ],
                        emailAddress: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'emailAddress',
                        ],
                        primaryPhone: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'primaryPhone',
                        ],
                        alternativePhone: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'alternativePhone',
                        ],
                        primaryBusinessActivityCS: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'primaryBusinessActivityCS',
                        ],
                        otherDescriptionOfBA: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'otherDescriptionOfBA',
                        ],
                        //TODO: remove _ from key name
                        $_occStartDate: occupant.startDate,
                        $_occEndDate: occupant.endDate,
                        $_occLabel: getDictiName(occupant.occupantTypeCS),
                        $occupants: unit.occupants,
                      },
                      help: {
                        $step: {
                          title:
                            occupant.occupantTypeCS ===
                              StorefrontUnitOccupancyType.Tenant
                              ? 'Tenant Contact Information'
                              : 'Owner Contact Information',
                          description:
                            occupant.occupantTypeCS ===
                              StorefrontUnitOccupancyType.Tenant ? (
                              <HelpStepSFInfo />
                            ) : (
                              'Enter contact information for the owner/related party.'
                            ),
                        },
                        $next: HelpGreatWorkMoveToTheNext,
                        otherDescriptionOfBA: {
                          title: 'Other Business Activity',
                          description: 'Describe other business activity.',
                        },
                        businessName: {
                          title: 'Business Name',
                          description:
                            'If there is a business name for the storefront operator or a sign identifying the storefront,' +
                            'provide the name here (i.e. Express Store, 5th Avenue Deli, ' +
                            ' Greenlight Bookstore, Somner Law Firm).',
                        },
                      },
                    })
                  }
                  if (
                    occupant.occupantTypeCS ===
                    StorefrontUnitOccupancyType.Tenant
                  ) {
                    result.push({
                      id: storefrontUnitOccupantRentInfo
                        .concat(String(unitIndex))
                        .concat(String(occupantIndex)),
                      section: RPIEWizardSections.Storefront,
                      flows: ['*'],
                      component:
                        StepStorefrontUnitOccupantRentInformationStep,
                      title: 'Rent Information',
                      description: getDescription(
                        unit,
                        unitIndex,
                        descriptionObject,
                        unit.isUnitApartmentNumberDifferent,
                        unit.isAddressDifferent
                      ),
                      dataMap: {
                        $unitNumber: unitIndex,
                        $occupantNumber: occupantIndex,
                        grossRentAmount: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'grossRentAmount',
                        ],
                        baseRentAmount: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'baseRentAmount',
                        ],
                        $grossRentAmountRequired: grossRentNeeded(
                          data,
                          context
                        ),
                        $occupantTypeCS: occupant.occupantTypeCS,

                        $_occStartDate: occupant.startDate,
                        $_occEndDate: occupant.endDate,
                        $_occLabel: getDictiName(occupant.occupantTypeCS),
                        $occupants: unit.occupants,
                      },
                      help: {
                        $next: HelpGreatWorkMoveToTheNext,
                        grossRentAmount: {
                          title:
                            'Annual gross rent paid during the entire period',
                          description:
                            'Enter the annual gross rent amount paid by the tenant. INCLUDE any tax escalations and operating escalations (utility and maintenance reimbursements, as well as all other reimbursements payable from tenant to landlord)',
                        },
                        baseRentAmount: {
                          title:
                            'Annual base rent paid during the entire period',
                          description:
                            'Enter the base rent amount. DO NOT INCLUDE any escalations or reimbursements. If base rent and gross rent are the same, enter the same number in both fields.',
                        },
                      },
                    })
                    result.push({
                      id: storefrontUnitOccupantLeaseConcessions
                        .concat(String(unitIndex))
                        .concat(String(occupantIndex)),
                      section: RPIEWizardSections.Storefront,
                      flows: ['*'],
                      component:
                        StepStorefrontUnitOccupantLeaseConcessionsStep,
                      title: 'Lease Concessions',
                      description: getDescription(
                        unit,
                        unitIndex,
                        descriptionObject,
                        unit.isUnitApartmentNumberDifferent,
                        unit.isAddressDifferent
                      ),
                      dataMap: {
                        $unitNumber: unitIndex,
                        $occupantNumber: occupantIndex,
                        hasLeaseInformation: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'hasLeaseInformation',
                        ],
                        existLeaseInformationCS: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'existLeaseInformationCS',
                        ],
                        monthNumber: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'monthNumber',
                        ],
                        reducedRentAmount: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'reducedRentAmount',
                        ],
                        improvementCashAmount: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'improvementCashAmount',
                        ],
                        otherDescriptionOfConcession: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'otherDescriptionOfConcession',
                        ],
                        isIncreaseThisYear: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'isIncreaseThisYear',
                        ],
                        isIncreaseAfterThisYear: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'isIncreaseAfterThisYear',
                        ],
                        isOccupiedByTenantOn1231: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'noVacantType',
                          'isOccupiedByTenantOn1231',
                        ],
                        $_occStartDate: occupant.startDate,
                        $_occEndDate: occupant.endDate,
                        $_occLabel: getDictiName(occupant.occupantTypeCS),
                        $occupants: unit.occupants,
                      },
                      help: {
                        $next: HelpGreatWorkMoveToTheNext,
                        hasLeaseInformation: {
                          title: 'Were there any lease concessions?',
                          description:
                            'If tenant was provided any incentives, discounts or free months of rent please select the ones that apply.',
                        },
                        // existLeaseInformation: {
                        //   title: 'Choose lease concessions',
                        //   description:
                        //     'If tenant was provided any incentives, please select the ones that apply.',
                        // },
                        monthNumber: {
                          title: 'Number of Months Rent-Free',
                          description:
                            'How many months of free rent did tenant get?',
                        },
                        reducedRentAmount: {
                          title:
                            'Reduced Starting Rent or Rent Abatement Amount',
                          description: 'Enter reduced starting rent amount.',
                        },
                        improvementCashAmount: {
                          title: 'Improvement or Cash Allowance Amount',
                          description:
                            'Enter the value of improvements or cash allowance amount paid for by landlord as an incentive to tenant.',
                        },
                        otherDescriptionOfConcession: {
                          title: 'Other',
                          description:
                            'If there were any other incentives, please explain.',
                        },
                        isIncreaseThisYear: {
                          title:
                            "Is the tenant's rent scheduled to increase this year?",
                          description: `Answer Yes, if there are scheduled increases in ${fiscalYear}. If not, answer No.`,
                        },
                        isIncreaseAfterThisYear: {
                          title:
                            "Do the tenant's lease terms provide scheduled rent increases after this year?",
                          description: `Answer Yes, if there are scheduled increases applicable for ${nextFiscalYear} and beyond. If not, answer No.`,
                        },
                      },
                    })
                  }
                  if (
                    occupant.occupantTypeCS ===
                    StorefrontUnitOccupancyType.Vacant &&
                    occupant.vacantType.hadConstruction
                  ) {
                    result.push({
                      id: RPIEWizardSteps.StorefrontUnitOccupantDOB.concat(
                        String(unitIndex)
                      ).concat(String(occupantIndex)),
                      section: RPIEWizardSections.Storefront,
                      flows: ['*'],
                      component: StepStorefrontUnitOccupantDOBJobsStep,
                      title: 'DOB Jobs',
                      description: getDescription(
                        unit,
                        unitIndex,
                        descriptionObject,
                        unit.isUnitApartmentNumberDifferent,
                        unit.isAddressDifferent
                      ),
                      dataMap: {
                        $unitNumber: unitIndex,
                        $occupantNumber: occupantIndex,
                        hadConstruction: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'vacantType',
                          'hadConstruction',
                        ],
                        constructionJobs: [
                          'storefront',
                          'units',
                          unitIndex,
                          'occupants',
                          occupantIndex,
                          'vacantType',
                          'constructionJobs',
                        ],
                        $_occStartDate: occupant.startDate,
                        $_occEndDate: occupant.endDate,
                        $_occLabel: getDictiName(occupant.occupantTypeCS),
                        $occupants: unit.occupants,
                      },
                      help: {
                        $next: HelpGreatWorkMoveToTheNext,
                        jobNumber: {
                          title: 'Job #',
                          description:
                            'Provide the DOB job number and other details for construction work that resulted in storefront vacancy.',
                        },
                        dateRange: {
                          title: 'Date range',
                          description:
                            'Indicate the start date and end date of the construction job. The end date can be the projected completion date in the future.',
                        },
                        projectStartDate: {
                          title: 'Start Date',
                          description:
                            'Indicate the start date and end date of the construction job. The end date can be the projected completion date in the future.',
                        },
                      },
                    })
                  }
                  return result
                })
                .flat(99),
            ]
          })
          .flat(3)
      // @ts-ignore
      return result
    },

    // RR com
    {
      id: RPIEWizardSteps.RentRollCommercialFillTypeSelection,
      section: RPIEWizardSections.RentRollCommercial,
      flows: ['*'],
      component: StepFillingTypeSelection,
      title: 'Commercial Rent Roll',
      description: repYearDescrReal,
      hidden: (data, context) => !rrComNeeded(data, context),
      dataMap: {
        fillingType: 'rentRollCommercial.showUploadArea',
        $docName: 'Commercial Rent Roll',
        $questionText:
          'How would you like to provide your commercial rent roll data?',
        $docType: DocumentType.RPIERentRollCommercial,
      },

      help: {
        $next: {
          title: 'Commercial Rent Roll',
          description: <HelpRRStepFillingTypeSelection />,
        },
        $step: {
          title: 'Commercial Rent Roll',
          description: <HelpRRStepFillingTypeSelection />,
        },
        upload: {
          title: 'Upload Commercial Rent Roll',
          description: (
            <HelpNextStepFillingTypeSelection
              fileName={'Commercial Rent Roll'}
            />
          ),
        },
        manual: {
          title: 'Complete Manually',
          description: (
            <HelpNextStepFillingTypeSelection
              fileName={'Commercial Rent Roll'}
            />
          ),
        },
      },
    },
    {
      id: RPIEWizardSteps.RentRollCommercialUpload,
      section: RPIEWizardSections.RentRollCommercial,
      flows: ['*'],
      component: StepUpload,
      title: 'Commercial Rent Roll',
      description: repYearDescrReal,
      hidden: (data, context) =>
        !data.rentRollCommercial.showUploadArea || !rrComNeeded(data, context),
      dataMap: {
        $docName: 'Commercial Rent Roll',
        $docType: DocumentType.RPIERentRollCommercial,
        $fileFormatsMessage: <ExcelFileFormatMessage />,
      },
      help: {
        $step: {
          title: 'Commercial Rent Roll',
          description: <HelpRRStepFillingTypeSelection />,
        },
      },
    },
    {
      id: RPIEWizardSteps.RentRollCommercial,
      section: RPIEWizardSections.RentRollCommercial,
      flows: ['*'],
      component: StepRentRollCommercialTable,
      hidden: (data, context) =>
        !rrComNeeded(data, context) || data.rentRollCommercial.showUploadArea,
      title: 'Commercial Rent Roll (Non-Storefronts)',
      description: repYearDescrReal,
      buttons: getButtonLastYear(
        'Commercial Rent Roll',
        RPIEWizardSteps.RentRollCommercial
      ),
      dataMap: {
        units: 'rentRollCommercial.units',
      },
      help: {
        // $step: {
        //   title: 'What to Expect',
        //   description: HelpStepRentRollCommercialTable,
        // },
      },
    },

    // RR res
    {
      id: RPIEWizardSteps.RentRollResidentialFillTypeSelection,
      section: RPIEWizardSections.RentRollResidential,
      flows: ['*'],
      component: StepFillingTypeSelection,
      hidden: (data, context) => !rrResNeeded(data, context),
      title: 'Residential Rent Roll',
      dataMap: {
        fillingType: 'rentRollResidential.showUploadArea',
        $docName: 'Rent Roll',
        $questionText:
          'How would you like to provide your residential rent roll data?',
        $docType: DocumentType.ResRentRoll,
      },

      help: {
        $next: {
          title: 'What to Expect',
          description: <HelpResRRStepFillingTypeSelection />,
        },
        upload: {
          title: 'Upload Rent Roll or Complete Manually',
          description: (
            <HelpNextStepFillingTypeSelection fileName={'Rent Roll'} />
          ),
        },
      },
    },
    {
      id: RPIEWizardSteps.RentRollResidentialUpload,
      section: RPIEWizardSections.RentRollResidential,
      flows: ['*'],
      component: StepUpload,
      title: 'Residential Rent Roll',
      description: asOfJanuary(fiscalYear),
      hidden: (data, context) =>
        !data.rentRollResidential.showUploadArea || !rrResNeeded(data, context),
      dataMap: {
        $docName: 'Monthly Residential Rent Roll',
        $docType: DocumentType.ResRentRoll,
        $fileFormatsMessage: <ExcelFileFormatMessage />,
      },
      help: {
        $next: HelpGreatWorkMoveToTheNext,
        $step: {
          title: 'Upload files',
          description: 'You may upload more than 1 file.',
        },
      },
    },
    {
      id: RPIEWizardSteps.RentRollResidential,
      section: RPIEWizardSections.RentRollResidential,
      flows: ['*'],
      component: StepRentRollResidentialTable,
      buttons: getButtonLastYear(
        'Residential Rent Roll',
        RPIEWizardSteps.RentRollResidential
      ),
      hidden: (data, context) =>
        !!data.rentRollResidential.showUploadArea ||
        !rrResNeeded(data, context),
      hideHelp: true,
      title: 'Residential Rent Roll',
      description: asOfJanuary(fiscalYear),
      dataMap: {
        units: 'rentRollResidential.units',
        numberOfResRegulatedUnit: 'general.numberOfResRegulatedUnit',
      },
      help: {
        $next: HelpGreatWorkMoveToTheNext,
        $step: {
          description:
            'Some of the information in this section may be pre-filled based on your responses. Please enter relevant responses in each of the fields.' +
            'We understand this is a lot of information! However, the City requires answers to each of these questions.',
        },
      },
    },

    // SUBMIT
    {
      id: RPIEWizardSteps.Summary,
      section: RPIEWizardSections.ReviewAndSubmit,
      flows: ['*'],
      title: 'Summary Page',
      component: StepSummary,
      hideHelp: true,
      actions: <PrintPageButton>Print Summary Page</PrintPageButton>,
      dataMap: {},
      help: {
        $next: {
          title: 'Summary page',
          description: 'Please review this summary before submitting the data.',
        },
      },
    },
    {
      id: RPIEWizardSteps.Submit,
      section: RPIEWizardSections.ReviewAndSubmit,
      flows: ['*'],
      title: 'Certification & Submission',
      component: StepSubmit,
      hideHelp: true,
      nextStepOptions: () => ({
        nextButtonTitle: () => 'Submit',
        onBefore: () => ({
          action: CUSTOM_WIZARD_ACTION_RPIE_FULL_SUBMIT,
        }),
      }),
      dataMap: {
        certified: '$certified',
      },
      help: {
        $step: {
          description: "You're almost there!",
        },
      },
    },
    // {
    //   id: RPIEWizardSteps.Finish,
    //   section: RPIEWizardSections.ReviewAndSubmit,
    //   flows: ['*'],
    //   title: '',
    //   component: () => <Navigate to={URL_SERVICERPIELIST} />,
    //   hideHelp: true,
    // },
  ]
}

const rpieWizardConfig: WizardConfig = {
  assistant: {
    title: 'RPIE Assistant',
  },
  flowFilterValue: 'bblsData.propertyTypeCS',
  steps: rpieWizardStepsConfig as StepsConfig,
}

export default rpieWizardConfig
