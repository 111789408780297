import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpNextStepFillingTypeSelectionProps = {
  fileName: string
} & BaseComponentProps

/**
 * HelpNextStepFillingTypeSelection component
 */
const HelpNextStepFillingTypeSelection = ({
  fileName,
}: HelpNextStepFillingTypeSelectionProps) => {
  return (
    <p>
      You may choose to upload your {fileName} file OR complete data manually on
      the portal (the <b>Manual Option</b> tends to be more accurate).
    </p>
  )
}

export default HelpNextStepFillingTypeSelection
