import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

const furnitureItem = {
  lastYearAmount: Joi.when('/isThereFurniture', {
    is: true,
    then: schemaRules.NOT_EMPTY_NUMBER,
    otherwise: null,
  }),
}

const schema = schemaWrapper({
  isThereFurniture: schemaRules.BOOLEAN,
  furniture: schemaRules.ITEMS(furnitureItem),
})

export default schema
