import { useMemo } from 'react'
import navigation, { Navigation } from 'routing/navigation'
import usePermission from './usePermission'
import { Permission } from 'role-acl'
import { Config } from 'helpers/config/utils'
import { useMyDocuments } from 'hooks/api/my/MyDocumentsContext'
import { DocumentStatus } from 'constants/dicti'

function useNavigation(config: Config): Navigation {
  const permissions = usePermission(),
    { data: documents } = useMyDocuments(),
    hasNewDocs = useMemo(
      () =>
        documents?.dc?.some(dx =>
          dx.documents.some(d => {
            return d.statusCS === DocumentStatus.DownloadNow
          })
        ) || false,
      [documents]
    ),
    navigationResult = useMemo(
      () =>
        navigation(config, hasNewDocs)
          .map(group => {
            const children = group.children.filter(item => {
              const perm = permissions
                .sync()
                .execute(item.action)
                .on(item.resource) as Permission

              return perm.granted && !item.hidden
            })

            return {
              ...group,
              children,
            }
          })
          .filter(g => g.children.length),
      [config, hasNewDocs, permissions]
    )

  return navigationResult
}

export default useNavigation
