import React, { memo, useMemo, ReactNode, useCallback } from 'react'
import { get } from 'object-path-immutable'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { TaFormData } from 'hooks/api/ta/TaFormData'

import { AnyFunction, getCallback, isNullOrWhiteSpace } from 'helpers/utils'
import isEqual from 'lodash/isEqual'

import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import Table from 'ui-framework/components/patterns/Table'
import { Flex, Divider, chakra, HStack } from '@chakra-ui/react'
import UsesAndFloorAreasTable from './UsesAndFloorAreasTable'
import Button from 'ui-framework/components/primitives/Button'
import Empty from 'ui-framework/components/primitives/Empty'
import { getDictiName, TAWizardSteps } from 'constants/dicti'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import { pick } from 'lodash'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'

type Props = {} & BaseComponentProps & StepPropsInterface<TaFormData>

const Title = chakra(Flex, {
  baseStyle: {
    bg: '#F9FAFF',
    h: '56px',
    textStyle: 'body.semibold',
    color: 'fontnavy',
    pl: '54px',
    alignItems: 'center',
  },
}),
  generalSection = {
    title: `General Information`,
    stepId: TAWizardSteps.DescriptionMainInfo,
  },
  usesAndFloorSection = {
    title: `Uses and Floor Areas`,
    preview: data => (
      <UsesAndFloorAreasTable staticData={data} headerColor="transparent" />
    ),
  },
  parkingSection = {
    title: `Parking`,
    stepId: TAWizardSteps.DescriptionParking,
  },
  baseTableParams = {
    rowsHeadingHeight: '0px',
    rowsHeight: '56px',
    headers: [
      { text: '', width: '54px', noborder: true },
      { text: '', width: '60%', noborder: true },
      {
        text: '',
        width: '20%',
        noborder: true,
      },
      {
        text: '',
        width: '277px',
        noborder: true,
      },
      { text: '', width: '24px', noborder: true },
    ],
  },
  generalParams = {
    ...baseTableParams,
    items: [
      {
        label: 'Applicant:',
        path: 'general.applicant.name',
      },
      {
        label: 'Relation:',
        path: 'general.applicant.relationCS',
        convertDicti: true,
      },
      {
        label: 'Signatory:',
        path: 'general.signatory.name',
      },
      {
        label: 'Title:',
        path: 'general.signatory.signatoryTitleCS',
        convertDicti: true,
      },
      {
        label: '# of Buildings on this Lot',
        path: 'general.propertyDescription.numberOfBuildings',
      },
      {
        label: '# of Floors Above Grade',
        path: 'general.propertyDescription.numberOfFloorAboveGrade',
      },
      {
        label: 'Year Built',
        path: 'general.propertyDescription.yearConstruction',
      },
      {
        label: 'Year Purchased',
        path: 'general.propertyDescription.yearPurchase',
      },
    ],
  },
  parkingParams = {
    ...baseTableParams,
    items: [
      {
        label: '# of Total Indoor Parking Spaces:',
        path: 'general.parking.indoorParkingUses.totalNumberOfParkingSpots',
      },
      {
        label: '# of Paid Indoor Parking Spaces:',
        path: 'general.parking.indoorParkingUses.numberOfPaidParkingSpots',
      },
      {
        label: '# of Total Outdoor Parking Spaces:',
        path: 'general.parking.outdoorParkingUses.totalNumberOfParkingSpots',
      },
      {
        label: '# of Paid Outdoor Parking Spaces:',
        path: 'general.parking.outdoorParkingUses.numberOfPaidParkingSpots',
      },
    ],
  },
  getTitleWithAction = (titleText: string, action: AnyFunction): ReactNode => (
    <HStack spacing="0" w="calc(100% - 301px)" justifyContent="space-between">
      <div>{titleText}</div>
      <Button variant="textPrimary" onClick={action}>
        Edit
      </Button>
    </HStack>
  )

const StepGeneralMainInfo = ({ onChange, ...props }: Props) => {
  const staticData = pick(props, ['general', 'uses']),
    { context, changeStep } = useServiceWizard(),
    handleEditStep = useCallback(
      stepId => () => {
        context?.serviceMetaDataSet({ baseFlow: true })
        //TODO: fix this hack with setTimeout - need to wait for context update
        setTimeout(() => {
          changeStep(stepId)
        }, 0)
      },
      [context]
    ),
    createView = useCallback(
      (
        title: string | ReactNode,
        params: object,
        preview?: ReactNode
      ): ReactNode | null => {
        const createRows = item => {
          const value = get(staticData, item.path),
            v = item.convertDicti ? getDictiName(value) : value

          return (
            <tr>
              <CellWithoutBorder />
              <td>
                <TextFromParams
                  params={{
                    textAlign: 'left',
                    color: 'fontandicongray',
                  }}
                >
                  {item.label}
                </TextFromParams>
              </td>
              <td>
                <TextFromParams
                  params={{
                    textAlign: 'right',
                  }}
                >
                  {isNullOrWhiteSpace(v) ? <Empty /> : v}
                </TextFromParams>
              </td>
              <td />
              <CellWithoutBorder />
            </tr>
          )
        }
        return (
          <>
            <div>
              <Divider />
              <Title>{title}</Title>
              <Divider />
            </div>
            {preview ? preview : <Table {...params}>{createRows}</Table>}
          </>
        )
      },
      [staticData]
    ),
    view = useMemo(() => {
      return [
        createView(
          getTitleWithAction(
            generalSection.title,
            handleEditStep(generalSection.stepId)
          ),
          generalParams
        ),
        createView(
          usesAndFloorSection.title,
          {},
          usesAndFloorSection.preview(staticData)
        ),
        createView(
          getTitleWithAction(
            parkingSection.title,
            handleEditStep(parkingSection.stepId)
          ),
          parkingParams
        ),
      ]
    }, [staticData, createView, handleEditStep])

  return (
    <StepBasic className={props.className} p="0">
      <ReviewLastYearDataWarningBlock m={`0 0 36px 54px`} />
      {view}
    </StepBasic>
  )
}

export default memo(StepGeneralMainInfo, isEqual)
