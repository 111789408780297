// https://imask.js.org/guide.html
import IMask from 'imask'
import dayjs from 'dayjs'
import NullableMaskedNumber from 'ui-framework/components/primitives/Input/NullableMaskedNumber'

const apiShortDateFormat = 'YYYY-MM-DD'
const apiDateFormat = 'YYYY-MM-DDTHH:mm:ss'
const uiDateFormat = 'MM/DD/YYYY'
const uiDateTimeFormat = 'MM/DD/YYYY hh:mma'
const uiDateShortFormat = 'MMM D, YYYY'
const uiFullDateFormat = 'MMMM D, YYYY'
const uiDateMonthYearFormat = 'MMMM, YYYY'
const uiDateMonthFormat = 'MMM D'


const integer = new NullableMaskedNumber({
  mask: Number,
  scale: 0,
})

const integerPositive = new NullableMaskedNumber({
  ...integer,
  min: 0,
})

const integerMax = (maxValue: number): object =>
  new NullableMaskedNumber({
    ...integer,
    max: maxValue,
  })

const integerThousands = new NullableMaskedNumber({
  ...integer,
  thousandsSeparator: ',',
})

const integerPositiveThousands = new NullableMaskedNumber({
  ...integerPositive,
  ...integerThousands,
})

const float2 = new NullableMaskedNumber({
  ...integerPositiveThousands,
  scale: 2,
  padFractionalZeros: true,
  normalizeZeros: true,
  radix: '.',
})

const phone = {
  mask: '000-000-0000',
  lazy: false,
  placeholderChar: '#',
}

const currency = { mask: '$ [*******************]' }

const dateFormat = {
  mask: Date, // enable date mask

  // other options are optional
  pattern: 'm/`d/`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
  // you can provide your own blocks definitions, default blocks for date mask are:
  blocks: {
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
      placeholderChar: 'M',
    },
    d: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
      placeholderChar: 'D',
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 9999,
      placeholderChar: 'Y',
    },
  },

  // define date -> str convertion
  format: function (date) {
    // console.log('format', date, dayjs(date).format(uiDateFormat))
    return dayjs(date).format(uiDateFormat)
  },
  // define str -> date convertion
  parse: function (str) {
    // console.log('parse', str, dayjs(str, uiDateFormat))
    return dayjs(str, uiDateFormat)
  },

  // optional interval options
  // min: new Date(2000, 0, 1), // defaults to `1900-01-01`
  // max: new Date(2020, 0, 1), // defaults to `9999-01-01`

  autofix: true, // defaults to `false`

  // also Pattern options can be set
  lazy: false,

  // and other common options
  overwrite: true, // defaults to `false`
}

const datePeriodFormat = {
  // mask: Object, // enable date mask

  // other options are optional
  mask: 'm/`d/`Y - m/`d/`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
  // you can provide your own blocks definitions, default blocks for date mask are:
  blocks: {
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    d: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 9999,
    },
  },
  // // define date -> str convertion
  // format: function (date) {
  //   var day = date.getDate()
  //   var month = date.getMonth() + 1
  //   var year = date.getFullYear()

  //   if (day < 10) day = '0' + day
  //   if (month < 10) month = '0' + month

  //   return [month, day, year].join('/')
  // },
  // // define str -> date convertion
  // parse: function (str) {
  //   var monthDayYear = str.split('/')
  //   return new Date(monthDayYear[2], monthDayYear[0] - 1, monthDayYear[1])
  // },

  // define date -> str convertion
  format: function (date) {
    // console.log('format', date, dayjs(date).format('MM/DD/YYYY'))
    return `${dayjs(date.start).format('MM/DD/YYYY')} - ${dayjs(
      date.end
    ).format('MM/DD/YYYY')}`
  },
  // define str -> date convertion
  parse: function (str) {
    // console.log('parse', str, dayjs(str, 'MM/DD/YYYY'))
    const vals = str.split(' - ')
    return {
      start: dayjs(vals[0], 'MM/DD/YYYY'),
      end: dayjs(vals[1], 'MM/DD/YYYY'),
    }
  },

  // optional interval options
  // min: new Date(2000, 0, 1), // defaults to `1900-01-01`
  // max: new Date(2020, 0, 1), // defaults to `9999-01-01`

  autofix: true, // defaults to `false`

  // also Pattern options can be set
  lazy: false,

  // and other common options
  overwrite: true, // defaults to `false`
}
export {
  float2,
  integer,
  integerPositiveThousands,
  integerPositive,
  integerMax,
  phone,
  currency,
  dateFormat,
  datePeriodFormat,
  apiShortDateFormat,
  apiDateFormat,
  uiDateFormat,
  uiDateTimeFormat,
  uiDateShortFormat,
  uiDateMonthYearFormat,
  uiFullDateFormat,
  uiDateMonthFormat,
}
