import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import { REGEXPS } from 'constants/CONSTANTS'
import Joi from 'joi'

const schema = schemaWrapper({
  isAddressDifferent: schemaRules.BOOLEAN,
  isUnitApartmentNumberDifferent: schemaRules.BOOLEAN,
  unitApartmentNumber: Joi.when('isUnitApartmentNumberDifferent', {
    is: true,
    then: schemaRules.NOT_EMPTY_STRING.max(10)
      .pattern(REGEXPS.MATCH_LETTER_OR_NUMBER)
      .rule({ message: 'enter at least 1 letter or 1 number' }),
    otherwise: schemaRules.ANY,
  }),
  houseNo: Joi.when('isAddressDifferent', {
    is: true,
    then: schemaRules.NOT_EMPTY_STRING.pattern(
      REGEXPS.MATCH_LETTER_OR_NUMBER
    ).rule({ message: 'enter at least 1 letter or 1 number' }),
    otherwise: schemaRules.ANY,
  }),
  streetName: Joi.when('isAddressDifferent', {
    is: true,
    then: schemaRules.NOT_EMPTY_STRING.pattern(
      REGEXPS.MATCH_LETTER_OR_NUMBER
    ).rule({ message: 'enter at least 1 letter or 1 number' }),
    otherwise: schemaRules.ANY,
  }),
})

export default schema
