import React, { useMemo } from 'react'
import {
  useMultiStyleConfig,
  Box,
  StylesProvider,
  chakra,
} from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'

//===================================================

type ProgressProps = {
  /**
   * Min value for begining of progressbar
   */
  min?: number
  /**
   * Max value for ending of progressbar
   */
  max: number
  /**
   * Current value
   */
  current?: number
  /**
   * Show rounded bar at start
   */
  radiusStart?: boolean
  /**
   * Show rounded bar at end
   */
  radiusEnd?: boolean
  onlyFinished?: boolean
} & ChakraComponentProps

/**
 * Progress component
 */
const Progress = (props: ProgressProps) => {
  const {
      min = 0,
      max,
      current = 0,
      radiusStart = true,
      radiusEnd = true,
      onlyFinished = false,
      children,
    } = props,
    stepPercent = useMemo(() => 100 / (max - min), [max, min]),
    percent = useMemo(() => {
      if (!current) return 0
      if (current <= min) return 0
      if (current >= max) return 100
      return (current - min) * stepPercent
    }, [current, max, min, stepPercent]),
    showCurrent = current >= min && current <= max && percent !== 100,
    styles = useMultiStyleConfig('MGNYProgress', {
      complete: percent,
      stepPercent,
      showCurrent,
      radiusStart,
      radiusEnd,
      onlyFinished,
      ...props,
    })

  return (
    <Box sx={styles.wrapper} className={props.className}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

export default chakra(Progress)
