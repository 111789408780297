import React, { useState } from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'

import Box from 'ui-framework/components/primitives/Box'
import { useTheme } from '@emotion/react'
import { OnClickHandler } from 'ui-framework/common/types'
import { getCallback, keyboardEventListener } from 'helpers/utils'

type UploadDropZoneWrapperProps = {
  disabled?: boolean
}
const UploadDropZoneWrapper = styled('div')<UploadDropZoneWrapperProps>(
  props => ({
    // border: `1px solid ${props.theme.colors.gray1}`,
    boxSizing: 'border-box',
    borderRadius: '2px',
    // padding: props.theme.spacing.space24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  props =>
    props.disabled && {
      color: props.theme.colors.black30,
    }
)

type DropZoneProps = {
  inDropZone: boolean
  hasError: boolean
}
const DropZone = styled('div')<DropZoneProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '120px',
  padding: '20px',
  boxSizing: 'border-box',
  borderRadius: '12px',
  border: '1px dashed',
  borderColor: props.hasError
    ? (props.theme as any).colors.red.base
    : (props.theme as any).colors.secondary.divider,
  backgroundColor: props.hasError
    ? (props.theme as any).colors.red.opacity
    : (props.theme as any).colors.secondary.sideMenu,
}))

type LinkWpapperProps = {
  disabled?: boolean
}
const LinkWpapper = styled('a')<LinkWpapperProps>(props => ({
  cursor: 'pointer',
  lineHeight: '25px',
  color: props.disabled
    ? (props.theme as any).colors.primary.disabled
    : (props.theme as any).colors.primary.base,
}))
type TextWpapperProps = {}
const TextWpapper = styled('span')<TextWpapperProps>(props => ({
  color: (props.theme as any).colors.fontnavy,
  lineHeight: '25px',
  textAlign: 'center',
}))

export const DefaultFileFormatsMessage = () => (
    <div>
      Only .docx, .doc, .xlsx, .xls, .jpg, .png and .pdf files up to 50 MB are
      supported.
    </div>
  ),
  ExcelFileFormatMessage = () => (
    <div>
      <p>Excel uploads are preferred.</p>
      <p>We also accept .docx, .doc, .png, .pdf up to 50MB.</p>
    </div>
  )

//===================================================
type Props = {
  docName?: string
  fileFormatsMessage?: React.ReactNode
  disabled?: boolean
  error?: string
  onClickFilesToUpload: OnClickHandler
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
} & BaseComponentProps

/**
 * UploadDropZoneUI component
 */
const UploadDropZoneUI = ({
  fileFormatsMessage,
  disabled,
  error,
  onDrop,
  onClickFilesToUpload,
  ...props
}: Props) => {
  const theme = useTheme()
  const [inDropZone, setInDropZone] = useState(false)

  const handleDragOver = e => {
    e.preventDefault()
    if (!inDropZone) setInDropZone(true)
  }

  const handleDragLeave = () => {
    setInDropZone(false)
  }

  return (
    <UploadDropZoneWrapper className={props.className} disabled={disabled}>
      <DropZone
        hasError={Boolean(error)}
        inDropZone={inDropZone}
        onDrop={getCallback(onDrop)}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <TextWpapper>
          Drag and drop or{' '}
          <LinkWpapper
            onClick={getCallback(onClickFilesToUpload)}
            onKeyDown={e =>
              keyboardEventListener(e, getCallback(onClickFilesToUpload))
            }
            tabIndex={0}
            disabled={disabled}
          >
            choose a file to upload.
          </LinkWpapper>
        </TextWpapper>
        <TextWpapper>
          {error && <div>{error}</div>}
          {fileFormatsMessage || <DefaultFileFormatsMessage />}
        </TextWpapper>
        <Box
          typography={theme.typography.caption2}
          color={theme.colors.black20}
          margin="2px 0 -2px 0"
        />
      </DropZone>
    </UploadDropZoneWrapper>
  )
}

export default UploadDropZoneUI
