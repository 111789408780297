import { AccessControl, Query } from 'role-acl'
import grants, { UserRole } from 'constants/grants'
import useAuth from './useAuth'
import { useCallback, useMemo } from 'react'

type QueryEx = { hasRole: (role: UserRole) => boolean } & Query

//TODO: role-acl package should be replaced due to project inactivity
const ac = new AccessControl(grants)

function usePermission() {
  const { profile } = useAuth()
  const allowedRoles = Object.keys(grants)

  const roles = useMemo(() => {
    let result = [UserRole.ANONYMOUS]
    if (profile?.roles) {
      result = profile?.roles as Array<UserRole>
      //result.push(ROLE_USER)
    }

    // clean roles
    return result.filter(r => allowedRoles.includes(r))
  }, [allowedRoles, profile?.roles])

  const hasRole = useCallback(
    (role: UserRole) => {
      return roles?.includes(role)
    },
    [roles]
  )

  const context = useMemo(
    () => ({
      services: profile?.availableAgreementServices,
    }),
    [profile?.availableAgreementServices]
  )

  const permissions = useMemo<QueryEx>(() => {
    const result = ac.can(roles).context(context) as QueryEx
    result.hasRole = hasRole
    return result
  }, [context, hasRole, roles])

  return permissions
}

export default usePermission
