import React, { useCallback, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import {
  OccupancyPeriod,
  Occupant,
  RpieFormData,
} from 'hooks/api/rpie/RpieFormData'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import Input from 'ui-framework/components/primitives/Input'
import IntervalBar from 'ui-framework/components/patterns/IntervalBar'
import { integerPositiveThousands } from 'helpers/formats'
import { wrap } from 'object-path-immutable'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { VStack } from '@chakra-ui/layout'
import Textarea from 'ui-framework/components/primitives/Textarea'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods, hasIntersections } from 'helpers/utils'
import { getDictiName, StorefrontLeaseConcessions, TaxClasses } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { getNameAndErrorProps } from 'helpers/utils'
import { omit } from 'lodash-es'
import StorefrontUnitOccupantsIntervalBar from 'pages/Services/Rpie/RpieFormPage/steps/storefront/StorefrontUnitOccupantsIntervalBar'

//===================================================

const clearValues = {
  monthNumber: null,
  reducedRentAmount: null,
  improvementCashAmount: null,
  otherDescriptionOfConcession: null,
}

type Props = {
  unitNumber: number
  occupantNumber: number
  hasLeaseInformation: boolean
  existLeaseInformationCS: StorefrontLeaseConcessions | null
  monthNumber: number
  reducedRentAmount: number
  improvementCashAmount: number
  otherDescriptionOfConcession: string
  isIncreaseThisYear: boolean
  isIncreaseAfterThisYear: boolean
  isOccupiedByTenantOn1231: boolean
  _occStartDate: string
  _occEndDate: string
  _occLabel: string
  occupants: Occupant[]
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

/**
 * StepStorefrontUnitOccupantLeaseConcessionsStep component
 */
const StepStorefrontUnitOccupantLeaseConcessionsStep = ({
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    showIsOccupiedByTenantOn1231 = hasIntersections(context.bblsData.taxClass, [
      TaxClasses._2,
      TaxClasses._4,
    ]),
    { fiscalYear, reportingYear } = getFiscalPeriods(context),
    {
      unitNumber,
      occupantNumber,
      hasLeaseInformation,
      existLeaseInformationCS,
      monthNumber,
      reducedRentAmount,
      improvementCashAmount,
      otherDescriptionOfConcession,
      isIncreaseThisYear,
      isIncreaseAfterThisYear,
      isOccupiedByTenantOn1231,
      _occStartDate,
      _occEndDate,
      _occLabel,
      occupants,
    } = props,
    validationData = useMemo(
      () => ({
        hasLeaseInformation,
        existLeaseInformationCS,
        monthNumber,
        reducedRentAmount,
        improvementCashAmount,
        otherDescriptionOfConcession,
        isIncreaseThisYear,
        isIncreaseAfterThisYear,
        showIsOccupiedByTenantOn1231,
        isOccupiedByTenantOn1231,
        _occStartDate,
        _occEndDate,
      }),
      [
        hasLeaseInformation,
        existLeaseInformationCS,
        monthNumber,
        reducedRentAmount,
        improvementCashAmount,
        otherDescriptionOfConcession,
        isIncreaseThisYear,
        isIncreaseAfterThisYear,
        showIsOccupiedByTenantOn1231,
        isOccupiedByTenantOn1231,
        _occStartDate,
        _occEndDate,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  const handleChange = useCallback(
    (value, name) => {
      if (name === 'hasLeaseInformation') {
        onChange({
          hasLeaseInformation: value,
          existLeaseInformationCS: hasLeaseInformation
            ? StorefrontLeaseConcessions.RentFree
            : StorefrontLeaseConcessions.None,
          ...(!hasLeaseInformation ? clearValues : {}),
        })
      } else if (name === 'existLeaseInformationCS') {
        const shouldClear =
          existLeaseInformationCS === StorefrontLeaseConcessions.RentFree
            ? omit(clearValues, 'monthNumber')
            : existLeaseInformationCS === StorefrontLeaseConcessions.StartRent
              ? omit(clearValues, 'reducedRentAmount')
              : existLeaseInformationCS === StorefrontLeaseConcessions.Improve
                ? omit(clearValues, 'improvementCashAmount')
                : existLeaseInformationCS === StorefrontLeaseConcessions.Other
                  ? omit(clearValues, 'otherDescriptionOfConcession')
                  : {}

        onChange({ existLeaseInformationCS: value, ...shouldClear })
      } else onChange(value, name)
    },
    [existLeaseInformationCS, hasLeaseInformation, onChange]
  )

  useEffect(() => {
    if (!hasLeaseInformation
      && existLeaseInformationCS !== StorefrontLeaseConcessions.None) {
      onChange({
        existLeaseInformationCS: StorefrontLeaseConcessions.None,
        ...clearValues,
      })
    }
  }, [hasLeaseInformation, onChange])

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px" w="680px">
        <StorefrontUnitOccupantsIntervalBar
          occupants={occupants}
          occupantNumber={occupantNumber}
          reportingYear={reportingYear}
        />
        <HelpTarget name="hasLeaseInformation">
          <SelectsGroup
            // label="Was Tenant provided any lease concessions, including those related to COVID-19?"
            label="Was Tenant provided any lease concessions?"
            error={hasError('hasLeaseInformation')}
          >
            <Radiobutton
              value={hasLeaseInformation}
              name="hasLeaseInformation"
              selected={false}
              disabled={disabled}
              onChange={handleChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={hasLeaseInformation}
              name="hasLeaseInformation"
              selected={true}
              disabled={disabled}
              onChange={handleChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        {hasLeaseInformation && (
          <>
            <HelpTarget name="existLeaseInformationCS">
              <SelectsGroup
                label="Choose Lease Concessions"
                error={hasError('existLeaseInformationCS')}
                variant={`vertical`}
              >
                <Radiobutton
                  value={existLeaseInformationCS}
                  name="existLeaseInformationCS"
                  selected={StorefrontLeaseConcessions.RentFree}
                  // compareFn={constNameComapreFn}
                  disabled={disabled}
                  onChange={handleChange}
                >
                  Number of Months Rent-Free
                </Radiobutton>
                <Radiobutton
                  value={existLeaseInformationCS}
                  name="existLeaseInformationCS"
                  selected={StorefrontLeaseConcessions.StartRent}
                  // compareFn={constNameComapreFn}
                  disabled={disabled}
                  onChange={handleChange}
                >
                  Reduced Starting Rent or Rent Abatement Amount
                </Radiobutton>
                <Radiobutton
                  value={existLeaseInformationCS}
                  name="existLeaseInformationCS"
                  selected={StorefrontLeaseConcessions.Improve}
                  // compareFn={constNameComapreFn}
                  disabled={disabled}
                  onChange={handleChange}
                >
                  Improvement or Cash Allowance Amount
                </Radiobutton>
                <Radiobutton
                  value={existLeaseInformationCS}
                  name="existLeaseInformationCS"
                  selected={StorefrontLeaseConcessions.Other}
                  // compareFn={constNameComapreFn}
                  disabled={disabled}
                  onChange={handleChange}
                >
                  Other
                </Radiobutton>
              </SelectsGroup>
            </HelpTarget>
            {existLeaseInformationCS ===
              StorefrontLeaseConcessions.RentFree && (
                <HelpTarget name="monthNumber">
                  <Input
                    label="Number of Months Rent-Free"
                    type="number"
                    value={monthNumber}
                    placeholder="Enter #"
                    disabled={disabled}
                    onChange={handleChange}
                    {...nameAndErrorProps('monthNumber')}
                  />
                </HelpTarget>
              )}
            {existLeaseInformationCS ===
              StorefrontLeaseConcessions.StartRent && (
                <HelpTarget name="reducedRentAmount">
                  <Input
                    label="Reduced Starting Rent or Rent Abatement Amount"
                    type="number"
                    format={integerPositiveThousands}
                    value={reducedRentAmount}
                    prefix="$"
                    disabled={disabled}
                    onChange={handleChange}
                    {...nameAndErrorProps('reducedRentAmount')}
                  />
                </HelpTarget>
              )}
            {existLeaseInformationCS === StorefrontLeaseConcessions.Improve && (
              <HelpTarget name="improvementCashAmount">
                <Input
                  label="Improvement or Cash Allowance Amount"
                  type="number"
                  prefix="$"
                  format={integerPositiveThousands}
                  value={improvementCashAmount}
                  disabled={disabled}
                  onChange={handleChange}
                  {...nameAndErrorProps('improvementCashAmount')}
                />
              </HelpTarget>
            )}

            {existLeaseInformationCS === StorefrontLeaseConcessions.Other && (
              <HelpTarget name="otherDescriptionOfConcession">
                <Textarea
                  label="Other"
                  value={otherDescriptionOfConcession}
                  placeholder="Enter"
                  disabled={disabled}
                  width={'400px'}
                  rows={4}
                  maxLength={255}
                  onChange={handleChange}
                  {...nameAndErrorProps('otherDescriptionOfConcession')}
                />
              </HelpTarget>
            )}
          </>
        )}
        <HelpTarget name="isIncreaseThisYear">
          <SelectsGroup
            label={`Is the tenants rent scheduled to increase in ${fiscalYear}?`}
            error={hasError('isIncreaseThisYear')}
          >
            <Radiobutton
              value={isIncreaseThisYear}
              name="isIncreaseThisYear"
              selected={false}
              disabled={disabled}
              onChange={handleChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isIncreaseThisYear}
              name="isIncreaseThisYear"
              selected={true}
              disabled={disabled}
              onChange={handleChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        <HelpTarget name="isIncreaseAfterThisYear">
          <SelectsGroup
            label={`Is the tenant's rent scheduled to increases after ${fiscalYear}?`}
            error={hasError('isIncreaseAfterThisYear')}
          >
            <Radiobutton
              value={isIncreaseAfterThisYear}
              name="isIncreaseAfterThisYear"
              selected={false}
              disabled={disabled}
              onChange={handleChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isIncreaseAfterThisYear}
              name="isIncreaseAfterThisYear"
              selected={true}
              disabled={disabled}
              onChange={handleChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        {showIsOccupiedByTenantOn1231 && (
          <HelpTarget name="isOccupiedByTenantOn1231">
            <SelectsGroup
              label={`Was the storefront occupied by the tenant and open for business on 12/31 of the reporting year?`}
              error={hasError('isOccupiedByTenantOn1231')}
            >
              <Radiobutton
                value={isOccupiedByTenantOn1231}
                name="isOccupiedByTenantOn1231"
                selected={false}
                disabled={disabled}
                onChange={handleChange}
              >
                No
              </Radiobutton>
              <Radiobutton
                value={isOccupiedByTenantOn1231}
                name="isOccupiedByTenantOn1231"
                selected={true}
                disabled={disabled}
                onChange={handleChange}
              >
                Yes
              </Radiobutton>
            </SelectsGroup>
          </HelpTarget>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepStorefrontUnitOccupantLeaseConcessionsStep
