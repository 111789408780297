import React, { useMemo } from 'react'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'

import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { VStack } from '@chakra-ui/react'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import { integerPositiveThousands } from 'helpers/formats'
import { ChakraComponentProps } from 'ui-framework/common/types'
import { RpieFormData } from 'hooks/api/rpie/RpieFormData'
import { createItemsByDicti, getNameAndErrorProps } from 'helpers/utils'
import { UseOfSpace } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'

//===================================================
type Props = {
  annualRentPaidToOwner: number | null
  payUtilityExpenses: boolean | null
  payRepairExpenses: boolean | null
  paySpaceOccupied: boolean | null
  isSubleasingProperty: boolean | null
  isGroundLessor: boolean | null
  annualRent: number | null
  subleasingAreaSF: number | null
  useOfSpaceCS: UseOfSpace
  groundLeaseAmount: number | null
} & ChakraComponentProps &
  StepPropsInterface<RpieFormData>

const useOfSpaceItems = createItemsByDicti(UseOfSpace)

/**
 * StepGeneralNetLeaseQuestions component
 */
const StepGeneralNetLeaseQuestions = ({
  annualRentPaidToOwner,
  payUtilityExpenses,
  payRepairExpenses,
  paySpaceOccupied,
  isSubleasingProperty,
  isGroundLessor,
  applyValidation,
  onValidation,
  annualRent,
  subleasingAreaSF,
  useOfSpaceCS,
  groundLeaseAmount,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({
        annualRentPaidToOwner,
        subleasingAreaSF,
        groundLeaseAmount,
        useOfSpaceCS,
        annualRent,
        isGroundLessor,
        payUtilityExpenses,
        payRepairExpenses,
        paySpaceOccupied,
        isSubleasingProperty,
      }),
      [
        annualRentPaidToOwner,
        subleasingAreaSF,
        groundLeaseAmount,
        useOfSpaceCS,
        annualRent,
        isGroundLessor,
        payUtilityExpenses,
        payRepairExpenses,
        paySpaceOccupied,
        isSubleasingProperty,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px">
        <HelpTarget name="annualRentPaidToOwner">
          <Input
            label="Annual Rent paid to the Property Owner"
            format={integerPositiveThousands}
            type="number"
            value={annualRentPaidToOwner}
            prefix="$"
            onChange={onChange}
            {...nameAndErrorProps('annualRentPaidToOwner')}
          />
        </HelpTarget>
        <HelpTarget name="payUtilityExpenses">
          <SelectsGroup
            label="Does the tenant pay utility expenses?"
            error={hasError('payUtilityExpenses')}
          >
            <Radiobutton
              value={payUtilityExpenses}
              name="payUtilityExpenses"
              selected={false}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={payUtilityExpenses}
              name="payUtilityExpenses"
              selected={true}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        <HelpTarget name="payRepairExpenses">
          <SelectsGroup
            label="Does the tenant pay maintenance and repair expenses?"
            error={hasError('payRepairExpenses')}
          >
            <Radiobutton
              value={payRepairExpenses}
              name="payRepairExpenses"
              selected={false}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={payRepairExpenses}
              name="payRepairExpenses"
              selected={true}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        <HelpTarget name="paySpaceOccupied">
          <SelectsGroup
            label="Does the tenant pay property tax for the space occupied?"
            error={hasError('paySpaceOccupied')}
          >
            <Radiobutton
              value={paySpaceOccupied}
              name="paySpaceOccupied"
              selected={false}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={paySpaceOccupied}
              name="paySpaceOccupied"
              selected={true}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        <HelpTarget name="isSubleasingProperty">
          <SelectsGroup
            label="Is the net lessee or owner related party subleasing any of the property?"
            error={hasError('isSubleasingProperty')}
          >
            <Radiobutton
              value={isSubleasingProperty}
              name="isSubleasingProperty"
              selected={false}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isSubleasingProperty}
              name="isSubleasingProperty"
              selected={true}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        {isSubleasingProperty ? (
          <VStack align="start" spacing="36px">
            <HelpTarget name="subleasingAreaSF">
              <Input
                label="Subleasing Area SF"
                format={integerPositiveThousands}
                type="number"
                value={subleasingAreaSF}
                placeholder="Enter SF"
                onChange={onChange}
                {...nameAndErrorProps('subleasingAreaSF')}
              />
            </HelpTarget>
            <HelpTarget name="useOfSpaceCS">
              <DropdownList
                label="Use of Space"
                value={useOfSpaceCS}
                itemId="id"
                itemValue="name"
                items={useOfSpaceItems}
                onChange={onChange}
                {...nameAndErrorProps('useOfSpaceCS')}
              />
            </HelpTarget>
            <HelpTarget name="annualRent">
              <Input
                label="Annual Rent"
                type="number"
                value={annualRent}
                format={integerPositiveThousands}
                prefix="$"
                onChange={onChange}
                {...nameAndErrorProps('annualRent')}
              />
            </HelpTarget>
          </VStack>
        ) : undefined}
        <HelpTarget name="isGroundLessor">
          <SelectsGroup
            label="Are you filling as ground lessor?"
            error={hasError('isGroundLessor')}
          >
            <Radiobutton
              value={isGroundLessor}
              name="isGroundLessor"
              selected={false}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isGroundLessor}
              name="isGroundLessor"
              selected={true}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        {isGroundLessor ? (
          <VStack align="start" spacing="36px">
            <Input
              label="What is the Ground Lease Amount that you are receiving?"
              format={integerPositiveThousands}
              prefix="$"
              type="number"
              value={groundLeaseAmount}
              onChange={onChange}
              {...nameAndErrorProps('groundLeaseAmount')}
            />
          </VStack>
        ) : undefined}
      </VStack>
    </StepBasic>
  )
}

export default StepGeneralNetLeaseQuestions
