import React from 'react'
import useNavigation from 'hooks/useNavigation'
import Icon from 'ui-framework/components/primitives/Icon'
import Link from 'ui-framework/components/primitives/Link'
import { useMultiStyleConfig, Box } from '@chakra-ui/react'
import { useAppConfig } from 'hooks/useApplicationContext'

type MainMenuProps = {}

const MainMenu = (props: MainMenuProps) => {
  const appconf = useAppConfig(),
    navItems = useNavigation(appconf),
    style = useMultiStyleConfig('MGNYMainItem', props)

  return (
    <>
      {navItems.map(navItem => (
        <div key={navItem.subTitle}>
          <Box sx={style.title}>{navItem.subTitle}</Box>
          {navItem.children.map((item, i) => (
            <Link
              variant={'menuPrimary'}
              decorate={false}
              to={item.route}
              key={i}
            >
              <Icon size={'md'}>{item.icon as any}</Icon>
              {item.module}
            </Link>
          ))}
        </div>
      ))}
    </>
  )
}

export default MainMenu
