import { ComponentStyleConfig } from '@chakra-ui/theme'

const CalendarStyle: ComponentStyleConfig = {
  parts: [
    'calendarWrapper',
    'contentWrapper',
    'dayContent',
    'periodWrapper',
    'weekdayWrapper',
    'dayContentWrapper',
  ],
  // Styles for the base style
  baseStyle: props => ({
    valueText: {
      outline: 'none',
    },
    calendarWrapper: {
      bgColor: 'white100',
    },
    dayContentWrapper: {
      textStyle: 'body.regular',
      color: 'fontnavy',
      width: '36px',
      height: '36px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dayContent: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&.selected, &:hover, &.range:hover': {
        color: 'white100',
        borderRadius: '6px',
        bgColor: 'primary.base',
      },
      '&.range': {
        bgColor: 'secondary.base',
        height: '32px',
      },
      '&.disabled': {
        color: 'primary.disabled',
        cursor: 'default',
        '&:hover': {
          bgColor: 'transparent',
        },
      },
    },
    weekdayWrapper: {
      textStyle: 'body.regular',
      color: 'fontandicongray',
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      periodWrapper: {
        width: '588px',
        borderRadius: '11px',
      },
      calendarWrapper: {
        height: props.isPeriodLeft || props.isPeriodRight ? '303px' : '347px',
        width: props.isPeriodLeft || props.isPeriodRight ? 'auto' : '288px',
        boxShadow:
          props.isPeriodLeft || props.isPeriodRight
            ? 'none'
            : '0 0 5px 0 rgba(0,0,0,0.08)',
        padding: props.isPeriodLeft || props.isPeriodRight ? '0' : '20px',
        borderRadius: props.isPeriodLeft || props.isPeriodRight ? '0' : '11px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}

export default CalendarStyle
