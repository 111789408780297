import { chakra, Tooltip } from '@chakra-ui/react'
import { getCallback } from 'helpers/utils'
import { useCallback } from 'react'

//==================================================

const Wrapper = chakra('span', {
  baseStyle: (props: any) => ({
    ...(props.onClick && {
      cursor: 'pointer',
      _hover: {
        color: 'primary.base',
      },
    }),
  }),
})

/**
 * useHandleStepChangeWithTooltip component
 */

const useHandleStepChangeWithTooltip = changeStep =>
  useCallback(
    (stepId, item) => {
      const handleStepChange = stepId
        ? () => getCallback(changeStep)(stepId)
        : undefined
      return (
        <Tooltip
          hasArrow
          placement={`bottom-start`}
          bg={'white100'}
          color={'fontnavy'}
          textStyle={'caption.regular'}
          p={'8px'}
          borderRadius={'6px'}
          label={handleStepChange && `Click to go back to ${item}`}
        >
          <Wrapper onClick={handleStepChange}>{item}</Wrapper>
        </Tooltip>
      )
    },
    [changeStep]
  )

export default useHandleStepChangeWithTooltip
