import { isCallback } from 'helpers/utils'
import { ComponentStyleConfig } from '@chakra-ui/theme'

const IconStyle: ComponentStyleConfig = {
  baseStyle: props => ({
    display: 'inline-flex',
    cursor: isCallback(props.onClick) ? 'pointer' : '',
    use: {
      fill: `${props.color || 'inherit'}`,
      fillOpacity: 1,
    },
    '&.close': {
      pointerEvents: 'all',
      cursor: 'pointer',
      svg: {
        fill: 'fontandicongray',
        '&:hover': {
          fill: 'primary.base',
        },
      },
    },
  }),
  sizes: {
    xs: props => ({
      svg: {
        height: '10px',
        width: '10px',
      },
    }),
    sm: props => ({
      svg: {
        height: '12px',
        width: '12px',
      },
    }),
    md: props => ({
      svg: {
        height: '16px',
        width: '16px',
      },
    }),
    lg: props => ({
      svg: {
        height: '20px',
        width: '20px',
      },
    }),
    xl: props => ({
      svg: {
        height: '24px',
        width: '24px',
      },
    }),
  },
  defaultProps: {
    size: 'md',
  },
}

export default IconStyle
