import { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import createPersistedState from 'use-persisted-state'
import useToast from './useToast'


const UPDATES_CHECK_INTERVAL = 15 // min

const useReloadedDueToUpdate = createPersistedState('reload_due_to_update')

function useAppUpdater(): [boolean | 'force', () => void] {
  const [hasUpdate, hasUpdateSet] = useState<boolean | 'force'>(false),
    [reloadedDueToUpdate, reloadedDueToUpdateSet] =
      useReloadedDueToUpdate(false),
    toast = useToast()

  useEffect(() => {
    const iid = setInterval(() => {
      axios({
        method: 'GET',
        baseURL: '/',
        url: '/version.json',
      })
        .then(({ data }) => {
          if (data.commit !== process.env.REACT_APP_COMMIT) {
            hasUpdateSet(
              data.version === process.env.REACT_APP_VERSION || 'force'
            )
            clear()
          }
        })
        .catch(() => { })
    }, UPDATES_CHECK_INTERVAL * 60 * 1000),
      clear = () => clearInterval(iid)

    if (reloadedDueToUpdate) {
      reloadedDueToUpdateSet(false)
      setTimeout(() => {
        toast.success(
          'Update Successful',
          'You can continue to work with the portal.'
        )
      }, 5000)
    }

    return clear
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const update = useCallback(() => {
    reloadedDueToUpdateSet(true)
    hasUpdateSet(false)
    window.location.reload()
  }, [hasUpdateSet, reloadedDueToUpdateSet])

  return [hasUpdate, update]
}

export default useAppUpdater
