import React from 'react'
import { getFiscalPeriods } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpVacantStepCommercialDescriptionProps = {} & BaseComponentProps

/**
 * HelpVacantStepCommercialDescription component
 */
const HelpVacantStepCommercialDescription = (
  props: HelpVacantStepCommercialDescriptionProps
) => {
  const { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context)

  return (
    <>
      <p>
        You have previously indicated that the property was at least partially
        vacant.
      </p>
      <p>
        Provide vacant floor area per each applicable use, as of January 5,{' '}
        {fiscalYear}
      </p>
    </>
  )
}

export default HelpVacantStepCommercialDescription
