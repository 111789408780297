import React, { useCallback } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { nanoid } from 'nanoid'
import { getCallback, keyboardEventListener } from 'helpers/utils'
import { OnChangeHandler } from 'ui-framework/common/types'
import {
  Box,
  useMultiStyleConfig,
  Flex,
  chakra,
  Tooltip,
} from '@chakra-ui/react'
import Icon from '../Icon'

type Props = {
  /**
   * description of prop
   * @disabled boolean
   */
  disabled?: boolean
  /**
   * description of prop
   * @checked boolean
   */
  checked?: boolean
  /**
   * onChange handler
   */
  onChange?: OnChangeHandler<boolean>
  /**
   * type of text
   * @text string
   */
  text?: string
  /**
   * type of name
   * @name string
   */
  name?: string
  indeterminate?: boolean | null
  error?: string | boolean
  errorTooltip?: boolean
} & BaseComponentProps

const Checkbox = (props: Props) => {
  const {
      disabled = false,
      name,
      onChange,
      checked,
      indeterminate,
      errorTooltip = true,
      error,
    } = props,
    icon = indeterminate ? 'minus' : 'successful',
    { title, wrapper, checkbox, message, tooltip, wrapperInner } =
      useMultiStyleConfig('MGNYCheckbox', {
        ...props,
        hasTitle: !!props.children,
      }),
    showErrorMessage = error && (error as string | string[])?.length,
    handleOnChange = useCallback(
      () => getCallback(onChange)(indeterminate ? false : !checked, name),
      [checked, indeterminate, name, onChange]
    )
  return (
    <Flex as={`label`} sx={wrapper} key={nanoid()} className={props.className}>
      <input
        checked={checked}
        name={name}
        disabled={disabled}
        type={'checkbox'}
        onChange={handleOnChange}
      />
      <Flex
        sx={checkbox}
        tabIndex={disabled ? undefined : 0}
        onKeyDown={e => keyboardEventListener(e, handleOnChange)}
      >
        {(checked || indeterminate) && <Icon size={`xs`}>{icon}</Icon>}
        {errorTooltip && showErrorMessage && (
          <Tooltip
            sx={tooltip}
            label={error}
            arrowSize={8}
            maxW={'268px'}
            bg={`red.base`}
            hasArrow
          >
            <Flex sx={wrapperInner}>
              <Icon className="warning" color="red.base">
                info-circle
              </Icon>
            </Flex>
          </Tooltip>
        )}
      </Flex>
      <Box sx={title}>{props.children}</Box>
      {!errorTooltip && showErrorMessage && <Box sx={message}>{error}</Box>}
    </Flex>
  )
}

export default chakra(Checkbox)
