import React from 'react'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import Textarea from 'ui-framework/components/primitives/Textarea'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { AnyFunction, getNameAndErrorProps } from 'helpers/utils'
import { StepHotelOperationInformationProps } from '.'

type AdditionalInformationProps = {
  hasError: AnyFunction
} & StepHotelOperationInformationProps

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const {
    applicantName,
    isManagedByUnrelatedEntity,
    haveProprietaryRights,
    haveProprietaryRightsDescription,
    hasError,
    disabled,
    onChange,
  } = props
  return (
    <>
      <HelpTarget name="isManagedByUnrelatedEntity">
        <SelectsGroup
          label={`Is the hotel managed by an entity that is unrelated to ${applicantName}?`}
          error={hasError('isManagedByUnrelatedEntity')}
        >
          <Radiobutton
            value={isManagedByUnrelatedEntity}
            name="isManagedByUnrelatedEntity"
            selected={false}
            disabled={disabled}
            onChange={onChange}
          >
            No
          </Radiobutton>
          <Radiobutton
            value={isManagedByUnrelatedEntity}
            name="isManagedByUnrelatedEntity"
            selected={true}
            disabled={disabled}
            onChange={onChange}
          >
            Yes
          </Radiobutton>
        </SelectsGroup>
      </HelpTarget>

      <HelpTarget name="haveProprietaryRights">
        <SelectsGroup
          label={`Does any individual, business or institutional user of hotel rooms have proprietary rights to use the rooms?`}
          error={hasError('haveProprietaryRights')}
        >
          <Radiobutton
            value={haveProprietaryRights}
            name="haveProprietaryRights"
            selected={false}
            disabled={disabled}
            onChange={onChange}
          >
            No
          </Radiobutton>
          <Radiobutton
            value={haveProprietaryRights}
            name="haveProprietaryRights"
            selected={true}
            disabled={disabled}
            onChange={onChange}
          >
            Yes
          </Radiobutton>
        </SelectsGroup>
      </HelpTarget>

      {haveProprietaryRights && (
        <HelpTarget name="haveProprietaryRightsDescription">
          <Textarea
            label={
              'Provide details of proprietary ownership and number of units'
            }
            value={haveProprietaryRightsDescription}
            placeholder="Enter"
            disabled={disabled}
            width={'400px'}
            rows={5}
            showCounter={false}
            onChange={onChange}
            {...getNameAndErrorProps(
              'haveProprietaryRightsDescription',
              hasError
            )}
          />
        </HelpTarget>
      )}
    </>
  )
}

export default AdditionalInformation
