import { useDisclosure } from '@chakra-ui/hooks'
import { Flex, Text } from '@chakra-ui/layout'
import { getCallback } from 'helpers/utils'
import { ReactChild, useCallback } from 'react'
import Button from 'ui-framework/components/primitives/Button'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'

const useTaPartialSubmitDialog = (onAction): [() => void, ReactChild] => {
  const submitDialog = useDisclosure()
  return [
    submitDialog.onOpen,
    <ModalDialog
      key="submitDialog"
      isOpen={submitDialog.isOpen}
      onAction={submitDialog.onClose}
      actionText="Continue Application"
      title="Your data has been successfully received!"
      size="w560"
      footerActions={
        <Flex w="100%" justifyContent="space-between">
          {/* <Button variant={`textPrimary`} onClick={submitDialog.onClose}>
            Cancel
          </Button> */}
          <Button variant={`secondary`} onClick={getCallback(onAction)}>
            Go to Property List
          </Button>
        </Flex>
      }
    >
      <Text pb="20px" lineHeight="25px">
        Our team will process the Property Description data and generate the
        main application form TC101 for your signature in the next few days. It
        will be available in your Document Center.
      </Text>
    </ModalDialog>,
  ]
}

export default useTaPartialSubmitDialog
