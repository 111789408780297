import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { getFiscalPeriods } from 'helpers/utils'

//===================================================
type HelpDetailsIncomeStepProps = {} & BaseComponentProps

/**
 * HelpDetailsIncomeStep component
 */
const HelpDetailsIncomeStep = (props: HelpDetailsIncomeStepProps) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context)
  return (
    <>
      <p>
        Provide {reportingYear} income information for each applicable income
        category separately (<b>if none - put "0"</b>).
      </p>
      <p>
        Round up to the nearest dollar, but DO NOT round up to the nearest
        hundred.
      </p>
      <p>
        Do not include income collected from owner-occupied space or a related
        tenant.
      </p>
    </>
  )
}

export default HelpDetailsIncomeStep
