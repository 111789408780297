import React, { useCallback, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import Input from 'ui-framework/components/primitives/Input'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { HStack, VStack } from '@chakra-ui/layout'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import { OrNull } from 'services/common/useFormDataHook'
import { RPIEExclusions } from 'constants/dicti'
import {
  createItemsByDicti,
  getFiscalPeriods,
  getNameAndErrorProps,
} from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
//===================================================

type Props = {
  numberOfBuildings: number
  yearPurchase: number
  isPropertyToNetLease: boolean
  wasAnyRentalIncome: boolean
  exclusionCS: OrNull<RPIEExclusions>
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepGeneralPropertyDescription component
 */
const StepGeneralPropertyDescription = ({
  numberOfBuildings,
  yearPurchase,
  isPropertyToNetLease,
  wasAnyRentalIncome,
  exclusionCS,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context),
    validationData = useMemo(
      () => ({
        numberOfBuildings,
        yearPurchase,
        isPropertyToNetLease,
        wasAnyRentalIncome,
        exclusionCS,
      }),
      [
        numberOfBuildings,
        yearPurchase,
        isPropertyToNetLease,
        wasAnyRentalIncome,
        exclusionCS,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError),
    handleOnWasAnyRentalIncomeChange = useCallback(
      (value, name) => {
        onChange(value, name)
        if (value) {
          onChange(null, 'exclusionCS')
        }
      },
      [onChange]
    )

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px">
        <ReviewLastYearDataWarningBlock />

        <HStack align="start" spacing="36px">
          <HelpTarget name="numberOfBuildings">
            <Input
              label="# of Buildings on the Lot"
              type="number"
              value={numberOfBuildings}
              placeholder="Enter #"
              disabled={disabled}
              fixedHeight
              onChange={onChange}
              {...nameAndErrorProps('numberOfBuildings')}
            />
          </HelpTarget>

          <HelpTarget name="yearPurchase">
            <Input
              label="Year of Purchase"
              type="number"
              value={yearPurchase}
              placeholder="Enter year"
              disabled={disabled}
              fixedHeight
              onChange={onChange}
              maxLength={4}
              {...nameAndErrorProps('yearPurchase')}
            />
          </HelpTarget>
        </HStack>

        <HelpTarget name="isPropertyToNetLease">
          <SelectsGroup
            label="Is the property subject to a net lease?"
            error={hasError('isPropertyToNetLease')}
          >
            <Radiobutton
              value={isPropertyToNetLease}
              name="isPropertyToNetLease"
              selected={false}
              disabled={disabled}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isPropertyToNetLease}
              name="isPropertyToNetLease"
              selected={true}
              disabled={disabled}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>
        {/*  */}

        <HelpTarget name="wasAnyRentalIncome">
          <SelectsGroup
            label={`Was there any rental income in ${reportingYear}?`}
            error={hasError('wasAnyRentalIncome')}
          >
            <Radiobutton
              value={wasAnyRentalIncome}
              name="wasAnyRentalIncome"
              selected={false}
              disabled={disabled}
              onChange={handleOnWasAnyRentalIncomeChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={wasAnyRentalIncome}
              name="wasAnyRentalIncome"
              selected={true}
              disabled={disabled}
              onChange={handleOnWasAnyRentalIncomeChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        {!wasAnyRentalIncome && (
          <HelpTarget name="exclusionCS">
            <DropdownList
              width="392px"
              listWidth="392px"
              label={`Provide the reason for not reporting ${reportingYear} rental income`}
              value={exclusionCS}
              items={createItemsByDicti(RPIEExclusions)}
              itemId="id"
              itemValue="name"
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('exclusionCS')}
            />
          </HelpTarget>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepGeneralPropertyDescription
