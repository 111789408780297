import typography from './typography'
import colors from './colors'
import spacing from './spacing'
import { extendTheme } from '@chakra-ui/react'
import globalStyles from './globalStyles'
import componentsStyles from './componentsStyles'

const defaultTheme = {
  typography: typography,
  spacing: spacing,
  styles: globalStyles,
  components: componentsStyles,

  colors: {
    ...colors,
    primary: {
      base: '#4D59EB',
      hovered: '#273194',
      pressed: '#717CE6',
      disabled: '#D6D6E6',
    },
    secondary: {
      base: '#EEF0FF',
      sideMenu: '#FAFBFF',
      inputGray: '#F5F5F7',
      divider: '#E6E8F0',
      currentStep: '#CED2FF',
    },
    fontnavy: '#0A1250',
    fontandicongray: '#7F83A7',
    red: {
      base: '#FF7564',
      opacity: '#FFE9E8',
    },
    orange: {
      base: '#FFB57B',
      opacity: '#FFF0E6',
    },
    green: {
      base: '#80B925',
      opacity: '#F1F7E7',
    },
    purple: {
      opacity: '#EDEEFD',
    },
  },
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Open Sans", sans-serif',
  },
  lineHeights: {
    caption: '18px',
    body: '20px',
    subtitle: '26px',
    h6: '30px',
    h5: '38px',
    h4: '33px',
  },
  fontWeights: {
    regular: 400,
  },
  fontSizes: {
    caption: '13px',
    body: '15px',
    subtitle: '19px',
    h6: '22px',
    h5: '28px',
    h4: '32px',
  },
  textStyles: {
    h4: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 'semibold',
      fontSize: 'h4',
      lineHeight: 'h4',
      letterSpacing: 0,
    },
    h6: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 'semibold',
      fontSize: 'h6',
      lineHeight: 'h6',
      letterSpacing: 0,
    },
    subtitle: {
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: 'semibold',
      fontSize: 'subtitle',
      lineHeight: 'subtitle',
      letterSpacing: 0,
    },
    body: {
      regular: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'regular',
        fontSize: 'body',
        lineHeight: 'body',
        letterSpacing: 0,
      },
      semibold: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: 'body',
        lineHeight: 'body',
        letterSpacing: 0,
      },
    },
    caption: {
      regular: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'regular',
        fontSize: 'caption',
        lineHeight: 'caption',
      },
      semibold: {
        fontFamily: 'body',
        fontStyle: 'normal',
        fontWeight: 'semibold',
        fontSize: 'caption',
        lineHeight: 'caption',
      },
    },
  },
}

const theme = extendTheme(defaultTheme)

export default theme
