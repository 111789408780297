import { useEffect, useState } from 'react'

const useJustWait = timeout => {
  const [wait, waitSet] = useState(false)
  useEffect(() => {
    const cl = setTimeout(() => waitSet(true), timeout)
    return () => clearTimeout(cl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return wait
}

export default useJustWait
