import React, { memo, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { getCallback } from 'helpers/utils'

import Checkbox from 'ui-framework/components/primitives/Checkbox'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { Text } from '@chakra-ui/react'
import AttentionBox from 'ui-framework/components/primitives/AttentionBox'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import isEqual from 'lodash/isEqual'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

//===================================================
type Props = {
  certified: boolean
} & BaseComponentProps &
  StepPropsInterface

const schema = schemaWrapper({
  certified: schemaRules.BOOLEAN.valid(true),
})

/**
 * StepSubmit component
 */
const StepSubmit = ({
  certified = false,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({
        certified,
      }),
      [certified]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    hasCertifiedError = Boolean(hasError('certified'))

  return (
    <StepBasic className={props.className}>
      <AttentionBox
        title="If you are confident that all data is accurate,
          please read the certification below and press submit.
          Once you submit, you cannot edit the information,
          unless you contact us directly."
        bgColor={
          hasCertifiedError
            ? `red.opacity`
            : certified
            ? `green.opacity`
            : `secondary.sideMenu`
        }
        w="640px"
      >
        <HelpTarget name="helpForTheHelp">
          <Text textStyle="body.regular" lineHeight="24px" color="fontnavy">
            I certify that all information provided herein is true and correct
            to the best of my knowledge and belief, and I understand that the
            information is being relied upon by MGNY Consulting Corp. and the
            City of New York.
          </Text>
        </HelpTarget>
        <Checkbox
          mt="16px"
          checked={certified}
          disabled={disabled}
          onChange={v => getCallback(onChange)({ certified: v })}
          error={hasCertifiedError}
        >
          I agree
        </Checkbox>
      </AttentionBox>
    </StepBasic>
  )
}

export default memo(StepSubmit, isEqual)
