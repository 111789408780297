import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react'
import { isCallback, getCallback, AnyFunction } from 'helpers/utils'
import { wrap, get } from 'object-path-immutable'
import { OnChangeHandler } from 'ui-framework/common/types'
import { HelpBlockProps } from 'ui-framework/components/patterns/HelpBlock'
import fp from 'lodash/fp'
import isEqual from 'lodash/isEqual'
import { getDictiName } from 'constants/dicti'
import { set } from 'lodash'
import { nanoid } from 'nanoid'

export type fnType<T> = (data: any, context?: any) => T
export type fnOrValueType<T> = T | fnType<T>

type Path = { id: string; title?: string }

export type StepSummary = {
  title: string
  value: fnOrValueType<string>
}

export type HelpBlockPropsObject = Record<string, HelpBlockProps> & {
  $step?: HelpBlockProps
  $next?: HelpBlockProps
}

export type StepsConfig = fnOrValueType<
  Array<fnOrValueType<StepsConfig | StepConfig>>
>

export type StepConfigCommon = {
  id: string
  title?: fnOrValueType<string>
  hidden?: fnOrValueType<boolean>
}

export type StepConfigContainer = {
  steps: StepsConfig
} & StepConfigCommon

export type CustomWizardAction = { action: string; params?: any } & {
  [fieldname: string]: any
}
export type NextStepAction = (
  data?: any,
  context?: any
) => CustomWizardAction | undefined | void

export type NextStepOptions = {
  nextButtonTitle?: fnOrValueType<string | undefined>
  saveOnNext?: boolean
  onBefore?: NextStepAction
  onAfter?: NextStepAction
}

export type StepConfigItem = {
  flows?: Array<string | '*'>
  section?: string
  description?: fnOrValueType<string>
  buttons?: fnOrValueType<ReactNode>
  actions?: ReactNode
  nextStepOptions?: fnOrValueType<NextStepOptions>
  component?: any
  hideHelp?: boolean
  // dataMap keys starting with '$' interprets as raw values
  dataMap?: object
  summary?: StepSummary
  disabled?: fnOrValueType<boolean>
  help?: HelpBlockPropsObject
  infoBlock?: AnyFunction | ReactNode
} & StepConfigCommon

export type StepConfig = StepConfigItem | StepConfigContainer

export type Step = {
  id: string
  title: string
  description: string
  buttons: ReactNode
  actions: ReactNode
  section: string
  nextStepOptions: NextStepOptions
  component: React.FunctionComponent<StepPropsInterface>
  hideHelp: boolean
  dataMap: object
  summary?: {
    title: string
    value: string
  }
  disabled: boolean
  path: Path[]
  props: StepPropsInterface
  help: HelpBlockPropsObject
  infoBlock?: AnyFunction | ReactNode
}
export type Steps = Step[]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface StepPropsInterface<T = any, C = any> {
  disabled?: boolean
  onChange: OnChangeHandler<any>
  applyValidation: boolean
  onValidation: OnChangeHandler<boolean>
}

export type UseStepsConfig = (
  stepsConfig: StepsConfig,
  flowFilterValue: any,
  data: any | null,
  context: any | null
) => [Steps, any]

type SectionItem = {
  sectionTitle: string
  stepsIds: Array<string>
  stepsCount: number
}
export type Sections = Array<SectionItem>

// dataMap keys starting with '$' interprets as raw values and must be readonly
const testDataMapKey = (key: string): [boolean, string] => {
  const ro = key.length > 1 && key[0] === '$'
  return [ro, ro ? key.slice(1) : key]
}

const createStep = fp.pick([
  'id',
  'component',
  'hideHelp',
  'help',
  'dataMap',
])

const useStepsConfig: UseStepsConfig & { getUpdatedData: AnyFunction } = (
  stepsConfig,
  flowFilterValue,
  data,
  context
) => {
  const //[resultSections, resultSectionsSet] = useState<Sections>([]),
    config = useMemo(() =>
      (isCallback(stepsConfig)
        ? getCallback(stepsConfig)(data, context)
        : stepsConfig
      ).filter(s =>
        !s.flows ||
        s.flows.includes(flowFilterValue) ||
        s.flows.includes('*')
      ), [stepsConfig, flowFilterValue, context, data])

  const fnOrValCtx = useCallback(value =>
    (isCallback(value) ? value(data, context) : value)
    , [context, data])


  const getSummary = useCallback(summary =>
    summary?.value
      ? {
        title: summary?.title,
        value: fnOrValCtx(summary.value),
      } : undefined
    , [fnOrValCtx])

  const makeStepFromConfig = useCallback((stepConfig): Step =>
    fp.flow(
      createStep,
      fp.set('nextStepOptions', fnOrValCtx(stepConfig.nextStepOptions)),
      fp.set('section', fnOrValCtx(stepConfig.section)),
      fp.set('description', fnOrValCtx(stepConfig.description)),
      fp.set('title', fnOrValCtx(stepConfig.title)),
      fp.set('disabled', fnOrValCtx(stepConfig.disabled)),
      fp.set('buttons', fnOrValCtx(stepConfig.buttons)),
      fp.set('actions', fnOrValCtx(stepConfig.actions)),
      fp.set('infoBlock', fnOrValCtx(stepConfig.infoBlock)),
      fp.set('summary', getSummary(stepConfig.summary))
    )(stepConfig)
    , [createStep, fnOrValCtx, getSummary])

  const getSteps = useCallback((configFn: StepsConfig) =>
    (data): Steps => {
      if (!data) return [] as Steps

      const config = fnOrValCtx(configFn)
      if (!config.length) return [] as Steps

      const shouldAddThisStep = step =>
        !!step?.component && !fnOrValCtx(step.hidden)

      return config
        .map((step: StepConfig) => fnOrValCtx(step))
        .flat(9)
        .map((stepConfig: StepConfig) => {
          if (Array.isArray(stepConfig)) throw new Error('SC')
          if (!shouldAddThisStep(stepConfig)) return []
          return makeStepFromConfig(stepConfig)
        })
    }
    , [fnOrValCtx, makeStepFromConfig])

  // const resultSteps = useMemo(() => {
  //   const result = fp.compose(fp.flattenDeep, getSteps(config))(data)
  //   //@ts-ignore
  //   result.$tag = nanoid()
  //   return result
  // }, [config, data, getSteps])

  // useEffect(() => {
  //   const result = Object.entries(
  //     resultSteps.reduce((acc, s) => {
  //       acc[s.section] = acc[s.section] || []
  //       acc[s.section].push(s.id)
  //       return acc
  //     }, {}))
  //     .map(([k, v]) =>
  //     ({
  //       sectionTitle: getDictiName(k),
  //       stepsIds: v,
  //       stepsCount: (v as []).length,
  //     } as SectionItem))

  //   !isEqual(resultSections, result) && resultSectionsSet(result)
  // }, [resultSteps, resultSections])

  // return [resultSteps, resultSections]

  return useMemo(() => {
    const resultSteps = fp.compose(fp.flattenDeep, getSteps(config))(data)
    //@ts-ignore
    // resultSteps.$tag = nanoid()

    const resultSections = Object.entries(
      resultSteps.reduce((acc, s) => {
        acc[s.section] = acc[s.section] || []
        acc[s.section].push(s.id)
        return acc
      }, {}))
      .map(([k, v]) =>
      ({
        sectionTitle: getDictiName(k),
        stepsIds: v,
        stepsCount: (v as []).length,
      } as SectionItem))

    return [resultSteps, resultSections]
  }, [config, data])
}

useStepsConfig.getUpdatedData = (resultSteps, data) => {
  const dataMapFields = () =>
    Array.from(
      resultSteps.reduce((acc, step) => {
        Object.entries(step.dataMap).forEach(([k, v]) => {
          const [ro] = testDataMapKey(k)
          !ro && acc.add(v)
        })
        return acc
      }, new Set())
    )

  const result = {},
    map = (path, src, dst) => {
      const value = get(src, path, undefined)
      value !== undefined && set(dst, path, value)
    }

  dataMapFields().forEach(path => map(path, data, result))
  return result
}

export default useStepsConfig
