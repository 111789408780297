import { Services } from 'constants/dicti'
import { URL_SERVICETALIST } from 'constants/URLS'
import { CalendarConfig, makeItemsForKey } from 'helpers/config/utils'

const actionText = 'Tax Appeal Service will be Available on December 15'
const actionTextFn = agreement =>
  agreement ? 'Go To My Property List' : 'Subscribe to Tax Appeal Service'

const fallbackUrl = URL_SERVICETALIST

const config: CalendarConfig = {
  '2023-12-01': {
    actionText: actionText,
    fallbackUrl: null,
  },
  '2023-12-15': {
    actionText: actionTextFn,
    fallbackUrl,
  },
  '2024-04-01': {
    actionText,
    fallbackUrl: null,
  },
  '2024-12-15': {
    actionText: actionTextFn,
    fallbackUrl,
  },
  '2025-04-01': {
    actionText,
    fallbackUrl: null,
  },
},
  configHomePageTAActions = makeItemsForKey<Services.TaxAppeal, CalendarConfig>(
    Services.TaxAppeal,
    config
  )

export { configHomePageTAActions }
