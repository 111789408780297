import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'

const schema = schemaWrapper({
  annualRentPaidToOwner: schemaRules.MIN_NUMBER(1),
  isGroundLessor: schemaRules.BOOLEAN,
  payUtilityExpenses: schemaRules.BOOLEAN,
  payRepairExpenses: schemaRules.BOOLEAN,
  paySpaceOccupied: schemaRules.BOOLEAN,
  isSubleasingProperty: schemaRules.BOOLEAN,
  groundLeaseAmount: Joi.when('isGroundLessor', {
    is: true,
    then: schemaRules.MIN_NUMBER(1),
    otherwise: schemaRules.ANY,
  }),
  subleasingAreaSF: Joi.when('isSubleasingProperty', {
    is: true,
    then: schemaRules.MIN_NUMBER(1),
    otherwise: schemaRules.ANY,
  }),
  annualRent: Joi.when('isSubleasingProperty', {
    is: true,
    then: schemaRules.MIN_NUMBER(1),
    otherwise: schemaRules.ANY,
  }),
  useOfSpaceCS: Joi.when('isSubleasingProperty', {
    is: true,
    then: schemaRules.NOT_EMPTY_STRING,
    otherwise: schemaRules.ANY,
  }),
})

export default schema
