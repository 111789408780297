import React, { createContext } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { useCheckedContext } from 'hooks/useApplicationContext'

type MainMenuProviderContext = UseDisclosureReturn | undefined
const MainMenuContext = createContext<MainMenuProviderContext>(undefined)

//===================================================
type MainMenuProviderProps = {} & BaseComponentProps

/**
 * MainMenuProvider component
 */
const MainMenuProvider = (props: MainMenuProviderProps) => {
  const mainMenu = useDisclosure()

  return (
    <MainMenuContext.Provider value={mainMenu}>
      {props.children}
    </MainMenuContext.Provider>
  )
}

const useMainMenu = () =>
  useCheckedContext<UseDisclosureReturn>(MainMenuContext)

export default MainMenuProvider
export { useMainMenu }
