import React, { memo, ReactNode } from 'react'
import {
  Tooltip,
  useMultiStyleConfig,
  Flex,
  chakra,
  ChakraProps,
} from '@chakra-ui/react'
import Icon, { IconProps } from '../Icon'
import { ChakraComponentProps } from 'ui-framework/common/types'
import isEqual from 'lodash/isEqual'

type PromptProps = {
  type?: 'warning' | 'information'
  label: string | ReactNode
  iconParams?: IconProps
  wrapper?: ChakraProps
} & ChakraComponentProps

let icon

const Prompt = (props: PromptProps) => {
  const { type } = props,
    style = useMultiStyleConfig('MGNYPrompt', props)

  switch (type) {
    case 'warning':
      icon = 'info-circle'
      break
    default:
      icon = 'prompt-circle'
      break
  }

  return (
    <Flex sx={style.wrapper} {...props.wrapper}>
      {props.children}
      <Tooltip
        sx={style.tooltip}
        hasArrow
        bg="white100"
        placement="bottom"
        {...props}
      >
        <div style={{ display: 'flex' }}>
          <Icon {...props.iconParams}>{icon}</Icon>
        </div>
      </Tooltip>
    </Flex>
  )
}

export default memo(chakra(Prompt) as any, isEqual)
