import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import Input from 'ui-framework/components/primitives/Input'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { HStack, VStack } from '@chakra-ui/layout'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import { getNameAndErrorProps } from 'helpers/utils'

//===================================================
type Props = {
  unitNumber: number
  isUnitApartmentNumberDifferent: boolean
  isAddressDifferent: boolean
  unitApartmentNumber: string
  streetName: string
  houseNo: string
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepStorefrontUnitGeneral1Step component
 */
const StepStorefrontUnitGeneral1Step = ({
  unitNumber,
  isUnitApartmentNumberDifferent,
  isAddressDifferent,
  unitApartmentNumber,
  streetName,
  houseNo,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({
        unitApartmentNumber,
        houseNo,
        streetName,
        isAddressDifferent,
        isUnitApartmentNumberDifferent,
      }),
      [
        unitApartmentNumber,
        houseNo,
        streetName,
        isAddressDifferent,
        isUnitApartmentNumberDifferent,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px">
        <ReviewLastYearDataWarningBlock />

        <HelpTarget name="isUnitApartmentNumberDifferent">
          <SelectsGroup label="Does this unit have a specific unit designation?">
            <Radiobutton
              value={isUnitApartmentNumberDifferent}
              name="isUnitApartmentNumberDifferent"
              selected={false}
              disabled={disabled}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isUnitApartmentNumberDifferent}
              name="isUnitApartmentNumberDifferent"
              selected={true}
              disabled={disabled}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        {isUnitApartmentNumberDifferent && (
          <HelpTarget name="unitApartmentNumber">
            <Input
              label="Unit Designation"
              type="text"
              value={unitApartmentNumber}
              placeholder="Enter information"
              disabled={disabled}
              fixedHeight
              onChange={onChange}
              {...nameAndErrorProps('unitApartmentNumber')}
            />
          </HelpTarget>
        )}

        <HelpTarget name="isAddressDifferent">
          <SelectsGroup label="Does this unit have a different address than above?">
            <Radiobutton
              value={isAddressDifferent}
              name="isAddressDifferent"
              selected={false}
              disabled={disabled}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isAddressDifferent}
              name="isAddressDifferent"
              selected={true}
              disabled={disabled}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        {isAddressDifferent && (
          <HStack spacing="36px">
            <HelpTarget name="houseNo">
              <Input
                label="House Number"
                type="text"
                value={houseNo}
                placeholder="Enter #"
                disabled={disabled}
                onChange={onChange}
                {...nameAndErrorProps('houseNo')}
              />
            </HelpTarget>
            <HelpTarget name="streetName">
              <Input
                width="320px"
                label="Street Name"
                type="text"
                value={streetName}
                placeholder="Enter street name"
                disabled={disabled}
                onChange={onChange}
                {...nameAndErrorProps('streetName')}
              />
            </HelpTarget>
          </HStack>
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepStorefrontUnitGeneral1Step
