import React from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'

import { OnClickHandler } from 'ui-framework/common/types'
import { getCallback } from 'helpers/utils'

//===================================================
type MonthViewItemWrapperProps = {}
const MonthViewItemWrapper = styled('div')<MonthViewItemWrapperProps>(
  props => ({
    width: '36px',
    height: '36px',
    boxSizing: 'border-box',
    padding: '3px 6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
)

export type MonthViewItemProps = {
  onClick?: OnClickHandler
} & BaseComponentProps

/**
 * MonthViewItem component
 */
const MonthViewItem = ({ onClick, ...props }: MonthViewItemProps) => {
  return (
    <MonthViewItemWrapper
      className={props.className}
      onClick={getCallback(onClick)}
    >
      {props.children}
    </MonthViewItemWrapper>
  )
}

export default MonthViewItem
