import React, { ReactNode } from 'react'
import { ChakraComponentProps } from 'ui-framework/common/types'
import AbstractInput, { AbstractInputProps } from '../AbstractInput'
import TextareaBase, { TextareaBaseProps } from './TextareaBase'

type TextareaProps = {
  label?: string | ReactNode
} & ChakraComponentProps &
  TextareaBaseProps &
  AbstractInputProps

const Textarea = (props: TextareaProps) => {
  const { value } = props
  return (
    <AbstractInput
      className={props.className}
      value={value}
      label={props.label}
      error={props.error}
      disabled={props.disabled}
      width={props.width}
      variant={'textarea'}
      resize={props.resize}
      errorTooltip={props.errorTooltip}
    >
      <TextareaBase {...props} />
    </AbstractInput>
  )
}

export default Textarea
