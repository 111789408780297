import { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { ReportingPeriodType } from 'constants/dicti'

//==================================================

type ReportingPeriodConfig = {
  value?: Date | null
  min?: Date
  max?: Date
  defaultDate?: Date | string
  disabled: boolean
}

type UseReportingPeriodProps = {
  startDate?: Date | null
  endDate?: Date | null
  reportingYear: number
  reportingPeriodTypeCS: string
  disabled?: boolean
}

const createConfig =
  (disabled: boolean) =>
  (
    value?: Date | null,
    min?: Date,
    max?: Date,
    defaultDate?: Date | string
  ): ReportingPeriodConfig => ({
    value,
    min,
    max,
    disabled,
    defaultDate,
  })

const useReportingPeriod = ({
  startDate,
  endDate,
  reportingYear,
  reportingPeriodTypeCS,
  disabled,
}: UseReportingPeriodProps): [ReportingPeriodConfig, ReportingPeriodConfig] => {
  const [isTypeCalendar, isTypePartial, isTypeFiscal] = useMemo(
      () => [
        reportingPeriodTypeCS === ReportingPeriodType.Calendar,
        reportingPeriodTypeCS === ReportingPeriodType.Partial,
        reportingPeriodTypeCS === ReportingPeriodType.Fiscal,
      ],
      [reportingPeriodTypeCS]
    ),
    getDate = useCallback(
      ({ year = reportingYear, month = 0, day = 1 } = {}): any =>
        new Date(year, month, day),
      [reportingYear]
    )

  const [
    startDateValue,
    defaultStartDate,
    minStartDate,
    maxStartDate,
    minEndDate,
    endDateValue,
  ] = useMemo(() => {
    const prevReportingYear = { year: reportingYear - 1 },
      partialAndFiscalRule =
        isTypePartial || (isTypeFiscal && prevReportingYear)
    return [
      isTypeCalendar ? getDate() : startDate,
      startDate || getDate(isTypePartial && (prevReportingYear as any)),
      getDate(partialAndFiscalRule as any),
      getDate(
        isTypePartial || isTypeFiscal
          ? {
              month: 11,
              day: 31,
            }
          : { day: 5 }
      ),
      dayjs(startDate || getDate(partialAndFiscalRule as any))
        .add(isTypePartial ? 3 : 6, 'months')
        .toDate(),
      isTypeCalendar
        ? getDate({
            month: 11,
            day: 31,
          })
        : isTypeFiscal
          ? dayjs(startDate).add(1, 'year').subtract(1, 'day').toDate()
          : endDate,
    ]
  }, [
    getDate,
    reportingYear,
    isTypeCalendar,
    isTypePartial,
    isTypeFiscal,
    startDate,
    endDate,
  ])

  return [
    createConfig(disabled || isTypeCalendar)(startDateValue, minStartDate, maxStartDate, defaultStartDate),
    createConfig(disabled || isTypeCalendar || isTypeFiscal)(endDateValue, minEndDate, dayjs().year(reportingYear).endOf('year').toDate()),
  ]
}

export default useReportingPeriod
