import React, { ReactNode } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import {
  useMultiStyleConfig,
  StylesProvider,
  Flex,
  Box,
} from '@chakra-ui/react'
import Message from '../Message'

type Props = {
  /**
   * type of label
   */
  label?: string | ReactNode
  /**
   * type of state
   */
  error?: string
  /**
   * disabled state
   */
  disabled?: boolean
  /**
   * preserve place for label and message
   */
  preserveHeight?: boolean
  /**
   * Width
   * @default '100%''
   */
  width?: string | number
  variant?: 'horizontal' | 'vertical'
} & BaseComponentProps

const SelectsGroup = (props: Props) => {
  const { label, error, width = '100%', preserveHeight = true, variant } = props
  const styles = useMultiStyleConfig('MGNYSelectsGroup', { variant, ...props })

  return (
    <Box sx={styles.wrapper} className={props.className}>
      <Box //TODO: must be made a component
        sx={styles.title}
      >
        {label}
      </Box>
      {(error || preserveHeight) && (
        <Box sx={styles.message}>
          <Message error={error} width={width} data-role="message">
            {error}
          </Message>
        </Box>
      )}
      <Flex sx={styles.groupWrapper}>
        <StylesProvider value={styles}>{props.children}</StylesProvider>
      </Flex>
    </Box>
  )
}

export default SelectsGroup
