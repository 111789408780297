import React, { useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { VStack } from '@chakra-ui/react'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import Textarea from 'ui-framework/components/primitives/Textarea'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { getNameAndErrorProps } from 'helpers/utils'

//===================================================
type Props = {
  isNewPropertyForThisYear: boolean
  hasPrevValueInIncomeFromRentalTenants: boolean
  wasIncomeFromRentalTenants: boolean
  isAnySpaceRelatedToOperator: boolean
  anySpaceRelatedToOperatorDescription: string
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepNonDepartmentalOperation component
 */
const StepNonDepartmentalOperation = ({
  isNewPropertyForThisYear,
  hasPrevValueInIncomeFromRentalTenants,
  wasIncomeFromRentalTenants,
  isAnySpaceRelatedToOperator,
  anySpaceRelatedToOperatorDescription,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({
        needWasIncomeFromRentalTenants:
          hasPrevValueInIncomeFromRentalTenants || wasIncomeFromRentalTenants,
        wasIncomeFromRentalTenants,
        isAnySpaceRelatedToOperator,
        anySpaceRelatedToOperatorDescription,
        isNewPropertyForThisYear,
      }),
      [
        hasPrevValueInIncomeFromRentalTenants,
        wasIncomeFromRentalTenants,
        isAnySpaceRelatedToOperator,
        anySpaceRelatedToOperatorDescription,
        isNewPropertyForThisYear,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )
  return (
    <StepBasic className={props.className}>
      <VStack width="568px" spacing="36px" align="flex-start">
        <VStack width="480px" spacing="36px" align="flex-start">
          {(isNewPropertyForThisYear ||
            hasPrevValueInIncomeFromRentalTenants) && (
            <HelpTarget name="wasIncomeFromRentalTenants">
              <SelectsGroup
                label={
                  'Was there any income collected from rental tenants (exclude tenants related to hotel operator)?'
                }
                disabled={disabled}
                error={hasError('wasIncomeFromRentalTenants')}
              >
                <Radiobutton
                  value={wasIncomeFromRentalTenants}
                  selected={false}
                  name="wasIncomeFromRentalTenants"
                  disabled={disabled}
                  onChange={onChange}
                >
                  No
                </Radiobutton>
                <Radiobutton
                  value={wasIncomeFromRentalTenants}
                  selected={true}
                  name="wasIncomeFromRentalTenants"
                  disabled={disabled}
                  onChange={onChange}
                >
                  Yes
                </Radiobutton>
              </SelectsGroup>
            </HelpTarget>
          )}

          <SelectsGroup
            label={
              'Is any space leased to persons related to the hotel operator?'
            }
            disabled={disabled}
            error={hasError('isAnySpaceRelatedToOperator')}
          >
            <Radiobutton
              value={isAnySpaceRelatedToOperator}
              selected={false}
              name="isAnySpaceRelatedToOperator"
              disabled={disabled}
              onChange={onChange}
            >
              No
            </Radiobutton>
            <Radiobutton
              value={isAnySpaceRelatedToOperator}
              selected={true}
              name="isAnySpaceRelatedToOperator"
              disabled={disabled}
              onChange={onChange}
            >
              Yes
            </Radiobutton>
          </SelectsGroup>
        </VStack>

        {isAnySpaceRelatedToOperator && (
          <Textarea
            label={
              'Describe lease arrangement and relationship of lessee to hotel operator.'
            }
            value={anySpaceRelatedToOperatorDescription}
            placeholder="Enter"
            disabled={disabled}
            width={'100%'}
            rows={5}
            showCounter={false}
            onChange={onChange}
            {...getNameAndErrorProps(
              'anySpaceRelatedToOperatorDescription',
              hasError
            )}
          />
        )}
      </VStack>
    </StepBasic>
  )
}

export default StepNonDepartmentalOperation
