import { ComponentStyleConfig } from '@chakra-ui/theme'
import { isBoolean } from 'lodash-es'
import isString from 'lodash/isString'

const ChipsStyle: ComponentStyleConfig = {
  // Styles for the base style
  baseStyle: props => ({
    display: 'inline-flex',
    minWidth: '62px',
    borderWidth: props.withState ? '1px' : '0px',
    borderStyle: 'solid',
    fontFamily: 'body',
    fontSize: 'body',
    lineHeight: 'body',
    color: 'fontnavy',
    alignItems: 'center',
    '&:before': {
      content: '""',
      display: isBoolean(props.prefix) && props.prefix ? 'flex' : 'none',
    },
    '.icon': {
      display: isString(props.prefix) || props.postfix ? 'block' : 'none',
    },
    _disabled: {
      '.icon': {
        display: 'none',
      },
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      height: '32px',
      borderRadius: '16px',
      padding: !props.prefix
        ? '5px 16px 7px'
        : !isBoolean(props.prefix)
        ? '4px 12px 4px 4px'
        : '10px 16px 10px 10px',
      '&:before': {
        width: '12px',
        height: '12px',
        borderRadius: '6px',
      },
      '.chakra-avatar': {
        width: '24px',
        height: '24px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {
    primary: props => ({
      backgroundColor: !props.withState ? 'purple.opacity' : 'transparent',
      borderColor: 'purple.opacity',
      _hover: {
        borderColor: props.withState ? 'primary.base' : 'inherit',
        '.icon': {
          svg: {
            fill: 'primary.base',
          },
        },
      },
      _disabled: {
        borderColor: !props.onClick ? 'inherit' : 'primary.disabled',
        color: props.withState ? 'fontandicongray' : 'inherit',
      },
      _active: {
        borderColor: props.withState ? '#A2AAFF' : 'inherit',
        '.icon': {
          svg: {
            fill: '#A2AAFF',
          },
        },
      },
      '&:before': {
        backgroundColor: 'primary.base',
      },
      '.icon': {
        svg: {
          fill: !props.withState ? 'primary.base' : 'fontandicongray',
        },
      },
    }),
    primaryOutlined: props => ({
      backgroundColor: 'secondary.inputGray',
      borderWidth: '1px',
      borderColor: 'primary.base',
      _hover: {
        borderColor: props.withState ? 'primary.base' : 'inherit',
        '.icon': {
          svg: {
            fill: 'primary.base',
          },
        },
      },
      _disabled: {
        borderColor: !props.onClick ? 'inherit' : 'primary.disabled',
        color: props.withState ? 'fontandicongray' : 'inherit',
      },
      _active: {
        borderColor: props.withState ? '#A2AAFF' : 'inherit',
        '.icon': {
          svg: {
            fill: '#A2AAFF',
          },
        },
      },
      '&:before': {
        backgroundColor: 'primary.base',
      },
      '.icon': {
        svg: {
          fill: !props.withState ? 'primary.base' : 'fontandicongray',
        },
      },
    }),
    green: props => ({
      backgroundColor: 'green.opacity',
      borderColor: 'green.opacity',
      _hover: {
        borderColor: props.withState ? 'green.base' : 'inherit',
        '.icon': {
          svg: {
            fill: 'green.base',
          },
        },
      },
      _disabled: {
        color: props.withState ? 'fontandicongray' : 'inherit',
        borderColor: 'transparent',
      },
      _active: {
        borderColor: props.withState ? 'green.base' : 'inherit',
        '.icon': {
          svg: {
            fill: 'green.base',
          },
        },
      },
      '&:before': {
        backgroundColor: 'green.base',
      },
      '.icon': {
        svg: {
          fill: 'green.base',
        },
      },
    }),
    red: props => ({
      backgroundColor: 'red.opacity',
      borderColor: 'red.opacity',
      _hover: {
        borderColor: props.withState ? 'red.base' : 'inherit',
        '.icon': {
          svg: {
            fill: 'red.base',
          },
        },
      },
      _disabled: {
        color: props.withState ? 'fontandicongray' : 'inherit',
        borderColor: 'transparent',
      },
      _active: {
        borderColor: props.withState ? '#FFB9B0' : 'inherit',
        '.icon': {
          svg: {
            fill: props.withState ? '#FFB9B0' : 'red.base',
          },
        },
      },
      '&:before': {
        backgroundColor: 'red.base',
      },
      '.icon': {
        svg: {
          fill: 'red.base',
        },
      },
    }),
    orange: props => ({
      backgroundColor: 'orange.opacity',
      borderColor: 'orange.opacity',
      _hover: {
        borderColor: props.withState ? 'orange.base' : 'inherit',
        '.icon': {
          svg: {
            fill: 'orange.base',
          },
        },
      },
      _disabled: {
        color: props.withState ? 'fontandicongray' : 'inherit',
        borderColor: 'transparent',
      },
      _active: {
        borderColor: props.withState ? '#FFCCA6' : 'inherit',
        '.icon': {
          svg: {
            fill: props.withState ? '#FFCCA6' : 'orange.base',
          },
        },
      },
      '&:before': {
        backgroundColor: 'orange.base',
      },
      '.icon': {
        svg: {
          fill: 'orange.base',
        },
      },
    }),
    gray: props => ({
      backgroundColor: isString(props.prefix)
        ? 'purple.opacity'
        : 'secondary.inputGray',
      borderColor: 'secondary.inputGray',
      _hover: {
        borderColor: props.withState ? 'fontandicongray' : 'inherit',
        '.icon': {
          svg: {
            fill: 'fontandicongray',
          },
        },
      },
      _disabled: {
        color: props.withState ? 'fontandicongray' : 'inherit',
        borderColor: 'transparent',
      },
      _active: {
        borderColor: props.withState ? '#A5ABFA' : 'inherit',
        '.icon': {
          svg: {
            fill: props.withState ? '#A5ABFA' : 'fontandicongray',
          },
        },
      },
      '&:before': {
        backgroundColor: 'fontandicongray',
      },
      '.icon': {
        svg: {
          fill: 'fontandicongray',
        },
      },
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
}

export default ChipsStyle
