import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { OnClickHandler } from 'ui-framework/common/types'
import { Flex, useMultiStyleConfig, Box, Divider } from '@chakra-ui/react'
import Icon from 'ui-framework/components/primitives/Icon'
import { isCallback } from 'helpers/utils'
import AboutAssistantTooltip from 'ui-framework/components/patterns/HelpBlock/AboutAssistantTooltip'
import OnboardingPopup from 'hooks/onbording/OnboardingPopup'
import useAssistantOnboarding from 'ui-framework/components/patterns/HelpBlock/useAssistantOnboarding'
import { useScrollYPosition } from 'hooks/useScrollPosition'
import useAssistant from 'ui-framework/components/patterns/HelpBlock/useAssistant'

//===================================================

type HelpItem = {
  header: string
  content: string
}

export type HelpBlockProps = {
  title?: string
  description?: string | ReactElement | ReactNode
  details?: string
  items?: HelpItem[]
  assistantHeader?: string
}

type Props = {
  hideHelp?: boolean
  onClick?: OnClickHandler
} & BaseComponentProps &
  HelpBlockProps

/**
 * HelpBlock component
 */
const HelpBlock = ({
  hideHelp,
  assistantHeader = 'Assistant',
  title,
  description,
  ...props
}: Props) => {
  const scrollY = useScrollYPosition(),
    helpBlock = useAssistant(),
    pageOffset = window.pageYOffset

  const styleProps = useMemo(
    () => ({
      variant: helpBlock.isOpen ? 'open' : 'hidden',
      fixed: pageOffset > 150,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [helpBlock.isOpen, pageOffset, scrollY]
  ),
    style = useMultiStyleConfig('MGNYHelpBlock', { ...styleProps, ...props })

  useEffect(() => {
    hideHelp && helpBlock.onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideHelp])

  const [assistantRef, showOnboardingPopup, onAssistantOnboardingPopupClose] =
    useAssistantOnboarding(helpBlock.isOpen)

  return (
    <>
      <Flex sx={style.wrapper} ref={assistantRef as any}>
        <Box
          tabIndex={0}
          as={`button`}
          sx={style.switcher}
          onClick={helpBlock.onToggle}
        >
          Assistant
        </Box>
        {helpBlock.isOpen && (
          <Box sx={style.innerWrapper}>
            <Flex sx={style.boxHeader}>
              {assistantHeader}
              <Icon tabIndex={0} size={`xs`} onClick={helpBlock.onToggle}>
                close
              </Icon>
            </Flex>
            <Divider />
            <Box sx={style.boxHeader}>{title}</Box>
            <Box sx={style.boxDesc}>
              {isCallback(description) ? (description as any)() : description}
            </Box>
          </Box>
        )}
      </Flex>
      <OnboardingPopup
        isOpen={showOnboardingPopup}
        targetRef={assistantRef as any}
        pointRef={assistantRef as any}
      >
        <AboutAssistantTooltip onClose={onAssistantOnboardingPopupClose} />
      </OnboardingPopup>
    </>
  )
}

export default HelpBlock
