import React from 'react'
import PropertyNotFoundImage from './PropertyNotFoundImage.png'
import BaseComponentProps from 'common/BaseComponentProps'
import { AnyFunction } from 'helpers/utils'
import ErrorPage from 'pages/Errors/ErrorPage'

type Props = {
  fallback: AnyFunction
} & BaseComponentProps

/**
 * PropertyNotFoundPage component
 */
const PropertyNotFoundPage = ({ fallback }: Props) => {
  return (
    <ErrorPage
      image={PropertyNotFoundImage}
      title="Property Not Found"
      description="We couldn't find the Property you are looking for, please contact us if you have any questions."
      fallback={fallback}
      fallbackText="Back to Property List"
    />
  )
}

export default PropertyNotFoundPage
