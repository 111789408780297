import { ReactNode, useCallback } from 'react'
import firebaseApp from 'helpers/firebaseInit'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import { FunctionNames, DocumentTypeTC, DocumentType } from 'constants/dicti'
import { Doc, DocFile } from './useGetDocs'
import Loader from 'ui-framework/components/primitives/Loader'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import useDelayedCallback from '../common/useDelayedCallback'
import usePermission from 'hooks/usePermission'
import { UserRole } from 'constants/grants'
import useBlockedPopupIssueDialog from '../common/useBlockedPopupIssueDialog'
import { getCallback, moreOrEqualCount, sanitizePath } from 'helpers/utils'
import useAuth from 'hooks/useAuth'
import dayjs from 'dayjs'

type DownloadDialogConfig = {
  actionText: string
  title: string | ReactNode
  children: string | ReactNode
}

export type DocumentWithFiles = {
  docTitle?: string
  isConsolidated?: boolean
} & Doc

type UseDownloadFilesResult = {
  downloadFilesHandler: (
    document: DocumentWithFiles[] | DocumentWithFiles
  ) => Promise<void> | any
  downloadDialog: ReactNode
  blockedPopupIssueDialog: ReactNode
}

const COUNT_FOR_ZIPPING_FILES = 3,
  FOLDER_INSTRUCTIONS_IN_ZIP = '/!!!INSTRUCTION - READ FIRST/',
  defaultConfig = {
    actionText: 'Ok',
    title: 'Preparing your files',
    children: (
      <Flex justify="flex-start" p="20px 0">
        <Box h="20px" w="20px" m=" 0 12px 0 0">
          <Loader size="small" />
        </Box>
        <TextFromParams
          params={{
            lineHeight: '20px',
            height: '20px',
            textAlign: 'left',
          }}
        >
          Zipping files
        </TextFromParams>
      </Flex>
    ),
  },
  formsType: string[] = Object.values(DocumentTypeTC),
  getLinks = (files: DocFile[]) => files.map(f => f.filePath).filter(x => x),
  getItems = (isWebClient: boolean) => (acc, document: DocumentWithFiles) => {
    const // folder = `/${replaceByRuleToItem(
      //   document.docTitle || 'folder'
      // )}/${sanitizePath(getDictiName(document.typeCS))}/`,
      consolidatedTC201 =
        !isWebClient &&
        document?.isConsolidated &&
        document.typeCS === DocumentType.TC201 &&
        DocumentTypeTC.TC201Cons,
      instructionId = isWebClient
        ? DocumentTypeTC.TCWeb
        : consolidatedTC201 || document.typeCS,
      instructionPath = `documentTypes/${
        consolidatedTC201 ? DocumentTypeTC.TC201 : instructionId
      }/documents/${instructionId}_INSTRUCTION`

    //acc.content['/' || folder]
    acc.content['/'] = (acc.content['/'] || []).concat(getLinks(document.files))

    acc.refPaths = [...acc.refPaths, document?.ref?.path]

    !acc.content[FOLDER_INSTRUCTIONS_IN_ZIP].includes(instructionPath) &&
      formsType.includes(document.typeCS) &&
      acc.content[FOLDER_INSTRUCTIONS_IN_ZIP].push(instructionPath)

    return acc
  }

export const useDownloadFiles = (
  downloadDialogConfig: DownloadDialogConfig = defaultConfig
): UseDownloadFilesResult => {
  const dialog = useDisclosure(),
    { effectiveProfile } = useAuth(),
    permission = usePermission(),
    isWebClient = permission.hasRole(UserRole.USER_WEB),
    delayedCallback = useDelayedCallback(dialog.onClose, 3000),
    [blockedPopupIssueDialog, checkBrowserBlock] = useBlockedPopupIssueDialog(),
    downloadFilesHandler = useCallback(
      async (documents: DocumentWithFiles[] | DocumentWithFiles) => {
        const params = (
            Array.isArray(documents) ? documents : [documents]
          ).reduce(getItems(isWebClient), {
            filename: `${sanitizePath(
              effectiveProfile!.lastName,
              '_'
            )}_mgny_documents_${dayjs().format('YYYYMMDDHHmmss')}.zip`,
            refPaths: [],
            content: { [FOLDER_INSTRUCTIONS_IN_ZIP]: [] },
          }),
          needToZipped = moreOrEqualCount(params, COUNT_FOR_ZIPPING_FILES)

        // console.log(params)
        needToZipped && dialog.onOpen()

        const callback = delayedCallback(),
          resultPromise = firebaseApp
            .functions()
            .httpsCallable(FunctionNames.DOWNLOAD_URL_FOR_FILE_OR_ZIP)(params),
          result = await resultPromise

        result?.data?.downloadUrl &&
          getCallback(checkBrowserBlock)(result?.data?.downloadUrl)

        needToZipped && callback()

        return resultPromise
      },
      [
        checkBrowserBlock,
        delayedCallback,
        dialog,
        effectiveProfile,
        isWebClient,
      ]
    ),
    downloadDialog = (
      <ModalDialog
        izLazy
        title={downloadDialogConfig.title}
        isOpen={dialog.isOpen}
        size="w580"
      >
        {downloadDialogConfig.children}
      </ModalDialog>
    )

  return {
    downloadFilesHandler,
    downloadDialog,
    blockedPopupIssueDialog,
  }
}
