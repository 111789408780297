import React from 'react'
import Stub from './stub.png'
import {
  Box,
  chakra,
  useMediaQuery,
  VStack,
  Button,
  Image,
} from '@chakra-ui/react'
import Logo from 'ui-framework/components/layout/MainLayout/Logo'
import SimpleLayoutCentered from 'ui-framework/components/layout/SimpleLayout/SimpleLayoutCentered'
import CenterForm from 'pages/Auth/CenterForm'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

const StubFormTitle = chakra('div', {
  baseStyle: {
    textStyle: 'body.semibold',
    lineHeight: '25px',
    textAlign: 'center',
    marginBottom: '12px',
    color: 'fontnavy',
  },
})

const StubFormContent = chakra('div', {
  baseStyle: {
    textStyle: 'body.regular',
    lineHeight: '25px',
    textAlign: 'center',
    color: 'fontnavy',
  },
})
/**
 * StubPage component
 */
const StubPage = props => {
  const [isLargerThan767] = useMediaQuery('(min-width: 767px)'),
    formWidth = isLargerThan767 ? '411px' : '343px'

  return (
    <SimpleLayoutCentered>
      <Logo width={'72px'} height={'64px'} margin={'24px auto 45px'} />
      <CenterForm width={formWidth} padding="20px">
        <VStack align="center" spacing="40px" width="100%">
          <Image
            src={Stub}
            width={`90px`}
            height={`120px`}
            marginLeft={'20px'}
          />
          <Box>
            <StubFormTitle>
              Ooops! Our portal does not support mobile devices right now. You
              must access the portal using your computer.
            </StubFormTitle>
            <StubFormContent>
              We are currently working on the mobile version. Contact us if you
              have any questions.
            </StubFormContent>
          </Box>
          <Button
            width={`100%`}
            onClick={() => window.open(`tel:${SUPPORT_CONTACTS.supportTel}`, '_self')}
          >
            {SUPPORT_CONTACTS.supportTel}
          </Button>
        </VStack>
      </CenterForm>
    </SimpleLayoutCentered>
  )
}

export default StubPage
