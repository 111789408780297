import { UserRole } from 'constants/grants'
import { UserProfile } from 'hooks/useAuth'
import useQueryParamState from 'hooks/useQueryParamState'
import { useEffect, useState } from 'react'

type UseEffectiveProfileEmailResult = {
  reset: (email?: string) => void
  email?: string
}
const useEffectiveProfileEmail = (
  originUser?: UserProfile
): UseEffectiveProfileEmailResult => {
  const [subject, subjectSet] = useQueryParamState('subject'),
    [effectiveEmail, effectiveEmailSet] = useState<string>()

  useEffect(() => {
    if (originUser?.email && originUser?.roles?.includes(UserRole.ADMIN)) {
      if (!effectiveEmail && !!subject) effectiveEmailSet(subject)
      else if (
        !!effectiveEmail &&
        effectiveEmail !== originUser.email &&
        effectiveEmail !== subject
      )
        subjectSet(effectiveEmail)
    } else {
      // !!subject && subjectSet(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originUser?.email, subject, subjectSet])

  return {
    reset: email => {
      effectiveEmailSet(email)
      subjectSet(email)
    },
    email: effectiveEmail,
  }
}

export default useEffectiveProfileEmail
