import React, { ReactNode } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { useMultiStyleConfig } from '@chakra-ui/system'
import { Slide, Box, Text } from '@chakra-ui/react'

//===================================================
export enum NBAR_TYPE {
  WARNING = 'warning',
  ERROR = 'error',
}

type NotificationBarProps = {
  variant?: NBAR_TYPE
  text?: string | ReactNode | null
} & BaseComponentProps

/**
 * NotificationBar component
 */
const NotificationBar = ({
  variant = NBAR_TYPE.WARNING,
  text = null,
  ...props
}: NotificationBarProps) => {
  const style = useMultiStyleConfig('MGNYNotificationBar', {
    variant,
    text,
  })

  return (
    <Box className={props.className} sx={style.wrapper}>
      <Slide
        direction={'top'}
        in={text !== null}
        unmountOnExit={true}
        style={style.slide as any}
      >
        <Text sx={style.box}>{text}</Text>
      </Slide>
    </Box>
  )
}

export default NotificationBar
