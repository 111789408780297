import { useCallback, useMemo } from 'react'
import usePermission from 'hooks/usePermission'

import { Resource, Action, UserRole } from 'constants/grants'
import { Permission } from 'role-acl'
import { URL_DOCUMENTS, URL_HOME } from 'constants/URLS'
import useAuth from 'hooks/useAuth'
import NoPermissionPage from 'pages/Errors/NoPermissionPage'
import MainLayout from 'ui-framework/components/layout/MainLayout'
import { Navigate } from 'react-router-dom'

//==================================

type useRouteByPermissionProps = {
  resource: Resource
  action: Action
}

const fallbackUrlByRole = {
  [UserRole.USER_WEB]: URL_HOME,
  [UserRole.ADMIN]: URL_HOME,
  [UserRole.USER_EMAIL]: URL_DOCUMENTS,
}

const useRouteByPermission = (props: useRouteByPermissionProps) => {
  const permissions = usePermission(),
    { profile } = useAuth(),
    roles = useMemo(() => profile?.roles || [], [profile]),
    checkRouteByPermission = useCallback(
      routeComponent => {
        const hasPermisson = (
          permissions
            .sync()
            .execute(props.action)
            .on(props.resource) as Permission
        ).granted

        return hasPermisson ? (
          routeComponent
        ) : fallbackUrlByRole[roles[0]] ? (
          <Navigate to={fallbackUrlByRole[roles[0]]} />
        ) : (
          <MainLayout>
            <NoPermissionPage />
          </MainLayout>
        )
      },
      [permissions, roles, props.action, props.resource]
    )
  return checkRouteByPermission
}

export default useRouteByPermission
// <NoPermissionPage />
