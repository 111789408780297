import { Services } from 'constants/dicti'
import dayjs from 'dayjs'
import {
  CalendarConfig,
  getDateByItems as gdi,
  makeItemsForKey,
} from 'helpers/config/utils'
import { formatFullDate } from 'helpers/formatter'
import { appBuildYear } from 'services/common/config/calendar'

const getDateByItems = gdi(appBuildYear)

const deadlineAction = currentDate =>
  // Apr 18 or after
  dayjs(currentDate)
    // .year(year)
    .isSameOrAfter(getDateByItems(18, 3, false))

const config: CalendarConfig = {
    '2024-03-01': {
      // March 1 -> May 2
      deadlineMessage: formatFullDate(getDateByItems(2, 4)),
      deadlineAction,
    },
    '2024-05-03': {
      // May 3 -> May 9
      deadlineMessage: formatFullDate(getDateByItems(9, 4)),
      deadlineAction,
    },
    '2024-05-10': {
      // May 10 -> May 16
      deadlineMessage: formatFullDate(getDateByItems(16, 4)),
      deadlineAction,
    },
    '2024-05-17': {
      // May 17 -> May 23
      deadlineMessage: formatFullDate(getDateByItems(23, 4)),
      deadlineAction,
    },
    '2024-05-24': {
      // May 24 -> May 31
      deadlineMessage: formatFullDate(getDateByItems(31, 4)),
      deadlineAction,
    },
    '2024-06-01': {
      deadlineMessage: null,
      deadlineAction: null,
    },
  },
  configRPIEDeadlines = makeItemsForKey<Services.RPIE, CalendarConfig>(
    Services.RPIE,
    config
  )

export { configRPIEDeadlines }
