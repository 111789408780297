import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import Joi from 'joi'
import { commonUnitSchema } from '../rentrollComm/schema'

export const getIsRegulatedErrorMsg = count =>
  `The count of checked units must be equal to # of Regulated Units at General section (${count})`

const residentialUnitSchema = {
  ...commonUnitSchema,
  isSuperUnit: schemaRules.ANY,
  wasVacant: schemaRules.ANY, // req
  isRegulated: schemaRules.CUSTOM((value, helpers) => {
    const originalValue = helpers.prefs.context?.originalValue,
      editingUnit = originalValue?.unit,
      regUnits = originalValue?.units,
      getCountOfRegulated = () => regUnits.filter(u => u?.isRegulated).length

    if (
      originalValue.numberOfResRegulatedUnit !== null &&
      !editingUnit &&
      getCountOfRegulated() !== originalValue.numberOfResRegulatedUnit
    ) {
      return helpers.message(
        getIsRegulatedErrorMsg(
          helpers.prefs.context?.originalValue.numberOfResRegulatedUnit
        ) as any
      )
    }
  }), //req
  hasOneMoreBathrooms: Joi.boolean().empty(null), //req
  bathroomsCount: Joi.when('hasOneMoreBathrooms', {
    is: true,
    then: schemaRules.MIN_NUMBER(1).required(),
    otherwise: schemaRules.ANY,
  }),
  bedroomsCount: schemaRules.MIN_MAX_NUMBER(0, 25).integer(), // req min=0
  monthlyRentAmount: Joi.when('wasVacant', {
    is: true,
    then: schemaRules.NOT_EMPTY_NUMBER,
    otherwise: Joi.when('currentlyOwnerOccupied', {
      is: true,
      then: schemaRules.NOT_EMPTY_NUMBER,
      otherwise: Joi.when('currentlyTenantOccupied', {
        is: true,
        then: schemaRules.GREATER_NUMBER().required(),
        otherwise: Joi.when('isSuperUnit', {
          is: true,
          then: schemaRules.NOT_EMPTY_NUMBER,
          otherwise: schemaRules.MIN_NUMBER().integer(),
        }),
      }),
    }),
  }),
  rentableNetAreaSF: schemaRules
    .GREATER_NUMBER()
    .rule({ message: 'Must be greater than 0' }),
  vacantMonths: Joi.when('wasVacant', {
    is: true,
    then: schemaRules.MIN_MAX_NUMBER(1, 12),
    otherwise: schemaRules.NUMBER.max(12).allow(null),
  }),
}

const schema = schemaWrapper({
  units: schemaRules.ITEMS(residentialUnitSchema).min(0),
  unit: residentialUnitSchema,
})

export default schema
