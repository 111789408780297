import { ComponentStyleConfig } from '@chakra-ui/theme'
import { getTabFocusSelectors } from 'helpers/utils'

const TabStyle: ComponentStyleConfig = {
  // The parts of the component
  parts: ['tab', 'title', 'label'],
  baseStyle: props => ({
    tab: {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',
      height: 'inherit',
    },
    label: {
      marginLeft: '4px',
    },
    title: {
      whiteSpace: 'nowrap',
    },
  }),

  // The size styles for each part
  sizes: {
    sm: props => ({
      tab: {
        padding: props.variant === 'rounded' ? '5px 16px 7px 16px' : '',
      },
    }),
    md: props => ({
      tab: {
        padding: props.variant === 'rounded' ? '9px 20px 11px 20px' : '',
      },
    }),
  },

  // Styles for the visual style variations
  variants: {
    rounded: props => {
      const { active } = props

      return {
        label: {
          display: 'none',
        },
        tab: {
          ':not(:last-child)': {
            marginRight: '12px',
          },
          lineHeight: 'body',
          textStyle: 'body.semibold',
          color: active ? 'white100' : 'primary.base',
          borderColor: active ? 'primary.base' : 'secondary.base',
          borderRadius: '20px',
          backgroundColor: active ? 'primary.base' : 'secondary.base',
          _hover: {
            color: active ? 'white100' : 'primary.base',
            borderColor: 'primary.base',
          },
          ...getTabFocusSelectors({
            color: active ? 'white100' : 'primary.base',
          }),
          _disabled: {
            color: 'white100',
            borderColor: 'primary.disabled',
            backgroundColor: 'primary.disabled',
            cursor: 'not-allowed',
            _hover: {
              borderColor: 'primary.disabled',
              backgroundColor: 'primary.disabled',
            },
          },
        },
      }
    },

    default: props => {
      const { active } = props

      return {
        tab: {
          ':not(:last-child)': {
            marginRight: '24px',
          },
          borderBottom: active ? '2px solid' : '',
          borderBottomColor: active ? 'primary.base' : '',
          transition: active ? 'border-bottom .2s ease, opacity .5s ease' : '',
          color: active ? 'primary.pressed' : 'fontandicongray',
          _hover: {
            color: 'primary.base',
          },
          _disabled: {
            color: 'primary.disabled',
            cursor: 'not-allowed',
          },
        },
      }
    },
  },

  // The default `size` or `variant` values
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
}

export default TabStyle
