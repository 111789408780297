import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import Input from 'ui-framework/components/primitives/Input'
import { integerPositiveThousands } from 'helpers/formats'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { VStack } from '@chakra-ui/layout'
import { StorefrontUnitDescriptionType } from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import { getNameAndErrorProps } from 'helpers/utils'

//===================================================
type Props = {
  unitNumber: number
  descriptionTypeCS: StorefrontUnitDescriptionType | null
  floorSizeSF: number
} & BaseComponentProps &
  StepPropsInterface

/**
 * StepStorefrontUnitGeneral2Step component
 */
const StepStorefrontUnitGeneral2Step = ({
  unitNumber,
  descriptionTypeCS,
  floorSizeSF,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  const validationData = useMemo(
      () => ({ floorSizeSF, descriptionTypeCS }),
      [floorSizeSF, descriptionTypeCS]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  const gfUnitWithStreetEntrance =
      StorefrontUnitDescriptionType.GroundFloorUnitWithStreetEntrance,
    gfUnitWithInteriorEntrance =
      StorefrontUnitDescriptionType.GroundFloorUnitWithInteriorEntrance,
    sfUnitWithStreetEntrance =
      StorefrontUnitDescriptionType.SecondFloorUnitWithStreetEntrance,
    sfUnitWithInteriorEntrance =
      StorefrontUnitDescriptionType.SecondFloorUnitWithInteriorEntrance

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="36px">
        <ReviewLastYearDataWarningBlock />

        <HelpTarget name="descriptionTypeCS">
          <SelectsGroup
            label="Unit Description"
            error={hasError('descriptionTypeCS')}
            variant={`vertical`}
          >
            <Radiobutton
              value={descriptionTypeCS}
              name="descriptionTypeCS"
              selected={gfUnitWithStreetEntrance}
              disabled={disabled}
              onChange={onChange}
            >
              Ground Floor Unit With Direct Street entrance
            </Radiobutton>
            <Radiobutton
              value={descriptionTypeCS}
              name="descriptionTypeCS"
              selected={gfUnitWithInteriorEntrance}
              disabled={disabled}
              onChange={onChange}
            >
              Ground Floor Unit Without Direct Street entrance
            </Radiobutton>

            <Radiobutton
              value={descriptionTypeCS}
              name="descriptionTypeCS"
              selected={sfUnitWithStreetEntrance}
              disabled={disabled}
              onChange={onChange}
            >
              Second Floor Unit With Direct Street entrance
            </Radiobutton>
            <Radiobutton
              value={descriptionTypeCS}
              name="descriptionTypeCS"
              selected={sfUnitWithInteriorEntrance}
              disabled={disabled}
              onChange={onChange}
            >
              Second Floor Unit Without Direct Street entrance
            </Radiobutton>
          </SelectsGroup>
        </HelpTarget>

        <HelpTarget name="floorSizeSF">
          <Input
            label={`Floor area for Commercial Unit #${unitNumber + 1}`}
            type="number"
            format={integerPositiveThousands}
            value={floorSizeSF}
            placeholder="Enter SF"
            disabled={disabled}
            fixedHeight
            onChange={onChange}
            {...getNameAndErrorProps('floorSizeSF', hasError)}
          />
        </HelpTarget>
      </VStack>
    </StepBasic>
  )
}

export default StepStorefrontUnitGeneral2Step
