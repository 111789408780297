import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import dayjs from 'dayjs'

const schema = schemaWrapper({
  workDescription: schemaRules.MIN_MAX_STRING(
    5,
    250,
    'Must include from 5 to 250 characters.'
  ),
  startConstructionDate: schemaRules.NOT_EMPTY_DATE,
  completionDate: schemaRules.NOT_EMPTY_DATE.greater(
    Joi.ref('startConstructionDate')
  )
    .custom((value, helpers) => {
      const startDate =
        helpers.prefs.context?.originalValue.startConstructionDate

      if (startDate) {
        if (dayjs(value).isBefore(dayjs(startDate).add(6, 'month')))
          return helpers.error('date.sixmonth')
      }
    })
    .messages({
      'date.sixmonth':
        'The Actual or Projected Completion Date should be at least 6 months after the Start Date',
    }),
  floorAreaNewSpace: schemaRules.NOT_EMPTY_NUMBER,
  floorAreaRenovatedSpace: schemaRules.NOT_EMPTY_NUMBER,
  percentWorkCompleted: schemaRules.NOT_EMPTY_NUMBER.custom((value, helpers) => {
    const completionDate =
      helpers.prefs.context?.originalValue.completionDate

    if (completionDate) {
      if (dayjs(completionDate).isAfter(dayjs().month(0).date(5))) {
        if (value === 100) {
          return helpers.error('less')
        }
      } else {
        if (value !== 100) {
          return helpers.error('exact')
        }
      }
    }
  }).messages({
    'less': `Completion percentage must be less than 100% for construction completion date after Jan 5th, ${dayjs().year()}`,
    'exact': `Completion percentage must be 100% for a construction completion date that is before Jan 5th, ${dayjs().year()}`
  }),

  // percentWorkCompleted: Joi.when('completionDate', {
  //   is: schemaRules.DATE.iso().greater(dayjs().month(0).date(5).toISOString()),
  //   then: schemaRules.MIN_MAX_NUMBER(1, 99),
  //   otherwise: schemaRules.NUMBER.valid(100)
  // }).messages({
  //   'number.max': `Completion percentage must be less than 100% for construction completion date after Jan 5th, ${dayjs().year()}`,
  //   'number.valid': `Completion percentage must be 100% for a construction completion date that is before Jan 5th, ${dayjs().year()}`
  // }),
})

export default schema
