import React from 'react'

import PaginationWrapperUI from './styles/PaginationWrapperUI'

import { OnChangeHandler, OnClickHandler } from 'ui-framework/common/types'
import Button from '../Button'

const Separator = () => (
  <Button size={`sm`} width={`32px`} px={0} variant={`secondary`}>
    ...
  </Button>
)

export type PaginationProps = {
  currentPage: number
  itemsPerPage: number
  totalItems: number
  onChange: OnChangeHandler<number>
  onNext: OnClickHandler
  onPrev: OnClickHandler
  onFirst: OnClickHandler
  onLast: OnClickHandler
}

const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onChange,
  onNext,
  onPrev,
  onFirst,
  onLast,
  ...props
}: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage),
    pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1),
    subNumbers = pageNumbers.slice(1, totalPages - 1),
    subVisibleNumber =
      currentPage > 3 && currentPage !== totalPages
        ? [currentPage, currentPage - 1, currentPage + 1]
        : currentPage === totalPages
        ? subNumbers.slice(-2)
        : subNumbers.slice(0, 3),
    isPaginShow = totalItems > itemsPerPage,
    isActive = (bool: boolean): 'primary' | 'secondary' =>
      bool ? 'primary' : 'secondary'

  return isPaginShow ? (
    <PaginationWrapperUI>
      {currentPage >= 2 && (
        <Button
          size={`sm`}
          variant={`secondary`}
          onClick={onPrev}
          prefix={`left`}
        >
          Previous
        </Button>
      )}
      <Button
        size={`sm`}
        width={`32px`}
        px={0}
        variant={isActive(currentPage === pageNumbers[0])}
        onClick={onFirst}
      >
        {pageNumbers[0]}
      </Button>
      {currentPage > 3 && totalPages > 4 && <Separator />}
      {subNumbers.map(
        (number, i) =>
          subVisibleNumber.includes(number) && (
            <Button
              key={i}
              size={`sm`}
              width={`32px`}
              px={0}
              variant={isActive(currentPage === number)}
              onClick={() => onChange(number)}
            >
              {number}
            </Button>
          )
      )}
      {currentPage < totalPages - 2 && totalPages > 4 && <Separator />}
      <Button
        size={`sm`}
        width={`32px`}
        px={0}
        variant={isActive(currentPage === totalPages)}
        onClick={onLast}
      >
        {totalPages}
      </Button>
      {currentPage !== totalPages && (
        <Button
          size={`sm`}
          variant={`secondary`}
          onClick={onNext}
          postfix={`right`}
        >
          Next
        </Button>
      )}
    </PaginationWrapperUI>
  ) : null
}

export default Pagination
