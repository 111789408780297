import React from 'react'
import { getFiscalPeriods } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpWhatToExpectDescriptionProps = {} & BaseComponentProps

/**
 * HelpWhatToExpectDescription component
 */
const HelpWhatToExpectDescription = (
  props: HelpWhatToExpectDescriptionProps
) => {
  const { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context)

  return (
    <>
      <p>
        In this section, you are asked to provide or verify a few pertinent
        details about the property:
      </p>
      <ul>
        <li>Descriptive information and uses, as of January 5, {fiscalYear}</li>
        <li>Occupancy and/or vacancy status, as of January 5, {fiscalYear}</li>
      </ul>
      <p>
        These details will determine the scope of RPIE filing and eventually be
        submitted to the City.
      </p>
      <b>Approximate time to complete: 2-3 minutes</b>
    </>
  )
}

export default HelpWhatToExpectDescription
