import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { getFiscalPeriods } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'

//===================================================
type HelpDescriptionConstructionCostStepHelpProps = {} & BaseComponentProps

/**
 * HelpDescriptionConstructionCostStepHelp component
 */
const HelpDescriptionConstructionCostStepHelp = (
  props: HelpDescriptionConstructionCostStepHelpProps
) => {
  const { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context)
  return (
    <>
      <p>
        How much was expended in construction costs, as of January 5,{' '}
        {fiscalYear}? Do not include site acquisition costs for construction and
        personal property costs.
      </p>
      <p>
        Report actual costs as of January 5, {fiscalYear} (NOT total projected
        costs).
      </p>
    </>
  )
}

export default HelpDescriptionConstructionCostStepHelp
