import React, { ReactNode, memo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useMultiStyleConfig,
  chakra,
} from '@chakra-ui/react'
import Button, { ButtonVariantProps } from '../Button'
import { ChakraComponentProps, OnClickHandler } from '../../../common/types'
import { getCallback, isCallback } from 'helpers/utils'
import isEqual from 'lodash/isEqual'

type ModalDialogProps = {
  title?: string | ReactNode
  hasCloseTrigger?: boolean
  onAction: OnClickHandler
  actionText?: string
  actionVariant?: ButtonVariantProps
  onClose?: OnClickHandler
  isOpen: boolean
  footerActions?: ReactNode
} & ChakraComponentProps

const ModalDialog = (props: ModalDialogProps) => {
  const {
      title,
      hasCloseTrigger = false,
      onAction,
      actionText = 'Action',
      actionVariant = 'primary',
      onClose,
      isOpen,
      footerActions,
      ...p
    } = props,
    style = useMultiStyleConfig('MGNYModalDialog', props)

  return (
    isOpen && (
      <Modal {...p} isOpen={isOpen} onClose={getCallback(onClose)}>
        <ModalOverlay />
        <ModalContent sx={style.modalContent}>
          {title && typeof title === 'string' ? (
            <ModalHeader sx={style.modalHeader}>{title}</ModalHeader>
          ) : (
            title
          )}
          {hasCloseTrigger && <ModalCloseButton />}
          <ModalBody sx={style.modalBody}>{props.children}</ModalBody>
          {/*TODO remove this if*/}
          {(footerActions || onClose || onAction) && (
            <ModalFooter sx={style.modalFooter}>
              {footerActions
                ? footerActions
                : isCallback(onClose) && (
                    <Button
                      variant={`textPrimary`}
                      onClick={getCallback(onClose)}
                    >
                      Cancel
                    </Button>
                  )}
              {onAction && (
                <Button
                  variant={actionVariant}
                  onClick={getCallback(onAction)}
                  ml={`20px`}
                >
                  {actionText}
                </Button>
              )}
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    )
  )
}

export default memo(chakra(ModalDialog as any) as any, isEqual)
