import React, { ReactNode, useCallback, useMemo } from 'react'
import {
  AnyFunction,
  getCallback,
  getFiscalPeriods,
  isCallback,
  getFilteredDataByFieldAndCondition,
  getSum,
} from 'helpers/utils'
import { get } from 'object-path-immutable'
import {
  getDictiName,
  DocumentType,
  RPIEWizardSteps,
  RPIEStatuses,
} from 'constants/dicti'
import {
  formatToCurrencyOrLongdash,
  formatToNumberITSOrLongdash,
} from 'helpers/formatter'
import Chips from 'ui-framework/components/primitives/Chips'
import {
  asOfJanuary,
  repYearDescrReal,
  rangeOfYear,
  getForamttedDateRange,
} from 'pages/Services/Rpie/RpieFormPage/wizard/common'
import { Section } from 'ui-framework/components/patterns/Wizard/SummaryTable'
import {
  StorefrontUnit,
  RentRollResidentialUnit,
} from 'hooks/api/rpie/RpieFormData'
import Empty from 'ui-framework/components/primitives/Empty'
import {
  hasExclusion,
  hideIEFill,
  hideIEUpload,
  isProperyNotHotel,
  rrComNeeded,
  rrResNeeded,
} from '../../wizard/rpieWizardConfig'
import useHandleStepChangeWithTooltip from 'ui-framework/components/patterns/Wizard/SummaryTable/useHandleStepChangeWithTooltip'

//==================================================

export enum RRUnitTypes {
  Owner = 'Owner',
  Tenant = 'Tenant',
  Vacant = 'Vacant',
  Super = 'Super',
}

const createChip = (value: any): ReactNode => (
  <Chips prefix={true} variant="orange" ml="16px">
    {value}
  </Chips>
),
  createContentByValueAndDescription = (
    value: any,
    description?: any
  ): ReactNode => (
    <>
      {value}
      {description}
    </>
  ),
  getFormattedTotal = (
    arr: any[],
    fld: string,
    formattedFn?: AnyFunction
  ): number =>
    getCallback(formattedFn || formatToCurrencyOrLongdash)(
      (arr || []).reduce(getSum(fld), 0)
    ),
  createItemsByFields = (
    data: any[],
    fields: Array<string | { currency?: string; value?: any } | AnyFunction>
  ): { value: any[] }[] =>
    !data?.length || !fields
      ? []
      : data.map((item, i) => ({
        value: fields.map(field => {
          const getValue = f => get(item, f)
          if (typeof field === 'object') {
            return getCallback(
              field.value
                ? formatToNumberITSOrLongdash
                : formatToCurrencyOrLongdash
            )(getValue(field?.currency || field?.value))
          } else if (isCallback(field)) {
            return getCallback(field)(item, i)
          } else {
            const value = getValue(field)
            return typeof value === 'string'
              ? getDictiName(value, '—')
              : formatToNumberITSOrLongdash(value)
          }
        }),
      })),
  getAllSectionsItems = data =>
    (data || []).reduce((acc, section) => {
      acc.push(
        ...getFilteredDataByFieldAndCondition(section.items).map(item => ({
          ...item,
          categoryName: item.categoryName || item.categoryCS,
        }))
      )

      return acc
    }, []),
  getStorefrontItems = (units: StorefrontUnit[], isStorefrontNeeded) => {
    if (!units?.length || !isStorefrontNeeded) return []
    const { items } = units.reduce(
      (acc, unit, ind) => {
        const items = createItemsByFields(unit.occupants, [
          () => ind + 1,
          'occupantTypeCS',
          occupant =>
            getForamttedDateRange(occupant.startDate, occupant.endDate),
          'noVacantType.primaryBusinessActivityCS',
          { currency: 'grossRentAmount' },
          { currency: 'baseRentAmount' },
        ])
        acc.items = acc.items.concat(items)
        return acc
      },
      { items: [] as { value: any[] }[] }
    ),
      getTotals = fld =>
        units.reduce((p, c) => p + c?.occupants?.reduce(getSum(fld), 0), 0),
      totals = [
        {
          isTotal: true,
          value: [
            'Total',
            undefined,
            undefined,
            undefined,
            formatToCurrencyOrLongdash(getTotals('grossRentAmount')),
            formatToCurrencyOrLongdash(getTotals('baseRentAmount')),
          ],
        },
      ]
    return [items, totals]
  }

export const RpieSummarySectionsConfig = (
  context,
  remoteData?: any,
  changeStep?: any
): Section[] => {
  const { fiscalYear, reportingYear, prevReportingYear } =
    getFiscalPeriods(context),
    data = useMemo(
      () => remoteData || context?.defaultServiceData,
      [context, remoteData]
    ),
    [isHiddenIE, propertyNotHotel, withoutExclusion] = useMemo(
      () => [
        hideIEFill(data, context),
        isProperyNotHotel(context),
        !hasExclusion(data),
      ],
      [data, context]
    ),
    getValue = useCallback(path => get(data, path), [data]),
    [
      isIncomeAndExpenseUploaded,
      isRentRollCommercialUploaded,
      isRentRollResidentialUploaded,
    ] = useMemo(
      () => [
        !hideIEUpload(data, context),
        getValue('rentRollCommercial.showUploadArea'),
        getValue('rentRollResidential.showUploadArea'),
      ],
      [context, data, getValue]
    ),
    [
      incomes,
      expenses,
      storefrontUnits,
      rentRollCommercialUnits,
      rentRollResidentialUnits,
    ] = useMemo(
      () => [
        getAllSectionsItems(isHiddenIE ? [] : getValue('income.sections')),
        getAllSectionsItems(isHiddenIE ? [] : getValue('expense.sections')),
        getValue('storefront.units'),
        getValue('rentRollCommercial.units'),
        getValue('rentRollResidential.units'),
      ],
      [isHiddenIE, getValue]
    ),
    [storefrontData = [], storefrontTotals = []] = useMemo(
      () =>
        getStorefrontItems(
          storefrontUnits,
          getValue('storefront.totalUnitNumber')
        ),
      [storefrontUnits, getValue]
    ),
    resRRItems = useMemo<{ [key: string]: RentRollResidentialUnit[] }>(
      () =>
        (rentRollResidentialUnits || []).reduce((result, u) => {
          const type = u.currentlyOwnerOccupied
            ? RRUnitTypes.Owner
            : u.currentlyTenantOccupied
              ? RRUnitTypes.Tenant
              : u.wasVacant
                ? RRUnitTypes.Vacant
                : u.isSuperUnit
                  ? RRUnitTypes.Super
                  : 'None'

          result[type] = Array.isArray(result[type])
            ? [...result[type], u]
            : [u]
          return result
        }, {}),
      [rentRollResidentialUnits]
    ),
    [rangeChip, januaryChip, incomeExpenseRangeChip] = useMemo(
      () => [
        createChip(rangeOfYear(reportingYear)),
        createChip(asOfJanuary(fiscalYear, 5)),
        incomes?.length && createChip(repYearDescrReal(data)),
      ],
      [reportingYear, fiscalYear, data, incomes?.length]
    )

  const inProgress = useMemo(
    () => [RPIEStatuses.InProgress].includes(context?.statusCS),
    [context?.statusCS]
  )
  const makeItemWithTooltip = useHandleStepChangeWithTooltip(changeStep)

  return [
    {
      id: RPIEWizardSteps.PropertyDescription,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.PropertyDescription,
          'Property Description'
        ),
        januaryChip
      ),
      childrens: [
        {
          name: `Reason for not reporting ${reportingYear} rental income`,
          value: getDictiName(getValue('general.exclusionCS'), '—'),
        },
      ],
    },
    getValue('general.numberOfResidentialUnit') &&
    propertyNotHotel && {
      id: RPIEWizardSteps.ResidentialDescription,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.ResidentialDescription,
          'Residential Description'
        ),
        januaryChip
      ),
      childrens: [
        {
          name: 'Total # of Residential Units:',
          value: getValue('general.numberOfResidentialUnit'),
        },
        {
          name: '# of Owner-occupied Units:',
          value: getValue('vacantOwnerTable.usesResidential.unitsApplicant'),
        },
        {
          name: ['Type', 'Regulated', 'Unregulated'],
        },
        {
          value: [
            'Vacant',
            getValue(
              'vacantOwnerTable.usesResidential.regulatedUnitsVacant'
            ) || 0,
            getValue(
              'vacantOwnerTable.usesResidential.unregulatedUnitsVacant'
            ) || 0,
          ],
        },
        {
          value: [
            'Non-vacant',
            getValue('general.numberOfResRegulatedUnit') -
            getValue(
              'vacantOwnerTable.usesResidential.regulatedUnitsVacant'
            ) || 0,
            getValue('general.numberOfResUnregulatedUnit') -
            getValue(
              'vacantOwnerTable.usesResidential.unregulatedUnitsVacant'
            ) || 0,
          ],
        },
      ],
    },
    getValue('general.numberOfCommercialUnit') && {
      id: RPIEWizardSteps.CommercialDescription,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.CommercialDescription,
          'Commercial Description'
        ),
        januaryChip
      ),
      childrens: [
        {
          name: 'Total # of Commercial Units:',
          value: getValue('general.numberOfCommercialUnit'),
        },
        {
          name: 'Total # of Storefront Units:',
          value: getValue('storefront.totalUnitNumber'),
        },
        {
          name: ['Uses', 'Total SF', 'Owner-occupied SF', 'Vacant SF'],
        },
        ...createItemsByFields(
          getValue('vacantOwnerTable.usesNonresidential'),
          ['usesTypeCS', 'sfTotal', 'sfApplicant', 'sfVacant']
        ),
      ],
    },
    !propertyNotHotel &&
    withoutExclusion && {
      id: RPIEWizardSteps.HotelOperation,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.HotelOperation,
          'Hotel Operation Information'
        ),
        rangeChip
      ),
      childrens: [
        {
          name: `Occupancy Rate for ${reportingYear}`,
          value: `${getValue('hotel.occupancyRate')}%`,
        },
      ],
    },
    !propertyNotHotel &&
    withoutExclusion &&
    false && // disable step and summary
    getValue('hotel.totalRoomsNumber') && {
      id: RPIEWizardSteps.HotelRoomRates,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.HotelRoomRates,
          'Room Rates'
        ),
        rangeChip
      ),
      childrens: [
        {
          name: ['Room Type', 'Number of Each', 'Single Rate', 'Double Rate'],
        },
        ...createItemsByFields(getValue('hotel.roomRates'), [
          'roomTypeCS',
          'numberOfeach',
          { currency: 'singleRate' },
          { currency: 'doubleRate' },
        ]),
        {
          isTotal: true,
          value: [
            'Total',
            formatToNumberITSOrLongdash(getValue('hotel.totalRoomsNumber')),
            undefined,
            undefined,
          ],
        },
      ],
    },
    incomes?.length && {
      id: RPIEWizardSteps.Income,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(inProgress && RPIEWizardSteps.Income, 'Income'),
        incomeExpenseRangeChip
      ),
      hidden: isIncomeAndExpenseUploaded,
      childrens: [
        {
          name: [
            'Type',
            '# of Units',
            `Annual ${prevReportingYear}`,
            `Annual ${reportingYear}`,
          ],
        },
        // ...createItemsByFields(incomes, [
        //   'categoryName',
        //   'numberOfUnits',
        //   { currency: 'prevYearAmount' },
        //   { currency: 'lastYearAmount' },
        // ]),
        {
          isTotal: true,
          value: [
            'Total',
            getFormattedTotal(
              incomes,
              'numberOfUnits',
              formatToNumberITSOrLongdash
            ),
            getFormattedTotal(incomes, 'prevYearAmount'),
            getFormattedTotal(incomes, 'lastYearAmount'),
          ],
        },
      ],
    },
    expenses?.length && {
      id: RPIEWizardSteps.Expense,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(inProgress && RPIEWizardSteps.Expense, 'Expenses'),
        incomeExpenseRangeChip
      ),
      hidden: isIncomeAndExpenseUploaded,
      childrens: [
        {
          name: [
            'Type',
            `Annual ${prevReportingYear}`,
            `Annual ${reportingYear}`,
          ],
        },
        // ...createItemsByFields(expenses, [
        //   'categoryName',
        //   { currency: 'prevYearAmount' },
        //   { currency: 'lastYearAmount' },
        // ]),
        {
          isTotal: true,
          value: [
            'Total',
            getFormattedTotal(expenses, 'prevYearAmount'),
            getFormattedTotal(expenses, 'lastYearAmount'),
          ],
        },
      ],
    },
    {
      id: RPIEWizardSteps.IncomeUpload,
      title: makeItemWithTooltip(
        inProgress && RPIEWizardSteps.IncomeUpload,
        `Income and Expense Information`
      ),
      hidden: !isIncomeAndExpenseUploaded,
      childrens: [
        {
          filesId: DocumentType.Income,
        },
      ],
    },
    storefrontData?.length && {
      id: RPIEWizardSteps.StorefrontGeneralInfo1.concat('0'),
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.StorefrontGeneralInfo1.concat('0'), // first stepId of storefront
          'Storefront'
        ),
        rangeChip
      ),
      maxContent: true,
      childrenStyles: [
        {
          width: '190px',
        },
        { width: '156px' },
        { width: '400px' },
        { width: '252px', pr: '20px' },
        { width: '77px', mr: '115px' },
        { width: '70px' },
      ],
      childrens: [
        {
          name: [
            'Unit #',
            'Occupancy',
            'Period',
            'Business Activity',
            'Gross Rent',
            'Base Rent',
          ],
        },
        ...storefrontData,
        ...storefrontTotals,
      ],
    },
    rrComNeeded(data, context) && {
      id: RPIEWizardSteps.RentRollCommercial,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.RentRollCommercial,
          'Commercial Rent Roll'
        ),
        rangeChip
      ),
      maxContent: !isRentRollCommercialUploaded,
      childrenStyles: [
        {
          width: '170px',
        },
        { width: '176px' },
        { width: '170px' },
        { width: '210px', pr: '20px' },
        { width: '252px', pr: '20px' },
        { width: '77px', mr: '115px' },
        { width: '70px' },
      ],
      childrens: isRentRollCommercialUploaded
        ? [
          {
            filesId: DocumentType.RPIERentRollCommercial,
          },
        ]
        : [
          {
            name: [
              'Unit #',
              'Floor',
              'Net Rentable Area',
              'Primary Business Activity',
              `Status ${asOfJanuary(fiscalYear).toLowerCase()}`,
              'Gross Rent',
              'Base Rent',
            ],
          },
          ...createItemsByFields(rentRollCommercialUnits, [
            (item, i) => item.unitApartmentNumber || i + 1,
            unit => unit.floorNumber,
            'rentableNetAreaSF',
            'primaryBusinessActivityCS',
            (unit, ind) =>
              unit.currentlyOwnerOccupied ? (
                RRUnitTypes.Owner
              ) : unit.currentlyTenantOccupied ? (
                RRUnitTypes.Tenant
              ) : unit.wasVacant ? (
                RRUnitTypes.Vacant
              ) : (
                <Empty type="longdash" />
              ),
            { currency: 'grossRentAmount' },
            { currency: 'baseRentAmount' },
          ]),
          {
            isTotal: true,
            value: [
              'Total',
              undefined,
              undefined,
              undefined,
              undefined,
              getFormattedTotal(rentRollCommercialUnits, 'grossRentAmount'),
              getFormattedTotal(rentRollCommercialUnits, 'baseRentAmount'),
            ],
          },
        ],
    },
    rrResNeeded(data, context) && {
      id: RPIEWizardSteps.RentRollResidential,
      title: createContentByValueAndDescription(
        makeItemWithTooltip(
          inProgress && RPIEWizardSteps.RentRollResidential,
          'Residential Rent Roll'
        ),
        rangeChip
      ),
      childrens: isRentRollResidentialUploaded
        ? [
          {
            filesId: DocumentType.ResRentRoll,
          },
        ]
        : [
          {
            name: ['Occupancy Type', 'Units', 'Total Rent'],
          },
          ...createItemsByFields(Object.entries(resRRItems), [
            type => type[0],
            type => type[1]?.length,
            type => getFormattedTotal(type[1], 'monthlyRentAmount'),
          ]),
          {
            isTotal: true,
            value: [
              'Total',
              formatToNumberITSOrLongdash(
                (rentRollResidentialUnits || []).length
              ),

              getFormattedTotal(
                rentRollResidentialUnits,
                'monthlyRentAmount'
              ),
            ],
          },
        ],
    },
  ].filter(x => x)
}
