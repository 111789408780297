import React, { useMemo } from 'react'

import dayjs from 'dayjs'
import { getCurrentYearDuration, groupBy } from 'helpers/utils'
import Progress from 'ui-framework/components/primitives/Progress'

import { Box, Flex, Text } from '@chakra-ui/layout'
import ProgressBar from 'ui-framework/components/primitives/Progress/ProgressBar'
import ProgressBackground from 'ui-framework/components/primitives/Progress/ProgressBackground'
import { chakra } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { ChakraComponentProps } from 'ui-framework/common/types'
import MinMax from 'dayjs/plugin/minMax'
import { uiDateShortFormat, uiDateMonthFormat } from 'helpers/formats'

dayjs.extend(MinMax)

//===================================================
const ProgressLabel = chakra('div', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    textStyle: 'body.regular',
    color: 'fontnavy',
    marginRight: '40px',
    marginTop: '17px',
  },
})
const ProgressMark = chakra('div', {
  baseStyle: {
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    marginRight: '8px',
  },
})

type Interval = {
  label?: string | null
  startDate?: string | null
  endDate?: string | null
  empty?: boolean
}

type Props = {
  items: Interval[]
  active?: number | null
  year: number
} & ChakraComponentProps

/**
 * IntervalBar component
 */
const IntervalBar = ({ items, active, year, ...props }: Props) => {
  const getIntervalWidth = value => {
      const { daysOfYear } = getCurrentYearDuration(year),
        filledDays = value.$duration
      return daysOfYear && (filledDays / daysOfYear) * 100
    },
    [, itemsEx] = useMemo(() => {
      const min = dayjs.min(
          items
            .filter(i => !!i.startDate)
            .map(i => dayjs(i.startDate as string))
        ),
        max = dayjs.max(
          items.filter(i => !!i.endDate).map(i => dayjs(i.endDate as string))
        )

      const itemsEx = items.map(i => {
        if (!!i.startDate && !!i.endDate) {
          return { ...i, $duration: dayjs(i.endDate).diff(i.startDate, 'day') }
        } else if (!!i.startDate && !i.endDate) {
          return {
            ...i,
            $duration: dayjs(i.startDate)
              .endOf('year')
              .diff(i.startDate, 'day'),
          }
        } else {
          return { ...i, $duration: 0 }
        }
      })

      return [dayjs(max).diff(min, 'day'), itemsEx]
    }, [items]),
    colors = {
      tenant: '#06AED5',
      vacant: 'green.base',
      owner: 'orange.base',
      default: 'transparent',
    },
    progeressTitle = useMemo(() => {
      const group = groupBy(itemsEx, 'label'),
        format = (date: string | Date): string =>
          date ? dayjs(date).format(uiDateMonthFormat) : '',
        prepareDate = (values: Array<Interval>): string =>
          values.reduce(
            (acc, v, i, arr) =>
              (acc += `${format(v.startDate as string | Date)} - ${format(
                v.endDate as string | Date
              )}${arr.length - 1 === i ? ')' : '; '}`),
            '('
          )
      return Object.keys(group).map(key => {
        const val = group[key],
          first = val[0]

        return first.label ? (
          <ProgressLabel key={nanoid()}>
            <ProgressMark
              bgColor={colors[key.toLowerCase()] || colors.default}
            />
            {first.label}
            {first.endDate ? (
              <chakra.span ml={`8px`} color={`fontandicongray`}>
                {prepareDate(val)}
              </chakra.span>
            ) : undefined}
          </ProgressLabel>
        ) : undefined
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsEx])
  return (
    <Box w="100%" className={props.className}>
      <Flex justify="space-between" color="fontandicongray" mb={`16px`}>
        <Text>
          {dayjs().year(year).startOf('year').format(uiDateShortFormat)}
        </Text>
        <Text>
          {dayjs().year(year).endOf('year').format(uiDateShortFormat)}
        </Text>
      </Flex>
      <Flex h={'6px'}>
        {itemsEx.map((i, idx) => (
          <>
            <Progress
              key={i.startDate || idx}
              variant={i.endDate ? 'normal' : 'active'}
              min={0}
              max={i.endDate ? 1 : getIntervalWidth(i)}
              current={i.endDate ? 1 : getIntervalWidth(i) / 10}
              width={`${getIntervalWidth(i)}%` || '50px'}
              radiusStart={idx === 0}
              radiusEnd={idx === itemsEx.length - 1}
            >
              <ProgressBackground>
                {!i.empty && (
                  <ProgressBar
                    backgroundColor={
                      colors[
                        (active === null ||
                        (active !== undefined && idx === active)
                          ? i.label?.toLowerCase() || 'default'
                          : 'default') as any
                      ]
                    }
                  />
                )}
              </ProgressBackground>
            </Progress>

            <Box
              borderLeft="3px solid"
              borderColor={
                idx !== itemsEx.length - 1 ? `secondary.divider` : 'transparent'
              }
              height="16px"
              w="0px"
              mt={`-4px`}
              zIndex="1"
            />
          </>
        ))}
      </Flex>
      <Flex flexWrap={`wrap`} minH={'37px'} alignItems={`flex-start`}>
        {progeressTitle}
      </Flex>
    </Box>
  )
}

export default IntervalBar
