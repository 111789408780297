import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Checkbox from 'ui-framework/components/primitives/Checkbox'
import Input from 'ui-framework/components/primitives/Input'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import {
  OccupancyPeriod,
  Occupant,
  RpieFormData,
} from 'hooks/api/rpie/RpieFormData'
import IntervalBar from 'ui-framework/components/patterns/IntervalBar'
import { phone } from 'helpers/formats'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { HStack, Text, VStack } from '@chakra-ui/layout'
import {
  getFiscalPeriods,
  createItemsByDicti,
  getNameAndErrorProps,
} from 'helpers/utils'
import {
  getDictiName,
  BusinessActivity,
} from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import StorefrontUnitOccupantsIntervalBar from 'pages/Services/Rpie/RpieFormPage/steps/storefront/StorefrontUnitOccupantsIntervalBar'

//===================================================
type Props = {
  unitNumber: number
  occupantNumber: number

  canOmitContactInformation: boolean
  isContactInformationUnavailable: boolean
  contactName: string
  emailAddress: string
  primaryPhone: string
  alternativePhone: string
  primaryBusinessActivityCS: BusinessActivity | null
  businessName: string
  otherDescriptionOfBA: string
  _occStartDate: string
  _occEndDate: string
  _occLabel: string
  occupants: Occupant[]
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

/**
 * StepStorefrontUnitOccupantContactInformationStep component
 */
const StepStorefrontUnitOccupantContactInformationStep = ({
  unitNumber,
  occupantNumber,

  canOmitContactInformation,
  isContactInformationUnavailable,
  contactName,
  emailAddress,
  primaryPhone,
  alternativePhone,
  primaryBusinessActivityCS,
  businessName,
  otherDescriptionOfBA,
  _occStartDate,
  _occEndDate,
  _occLabel,
  occupants,

  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: Props) => {
  // console.log('step', contactName, emailAddress, businessName)
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context),
    primaryBusinessActivityItems = useMemo(
    () => createItemsByDicti(BusinessActivity), //.filter((i) => i.id !== BusinessActivity.Vacant),
      []
    ),
    validationData = useMemo(
      () => ({
        contactName,
        primaryPhone,
        emailAddress,
        primaryBusinessActivityCS,
        otherDescriptionOfBA,
        businessName,
        isContactInformationUnavailable,
        canOmitContactInformation,
      }),
      [
        contactName,
        primaryPhone,
        emailAddress,
        primaryBusinessActivityCS,
        otherDescriptionOfBA,
        businessName,
        isContactInformationUnavailable,
        canOmitContactInformation,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack align="start" spacing="24px" w="680px">
        <StorefrontUnitOccupantsIntervalBar
          occupants={occupants}
          occupantNumber={occupantNumber}
          reportingYear={reportingYear}
        />
        {canOmitContactInformation && (
          <Checkbox
            checked={isContactInformationUnavailable}
            name="isContactInformationUnavailable"
            disabled={disabled}
            onChange={onChange}
          >
            Contact information is not available
          </Checkbox>
        )}
        {isContactInformationUnavailable === true && (
          <div>
            <Text lineHeight="24px">
              I have made good faith attempts to obtain the contact information
              for the occupant.
              <br />
              Despite my diligent efforts, I was unable to obtain the
              information.
            </Text>
          </div>
        )}
        {isContactInformationUnavailable !== true && (
          <>
            <HStack spacing="36px">
              <HelpTarget name="contactName">
                <Input
                  label="Contact Name"
                  type="text"
                  value={contactName}
                  placeholder="Enter name"
                  disabled={disabled}
                  onChange={onChange}
                  fixedHeight
                  {...nameAndErrorProps('contactName')}
                />
              </HelpTarget>
              <HelpTarget name="primaryPhone">
                <Input
                  label="Primary Phone #"
                  type="text"
                  value={primaryPhone}
                  format={phone}
                  placeholder={false}
                  disabled={disabled}
                  onChange={onChange}
                  fixedHeight
                  {...nameAndErrorProps('primaryPhone')}
                />
              </HelpTarget>
            </HStack>
            <HelpTarget name="emailAddress">
              <Input
                width="396px"
                label="Email Address"
                type="text"
                value={emailAddress}
                placeholder="Enter email"
                disabled={disabled}
                onChange={onChange}
                fixedHeight
                {...nameAndErrorProps('emailAddress')}
              />
            </HelpTarget>
          </>
        )}

        <HStack spacing="36px">
          <HelpTarget name="primaryBusinessActivityCS">
            <DropdownList
              width="180px"
              label="Primary Business Activity"
              items={primaryBusinessActivityItems}
              itemId="id"
              itemValue="name"
              value={primaryBusinessActivityCS}
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('primaryBusinessActivityCS')}
            />
          </HelpTarget>

          {(primaryBusinessActivityCS ===
            BusinessActivity.Other ||
            primaryBusinessActivityCS ===
              BusinessActivity.MiscellaneousOtherService) && (
            <HelpTarget name="otherDescriptionOfBA">
              <Input
                label="Other Business Activity"
                type="text"
                value={otherDescriptionOfBA}
                placeholder="Enter Activity"
                disabled={disabled}
                onChange={onChange}
                {...nameAndErrorProps('otherDescriptionOfBA')}
              />
            </HelpTarget>
          )}
        </HStack>
        <HelpTarget name="businessName">
          <Input
            width="396px"
            label="Business name"
            type="text"
            value={businessName}
            placeholder="Enter business name"
            disabled={disabled}
            onChange={onChange}
            {...nameAndErrorProps('businessName')}
          />
        </HelpTarget>
      </VStack>
    </StepBasic>
  )
}

export default StepStorefrontUnitOccupantContactInformationStep
