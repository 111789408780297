import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpEndDateStepSFIntervalsProps = {} & BaseComponentProps

/**
 * HelpEndDateStepSFIntervals component
 */
const HelpEndDateStepSFIntervals = (props: HelpEndDateStepSFIntervalsProps) => {
  return (
    <p>
      Select the <b>end date</b> for the specified Occupancy Type. The{' '}
      <b>start date</b> is automatically triggered as the day after the{' '}
      <b>end date</b> of the preceding interval.
    </p>
  )
}

export default HelpEndDateStepSFIntervals
