import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpDescriptionGeneralStepProps = {} & BaseComponentProps

/**
 * HelpDescriptionGeneralStep component
 */
const HelpDescriptionGeneralStep = (props: HelpDescriptionGeneralStepProps) => {
  return (
    <>
      <p>
        General information about your property was gathered from public
        databases and/or past filings.
      </p>
      <p>Review and verify each field:</p>
      <ul>
        <li>If everything is correct, proceed to the Next Step.</li>
        <li>
          If you need to correct Applicant, Signatory and/or Title, you can do
          that manually.
        </li>
        <li>
          <b>If you need to correct any other fields, contact our office.</b>
        </li>
      </ul>
    </>
  )
}

export default HelpDescriptionGeneralStep
