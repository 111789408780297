import { isNullOrWhiteSpace } from 'helpers/utils'

enum BuildingClasses {
  A0 = 'A0', // CAPE COD
  A1 = 'A1', // TWO STORIES - DETACHED SM OR MID
  A2 = 'A2', // ONE STORY - PERMANENT LIVING QUARTER
  A3 = 'A3', // LARGE SUBURBAN RESIDENCE
  A4 = 'A4', // CITY RESIDENCE ONE FAMILY
  A5 = 'A5', // ONE FAMILY ATTACHED OR SEMI-DETACHED
  A6 = 'A6', // SUMMER COTTAGE
  A7 = 'A7', // MANSION TYPE OR TOWN HOUSE
  A8 = 'A8', // BUNGALOW COLONY - COOPERATIVELY OWNED LAND
  A9 = 'A9', // MISCELLANEOUS ONE FAMILY
  B1 = 'B1', // TWO FAMILY BRICK
  B2 = 'B2', // TWO FAMILY FRAME
  B3 = 'B3', // TWO FAMILY CONVERTED FROM ONE FAMILY
  B9 = 'B9', // MISCELLANEOUS TWO FAMILY
  C0 = 'C0', // THREE FAMILIES
  C1 = 'C1', // OVER SIX FAMILIES WITHOUT STORES
  C2 = 'C2', // FIVE TO SIX FAMILIES
  C3 = 'C3', // FOUR FAMILIES
  C4 = 'C4', // OLD LAW TENEMENT
  C5 = 'C5', // CONVERTED DWELLINGS OR ROOMING HOUSE
  C6 = 'C6', // WALK-UP COOPERATIVE
  C7 = 'C7', // WALK-UP APT. OVER SIX FAMILIES WITH STORES
  C8 = 'C8', // WALK-UP CO-OP; CONVERSION FROM LOFT/WAREHOUSE
  C9 = 'C9', // GARDEN APARTMENTS
  CM = 'CM', // MOBILE HOMES/TRAILER PARKS
  D0 = 'D0', // ELEVATOR CO-OP; CONVERSION FROM LOFT/WAREHOUSE
  D1 = 'D1', // ELEVATOR APT; SEMI-FIREPROOF WITHOUT STORES
  D2 = 'D2', // ELEVATOR APT; ARTISTS IN RESIDENCE
  D3 = 'D3', // ELEVATOR APT; FIREPROOF WITHOUT STORES
  D4 = 'D4', // ELEVATOR COOPERATIVE
  D5 = 'D5', // ELEVATOR APT; CONVERTED
  D6 = 'D6', // ELEVATOR APT; FIREPROOF WITH STORES
  D7 = 'D7', // ELEVATOR APT; SEMI-FIREPROOF WITH STORES
  D8 = 'D8', // ELEVATOR APT; LUXURY TYPE
  D9 = 'D9', // ELEVATOR APT; MISCELLANEOUS
  E1 = 'E1', // FIREPROOF WAREHOUSE
  E2 = 'E2', // CONTRACTORS WAREHOUSE
  E3 = 'E3', // SEMI-FIREPROOF WAREHOUSE
  E4 = 'E4', // METAL FRAME WAREHOUSE
  E7 = 'E7', // SELF-STORAGE WAREHOUSES
  E9 = 'E9', // MISCELLANEOUS WAREHOUSE
  F1 = 'F1', // FACTORY; HEAVY MANUFACTURING - FIREPROOF
  F2 = 'F2', // FACTORY; SPECIAL CONSTRUCTION - FIREPROOF
  F4 = 'F4', // FACTORY; INDUSTRIAL SEMI-FIREPROOF
  F5 = 'F5', // FACTORY; LIGHT MANUFACTURING
  F8 = 'F8', // FACTORY; TANK FARM
  F9 = 'F9', // FACTORY; INDUSTRIAL-MISCELLANEOUS
  G0 = 'G0', // GARAGE; RESIDENTIAL TAX CLASS 1
  G1 = 'G1', // ALL PARKING GARAGES
  G2 = 'G2', // AUTO BODY/COLLISION OR AUTO REPAIR
  G3 = 'G3', // GAS STATION WITH RETAIL STORE
  G4 = 'G4', // GAS STATION WITH SERVICE/AUTO REPAIR
  G5 = 'G5', // GAS STATION ONLY WITH/WITHOUT SMALL KIOSK
  G6 = 'G6', // LICENSED PARKING LOT
  G7 = 'G7', // UNLICENSED PARKING LOT
  G8 = 'G8', // CAR SALES/RENTAL WITH SHOWROOM
  G9 = 'G9', // MISCELLANEOUS GARAGE
  GU = 'GU', // CAR SALES OR RENTAL LOTS WITHOUT SHOWROOM
  GW = 'GW', // CAR WASH OR LUBRITORIUM FACILITY
  HB = 'HB', // BOUTIQUE: 10-100 ROOMS, W/LUXURY FACILITIES, THEMED, STYLISH, W/FULL SVC ACCOMMODATIONS
  HH = 'HH', // HOSTELS- BED RENTALS IN DORMITORY-LIKE SETTINGS W/SHARED ROOMS & BATHROOMS
  HR = 'HR', // SRO- 1 OR 2 PEOPLE HOUSED IN INDIVIDUAL ROOMS IN MULTIPLE DWELLING AFFORDABLE HOUSING
  HS = 'HS', // EXTENDED STAY/SUITE: AMENITIES SIMILAR TO APT; TYPICALLY CHARGE WEEKLY RATES & LESS EXPENSIVE THAN FULL-SERVICE HOTEL
  H1 = 'H1', // LUXURY HOTEL
  H2 = 'H2', // FULL SERVICE HOTEL
  H3 = 'H3', // LIMITED SERVICE; MANY AFFILIATED WITH NATIONAL CHAIN
  H4 = 'H4', // MOTEL
  H5 = 'H5', // HOTEL; PRIVATE CLUB, LUXURY TYPE
  H6 = 'H6', // APARTMENT HOTEL
  H7 = 'H7', // APARTMENT HOTEL - COOPERATIVELY OWNED
  H8 = 'H8', // DORMITORY
  H9 = 'H9', // MISCELLANEOUS HOTEL
  I1 = 'I1', // HOSPITAL, SANITARIUM, MENTAL INSTITUTION
  I2 = 'I2', // INFIRMARY
  I3 = 'I3', // DISPENSARY
  I4 = 'I4', // HOSPITAL; STAFF FACILITY
  I5 = 'I5', // HEALTH CENTER, CHILD CENTER, CLINIC
  I6 = 'I6', // NURSING HOME
  I7 = 'I7', // ADULT CARE FACILITY
  I9 = 'I9', // MISCELLANEOUS HOSPITAL, HEALTH CARE FACILITY
  J1 = 'J1', // THEATRE; ART TYPE LESS THAN 400 SEATS
  J2 = 'J2', // THEATRE; ART TYPE MORE THAN 400 SEATS
  J3 = 'J3', // MOTION PICTURE THEATRE WITH BALCONY
  J4 = 'J4', // LEGITIMATE THEATRE, SOLE USE
  J5 = 'J5', // THEATRE IN MIXED-USE BUILDING
  J6 = 'J6', // TELEVISION STUDIO
  J7 = 'J7', // OFF BROADWAY TYPE THEATRE
  J8 = 'J8', // MULTIPLEX PICTURE THEATRE
  J9 = 'J9', // MISCELLANEOUS THEATRE
  K1 = 'K1', // ONE STORY RETAIL BUILDING
  K2 = 'K2', // MULTI-STORY RETAIL BUILDING (2 OR MORE)
  K3 = 'K3', // MULTI-STORY DEPARTMENT STORE
  K4 = 'K4', // PREDOMINANT RETAIL WITH OTHER USES
  K5 = 'K5', // STAND-ALONE FOOD ESTABLISHMENT
  K6 = 'K6', // SHOPPING CENTER WITH OR WITHOUT PARKING
  K7 = 'K7', // BANKING FACILITIES WITH OR WITHOUT PARKING
  K8 = 'K8', // BIG BOX RETAIL: NOT AFFIXED & STANDING ON OWN LOT W/PARKING, E.G. COSTCO & BJ'S
  K9 = 'K9', // MISCELLANEOUS STORE BUILDING
  L1 = 'L1', // LOFT; OVER 8 STORIES (MID MANH. TYPE)
  L2 = 'L2', // LOFT; FIREPROOF AND STORAGE TYPE WITHOUT STORES
  L3 = 'L3', // LOFT; SEMI-FIREPROOF
  L8 = 'L8', // LOFT; WITH RETAIL STORES OTHER THAN TYPE ONE
  L9 = 'L9', // MISCELLANEOUS LOFT
  M1 = 'M1', // CHURCH, SYNAGOGUE, CHAPEL
  M2 = 'M2', // MISSION HOUSE (NON-RESIDENTIAL)
  M3 = 'M3', // PARSONAGE, RECTORY
  M4 = 'M4', // CONVENT
  M9 = 'M9', // MISCELLANEOUS RELIGIOUS FACILITY
  N1 = 'N1', // ASYLUM
  N2 = 'N2', // HOME FOR INDIGENT CHILDREN, AGED, HOMELESS
  N3 = 'N3', // ORPHANAGE
  N4 = 'N4', // DETENTION HOUSE FOR WAYWARD GIRLS
  N9 = 'N9', // MISCELLANEOUS ASYLUM, HOME
  O1 = 'O1', // OFFICE ONLY - 1 STORY
  O2 = 'O2', // OFFICE ONLY 2 - 6 STORIES
  O3 = 'O3', // OFFICE ONLY 7 - 19 STORIES
  O4 = 'O4', // OFFICE ONLY WITH OR WITHOUT COMM - 20 STORIES OR MORE
  O5 = 'O5', // OFFICE WITH COMM - 1 TO 6 STORIES
  O6 = 'O6', // OFFICE WITH COMM 7 - 19 STORIES
  O7 = 'O7', // PROFESSIONAL BUILDINGS/STAND ALONE FUNERAL HOMES
  O8 = 'O8', // OFFICE WITH APARTMENTS ONLY (NO COMM)
  O9 = 'O9', // MISCELLANEOUS AND OLD STYLE BANK BLDGS.
  P1 = 'P1', // CONCERT HALL
  P2 = 'P2', // LODGE ROOM
  P3 = 'P3', // YWCA, YMCA, YWHA, YMHA, PAL
  P4 = 'P4', // BEACH CLUB
  P5 = 'P5', // COMMUNITY CENTER
  P6 = 'P6', // AMUSEMENT PLACE, BATH HOUSE, BOAT HOUSE
  P7 = 'P7', // MUSEUM
  P8 = 'P8', // LIBRARY
  P9 = 'P9', // MISCELLANEOUS INDOOR PUBLIC ASSEMBLY
  Q1 = 'Q1', // PARKS/RECREATION FACILTY
  Q2 = 'Q2', // PLAYGROUND
  Q3 = 'Q3', // OUTDOOR POOL
  Q4 = 'Q4', // BEACH
  Q5 = 'Q5', // GOLF COURSE
  Q6 = 'Q6', // STADIUM, RACE TRACK, BASEBALL FIELD
  Q7 = 'Q7', // TENNIS COURT
  Q8 = 'Q8', // MARINA, YACHT CLUB
  Q9 = 'Q9', // MISCELLANEOUS OUTDOOR RECREATIONAL FACILITY
  RA = 'RA', // CULTURAL, MEDICAL, EDUCATIONAL, ETC.
  RB = 'RB', // OFFICE SPACE
  RG = 'RG', // INDOOR PARKING
  RH = 'RH', // HOTEL/BOATEL
  RK = 'RK', // RETAIL SPACE
  RP = 'RP', // OUTDOOR PARKING
  RR = 'RR', // CONDOMINIUM RENTALS
  RS = 'RS', // NON-BUSINESS STORAGE SPACE
  RT = 'RT', // TERRACES/GARDENS/CABANAS
  RW = 'RW', // WAREHOUSE/FACTORY/INDUSTRIAL
  R0 = 'R0', // SPECIAL CONDOMINIUM BILLING LOT
  R1 = 'R1', // CONDO; RESIDENTIAL UNIT IN 2-10 UNIT BLDG.
  R2 = 'R2', // CONDO; RESIDENTIAL UNIT IN WALK-UP BLDG.
  R3 = 'R3', // CONDO; RESIDENTIAL UNIT IN 1-3 STORY BLDG.
  R4 = 'R4', // CONDO; RESIDENTIAL UNIT IN ELEVATOR BLDG.
  R5 = 'R5', // MISCELLANEOUS COMMERCIAL
  R6 = 'R6', // CONDO; RESID.UNIT OF 1-3 UNIT BLDG-ORIG CLASS 1
  R7 = 'R7', // CONDO; COMML.UNIT OF 1-3 UNIT BLDG-ORIG CLASS 1
  R8 = 'R8', // CONDO; COMML.UNIT OF 2-10 UNIT BLDG.
  R9 = 'R9', // CO-OP WITHIN A CONDOMINIUM
  S0 = 'S0', // PRIMARILY 1 FAMILY WITH 2 STORES OR OFFICES
  S1 = 'S1', // PRIMARILY 1 FAMILY WITH 1 STORE OR OFFICE
  S2 = 'S2', // PRIMARILY 2 FAMILY WITH 1 STORE OR OFFICE
  S3 = 'S3', // PRIMARILY 3 FAMILY WITH 1 STORE OR OFFICE
  S4 = 'S4', // PRIMARILY 4 FAMILY WITH 1 STORE OROFFICE
  S5 = 'S5', // PRIMARILY 5-6 FAMILY WITH 1 STORE OR OFFICE
  S9 = 'S9', // SINGLE OR MULTIPLE DWELLING WITH STORES OR OFFICES
  T1 = 'T1', // AIRPORT, AIRFIELD, TERMINAL
  T2 = 'T2', // PIER, DOCK, BULKHEAD
  T9 = 'T9', // MISCELLANEOUS TRANSPORTATION FACILITY
  U0 = 'U0', // UTILITY COMPANY LAND AND BUILDING
  U1 = 'U1', // BRIDGE, TUNNEL, HIGHWAY
  U2 = 'U2', // GAS OR ELECTRIC UTILITY
  U3 = 'U3', // CEILING RAILROAD
  U4 = 'U4', // TELEPHONE UTILITY
  U5 = 'U5', // COMMUNICATION FACILITY OTHER THAN TELEPHONE
  U6 = 'U6', // RAILROAD - PRIVATE OWNERSHIP
  U7 = 'U7', // TRANSPORTATION - PUBLIC OWNERSHIP
  U8 = 'U8', // REVOCABLE CONSENT
  U9 = 'U9', // MISCELLANEOUS UTILITY PROPERTY
  V0 = 'V0', // ZONED RESIDENTIAL; NOT MANHATTAN
  V1 = 'V1', // ZONED COMMERCIAL OR MANHATTAN RESIDENTIAL
  V2 = 'V2', // ZONED COMMERCIAL ADJACENT TO CLASS 1 DWELLING: NOT MANHATTAN
  V3 = 'V3', // ZONED PRIMARILY RESIDENTIAL; NOT MANHATTAN
  V4 = 'V4', // POLICE OR FIRE DEPARTMENT
  V5 = 'V5', // SCHOOL SITE OR YARD
  V6 = 'V6', // LIBRARY, HOSPITAL OR MUSEUM
  V7 = 'V7', // PORT AUTHORITY OF NEW YORK AND NEW JERSEY
  V8 = 'V8', // NEW YORK STATE OR US GOVERNMENT
  V9 = 'V9', // MISCELLANEOUS VACANT LAND
  W1 = 'W1', // PUBLIC ELEMENTARY, JUNIOR OR SENIOR HIGH
  W2 = 'W2', // PAROCHIAL SCHOOL, YESHIVA
  W3 = 'W3', // SCHOOL OR ACADEMY
  W4 = 'W4', // TRAINING SCHOOL
  W5 = 'W5', // CITY UNIVERSITY
  W6 = 'W6', // OTHER COLLEGE AND UNIVERSITY
  W7 = 'W7', // THEOLOGICAL SEMINARY
  W8 = 'W8', // OTHER PRIVATE SCHOOL
  W9 = 'W9', // MISCELLANEOUS EDUCATIONAL FACILITY
  Y1 = 'Y1', // FIRE DEPARTMENT
  Y2 = 'Y2', // POLICE DEPARTMENT
  Y3 = 'Y3', // PRISON, JAIL, HOUSE OF DETENTION
  Y4 = 'Y4', // MILITARY AND NAVAL INSTALLATION
  Y5 = 'Y5', // DEPARTMENT OF REAL ESTATE
  Y6 = 'Y6', // DEPARTMENT OF SANITATION
  Y7 = 'Y7', // DEPARTMENT OF PORTS AND TERMINALS
  Y8 = 'Y8', // DEPARTMENT OF PUBLIC WORKS
  Y9 = 'Y9', // DEPARTMENT OF ENVIRONMENTAL PROTECTION
  Z0 = 'Z0', // TENNIS COURT, POOL, SHED, ETC.
  Z1 = 'Z1', // COURT HOUSE
  Z2 = 'Z2', // PUBLIC PARKING AREA
  Z3 = 'Z3', // POST OFFICE
  Z4 = 'Z4', // FOREIGN GOVERNMENT
  Z5 = 'Z5', // UNITED NATIONS
  Z7 = 'Z7', // EASEMENT
  Z8 = 'Z8', // CEMETERY
  Z9 = 'Z9', // OTHER MISCELLANEOUS
}
const HotelBuildingClasses = [
  BuildingClasses.H1,
  BuildingClasses.H2,
  BuildingClasses.H3,
  BuildingClasses.H4,
  BuildingClasses.H5,
  BuildingClasses.H6,
  BuildingClasses.H7,
  BuildingClasses.H8,
  BuildingClasses.H9,
  BuildingClasses.HB,
  BuildingClasses.HH,
  BuildingClasses.HR,
  BuildingClasses.HS,
  BuildingClasses.RH,
]

enum TaxClasses {
  _1 = '1',
  _2 = '2',
  _4 = '4',
  _2A = '2A',
  _2B = '2B',
}

enum ActualAVType {
  Final = 'CS_BBL_AV_TYPE_FINAL',
  Tentative = 'CS_BBL_AV_TYPE_TENTATIVE',
}

enum Services {
  TaxAppeal = 'CS_AGREEMENT_SERVICE_TYPE_TAX',
  RPIE = 'CS_AGREEMENT_SERVICE_TYPE_RPIE',
}

enum SFApplicationStatus {
  Void = 'CS_SF_APPL_STATUS_VOID',
  New = 'CS_SF_APPL_STATUS_NEW',
  InProgress = 'CS_SF_APPL_STATUS_IN_PROGRESS',
  Approved = 'CS_SF_APPL_STATUS_APPROVED',
  ReadyToFiling = 'CS_SF_APPL_STATUS_READY_TO_FILE',
  Filed = 'CS_SF_APPL_STATUS_FILED',
}

enum RPIEStatuses {
  DataUpdateRequested = 'CS_WF_LT_SVC_STS_RPIE_AWAIT_VERF',
  Imported = 'CS_WF_LT_SVC_STS_RPIE_IMPORTED',
  InProgress = 'CS_WF_LT_SVC_STS_RPIE_IN_PROGRESS',
  NotStarted = 'CS_WF_LT_SVC_STS_RPIE_NEW',
  NoSubmission = 'CS_WF_LT_SVC_STS_TA_NO_SUBMISSION',
  RPIEFiled = 'CS_WF_LT_SVC_STS_RPIE_RPIE_FILED',
  SubmittedPendingDocumentReview = 'CS_WF_LT_SVC_STS_RPIE_SBM_PND_DOC',
  Submitted = 'CS_WF_LT_SVC_STS_RPIE_SUBMIT',
}

enum RPIEWizardSections {
  General = 'CS_WZSC_RPIE_GNR',
  IncomeExpense = 'CS_WZSC_RPIE_IE',
  Storefront = 'CS_WZSC_RPIE_SF',
  RentRollResidential = 'CS_WZSC_RPIE_RRR',
  RentRollCommercial = 'CS_WZSC_RPIE_RRC',
  ReviewAndSubmit = 'CS_WZSC_RPIE_RS',
}

enum RPIEWizardSteps {
  PropertyDescription = 'CS_WZST_RPIE_PROP_DESCR',
  Parking = 'CS_WZST_RPIE_PARKING',
  NetLeaseFillTypeSelection = 'CS_WZST_RPIE_NET_LEASE_FTS',
  NetLeaseUpload = 'CS_WZST_RPIE_NET_LEASE_UPL',
  NetLease = 'CS_WZST_RPIE_NET_LEASE_FILL',
  ResidentialDescription = 'CS_WZST_RPIE_RESID_DESCR',
  CommercialDescription = 'CS_WZST_RPIE_COMM_DESCR',

  HotelOperation = 'CS_WZST_TA_HTL_OP',
  HotelRoomRates = 'CS_WZST_TA_HTL_RATES',
  HotelFurnitureFixturesAndEquipment = 'CS_WZST_TA_HTL_FRN_FIX_EQP',

  ReportingPeriod = 'CS_WZST_RPIE_REP_PRD',
  IncomeFillTypeSelection = 'CS_WZST_RPIE_INC_FTS',
  IncomeUpload = 'CS_WZST_RPIE_INC_UPL',
  Income = 'CS_WZST_RPIE_INC_FILL',
  Expense = 'CS_WZST_RPIE_EXP_FILL',

  StorefrontGeneralInfo1 = 'CS_WZST_RPIE_SF_GI_1',
  StorefrontGeneralInfo2 = 'CS_WZST_RPIE_SF_GI_2',
  StorefrontUnitOccupancyIntervals = 'CS_WZST_RPIE_SF_UNT_OCC_INT',
  StorefrontUnitOccupantContactInfo = 'CS_WZST_RPIE_SF_UNT_OCC_CNTCT',
  StorefrontUnitOccupantRentInfo = 'CS_WZST_RPIE_SF_UNT_OCC_RENT',
  StorefrontUnitOccupantLeaseConcessions = 'CS_WZST_RPIE_SF_UNT_OCC_LEASE',
  StorefrontUnitOccupantDOB = 'CS_WZST_RPIE_SF_UNT_OCC_DOB',

  RentRollCommercial = 'CS_WZST_RPIE_RR_COM_FILL',
  RentRollCommercialFillTypeSelection = 'CS_WZST_RPIE_RR_COM_FTS',
  RentRollCommercialUpload = 'CS_WZST_RPIE_RR_COM_UPL',

  RentRollResidentialFillTypeSelection = 'CS_WZST_RPIE_RR_RES_FTS',
  RentRollResidentialUpload = 'CS_WZST_RPIE_RR_RES_UPL',
  RentRollResidential = 'CS_WZST_RPIE_RR_RES_FILL',

  Summary = 'CS_WZST_RPIE_SMMR',
  Submit = 'CS_WZST_RPIE_SBMT',
  Finish = 'CS_WZST_RPIE_FIN',
}

enum TAStatuses {
  New = 'CS_WF_LT_SVC_STS_TA_NEW',
  Submit = 'CS_WF_LT_SVC_STS_TA_SUBMIT',
  TC101Ready = 'CS_WF_LT_SVC_STS_TA_TC101_READY',
  TC101Imported = 'CS_WF_LT_SVC_STS_TA_TC101_IMPORTED',
  InProgress = 'CS_WF_LT_SVC_STS_TA_IN_PROGRESS',
  AwaitVerification = 'CS_WF_LT_SVC_STS_TA_AWAIT_VERF',
  Imported = 'CS_WF_LT_SVC_STS_TA_IMPORTED',
  NoSubmission = 'CS_WF_LT_SVC_STS_TA_NO_SUBMISSION',
  SubmitOutOfPortal = 'CS_WF_LT_SVC_STS_TA_SUBMIT_OUT_PRTL',
  SubmitPendingDocs = 'CS_WF_LT_SVC_STS_TA_SBM_PND_DOC',
}

enum TAWizardSections {
  GeneralInformation = 'SC_WZSC_TA_GNR',
  PropertyDetails = 'SC_WZSC_TA_PD',
  ReviewAndSubmit = 'SC_WZSC_TA_RS',
}

enum TAWizardSteps {
  DescriptionReveiw = 'CS_WZST_TA_DESCR_REVIEW',
  DescriptionMainInfo = 'CS_WZST_TA_DESCR_MAIN_INFO',
  DescriptionAreas = 'CS_WZST_TA_DESCR_AREAS',
  DescriptionParking = 'CS_WZST_TA_DESCR_PARKING',
  DescriptionCovid = 'CS_WZST_TA_DESCR_COVID',
  DescriptionStatus = 'CS_WZST_TA_DESCR_STATUS',
  DescriptionConstruction = 'CS_WZST_TA_DESCR_CONSTR',
  DescriptionConstructionCosts = 'CS_WZST_TA_DESCR_CONSTR_COSTS',
  //====
  DetailsRentRollFillTypeSelection = 'CS_WZST_TA_DETAILS_RR_FTS',
  DetailsRentRollUpload = 'CS_WZST_TA_DETAILS_RR_UPL',
  DetailsResidentialMain = 'CS_WZST_TA_DETAILS_RES_MAIN',
  DetailsResidentialRent = 'CS_WZST_TA_DETAILS_RES_RENT',
  //====
  DetailsNonResidentialBasement = 'CS_WZST_TA_DETAILS_NONRES_BSMNT',
  DetailsNonResidentialFirst = 'CS_WZST_TA_DETAILS_NONRES_FST',
  DetailsNonResidentialSecond = 'CS_WZST_TA_DETAILS_NONRES_SND',
  DetailsNonResidentialThird = 'CS_WZST_TA_DETAILS_NONRES_TRD',

  HotelOperation = 'CS_WZST_TA_HTL_OP',
  HotelRoomRates = 'CS_WZST_TA_HTL_RATES',
  HotelNonDepartmentalOp = 'CS_WZST_TA_HTL_NONDEP_OP',
  HotelFurnitureFixturesAndEquipment = 'CS_WZST_TA_HTL_FRN_FIX_EQP',

  RentalIncome = 'CS_WZST_TA_RNTL_INC',
  ReportingPeriod = 'CS_WZST_TA_REP_PRD',
  IncomeFillTypeSelection = 'CS_WZST_TA_INC_FTS',
  IncomeUpload = 'CS_WZST_TA_INC_UPL',
  Income = 'CS_WZST_TA_INC_FILL',
  Expense = 'CS_WZST_TA_EXP_FILL',

  Summary = 'CS_WZST_TA_SMMR',
  Submit = 'CS_WZST_TA_FL_SBMT',
}

enum Relations {
  Owner = 'CS_APPLCNT_RELATION_OWNER_1',
  NetLessee = 'CS_APPLCNT_RELATION_NET_LESSEE_2',
  PartialLessee = 'CS_APPLCNT_RELATION_PARTIAL_LESSEE_3',
  BuyerUnderContract = 'CS_APPLCNT_RELATION_BUYER_UNDER_CONTRACT_4',
  Mortgage = 'CS_APPLCNT_RELATION_MORTGAGE_5',
  Trustee = 'CS_APPLCNT_RELATION_TRUSTEE_6',
  CondoBoard = 'CS_APPLCNT_RELATION_CONDO_BOARD_7',
  OwnerInterest = 'CS_APPLCNT_RELATION_OWNER_INTEREST_8',
  Other = 'CS_APPLCNT_RELATION_OTHER_9',
}

enum PropertyUsage {
  NonResidential = 'CS_PROP_USAGE_NON_RESIDENTIAL',
  Residential = 'CS_PROP_USAGE_RESIDENTIAL',
  Mixed = 'CS_PROP_USAGE_MIXED',
  Vacant = 'CS_PROP_USAGE_VACANT',
}

enum UseByApplicant {
  Entire = 'CS_USE_APPLCNT_ENTIRE',
  Part = 'CS_USE_APPLCNT_PART',
  No = 'CS_USE_APPLCNT_NO',
}

enum PortionUsedByApplicant {
  Residential = 'CS_PRTN_USED_APPLCNT_RESIDENTIAL',
  NonResidential = 'CS_PRTN_USED_APPLCNT_NONRESIDENTIAL',
  Both = 'CS_PRTN_USED_APPLCNT_BOTH',
}

enum ReportingPeriodType {
  Calendar = 'CS_RPT_PRD_TYPE_CALENDAR',
  Fiscal = 'CS_RPT_PRD_TYPE_FISCAL',
  Partial = 'CS_RPT_PRD_TYPE_PARTIAL',
}

enum AccountingBasis {
  Cash = 'CS_RPT_PRD_ACNT_BS_CASH',
  Accrual = 'CS_RPT_PRD_ACNT_BS_ACCRUAL',
}

enum ExpenseSectionTypes {
  //SINGLE
  ExpenseMain = 'CS_EXPENSE_SECTION_SNGL_MAIN',
  ExpenseNonDeductable = 'CS_EXPENSE_SECTION_SNGL_NONDEDUCTIBLE',
  ExpenseOther = 'CS_EXPENSE_SECTION_SNGL_OTHER',

  //HOTEL
  HotelDepartmental = 'CS_EXPENSE_SECTION_HTL_DEPARTMENTAL',
  HotelFinancial = 'CS_EXPENSE_SECTION_HTL_FINANCIAL',
  HotelUndistributed = 'CS_EXPENSE_SECTION_HTL_UNDISTRIBUTED',
  HotelOther = 'CS_EXPENSE_SECTION_HTL_OTHER',

  //CONDO
  CondoMain = 'CS_EXPENSE_SECTION_CONDO_MAIN',
  CondoOther = 'CS_EXPENSE_SECTION_CONDO_OTHER',
}

enum ExpenseCategories {
  Fuel = 'CS_EXPENSE_CATEGORY_MN_FUEL',
  Light = 'CS_EXPENSE_CATEGORY_MN_LIGHT',
  Cleaning = 'CS_EXPENSE_CATEGORY_MN_CLEANING',
  Wages = 'CS_EXPENSE_CATEGORY_MN_WAGES',
  Repairs = 'CS_EXPENSE_CATEGORY_MN_REPAIRS',
  Management = 'CS_EXPENSE_CATEGORY_MN_MANAGEMENT',
  Insurance = 'CS_EXPENSE_CATEGORY_MN_INSURANCE',
  Water = 'CS_EXPENSE_CATEGORY_MN_WATER',
  Advertising = 'CS_EXPENSE_CATEGORY_MN_ADVERTISING',
  Interior = 'CS_EXPENSE_CATEGORY_MN_INTERIOR',
  Leasing = 'CS_EXPENSE_CATEGORY_MN_LEASING',
  Tenant = 'CS_EXPENSE_CATEGORY_MN_TENANT',
  Taxes = 'CS_EXPENSE_CATEGORY_NONDD_RE_TAXES',
  BadDebt = 'CS_EXPENSE_CATEGORY_NONDD_BAD_DEBT',
  Depreciation = 'CS_EXPENSE_CATEGORY_NONDD_DEPRECIATION',
  Mortgage = 'CS_EXPENSE_CATEGORY_NONDD_MORTGAGE',

  //HOTEL
  FoodAndBeverage = 'CS_EXPENSE_CATEGORY_HTL_DPT_FOOD',
  OtherDepartments = 'CS_EXPENSE_CATEGORY_HTL_DPT_OTHER',
  Rooms = 'CS_EXPENSE_CATEGORY_HTL_DPT_ROOMS',
  Telephone = 'CS_EXPENSE_CATEGORY_HTL_DPT_TELEPHONE',
  AudioVisual = 'CS_EXPENSE_CATEGORY_HTL_DPT_AUDIO',
  PublicRoom = 'CS_EXPENSE_CATEGORY_HTL_DPT_PUBLICROOM',
  RealEstateRentExpense = 'CS_EXPENSE_CATEGORY_HTL_FNC_RE_RENT',
  RealEstateTaxes = 'CS_EXPENSE_CATEGORY_HTL_FNC_RE_TAXES',
  Utilities = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_UTILITIES',
  AdministrativeAndGeneral = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_ADMIN',
  Energy = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_ENERGY',
  FranchiseFee = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_FRANCHISE',
  HotelInsurance = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_INSURANCE',
  ManagementFee = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_MANAGEMENT',
  Marketing = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_MARKETING',
  OtherOperatingExpenses = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_OTHER',
  OperationsAndMaintenance = 'CS_EXPENSE_CATEGORY_HTL_UNDIS_PROPERTY',

  //CONDO
  Electricity = 'CS_EXPENSE_CATEGORY_CONDO_ELECTRICITY',
  CondoFuel = 'CS_EXPENSE_CATEGORY_CONDO_FUEL',
  CondoInsurance = 'CS_EXPENSE_CATEGORY_CONDO_INSURANCE',
  LeasingCommissions = 'CS_EXPENSE_CATEGORY_CONDO_LEASING',
  CondoManagement = 'CS_EXPENSE_CATEGORY_CONDO_MANAGEMENT',
  OtherExpenses = 'CS_EXPENSE_CATEGORY_CONDO_OTHER',
  RepairsAndMaintenance = 'CS_EXPENSE_CATEGORY_CONDO_REPAIRS',
  WagesAndPayrollCosts = 'CS_EXPENSE_CATEGORY_CONDO_WAGES',
  WaterAndSewer = 'CS_EXPENSE_CATEGORY_CONDO_WATER',
  LandOrBuildingRent = 'CS_EXPENSE_CATEGORY_CONDO_LAND_RENT',
}

enum IncomeSectionTypes {
  //SINGLE
  IncomeMain = 'CS_INCOME_SECTION_SNGL_MAIN',
  IncomeAdditional = 'CS_INCOME_SECTION_SNGL_ADDITIONAL',
  IncomeOther = 'CS_INCOME_SECTION_SNGL_OTHER',

  //HOTEL
  HotelMain = 'CS_INCOME_SECTION_HTL_MAIN',
  HotelRental = 'CS_INCOME_SECTION_HTL_RENTAL',
  HotelOther = 'CS_INCOME_SECTION_HTL_OTHER',

  //CONDO
  CondoMain = 'CS_INCOME_SECTION_CONDO_MAIN',
  CondoRental = 'CS_INCOME_SECTION_CONDO_RENTAL',
  CondoOther = 'CS_INCOME_SECTION_CONDO_OTHER',
}

enum IncomeCategories {
  // Additional
  OperatingEscalation = 'CS_INCOMES_SOURCE_RE_ANC_OPERATING',
  TaxEscalation = 'CS_INCOMES_SOURCE_RE_ANC_RE_TAX',
  SaleUtility = 'CS_INCOMES_SOURCE_RE_ANC_SALE_UTILITY',
  SaleOther = 'CS_INCOMES_SOURCE_RE_ANC_SALE_OTHER',
  GovernmentRent = 'CS_INCOMES_SOURCE_RE_ANC_GOV_RENT',
  Signage = 'CS_INCOMES_SOURCE_RE_ANC_SIGNAGE',
  CellTower = 'CS_INCOMES_SOURCE_RE_ANC_CELL_TOWER',
  // LateFees = 'CS_INCOMES_SOURCE_RE_ANC_LATE_FEES',
  // TenantCharges = 'CS_INCOMES_SOURCE_RE_ANC_TENT_CHARG',

  // Main
  Office = 'CS_INCOMES_SOURCE_RE_NONRES_OFFICE',
  ResRegulated = 'CS_INCOMES_SOURCE_RE_RES_REGULATED',
  ResUnregulated = 'CS_INCOMES_SOURCE_RE_RES_UNREGULATED',
  Retail = 'CS_INCOMES_SOURCE_RE_NONRES_RETAIL',
  Loft = 'CS_INCOMES_SOURCE_RE_NONRES_LOFT',
  Factory = 'CS_INCOMES_SOURCE_RE_NONRES_FACTORY',
  Warehouse = 'CS_INCOMES_SOURCE_RE_NONRES_WAREHOUSE',
  Storage = 'CS_INCOMES_SOURCE_RE_NONRES_STORAGE',
  Garages = 'CS_INCOMES_SOURCE_RE_NONRES_GARAGES',
  Parking = 'CS_INCOMES_SOURCE_RE_NONRES_PARKING',
  Owner = 'CS_INCOMES_SOURCE_RE_NONRES_OWNER',
  Other = 'CS_INCOMES_SOURCE_RE_NONRES_OTHER',

  //HOTEL MAIN
  Rooms = 'CS_INCOMES_SOURCE_HTL_DPT_ROOMS',
  Food = 'CS_INCOMES_SOURCE_HTL_DPT_FOOD',
  Telephone = 'CS_INCOMES_SOURCE_HTL_DPT_TELEPHONE',
  PublicRoom = 'CS_INCOMES_SOURCE_HTL_DPT_PUBLIC_ROOM',
  MainParking = 'CS_INCOMES_SOURCE_HTL_DPT_PARKING',
  AudioVisual = 'CS_INCOMES_SOURCE_HTL_DPT_AUDIO_VISUAL',
  HotelOther = 'CS_INCOMES_SOURCE_HTL_DPT_OTHER',

  //HOTEL RENTAL
  RentalApartments = 'CS_INCOMES_HOTEL_OR_APARTMENTS',
  RentalRetail = 'CS_INCOMES_HOTEL_OR_RETAIL',
  RentalRestaurant = 'CS_INCOMES_HOTEL_OR_RESTAURANT',
  RentalOffice = 'CS_INCOMES_HOTEL_OR_OFFICE',

  RentalParking = 'CS_INCOMES_HOTEL_OR_PARKING',
  RentalTelecom = 'CS_INCOMES_HOTEL_OR_TELECOM',
  RentalOther = 'CS_INCOMES_HOTEL_OR_OTHER',
  RentalSignage = 'CS_INCOMES_HOTEL_OR_SIGNAGE',

  //Condo Main
  MaintenanceCommonCharges = 'CS_INCOMES_SOURCE_CONDO_UNIT_OWNER',

  //Condo Rental
  Residental = 'CS_INCOMES_SOURCE_COND_CM_RESIDENTIAL',
  CondoRetail = 'CS_INCOMES_SOURCE_COND_CM_RETAIL',
  Offices = 'CS_INCOMES_SOURCE_COND_CM_OFFICES',
  CondoOther = 'CS_INCOMES_SOURCE_COND_CM_OTHER',
  Signate = 'CS_INCOMES_SOURCE_COND_CM_SIGNAGE',
  Tower = 'CS_INCOMES_SOURCE_COND_CM_CELL_TOWER',
  Garage = 'CS_INCOMES_SOURCE_COND_CM_GARAGE',
  Laundry = 'CS_INCOMES_SOURCE_COND_CM_LAUNDRY',
}

enum FloorUsesType {
  // Garage = 'CS_TC_FD_USES_TP_GARAGE',
  // RetailOld = 'CS_TC_FD_USES_TP_RETAIL',
  // Parking = 'CS_TC_FD_USES_TP_PARKING',
  // Retail = 'CS_TC_FD_USES_TP_RETAIL_USES',
  // Construction = 'CS_TC_FD_USES_TP_UNDER_CONSTRUCTION',
  // Vacant = 'CS_TC_FD_USES_TP_VACANT',
  // Other = 'CS_TC_FD_USES_TP_OTHER',
  AccessoryCommStorage = 'CS_TC_FD_USES_TP_ACC_COMM_STORAGE',
  AdultDayCare = 'CS_TC_FD_USES_TP_ADULT_CARE',
  AmusementCenter = 'CS_TC_FD_USES_TP_AMUSEMENT_CENTER',
  ArtGallery = 'CS_TC_FD_USES_TP_ART_GALLERY',
  ArtistStudio = 'CS_TC_FD_USES_TP_ARTIST_STUDIO',
  AutoDealership = 'CS_TC_FD_USES_TP_AUTO_DEALERSHIP',
  AutoRepair = 'CS_TC_FD_USES_TP_AUTO_REPAIR',
  BoilerRoom = 'CS_TC_FD_USES_TP_BOILER_ROOM',
  CarWash = 'CS_TC_FD_USES_TP_CAR_WASH',
  Church = 'CS_TC_FD_USES_TP_CHURCH',
  CommunityCenter = 'CS_TC_FD_USES_TP_COMMUNITY_CENTER',
  CommLobby = 'CS_TC_FD_USES_TP_COMM_LOBBY',
  ConcertHall = 'CS_TC_FD_USES_TP_CONCERT_HALL',
  CulturalFacility = 'CS_TC_FD_USES_TP_CULT_FACILITY',
  DayCareCenter = 'CS_TC_FD_USES_TP_DAY_CENTER',
  Dormitory = 'CS_TC_FD_USES_TP_DORMITORY',
  EducationalFacility = 'CS_TC_FD_USES_TP_EDUC_FACILITY',
  Factory = 'CS_TC_FD_USES_TP_FACTORY',
  FuneralHome = 'CS_TC_FD_USES_TP_FUNERAL_HOME',
  Garage = 'CS_TC_FD_USES_TP_GARAGE',
  GarbageCollectionArea = 'CS_TC_FD_USES_TP_GARBAGE_AREA',
  GasStation = 'CS_TC_FD_USES_TP_GAS_STATION',
  Gym = 'CS_TC_FD_USES_TP_GYM',
  Hospital = 'CS_TC_FD_USES_TP_HOSPITAL_HEALTH',
  Hotel = 'CS_TC_FD_USES_TP_HOTEL',
  Laundromat = 'CS_TC_FD_USES_TP_LAUNDROMAT',
  Loft = 'CS_TC_FD_USES_TP_LOFT',
  MedicalFacility = 'CS_TC_FD_USES_TP_MEDIC_FACILITY',
  MeterRoom = 'CS_TC_FD_USES_TP_METER_ROOM',
  Museum = 'CS_TC_FD_USES_TP_MUSEUM',
  OfficeUses = 'CS_TC_FD_USES_TP_OFFICE_USES',
  OrdinaryUse = 'CS_TC_FD_USES_TP_ORDINARY_USE',
  Other = 'CS_TC_FD_USES_TP_OTHER',
  OutdoorUses = 'CS_TC_FD_USES_TP_OUTDOOR_USES',
  Parking = 'CS_TC_FD_USES_TP_PARKING',
  ResidentialParking = 'CS_TC_FD_USES_TP_RESID_PARKING',
  AccessoryParking = 'CS_TC_FD_USES_TP_ACCESS_PARKING',
  RecordingStudio = 'CS_TC_FD_USES_TP_RECORDING_STUDIO',
  Resident = 'CS_TC_FD_USES_TP_RESIDENT',
  RetailUses = 'CS_TC_FD_USES_TP_RETAIL_USES',
  School = 'CS_TC_FD_USES_TP_SCHOOL',
  Shelter = 'CS_TC_FD_USES_TP_SHELTER',
  Storage = 'CS_TC_FD_USES_TP_STORAGE',
  Theater = 'CS_TC_FD_USES_TP_THEATER',
  UnderConstruction = 'CS_TC_FD_USES_TP_UNDER_CONSTRUCTION',
  Vacant = 'CS_TC_FD_USES_TP_VACANT',
  Veterinary = 'CS_TC_FD_USES_TP_VETERINARY',
  Warehouse = 'CS_TC_FD_USES_TP_WAREHOUSE',
  ResidApartments = 'CS_TC_FD_USES_TP_RESID_APARTMENTS',
  ResidLobby = 'CS_TC_FD_USES_TP_RESID_LOBBY',
  ResidRecreationalArea = 'CS_TC_FD_USES_TP_RESID_RECR_AREA',
  AccessoryResidStorage = 'CS_TC_FD_USES_TP_ACC_RESID_STORAGE',
  Office = 'CS_TC_FD_USES_TP_OFFICE',
  ProfessionalOffice = 'CS_TC_FD_USES_TP_PROF_OFFICE',
  MedicalOffice = 'CS_TC_FD_USES_TP_MEDICAL_OFFICE',
  Backyard = 'CS_TC_FD_USES_TP_BACKYARD',
  OtherOut = 'CS_TC_FD_USES_TP_OUT_OTHER',
  ParkPlayground = 'CS_TC_FD_USES_TP_PLAYGROUND',
  CellTower = 'CS_TC_FD_USES_TP_CELL_TOWER',
  Courtyard = 'CS_TC_FD_USES_TP_COURTYARD',
  Generators = 'CS_TC_FD_USES_TP_GENERATOR',
  Signage = 'CS_TC_FD_USES_TP_SIGNAGE',
  TelecomEquipment = 'CS_TC_FD_USES_TP_TELECOM_EQUIP',
  Retail = 'CS_TC_FD_USES_TP_RETAIL',
  Bank = 'CS_TC_FD_USES_TP_BANK',
  Cafeteria = 'CS_TC_FD_USES_TP_CAFETERIA',
  Lounge = 'CS_TC_FD_USES_TP_LOUNGE',
  Restaurant = 'CS_TC_FD_USES_TP_RESTAURANT',
  Spa = 'CS_TC_FD_USES_TP_SPA',
  Bar = 'CS_TC_FD_USES_TP_BAR',
  FastFoodFranchise = 'CS_TC_FD_USES_TP_FAST_FOOD_FRANCH',
  NightClub = 'CS_TC_FD_USES_TP_NIGHT_CLUB',
  RoofComm = 'CS_TC_FD_USES_TP_ROOF_COMM',
  RoofRes = 'CS_TC_FD_USES_TP_ROOF_RES',
}

enum PortionRentFromAnyTenants {
  Residential = 'CS_PRTN_VCNT_TRBL_TENANT_RESIDENTIAL',
  NonResidential = 'CS_PRTN_VCNT_TRBL_TENANT_NONRESIDENTIAL',
  Both = 'CS_PRTN_VCNT_TRBL_TENANT_BOTH',
}

enum PropertyType {
  Hotel = 'CS_PRPT_TP_HOTEL',
  Coop = 'CS_PRPT_TP_COOP',
  Single = 'CS_PRPT_TP_SINGLE',
  CondoRelation1 = 'CS_PRPT_TP_CONDO_1',
  CondoRelation7 = 'CS_PRPT_TP_CONDO_7',
  Vacant = 'CS_PRPT_TP_VACANT',
}

enum EventType {
  Aggregated = 'AGGREGATED',
  NonAggregated = 'NON_AGGREGATED',
}

enum Aggregated {
  // events
  LastAccess = 'CS_EVENT_TP_LAST_ACCESS',
}

enum NonAggregated {
  // warnings
  UpdatedGeneralInfo = 'CS_EVENT_TP_WRN_UPD_GNRL_INFO_TAX',
  UpdatedParking = 'CS_EVENT_TP_WRN_UPD_PARK_TAX',
  OwnerOccupiedProperty = 'CS_EVENT_TP_WRN_OO_PROPERTY_TAX',
  UnderConstructions = 'CS_EVENT_TP_WRN_UNDR_CONSTRUCTION_TAX',
  NoRentalIncome = 'CS_EVENT_TP_WRN_NO_RENTAL_INCOME_TAX',
  UploadedDocuments = 'CS_EVENT_TP_WRN_UPLD_DOCS_TAX',
  TooHighMonthlyResRR = 'CS_EVENT_TP_WRN_HIGH_RES_RR_RENT_TAX',
  SaleInTheReportingYear = 'CS_EVENT_TP_WRN_SALE_IN_RPT_PRD_TAX',
  // TC Curse warnings
  OperatingLoss = 'CS_EVENT_TP_WRN_TC_OPERTNG_LOSS_TAX',
  GrossIncomeDecrease = 'CS_EVENT_TP_WRN_TC_INCOME_DCRS_TAX',
  OperatingExpensesDecrease = 'CS_EVENT_TP_WRN_TC_EXPNS_DCRS_TAX',
  RepairsGreater15 = 'CS_EVENT_TP_WRN_TC_RPR_GRTR_15_TAX',
  RR12LowerResidentialIncome = 'CS_EVENT_TP_WRN_TC_RR_12_LWR_RSDNL_INCM_TAX',
  RR12HigherResidentialIncome = 'CS_EVENT_TP_WRN_TC_RR_12_HGHR_RSDNL_INCM_TAX',
  RR12EqualsResidentialIncome = 'CS_EVENT_TP_WRN_TC_RR_12_EQLS_RSDNL_INCM_TAX',
}

enum HotelRoomTypes {
  King = 'CS_HTL_ROOM_TP_KING',
  Double = 'CS_HTL_ROOM_TP_DOUBLE',
  Single = 'CS_HTL_ROOM_TP_SINGLE',
  Queen = 'CS_HTL_ROOM_TP_QUEEN',
  Suite = 'CS_HTL_ROOM_TP_SUITE',
  Penthouse = 'CS_HTL_ROOM_TP_PENTHOUSE',
}

enum HotelFurnitureCosts {
  PurchasingItems = 'CS_HTL_FRNTR_PURCHASE_ITEMS',
  ContributionToReserve = 'CS_HTL_FRNTR_CONTR_RESERVE',
  BookAll = 'CS_HTL_FRNTR_BOOK_ALL',
  BookWoAccum = 'CS_HTL_FRNTR_BOOK_WO_ACCUM',
}

enum FileStatus {
  Uploaded = 'CS_UPLD_STATUS_DOCS',
}

enum DocumentType {
  // TC forms
  TC159 = 'CS_DOC_TYPE_TC159',
  TC200 = 'CS_DOC_TYPE_TC200',
  TC101 = 'CS_DOC_TYPE_TC101',
  TC201 = 'CS_DOC_TYPE_TC201',
  TC203 = 'CS_DOC_TYPE_TC203',
  TC309 = 'CS_DOC_TYPE_TC309',
  TC108 = 'CS_DOC_TYPE_TC108',
  TC109 = 'CS_DOC_TYPE_TC109',
  TC140 = 'CS_DOC_TYPE_TC140',
  TC150 = 'CS_DOC_TYPE_TC150',
  TC208 = 'CS_DOC_TYPE_TC208',
  TC214 = 'CS_DOC_TYPE_TC214',
  TC230 = 'CS_DOC_TYPE_TC230',
  TC106 = 'CS_DOC_TYPE_TC106',
  TC10 = 'CS_DOC_TYPE_TC10',
  TC10New = 'CS_DOC_TYPE_TC10_NEW',
  TC166 = 'CS_DOC_TYPE_TC166',
  TC70 = 'CS_DOC_TYPE_TC70',
  TC155 = 'CS_DOC_TYPE_TC155',
  TC301 = 'CS_DOC_TYPE_TC301',
  TC80 = 'CS_DOC_TYPE_TC80',
  TCFullSubmission = 'CS_DOC_TYPE_TCFULL',
  TCStatusNotif = 'CS_DOC_TYPE_TC_STATUS_NOTIF',
  AnalysisTC = 'CS_DOC_TYPE_TC_ANALYSIS_COMB',
  TC201Cons = 'CS_DOC_TYPE_TC201_CONSOLIDATED',
  TCWeb = 'CS_DOC_TYPE_WEB',
  // OTHER
  Covid = 'CS_DOC_TYPE_COVID',
  Income = 'CS_DOC_TYPE_PROFIT_LOSS_STATEMENT',
  ResRentRoll = 'CS_DOC_TYPE_RESIDENTIAL_RENT_ROLL',
  RPIERentRollCommercial = 'CS_DOC_TYPE_COMMERCIAL_RENT_ROLL',
  StarkpatchBio = 'CS_DOC_TYPE_STARK_BIO',
  RFRAnalysis = 'CS_DOC_TYPE_RFR_ANALYSIS',
  ExcelRpie1Year = 'CS_DOC_TYPE_EXCEL_RPIE_1YEAR',
  RPIEConfirmation = 'CS_REPORT_NAME_RPIE_CONF',
  RPIECopy = 'CS_DOC_TYPE_RPIE_COPY',
  RPIEWorksheetCompleted = 'CS_DOC_TYPE_RPIE_WORKSHEET_COMPLETED',
  Innocent = 'CS_DOC_TYPE_RPIE_INNO_PURCHASER_AFFIDAVIT',
  RPIEWorksheetAdult = 'CS_DOC_TYPE_RPIE_ADULT_DAY_CARE',
  RPIEWorksheetStation = 'CS_DOC_TYPE_RPIE_GAS_STATION',
  RPIEWorksheetHotels = 'CS_DOC_TYPE_RPIE_HOTELS',
  RPIEWorksheetSelfStorage = 'CS_DOC_TYPE_RPIE_SELF_STORAGE',
  RPIEWorksheetTheatres = 'CS_DOC_TYPE_RPIE_THEATRES',
  RPIEWorksheetBlank = 'CS_DOC_TYPE_RPIE_BLANK',
  RPIECover = 'CS_DOC_TYPE_COVER_LETTER',
  CommercialRR = 'CS_DOC_TYPE_COMMERCIAL_RENT_ROLL',
  ResidentialRR = 'CS_DOC_TYPE_RESIDENTIAL_RENT_ROLL',
  StorefrontRegistry = 'CS_DOC_TYPE_SF_REG',
  TaxCommission = 'CS_DOC_TYPE_TAX_COM_OFR_NOTIF',
  LeaseCopy = 'CS_DOC_TYPE_LEASE_COPY',
}

enum DocumentTypeTC {
  TC159 = 'CS_DOC_TYPE_TC159',
  TC200 = 'CS_DOC_TYPE_TC200',
  TC101 = 'CS_DOC_TYPE_TC101',
  TC201 = 'CS_DOC_TYPE_TC201',
  TC203 = 'CS_DOC_TYPE_TC203',
  TC309 = 'CS_DOC_TYPE_TC309',
  TC108 = 'CS_DOC_TYPE_TC108',
  TC109 = 'CS_DOC_TYPE_TC109',
  TC140 = 'CS_DOC_TYPE_TC140',
  TC150 = 'CS_DOC_TYPE_TC150',
  TC208 = 'CS_DOC_TYPE_TC208',
  TC214 = 'CS_DOC_TYPE_TC214',
  TC230 = 'CS_DOC_TYPE_TC230',
  TC106 = 'CS_DOC_TYPE_TC106',
  TC10 = 'CS_DOC_TYPE_TC10',
  TC10New = 'CS_DOC_TYPE_TC10_NEW',
  TC166 = 'CS_DOC_TYPE_TC166',
  TC70 = 'CS_DOC_TYPE_TC70',
  TC155 = 'CS_DOC_TYPE_TC155',
  TC301 = 'CS_DOC_TYPE_TC301',
  TC80 = 'CS_DOC_TYPE_TC80',
  TCFullSubmission = 'CS_DOC_TYPE_TCFULL',
  TCStatusNotif = 'CS_DOC_TYPE_TC_STATUS_NOTIF',
  AnalysisTC = 'CS_DOC_TYPE_TC_ANALYSIS_COMB',
  TC201Cons = 'CS_DOC_TYPE_TC201_CONSOLIDATED',
  TCWeb = 'CS_DOC_TYPE_WEB',
}

enum DocumentTypeOther {
  Covid = 'CS_DOC_TYPE_COVID',
  Income = 'CS_DOC_TYPE_PROFIT_LOSS_STATEMENT',
  ResRentRoll = 'CS_DOC_TYPE_RESIDENTIAL_RENT_ROLL',
  RPIERentRollCommercial = 'CS_DOC_TYPE_COMMERCIAL_RENT_ROLL',
  StarkpatchBio = 'CS_DOC_TYPE_STARK_BIO',
  RFRAnalysis = 'CS_DOC_TYPE_RFR_ANALYSIS',
  ExcelRpie1Year = 'CS_DOC_TYPE_EXCEL_RPIE_1YEAR',
  RPIEConfirmation = 'CS_REPORT_NAME_RPIE_CONF',
  RPIECopy = 'CS_DOC_TYPE_RPIE_COPY',
  RPIEWorksheetCompleted = 'CS_DOC_TYPE_RPIE_WORKSHEET_COMPLETED',
  Innocent = 'CS_DOC_TYPE_RPIE_INNO_PURCHASER_AFFIDAVIT',
  RPIEWorksheetAdult = 'CS_DOC_TYPE_RPIE_ADULT_DAY_CARE',
  RPIEWorksheetStation = 'CS_DOC_TYPE_RPIE_GAS_STATION',
  RPIEWorksheetHotels = 'CS_DOC_TYPE_RPIE_HOTELS',
  RPIEWorksheetSelfStorage = 'CS_DOC_TYPE_RPIE_SELF_STORAGE',
  RPIEWorksheetTheatres = 'CS_DOC_TYPE_RPIE_THEATRES',
  RPIEWorksheetBlank = 'CS_DOC_TYPE_RPIE_BLANK',
  RPIECover = 'CS_DOC_TYPE_COVER_LETTER',
  CommercialRR = 'CS_DOC_TYPE_COMMERCIAL_RENT_ROLL',
  ResidentialRR = 'CS_DOC_TYPE_RESIDENTIAL_RENT_ROLL',
  StorefrontRegistry = 'CS_DOC_TYPE_SF_REG',
  TaxCommission = 'CS_DOC_TYPE_TAX_COM_OFR_NOTIF',
  LeaseCopy = 'CS_DOC_TYPE_LEASE_COPY',
}

enum DocumentStatus {
  Downloaded = 'CS_DOC_STATUS_DOWNLOADED',
  DownloadNow = 'CS_DOC_STATUS_DOWNLOAD_NOW',
  Received = 'CS_DOC_STATUS_RCVD',
  Uploaded = 'CS_DOC_STATUS_UPLD',
}

enum FileSources {
  WEB = 'CS_FILE_SOURCE_WEB',
  SF = 'CS_FILE_SOURCE_SF',
}

enum UseOfSpace {
  Factory = 'CS_BBL_LEASE_USE_SPACE_FACTORY',
  Residential = 'CS_BBL_LEASE_USE_SPACE_RESIDENTIAL',
  Warehouse = 'CS_BBL_LEASE_USE_SPACE_WAREHOUSE',
  Office = 'CS_BBL_LEASE_USE_SPACE_OFFICE',
  Storage = 'CS_BBL_LEASE_USE_SPACE_STORAGE',
  Retail = 'CS_BBL_LEASE_USE_SPACE_RETAIL',
  Parking = 'CS_BBL_LEASE_USE_SPACE_GAR_PARK',
  Loft = 'CS_BBL_LEASE_USE_SPACE_LOFT',
}

enum StorefrontUnitDescriptionType {
  GroundFloorUnitWithStreetEntrance = 'CS_RPIE_STOREFRONT_UNIT_DESCR_G_STREET',
  GroundFloorUnitWithInteriorEntrance = 'CS_RPIE_STOREFRONT_UNIT_DESCR_G_INTERIOR',
  SecondFloorUnitWithStreetEntrance = 'CS_RPIE_STOREFRONT_UNIT_DESCR_S_STREET',
  SecondFloorUnitWithInteriorEntrance = 'CS_RPIE_STOREFRONT_UNIT_DESCR_S_INTERIOR',
}

enum StorefrontUnitOccupancyType {
  Owner = 'CS_RPIE_STOREFRONT_OCCUPANT_TYPE_OWNER',
  Tenant = 'CS_RPIE_STOREFRONT_OCCUPANT_TYPE_TENANT',
  Vacant = 'CS_RPIE_STOREFRONT_OCCUPANT_TYPE_VACANT',
}

enum BusinessActivity {
  Other = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_OTHER',
  AccountingServices = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_AC',
  BroadcastingTelecom = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_BT',
  EducationalServices = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_ES',
  FinanceAndInsurance = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_FI',
  FoodServices = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_FS',
  HealthCareAndSocialAssistance = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_HC',
  InformationServices = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_IS',
  LegalServices = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_LS',
  Manufacturing = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_MF',
  MiscellaneousOtherService = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_MOS',
  MoviesVideoSound = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_MVS',
  Publishing = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_PUB',
  RealEstate = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_RE',
  Retail = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_RT',
  Wholesale = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_WS',
  // Vacant = 'CS_RPIE_STOREFRONT_OCCUPANT_PB_ACTIVITY_VACANT',
}

enum StorefrontLeaseConcessions {
  None = 'CS_RPIE_STOREFRONT_OCCUPANT_LEASE_NA',
  RentFree = 'CS_RPIE_STOREFRONT_OCCUPANT_LEASE_RENT_FREE',
  StartRent = 'CS_RPIE_STOREFRONT_OCCUPANT_LEASE_START_RENT',
  Improve = 'CS_RPIE_STOREFRONT_OCCUPANT_LEASE_IMPROVE',
  Other = 'CS_RPIE_STOREFRONT_OCCUPANT_LEASE_OTHER',
}

enum Utilities {
  Electricity = 'CS_RPIE_RENTROLL_UTILTNT_ELECTRICITY',
  Heat = 'CS_RPIE_RENTROLL_UTILTNT_HEAT',
  None = 'CS_RPIE_RENTROLL_UTILTNT_NONE',
  Water = 'CS_RPIE_RENTROLL_UTILTNT_WATER',
}

enum RPIEExclusions {
  Actual = 'CS_BBL_RPIE_EXCLUSION_ACTUAL_ASSES_40_OR_LESS',
  Residential10 = 'CS_BBL_RPIE_EXCLUSION_EXCL_RESID_10_OR_FEW_APRT',
  Residential6 = 'CS_BBL_RPIE_EXCLUSION_PRIM_RESID_6_OR_FEW_APRT',
  ResidentialCoop = 'CS_BBL_RPIE_EXCLUSION_RESID_COOPERATIVE',
  IndividResidentialCondo = 'CS_BBL_RPIE_EXCLUSION_INDIVID_RESID_CONDO',
  RentedExclusive = 'CS_BBL_RPIE_EXCLUSION_RENTED_EXCLUSIVE',
  OccupiedExclusive = 'CS_BBL_RPIE_EXCLUSION_OCCUPIED_EXCLUSIVE',
  VacantNonIncome = 'CS_BBL_RPIE_EXCLUSION_VACANT_UNINH_NON_INCOME',
  VacantNonIncomeLand = 'CS_BBL_RPIE_EXCLUSION_VACANT_NON_INCOME_LAND',
  OwnerNotOperated = 'CS_BBL_RPIE_EXCLUSION_OWNER_NOT_OPERATED_WO_IE',
  OwnedUsedExclusive = 'CS_BBL_RPIE_EXCLUSION_OWNED_USED_EXCLUSIVE',
}

enum SignatoryTitle {
  Accountant = 'CS_SIGNATORY_TTL_ACCOUNTANT',
  AsstSecretary = 'CS_SIGNATORY_TTL_ASST_SECRETARY',
  AuthorizedSignatory = 'CS_SIGNATORY_TTL_AUTH_SIGNATORY',
  CEO = 'CS_SIGNATORY_TTL_CEO',
  Chairman = 'CS_SIGNATORY_TTL_CHAIRMAN',
  CoOwner = 'CS_SIGNATORY_TTL_CO_OWNER',
  CoOwnerIndividual = 'CS_SIGNATORY_TTL_CO_OWNER_INDIVID',
  Director = 'CS_SIGNATORY_TTL_DIRECTOR',
  GeneralPartner = 'CS_SIGNATORY_TTL_GENERAL_PARTNER',
  Manager = 'CS_SIGNATORY_TTL_MANAGER',
  ManagingAgent = 'CS_SIGNATORY_TTL_MANAGING_AGENT',
  ManagingMember = 'CS_SIGNATORY_TTL_MANAGING_MEMBER',
  Member = 'CS_SIGNATORY_TTL_MEMBER',
  MemberBoardManagers = 'CS_SIGNATORY_TTL_MEMBER_BOARD_MNGR',
  Officer = 'CS_SIGNATORY_TTL_OFFICER',
  OwnerIndividual = 'CS_SIGNATORY_TTL_OWNER_INDIVID',
  Partner = 'CS_SIGNATORY_TTL_PARTNER',
  President = 'CS_SIGNATORY_TTL_PRESIDENT',
  PresidentBoardManagers = 'CS_SIGNATORY_TTL_PRESIDENT_BOARD_MNGR',
  Secretary = 'CS_SIGNATORY_TTL_SECRETARY',
  Treasurer = 'CS_SIGNATORY_TTL_TREASURER',
  Trustee = 'CS_SIGNATORY_TTL_TRUSTEE',
  VicePresident = 'CS_SIGNATORY_TTL_VICE_PRESIDENT',
}

const DictiNames: { [key: string]: string } = {
  [Services.RPIE]: 'RPIE',
  [RPIEWizardSections.General]: 'General',
  [RPIEWizardSections.IncomeExpense]: 'Inc. and Exp.',
  [RPIEWizardSections.Storefront]: 'Storefront',
  [RPIEWizardSections.RentRollResidential]: 'Res. RR',
  [RPIEWizardSections.RentRollCommercial]: 'Com. RR',
  [RPIEWizardSections.ReviewAndSubmit]: 'Submit',
  [RPIEStatuses.DataUpdateRequested]: 'Data Update Requested',
  [RPIEStatuses.Imported]: 'Imported',
  [RPIEStatuses.InProgress]: 'In Progress',
  [RPIEStatuses.NotStarted]: 'Not Started',
  [RPIEStatuses.NoSubmission]: 'No action',
  [RPIEStatuses.RPIEFiled]: 'RPIE Filed',
  [RPIEStatuses.SubmittedPendingDocumentReview]:
    'Submitted - Pending Document Review',
  [RPIEStatuses.Submitted]: 'Submitted',

  [Services.TaxAppeal]: 'Tax Appeal',
  [TAStatuses.New]: 'New',
  [TAStatuses.Submit]: 'Submitted',
  [TAStatuses.TC101Ready]: 'TC101 Ready',
  [TAStatuses.TC101Imported]: 'TC 101 Imported',
  [TAStatuses.InProgress]: 'In progress',
  [TAStatuses.AwaitVerification]: 'Awaiting Verification',
  [TAStatuses.Imported]: 'Imported',
  [TAStatuses.NoSubmission]: 'No action',
  [TAStatuses.SubmitOutOfPortal]: 'Submitted',
  [TAStatuses.SubmitPendingDocs]: 'Submitted',
  [TAWizardSections.GeneralInformation]: 'General Info',
  [TAWizardSections.PropertyDetails]: 'Property Details',
  [TAWizardSections.ReviewAndSubmit]: 'Review & Submit',
  [TAWizardSteps.DescriptionReveiw]: 'Review and Confirm General Information',
  [TAWizardSteps.DescriptionMainInfo]:
    'Review and Confirm General Information',
  [TAWizardSteps.DescriptionAreas]: 'Uses and Floor Area',
  [TAWizardSteps.DescriptionParking]: 'Parking',
  [TAWizardSteps.DescriptionCovid]:
    // 'Provide Details About Vacancy and COVID-19',
    'Provide Details About Vacancy',
  [TAWizardSteps.DescriptionStatus]: 'Provide Property Status Information',
  [TAWizardSteps.DescriptionConstruction]: 'Construction Details',
  [TAWizardSteps.DescriptionConstructionCosts]: 'Construction Costs',
  [TAWizardSteps.DetailsRentRollFillTypeSelection]:
    'Provide Residential Rent Roll Data',
  [TAWizardSteps.DetailsRentRollUpload]: 'Upload Residential Rent Roll',
  [TAWizardSteps.DetailsResidentialMain]: 'Main Residential Information',
  [TAWizardSteps.DetailsResidentialRent]: 'Residential Rent Information',
  [TAWizardSteps.DetailsNonResidentialBasement]:
    'Provide Non-Residential Information ',
  [TAWizardSteps.DetailsNonResidentialFirst]:
    'Provide Non-Residential Information ',
  [TAWizardSteps.DetailsNonResidentialSecond]:
    'Provide Non-Residential Information ',
  [TAWizardSteps.DetailsNonResidentialThird]:
    'Provide Non-Residential Information ',

  [TAWizardSteps.HotelOperation]: 'Hotel Operation Information',
  [TAWizardSteps.HotelRoomRates]: 'Room Rates',
  [TAWizardSteps.HotelNonDepartmentalOp]: 'Non-Departmental Operation',
  [TAWizardSteps.HotelFurnitureFixturesAndEquipment]:
    'Furniture, Fixtures and Equipment',

  [TAWizardSteps.ReportingPeriod]: 'Reporting Period and Accounting Basis',
  [TAWizardSteps.IncomeFillTypeSelection]: 'Provide Income and Expense Data',
  [TAWizardSteps.IncomeUpload]: 'Upload Your P&L',
  [TAWizardSteps.Income]: 'Income',
  [TAWizardSteps.Expense]: 'Provide Expense Information',

  [TAWizardSteps.Summary]: 'Summary Page',
  [TAWizardSteps.Submit]: 'Submit Information for Tax Appeal Application',

  [Relations.Owner]: 'Owner/Title Holder',
  [Relations.NetLessee]: 'Net Lessee',
  [Relations.PartialLessee]: 'Partial Lessee',
  [Relations.BuyerUnderContract]: 'Buyer under Contract',
  [Relations.Mortgage]: 'Mortgagee in Possession',
  [Relations.Trustee]: 'Receiver or Trustee',
  [Relations.CondoBoard]: 'Condo Board',
  [Relations.OwnerInterest]: 'Owner of Divided Interest',
  [Relations.Other]: 'Other',

  [PropertyUsage.NonResidential]: 'Non-residential',
  [PropertyUsage.Residential]: 'Residential',
  [PropertyUsage.Mixed]: 'Mixed',
  [PropertyUsage.Vacant]: 'Vacant',

  [UseByApplicant.Entire]: 'Entire',
  [UseByApplicant.Part]: 'Part',
  [UseByApplicant.No]: 'No',

  [ExpenseSectionTypes.ExpenseMain]: 'Main',
  [ExpenseSectionTypes.ExpenseNonDeductable]: 'Non-deductable',
  [ExpenseSectionTypes.ExpenseOther]: 'Other',

  [ExpenseSectionTypes.HotelDepartmental]: 'Departmental',
  [ExpenseSectionTypes.HotelFinancial]: 'Financial',
  [ExpenseSectionTypes.HotelUndistributed]: 'Undistributed',
  [ExpenseSectionTypes.HotelOther]: 'Other',

  [ExpenseSectionTypes.CondoMain]: 'Main',
  [ExpenseSectionTypes.CondoOther]: 'Other',

  [ExpenseCategories.Fuel]: 'Fuel',
  [ExpenseCategories.Light]: 'Light and Power',
  [ExpenseCategories.Cleaning]: 'Cleaning Contracts',
  [ExpenseCategories.Wages]: 'Wages and Payroll',
  [ExpenseCategories.Repairs]: 'Repairs and Maintenance',
  [ExpenseCategories.Management]: 'Management and Administration',
  [ExpenseCategories.Insurance]: 'Insurance (Annual)',
  [ExpenseCategories.Water]: 'Water and Sewer',
  [ExpenseCategories.Advertising]: 'Advertising',
  [ExpenseCategories.Interior]: 'Interior Painting and Decorating',
  [ExpenseCategories.Leasing]:
    'Amortized Leasing Costs (annualized, pro-rated cost)',
  [ExpenseCategories.Tenant]:
    'Amortized Tenant Improvement Costs (annualized, pro-rated cost)',
  [ExpenseCategories.Taxes]: 'Real Estate Taxes',
  [ExpenseCategories.BadDebt]: 'Bad Debt',
  [ExpenseCategories.Depreciation]: 'Depreciation',
  [ExpenseCategories.Mortgage]: 'Mortgage Interest',

  //HOTEL
  [ExpenseCategories.FoodAndBeverage]: 'Food and beverage',
  [ExpenseCategories.OtherDepartments]: 'Other departments',
  [ExpenseCategories.Rooms]: 'Rooms',
  [ExpenseCategories.Telephone]: 'Telephone',
  [ExpenseCategories.AudioVisual]: 'Audio visual',
  [ExpenseCategories.PublicRoom]: 'Public room, conferences, exhibits',
  [ExpenseCategories.RealEstateRentExpense]: 'Real estate rent expense',
  [ExpenseCategories.RealEstateTaxes]: 'Real estate taxes',
  [ExpenseCategories.Utilities]: 'Utilities',
  [ExpenseCategories.AdministrativeAndGeneral]: 'Administrative and general',
  [ExpenseCategories.Energy]: 'Energy',
  [ExpenseCategories.FranchiseFee]: 'Franchise fee',
  [ExpenseCategories.HotelInsurance]: 'Insurance',
  [ExpenseCategories.ManagementFee]: 'Management fee',
  [ExpenseCategories.Marketing]: 'Marketing',
  [ExpenseCategories.OtherOperatingExpenses]: 'Other operating expenses',
  [ExpenseCategories.OperationsAndMaintenance]: 'Operations and maintenance',

  //CONDO
  [ExpenseCategories.Electricity]: 'Electricity',
  [ExpenseCategories.CondoFuel]: 'Fuel',
  [ExpenseCategories.CondoInsurance]: 'Insurance',
  [ExpenseCategories.LeasingCommissions]: 'Leasing commissions',
  [ExpenseCategories.CondoManagement]: 'Management',
  [ExpenseCategories.OtherExpenses]: 'Other expenses',
  [ExpenseCategories.RepairsAndMaintenance]: 'Repairs & maintenance',
  [ExpenseCategories.WagesAndPayrollCosts]: 'Wages & payroll costs',
  [ExpenseCategories.WaterAndSewer]: 'Water & sewer',
  [ExpenseCategories.LandOrBuildingRent]: 'Land or building rent',

  [IncomeSectionTypes.IncomeMain]: 'Main',
  [IncomeSectionTypes.IncomeAdditional]: 'Additional',
  [IncomeSectionTypes.IncomeOther]: 'Other',

  [IncomeSectionTypes.HotelMain]: 'Main',
  [IncomeSectionTypes.HotelRental]: 'Rental',
  [IncomeSectionTypes.HotelOther]: 'Other',

  [IncomeSectionTypes.CondoMain]: 'Main',
  [IncomeSectionTypes.CondoRental]: 'Rental',
  [IncomeSectionTypes.CondoOther]: 'Other',

  [IncomeCategories.Office]: 'Office',
  [IncomeCategories.ResRegulated]: 'Residential (Regulated)',
  [IncomeCategories.ResUnregulated]: 'Residential (Unregulated)',
  [IncomeCategories.Retail]: 'Retail',
  [IncomeCategories.Loft]: 'Loft',
  [IncomeCategories.Factory]: 'Factory',
  [IncomeCategories.Warehouse]: 'Warehouse',
  [IncomeCategories.Storage]: 'Storage',
  [IncomeCategories.Garages]: 'Garages',
  [IncomeCategories.Parking]: 'Parking',
  [IncomeCategories.Owner]: 'Owner-Occupied or Owner-Related Space',
  // Additional
  [IncomeCategories.OperatingEscalation]: 'Operating Escalation',
  [IncomeCategories.TaxEscalation]: 'Real Estate Tax Escalation',
  [IncomeCategories.SaleUtility]: 'Sale of Utility Services',
  [IncomeCategories.SaleOther]: 'Sale of Other Services',
  [IncomeCategories.GovernmentRent]: 'Government Rent Subsidies',
  [IncomeCategories.Signage]: 'Signage/Billboard',
  [IncomeCategories.CellTower]: 'Cell Towers',
  // [IncomeCategories.LateFees]: 'Late Fees',
  // [IncomeCategories.TenantCharges]: 'Tenant Charges',

  //Hotel
  [IncomeCategories.Rooms]: 'Rooms',
  [IncomeCategories.Food]: 'Food and beverage',
  [IncomeCategories.Telephone]: 'Telephone',
  [IncomeCategories.PublicRoom]: 'Public Room Rental',
  [IncomeCategories.MainParking]: 'Parking',
  [IncomeCategories.AudioVisual]: 'Audio visual',
  [IncomeCategories.HotelOther]: 'Other departments',

  [IncomeCategories.RentalApartments]: 'Apartments',
  [IncomeCategories.RentalRetail]: 'Retail',
  [IncomeCategories.RentalRestaurant]: 'Restaurant',
  [IncomeCategories.RentalOffice]: 'Office',
  [IncomeCategories.RentalParking]: 'Parking Garage',
  [IncomeCategories.RentalTelecom]: 'Cell/Telecom Equip.',
  [IncomeCategories.RentalOther]: 'Other',
  [IncomeCategories.RentalSignage]: 'Signage',

  //Condo
  [IncomeCategories.MaintenanceCommonCharges]: 'Maintenance/Common charges',

  [IncomeCategories.Residental]: 'Residental',
  [IncomeCategories.CondoRetail]: 'Retail',
  [IncomeCategories.Offices]: 'Offices',
  [IncomeCategories.CondoOther]: 'Other',
  [IncomeCategories.Signate]: 'Signage/Billboard',
  [IncomeCategories.Tower]: 'Cell tower/Tel. equip.',
  [IncomeCategories.Garage]: 'Garage',
  [IncomeCategories.Laundry]: 'Laundry',

  [FloorUsesType.AccessoryCommStorage]: 'Accessory Comm. Storage',
  [FloorUsesType.AdultDayCare]: 'Adult Day Care',
  [FloorUsesType.AmusementCenter]: 'Amusement Center',
  [FloorUsesType.ArtGallery]: 'Art Gallery',
  [FloorUsesType.ArtistStudio]: 'Artist Studio',
  [FloorUsesType.AutoDealership]: 'Auto Dealership',
  [FloorUsesType.AutoRepair]: 'Auto Repair',
  [FloorUsesType.BoilerRoom]: 'Boiler Room',
  [FloorUsesType.CarWash]: 'Car Wash',
  [FloorUsesType.Church]: 'Church',
  [FloorUsesType.CommunityCenter]: 'Community Center',
  [FloorUsesType.ConcertHall]: 'Concert Hall',
  [FloorUsesType.CulturalFacility]: 'Cultural Facility',
  [FloorUsesType.DayCareCenter]: 'Day Care Center',
  [FloorUsesType.Dormitory]: 'Dormitory',
  [FloorUsesType.EducationalFacility]: 'Educational Facility',
  [FloorUsesType.Factory]: 'Factory',
  [FloorUsesType.FuneralHome]: 'Funeral Home',
  [FloorUsesType.Garage]: 'Garage',
  [FloorUsesType.GarbageCollectionArea]: 'Garbage collection area',
  [FloorUsesType.GasStation]: 'Gas Station',
  [FloorUsesType.Gym]: 'Gym',
  [FloorUsesType.Hospital]: 'Hospital/ health facility',
  [FloorUsesType.Hotel]: 'Hotel / Motel',
  [FloorUsesType.Laundromat]: 'Laundromat',
  [FloorUsesType.Loft]: 'Loft',
  [FloorUsesType.MedicalFacility]: 'Medical/Ambulatory Facility',
  [FloorUsesType.MeterRoom]: 'Meter room',
  [FloorUsesType.Museum]: 'Museum',
  [FloorUsesType.OfficeUses]: 'Office Uses',
  [FloorUsesType.OrdinaryUse]: 'Ordinary Use',
  [FloorUsesType.Other]: 'Other',
  [FloorUsesType.OutdoorUses]: 'Outdoor uses',
  [FloorUsesType.RecordingStudio]: 'Recording Studio',
  [FloorUsesType.RetailUses]: 'Retail Uses',
  [FloorUsesType.School]: 'School',
  [FloorUsesType.Shelter]: 'Shelter',
  [FloorUsesType.Storage]: 'Storage',
  [FloorUsesType.Theater]: 'Theater',
  [FloorUsesType.UnderConstruction]: 'Under construction',
  [FloorUsesType.Vacant]: 'Vacant/Uninhabitable',
  [FloorUsesType.Veterinary]: 'Veterinary',
  [FloorUsesType.Warehouse]: 'Warehouse',
  [FloorUsesType.ResidApartments]: 'Resid. Apartments',
  [FloorUsesType.ResidLobby]: 'Resid. Lobby',
  [FloorUsesType.CommLobby]: 'Comm. Lobby',
  [FloorUsesType.ResidRecreationalArea]: 'Resid. Recreational Area',
  [FloorUsesType.Parking]: 'Parking',
  [FloorUsesType.ResidentialParking]: 'Residential Parking',
  [FloorUsesType.AccessoryParking]: 'Accessory Parking',
  [FloorUsesType.AccessoryResidStorage]: 'Accessory Resid. Storage',
  [FloorUsesType.Office]: 'Office',
  [FloorUsesType.ProfessionalOffice]: 'Professional Office',
  [FloorUsesType.MedicalOffice]: 'Medical Office',
  [FloorUsesType.Backyard]: 'Backyard',
  [FloorUsesType.OtherOut]: 'OtherOut',
  [FloorUsesType.ParkPlayground]: 'Park / Playground',
  [FloorUsesType.CellTower]: 'Cell tower',
  [FloorUsesType.Courtyard]: 'Courtyard',
  [FloorUsesType.Generators]: 'Generators',
  [FloorUsesType.Signage]: 'Signage',
  [FloorUsesType.TelecomEquipment]: 'Telecom equipment',
  [FloorUsesType.Retail]: 'Retail',
  [FloorUsesType.Bank]: 'Bank',
  [FloorUsesType.Cafeteria]: 'Cafeteria',
  [FloorUsesType.Lounge]: 'Lounge',
  [FloorUsesType.Restaurant]: 'Restaurant',
  [FloorUsesType.Spa]: 'Spa',
  [FloorUsesType.Bar]: 'Bar',
  [FloorUsesType.FastFoodFranchise]: 'Fast Food Franchise',
  [FloorUsesType.NightClub]: 'Night Club',
  [FloorUsesType.RoofComm]: 'Roof Comm.',
  [FloorUsesType.RoofRes]: 'Roof Res.',

  [PortionRentFromAnyTenants.Residential]: 'Residential only',
  [PortionRentFromAnyTenants.NonResidential]: 'Non-residential only',
  [PortionRentFromAnyTenants.Both]: 'Both',
  [PortionUsedByApplicant.Residential]: 'Residential only',
  [PortionUsedByApplicant.NonResidential]: 'Non-residential only',
  [PortionUsedByApplicant.Both]: 'Both',

  [HotelRoomTypes.King]: 'King',
  [HotelRoomTypes.Double]: 'Double',
  [HotelRoomTypes.Single]: 'Single',
  [HotelRoomTypes.Queen]: 'Queen',
  [HotelRoomTypes.Suite]: 'Suite',
  [HotelRoomTypes.Penthouse]: 'Penthouse',

  [HotelFurnitureCosts.ContributionToReserve]: 'Contribution to reserve',
  [HotelFurnitureCosts.BookAll]:
    'Book cost of all Furniture, Fixtures, and Equipment at year end',
  [HotelFurnitureCosts.BookWoAccum]: 'Book cost minus accum. depreciation',

  //Events
  [EventType.Aggregated]: 'Aggregated events',
  [EventType.NonAggregated]: 'Non-aggregated events',
  [Aggregated.LastAccess]: 'Last access',

  [BuildingClasses.A0]: 'CAPE COD',
  [BuildingClasses.A1]: 'TWO STORIES - DETACHED SM OR MID',
  [BuildingClasses.A2]: 'ONE STORY - PERMANENT LIVING QUARTER',
  [BuildingClasses.A3]: 'LARGE SUBURBAN RESIDENCE',
  [BuildingClasses.A4]: 'CITY RESIDENCE ONE FAMILY',
  [BuildingClasses.A5]: 'ONE FAMILY ATTACHED OR SEMI-DETACHED',
  [BuildingClasses.A6]: 'SUMMER COTTAGE',
  [BuildingClasses.A7]: 'MANSION TYPE OR TOWN HOUSE',
  [BuildingClasses.A8]: 'BUNGALOW COLONY - COOPERATIVELY OWNED LAND',
  [BuildingClasses.A9]: 'MISCELLANEOUS ONE FAMILY',
  [BuildingClasses.B1]: 'TWO FAMILY BRICK',
  [BuildingClasses.B2]: 'TWO FAMILY FRAME',
  [BuildingClasses.B3]: 'TWO FAMILY CONVERTED FROM ONE FAMILY',
  [BuildingClasses.B9]: 'MISCELLANEOUS TWO FAMILY',
  [BuildingClasses.C0]: 'THREE FAMILIES',
  [BuildingClasses.C1]: 'OVER SIX FAMILIES WITHOUT STORES',
  [BuildingClasses.C2]: 'FIVE TO SIX FAMILIES',
  [BuildingClasses.C3]: 'FOUR FAMILIES',
  [BuildingClasses.C4]: 'OLD LAW TENEMENT',
  [BuildingClasses.C5]: 'CONVERTED DWELLINGS OR ROOMING HOUSE',
  [BuildingClasses.C6]: 'WALK-UP COOPERATIVE',
  [BuildingClasses.C7]: 'WALK-UP APT. OVER SIX FAMILIES WITH STORES',
  [BuildingClasses.C8]: 'WALK-UP CO-OP; CONVERSION FROM LOFT/WAREHOUSE',
  [BuildingClasses.C9]: 'GARDEN APARTMENTS',
  [BuildingClasses.CM]: 'MOBILE HOMES/TRAILER PARKS',
  [BuildingClasses.D0]: 'ELEVATOR CO-OP; CONVERSION FROM LOFT/WAREHOUSE',
  [BuildingClasses.D1]: 'ELEVATOR APT; SEMI-FIREPROOF WITHOUT STORES',
  [BuildingClasses.D2]: 'ELEVATOR APT; ARTISTS IN RESIDENCE',
  [BuildingClasses.D3]: 'ELEVATOR APT; FIREPROOF WITHOUT STORES',
  [BuildingClasses.D4]: 'ELEVATOR COOPERATIVE',
  [BuildingClasses.D5]: 'ELEVATOR APT; CONVERTED',
  [BuildingClasses.D6]: 'ELEVATOR APT; FIREPROOF WITH STORES',
  [BuildingClasses.D7]: 'ELEVATOR APT; SEMI-FIREPROOF WITH STORES',
  [BuildingClasses.D8]: 'ELEVATOR APT; LUXURY TYPE',
  [BuildingClasses.D9]: 'ELEVATOR APT; MISCELLANEOUS',
  [BuildingClasses.E1]: 'FIREPROOF WAREHOUSE',
  [BuildingClasses.E2]: 'CONTRACTORS WAREHOUSE',
  [BuildingClasses.E3]: 'SEMI-FIREPROOF WAREHOUSE',
  [BuildingClasses.E4]: 'METAL FRAME WAREHOUSE',
  [BuildingClasses.E7]: 'SELF-STORAGE WAREHOUSES',
  [BuildingClasses.E9]: 'MISCELLANEOUS WAREHOUSE',
  [BuildingClasses.F1]: 'FACTORY; HEAVY MANUFACTURING - FIREPROOF',
  [BuildingClasses.F2]: 'FACTORY; SPECIAL CONSTRUCTION - FIREPROOF',
  [BuildingClasses.F4]: 'FACTORY; INDUSTRIAL SEMI-FIREPROOF',
  [BuildingClasses.F5]: 'FACTORY; LIGHT MANUFACTURING',
  [BuildingClasses.F8]: 'FACTORY; TANK FARM',
  [BuildingClasses.F9]: 'FACTORY; INDUSTRIAL-MISCELLANEOUS',
  [BuildingClasses.G0]: 'GARAGE; RESIDENTIAL TAX CLASS 1',
  [BuildingClasses.G1]: 'ALL PARKING GARAGES',
  [BuildingClasses.G2]: 'AUTO BODY/COLLISION OR AUTO REPAIR',
  [BuildingClasses.G3]: 'GAS STATION WITH RETAIL STORE',
  [BuildingClasses.G4]: 'GAS STATION WITH SERVICE/AUTO REPAIR',
  [BuildingClasses.G5]: 'GAS STATION ONLY WITH/WITHOUT SMALL KIOSK',
  [BuildingClasses.G6]: 'LICENSED PARKING LOT',
  [BuildingClasses.G7]: 'UNLICENSED PARKING LOT',
  [BuildingClasses.G8]: 'CAR SALES/RENTAL WITH SHOWROOM',
  [BuildingClasses.G9]: 'MISCELLANEOUS GARAGE',
  [BuildingClasses.GU]: 'CAR SALES OR RENTAL LOTS WITHOUT SHOWROOM',
  [BuildingClasses.GW]: 'CAR WASH OR LUBRITORIUM FACILITY',
  [BuildingClasses.HB]:
    'BOUTIQUE: 10-100 ROOMS, W/LUXURY FACILITIES, THEMED, STYLISH, W/FULL SVC ACCOMMODATIONS',
  [BuildingClasses.HH]:
    'HOSTELS- BED RENTALS IN DORMITORY-LIKE SETTINGS W/SHARED ROOMS & BATHROOMS',
  [BuildingClasses.HR]:
    'SRO- 1 OR 2 PEOPLE HOUSED IN INDIVIDUAL ROOMS IN MULTIPLE DWELLING AFFORDABLE HOUSING',
  [BuildingClasses.HS]:
    'EXTENDED STAY/SUITE: AMENITIES SIMILAR TO APT; TYPICALLY CHARGE WEEKLY RATES & LESS EXPENSIVE THAN FULL-SERVICE HOTEL',
  [BuildingClasses.H1]: 'LUXURY HOTEL',
  [BuildingClasses.H2]: 'FULL SERVICE HOTEL',
  [BuildingClasses.H3]:
    'LIMITED SERVICE; MANY AFFILIATED WITH NATIONAL CHAIN',
  [BuildingClasses.H4]: 'MOTEL',
  [BuildingClasses.H5]: 'HOTEL; PRIVATE CLUB, LUXURY TYPE',
  [BuildingClasses.H6]: 'APARTMENT HOTEL',
  [BuildingClasses.H7]: 'APARTMENT HOTEL - COOPERATIVELY OWNED',
  [BuildingClasses.H8]: 'DORMITORY',
  [BuildingClasses.H9]: 'MISCELLANEOUS HOTEL',
  [BuildingClasses.I1]: 'HOSPITAL, SANITARIUM, MENTAL INSTITUTION',
  [BuildingClasses.I2]: 'INFIRMARY',
  [BuildingClasses.I3]: 'DISPENSARY',
  [BuildingClasses.I4]: 'HOSPITAL; STAFF FACILITY',
  [BuildingClasses.I5]: 'HEALTH CENTER, CHILD CENTER, CLINIC',
  [BuildingClasses.I6]: 'NURSING HOME',
  [BuildingClasses.I7]: 'ADULT CARE FACILITY',
  [BuildingClasses.I9]: 'MISCELLANEOUS HOSPITAL, HEALTH CARE FACILITY',
  [BuildingClasses.J1]: 'THEATRE; ART TYPE LESS THAN 400 SEATS',
  [BuildingClasses.J2]: 'THEATRE; ART TYPE MORE THAN 400 SEATS',
  [BuildingClasses.J3]: 'MOTION PICTURE THEATRE WITH BALCONY',
  [BuildingClasses.J4]: 'LEGITIMATE THEATRE, SOLE USE',
  [BuildingClasses.J5]: 'THEATRE IN MIXED-USE BUILDING',
  [BuildingClasses.J6]: 'TELEVISION STUDIO',
  [BuildingClasses.J7]: 'OFF BROADWAY TYPE THEATRE',
  [BuildingClasses.J8]: 'MULTIPLEX PICTURE THEATRE',
  [BuildingClasses.J9]: 'MISCELLANEOUS THEATRE',
  [BuildingClasses.K1]: 'ONE STORY RETAIL BUILDING',
  [BuildingClasses.K2]: 'MULTI-STORY RETAIL BUILDING (2 OR MORE)',
  [BuildingClasses.K3]: 'MULTI-STORY DEPARTMENT STORE',
  [BuildingClasses.K4]: 'PREDOMINANT RETAIL WITH OTHER USES',
  [BuildingClasses.K5]: 'STAND-ALONE FOOD ESTABLISHMENT',
  [BuildingClasses.K6]: 'SHOPPING CENTER WITH OR WITHOUT PARKING',
  [BuildingClasses.K7]: 'BANKING FACILITIES WITH OR WITHOUT PARKING',
  [BuildingClasses.K8]:
    "BIG BOX RETAIL: NOT AFFIXED & STANDING ON OWN LOT W/PARKING, E.G. COSTCO & BJ'S",
  [BuildingClasses.K9]: 'MISCELLANEOUS STORE BUILDING',
  [BuildingClasses.L1]: 'LOFT; OVER 8 STORIES (MID MANH. TYPE)',
  [BuildingClasses.L2]: 'LOFT; FIREPROOF AND STORAGE TYPE WITHOUT STORES',
  [BuildingClasses.L3]: 'LOFT; SEMI-FIREPROOF',
  [BuildingClasses.L8]: 'LOFT; WITH RETAIL STORES OTHER THAN TYPE ONE',
  [BuildingClasses.L9]: 'MISCELLANEOUS LOFT',
  [BuildingClasses.M1]: 'CHURCH, SYNAGOGUE, CHAPEL',
  [BuildingClasses.M2]: 'MISSION HOUSE (NON-RESIDENTIAL)',
  [BuildingClasses.M3]: 'PARSONAGE, RECTORY',
  [BuildingClasses.M4]: 'CONVENT',
  [BuildingClasses.M9]: 'MISCELLANEOUS RELIGIOUS FACILITY',
  [BuildingClasses.N1]: 'ASYLUM',
  [BuildingClasses.N2]: 'HOME FOR INDIGENT CHILDREN, AGED, HOMELESS',
  [BuildingClasses.N3]: 'ORPHANAGE',
  [BuildingClasses.N4]: 'DETENTION HOUSE FOR WAYWARD GIRLS',
  [BuildingClasses.N9]: 'MISCELLANEOUS ASYLUM, HOME',
  [BuildingClasses.O1]: 'OFFICE ONLY - 1 STORY',
  [BuildingClasses.O2]: 'OFFICE ONLY 2 - 6 STORIES',
  [BuildingClasses.O3]: 'OFFICE ONLY 7 - 19 STORIES',
  [BuildingClasses.O4]:
    'OFFICE ONLY WITH OR WITHOUT COMM - 20 STORIES OR MORE',
  [BuildingClasses.O5]: 'OFFICE WITH COMM - 1 TO 6 STORIES',
  [BuildingClasses.O6]: 'OFFICE WITH COMM 7 - 19 STORIES',
  [BuildingClasses.O7]: 'PROFESSIONAL BUILDINGS/STAND ALONE FUNERAL HOMES',
  [BuildingClasses.O8]: 'OFFICE WITH APARTMENTS ONLY (NO COMM)',
  [BuildingClasses.O9]: 'MISCELLANEOUS AND OLD STYLE BANK BLDGS.',
  [BuildingClasses.P1]: 'CONCERT HALL',
  [BuildingClasses.P2]: 'LODGE ROOM',
  [BuildingClasses.P3]: 'YWCA, YMCA, YWHA, YMHA, PAL',
  [BuildingClasses.P4]: 'BEACH CLUB',
  [BuildingClasses.P5]: 'COMMUNITY CENTER',
  [BuildingClasses.P6]: 'AMUSEMENT PLACE, BATH HOUSE, BOAT HOUSE',
  [BuildingClasses.P7]: 'MUSEUM',
  [BuildingClasses.P8]: 'LIBRARY',
  [BuildingClasses.P9]: 'MISCELLANEOUS INDOOR PUBLIC ASSEMBLY',
  [BuildingClasses.Q1]: 'PARKS/RECREATION FACILTY',
  [BuildingClasses.Q2]: 'PLAYGROUND',
  [BuildingClasses.Q3]: 'OUTDOOR POOL',
  [BuildingClasses.Q4]: 'BEACH',
  [BuildingClasses.Q5]: 'GOLF COURSE',
  [BuildingClasses.Q6]: 'STADIUM, RACE TRACK, BASEBALL FIELD',
  [BuildingClasses.Q7]: 'TENNIS COURT',
  [BuildingClasses.Q8]: 'MARINA, YACHT CLUB',
  [BuildingClasses.Q9]: 'MISCELLANEOUS OUTDOOR RECREATIONAL FACILITY',
  [BuildingClasses.RA]: 'CULTURAL, MEDICAL, EDUCATIONAL, ETC.',
  [BuildingClasses.RB]: 'OFFICE SPACE',
  [BuildingClasses.RG]: 'INDOOR PARKING',
  [BuildingClasses.RH]: 'HOTEL/BOATEL',
  [BuildingClasses.RK]: 'RETAIL SPACE',
  [BuildingClasses.RP]: 'OUTDOOR PARKING',
  [BuildingClasses.RR]: 'CONDOMINIUM RENTALS',
  [BuildingClasses.RS]: 'NON-BUSINESS STORAGE SPACE',
  [BuildingClasses.RT]: 'TERRACES/GARDENS/CABANAS',
  [BuildingClasses.RW]: 'WAREHOUSE/FACTORY/INDUSTRIAL',
  [BuildingClasses.R0]: 'SPECIAL CONDOMINIUM BILLING LOT',
  [BuildingClasses.R1]: 'CONDO; RESIDENTIAL UNIT IN 2-10 UNIT BLDG.',
  [BuildingClasses.R2]: 'CONDO; RESIDENTIAL UNIT IN WALK-UP BLDG.',
  [BuildingClasses.R3]: 'CONDO; RESIDENTIAL UNIT IN 1-3 STORY BLDG.',
  [BuildingClasses.R4]: 'CONDO; RESIDENTIAL UNIT IN ELEVATOR BLDG.',
  [BuildingClasses.R5]: 'MISCELLANEOUS COMMERCIAL',
  [BuildingClasses.R6]: 'CONDO; RESID.UNIT OF 1-3 UNIT BLDG-ORIG CLASS 1',
  [BuildingClasses.R7]: 'CONDO; COMML.UNIT OF 1-3 UNIT BLDG-ORIG CLASS 1',
  [BuildingClasses.R8]: 'CONDO; COMML.UNIT OF 2-10 UNIT BLDG.',
  [BuildingClasses.R9]: 'CO-OP WITHIN A CONDOMINIUM',
  [BuildingClasses.S0]: 'PRIMARILY 1 FAMILY WITH 2 STORES OR OFFICES',
  [BuildingClasses.S1]: 'PRIMARILY 1 FAMILY WITH 1 STORE OR OFFICE',
  [BuildingClasses.S2]: 'PRIMARILY 2 FAMILY WITH 1 STORE OR OFFICE',
  [BuildingClasses.S3]: 'PRIMARILY 3 FAMILY WITH 1 STORE OR OFFICE',
  [BuildingClasses.S4]: 'PRIMARILY 4 FAMILY WITH 1 STORE OROFFICE',
  [BuildingClasses.S5]: 'PRIMARILY 5-6 FAMILY WITH 1 STORE OR OFFICE',
  [BuildingClasses.S9]: 'SINGLE OR MULTIPLE DWELLING WITH STORES OR OFFICES',
  [BuildingClasses.T1]: 'AIRPORT, AIRFIELD, TERMINAL',
  [BuildingClasses.T2]: 'PIER, DOCK, BULKHEAD',
  [BuildingClasses.T9]: 'MISCELLANEOUS TRANSPORTATION FACILITY',
  [BuildingClasses.U0]: 'UTILITY COMPANY LAND AND BUILDING',
  [BuildingClasses.U1]: 'BRIDGE, TUNNEL, HIGHWAY',
  [BuildingClasses.U2]: 'GAS OR ELECTRIC UTILITY',
  [BuildingClasses.U3]: 'CEILING RAILROAD',
  [BuildingClasses.U4]: 'TELEPHONE UTILITY',
  [BuildingClasses.U5]: 'COMMUNICATION FACILITY OTHER THAN TELEPHONE',
  [BuildingClasses.U6]: 'RAILROAD - PRIVATE OWNERSHIP',
  [BuildingClasses.U7]: 'TRANSPORTATION - PUBLIC OWNERSHIP',
  [BuildingClasses.U8]: 'REVOCABLE CONSENT',
  [BuildingClasses.U9]: 'MISCELLANEOUS UTILITY PROPERTY',
  [BuildingClasses.V0]: 'ZONED RESIDENTIAL; NOT MANHATTAN',
  [BuildingClasses.V1]: 'ZONED COMMERCIAL OR MANHATTAN RESIDENTIAL',
  [BuildingClasses.V2]:
    'ZONED COMMERCIAL ADJACENT TO CLASS 1 DWELLING: NOT MANHATTAN',
  [BuildingClasses.V3]: 'ZONED PRIMARILY RESIDENTIAL; NOT MANHATTAN',
  [BuildingClasses.V4]: 'POLICE OR FIRE DEPARTMENT',
  [BuildingClasses.V5]: 'SCHOOL SITE OR YARD',
  [BuildingClasses.V6]: 'LIBRARY, HOSPITAL OR MUSEUM',
  [BuildingClasses.V7]: 'PORT AUTHORITY OF NEW YORK AND NEW JERSEY',
  [BuildingClasses.V8]: 'NEW YORK STATE OR US GOVERNMENT',
  [BuildingClasses.V9]: 'MISCELLANEOUS VACANT LAND',
  [BuildingClasses.W1]: 'PUBLIC ELEMENTARY, JUNIOR OR SENIOR HIGH',
  [BuildingClasses.W2]: 'PAROCHIAL SCHOOL, YESHIVA',
  [BuildingClasses.W3]: 'SCHOOL OR ACADEMY',
  [BuildingClasses.W4]: 'TRAINING SCHOOL',
  [BuildingClasses.W5]: 'CITY UNIVERSITY',
  [BuildingClasses.W6]: 'OTHER COLLEGE AND UNIVERSITY',
  [BuildingClasses.W7]: 'THEOLOGICAL SEMINARY',
  [BuildingClasses.W8]: 'OTHER PRIVATE SCHOOL',
  [BuildingClasses.W9]: 'MISCELLANEOUS EDUCATIONAL FACILITY',
  [BuildingClasses.Y1]: 'FIRE DEPARTMENT',
  [BuildingClasses.Y2]: 'POLICE DEPARTMENT',
  [BuildingClasses.Y3]: 'PRISON, JAIL, HOUSE OF DETENTION',
  [BuildingClasses.Y4]: 'MILITARY AND NAVAL INSTALLATION',
  [BuildingClasses.Y5]: 'DEPARTMENT OF REAL ESTATE',
  [BuildingClasses.Y6]: 'DEPARTMENT OF SANITATION',
  [BuildingClasses.Y7]: 'DEPARTMENT OF PORTS AND TERMINALS',
  [BuildingClasses.Y8]: 'DEPARTMENT OF PUBLIC WORKS',
  [BuildingClasses.Y9]: 'DEPARTMENT OF ENVIRONMENTAL PROTECTION',
  [BuildingClasses.Z0]: 'TENNIS COURT, POOL, SHED, ETC.',
  [BuildingClasses.Z1]: 'COURT HOUSE',
  [BuildingClasses.Z2]: 'PUBLIC PARKING AREA',
  [BuildingClasses.Z3]: 'POST OFFICE',
  [BuildingClasses.Z4]: 'FOREIGN GOVERNMENT',
  [BuildingClasses.Z5]: 'UNITED NATIONS',
  [BuildingClasses.Z7]: 'EASEMENT',
  [BuildingClasses.Z8]: 'CEMETERY',
  [BuildingClasses.Z9]: 'OTHER MISCELLANEOUS',
  [FileStatus.Uploaded]: 'Uploaded',
  [DocumentStatus.Downloaded]: 'Downloaded',
  [DocumentStatus.DownloadNow]: 'Download Now',
  [DocumentStatus.Received]: 'Received',
  [DocumentStatus.Uploaded]: 'Uploaded',
  [DocumentType.Covid]: 'Covid',
  [DocumentType.ResRentRoll]: 'Residential Rent Roll',
  [DocumentType.RPIERentRollCommercial]: 'Commercial Rent Roll',
  [DocumentType.Income]: 'Income / Expense',
  [DocumentType.StarkpatchBio]: 'Starkpatch BIO',
  [DocumentType.RFRAnalysis]: 'RFR Analysis',
  [DocumentType.TC159]: 'TC 159',
  [DocumentType.TC200]: 'TC 200',
  [DocumentType.TC101]: 'TC 101',
  [DocumentType.TC201]: 'TC 201',
  [DocumentType.TC203]: 'TC 203',
  [DocumentType.TC309]: 'TC 309',
  [DocumentType.TC108]: 'TC 108',
  [DocumentType.TC109]: 'TC 109',
  [DocumentType.TC140]: 'TC 140',
  [DocumentType.TC150]: 'TC 150',
  [DocumentType.TC208]: 'TC 208',
  [DocumentType.TC214]: 'TC 214',
  [DocumentType.TC230]: 'TC 230',
  [DocumentType.TC106]: 'TC 106',
  [DocumentType.TC10]: 'TC 10',
  [DocumentType.TC10New]: 'TC 10 (NEW)',
  [DocumentType.TC166]: 'TC 166',
  [DocumentType.TC70]: 'TC 70',
  [DocumentType.TC155]: 'TC 155',
  [DocumentType.TC301]: 'TC 301',
  [DocumentType.TC80]: 'TC 80',
  [DocumentType.TCStatusNotif]: 'TC Status Notification',
  [DocumentType.AnalysisTC]: 'Analysis TC Combined',
  [DocumentType.ExcelRpie1Year]: 'EXCEL RPIE 1 year',
  [DocumentType.RPIEConfirmation]: 'RPIE Confirmation',
  [DocumentType.RPIECopy]: 'RPIE Copy',
  [DocumentType.RPIEWorksheetCompleted]: 'RPIE Worksheet Completed',
  [DocumentType.Innocent]: 'Innocent Purchaser Affidavit',
  [DocumentType.RPIEWorksheetAdult]:
    'RPIE Worksheet - Adult Day Care_Nursing Home',
  [DocumentType.RPIEWorksheetStation]:
    'RPIE Worksheet - Gas Station, Car Wash, Oil Change',
  [DocumentType.RPIEWorksheetHotels]: 'RPIE Worksheet - Hotels',
  [DocumentType.RPIEWorksheetSelfStorage]:
    'RPIE Worksheet - Self-storage Facility',
  [DocumentType.RPIEWorksheetTheatres]: 'RPIE Worksheet - Theatres',
  [DocumentType.RPIEWorksheetBlank]: 'RPIE Worksheet - Blank',
  [DocumentType.RPIECover]: 'RPIE Cover Letter',
  [DocumentType.CommercialRR]: 'Commercial Rent-Roll',
  [DocumentType.ResidentialRR]: 'Residential Rent-Roll',
  [DocumentType.StorefrontRegistry]: 'Storefront Registry',
  [DocumentType.TaxCommission]: 'Tax Commission Offer Notification',
  [ReportingPeriodType.Calendar]: 'Calendar',
  [ReportingPeriodType.Fiscal]: 'Fiscal',
  [ReportingPeriodType.Partial]: 'Partial',
  [AccountingBasis.Accrual]: 'Accrual',
  [AccountingBasis.Cash]: 'Cash',

  [UseOfSpace.Factory]: 'Factory',
  [UseOfSpace.Residential]: 'Residential',
  [UseOfSpace.Warehouse]: 'Warehouse',
  [UseOfSpace.Office]: 'Office',
  [UseOfSpace.Storage]: 'Storage',
  [UseOfSpace.Retail]: 'Retail',
  [UseOfSpace.Parking]: 'Garage/Parking',
  [UseOfSpace.Loft]: 'Loft',

  [StorefrontUnitDescriptionType.GroundFloorUnitWithStreetEntrance]:
    'Ground-floor unit with street entrance',
  [StorefrontUnitDescriptionType.GroundFloorUnitWithInteriorEntrance]:
    'Ground-floor unit with interior entrance',
  [StorefrontUnitDescriptionType.SecondFloorUnitWithStreetEntrance]:
    'Second-floor unit with street entrance',
  [StorefrontUnitDescriptionType.SecondFloorUnitWithInteriorEntrance]:
    'Second-floor unit with interior entrance',

  [StorefrontUnitOccupancyType.Owner]: 'Owner',
  [StorefrontUnitOccupancyType.Tenant]: 'Tenant',
  [StorefrontUnitOccupancyType.Vacant]: 'Vacant',

  [BusinessActivity.Other]: 'Unable to Classify',
  [BusinessActivity.AccountingServices]:
    'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
  [BusinessActivity.BroadcastingTelecom]:
    'Broadcasting (except Internet)',
  [BusinessActivity.EducationalServices]:
    'Educational Services',
  [BusinessActivity.FinanceAndInsurance]:
    'Finance and Insurance',
  [BusinessActivity.FoodServices]:
    'Food Services and Drinking Places',
  [BusinessActivity.HealthCareAndSocialAssistance]:
    'Health Care and Social Assistance',
  [BusinessActivity.InformationServices]:
    'All Other Information Services',
  [BusinessActivity.LegalServices]: 'Legal Services',
  [BusinessActivity.Manufacturing]: 'Manufacturing',
  [BusinessActivity.MiscellaneousOtherService]:
    'Accommodation and Food Services',
  [BusinessActivity.MoviesVideoSound]:
    'Motion Picture and Sound Recording Industries',
  [BusinessActivity.Publishing]:
    'Publishing Industries (except Internet)',
  [BusinessActivity.RealEstate]:
    'Real Estate and Rental and Leasing',
  [BusinessActivity.Retail]: 'Retail Trade',
  [BusinessActivity.Wholesale]: 'Wholesale',
  // [BusinessActivity.Vacant]: 'Vacant',

  [StorefrontLeaseConcessions.None]: 'N/A or None',
  [StorefrontLeaseConcessions.RentFree]: 'Number of Months Rent-Free',
  [StorefrontLeaseConcessions.StartRent]:
    'Reduced Starting Rent or Rent Abatement Amount',
  [StorefrontLeaseConcessions.Improve]:
    'Improvement or Cash Allowance Amount',
  [StorefrontLeaseConcessions.Other]: 'Other',

  [Utilities.Electricity]: 'Electricity',
  [Utilities.Heat]: 'Heat',
  [Utilities.Water]: 'Water',
  [Utilities.None]: 'None',

  [RPIEExclusions.Actual]: 'Assessed Value below $40,000',
  [RPIEExclusions.Residential10]: 'Residential only (10 or fewer apartments)',
  [RPIEExclusions.Residential6]:
    'Primarily residential (up to 1 commercial unit and up to 6 apartments)',
  [RPIEExclusions.ResidentialCoop]: 'Residential coop',
  [RPIEExclusions.IndividResidentialCondo]:
    'Individual residential condo unit',
  [RPIEExclusions.RentedExclusive]: 'Rented to a related person',
  [RPIEExclusions.OccupiedExclusive]: 'Owner-occupied',
  [RPIEExclusions.VacantNonIncome]: 'Vacant/uninhabitable',
  [RPIEExclusions.VacantNonIncomeLand]: 'Vacant land',
  [RPIEExclusions.OwnerNotOperated]:
    'Partial year (purchase in reporting period)',
  [RPIEExclusions.OwnedUsedExclusive]: 'Owned by not-for-profit organization',

  //Signatory title
  [SignatoryTitle.Accountant]: 'Accountant',
  [SignatoryTitle.AsstSecretary]: 'Asst. Secretary',
  [SignatoryTitle.AuthorizedSignatory]: 'Authorized Signatory',
  [SignatoryTitle.CEO]: 'CEO',
  [SignatoryTitle.Chairman]: 'Chairman',
  [SignatoryTitle.CoOwner]: 'Co-Owner',
  [SignatoryTitle.CoOwnerIndividual]: 'Co-Owner (Individual)',
  [SignatoryTitle.Director]: 'Director',
  [SignatoryTitle.GeneralPartner]: 'General Partner',
  [SignatoryTitle.Manager]: 'Manager',
  [SignatoryTitle.ManagingAgent]: 'Managing Agent',
  [SignatoryTitle.ManagingMember]: 'Managing Member',
  [SignatoryTitle.Member]: 'Member',
  [SignatoryTitle.MemberBoardManagers]: 'Member of the Board of Managers',
  [SignatoryTitle.Officer]: 'Officer',
  [SignatoryTitle.OwnerIndividual]: 'Owner (Individual)',
  [SignatoryTitle.Partner]: 'Partner',
  [SignatoryTitle.President]: 'President',
  [SignatoryTitle.PresidentBoardManagers]:
    'President of the Board of Managers',
  [SignatoryTitle.Secretary]: 'Secretary',
  [SignatoryTitle.Treasurer]: 'Treasurer',
  [SignatoryTitle.Trustee]: 'Trustee',
  [SignatoryTitle.VicePresident]: 'Vice President',
},
  EMPTY_DICTI_NAME = 'N/A',
  getDictiName = (key: string | null, defaultValue?: any) =>
    isNullOrWhiteSpace(key) || isNullOrWhiteSpace(DictiNames[key as string])
      ? defaultValue || EMPTY_DICTI_NAME
      : DictiNames[key as string]

//TODO reconfigure modules and remove this constants ======================
enum FunctionNames {
  DOWNLOAD_URL_FOR_FILE_OR_ZIP = 'main-getDownloadFileOrZipURL',
  CLEAR_EXPARIED_ITEMS = 'main-clearExpariedZipFiles',
  GET_FILE_LINK = 'main-getFileLink',
  SEND_NOTIFICATION_FOR_ADMIN = 'main-sendNotificationForAdmin',
  RESET_PASSWORD_USER_NOT_FOUND = 'main-resetPasswordUserNotFound',
  USE_INVITE = 'main-useInvite',
  GET_PROFILE_DATA_BY_INVITE = 'main-getProfileDataByInvite',
  FLUSH_INVITES = 'main-flushInvites',
}

export {
  //=======================================
  getDictiName,
  FunctionNames,
  BuildingClasses,
  TaxClasses,
  ActualAVType,
  Services,
  SFApplicationStatus,
  TAStatuses,
  TAWizardSections,
  TAWizardSteps,
  RPIEStatuses,
  RPIEWizardSections,
  RPIEWizardSteps,
  Relations,
  PropertyUsage,
  UseByApplicant,
  PortionUsedByApplicant,
  IncomeSectionTypes,
  IncomeCategories,
  ExpenseSectionTypes,
  ExpenseCategories,
  FloorUsesType,
  PortionRentFromAnyTenants,
  PropertyType,
  HotelRoomTypes,
  HotelFurnitureCosts,
  EventType,
  Aggregated,
  NonAggregated,
  FileStatus,
  DocumentType,
  DocumentTypeTC,
  DocumentTypeOther,
  DocumentStatus,
  ReportingPeriodType,
  AccountingBasis,
  FileSources,
  UseOfSpace,
  StorefrontUnitDescriptionType,
  StorefrontUnitOccupancyType,
  BusinessActivity,
  StorefrontLeaseConcessions,
  Utilities,
  RPIEExclusions,
  SignatoryTitle,
  HotelBuildingClasses,
}
