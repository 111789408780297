import React from 'react'
import { Flex, Image, VStack } from '@chakra-ui/react'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import Button from 'ui-framework/components/primitives/Button'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'
import BaseComponentProps from 'common/BaseComponentProps'
import { AnyFunction } from 'helpers/utils'

type Props = {
  image?: string
  title: string
  description: string
  fallback: AnyFunction
  fallbackText: string
} & BaseComponentProps

/**
 * ErrorPage component
 */
const ErrorPage = ({
  image,
  title,
  description,
  fallback,
  fallbackText,
}: Props) => {
  return (
    <VStack w="100%" spacing={'24px'} mt={`120px`}>
      {image && <Image src={image} />}

      <div style={{ width: '340px' }}>
        <TextFromParams
          params={{
            lineHeight: '20px',
            fontSize: '15px',
            textStyle: 'body.semibold',
          }}
        >
          {title}
        </TextFromParams>

        <TextFromParams params={{ lineHeight: '25px', m: '8px 0 24px' }}>
          {description}
        </TextFromParams>

        <Flex justifyContent="center">
          <Button
            variant={'secondary'}
            width={'129px'}
            margin={'0 20px 0 0'}
            to={`mailto:${SUPPORT_CONTACTS.contactUsEmail}`}
          >
            Contact Us
          </Button>
          {fallback && fallbackText && (
            <Button onClick={fallback}>{fallbackText}</Button>
          )}
        </Flex>
      </div>
    </VStack>
  )
}

export default ErrorPage
