import React, {
  useMemo,
  useState,
  useCallback,
  ReactNode,
  useEffect,
} from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import Table from 'ui-framework/components/patterns/Table'
import Button from 'ui-framework/components/primitives/Button'
import Input from 'ui-framework/components/primitives/Input'
import Icon from 'ui-framework/components/primitives/Icon'
import PageTabbedLayout from 'ui-framework/components/layout/PageTabbedLayout'
import { TableHeader } from 'ui-framework/components/patterns/Table/TableHeaders'
import { generatePortalPath, IsAdmin } from 'helpers/utils'
import { set } from 'object-path-immutable'
import { getBBLWithAddressString } from 'helpers/business'
import Chips from 'ui-framework/components/primitives/Chips'
import { HStack, Text, Flex, Box } from '@chakra-ui/layout'
import { chakra, ChakraProps } from '@chakra-ui/system'
import Link from 'ui-framework/components/primitives/Link'
import OnboardingDialog from 'ui-framework/components/primitives/OnboardingDialog'
import createPersistedState from 'use-persisted-state'
import Empty from 'ui-framework/components/primitives/Empty'
import { TabItem } from 'ui-framework/components/primitives/Tabs'
import usePropertyList from 'hooks/api/firebase/usePropertyList'
import InformationDialog from 'ui-framework/components/primitives/InformationDialog'
import CircleMark from 'ui-framework/components/primitives/CircleMark'
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
} from '@chakra-ui/react'
import {
  PropertyListAction,
  PropertyListStatusType,
  UseServiceStatus,
} from 'services/ta/useTaStatus'
import { Application } from 'hooks/api/firebase/useApplication'
import { useDownloadFiles } from 'hooks/api/documents/useDownloadFiles'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import { isConsolidatedNotRoot } from 'pages/Services/TA/TAFormPage/wizard/taWizardConfig'
import {
  DocumentStatus,
  getDictiName,
  RPIEStatuses,
  Services,
  TAStatuses,
} from 'constants/dicti'
import { ResultDoc } from 'hooks/api/documents/useGetDocs'
import useFilingDeadlineWarningDialog from 'pages/Services/TA/TAListPage/FilingDeadlineWarningDialog/useFilingDeadlineWarningDialog'
import { NBAR_TYPE } from 'ui-framework/components/patterns/NotificationBar'
import useScrollToView from 'hooks/useScrollToView'
import useApplicationContext, {
  useAppConfig,
} from 'hooks/useApplicationContext'
import Prompt from 'ui-framework/components/primitives/Prompt'
import { useMyDocuments } from 'hooks/api/my/MyDocumentsContext'
import useAuth from 'hooks/useAuth'
import useQueryParamState from 'hooks/useQueryParamState'

//===================================================
type PropertyEx = {
  $bbl?: string
  $isOpened?: boolean
  $children?: PropertyEx[]
} & Application<any>

enum PropertyListTabs {
  ALL = 'all',
  NOT_STARTED = 'new',
  ACTIVE = 'active',
  UNPAID = 'unpaid',
  COMPLETE = 'complete',
  CONSOLIDATED = 'consolidated',
  SUBMITTED = 'submitted',
}

type TabIdsTypes =
  | PropertyListTabs.ALL
  | PropertyListTabs.ACTIVE
  | PropertyListTabs.UNPAID
  | PropertyListTabs.COMPLETE
  | PropertyListTabs.CONSOLIDATED
  | PropertyListTabs.SUBMITTED

const PropertyListTabsNames = {
  [PropertyListTabs.ALL]: 'All',
  [PropertyListTabs.NOT_STARTED]: 'Not started',
  [PropertyListTabs.ACTIVE]: 'In progress',
  [PropertyListTabs.UNPAID]: 'Unpaid',
  [PropertyListTabs.COMPLETE]: 'Complete',
  [PropertyListTabs.CONSOLIDATED]: 'Consolidated',
  [PropertyListTabs.SUBMITTED]: 'Submitted',
}

const tabsIds = Object.values(PropertyListTabs)

const Marked = chakra('td', {
  baseStyle: {
    borderBottom: 'none !important',
    '.marker': {
      minWidth: '11px',
      height: '11px',
      borderRadius: '50%',
      border: '1px solid',
      margin: '0 16px',
      borderColor: 'primary.base',
      display: 'inline-block',
      '&:before, &:after': {
        content: '""',
        width: '1px',
        bgColor: 'primary.base',
        position: 'absolute',
        height: '41%',
        left: '21px',
      },
      '&:before': {
        bottom: '0',
      },
      '&:after': {
        top: '0',
      },
      '&.last:before': {
        display: 'none',
      },
      '&.first:after': {
        display: 'none',
      },
    },
  },
})

const NameTitle = chakra('p', {
  baseStyle: {
    textStyle: 'body.semibold',
    color: 'fontnavy',
    position: 'absolute',
  },
})

const isPreferPayByCheck = false,
  useOnboardingShow = createPersistedState('pl_onboarding_dialog_show')
// This field was removed from Property (#10283)
// In future this flag should be defined in Document Center

type Props = {
  serviceType: Services
  backLink: string
  backLinkText: string
  formLink: string
  formSummaryLink: string
  useServiceStatus: UseServiceStatus
  onboarding?: {
    template: ReactNode
    styles: ChakraProps
  }
} & BaseComponentProps

const createTab = (title: string | ReactNode) => (
  <Tab
    p={'0 0 12px 0'}
    m={'0 24px 0 0'}
    _selected={{
      p: { color: 'primary.base' },
      borderColor: 'primary.base',
    }}
    _active={{
      bgColor: 'transparent',
    }}
    _focus={{
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
    }}
  >
    <TextFromParams
      params={{
        textAlign: 'left',
      }}
    >
      {title}
    </TextFromParams>
  </Tab>
)

/**
 * ServicePropertyList component
 */
const ServicePropertyList = ({
  serviceType,
  backLink,
  backLinkText,
  formLink,
  formSummaryLink,
  useServiceStatus,
  onboarding,
  ...props
}: Props) => {
  const admin = IsAdmin(),
    [onboardingShow, onboardingShowSet] = useOnboardingShow<boolean>(!admin),
    { getStatusType, getStatusAction } = useServiceStatus()

  const [activeTab, handleTabChange] = useQueryParamState(
    'tab',
    PropertyListTabs.ALL
  )
  const [search, handleSearchChange] = useQueryParamState('search', undefined),
    { effectiveProfile, showUnowned } = useAuth(),
    getOwnerName = useCallback(
      () =>
        showUnowned
          ? 'UNOWNED'
          : effectiveProfile
            ? `${effectiveProfile?.firstName} ${effectiveProfile?.lastName}'s`
            : '',
      [effectiveProfile, showUnowned]
    )

  const propertyList = usePropertyList(serviceType),
    properties = useMemo<PropertyEx[]>(
      () => propertyList?.data || [],
      [propertyList.data]
    ),
    [openedConsolidatedIds, openedConsolidatedIdsSet] = useState<string[]>([]),
    {
      data: { dc: documents },
    } = useMyDocuments(),
    applicationDocuments = useMemo(
      () =>
        (documents || [])
          .map(doc => ({
            ...doc,
            documents: doc.documents.filter(d => d.files.length),
          }))
          .filter(doc => doc.documents.length),
      [documents]
    ),
    { downloadFilesHandler, downloadDialog } = useDownloadFiles()

  const { nbar } = useApplicationContext(),
    appconf = useAppConfig(),
    deadlineMessage = useMemo(
      () => appconf.deadlineMessage?.[serviceType],
      [appconf.deadlineMessage, serviceType]
    ),
    warningDialog = useFilingDeadlineWarningDialog(
      deadlineMessage,
      appconf.deadlineAction?.[serviceType]
    )

  useEffect(() => {
    if (deadlineMessage)
      nbar.set({
        type: NBAR_TYPE.WARNING,
        message: (
          <>
            Provide info for all properties by <b>{deadlineMessage}!</b>
          </>
        ),
      })
    else nbar && nbar.reset()
    return () => nbar && nbar.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlineMessage])

  const getFormLink = useCallback(
    (bblid, view = false) =>
      generatePortalPath(
        view ? formSummaryLink : formLink,
        {
          bblid: bblid,
        },
        true
      ),
    [formLink, formSummaryLink]
  )

  const headers: TableHeader[] = useMemo(() => {
    const permanentHeaders = [
      { text: '', width: '40px', noborder: true },
      { text: 'BBL / Address', path: '$bbl', width: '30%' },
      { text: 'Applicant', path: 'bbl.applicantName', width: '30%' },
      { text: 'Status', width: '30%' },
      { text: '', width: admin ? '330px' : '220px' },
      { text: '', width: '80px', noborder: true },
    ]

    if (admin) {
      permanentHeaders.splice(4, 0, { text: 'Fin docs ready', width: '101px' })
    }
    return permanentHeaders
  }, [admin])

  const getDocument = useCallback(
    (parentId: string) =>
      applicationDocuments.find(doc => doc.parentId === parentId) as ResultDoc,
    [applicationDocuments]
  )

  const scrollToViewByRef = useScrollToView()

  //TODO: Use service context dependency (PropertyListAction) in the servcie-context-free component
  const getAction = useCallback(
    (item: Application<any>, disabled = false) => {
      const action = getStatusAction(item)
      return (
        <>
          {admin && (action === PropertyListAction.VIEW || disabled) && (
            <Button
              size="sm"
              minWidth="98px"
              variant="secondary"
              to={getFormLink(item.id)}
            >
              Edit
            </Button>
          )}
          {action !== PropertyListAction.NONE && (
            <Button
              size="sm"
              minWidth="98px"
              disabled={disabled && action !== PropertyListAction.VIEW}
              variant="secondary"
              to={getFormLink(item.id, action === PropertyListAction.VIEW)}
            >
              {action === PropertyListAction.CONTINUE
                ? 'Continue'
                : action === PropertyListAction.START
                  ? 'Start'
                  : 'View'}
            </Button>
          )}
        </>
      )
    },
    [admin, getFormLink, getStatusAction]
  )

  const checkItems = useCallback(
    (i: PropertyEx, tab) => {
      const cst = getStatusType(i)
      switch (tab) {
        case PropertyListTabs.ALL:
          return true
        case PropertyListTabs.UNPAID:
          return i.hasUnpaidInvoice
        case PropertyListTabs.COMPLETE:
          return [
            PropertyListStatusType.COMPLETE,
            PropertyListStatusType.FILED,
            PropertyListStatusType.SUBMITTED,
            PropertyListStatusType.IMPORTED,
          ].includes(cst)
        case PropertyListTabs.CONSOLIDATED:
          return i?.isConsolidated === true
        case PropertyListTabs.SUBMITTED:
          return cst === PropertyListStatusType.SUBMITTED
        case PropertyListTabs.NOT_STARTED:
          return cst === PropertyListStatusType.NEW
        default:
        case PropertyListTabs.ACTIVE:
          return cst === PropertyListStatusType.IN_PROGRESS
      }
    },
    [getStatusType]
  )

  const groupedConsolidItems = useMemo(() => {
    const itemsWoNonRoots = properties.filter(i => !isConsolidatedNotRoot(i))
    return itemsWoNonRoots.map(i => {
      return i.isRootConsolidateBBL
        ? set(
          i,
          '$children',
          properties.filter(
            c =>
              c.rootConsolidateCoreBBLE_ID === i.rootConsolidateCoreBBLE_ID
          )
        )
        : i
    })
  }, [properties]),
    filteredItems = useMemo(() => {
      return (
        [
          PropertyListTabs.ALL,
          PropertyListTabs.CONSOLIDATED,
          PropertyListTabs.UNPAID,
        ].includes(activeTab as PropertyListTabs)
          ? groupedConsolidItems
          : properties
      )
        .filter(i => checkItems(i, activeTab || PropertyListTabs.ALL))
        .sort((b, a) => {
          let result = 0

          if (a.hasUnpaidInvoice && !b.hasUnpaidInvoice) result = 1
          else if (!a.hasUnpaidInvoice && b.hasUnpaidInvoice) result = -1
          else {
            const at = getStatusType(a),
              bt = getStatusType(b)

            result =
              at === bt
                ? 0
                : at === PropertyListStatusType.NEW &&
                  bt !== PropertyListStatusType.NEW
                  ? 1
                  : at !== PropertyListStatusType.NEW &&
                    bt === PropertyListStatusType.NEW
                    ? -1
                    : at === PropertyListStatusType.IN_PROGRESS &&
                      bt !== PropertyListStatusType.IN_PROGRESS
                      ? 1
                      : at !== PropertyListStatusType.IN_PROGRESS &&
                        bt === PropertyListStatusType.IN_PROGRESS
                        ? -1
                        : 0
          }
          return result
        })
    }, [activeTab, checkItems, getStatusType, groupedConsolidItems, properties])

  const getCount = useCallback(
    tab => properties.reduce((p, i) => p + (checkItems(i, tab) ? 1 : 0), 0),
    [checkItems, properties]
  )

  const isSubmittedHidden = tabId =>
    tabId === PropertyListTabs.SUBMITTED && !admin

  const tabs = useMemo<TabItem[]>(
    () =>
      tabsIds.map(tabId => ({
        id: tabId,
        title: PropertyListTabsNames[tabId],
        label: getCount(tabId),
        hidden: !getCount(tabId) || !!isSubmittedHidden(tabId),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCount, admin]
  )

  const isConsolidatedOpened = id => openedConsolidatedIds.includes(id),
    toggleGroup = item => {
      const found = isConsolidatedOpened(item.id),
        newIds = found
          ? openedConsolidatedIds.filter(i => i !== item.id)
          : [...openedConsolidatedIds, item.id]

      openedConsolidatedIdsSet(newIds)
    }
  const getGroupRow = item => {
    const isOpened = isConsolidatedOpened(item.id)
    return (
      <chakra.tr
        backgroundColor={isOpened ? 'secondary.sideMenu' : undefined}
        className={isOpened ? 'noborder' : ''}
      >
        <Marked />
        <td>
          <Text isTruncated>
            <Link
              to=""
              decorate={false}
              onClick={e => {
                toggleGroup(item)
                e?.preventDefault()
              }}
            >
              {getBBLWithAddressString(item)}
            </Link>
          </Text>
        </td>
        <td>
          <Text
            isTruncated
          >{`Group of ${item.$children.length} consolidated lots`}</Text>
        </td>
        <td></td>
        {admin && <td></td>}
        <td>
          <HStack justify="flex-end">
            <Button
              variant="secondary"
              size="sm"
              width="98px"
              onClick={() => toggleGroup(item)}
            >
              {isOpened ? 'Hide' : 'Show'}
            </Button>
          </HStack>
        </td>
        <Marked />
      </chakra.tr>
    )
  }
  const getPropertyRow = (
    item,
    parent = false,
    isLast = false,
    ungrouped = false
  ) => {
    const { status, sections } = preparedValues[item.id]
    const documents = getDocument(item.id)?.documents

    return (
      <chakra.tr
        backgroundColor={ungrouped ? 'secondary.sideMenu' : undefined}
        className={`${ungrouped && !isLast ? 'noborder' : ''}`}
        sx={{
          _hover: {
            div: {
              display: 'flex',
            },
          },
        }}
      >
        <Marked style={{ position: 'relative' }}>
          {ungrouped ? (
            <span
              className={`marker ${parent ? 'first' : isLast ? 'last' : ''}`}
            />
          ) : undefined}
        </Marked>
        <td>
          {item.isAllowedForWeb ? (
            <Text isTruncated>
              <Link
                decorate={false}
                to={
                  // TODO: This is temporary hotfix
                  getStatusAction(item) !== PropertyListAction.NONE
                    ? getFormLink(
                      item.id,
                      getStatusAction(item) === PropertyListAction.VIEW
                    )
                    : '#'
                }
              >
                {getBBLWithAddressString(item)}
              </Link>
            </Text>
          ) : (
            <Text isTruncated>{getBBLWithAddressString(item)}</Text>
          )}
        </td>
        <td style={{ position: 'relative' }}>
          <Text isTruncated>{item.applicantName}</Text>
          {parent ? <NameTitle>Main Tax Lot</NameTitle> : null}
        </td>
        <td>
          <Flex alignItems="center">{status}</Flex>
        </td>
        {admin && (
          <td>
            <Flex justify={'center'}>
              {documents?.length || <Empty type={'longdash'} />}
            </Flex>
          </td>
        )}
        <td>
          <HStack justify="flex-end">
            {sections && (
              <InformationDialog mr={'8px'} placement="left-start">{sections}</InformationDialog>
            )}
            {item.hasUnpaidInvoice &&
              !isPreferPayByCheck &&
              (parent || !item.isConsolidated) && (
                <Button
                  size="sm"
                  minWidth="98px"
                  onClick={() =>
                    window.open(item.unpaidInvoicePageURL, '_blank')
                  }
                >
                  Pay
                </Button>
              )}
            {getAction(item, !item.isAllowedForWeb)}
          </HStack>
        </td>
        <Marked />
      </chakra.tr>
    )
  }

  const getStepsInfo = useCallback(
    item => {
      const { steps, currentStepId } = item?.serviceMetaData || {},
        getChipStatus = (
          text = 'Not started',
          variant = 'gray',
          hasPrompt = false
        ) => {
          return (
            <>
              <Chips prefix={true} variant={variant}>
                {text}
              </Chips>
              {hasPrompt && (
                <Prompt
                  type="warning"
                  maxW="190px"
                  iconParams={{ marginLeft: '8px' }}
                  label={
                    'MGNY received all required information. No extra action needed'
                  }
                />
              )}
            </>
          )
        },
        statusType = getStatusType(item),
        inProgress = statusType === PropertyListStatusType.IN_PROGRESS,
        isNew = statusType === PropertyListStatusType.NEW,
        isNoSubmission = statusType === PropertyListStatusType.NO_SUBMISSSION,
        sectionsCount = steps && steps.length

      if (steps && inProgress) {
        const { t, sections } = steps.reduce(
          (acc, { stepsIds = [], sectionTitle }, i) => {
            const checkPrevSection = key => {
              if (!acc.sections[key - 1]) return
              acc.sections[key - 1].finished = true
              checkPrevSection(key - 1)
            },
              addSection = v => acc.sections.push(v)

            const index = stepsIds.findIndex(id => id === currentStepId),
              section = {
                sectionTitle,
                started: false,
                finished: false,
              }

            if (index !== -1) {
              acc.t = `${sectionTitle} (${i + 1}/${sectionsCount})`
              index >= 0 && (section.started = true)
            }
            addSection(section)
            index >= 0 && checkPrevSection(i)

            return acc
          },
          { t: undefined, sections: [] }
        )

        return {
          status: getChipStatus(t, 'orange'),
          sections,
        }
      }

      return {
        status: isNew
          ? getChipStatus()
          : isNoSubmission
            ? getChipStatus(getDictiName(item.statusCS), 'gray', true)
            : inProgress
              ? getChipStatus(getDictiName(item.statusCS), 'orange')
              : getChipStatus(
                getDictiName(admin ? item.statusCS : TAStatuses.Submit),
                'green'
              ),
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStatusType]
  ),
    getSectionsInfo = useCallback(
      (sections, parentId) => {
        const document = getDocument(parentId),
          content = (
            <Tabs
              isLazy
              maxHeight="270px"
              border={'none'}
              borderRadius="12px"
              boxShadow={'0 4px 8px 0 rgba(4,7,31,0.15)'}
            >
              <TabList
                borderColor={`secondary.divider`}
                borderBottom={'1px solid'}
                p={'14px 16px 0'}
              >
                {createTab('Progress details')}
                {document && createTab('Files to download')}
              </TabList>

              <TabPanels
                overflowY={`scroll`}
                h={'220px'}
                sx={{
                  '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                    border: 'none',
                  },
                }}
              >
                <TabPanel p={'0 14px 0 16px'}>
                  <Flex flexDirection="column">
                    {sections.map((el, i) => {
                      const getStatus = ({ finished, started }) => {
                        const t =
                          started && !finished
                            ? 'In Progress'
                            : finished
                              ? 'Completed'
                              : 'Not Started'
                        return (
                          <TextFromParams
                            params={{
                              color: 'fontandicongray',
                            }}
                          >
                            {t}
                          </TextFromParams>
                        )
                      },
                        notLast = sections.length !== i + 1
                      return (
                        <Flex
                          justifyContent="space-between"
                          w="100%"
                          alignItems="center"
                          key={i}
                          //Section in progress
                          ref={
                            (el.started && !el.finished && scrollToViewByRef) ||
                            null
                          }
                        >
                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <Flex
                              justifyContent="space-between"
                              w="100%"
                              alignItems="center"
                              h="72px"
                            >
                              <Box>
                                <TextFromParams
                                  params={{
                                    textAlign: 'left',
                                    mt: '6px',
                                  }}
                                >
                                  Step {i + 1}: {el.sectionTitle}
                                </TextFromParams>

                                <TextFromParams
                                  params={{
                                    color: 'fontandicongray',
                                    textStyle: 'caption.regular',
                                    textAlign: 'left',
                                    mt: '6px',
                                  }}
                                >
                                  {deadlineMessage && (
                                    <>Deadline: {deadlineMessage}</>
                                  )}
                                </TextFromParams>
                              </Box>
                              <Box>{getStatus(el)}</Box>
                            </Flex>
                            {notLast && <Divider />}
                          </Flex>
                          <CircleMark
                            aroundBg={
                              el.finished
                                ? 'green.opacity'
                                : el.started
                                  ? 'orange.opacity'
                                  : 'secondary.inputGray'
                            }
                            circleBg={
                              el.finished
                                ? 'green.base'
                                : el.started
                                  ? 'orange.base'
                                  : 'fontandicongray'
                            }
                            h="73px"
                            ml="14px"
                            topLine={!!i}
                            bottomLine={notLast}
                          />
                        </Flex>
                      )
                    })}
                  </Flex>
                </TabPanel>
                <TabPanel p={'0 16px 0'}>
                  <Flex flexDirection="column">
                    {document?.documents?.map((el, i, arr) => {
                      const createMark = status => {
                        const params: { aroundBg: string; circleBg: string } =
                        {
                          aroundBg: 'purple.opacity',
                          circleBg: 'primary.base',
                        }
                        switch (status) {
                          case DocumentStatus.DownloadNow:
                            params.aroundBg = 'orange.opacity'
                            params.circleBg = 'orange.base'
                            break
                          case DocumentStatus.Uploaded:
                            params.aroundBg = 'secondary.inputGray'
                            params.circleBg = 'fontandicongray'
                            break
                          case DocumentStatus.Received:
                            params.aroundBg = 'green.opacity'
                            params.circleBg = 'green.base'
                            break
                        }
                        return (
                          <CircleMark
                            aroundBg={params.aroundBg}
                            circleBg={params.circleBg}
                            h="73px"
                            ml="14px"
                          />
                        )
                      },
                        notLast = arr.length - 1 !== i
                      return (
                        <Flex
                          justifyContent="space-between"
                          w="100%"
                          alignItems="center"
                          key={i}
                        >
                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <Flex
                              justifyContent="space-between"
                              w="100%"
                              alignItems="center"
                              h="72px"
                            >
                              <Box>
                                <TextFromParams
                                  params={{
                                    textAlign: 'left',
                                  }}
                                >
                                  {getDictiName(el.typeCS)}
                                </TextFromParams>

                                <Button
                                  height={'18px'}
                                  width={`62px`}
                                  variant={`textPrimary`}
                                  mt={'6px'}
                                  onClick={() =>
                                    downloadFilesHandler({
                                      docTitle: document.title,
                                      ...el,
                                    })
                                  }
                                >
                                  Download
                                </Button>
                              </Box>
                              <Box>{getDictiName(el.statusCS)}</Box>
                            </Flex>
                            {notLast && <Divider />}
                          </Flex>
                          {createMark(el.statusCS)}
                        </Flex>
                      )
                    })}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )
        return content
      },
      [deadlineMessage, downloadFilesHandler, getDocument, scrollToViewByRef]
    ),
    preparedValues = useMemo(() => {
      return properties.reduce((acc, item) => {
        const { status, sections } = getStepsInfo(item)
        acc[item.id] = {
          status,
          ...(sections && { sections: getSectionsInfo(sections, item.id) }),
        }
        return acc
      }, {})
    }, [getStepsInfo, getSectionsInfo, properties])

  return (
    <PageTabbedLayout
      title={
        <Flex alignItems="center">
          {admin && !propertyList.isPending ? getOwnerName() + ' ' : ''}
          Property list
          {onboarding && (
            <OnboardingDialog
              styles={onboarding?.styles}
              show={onboardingShow}
              showSet={onboardingShowSet}
            >
              {onboarding.template}
            </OnboardingDialog>
          )}
        </Flex>
      }
      leftAction={
        admin ? (
          <Button
            height={'auto'}
            width={`150px`}
            prefix={`left`}
            variant={`textSecondary`}
            to={backLink}
          >
            {backLinkText}
          </Button>
        ) : undefined
      }
      mainActions={
        <Input
          value={search as string}
          showClearAfter={2}
          width={`448px`}
          prefix={<Icon>search</Icon>}
          placeholder="Search"
          onChange={handleSearchChange}
        />
      }
      tabs={tabs}
      onTabChange={handleTabChange}
      activeTab={activeTab as TabIdsTypes}
    >
      <Table
        isSticky
        items={filteredItems}
        headers={headers}
        search={search?.toString().toLowerCase()}
        pending={propertyList.isPending}
        rowsSpacing={16}
        itemsPerPage={15}
        rowsHeight="56px"
        cellSidePaddings={`8px`}
        emptyMessage={{
          title: 'There are no properties yet',
          message: 'Check this page later.',
        }}
      >
        {(item: PropertyEx) =>
          item.$children ? (
            !isConsolidatedOpened(item.id) ? (
              getGroupRow(item)
            ) : (
              <>
                {getGroupRow(item)}
                {item.$children
                  .sort(
                    (a, b) =>
                      Number(b.isRootConsolidateBBL) -
                      Number(a.isRootConsolidateBBL)
                  )
                  .map((c, i, arr) =>
                    getPropertyRow(
                      c,
                      c.isRootConsolidateBBL,
                      arr.length - 1 === i,
                      true
                    )
                  )}
              </>
            )
          ) : (
            getPropertyRow(item)
          )
        }
      </Table>
      {downloadDialog}
      {warningDialog}
    </PageTabbedLayout>
  )
}

export default ServicePropertyList
