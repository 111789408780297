import { ComponentStyleConfig } from '@chakra-ui/theme'

const BackAndNextStyle: ComponentStyleConfig = {
  baseStyle: {
    borderTop: '1px solid',
    borderColor: 'secondary.divider',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '40px',
    paddingTop: '20px',
    paddingBottom: '40px',
    // '& > :first-child': {
    //   marginRight: '12px',
    // },
  },
  // The size styles for each part
  sizes: {
    md: {},
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default BackAndNextStyle
