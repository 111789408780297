import React from 'react'
import styled from '@emotion/styled/macro'

import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type Props = {
  typography?: object
  color?: string
  margin?: string
  padding?: string
  ellipsis?: boolean
  as?: string
  style?: object
} & BaseComponentProps
const BoxWrapper = styled(({ as: T = 'span', ...props }) => (
  <T {...props} />
))<Props>(
  props =>
    props.color && {
      color: props.color,
    },
  props =>
    props.typography && {
      ...props.typography,
    },
  props =>
    props.margin && {
      margin: props.margin,
    },
  props =>
    props.padding && {
      padding: props.padding,
    },
  props =>
    props.ellipsis && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
)
/**
 * Box component
 */
const Box = ({ className, children, style = {}, ...props }: Props) => {
  return (
    <BoxWrapper {...props} className={className} style={style}>
      {children}
    </BoxWrapper>
  )
}

export default Box
