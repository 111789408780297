import { RPIEExclusions } from 'constants/dicti'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'
import dayjs from 'dayjs'
import Joi from 'joi'

const schema = schemaWrapper({
  numberOfBuildings: schemaRules.MIN_NUMBER(),
  yearPurchase: schemaRules.MIN_MAX_NUMBER(1800, dayjs().year()),
  isPropertyToNetLease: schemaRules.BOOLEAN,
  wasAnyRentalIncome: schemaRules.BOOLEAN,
  exclusionCS: Joi.when('wasAnyRentalIncome', {
    is: false,
    then: schemaRules.VALID_STRING(...Object.values(RPIEExclusions)),
    otherwise: null,
  }),
})

export default schema
