import { ComponentStyleConfig } from '@chakra-ui/theme'

const AbstractInputStyles: ComponentStyleConfig = {
  parts: ['wrapper', 'placeholder'],
  baseStyle: props => {
    const { error } = props

    return {
      gwrapper: {
        width: props.width || '180px',
        position: 'relative',
      },
      wrapper: {
        width: '100%',
        textStyle: 'body.regular',
        color: 'fontnavy',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        borderRadius: '6px',
        border: '1px solid',
        borderColor: error
          ? 'red.base'
          : props.disabled
          ? 'primary.disabled'
          : 'transparent',
        backgroundColor: error
          ? 'red.opacity'
          : props.disabled
          ? 'secondary.sideMenu'
          : 'secondary.inputGray',
        //TODO: refactor data-type
        '[data-type="postfix"]': {
          marginBottom: '-2px',
        },
        '[data-type="content"]': {
          display: 'grid',
          gridTemplateColumns: '1fr',
          '& > *': {
            gridRowStart: 1,
            gridColumnStart: 1,
          },
        },
        '.icon': {
          // marginBottom: '-2px',
          svg: {
            fill: error ? 'red.base' : 'fontandicongray',
          },
        },
        '.warning': {
          // marginBottom: '0',
          svg: {
            width: '16px',
            height: '16px',
          },
        },
        textarea: {
          border: 'none',
          outline: 'none',
          bgColor: 'transparent',
          width: '100%',
          resize: props.resize || 'none',
        },
        input: {
          outline: 'none',
          width: '100%',
          backgroundColor: 'transparent',
          textOverflow: 'ellipsis',
          '&[type="password"]': {
            color: error ? 'red.base' : 'unset',
            fontSize: 'body',
            letterSpacing: '3px',
            lineHeight: 'caption',
            '&::placeholder': {
              letterSpacing: 'initial',
            },
          },
          '&:-webkit-autofill': {
            //For fix http://dev.mgnyconsulting.com:8081/redmine/issues/12294
            border: 'solid transparent',
          },
        },
        _hover: !props.disabled && {
          borderColor: 'primary.base',
          bgColor: 'secondary.sideMenu',
          '[data-role="placeholder"]': {
            color: props.disabled ? 'primary.disabled' : 'fontandicongray',
          },
        },
        _focusWithin: {
          borderColor: 'primary.base',
          bgColor: 'white',
          '[data-role="placeholder"]': {
            color: props.disabled ? 'primary.disabled' : 'fontandicongray',
          },
        },
        _disabled: {
          borderColor: 'primary.disabled',
          bgColor: 'secondary.sideMenu',
          '.icon': {
            svg: {
              fill: 'primary.disabled',
            },
          },
        },
      },
      tooltip: {
        borderRadius: '8px',
        padding: '8px',
        textStyle: `caption.regular`,
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
      wrapperInner: {
        position: props.errorTooltip?.outside ? 'absolute' : 'relative',
        right: props.errorTooltip?.outside ? '-24px' : '',
      },
      'input::placeholder': {
        // display: (!props.placeholder || !!props.value) && 'none',
        color: props.disabled
          ? 'primary.disabled'
          : error
          ? 'red.base'
          : 'fontandicongray',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        textOverflow: 'ellipsis',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      label: {
        textStyle: 'body.regular',
        display: !props.label && 'none',
        marginBottom: '12px',
        whiteSpace: 'nowrap',
      },
      message: {
        textStyle: 'caption.regular',
        display: !error && 'none',
        marginTop: '6px',
        position: 'absolute',
        width: 'max-content',
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
    }
  },
  sizes: {
    md: props => ({
      gwrapper: {
        minHeight: props.fixedHeight ? 'auto' : 'unset',
      },
      wrapper: {
        padding: '9px 12px 11px 12px',
        height: '40px',
      },
    }),
    sm: props => ({
      gwrapper: {
        minHeight: props.fixedHeight ? 'auto' : 'unset',
      },
      wrapper: {
        padding: '5px 10px 7px 12px',
        height: '32px',
      },
    }),
  },
  variants: {
    textarea: {
      wrapper: {
        height: 'auto',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
}

export default AbstractInputStyles
