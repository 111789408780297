import React, { memo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'

import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import { RpieFormData } from 'hooks/api/rpie/RpieFormData'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import SummaryTable from 'ui-framework/components/patterns/Wizard/SummaryTable'
import { RpieSummarySectionsConfig } from './RpieSummarySectionsConfig'

//===================================================
type Props = {} & BaseComponentProps & StepPropsInterface<RpieFormData>

/**
 * StepSummary component
 */
const StepSummary = (props: Props) => {
  const { serviceData, context, changeStep } = useServiceWizard()
  return (
    <SummaryTable
      {...props}
      data={serviceData}
      context={context}
      sectionsConfig={RpieSummarySectionsConfig}
      changeStep={changeStep}
    />
  )
}

export default memo(StepSummary)
