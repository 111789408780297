import { useEffect, useState } from 'react'
import useQueryParamState from './useQueryParamState'
import firebaseApp from 'helpers/firebaseInit'
import { UserProfile } from './useAuth'
import { FunctionNames } from 'constants/dicti'

type UseInviteResult = {
  invite: string | null
  profile: (UserProfile & { hasCreds: boolean }) | undefined
  isPending: boolean
}

function useInvite(): UseInviteResult {
  const [invite] = useQueryParamState('invite', null)
  const [profile, profileSet] = useState(),
    [isPending, isPendingSet] = useState<boolean>(Boolean(invite))

  useEffect(() => {
    const getProfileDataByInvite = firebaseApp
      .functions()
      .httpsCallable(FunctionNames.GET_PROFILE_DATA_BY_INVITE)
    if (invite)
      getProfileDataByInvite({
        invite: invite,
      })
        .then(({ data }) => {
          profileSet(data as any)
        })
        .catch(error => {
          // console.log(444, error.code, error.message, error.details)
          // if (code === 409) isUserExistSet(true)

          //TODO: decide whether we need this console:
          // eslint-disable-next-line no-console
          console.error('Invite processing failed!', error.code, error.message)
        })
        .finally(() => {
          isPendingSet(false)
        })
  }, [invite])

  return {
    invite,
    profile,
    isPending,
  }
}

export default useInvite
