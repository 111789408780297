import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

import firebase from 'firebase/compat/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASHUREMENT_ID,
}

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig)

// firebaseApp.firestore().enablePersistence()
firebaseApp.auth().setPersistence('local')
firebaseApp.storage().setMaxUploadRetryTime(10000)

firebase.analytics.isSupported().then(available => {
  if (available) {
    firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
    firebaseApp.analytics().logEvent('loaded')
  }
})

const emulators = process.env.REACT_APP_FIREBASE_EMULATOR?.split(',') || [],
  all = emulators.includes('all'),
  isEnabled = emulator => all || emulators.includes(emulator)

if (emulators.length > 0) {
  //TODO: decide whether we need this console:
  // eslint-disable-next-line no-console
  console.warn('!!! EMULATOR RUNNING')

  isEnabled('auth') && firebaseApp.auth().useEmulator('http://localhost:9099')
  isEnabled('functions') &&
    firebaseApp.functions().useEmulator('localhost', 5001)
  isEnabled('firestore') &&
    firebaseApp.firestore().useEmulator('localhost', 8080)
  isEnabled('storage') && firebaseApp.storage().useEmulator('localhost', 8081)
}

export default firebaseApp
export { firebase }
