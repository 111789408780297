import { ComponentStyleConfig } from '@chakra-ui/theme'

const AvatarStyle: ComponentStyleConfig = {
  parts: ['image'],
  baseStyle: props => ({
    image: {
      '&.chakra-avatar': {
        border: `${!props.admin ? '0' : '2'}px solid`,
        borderColor: 'red.base',
      },
    },
  }),
  sizes: {
    sm: props => ({}),
    md: props => ({
      image: {
        '&.chakra-avatar': {
          w: '36px',
          h: '36px',
        },
      },
    }),
  },
  variants: {},
  defaultProps: {
    size: 'md',
  },
}

export default AvatarStyle
