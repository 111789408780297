import React from 'react'
import { getFiscalPeriods } from 'helpers/utils'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import BaseComponentProps from 'common/BaseComponentProps'

//===================================================
type HelpApplicantStepCommercialDescriptionProps = {} & BaseComponentProps

/**
 * HelpApplicantStepCommercialDescription component
 */
const HelpApplicantStepCommercialDescription = (
  props: HelpApplicantStepCommercialDescriptionProps
) => {
  const { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context)

  return (
    <>
      <p>
        You have previously indicated that the property was at least partially
        occupied by an owner/related party.
      </p>
      <p>
        Provide floor area occupied by an owner / related party per each
        applicable use, as of January 5, {fiscalYear}.
      </p>
    </>
  )
}

export default HelpApplicantStepCommercialDescription
