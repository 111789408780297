import React, { useMemo } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import MonthViewItem, { MonthViewItemProps } from './MonthViewItem'
import { Box } from '@chakra-ui/react'
import { useCalendarStyles } from 'ui-framework/components/primitives/Calendar'

//===================================================

type Props = {
  day?: number
} & BaseComponentProps &
  MonthViewItemProps

/**
 * WeekDay component
 */
const WeekDay = ({ day = 0, ...props }: Props) => {
  const weekDay = useMemo(
      () => ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][day],
      [day]
    ),
    style = useCalendarStyles()
  return (
    <MonthViewItem className={props.className}>
      <Box sx={style.weekdayWrapper}>{weekDay}</Box>
    </MonthViewItem>
  )
}

export default WeekDay
