import dayjs from 'dayjs'
import { uiDateShortFormat } from 'helpers/formats'

function template(strings, ...keys) {
  return (...values) => {
    const dict = values[values.length - 1] || {}
    const result = [strings[0]]
    keys.forEach((key, i) => {
      const value = Number.isInteger(key) ? values[key] : dict[key]
      result.push(value, strings[i + 1])
    })
    return result.join('')
  }
}

const getFormattedDate = (date, format) =>
  dayjs(typeof date === 'string' ? date.replace('Z', '') : date).format(format)

const getForamttedDateRangeBase =
    tmplt =>
    (startDate, endDate, format = uiDateShortFormat) =>
      tmplt({
        from: getFormattedDate(startDate, format),
        to: getFormattedDate(endDate, format),
      }),
  templateDash = template`${'from'} - ${'to'}`,
  templateFromTo = template`From ${'from'} to ${'to'}`,
  getForamttedDateRangeDash = getForamttedDateRangeBase(templateDash),
  getForamttedDateRange = getForamttedDateRangeDash,
  getForamttedDateRangeFromTo = getForamttedDateRangeBase(templateFromTo)

const repYearDescrReal = data => {
  const rp = data.income.reportingPeriod || data.income.reportingPertiod

  return getForamttedDateRange(rp.startDate, rp.endDate)
}

const asOfJanuary = (fiscalYear, janDay = 5) =>
  `As of Jan ${janDay}, ${fiscalYear}`

const rangeOfYear = year =>
  getForamttedDateRange(new Date(year, 0, 1), new Date(year, 11, 31))

export {
  repYearDescrReal,
  asOfJanuary,
  rangeOfYear,
  getFormattedDate,
  getForamttedDateRange,
  getForamttedDateRangeDash,
  getForamttedDateRangeFromTo,
}
