import StepOne from './assets/1.png'
import StepTwo from './assets/2.png'
import StepThree from './assets/3.png'
import StepFour from './assets/4.png'
import StepFive from './assets/5.png'
import StepSix from './assets/6.png'

type TAOnboardingStepsObject = {
  title: string
  text?: string
  imageSrc: string
}

export const TAOnboardingSteps = (appconf): Array<TAOnboardingStepsObject> => [
  {
    title: 'Step 1.',
    text: 'Review and confirm property description (uses, floor area, square footage, etc).',
    imageSrc: StepOne,
  },
  {
    title: 'Step 2.',
    // text: 'Tell us about major recent activity (COVID-19 impact, significant improvements, vacancies, owner-occupancy, etc).',
    text: 'Tell us about major recent activity (significant improvements, vacancies, owner-occupancy, etc).',
    imageSrc: StepTwo,
  },
  {
    title: 'Step 3.',
    text: 'Answer questions about occupancy status and rent-roll.',
    imageSrc: StepThree,
  },
  {
    title: 'Step 4.',
    text: `Provide income and expense info (if your rental property produced any income in ${appconf.fiscalYear - 1
      }). At your request, this section may be completed by your accountant.`,
    imageSrc: StepFour,
  },
  {
    title: 'Step 5.',
    text: 'Provide substantiation or additional documentation (if requested).',
    imageSrc: StepFive,
  },
  {
    title: 'Need Help?',
    imageSrc: StepSix,
  },
]
