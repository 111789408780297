import { Services } from 'constants/dicti'
import { URL_SERVICERPIELIST } from 'constants/URLS'
import { CalendarConfig, makeItemsForKey } from 'helpers/config/utils'

const actionText = 'RPIE Service will be Available on March 1'
const actionTextFn = agreement =>
  agreement ? 'Go To My Property List' : 'Subscribe to RPIE Service'

const fallbackUrl = URL_SERVICERPIELIST

const config: CalendarConfig = {
  '2023-12-01': {
    actionText,
    fallbackUrl: null,
  },
  '2024-03-01': {
    actionText: actionTextFn,
    fallbackUrl,
  },
  '2024-07-01': {
    actionText,
    fallbackUrl: null,
  },
},
  configHomePageRPIEActions = makeItemsForKey<Services.RPIE, CalendarConfig>(
    Services.RPIE,
    config
  )

export { configHomePageRPIEActions }
