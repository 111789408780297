import styled from '@emotion/styled/macro'

export type CellVerticalAlign =
  | 'baseline'
  | 'sub'
  | 'super'
  | 'text-top'
  | 'text-bottom'
  | 'middle'
  | 'top'
  | 'bottom'
  | number

const TableCell = styled.td<any>()
export default TableCell
