import { useLayoutEffect, useState, useRef } from 'react'
import { nanoid } from 'nanoid'
import useMutationObserver from 'hooks/useMutationObserver'

function useArrowHook(focusedRef, help) {
  const [trigger, triggerSet] = useState<string>()
  const [focusedName, setFocusedName] = useState<string>()
  const documentRef = useRef(document.body)

  const update = () => {
    triggerSet(nanoid())
  }

  useMutationObserver(documentRef, update)

  useLayoutEffect(() => {
    const sfn = e => {
      const name = e.target && (e.target.dataset.arrowName || e.target['name'])
      focusedRef.current = e.target
      setFocusedName(name)
    }
    const sfu = e => {
      focusedRef.current = null
      setFocusedName(undefined)
    }

    const elements: Array<any> = []
    if (help) {
      Object.keys(help)
        .filter(h => h !== '$step' && h !== '$next')
        .forEach(h => {
          let els = document.querySelectorAll(`[data-arrow-name="${h}"]`)
          // if (!els.length) els = document.querySelectorAll(`[name="${h}"]`)
          els.forEach(el => {
            if (el) {
              elements.push(el)
              el.addEventListener('mouseenter', sfn)
              el.addEventListener('mouseleave', sfu)
            }
          })
        })
    }

    return () => {
      elements.forEach(el => {
        el.removeEventListener('mouseenter', sfn)
        el.removeEventListener('mouseleave', sfu)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [help, trigger])

  return [focusedName]
}

export default useArrowHook
