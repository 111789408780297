import React from 'react'
import SomethingWentWrongImage from './somethingWentWrongBg.png'
import { Image, VStack } from '@chakra-ui/react'
import TextFromParams from 'ui-framework/components/primitives/TextFromParams'
import Button from 'ui-framework/components/primitives/Button'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

/**
 * SomethingWentWrongPage component
 */
const SomethingWentWrongPage = props => {
  return (
    <div className={props.className}>
      <VStack w="100%" spacing={'40px'} mt={`120px`}>
        <Image src={SomethingWentWrongImage} />

        <div style={{ width: '340px' }}>
          <TextFromParams
            params={{
              lineHeight: '24px',
              fontSize: '22px',
              textStyle: 'body.semibold',
            }}
          >
            Something went wrong
          </TextFromParams>

          <TextFromParams params={{ lineHeight: '25px', m: '4px 0 24px' }}>
            We're having an issue at the moment.
            <br />
            We'll have it fixed in no time!
          </TextFromParams>

          <div style={{ display: 'flex' }}>
            <Button
              variant={'secondary'}
              width={'129px'}
              margin={'0 20px 0 0'}
              to={`mailto:${SUPPORT_CONTACTS.contactUsEmail}`}
            >
              Contact Us
            </Button>

            <Button onClick={() => window.location.reload()}>
              Try to reload
            </Button>
          </div>
        </div>
      </VStack>
    </div>
  )
}

export default SomethingWentWrongPage
