import React, { ReactNode } from 'react'
import useInvite from 'hooks/useInvite'
import Loader from 'ui-framework/components/primitives/Loader'
import { URL_AUTH_LOGIN, URL_AUTH_REGISTRATION } from 'constants/URLS'
import { Resource, Action } from 'constants/grants'
import useRouteByPermission from './useRouteByPermission'
import MyApplicationsProvider from 'hooks/api/my/MyApplicationsContext'
import MyDocumentsProvider from 'hooks/api/my/MyDocumentsContext'
import useAuth from 'hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

export type RequireAuthProps = {
  resource?: Resource
  action?: Action
  children: ReactNode
}

function RequireAuth({
  resource = Resource.PUBLIC,
  action = Action.READ,
  ...props
}: RequireAuthProps) {
  const { isLogged } = useAuth(),
    { invite, isPending: isPendingInvite, profile } = useInvite(),
    { pathname } = useLocation(),
    checkRouteByPermission = useRouteByPermission({ resource, action }),
    AuthComponent = !isLogged
      ? (localStorage.setItem('desired_url', pathname),
        invite ? (
          isPendingInvite ? (
            <Loader />
          ) : profile?.hasCreds ? (
            <Navigate to={URL_AUTH_LOGIN} replace />
          ) : (
            <Navigate to={URL_AUTH_REGISTRATION} replace />
          )
        ) : (
          <Navigate to={URL_AUTH_LOGIN} replace />
        ))
      : checkRouteByPermission(props.children)

  return (
    <MyApplicationsProvider>
      <MyDocumentsProvider>{AuthComponent}</MyDocumentsProvider>
    </MyApplicationsProvider>
  )
}

export default RequireAuth
