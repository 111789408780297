import { ComponentStyleConfig } from '@chakra-ui/react'
import { getTabFocusSelectors } from 'helpers/utils'

const OnboardingDialogStyle: ComponentStyleConfig = {
  parts: ['wrapper', 'showBtn'],
  baseStyle: props => ({
    wrapper: {
      borderRadius: '16px',
      overflow: 'hidden',
    },
    showBtn: {
      ml: `12px`,
      w: `32px`,
      h: `32px`,
      border: `1px solid`,
      borderRadius: `50%`,
      borderColor: 'secondary.divider',
      ...getTabFocusSelectors({
        borderRadius: '50%',
      }),
      '.chakra-stack': {
        m: 'auto',
        w: '16px',
      },
      '.icon': {
        svg: {
          fill: 'fontandicongray',
          width: '16px',
          height: '16px',
        },
      },
    },
  }),
  variants: {
    normal: props => ({}),
  },
  defaultProps: {
    variant: 'normal',
  },
}

export default OnboardingDialogStyle
