import { ApiObject } from 'helpers/apiHelper'
import { createContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useAppConfig, useCheckedContext } from 'hooks/useApplicationContext'
import useGetDocs, {
  Doc,
  DocFile,
  ResultDoc,
} from 'hooks/api/documents/useGetDocs'
import { useApplications } from 'hooks/api/firebase/useApplications'
import { Config } from 'helpers/config/utils'
import { DocumentStatus, DocumentType, DocumentTypeTC } from 'constants/dicti'
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { enumValues } from 'helpers/utils'

type MyDocuments = { all: ResultDoc[]; dc: ResultDoc[] }
type MyDocumentsState = ApiObject<MyDocuments>
const defaultState: MyDocumentsState = {
  error: null,
  isPending: true,
  data: {
    // all documents as is
    all: [],
    // documents filtered by appconfig settings
    dc: [],
  },
}

const MyDocumentsContext = createContext(defaultState)
const MyDocumentsDispatchContext = createContext({})

enum MyDocumentsActionKind {
  SET,
  SET_STATUS,
}

interface MyDocumentsAction {
  type: MyDocumentsActionKind
  payload: any
}

const myDocumentsReducer = (
  state: MyDocumentsState,
  action: MyDocumentsAction
) => {
  switch (action.type) {
    case MyDocumentsActionKind.SET: {
      return { ...state, data: action.payload }
    }
    case MyDocumentsActionKind.SET_STATUS: {
      return { ...state, isPending: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const PRECONF_FILTER_DC_TCFORMSONLY = 'PRECONF_FILTER_DC_TCFORMSONLY',
  PRECONF_FILTER_DC_TC_NOTFILED = 'PRECONF_FILTER_DC_TC_NOTFILED',
  PRECONF_FILTER_DC_RPIECONFIRMONLY = 'PRECONF_FILTER_DC_RPIECONFIRMONLY'

export const filterByDocType = types => doc => types.includes(doc.typeCS),
  filterNotFilled = doc => !doc.isFiled,
  filterByPredefinedFilters = (config: Config) => doc => {
    switch (config.docCenterDefaultFilter) {
      case PRECONF_FILTER_DC_TCFORMSONLY:
        return filterByDocType(enumValues(DocumentTypeTC))(doc)
      case PRECONF_FILTER_DC_TC_NOTFILED:
        const filteredDocumentTypes = enumValues(DocumentTypeTC).filter(
          dt =>
            ![
              DocumentTypeTC.TC101,
              DocumentTypeTC.TC106,
              DocumentTypeTC.TC108,
              DocumentTypeTC.TC109,
            ].includes(dt)
        )
        return (
          filterByDocType(filteredDocumentTypes)(doc) && filterNotFilled(doc)
        )
      case PRECONF_FILTER_DC_RPIECONFIRMONLY:
        return filterByDocType([DocumentType.RPIEConfirmation])(doc)
      default:
        return true
    }
  }

const MyDocumentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(myDocumentsReducer, defaultState)
  const appconf = useAppConfig(),
    { data, isPending: isApplicationsPending } =
      useApplications()
      // appconf.serviceCS
  const { documents, isPending: isDocumentsPending } = useGetDocs(
      data || undefined
    ),
    documentsFiltered = useMemo(() => {
      const dx = documents || []
      return dx.map(doc => {
        return {
          ...doc,
          documents: doc.documents.filter(filterByPredefinedFilters(appconf)),
        }
      })
    }, [appconf, documents])

  useEffect(() => {
    dispatch({
      type: MyDocumentsActionKind.SET_STATUS,
      payload: isApplicationsPending || isDocumentsPending,
    })
  }, [isApplicationsPending, isDocumentsPending])

  useEffect(() => {
    dispatch({
      type: MyDocumentsActionKind.SET,
      payload: { all: documents, dc: documentsFiltered },
    })
  }, [documents, documentsFiltered])

  return (
    <MyDocumentsContext.Provider value={state}>
      <MyDocumentsDispatchContext.Provider value={dispatch}>
        {children}
      </MyDocumentsDispatchContext.Provider>
    </MyDocumentsContext.Provider>
  )
}

const useMyDocuments = (): MyDocumentsState =>
  useCheckedContext(MyDocumentsContext)

const useMyDocument = (
  contextRef: DocumentReference<DocumentData>,
  documentType: DocumentType
): Doc => {
  const {
    data: { all },
  } = useMyDocuments()

  const [files, filesSet] = useState<DocFile[]>([])

  const docRefConstructed = useMemo(
    () => doc(contextRef, 'documents', documentType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextRef.id, documentType]
  )

  const document = useMemo(() => {
    const result = all
      .find(a => a.parentId === contextRef.id)
      ?.documents.find(d => d.id === documentType) || {
      id: documentType,
      ref: docRefConstructed,
      typeCS: documentType,
      statusCS: DocumentStatus.Uploaded,
    }

    return { ...result, files } as Doc
  }, [all, contextRef.id, docRefConstructed, documentType, files])

  useEffect(() => {
    if (!document?.ref) return
    const filesRef = collection(document.ref, 'files'),
      q = query(filesRef, where('wasDeleted', '==', false)),
      unsubscribe = onSnapshot(q, fx => {
        const result: DocFile[] = []
        fx.forEach(file => {
          result.push({ id: file.id, ref: file.ref, ...file.data() } as DocFile)
        })

        filesSet(result)
      })

    return unsubscribe
  }, [document?.ref])

  return document
}

export default MyDocumentsProvider
export { useMyDocuments, useMyDocument }
