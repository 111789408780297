import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import firebaseApp from 'helpers/firebaseInit'
import { ApiObject } from 'helpers/apiHelper'
import {
  ActualAVType,
  PropertyType,
  RPIEStatuses,
  Services,
  SFApplicationStatus,
  TAStatuses,
} from 'constants/dicti'
import { APP_VERSION, log } from 'helpers/utils'
import useLocalObjectState from 'hooks/useLocalObjectState'
import {
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  updateDoc,
} from 'firebase/firestore'
import { useMyApplication } from 'hooks/api/my/MyApplicationsContext'
import { del, merge, set, wrap } from 'object-path-immutable'
import { OrNull } from 'services/common/useFormDataHook'
import { Application, ServiceMetaData } from 'hooks/api/firebase/useApplication'
import { nanoid } from 'nanoid'
import usePrevious from 'hooks/usePrevious'

type UseApplicationReducerResult<T, E> = [Application<T, E>, any, boolean]

const statuses = {
  [TAStatuses.New]: TAStatuses.InProgress,
  [RPIEStatuses.NotStarted]: RPIEStatuses.InProgress
}

// Определение типов для экшенов
export enum APP_ACTIONS {
  SET_APPLICATION_DATA = 'SET_APPLICATION_DATA',
  SET_SERVICE_DATA = 'SET_SERVICE_DATA',
  SET_SERVICE_METADATA = 'SET_SERVICE_METADATA',
  SET_STATUS = 'SET_STATUS',
  SAVE = 'SAVE',
}

type DefaultAction = {
  type: APP_ACTIONS;
  update?: boolean; // sets new stag
};

export type SaveAction = {
  type: APP_ACTIONS.SAVE;
  update: true;
};

export type SetApplicationDataAction<T> = DefaultAction & {
  type: APP_ACTIONS.SET_APPLICATION_DATA;
  application: T;
};

export type SetServiceDataAction<T> = DefaultAction & {
  type: APP_ACTIONS.SET_SERVICE_DATA;
  serviceData: T;
};

export type SetServiceMetaDataAction<T> = DefaultAction & {
  type: APP_ACTIONS.SET_SERVICE_METADATA;
  serviceMetaData: ServiceMetaData<T>;
};

export type SetStatusAction = DefaultAction & {
  type: APP_ACTIONS.SET_STATUS;
  statusCS: string;
};

export type Actions<T, E> =
  SetApplicationDataAction<T> |
  SetServiceDataAction<T> |
  SetServiceMetaDataAction<E> |
  SetStatusAction |
  SaveAction;

const applicationDataReducer = <T, E = {}>(data, action: Actions<T, E>) => {

  const wdata = wrap(data)

  if (action.update) {
    log('Reducer update')
    wdata.set('stag', nanoid())
  }

  switch (action.type) {
    case APP_ACTIONS.SET_APPLICATION_DATA:
      return wrap(action.application).value()
    case APP_ACTIONS.SET_SERVICE_DATA:
      log('Reducer set serviceData', action.serviceData)
      return wdata
        .set('serviceData', action.serviceData)
        .value()
    case APP_ACTIONS.SET_SERVICE_METADATA:
      log('Reducer set service metadata', action.serviceMetaData)
      return wdata
        .set('serviceMetaData.buildVersion', APP_VERSION)
        .set('serviceMetaData.userAgent', navigator.userAgent)
        .merge('serviceMetaData', action.serviceMetaData)
        .value()
    case APP_ACTIONS.SET_STATUS:
      log('Reducer set status', action.statusCS)
      return wdata
        .set('statusCS', action.statusCS)
        .value()
    case APP_ACTIONS.SAVE:
      const s = statuses[data.statusCS]
      if (s) wdata.set('statusCS', s)
      const result = wdata.value()
      log('Reducer SAVE', result)
      return result
    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`)
  }
}

const useApplicationReducer = <T, E>(
  remoteData: Application<T> | undefined
): UseApplicationReducerResult<T, E> => {
  const [stags, stagsSet] = useState<string[]>([])
  const [data, dispatch] = useReducer(applicationDataReducer, remoteData)

  useEffect(() => {
    if (!data && remoteData) {
      dispatch({ type: APP_ACTIONS.SET_APPLICATION_DATA, application: remoteData })
    }
  }, [remoteData])

  useEffect(() => {
    if (!data) return
    if (stags.includes(data?.stag)) return

    stagsSet([...stags, data?.stag])
  }, [data?.stag])

  // if remoteData is changed outside set flag to true
  const updatedOutside = useMemo(() =>
    !!remoteData && !!data &&
    !stags.includes(remoteData?.stag)
    , [remoteData, stags])

  return [data, dispatch, updatedOutside]
}

export default useApplicationReducer
