import React, { useMemo, useRef } from 'react'

import { chakra } from '@chakra-ui/react'
import AttentionBox from '../../primitives/AttentionBox'
import Checkbox from '../../primitives/Checkbox'
import createPersistedState from 'use-persisted-state'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import { get } from 'object-path-immutable'
import { ChakraProps } from '@chakra-ui/react'

const useAttentionShow = createPersistedState(
  'reviewLastYearDataWarningBlock_block_shown'
)

const ReviewLastYearDataWarningBlock = (props: ChakraProps) => {
  const { context } = useServiceWizard()
  const isNewPropertyForThisYear = useMemo(
    () => get(context, 'defaultServiceData.general.isNewPropertyForThisYear'),
    [context]
  )

  const [attentionShow, attentionShowSet] = useAttentionShow<boolean>(
    !isNewPropertyForThisYear
  )
  const { current: show } = useRef<boolean>(attentionShow)

  return (
    <>
      {show && (
        <AttentionBox w={'560px'} title={null} variant="warning" {...props}>
          Review the data provided last year and update as necessary.
          <Checkbox
            mt="16px"
            checked={!attentionShow}
            onChange={v => attentionShowSet(!v)}
          >
            Do not show me this message again
          </Checkbox>
        </AttentionBox>
      )}
    </>
  )
}

export default chakra(ReviewLastYearDataWarningBlock)
