import React from 'react'
import styled from '@emotion/styled/macro'
import BaseComponentProps from 'common/BaseComponentProps'
import UploadFileRow from '../UploadFileRow'
import { getCallback } from 'helpers/utils'
import { OnChangeHandler } from 'ui-framework/common/types'

import Table from '../Table'
import { TableHeaderItems } from '../Table/TableHeaders'
import { Files, UploadFiles } from 'hooks/api/documents/useDocumentFiles'

//===================================================
type FileListWrapperProps = {}
const FileListWrapper = styled('div')<FileListWrapperProps>(props => ({
  marginTop: '40px',
}))

type Props = {
  files: Files
  hideState?: boolean
  disabled?: boolean
  onDownload?: OnChangeHandler<any>
  onDelete?: OnChangeHandler<UploadFiles>
  onRetry?: OnChangeHandler<UploadFiles>
} & BaseComponentProps

/**
 * FileList component
 */

const FileList = ({
  files,
  hideState = false,
  disabled = false,
  onDownload,
  onDelete,
  onRetry,
  ...props
}: Props) => {
  const onHandleDelete = file => {
    if (!disabled) {
      getCallback(onDelete)(file)
    }
  }
  const onHandleDownload = file => getCallback(onDownload)(file)
  const onHandleRetry = file => getCallback(onRetry)(file)

  const tableHeaders: TableHeaderItems = [
    { id: 'fn', text: 'File name', width: '52%' },
    { id: 'sts', text: 'Status', width: '26%' },
    { id: 'ow', text: '', width: '26%' },
  ]

  return (
    <FileListWrapper className={props.className}>
      <Table headers={tableHeaders} items={files}>
        {file => (
          <UploadFileRow
            title={file.name || file.fileName}
            uploadProgress={
              file.uploadProgress ||
              (file.totalBytes && file.totalBytes > 0
                ? ((file.bytesTransferred || 0) * 100) / file.totalBytes
                : 100)
            }
            uploadState={file.state}
            hideState={hideState}
            onDownload={onDownload ? () => onHandleDownload(file) : undefined}
            onDelete={onDelete ? () => onHandleDelete(file) : undefined}
            onRetry={onRetry ? () => onHandleRetry(file) : undefined}
          />
        )}
      </Table>
    </FileListWrapper>
  )
}

export default FileList
