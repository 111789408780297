import { ReactChild } from 'react'
import { ChakraProps, Tooltip, Center, TooltipProps } from '@chakra-ui/react'
import Icon from 'ui-framework/components/primitives/Icon'
import BaseComponentProps from 'common/BaseComponentProps'

//========================================================================

type InformationTooltipWithLabelProps = {
  infoMessage: ReactChild
  iconWrapperParams?: ChakraProps
  tooltipParams?: TooltipProps
} & BaseComponentProps

const InformationTooltipWithLabel = ({
  infoMessage,
  iconWrapperParams,
  tooltipParams,
  children,
}: InformationTooltipWithLabelProps) => (
  <Tooltip
    isLazy
    label={infoMessage}
    bg={'white100'}
    color={'fontnavy'}
    textStyle={'caption.regular'}
    p={'8px'}
    borderRadius={'6px'}
    margin={'0 0 -8px 45px'}
    placement={'top'}
    {...tooltipParams}
  >
    <Center w={'100%'} {...iconWrapperParams}>
      {children}
      <Icon size={'md'}>info-circle</Icon>
    </Center>
  </Tooltip>
)

export default InformationTooltipWithLabel
