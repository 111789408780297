import React, { useMemo, ReactNode, useState, useRef, useEffect } from 'react'
import Tab from './Tab/Tab'

import {
  OnChangeHandler,
  ChakraComponentProps,
  OnClickHandler,
} from 'ui-framework/common/types'
import {
  useMultiStyleConfig,
  Box,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  PopoverBody,
} from '@chakra-ui/react'
import { getCallback, log } from 'helpers/utils'
import useWindowSize from 'hooks/useWindowSize'
import useResizeObserver from 'hooks/useResizeObserver'

// ============================================================
export type TabItem = {
  id: string
  title: string
  label?: string | number
  disabled?: boolean
  hidden?: boolean
}

export type TabsProps = {
  /**
   * tabs description
   */
  items: TabItem[]
  /**
   * active tab id
   */
  active: string
  /**
   * rounded or default tab type
   */
  variant?: string
  /**
   * on click handler
   */
  onClick?: OnChangeHandler<string>
} & ChakraComponentProps

const PopoverItemWrapper = chakra(Flex, {
  baseStyle: {
    height: '40px',
    padding: '8px 0 8px 0',
    borderBottom: '1px solid',
    borderColor: 'secondary.divider',
    '& > *': {
      opacity: '1 !important',
    },
  },
})

const refCallback = (tabPanel, hiddenTabIndexes, hiddenTabIndexesSet) => {
  if (!tabPanel && !tabPanel?.childNodes) return
  const getStyles = element => window.getComputedStyle(element),
    panelStyles = getStyles(tabPanel),
    panelWidth =
      tabPanel?.clientWidth - (parseFloat(panelStyles.paddingLeft) || 0),
    { hiddenIndexes } = Array.from(tabPanel?.childNodes).reduce(
      (acc: { [key: string]: any }, node: any, i) => {
        const styles = getStyles(node),
          stylesWidth =
            parseFloat(styles.marginLeft) +
            parseFloat(styles.marginRight) +
            parseFloat(styles.borderWidth)

        acc.totalNodesWidth -= (node?.clientWidth || 0) + stylesWidth

        acc.totalNodesWidth <= 0 && acc.hiddenIndexes.push(i)

        return acc
      },
      {
        totalNodesWidth: panelWidth as number,
        hiddenIndexes: [] as number[],
      }
    )

  hiddenIndexes?.length !== hiddenTabIndexes.length &&
    hiddenTabIndexesSet(hiddenIndexes)
}

const getItems = (
  visibleItem,
  windowWidth,
  active,
  hiddenTabIndexes,
  getHandler,
  variant
) =>
  visibleItem.reduce(
    (acc, item, i) => {
      const isTabHidden = hiddenTabIndexes.includes(i),
        createTab = (params = {}) => (
          <Tab
            {...item}
            key={item.id}
            active={active === item.id}
            variant={variant}
            onClick={() => getHandler(item.id, item.disabled)}
            {...params}
          />
        ),
        { tabs, popupItems } = acc

      tabs.push(
        createTab(
          isTabHidden && {
            onClick: undefined,
            cursor: 'default',
            opacity: 0,
            tabIndex: undefined,
          }
        )
      )

      isTabHidden &&
        popupItems.push(
          <PopoverItemWrapper key={item.id}>
            {createTab({ height: '32px' })}
          </PopoverItemWrapper>
        )
      return acc
    },
    {
      tabs: [] as ReactNode[],
      popupItems: [] as ReactNode[],
    }
  )

/**
 * Tabs component
 */
const Tabs = (props: TabsProps) => {
  const { items, active, variant, onClick, ...rest } = props,
    tabRef = useRef<HTMLDivElement>(null),
    visibleItem = useMemo(() => items.filter(x => !x.hidden), [items]),
    [hiddenTabIndexes, hiddenTabIndexesSet] = useState<number[]>([]),
    [windowWidth] = useWindowSize(),
    style = useMultiStyleConfig('MGNYTabs', props),
    getHandler = (
      tabId: number | string,
      disabled?: boolean
    ): OnClickHandler | undefined =>
      !disabled ? getCallback(onClick)(tabId) : undefined,
    { tabs, popupItems } = getItems(
      visibleItem,
      windowWidth,
      active,
      hiddenTabIndexes,
      getHandler,
      variant
    )

  const tabRect = useResizeObserver(tabRef)

  useEffect(() => {
    refCallback(tabRef.current, hiddenTabIndexes, hiddenTabIndexesSet)
  }, [tabRect?.width])






  return (
    <>
      <Box
        id="tabs-container"
        ref={tabRef}
        sx={style.wrapper}
        className={props.className}
        {...rest}
      >
        {tabs}
      </Box>
      {popupItems.length > 0 && (
        <Popover placement="bottom-start" closeOnBlur isLazy>
          {/* @ts-ignore */}
          <PopoverTrigger>
            <div>
              <Flex tabIndex={0} as="button" sx={style.moreButton}>
                ...
              </Flex>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody
              bg="white100"
              w="fit-content"
              p="0 16px"
              borderRadius="8px"
              mt="-20px"
            >
              {popupItems}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  )
}

export default chakra(Tabs)
