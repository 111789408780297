import { Text, useDisclosure, VStack } from '@chakra-ui/react'
import { AnyFunction, getCallback } from 'helpers/utils'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import ModalDialog from 'ui-framework/components/primitives/ModalDialog'
import Radiobutton from 'ui-framework/components/primitives/Radiobutton'
import SelectsGroup from 'ui-framework/components/primitives/SelectsGroup'

export type UseConcurrentEditModalResult = [
  modal: ReactNode,
  onOpen: (onApply: AnyFunction, onDiscard?: AnyFunction) => void
]

enum CONCURRENT_EDIT_MODE {
  APPLY,
  RESET,
}

const asFunction = fnValue => () => fnValue

const useConcurrentEditModal = (isFinished): UseConcurrentEditModalResult => {
  const concurrentEditModal = useDisclosure(),
    [onApply, onApplySet] = useState<AnyFunction>(),
    [onDiscard, onDiscardSet] = useState<AnyFunction>(),
    [mode, modeSet] = useState<CONCURRENT_EDIT_MODE>(
      CONCURRENT_EDIT_MODE.APPLY
    ),
    handleAction = useCallback(() => {
      mode === CONCURRENT_EDIT_MODE.APPLY
        ? getCallback(onApply)()
        : getCallback(onDiscard)()
      concurrentEditModal.onClose()
    }, [concurrentEditModal, mode, onApply, onDiscard]),
    onOpen = useCallback(
      (onApply: AnyFunction, onDiscard?: AnyFunction) => {
        onApplySet(asFunction(onApply))
        onDiscardSet(asFunction(onDiscard))
        concurrentEditModal.onOpen()
      },
      [concurrentEditModal]
    )

  useEffect(() => {
    if (isFinished) {
      modeSet(CONCURRENT_EDIT_MODE.RESET)
    }
  }, [isFinished])

  return [
    // eslint-disable-next-line react/jsx-key
    <ModalDialog
      isLazy
      isOpen={concurrentEditModal.isOpen}
      onClose={concurrentEditModal.onClose}
      title="Resolve Duplicate Session"
      size={'w580'}
      onAction={handleAction}
      actionText={`Continue`}
    >
      <VStack spacing="16px">
        <p>Another portal session is active on this property now!</p>
        <p>
          <SelectsGroup label="Would you like to:" variant="vertical">
            {!isFinished && (
              <Radiobutton
                value={mode}
                selected={CONCURRENT_EDIT_MODE.APPLY}
                onChange={modeSet}
              >
                Save changes I am presently working on{' '}
                <Text color="fontandicongray" as="span">
                  (Caution! Previous changes made by another user or in other
                  windows/tabs will be overwritten)
                </Text>
              </Radiobutton>
            )}

            <Radiobutton
              value={mode}
              selected={CONCURRENT_EDIT_MODE.RESET}
              onChange={modeSet}
            >
              Discard and revert{' '}
              <Text color="fontandicongray" as="span">
                (Your session will display the changes made in another
                concurrent session. Those change may have been made by other
                users or by you in another window or tab)
              </Text>
            </Radiobutton>
          </SelectsGroup>
        </p>
      </VStack>
    </ModalDialog>,
    onOpen,
  ]
}

export default useConcurrentEditModal
