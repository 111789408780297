import React from 'react'
import {
  useMultiStyleConfig,
  Image,
  Flex,
  Box,
  chakra,
  VStack,
  HStack,
  Divider,
} from '@chakra-ui/react'
import { ChakraComponentProps } from 'ui-framework/common/types'
import { AnyFunction } from 'helpers/utils'
import Button from 'ui-framework/components/primitives/Button'
import { TAOnboardingSteps } from './TAOnboardingSteps'
import { useAppConfig } from 'hooks/useApplicationContext'
import { SUPPORT_CONTACTS } from 'constants/CONSTANTS'

type TAOnboardingBodyProps = {
  onCloseHandle?: AnyFunction
} & ChakraComponentProps

const TAOnboardingBody = ({
  onCloseHandle,
  ...props
}: TAOnboardingBodyProps) => {
  const appconf = useAppConfig()
  const style = useMultiStyleConfig('MGNYTAOnboardingBody', props)

  const getStep = (title, text, img, showDivider, isContacts = false) => (
    <Box sx={style.step}>
      <Flex mb="20px">
        <p>
          {isContacts ? (
            <>
              <b>{title} </b>
              Reach us via&nbsp;
              {/* <chakra.a href="">online chat</chakra.a>, <br /> */}
              <chakra.a href="mailto:taxappeal@mgnyconsulting.com">
                taxappeal@mgnyconsulting.com
              </chakra.a>
              , or by
              <br />
              phone at &nbsp;
              <chakra.a href={`tel:${SUPPORT_CONTACTS.supportTel}`}>
                {SUPPORT_CONTACTS.supportTel}
              </chakra.a>.
            </>
          ) : (
            <>
              <b>{title} </b>
              {text}
            </>
          )}
        </p>
        <Image src={img} />
      </Flex>
      {showDivider && <Divider mt="auto" />}
    </Box>
  )

  return (
    <Flex sx={style.container}>
      <Box sx={style.header}>
        <Box sx={style.title}>Welcome to the MGNY Tax Appeal Portal!</Box>
        <Box sx={style.subTitle}>
          5 Steps to a Successful Tax Protest Application:
        </Box>
      </Box>

      <Box sx={style.content}>
        <VStack spacing="0">
          {TAOnboardingSteps(appconf).map(
            ({ title, text, imageSrc }, i, arr) => {
              if (i % 2 !== 0) return null

              const isLastRow = i === arr.length - 2
              const {
                title: nextTitle,
                text: nextText,
                imageSrc: nextImageSrc,
              } = arr[i + 1]

              return (
                <HStack spacing="40px" key={i}>
                  {getStep(title, text, imageSrc, !isLastRow)}
                  {getStep(
                    nextTitle,
                    nextText,
                    nextImageSrc,
                    !isLastRow,
                    isLastRow
                  )}
                </HStack>
              )
            }
          )}
        </VStack>
      </Box>

      <Box sx={style.footer}>
        <Button onClick={onCloseHandle} w={`400px`} h={'40px'}>
          Go to Tax Appeal Portal
        </Button>
      </Box>
    </Flex>
  )
}

export default chakra(TAOnboardingBody)
