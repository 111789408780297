import { getCallback, log } from 'helpers/utils';
import { isEqual } from 'lodash-es';
import { useEffect, MutableRefObject, useState, useLayoutEffect } from 'react'

type ResizeCallback = (resizeEntry: ResizeObserverEntry, observer: ResizeObserver) => void

function useResizeObserver(
  ref: MutableRefObject<HTMLElement | null>,
  callback?: ResizeCallback
): DOMRectReadOnly | undefined {
  const [entry, entrySet] = useState<DOMRectReadOnly>()


  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let _entry of entries) {
        if (_entry.target !== ref.current) continue
        const rect = _entry.contentRect;
        // console.log('New width:', entry.contentRect.width);
        getCallback(callback)(rect, resizeObserver);

        entrySet(prev => {
          // log('isEqual(entry, rect)', isEqual(entry, rect));
          return !isEqual(entry, rect) ? rect : prev
        });
      }
    });

    if (ref.current)
      resizeObserver.observe(ref.current);

    return () => {
      // log('useResizeObserver: unobserve');
      if (ref.current)
        resizeObserver.unobserve(ref.current);
    };
  }, [callback, ref])

  return entry
}

export default useResizeObserver
