import { useState } from 'react'
import useValidation from './useValidation'
import { AnyFunction, getCallback } from 'helpers/utils'
import Joi from 'joi'

type UseDoubleClickValidationCallbackResult<T> = [Partial<T>, AnyFunction]
type UseDoubleClickValidationCallback = <T>(
  value: T,
  schema: Joi.Schema<T>,
  callback: AnyFunction
) => UseDoubleClickValidationCallbackResult<T>

const empty = {}

const useDoubleClickValidationCallback: UseDoubleClickValidationCallback = (
  value,
  schema,
  callback
) => {
  const [validationErrors] = useValidation(value, schema),
    [showError, showErrorSet] = useState<boolean>(false),
    errors = showError ? validationErrors : empty,
    onValidation = () =>
      Object.keys(validationErrors).length
        ? showErrorSet(true)
        : getCallback(callback)(value)

  return [errors, onValidation]
}

export default useDoubleClickValidationCallback
