import React from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import IntervalBar from 'ui-framework/components/patterns/IntervalBar'
import { Occupant } from 'hooks/api/rpie/RpieFormData'
import { getDictiName } from 'constants/dicti'

//===================================================
type StorefrontUnitOccupantsIntervalBarProps = {
  occupants: Occupant[]
  occupantNumber: number
  reportingYear: number
} & BaseComponentProps

/**
 * StorefrontUnitOccupantsIntervalBar component
 */
const StorefrontUnitOccupantsIntervalBar = (
  props: StorefrontUnitOccupantsIntervalBarProps
) => {
  return (
    <IntervalBar
      className={props.className}
      items={props.occupants.map(i => ({
        ...i,
        label: getDictiName(i.occupantTypeCS),
      }))}
      active={props.occupantNumber}
      year={props.reportingYear}
    />
  )
}

export default StorefrontUnitOccupantsIntervalBar
