import React, { useMemo, useCallback, useEffect } from 'react'
import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import IntervalBar from 'ui-framework/components/patterns/IntervalBar'
import {
  OccupancyPeriod,
  Occupant,
  RpieFormData,
} from 'hooks/api/rpie/RpieFormData'
import { apiDateFormat, uiDateShortFormat } from 'helpers/formats'
import DropdownList from 'ui-framework/components/primitives/DropdownList'
import Button from 'ui-framework/components/primitives/Button'
import {
  getCallback,
  getFiscalPeriods,
  createItemsByDicti,
  getNameAndErrorProps,
  AnyFunction,
} from 'helpers/utils'
import { push, set } from 'object-path-immutable'
import dayjs from 'dayjs'
import DateInput from 'ui-framework/components/primitives/Calendar/DateInput'
import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { Box, Center, chakra, Flex } from '@chakra-ui/react'
import Table from 'ui-framework/components/patterns/Table'
import { TableHeaderItems } from 'ui-framework/components/patterns/Table/TableHeaders'
import Checkbox from 'ui-framework/components/primitives/Checkbox'
import Icon from 'ui-framework/components/primitives/Icon'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'
import {
  getDictiName,
  StorefrontLeaseConcessions,
  StorefrontUnitOccupancyType,
} from 'constants/dicti'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'

//===================================================
type Props = {
  unitNumber: number
  occupants: Occupant[]
} & BaseComponentProps &
  StepPropsInterface<RpieFormData>

/**
 * StepStorefrontUnitOccupancyIntervalsStep component
 */
const StepStorefrontUnitOccupancyIntervalsStep = ({
  unitNumber,
  occupants,
  disabled,
  applyValidation,
  onValidation,
  onChange,
  ...props
}: Props) => {
  const { context } = useServiceWizard(),
    { reportingYear } = getFiscalPeriods(context),
    validationData = useMemo(() => ({ occupants }), [occupants]),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    )

  const startDate = useMemo(
    () =>
      occupants.length === 0
        ? dayjs().year(reportingYear).startOf('year').format(apiDateFormat)
        : dayjs(occupants[occupants.length - 1].endDate)
          .add(1, 'day')
          .startOf('day')
          .format(apiDateFormat),
    [occupants, reportingYear]
  ),
    filled = useMemo(
      () => dayjs(startDate).isAfter(dayjs().year(reportingYear).endOf('year')),
      [startDate, reportingYear]
    ),
    handleClearIntervals = useCallback(() => {
      getCallback(onChange)(set({ occupants }, 'occupants', []))
    }, [occupants, onChange]),
    handleOnClick = useCallback(() => {
      const result = [...occupants, {
        occupantTypeCS: null,
        startDate: startDate,
        endDate: null,
        avgMonthlyRentPSF: null,
        grossRentAmount: null,
        baseRentAmount: null,
        businessName: null,
        noVacantType: {
          contactName: null,
          emailAddress: null,
          primaryPhone: null,
          alternativePhone: null,
          primaryBusinessActivityCS: null,
          otherDescriptionOfBA: null,
          existLeaseInformationCS: StorefrontLeaseConcessions.None,
          monthNumber: null,
          reducedRentAmount: null,
          improvementCashAmount: null,
          otherDescriptionOfConcession: null,
          isIncreaseThisYear: false,
          isIncreaseAfterThisYear: false,
          hasLeaseInformation: false,
          isContactInformationUnavailable: false,
        },
        vacantType: {
          hadConstruction: false,
          constructionJobs: [],
        },
      }]

      getCallback(onChange)(result, 'occupants')
    }, [occupants, onChange, startDate]),
    handleOnChange = useCallback(
      (index, formattedFn?: AnyFunction) => (value, name) => {
        getCallback(onChange)(
          set(
            { occupants },
            `occupants.${index}.${name}`,
            getCallback(formattedFn)(value)
          )
        )
      },
      [occupants, onChange]
    )

  const listOfOccupancyIntervals: TableHeaderItems = [
    { id: 'ot', text: 'Occupancy Type', width: '25%' },
    {
      id: 'v',
      text: 'Construction Activities',
      width: '25%',
      align: 'center',
    },
    { id: 'st', text: 'Start Date', width: '25%' },
    { id: 'ed', text: 'End Date', width: '25%' },
  ],
    intervals = useMemo(() => {
      const currentIntervals = occupants.map(op => ({
        startDate: op.startDate,
        endDate: op.endDate,
        label: op.occupantTypeCS
          ? getDictiName(op.occupantTypeCS).toLowerCase()
          : '',
        empty: false,
      })),
        hasEndDate =
          currentIntervals.length === 0 ||
          (!!currentIntervals[currentIntervals.length - 1].endDate &&
            !!currentIntervals[currentIntervals.length - 1].label)

      return !filled && hasEndDate
        ? [
          ...currentIntervals,
          {
            startDate: dayjs(startDate).format(apiDateFormat),
            endDate: null,
            label: null,
            empty: true,
          },
        ]
        : currentIntervals
    }, [filled, occupants, startDate]),
    isLastRowValid = useMemo(
      () =>
        intervals.length === 0 ||
        intervals[intervals.length - 1].empty ||
        (!!intervals[intervals.length - 1].endDate &&
          !!intervals[intervals.length - 1].label),
      [intervals]
    ),
    hasValue = useMemo(() => {
      const isFirst = Boolean(intervals.length - 1)
      return !isLastRowValid && !isFirst
    }, [isLastRowValid, intervals]),
    mappedItems = useMemo(
      () => createItemsByDicti(StorefrontUnitOccupancyType),
      []
    )


  if (occupants.length === 0) handleOnClick()

  const actions = (
    <chakra.tr marginTop="12px">
      <td colSpan={3}>
        {!filled && (
          <Button
            size="sm"
            name="confirmRange"
            variant="textPrimary"
            padding="0"
            disabled={!isLastRowValid}
            onClick={handleOnClick}
          >
            Add new interval
          </Button>
        )}
      </td>
      <td colSpan={1} align="right">
        <Button
          size="sm"
          onClick={handleClearIntervals}
          variant="textPrimary"
          padding="0"
          disabled={hasValue}
        >
          Clear all intervals
        </Button>
      </td>
    </chakra.tr>
  )
  const periodsCount = occupants.length - 1,
    handleDeletePeriod = item => {
      const periods = occupants.filter(el => el.startDate !== item.startDate)
      !filled && (periods[periods.length - 1].startDate = item.startDate)

      getCallback(onChange)(set({ occupants }, 'occupants', periods))
    }

  return (
    <StepBasic className={props.className}>
      <Box w="680px">
        <IntervalBar items={intervals} active={null} year={reportingYear} />

        <Box mt={'36px'}>
          <Table
            cellSidePaddings={'16px'}
            headers={listOfOccupancyIntervals}
            items={occupants}
            footer={!hasValue && actions}
          >
            {(item: Occupant, i) => {
              const nameAndErrorProps = name =>
                getNameAndErrorProps(name, hasError, `occupants.${i}`)

              return (
                <tr>
                  <td>
                    <DropdownList
                      tabIndex={0}
                      width="100%"
                      listMaxHeight={`145px`}
                      listWidth={`160px`}
                      size={`sm`}
                      value={item.occupantTypeCS}
                      items={mappedItems}
                      itemId="id"
                      itemValue="name"
                      disabled={disabled}
                      errorTooltip
                      onChange={handleOnChange(i)}
                      {...nameAndErrorProps('occupantTypeCS')}
                    />
                  </td>
                  <td>
                    {item.occupantTypeCS ===
                      StorefrontUnitOccupancyType.Vacant && (
                        <HelpTarget name="hadConstruction">
                          <Box
                            style={{ width: '100%', justifyContent: 'center' }}
                          >
                            <Center>
                              <Checkbox
                                checked={Boolean(item.vacantType.hadConstruction)}
                                name="vacantType.hadConstruction"
                                disabled={disabled}
                                onChange={handleOnChange(i)}
                              />
                            </Center>
                          </Box>
                        </HelpTarget>
                      )}
                  </td>
                  <td>{dayjs(item.startDate).format(uiDateShortFormat)}</td>
                  <td>
                    <Flex position="relative" align="center" width="100%">
                      <HelpTarget name="endDate">
                        <DateInput
                          width="100%"
                          size={`sm`}
                          value={item.endDate as any}
                          defaultDate={dayjs(item.startDate).toDate()}
                          min={dayjs(item.startDate).toDate()}
                          max={dayjs(item.startDate).endOf('year').toDate()}
                          placeholder="Select"
                          errorTooltip
                          disabled={
                            disabled || filled || i !== occupants.length - 1
                          }
                          onChange={handleOnChange(
                            i,
                            v =>
                              v && dayjs(v).endOf('day').format(apiDateFormat)
                          )}
                          {...nameAndErrorProps('endDate')}
                        />
                      </HelpTarget>
                      {/* // TODO: Attention! Weird logic here! */}
                      {i > 0 &&
                        ((periodsCount === i + 1 && !filled) ||
                          (filled && i === periodsCount)) && (
                          <Box position="absolute" right={'-24px'}>
                            <Icon
                              size="sm"
                              color="fontandicongray"
                              onClick={() => handleDeletePeriod(item)}
                              tabIndex={0}
                            >
                              close
                            </Icon>
                          </Box>
                        )}
                    </Flex>
                  </td>
                </tr>
              )
            }}
          </Table>
        </Box>
      </Box>
    </StepBasic>
  )
}

export default StepStorefrontUnitOccupancyIntervalsStep
