import React, { useMemo } from 'react'

import BaseComponentProps from 'common/BaseComponentProps'
import { StepPropsInterface } from 'hooks/wizard/useStepsConfig'
import StepBasic from 'ui-framework/components/patterns/Wizard/StepBasic'
import Input from 'ui-framework/components/primitives/Input'
import { apiDateFormat, integerPositiveThousands } from 'helpers/formats'

import HelpTarget from 'ui-framework/components/patterns/Wizard/HelpTarget'
import { TaFormData } from 'hooks/api/ta/TaFormData'
import { VStack, HStack, Text } from '@chakra-ui/react'
import DateInput from 'ui-framework/components/primitives/Calendar/DateInput'
import Textarea from 'ui-framework/components/primitives/Textarea'
import { getCallback, getFiscalPeriods, getNameAndErrorProps } from 'helpers/utils'
import dayjs from 'dayjs'
import ReviewLastYearDataWarningBlock from 'ui-framework/components/patterns/ReviewLastYearDataWarningBlock'
import useStepValidation from 'ui-framework/components/patterns/Wizard/useStepValidation'
import schema from './schema'
import NullableMaskedNumber from 'ui-framework/components/primitives/Input/NullableMaskedNumber'
import { useServiceWizard } from 'ui-framework/components/patterns/Wizard/ServiceWizardProvider'

//==================================================

type StepPropertyDetailsConstructionProps = {
  startConstructionDate?: Date | null
  completionDate?: Date | null
  workDescription?: string
  floorAreaNewSpace?: number
  percentWorkCompleted?: number
  floorAreaRenovatedSpace?: number
} & BaseComponentProps &
  StepPropsInterface<TaFormData>

/**
 * StepPropertyDetailsConstruction component
 */
const StepPropertyDetailsConstruction = ({
  startConstructionDate,
  completionDate,
  workDescription,
  floorAreaNewSpace,
  floorAreaRenovatedSpace,
  percentWorkCompleted,
  applyValidation,
  onValidation,
  disabled,
  onChange,
  ...props
}: StepPropertyDetailsConstructionProps) => {
  const handleDateChange = (value: Date, name?: string) => {
    if (!name) return
    getCallback(onChange)({
      [name]: dayjs(value).format(apiDateFormat),
    })
  },
    { context } = useServiceWizard(),
    { fiscalYear } = getFiscalPeriods(context),
    currentYear = new Date().getFullYear(),
    startOfYear = new Date(currentYear, 0),
    validationData = useMemo(
      () => ({
        workDescription,
        startConstructionDate,
        completionDate,
        floorAreaNewSpace,
        floorAreaRenovatedSpace,
        percentWorkCompleted,
      }),
      [
        workDescription,
        startConstructionDate,
        completionDate,
        floorAreaNewSpace,
        floorAreaRenovatedSpace,
        percentWorkCompleted,
      ]
    ),
    hasError = useStepValidation(
      validationData,
      schema,
      applyValidation,
      onValidation
    ),
    nameAndErrorProps = name => getNameAndErrorProps(name, hasError)

  return (
    <StepBasic className={props.className}>
      <VStack width="560px" spacing="36px" align="left">
        <ReviewLastYearDataWarningBlock />

        <HStack spacing="36px">
          <HelpTarget name="startConstructionDate">
            <DateInput
              label="Start Date"
              placeholder={'mm/dd/yyyy'}
              value={startConstructionDate}
              defaultDate={startConstructionDate || startOfYear}
              disabled={disabled}
              onChange={handleDateChange}
              width={'262px'}
              {...nameAndErrorProps('startConstructionDate')}
            />
          </HelpTarget>

          <HelpTarget name="completionDate">
            <DateInput
              label="Actual or Projected Completion Date"
              placeholder={'mm/dd/yyyy'}
              value={completionDate}
              min={dayjs(startConstructionDate || startOfYear)
                .add(6, 'months')
                .toDate()}
              disabled={disabled}
              onChange={handleDateChange}
              {...nameAndErrorProps('completionDate')}
            />
          </HelpTarget>
        </HStack>

        <HelpTarget name="workDescription">
          <Textarea
            label={
              <Text textStyle="body.regular" lineHeight="25px">
                Describe the full scope of existing or planned construction,
                demolition or major
                <br />
                alteration.
              </Text>
            }
            value={workDescription}
            placeholder="Enter"
            disabled={disabled}
            width={'100%'}
            rows={5}
            showCounter={false}
            onChange={onChange}
            {...nameAndErrorProps('workDescription')}
          />
        </HelpTarget>

        <HStack spacing={'36px'}>
          <HelpTarget name="floorAreaNewSpace">
            <Input
              width={'262px'}
              label="New Floor Area, SF"
              value={floorAreaNewSpace}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('floorAreaNewSpace')}
            />
          </HelpTarget>
          <HelpTarget name="floorAreaRenovatedSpace">
            <Input
              width={'262px'}
              label="Renovated Floor Area, SF"
              value={floorAreaRenovatedSpace}
              type="number"
              format={integerPositiveThousands}
              disabled={disabled}
              onChange={onChange}
              {...nameAndErrorProps('floorAreaRenovatedSpace')}
            />
          </HelpTarget>
        </HStack>

        <HelpTarget name="percentWorkCompleted">
          <Input
            width={'262px'}
            label="% of Completion"
            placeholder="%"
            value={percentWorkCompleted}
            type="number"
            format={
              new NullableMaskedNumber({
                ...integerPositiveThousands,
                min: 1,
                max: 100,
              })
            }
            disabled={disabled}
            onChange={onChange}
            {...nameAndErrorProps('percentWorkCompleted')}
          />
        </HelpTarget>
      </VStack>
    </StepBasic>
  )
}

export default StepPropertyDetailsConstruction
