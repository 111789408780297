import { ComponentStyleConfig } from '@chakra-ui/theme'
import { getTabFocusSelectors } from 'helpers/utils'
import { LoaderWrapper } from 'ui-framework/components/primitives/Loader'

const ButtonStyle: ComponentStyleConfig = {
  // Styles for the base style
  baseStyle: props => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: 6,
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'semibold',
    lineHeight: 'body',
    letterSpacing: 0,
    pointerEvents: props.disabled ? 'none' : 'auto',
    '.icon': {
      svg: {
        width: '12px',
        height: '12px',
      },
    },
    [LoaderWrapper as any]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: 6,
      border: '1px solid transparent',
    },
    _disabled: {
      cursor: 'not-allowed',
      color: 'white',
      backgroundColor: 'primary.disabled',
      [LoaderWrapper as any]: {
        backgroundColor: 'primary.disabled',
      },
      'svg, svg *': {
        fill: 'white',
      },
      _hover: {
        backgroundColor: 'primary.disabled',
        outlineColor: 'transparent',
      },
    },
  }),
  // Styles for the size variations
  sizes: {
    md: props => ({
      height: '40px',
      padding: '7px 0 9px 0',
      paddingLeft: !!props.prefix ? '12px' : '20px',
      paddingRight: !!props.postfix ? '12px' : '20px',
      [LoaderWrapper as any]: {
        svg: {
          height: '24px !important',
          width: '24px !important',
        },
      },
    }),
    sm: props => ({
      height: '32px',
      padding: '3px 0 5px 0',
      paddingLeft: !!props.prefix ? '10px' : '16px',
      paddingRight: !!props.postfix ? '10px' : '16px',
      [LoaderWrapper as any]: {
        svg: {
          height: '16px !important',
          width: '16px !important',
        },
      },
    }),
  },
  // Styles for the visual style variations
  variants: {
    primary: {
      color: 'white',
      backgroundColor: 'primary.base',
      [LoaderWrapper as any]: {
        backgroundColor: 'primary.base',
      },
      _hover: {
        backgroundColor: 'primary.hovered',
        [LoaderWrapper as any]: {
          backgroundColor: 'primary.hovered',
        },
      },
      ...getTabFocusSelectors({
        color: 'primary.base',
      }),
      _active: {
        backgroundColor: 'primary.pressed',
        [LoaderWrapper as any]: {
          backgroundColor: 'primary.pressed',
        },
      },
      'svg, svg *': {
        fill: 'white',
      },
    },
    secondary: {
      color: 'primary.base',
      backgroundColor: 'secondary.base',
      [LoaderWrapper as any]: {
        backgroundColor: 'secondary.base',
      },
      _hover: {
        outline: 'solid 1px',
        outlineColor: 'primary.base',
      },
      _active: {
        outline: 'solid 1px',
        outlineColor: 'primary.pressed',
        backgroundColor: 'secondary.sideMenu',
        [LoaderWrapper as any]: {
          backgroundColor: 'secondary.sideMenu',
        },
      },
      'svg, svg *': {
        fill: 'primary.base',
      },
    },
    textPrimary: {
      height: 'unset',
      color: 'primary.base',
      fontWeight: 'regular',
      fontSize: '15px',
      padding: '0 5px !important',
      _hover: {
        outlineColor: 'none',
        backgroundColor: 'none',
        color: 'primary.hovered',
        'svg, svg *': {
          fill: 'primary.hovered',
        },
      },
      _active: {
        outlineColor: 'none',
        backgroundColor: 'none',
        color: 'primary.pressed',
        'svg, svg *': {
          fill: 'primary.pressed',
        },
      },
      _disabled: {
        backgroundColor: 'none',
        color: 'primary.disabled',
        'svg, svg *': {
          fill: 'primary.disabled',
        },
        _hover: {
          backgroundColor: 'none',
        },
      },
      [LoaderWrapper as any]: {
        display: 'none',
      },
      'svg, svg *': {
        fill: 'primary.base',
      },
    },
    textSecondary: {
      height: 'unset',
      color: 'fontandicongray',
      fontWeight: 'regular',
      fontSize: '15px',
      padding: '0 5px !important',
      _hover: {
        outlineColor: 'none',
        backgroundColor: 'none',
        color: 'primary.hovered',
        'svg, svg *': {
          fill: 'primary.hovered',
        },
      },
      _active: {
        outlineColor: 'none',
        backgroundColor: 'none',
        color: 'primary.pressed',
        'svg, svg *': {
          fill: 'primary.pressed',
        },
      },
      _disabled: {
        backgroundColor: 'none',
        color: 'primary.disabled',
        'svg, svg *': {
          fill: 'primary.disabled',
        },
        _hover: {
          backgroundColor: 'none',
        },
      },
      'svg, svg *': {
        fill: 'fontandicongray',
      },
      [LoaderWrapper as any]: {
        display: 'none',
      },
    },
    warning: {
      color: 'white100',
      bgColor: 'red.base',
      padding: '9px 20px 11px',
      [LoaderWrapper as any]: {
        display: 'none',
      },
      _hover: {
        bgColor: '#E55442',
      },
      ...getTabFocusSelectors({
        color: 'primary.base',
      }),
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
}

export default ButtonStyle
