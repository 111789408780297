import Joi from 'joi'
import { schemaRules, schemaWrapper } from 'constants/schemaRules'

const schema = schemaWrapper({
  anySpaceRelatedToOperatorDescription: Joi.when(
    'isAnySpaceRelatedToOperator',
    {
      is: true,
      then: schemaRules.SOME_SYMBOL_STRING(3),
      otherwise: null,
    }
  ),
  wasIncomeFromRentalTenants: Joi.when('needWasIncomeFromRentalTenants', {
    is: true,
    then: schemaRules.BOOLEAN,
    otherwise: schemaRules.ANY,
  }),
})

export default schema
