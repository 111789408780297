import { chakra } from '@chakra-ui/react'
import { set } from 'object-path-immutable'
import { CellWithoutBorder } from 'pages/Services/Rpie/RpieFormPage/steps/income/StepExpenseTable'
import { useState, useCallback } from 'react'
import Icon from 'ui-framework/components/primitives/Icon'

//=======================================================================

const TwoBlockCol = chakra('div', {
    baseStyle: {
      display: 'grid',
      gridTemplateColumns: '410px minmax(240px, 417px)',
      columnGap: '79px',
    },
  }),
  Wrapper = chakra('div', {
    baseStyle: ({ active }: any) => ({
      display: 'flex',
      width: 'calc(100% - 20px)',
      alignItems: 'center',
      color: active ? 'primary.base' : 'inherit',
      div: { flexGrow: 0 },
      '.icon': {
        marginLeft: '8px',
        svg: {
          fill: active ? 'primary.base' : 'fontandicongray',
        },
      },
    }),
  })

const PromptWrapper = chakra('div', {
    baseStyle: {
      display: 'flex',
      flexDirection: 'column',
      mt: '12px',
    },
  }),
  PromptContent = chakra('span', {
    baseStyle: {
      color: 'fontnavy',
      textStyle: 'body.semibold',
      lineHeight: '25px',
    },
  })

const renderPrompt = item => (
    <CellWithoutBorder>
      <PromptWrapper>
        <PromptContent>{item.title}</PromptContent>
        <PromptContent textStyle="body.regular">
          {item.description}
        </PromptContent>
      </PromptWrapper>
    </CellWithoutBorder>
  ),
  createDescription = (item, colSpan) =>
    item &&
    !item.hidden && (
      <tr>
        <td style={{ paddingTop: 0 }} colSpan={colSpan - 2}>
          <PromptContent lineHeight="20px">
            Acceptable Expense Types
          </PromptContent>
          <TwoBlockCol>{item.content.map(renderPrompt)}</TwoBlockCol>
        </td>
        <CellWithoutBorder />
      </tr>
    )

const getPrompts = items =>
  items.reduce((res, s) => {
    s.prompts &&
      Object.entries(s.prompts).forEach(
        ([k, v]) =>
          (res[k] = {
            hidden: true,
            ...(v as object),
          })
      )

    return res
  }, {})

const useSectionPrompts = (sections: any[] = []) => {
  const [prompt, promptSet] = useState(getPrompts(sections))
  const togglePrompt = (item, key) => () =>
      promptSet(prevState => set(prevState, [key, 'hidden'], !item.hidden)),
    createViewForPrompt = useCallback(
      (key, value, colSpan) => {
        const item = prompt[key]
        // eslint-disable-next-line react/jsx-key
        if (!item || !key) return [<Wrapper>{value}</Wrapper>, null]
        return [
          //@ts-ignore
          <Wrapper key={key} active={!item.hidden} data-id="11">
            {value}
            <Icon onClick={togglePrompt(item, key)}>prompt-circle</Icon>
          </Wrapper>,
          createDescription(item, colSpan),
        ]
      },
      [prompt]
    )

  return createViewForPrompt
}

export default useSectionPrompts
