import { ComponentStyleConfig } from '@chakra-ui/theme'

const InformationDialogStyle: ComponentStyleConfig = {
  parts: ['modalContent', 'triggerWrapper', 'wrapper'],
  // Styles for the base style
  baseStyle: props => ({
    modalContent: {
      borderRadius: '12px',
      bgColor: 'white100',
    },
    triggerWrapper: {
      borderRadius: '6px',
      bgColor: 'secondary.base',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      display: 'none',
      svg: {
        fill: 'primary.base',
      },
    },
  }),
  // Styles for the size variations
  sizes: {
    sm: props => ({}),
    md: props => ({
      modalContent: {
        w: '400px',
        maxH: '270px',
      },
      triggerWrapper: {
        w: '32px',
        h: '32px',
      },
      wrapper: {
        w: '32px',
        h: '32px',
      },
    }),
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    size: 'md',
  },
}

export default InformationDialogStyle
