import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'

import { InputState } from 'ui-framework/components/primitives/Input/styles/InputStyles'
import { OnClickHandler } from 'ui-framework/common/types'
import { isCallback, getCallback, removeMatched } from 'helpers/utils'

import BaseComponentProps from 'common/BaseComponentProps'
import Icon from 'ui-framework/components/primitives/Icon'
import TableCell from '../Table/elements/TableCell'
import { HStack } from '@chakra-ui/react'
import ProgressIndicator from 'ui-framework/components/primitives/ProgressIndicator'
import Button from 'ui-framework/components/primitives/Button'
import { REGEXPS } from 'constants/CONSTANTS'

type TitleProps = {}
const Title = styled('p')<TitleProps>(props => ({
  color: (props.theme as any).colors.fontnavy,
  fontSize: '15px',
}))
// type RetryBtnProps = {}
// const RetryBtn = styled('p')<RetryBtnProps>(props => ({
//   color: (props.theme as any).colors.primary.base,
//   fontSize: '15px',
//   marginRight: '24px',
// }))

//===================================================
type Props = {
  /**
   * Filename title
   */
  title: string
  /**
   * Progress in percent
   */
  uploadProgress: number
  /**
   * Current upload state
   */
  uploadState?: InputState
  hideState?: boolean
  /**
   * Disabled state
   */
  disabled?: boolean
  /**
   * On delete handler
   */
  onDownload?: OnClickHandler
  /**
   * On delete handler
   */
  onDelete?: OnClickHandler
  /**
   * On retry handler
   */
  onRetry?: OnClickHandler
} & BaseComponentProps

/**
 * UploadFileRow component
 */
const UploadFileRow = ({
  title,
  uploadProgress,
  uploadState = 'success',
  hideState = false,
  disabled = false,
  onDownload,
  onDelete,
  onRetry,
  ...props
}: Props) => {
  const fileName = useMemo(
    () =>
      removeMatched(title, REGEXPS.MATCH_FROM_SECOND_WORD).toLowerCase() ===
      'initial' ? (
        <>
          <b>(initial)</b> {removeMatched(title, REGEXPS.MATCH_FIRST_WORD)}
        </>
      ) : (
        title
      ),
    [title]
  )

  return (
    <tr className={props.className}>
      <TableCell>
        <Title>{fileName}</Title>
      </TableCell>
      <TableCell>
        <ProgressIndicator progress={uploadProgress} state={uploadState} />
      </TableCell>
      <TableCell>
        <HStack spacing={`24px`} justify="flex-end">
          {isCallback(onRetry) && uploadState === 'error' && (
            <Button
              variant="textPrimary"
              onClick={getCallback(onRetry)}
              tabIndex={0}
            >
              Reload
            </Button>
          )}
          {isCallback(onDownload) && uploadState === 'success' && (
            <Button
              variant="textPrimary"
              onClick={getCallback(onDownload)}
              tabIndex={0}
            >
              Download
            </Button>
          )}
          {isCallback(onDelete) && (
            <Icon
              size={`sm`}
              onClick={getCallback(onDelete)}
              color="fontandicongray"
              tabIndex={0}
            >
              close
            </Icon>
          )}
        </HStack>
      </TableCell>
    </tr>
  )
}

export default UploadFileRow
