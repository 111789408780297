import { ReactNode } from 'react'
import { Flex, chakra, Divider } from '@chakra-ui/react'
import Icon from '../Icon'
import TextFromParams from '../TextFromParams'
import { AnyFunction } from 'helpers/utils'
import BaseComponentProps from 'common/BaseComponentProps'

//=============================

type AccordionItemProps = {
  title?: ReactNode | string
  expanded?: boolean
  divider?: boolean
  handleToggle: AnyFunction
} & BaseComponentProps

const Wrapper = chakra(Flex, {
  baseStyle: {
    h: '45px',
    alignItems: 'center',
    cursor: 'pointer',
  },
})

const AccordionItem = ({
  title,
  expanded = false,
  divider = true,
  handleToggle,
  children,
  ...rest
}: AccordionItemProps) => (
  <>
    <Wrapper onClick={handleToggle} h={`45px`} alignItems={'center'} {...rest}>
      <TextFromParams
        params={{ textAlign: 'left', textStyle: 'body.semibold' }}
      >
        {title}
      </TextFromParams>
      <Icon size={'xs'} color={'fontandicongray'}>
        {expanded ? 'up' : 'down'}
      </Icon>
    </Wrapper>
    {expanded && children}
    {divider && <Divider />}
  </>
)

export default AccordionItem
